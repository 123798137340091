import { PlanEdgeType } from "../containers/batch/Batch";

export const generateStepName = (elements: string[]): string => {

    // Handle cases where elements is undefined, not an array, or empty
    if (!elements || !Array.isArray(elements) || elements.length === 0) {
        return "";
    }

    // 1. Safely take the metric from the first element, if it exists
    const metric = elements[0] || "";

    // 2. Safely process the second element, handle cases where it's undefined or empty
    if (!elements[1]) {
        return metric;
    }

    const items = elements[1].split(',').map(item => item.trim()).filter(item => item !== '');
    const processedItems = items.map(item => {
        const itemParts = item.split(' ');

        // If it's a single word, take up to the first three characters
        if (itemParts.length === 1) {
            return itemParts[0].slice(0, 3);
        }
        // If it's a two-word item, safely take the first two letters of the first word and the first letter of the second word
        else if (itemParts.length >= 2) {
            const firstWord = itemParts[0];
            const secondWord = itemParts[1];
            const firstPart = firstWord.slice(0, 2) || "";  // Ensure first two letters of first word
            const secondPart = secondWord.charAt(0) || "";  // Ensure first letter of second word
            return `${firstPart}${secondPart}`;
        }
        // Handle cases with more than two words (if needed)
        return itemParts.map(part => part.charAt(0)).join('');
    });

    // Combine all parts
    const result = `${metric} | ${processedItems.join('/')}`;
    return result;
}


export const getTreeName = (currentName: string, timeBucket: string): string => {

    // Split the current name by '|' and trim each part
    const nameParts = currentName.split('|').map(part => part.trim());
  
    if (nameParts.length === 3) {
      // Update the last part with the new temporal name
      nameParts[2] = timeBucket;
    } else if (nameParts.length === 2) {
      // Append the temporal name as the third part
      nameParts.push(timeBucket);
    } else {
      // Handle cases where the name does not have 2 or 3 parts
      // For example, if it's a single part, you might want to append the temporal name
      // Adjust this logic based on your specific requirements
      nameParts.push(timeBucket);
    }
  
    // Join the parts back together with ' | ' delimiter
    return nameParts.join(' | ');
};

// Function to get the  type
export const getAnalysisPlanNodeName = (edgeName: string, edgeType: string) => {
    switch (edgeType) {
      case "ATTRIBUTE_EDGE":
        return `Split by ${edgeName}`;
      case "BENCHMARK_EDGE":
        return `Compare to ${edgeName}`;
      case "FUNDAMENTAL_EDGE":
        return `Explore ${edgeName}`;
      default:
        return "Unknown Edge Type";
    }
  };
  
// Mapping of edge types to labels based on analysis type
const edgeLabelMap: Record<PlanEdgeType, Record<string, string>> = {
  "Metric->Benchmark": {
    Growth: "Next",
    Diagnostic: "Next",
  },
  "Benchmark->First": {
    Growth: "Next",
    Diagnostic: "Next",
  },
  "After": {
    Growth: "Pick Outliers",
    Diagnostic: "Pick At Risk",
  },
};

// Function to get the label based on edge type and analysis type
export const getAnalysisPlanEdgeLabel = (
  edgeType: PlanEdgeType,
  analysisType: string
): string => {
  // If analysisType is not in the map, fall back to 'Growth'
  const validAnalysisType = edgeLabelMap[edgeType]?.[analysisType] 
    ? analysisType 
    : "Growth";

  return edgeLabelMap[edgeType][validAnalysisType] || "Unknown Edge";
};
