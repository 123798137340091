import { Input, InputGroup, InputLeftElement, InputProps } from '@chakra-ui/react'
import { BsSearch } from 'react-icons/bs'

/**
 * Component to search analysis from the given list of analysis
 */
export const SearchInput = (props: InputProps) => {
  return (
    <InputGroup size="sm">
      <InputLeftElement color="fg.subtle">
        <BsSearch />
      </InputLeftElement>
      <Input placeholder="Search through analyses..." {...props} />
    </InputGroup>
  )
}