import { useEffect } from 'react';

const handleBeforeUnload = (event: BeforeUnloadEvent) => {
  const confirmationMessage = 'Are you sure you want to leave? Analyses that are not saved will be lost.';
  (event || window.event).returnValue = confirmationMessage; // For Gecko + IE
  return confirmationMessage; // For Gecko + Webkit, Safari, Chrome etc.
};

export const useBeforeUnload = () => {
  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);
};
