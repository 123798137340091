// import React from 'react';
// import {Link} from "react-router-dom";
// const SuccessModel: React.FC<{onEditMkg: () => void; onClose: () => void;}>= ({onEditMkg, onClose}) => {
//     return (
//       <div className="modal-dialog modal-confirm">
//         <div className="modal-content">
//           <div className="modal-header">
//             <div className="icon-box">
//               <i className="material-icons">&#xE876;</i>
//             </div>
//             <h4 className="modal-title w-100">Awesome!</h4>
//             <Link type="Link" className="close" onClick={onClose} to={''}>
//               <span>&times;</span>
//             </Link>
//           </div>
//           <div className="modal-body">
//             <p className="text-center">To edit MKG go to edit mkg</p>
//           </div>
//           <div className="modal-footer">
//             <Link className="btn btn-success btn-block" onClick={onEditMkg} to={''}>
//               Edit MKG
//             </Link>
//           </div>
//         </div>
//       </div>
//     );
//   };
//   export default SuccessModel;
import React from "react";
import {
  Box,
  Button,
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Icon,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { MdCheckCircle } from "react-icons/md";

interface ConfirmModalProps {
  isOpen: boolean;
  onClose: () => void;
  onEditMkg: () => void;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  isOpen,
  onClose,
  onEditMkg,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalContent>
        <ModalHeader>
          <VStack spacing={2}>
            <Icon as={MdCheckCircle} w={8} h={8} color="green.500" />
            <Text fontSize="xl">Awesome!</Text>
          </VStack>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text textAlign="center">To edit MKG go to edit mkg</Text>
        </ModalBody>
        <ModalFooter>
          <Button
            as={Link}
            to={''}
            onClick={onEditMkg}
            colorScheme="green"
            width="full"
          >
            Edit MKG
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmModal;
