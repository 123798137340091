import React, { useState } from "react";
import {
  Box,
  Text,
  Button,
  Grid,
  GridItem,
  Flex,
  Link,
  SimpleGrid,
  IconButton,
  Tag,
  TagLabel,
  TagCloseButton,
  Select,
  Checkbox,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Icon,
  Divider,
  HStack,
  Stack,
  StackDivider,
  VStack,
  Input,
  InputGroup,
  InputLeftElement,
  Spinner,
  useToast,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  DeleteIcon,
} from "@chakra-ui/icons";
import { CgArrowTopLeftO } from "react-icons/cg";
import { FaExternalLinkAlt, FaCheckCircle } from "react-icons/fa";
import { useExportContext } from "../../context/export/ExportContext";
import { Header } from "../Interfaces";
import { RiSearchLine } from "react-icons/ri";
import { getSortByLabel } from "../../utils/analysisUtils";
import { deleteAnalysisTreeByID } from "../../services/AnalysisTreeServices";

const TreePanel: React.FC = () => {
  const {
    isLoading,
    metricHeaders,
    selectedTreeHeaders,
    searchMetrics,
    currentPage,
    cachedPages,
    hasMorePages,
    searchString,
    sortBy,
    setCurrentPage,
    setSearchMetrics,
    setSearchString,
    setCachedPages,
    setSelectedTreeHeaders,
    handleSortingOrderChange,
    handleTreeHeaderSelection,
    handleAssignHeadersToPage,
    handleKeyDown,
    selectedPageId
  } = useExportContext();

  const toast = useToast();
  const deleteTreeModal = useDisclosure();
  const [selectedTreeId, setSelectedTreeId] = useState<string>("");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(e.target.value);
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    if (hasMorePages || cachedPages.has(currentPage + 1)) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Determine if a header is selected
  const isSelected = (treeId: string) => {
    return (
      selectedTreeHeaders &&
      selectedTreeHeaders.some((header) => header.id === treeId)
    );
  };

  // Handler for when an option is selected in the dropdown
  // Toggle a metric in the selectedMetrics array
  const handleMetricChange = (metricId: string) => {
    setSearchMetrics((prevMetrics) => {
      if (prevMetrics.includes(metricId)) {
        // Remove metric from the array
        return prevMetrics.filter((id) => id !== metricId);
      } else {
        // Add metric to the array
        return [...prevMetrics, metricId];
      }
    });
  };

  const handleDeleteTree = async (id: string) => {
    await deleteAnalysisTreeByID(id);
    toast({
      title: "Tree deleted successfully",
      description: "Tree deleted successfully",
      status: "success",
      duration: 9000,
      isClosable: true,
      position: "bottom-right",
    })
    
    // Remove the deleted tree from cached pages
    const updatedPages = new Map<number, Header[]>();
    cachedPages.forEach((pageValue, pageKey) => {
      const filteredHeaders = pageValue.filter(header => header.id !== id);
      updatedPages.set(pageKey, filteredHeaders);
    });

    // Remove the deleted tree from selected headers
    const updatedSelectedHeaders = selectedTreeHeaders && selectedTreeHeaders.filter(header => header.id !== id);
    setSelectedTreeHeaders(updatedSelectedHeaders);

    setCachedPages(updatedPages);
    deleteTreeModal.onClose();
  }

  const handleClearSelectedTree = () => {
    setSelectedTreeHeaders([]);
  }
  const openDeleteTreeModal = (id: string) => {
    deleteTreeModal.onOpen();
    setSelectedTreeId(id);
  }
  return (
    <Grid
      templateAreas={`"filters" "content" "pagination"`}
      templateRows="auto 1fr auto"
      templateColumns="1fr"
      gap={2}
      height="100%"
    >
      <GridItem gridArea="filters">
        <Flex
          justifyContent="space-between"
          px={3}
          py={1}
          mt={1}
          width="100%"
          bg="bg.surface"
          borderRadius="lg"
        >
          <HStack justify="space-between">
            <Text
              fontWeight="bold"
              align="left"
              m={1}
              color="fg.muted"
              fontSize="sm"
            >
              Commentaries
            </Text>
            <VStack
              align="center"
              justify="center"
              p={2}
              _hover={{ bg: "gray.100" }}
              cursor="pointer"
              borderRadius="md"
              fontSize="xs"
            ></VStack>
          </HStack>

          <HStack spacing={7}>
            {/* Filter by Section */}
            <Flex align="center">
              <InputGroup size="sm">
                <InputLeftElement>
                  <Icon as={RiSearchLine} color="gray.500" fontSize="lg" />
                </InputLeftElement>
                <Input
                  focusBorderColor="purple.600"
                  value={searchString}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                  width="full"
                  fontSize="sm"
                  variant="filled"
                  type="text"
                  placeholder="Search..."
                  autoComplete="off"
                />
              </InputGroup>
            </Flex>
            <Flex align="center">
              <Text
                align="center"
                justifyContent="center"
                mb={0}
                mr={2}
                fontSize="sm"
                fontWeight="medium"
                color="fg.muted"
              >
                Filter by:
              </Text>

              <Menu closeOnSelect={false} preventOverflow={true}>
                <MenuButton
                  as={Button}
                  color="fg.default"
                  bg="bg.default"
                  maxW="100%"
                  width="auto"
                  rightIcon={<ChevronDownIcon color="fg.muted" />}
                  fontSize="xs"
                  size="xs"
                  border="1px"
                  borderColor="gray.300"
                  borderRadius="md"
                  fontWeight="normal"
                  _hover={{ bg: "purple.50" }}
                  p={3}
                >
                  {metricHeaders && metricHeaders.length > 0
                    ? `Selected (${searchMetrics.length})`
                    : "Select Options"}
                </MenuButton>
                <MenuList maxHeight="200px" overflowY="auto" ml={-6}>
                  {metricHeaders &&
                    metricHeaders.map((header: Header, index) => (
                      <MenuItem key={index} minH="40px" zIndex={10}>
                        <Checkbox
                          isChecked={searchMetrics.includes(header.id!)}
                          onChange={() => handleMetricChange(header.id!)}
                          colorScheme="purple"
                        >
                          {header.name}
                        </Checkbox>
                      </MenuItem>
                    ))}
                </MenuList>
              </Menu>
            </Flex>

            {/* Sort by Section */}
            <Flex align="center">
              <Text
                align="center"
                mb={0}
                mr={2}
                fontSize="sm"
                fontWeight="medium"
                color="fg.muted"
                whiteSpace="nowrap"
              >
                Sort by:
              </Text>
              <Menu>
                <MenuButton as={Button}
                  rightIcon={<ChevronDownIcon />}
                  fontSize="xs"
                  size="xs"
                  rounded="md"
                  color="fg.default"
                  bg="bg.default"
                  border="1px"
                  borderColor="gray.300"
                  _hover={{ bg: "purple.50" }}
                  p={3}
                  aria-label="Sort by"
                  textAlign="left"
                  width="150px"
                  isTruncated>
                  {getSortByLabel(sortBy)}
                </MenuButton>
                <MenuList>
                  <MenuItem key="last_modified" onClick={() => handleSortingOrderChange('LAST_MODIFIED')} fontSize="small">
                    Last Modified
                  </MenuItem>
                  <MenuItem key="created" onClick={() => handleSortingOrderChange('CREATED')} fontSize="small">
                    Created At
                  </MenuItem>
                  <MenuItem key="name" onClick={() => handleSortingOrderChange('NAME')} fontSize="small">
                    Name
                  </MenuItem>
                </MenuList>
              </Menu>
            </Flex>
          </HStack>
        </Flex>
        <HStack bg="bg.surface">
          <Divider m={0} />
          <Text
            fontSize="xs"
            color="fg.muted"
            whiteSpace="nowrap"
            fontWeight={600}
            my={0}
            mx={1}
          >
            Showing {cachedPages.get(currentPage)?.length ?? 0} results
          </Text>
          <Divider m={0} />
        </HStack>
      </GridItem>
      <GridItem gridArea="content" overflowY="auto" p={2}>
        {isLoading ? (
          <Flex p={3} height="75vh" justifyContent="center" alignItems="center" width="100%">
            <Spinner size="xl" />
          </Flex>
        ) : cachedPages.has(currentPage) && cachedPages.get(currentPage)!.length > 0 ? (
          <SimpleGrid columns={{ sm: 1, md: 2, lg: 3 }} gap={2}>
            {cachedPages.get(currentPage)!.map((header) => (
              <Box
                key={header.id}
                p={3}
                pb={2}
                boxShadow="md"
                borderWidth={isSelected(header.id!) ? "2px" : "2px"}
                borderRadius="lg"
                bg={isSelected(header.id!) ? "bg.surface" : "bg.surface"}
                borderColor={isSelected(header.id!) ? "purple.700" : "gray.50"}
                overflowY="auto"
                display="flex"
                flexDirection="column"
                position="relative"
                height="100%"
                _hover={{
                  bg: isSelected(header.id!) ? "green.50" : "gray.50",
                }}
                role="group"
                onClick={() => handleTreeHeaderSelection(header)}
                cursor="pointer"
              >
                <Box
                  flex="1"
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                >
                  <Box>
                    <Text
                      fontWeight={600}
                      fontSize="sm"
                      mb={2}
                      noOfLines={2}
                      align="left"
                    >
                      {header.name}
                    </Text>
                  </Box>
                  <Flex
                    position="absolute"
                    direction="row"
                    top="0%"
                    right="2%"
                    bottom="100%"
                    height="100%"
                    justify="space-between"
                    align="flex-start"
                    visibility="hidden"
                    _groupHover={{ visibility: "visible" }}
                  >
                    <Link>
                    <Icon
                      as={DeleteIcon}
                      color="gray.400"
                      boxSize={4}
                      _hover={{ color: "purple.700" }}
                      mr={3}
                      onClick={() => openDeleteTreeModal(header.id!)}
                    />
                    </Link>
                    <Link href={`/analyze/${header.id}`} isExternal>
                      <Icon
                        as={FaExternalLinkAlt}
                        color="gray.400"
                        boxSize={4}
                        _hover={{ color: "purple.700" }}
                      />
                    </Link>
                  </Flex>
                  <Flex align="center" justifyContent="flex-end">
                    <HStack spacing="1" visibility={isSelected(header.id!) ? 'visible' : 'hidden'}>
                      <Icon
                        as={FaCheckCircle}
                        color="green.500"
                        boxSize={3}
                        m={0}
                      />
                      <Text
                        m={0}
                        fontSize="xs"
                        fontWeight="bold"
                        color="green.500"
                      >
                        Selected
                      </Text>
                    </HStack>
                    {/* Invisible placeholder to reserve space */}
                    {!isSelected(header.id!) && (
                      <HStack spacing="1" visibility="hidden" position="absolute">
                        <Icon as={FaCheckCircle} boxSize={3} m={0} />
                        <Text m={0} fontSize="xs" fontWeight="bold">Selected</Text>
                      </HStack>
                    )}
                  </Flex>
                </Box>
              </Box>
            ))}
          </SimpleGrid>
        ) : (
          <Flex p={3} height="75vh" justifyContent="center" alignItems="center" width="100%">
            <Text fontSize="lg" color="gray.500">
              No results found. Please modify your search.
            </Text>
          </Flex>
        )}
      </GridItem>
      <GridItem gridArea="pagination">
        {selectedPageId && (
          <Box
            as="section"
            zIndex={2}
            width="100%"
            bg="purple.50"
            borderRadius="md"
            mb={1}
          >
            <Flex direction="row">
              <Stack
                justify="space-evenly"
                align="center"
                minW="24"
                spacing="0"
              >
                <Box
                  w="100%"
                  h="100%"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="lg"
                >
                  <Button
                    bg="green.600"
                    variant="outline"
                    leftIcon={<CgArrowTopLeftO />}
                    fontSize="sm"
                    h="100%"
                    w="100%"
                    color="white"
                    fontWeight={600}
                    borderWidth={1}
                    borderColor="green.600"
                    onClick={() => handleAssignHeadersToPage()}
                    _hover={{ bg: "green.700" }}
                  >
                    Assign
                  </Button>
                </Box>
              </Stack>
              <Flex width="100%" overflow="hidden" align="stretch">
                <Stack
                  direction="row"
                  divider={<StackDivider />}
                  spacing="0"
                  align="stretch"
                  flex="1"
                >
                  <Box p={2} flex="1">
                    <SimpleGrid columns={2} spacing={2}>
                      {selectedTreeHeaders.map((header) => (
                        <Tag
                          key={header.id}
                          size="md"
                          fontSize="xs"
                          borderRadius="lg"
                          variant="solid"
                          colorScheme="purple"
                          bg="bg.surface"
                          borderWidth={1}
                          borderColor="purple.700"
                          color="fg.default"
                          display="flex" // Ensures flexbox layout
                          justifyContent="space-between" // Spaces out the children
                          width="100%" // Takes full width of its container
                        >
                          <TagLabel flexGrow={1} textAlign="left">
                            {header.name}
                          </TagLabel>
                          <TagCloseButton
                            onClick={() => handleTreeHeaderSelection(header)}
                          />
                        </Tag>
                      ))}
                    </SimpleGrid>
                  </Box>
                </Stack>
              </Flex>
              <Box
                alignItems="right"
                justifyContent="flex-end"
                borderRadius="lg"
              >
                <Button
                  bg="red.400"
                  variant="outline"
                  leftIcon={<DeleteIcon boxSize={4} />}
                  fontSize="sm"
                  h="100%"
                  w="100%"
                  color="white"
                  fontWeight="medium"
                  borderWidth={1}
                  borderColor="red.400"
                  _hover={{ bg: "red.500" }}
                  iconSpacing={0}
                  onClick={() => handleClearSelectedTree()}
                >
                </Button>
              </Box>
            </Flex>
          </Box>
        )}

        <Flex
          justifyContent="center"
          alignItems="center"
          p={0}
          m={0}
          mb={1}
          width="100%"
        >
          <Flex alignItems="center" bg="gray.100" borderRadius="lg" zIndex={2}>
            <IconButton
              onClick={goToPreviousPage}
              borderRadius="full"
              aria-label="Previous Page"
              icon={<ChevronLeftIcon />}
              colorScheme="gray"
              size="xs"
              variant="ghost"
              mr={2}
              isDisabled={currentPage <= 0}
            />
            <Text mx={1} fontSize="xs" fontWeight="medium" my={0}>
              Page {currentPage}
            </Text>
            <IconButton
              onClick={goToNextPage}
              aria-label="Next Page"
              borderRadius="full"
              icon={<ChevronRightIcon />}
              colorScheme="gray"
              size="xs"
              variant="ghost"
              ml={2}
              isDisabled={!hasMorePages}
            />
          </Flex>
        </Flex>
      </GridItem>
      <Modal isOpen={deleteTreeModal.isOpen} onClose={deleteTreeModal.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize="md">Are you sure want to delete the tree?</ModalHeader>
          <ModalCloseButton />
          <ModalFooter>
            <Button colorScheme="purple" mr={3} onClick={() => handleDeleteTree(selectedTreeId)}>
              OK
            </Button>
            <Button variant="ghost" onClick={deleteTreeModal.onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Grid>
  );
};

export default TreePanel;
