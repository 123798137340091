import React, { useState } from "react";
import {
  Box,
  Accordion,
  Button,
  Flex,
} from "@chakra-ui/react";
import {
  ArrowRightIcon,
} from "@chakra-ui/icons";
import {
  AnalysisTreeRequest,
  TemporalColumn,
} from "../../../containers/commentary/Interfaces";
import { useAnalysisContext } from "../../../context/AnalysisContext";
import { useSidebarContext } from "../../../context/CommentarySidebarContext";
import MetricAccordionContainerNew from "../../../containers/commentary/sidebar/MetricAccordionContainerNew";
import ContextAccordionContainerNew from "../../../containers/commentary/sidebar/ContextAccordionContainerNew";
import BenchmarkAccordionContainerNew from "../../../containers/commentary/sidebar/BenchmarkAccordionContainerNew";
interface SidebarProps {
  isCollapsed: boolean;
  toggleSidebar: () => void;
  analysisTreeReq: (treeReq: AnalysisTreeRequest[]) => void;
}

const Sidebar: React.FC<SidebarProps> = ({
  isCollapsed,
  toggleSidebar,
  analysisTreeReq,
}) => {
  const {
    analysisTreeReqJSON
  } = useSidebarContext();

  const [selectedTemporalColumn, setSelectedTemporalColumn] = useState<
    TemporalColumn[]
  >([]);
  const { isLockAnalyzeButton } = useAnalysisContext();

  const handleSelectedTemporal = (data: TemporalColumn[]) => {
    setSelectedTemporalColumn(data);
  };

  const handleAnalyzeClick = () => {
    analysisTreeReq(analysisTreeReqJSON);
  };

  return (
    <Box
      position="fixed"
      left="0"
      w={isCollapsed ? "fit-content" : "29%"}
      h="calc(100vh - 70px)"
      bg="purple.50"
      p={isCollapsed ? 2 : 4}
      color="black"
      overflowY="visible"
      transition="width 0.7s"
    >
      {!isCollapsed && (
        <Flex direction="column" h="full" justify="space-between">
          <Box overflowY="auto">
            <Accordion allowMultiple bg="purple.50">
              <MetricAccordionContainerNew />
              <ContextAccordionContainerNew
                selectedTemporal={handleSelectedTemporal}
              />
              <BenchmarkAccordionContainerNew
                temporalColumns={selectedTemporalColumn}
              />
            </Accordion>
          </Box>
          <Button
            mt="auto"
            mb={4}
            size="lg"
            colorScheme="purple"
            bg="purple.50"
            color="black"
            minH="42px"
            width="full"
            fontSize="lg"
            fontWeight="bold"
            borderRadius="xl"
            border="1px solid"
            borderColor="#4e1e6c"
            onClick={handleAnalyzeClick}
            _hover={{ bg: "#4e1e6c", color: "white", fontWeight: "bold" }}
            isLoading={isLockAnalyzeButton}
            loadingText="Analyzing..."
            spinnerPlacement="end"
          >
            Start Analysis
            <ArrowRightIcon
              ml={2}
              mr={0}
              h={6}
              w={6}
              boxSize="1rem"
              color="purple.50"
              transition="opacity 0.5s ease-in-out"
            />
          </Button>
        </Flex>
      )}
    </Box>
  );
};

export default Sidebar;
