import React, { useEffect, useState } from "react";
import CreateNewMetric from "../../../components/configuration/snowflake/CreateNewMetric";
import { Header, ModelInfo } from "../../Interfaces";
import {
  fetchModelList,
  getModelById,
} from "../../../services/DataModelService";

/**
 *
 * Input: dataModel callback to pass the fetched data to the parent component.
 *
 * Manages the creation of a new metric by fetching data models from the API,
 * displaying them in a paginated table, and allowing the user to select a data model.
 *
 * @component
 */

const CreateNewMetricContainer: React.FC<{
  dataModel: (data: ModelInfo) => void;
}> = ({ dataModel }) => {
  // State variables to manage fetched data, current page, and rows per page
  const [useData, setData] = useState<Header[]>([]); // State to hold fetched data
  const [page, setPage] = useState<number>(0); // State to manage current page number
  const [rowsPerPage, setRowsPerPage] = useState<number>(5); // State to manage rows per page

  // Fetch data from API when the component mounts
  useEffect(() => {
    fetchData();
  }, []);

  // Function to fetch data from the API
  const fetchData = async () => {
    try {
      const data = await fetchModelList();
      setData(data); // Update state with fetched data
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  /**
   * Handles the click event when the "Add" button is clicked for a specific row.
   * Fetches data for the specified row using an API call and passes the fetched data to the parent component.
   *
   * @param {Header} row - The row for which the "Add" button is clicked.
   */
  const handleAdd = async (row: Header) => {
    console.log("Add button clicked for row:", row);
    try {
      if (row.id !== undefined) {
        // API call
        const data = await getModelById(row.id);
        console.log("API Response for Row ID", row.id, ":", data);
        dataModel(data); // Pass the fetched data to the parent component
      }
    } catch (error) {
      console.error("Error fetching data for ID", row.id, ":", error);
    }
  };

  /**
   * Handles the pagination page change event.
   *
   * @param {any} event - The event object.
   * @param {number} newPage - The new page number.
   */
  const handleChangePage = (
    event: any,
    newPage: React.SetStateAction<number>
  ) => {
    setPage(newPage);
  };

  /**
   * Handles the pagination rows per page change event.
   *
   * @param {object} event - The event object.
   */
  const handleChangeRowsPerPage = (event: { target: { value: string } }) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // Render the CreateNewMetric component with relevant props
  return (
    <CreateNewMetric
      useData={useData}
      page={page}
      rowsPerPage={rowsPerPage}
      handleAdd={handleAdd}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
    />
  );
};

export default CreateNewMetricContainer;
