import React, { useState, FormEvent } from "react";
import "../../../App.css";
import { ModelInfo } from "../../Interfaces";
import { fetchModelInfo } from "../../../services/DataModelService";
import CreateDataModel from "../../../components/configuration/snowflake/CreateDataModel";

/**
 * CreateDataModelContainer Component
 *
 * Input: modelInfo callback to pass fetched model information to the parent component.
 *
 * Manages user input for creating a data model. Fetches model information from the API
 * based on the provided source details (sourceType, dbName, schemaName, tableName).
 *
 * @component
 */

const CreateDataModelContainer: React.FC<{
  modelInfo: (data: ModelInfo) => void; // Callback to pass fetched model information to the parent component.
}> = ({ modelInfo }) => {
  // State variables to manage user input
  const [sourceType, setSourceType] = useState<string>("SNOWFLAKE");
  const [dbName, setDbName] = useState<string>("PC_DBT_DB");
  const [schemaName, setSchemaName] = useState<string>("DBT_NGUPTA");
  const [tableName, setTableName] = useState<string>("LABEL_NEW_REVENUE");
  const [buttonLoader, setButtonLoader] = useState<boolean>(false);
  // Function to fetch model information from the API
  const fetchData = async () => {
    try {
      // Build modelSource object with user input
      const modelSource = {
        source_type: sourceType,
        db_name: dbName,
        schema_name: schemaName,
        table_name: tableName,
      };

      // API call to fetch model information
      const data = await fetchModelInfo(modelSource);

      // Callback to pass fetched model information to the parent component
      modelInfo(data?.model_info);
    } catch (error) {
      // Handle errors that may occur during the fetch process
      alert("Error fetching Model Info");
    }
  };

  // Handler for button click to initiate data fetching
  const handleButtonClick = async () => {
    await fetchData();
  };

  /**
   * Handler for form submission, preventing default behavior and triggering data fetching.
   *
   * @param {FormEvent<HTMLFormElement>} e - The form submission event.
   */
  const handleFormSubmit = async(e: FormEvent<HTMLFormElement>) => {
    setButtonLoader(true);
    e.preventDefault(); // Prevents default form submission behavior
    await handleButtonClick(); // Call your existing function
    setButtonLoader(false);
  };

  return (
    // Render the CreateDataModel component with relevant props
    <CreateDataModel
      sourceType={sourceType}
      dbName={dbName}
      schemaName={schemaName}
      tableName={tableName}
      setSourceType={setSourceType}
      setDbName={setDbName}
      setSchemaName={setSchemaName}
      setTableName={setTableName}
      handleFormSubmit={handleFormSubmit}
      buttonLoader={buttonLoader}
    />
  );
};
export default CreateDataModelContainer;
