import React, { useEffect, useState } from 'react'
import {
  AnalysisTreeResponse,
  ColumnValuesList,
  Node,
} from '../../containers/commentary/Interfaces'
import TreeViewer from '../../components/commentary/TreeViewer'
import { fetchTreeNodeData } from '../../services/AnalysisTreeServices'

interface TreeViewerContainerProps {
  tree: AnalysisTreeResponse | undefined
}

/**
 * TreeViewerContainer Component
 *
 * Input:
 * - tree: AnalysisTreeResponse object representing the analysis tree.
 *
 * Return:
 * Renders the TreeViewer component with relevant props.
 *
 * This component manages the container for the tree viewer.
 * It handles the selection of nodes in the tree and updates the state accordingly.
 * The main functionality includes handling node clicks, retrieving node information,
 * and passing necessary data to the TreeViewer component for rendering.
 *
 * @component
 */
const TreeViewerContainer: React.FC<TreeViewerContainerProps> = ({ tree }) => {
  // console.log("TreeViewerContainer Tree", tree);
  // State variables
  const [selectedNodeId, setSelectedNodeId] = useState<string | null>(null)
  const [nodeMap, setNodeMap] = useState<Map<string, Node> | null>(null)
  const [selectedNode, setSelectedNode] = useState<Node | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  // Function to handle node click
  const handleNodeClick = async (
    nodeId: string,
    nodeMap: Map<string, Node>,
  ) => {
    setNodeMap(nodeMap)
    setSelectedNodeId(nodeId)

    const node = nodeMap?.get(nodeId)
    const nodeData = node?.analysed_data
    // console.log("nodeData ", nodeData);

    if (nodeData !== undefined) {
      setSelectedNode(node!)
    } else {
      setIsLoading(true)
      const responseData = await fetchTreeNodeData(tree?.header.id!, nodeId)
      // console.log("selectedNode", selectedNode);
      if (responseData) {
        nodeMap.set(nodeId, responseData)
        setSelectedNode(responseData)
      }
      setIsLoading(false)
    }
    const columnValues = selectedNode?.analysed_data?.column_values_list
  }

  return (
    <TreeViewer
      tree={tree}
      selectedNodeId={selectedNodeId}
      handleNodeClick={handleNodeClick}
      selectedNode={selectedNode}
      isLoading={isLoading}
    />
  )
}

export default TreeViewerContainer
