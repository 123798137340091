import React, { useState } from "react";
import { Link } from "react-router-dom";
import Layout from "../../components/layout/Layout";
import "../CSS/MetricKnowledgeGraph.css";
import ViewGraphContainer from "../../containers/configuration/mkg/ViewGraphContainer";
import EditGraphContainer from "../../containers/configuration/mkg/EditGraphContainer";



const MetricKnowledgeGraph: React.FC = () => {
  const [selectedLink, setSelectedLink] = useState<string | null>(null);

  const renderComponent = (link: string) => {
    setSelectedLink(link);
  };

  const renderSelectedComponent = () => {
    switch (selectedLink) {
      case "EditGraph":
        return <EditGraphContainer />;
      case "ViewGraph":
        return <ViewGraphContainer />;
      default:
        return null;
    }
  };

  return (
    // <Layout>
    //   <div className="row no-gutters">
    //     <div className="col-md-3" style={{ backgroundColor: "#FFF5F9" }}>
    //       <div
    //         className="d-flex flex-column flex-shrink-0 p-3 bg-light"
    //         style={{ height: "100vh" }}
    //       >
    //         <span
    //           className="badge text-center"
    //           style={{
    //             backgroundColor: "#fff2f8",
    //             color: "#000",
    //             display: "block",
    //             marginTop: "10px",
    //             marginBottom: "10px",
    //           }}
    //         >
    //           <h6>Knowledge Graph</h6>
    //         </span>
    //         <ul className="nav nav-pills flex-column mb-auto">
    //           <li
    //             className={
    //               selectedLink === "EditGraph"
    //                 ? "mkg-li mkg-active-link"
    //                 : "mkg-li"
    //             }
    //             onClick={() => renderComponent("EditGraph")}
    //           >
    //             <Link to={""}>Edit Graph</Link>
    //           </li>
    //           <li
    //             className={
    //               selectedLink === "ViewGraph"
    //                 ? "mkg-li mkg-active-link"
    //                 : "mkg-li"
    //             }
    //             onClick={() => renderComponent("ViewGraph")}
    //           >
    //             <Link to={""}>View Graph</Link>
    //           </li>
    //         </ul>
    //       </div>
    //     </div>
    //     <div
    //       className="col-md-9"
    //       style={{
    //         backgroundColor: "white",
    //         height: "100vh",
    //         overflowY: "auto",
    //         position: "relative",
    //       }}
    //     >
    //       {/* Render the selected component */}
    //       {renderSelectedComponent()}
    //     </div>
    //   </div>
    // </Layout>
    <></>
  );
};

export default MetricKnowledgeGraph;
