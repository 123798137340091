import { Center, Circle, Icon, Text, keyframes, Avatar } from '@chakra-ui/react'
import logo from '../../assets/Anko.png';
/**
 * Component to display the prompt before the start of the conversation.
 */
const rotateAnimation = keyframes`
  0% { transform: rotate(0deg); }
  50% { transform: rotate(15deg); }
  100% { transform: rotate(120deg); }
`;

export const EmptyStatePrompt = (props: React.PropsWithChildren) => {
  const animation = `${rotateAnimation} 1s linear infinite`;
  const { children } = props
  return (
    <Center height="full" gap="6">
      <Circle
        bg="#E8E0FA"
        color="fg.default"
        size="12"
        outline="2px solid"
        outlineColor="#4e1e6c"
      >
      <Avatar size="xs" src={logo} name="Assistant" animation={animation} color="black"/>
      </Circle>
      <Text fontSize="xl" color="fg.muted" fontWeight="medium" mb={0}>
        {children}
      </Text>
    </Center>
  )
}