import { AnalysisTreeRequest } from "../containers/commentary/Interfaces";
import { CancelRequest, StatefulRequest } from "../containers/stateful/Stateful";
import showToast from "../hooks/useCustomToast";

/**
 * Generates trees based on the provided analysis tree requests.
 *
 * @param {AnalysisTreeRequest[]} analysisTreeReq - Array of analysis tree requests.
 * @returns {Promise<any[]>} A promise that resolves to an array of generated trees.
 * @throws {Error} Throws an error if there is an issue generating trees or if the request fails.
 */
export const generateTrees = async (analysisTreeReq: AnalysisTreeRequest[]) => {
  try {
    const fetchPromises = analysisTreeReq.map(async (jsonData) => {
      try {
        const response = await fetch(process.env.REACT_APP_GENERATE_TREE!, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify(jsonData),
        });

        if (!response.ok) {
          showToast("Analysis Tree Generation Failed.", "", "error");
          return null;
        }

        return response.json();
      } catch (error) {
        showToast("Analysis Tree Generation Failed.", "", "error");
        return null; // Return null for failed requests
      }
    });

    const responses = await Promise.all(fetchPromises);
    return responses.filter((response) => response !== null); // Filter out null responses
  } catch (error) {
    showToast("Error generating trees", "", "error");
    return []; // Return empty array if there's an error
  }
};

/**
 * Fetches analysis tree data based on the provided tree ID.
 *
 * @param {string} id - The ID of the tree to fetch.
 * @returns {Promise<any>} A promise that resolves to the fetched analysis tree data.
 * @throws {Error} Throws an error if there is an issue fetching the data or if the request fails.
 */
export const fetchAnalysisTreeById = async (
  id: string,
  includeNodes: boolean,
  includeStarredCommentary: boolean,
  includeAllCommentary: boolean,
  includeSavedCommentary: boolean
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_GET_ANALYSIS_TREE_BY_ID}/${id}?includeNodes=${includeNodes}&includeStarredCommentary=${includeStarredCommentary}&includeAllCommentary=${includeAllCommentary}&includeSavedCommentary=${includeSavedCommentary}`,
      {
        method: "GET",
        credentials: "include",
      }
    );
    if (!response.ok) {
      showToast("Failed to fetch analysis tree", "", "error");
      return null;
    }
    return await response.json();
  } catch (error) {
    showToast("Error fetching analysis tree", "", "error");
    return null;
  }
};

/**
 * Delete Analysis tree by ID.
 *
 * @param {string} id - The ID of the tree to fetch.
 * @returns {Promise<any>} A promise that resolves to the fetched analysis tree data.
 * @throws {Error} Throws an error if there is an issue fetching the data or if the request fails.
 */
export const deleteAnalysisTreeByID = async (treeId: string) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_DELETE_ANALYSIS_TREE_BY_ID}/${treeId}`,
      {
        method: "DELETE",
        credentials: "include",
      }
    );

    if (response.status !== 204) {
      return null;
    }

    return response;
  } catch (error) {
    showToast("Error deleting analysis tree", "", "error");
  }
};
/**
 * Fetches analysis tree node based on the provided treeId and nodeId
 *
 * @param {string} treeId - The ID of the tree to fetch.
 * @param {string} nodeId - The ID of the node to fetch.
 * @returns {Promise<any>} A promise that resolves to the fetched analysis tree data.
 * @throws {Error} Throws an error if there is an issue fetching the data or if the request fails.
 */
export const fetchTreeNodeData = async (treeId: string, nodeId: string) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_GET_ANALYSIS_TREE_NODE}?treeId=${treeId}&nodeId=${nodeId}&includeData=true`,
      {
        method: "GET",
        credentials: "include",
      }
    );
    if (!response.ok) {
      showToast("Failed to fetch analysis tree node", "", "error");
      return null;
    }
    return await response.json();
  } catch (error) {
    showToast("Error fetching analysis tree node", "", "error");
  }
};

/**
 * Updates Analysis name.
 *
 * @param {string} id - The ID of the tree to fetch.
 * @param {string} name - Name of the Analysis.
 * @returns {Promise<any>} A promise that resolves to the fetched analysis tree data.
 * @throws {Error} Throws an error if there is an issue fetching the data or if the request fails.
 */
export const updateAnalysisName = async (id: string, name: string) => {
  try {
    const encodedName = encodeURIComponent(name);
    const response = await fetch(
      `${process.env.REACT_APP_UPDATE_ANALYSIS_NAME}/?id=${id}&name=${encodedName}`,
      {
        method: "PUT",
        credentials: "include",
      }
    );
    if (response.status !== 204) {
      showToast("error in updating analysis tree name", "", "error");
      return null;
    }
    return response;
  } catch (error) {
    showToast("error in updating analysis tree name", "", "error");
  }
};

//---------------------------------deprecated----------------------------------//
/**
 * Fetches Analysis Tree Headers.
 *
 * @returns {Promise<any>} A promise that resolves to the fetched analysis tree data.
 * @throws {Error} Throws an error if there is an issue fetching the data or if the request fails.
 */
export const fetchAnalysisTreeHeaders = async (maxResults: number) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_GET_ALL_TREE_HEADERS}?pageSize=${maxResults}`,
      {
        method: "GET",
        credentials: "include",
      }
    );

    if (!response.ok) {
      showToast("Error in fetching analysis tree headers", "", "error");
      return null;
    }

    const data = await response.json();
    return data;
  } catch (error) {
    showToast("Error in fetching analysis tree headers.", "", "error");
  }
};

/**
 * Generates trees based on the provided analysis tree requests.
 *
 * @param {AnalysisTreeRequest} analysisTreeReq - Array of analysis tree requests.
 * @returns {Promise<any>} A promise that resolves to an array of generated trees.
 * @throws {Error} Throws an error if there is an issue generating trees or if the request fails.
 */
export const generateTree = async (jsonData: AnalysisTreeRequest): Promise<any | null> => {
  const timeoutDuration = parseInt(process.env.REACT_APP_DEFAULT_TIMEOUT!, 10);

  const timeout = new Promise<Response>((_, reject) => 
    setTimeout(() => reject(new Error("Analysis Tree Generation Timeout!")), timeoutDuration)
  );

  try {
    // Use Promise.race to race the fetch against the timeout
    const response = await Promise.race([
      fetch(process.env.REACT_APP_GENERATE_TREE!, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(jsonData),
      }),
      timeout
    ]);

    if (!response.ok) {
      showToast("Analysis Tree Generation Failed", "", "error");
      return null;
    }

    return response.json();
  } catch (error) {
    showToast("Analysis Tree Generation Failed", "", "error");
    return null; // Return null for failed requests or timeout
  }
};

/**
 * Fetches Analysis Tree Headers.
 *
 * @returns {Promise<any>} A promise that resolves to the fetched analysis tree data.
 * @throws {Error} Throws an error if there is an issue fetching the data or if the request fails.
 */
export const getTreeHeaders = async (sortField: string, metricId: string[],
  pageSize: number, pageNumber: number, searchString: string
) => {
  const metricIdsParam = encodeURIComponent(JSON.stringify(metricId));
  const sortAsc = sortField === "LAST_MODIFIED" ? false : true;
  try {
    const response = await fetch(
      `${process.env.REACT_APP_GET_ALL_TREE_HEADERS}?sortByField=${sortField}&sortAscending=${sortAsc}
      &metricIds=${metricIdsParam}&pageSize=${pageSize}&pageNumber=${pageNumber}&searchString=${searchString}`,
      {
        method: "GET",
        credentials: "include",
      }
    );

    if (!response.ok) {
      showToast("Error in fetching analysis tree headers", "", "error");
      return null;
    }

    const data = await response.json();
    return data;
  } catch (error) {
    showToast("Error in fetching analysis tree headers.", "", "error");
  }
};

/**
 * Generates trees based on the provided analysis tree requests.
 *
 * @param {StatefulRequest} json - analysis tree requests.
 * @returns {Promise<any>} A promise that resolves to an array of generated trees.
 * @throws {Error} Throws an error if there is an issue generating trees or if the request fails.
 */
export const statefulAnalysisTree = async (json: StatefulRequest): Promise<any | null> => {
  const timeoutDuration = parseInt(process.env.REACT_APP_DEFAULT_TIMEOUT!, 10);

  const timeout = new Promise<Response>((_, reject) => 
    setTimeout(() => reject(new Error("Analysis Tree Generation Timeout!")), timeoutDuration)
  );

  try {
    // Use Promise.race to race the fetch against the timeout
    const response = await Promise.race([
      fetch(process.env.REACT_APP_STATEFUL_ANALYSIS_TREE!, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(json),
      }),
      timeout
    ]);

    if (!response.ok) {
      showToast("Analysis Tree Generation Failed", "", "error");
      return null;
    }

    return response.json();
  } catch (error) {
    showToast("Analysis Tree Generation Failed", "", "error");
    return null; // Return null for failed requests or timeout
  }
};

/**
 * Fetches analysis tree node based on the providxsed treeId and nodeId
 *
 * @param {CancelRequest} json - request structure to cancel transaction.
 * @returns {Promise<any>} A promise that resolves to the fetched analysis tree data.
 * @throws {Error} Throws an error if there is an issue fetching the data or if the request fails.
 */
export const cancelTransaction = async (json: CancelRequest) => {
  try {
    const response = await fetch(
      process.env.REACT_APP_CANCEL_STATEFUL!,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",       
        },
        credentials: "include",
        body: JSON.stringify(json),
      }
    );
    if (response.status === 200) {
      showToast("transaction cancelled", "", "success");
      return;
    }

    showToast("Failed to cancel transaction", "", "error");
    return;

  } catch (error) {
    showToast("Error in cancel transaction", "", "error");
  }
};