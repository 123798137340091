import { StatefulRequestType, AnalysisTreeRequestType } from "../../utils/enum";
import { AnalysisTreeRequest, Benchmark, Commentary, SelectedNodes } from "../commentary/Interfaces";
import { StatefulAnalysisTreeRequest, StatefulRequest } from "./Stateful";

export class StatefulRequestBuilder {
    private transactionId: string;
  
    constructor(transactionId: string) {
      this.transactionId = transactionId;
    }
  
    private createStatefulRequest(
      analysisTreeRequests: StatefulAnalysisTreeRequest[]
    ): StatefulRequest {
      return {
        request_type: StatefulRequestType.ANALYSIS_TREE_REQUEST,
        state_key: {
          transaction_id: this.transactionId,
        },
        analysis_tree_requests: analysisTreeRequests,
      };
    }

    private statefulGetTreeRequest: StatefulAnalysisTreeRequest = {
      type: AnalysisTreeRequestType.GET_TREE,
      get_tree: {
          include_nodes: true,
          include_commentary: true,
          include_node_metric_map: true,
      },
    };

    // Generate tree request
    public GenerateTreeRequest(analysisTreeRequest: AnalysisTreeRequest): StatefulRequest {

        const statefulGenerateTreeRequest: StatefulAnalysisTreeRequest = {
            type: AnalysisTreeRequestType.GENERATE_TREE,
            generate_tree: analysisTreeRequest,
        };

        return this.createStatefulRequest([statefulGenerateTreeRequest, this.statefulGetTreeRequest]);
    }
  
    // Generate commentary request
    public GenerateCommentaryRequest(commentaryConfig: SelectedNodes): StatefulRequest {

      const statefulCommentaryRequest: StatefulAnalysisTreeRequest = {
        type: AnalysisTreeRequestType.GENERATE_COMMENTARY,
        generate_commentary: { 
            configuration: commentaryConfig 
        },
      };
  
      return this.createStatefulRequest([statefulCommentaryRequest]);
    }
  
    // Get analysis node request
    public GetNodeRequest(nodeId: string): StatefulRequest {
      const statefulGetNodeRequest: StatefulAnalysisTreeRequest = {
        type: AnalysisTreeRequestType.GET_ANALYSIS_NODE,
        get_analysis_node: { node_id: nodeId, include_data: true },
      };
  
      return this.createStatefulRequest([statefulGetNodeRequest]);
    }
  
    // Load and get tree request
    public LoadTreeRequest(treeId: string): StatefulRequest {

      const statefulLoadTreeRequest: StatefulAnalysisTreeRequest = {
        type: AnalysisTreeRequestType.LOAD_TREE,
        load_tree: { tree_id: treeId },
      };
  
      return this.createStatefulRequest([statefulLoadTreeRequest, this.statefulGetTreeRequest]);
    }

    // Save commentary request
    public SaveCommentaryRequest(commentary: string, conversationId: string): StatefulRequest {

        const statefulSaveCommentaryRequest: StatefulAnalysisTreeRequest = {
        type: AnalysisTreeRequestType.SAVE_COMMENTARY,
        save_commentary: {
            conversation_id: conversationId,
            edited_commentary: commentary,
        },
        };

        return this.createStatefulRequest([statefulSaveCommentaryRequest]);
    }

    // Delete commentary request
    public DeleteCommentaryRequest(commentary: Commentary): StatefulRequest {
        const statefulDeleteCommentaryRequest: StatefulAnalysisTreeRequest = {
            type: AnalysisTreeRequestType.DELETE_COMMENTARY,
            delete_commentary: {
                conversation_id: commentary.conversation_header.id,
            },
        };

        return this.createStatefulRequest([statefulDeleteCommentaryRequest]);
    }

    // Generate sub tree request
    public  GenerateSubTreeRequest(
        nodeId: string,
        edgeId: string,
        rowIndex: number,
        benchmark: Benchmark | null
    ): StatefulRequest {

        const statefulGenerateSubTreeRequest: StatefulAnalysisTreeRequest = {
            type: AnalysisTreeRequestType.GENERATE_SUB_TREE,
            generate_sub_tree: {
                parent_node_id: nodeId,
                ...(benchmark ? {} : {edge_id: edgeId}),
                row_index: rowIndex,
                benchmark: benchmark,
                create_root_node_only: true,
            },
        };

        return this.createStatefulRequest([statefulGenerateSubTreeRequest, this.statefulGetTreeRequest]);
    }

    // Update tree name request
    public UpdateTreeHeaderRequest(name: string): StatefulRequest {
      const statefulUpdateTreeNameRequest: StatefulAnalysisTreeRequest = {
          type: AnalysisTreeRequestType.UPDATE_TREE_HEADER,
          update_tree_header: {
              updated_name: name,
          },
      };

      return this.createStatefulRequest([statefulUpdateTreeNameRequest]);
    }

    // Get tree request
    public GetTreeRequest(): StatefulRequest {
      return this.createStatefulRequest([this.statefulGetTreeRequest]);
    }
  }
  