import showToast from "../hooks/useCustomToast";

export const fetchDownloadPrompt = async(id: string) => {
  try {
      const response = await fetch(
        `${process.env.REACT_APP_DOWNLOAD_PROMPT!}/${id}`,
        {
          credentials: "include",
        }
      );
      // Try to extract filename from Content-Disposition header or use a default
      const contentDisposition = response.headers.get("Content-Disposition");
      const filename = contentDisposition
        ? contentDisposition.split("filename=")[1].split(";")[0]
        : "prompt.txt";

      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = downloadUrl;
      a.download = filename.replace(/['"]/g, ""); // Remove any quotes in filename
      document.body.appendChild(a);
      a.click();

      // Clean up by revoking the Object URL and removing the anchor element
      window.URL.revokeObjectURL(downloadUrl);
      a.remove();
    } catch (error) {
      showToast("Error downloading prompt", "", "error");
    }
 }
