import { Commentary, SelectedNodes } from "../containers/commentary/Interfaces";
import showToast from "../hooks/useCustomToast";

// Create a function to handle the API call
export const updateCommentary = async (treeId: string, insights: string) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_UPDATE_COMMENTARY}?treeId=${treeId}&insights=${insights}`,
      {
        method: "GET",
        credentials: "include",
      }
    );

    if (!response.ok) {
      showToast("Failed to update commentary", "", "error");
      return null;
    }

    return await response.json();
  } catch (error) {
    showToast("Error updating commentary.", "", "error");
  }
};

export const generateCommentary = async (treeId: string) => {
  try {
    const data = await fetch(
      `${process.env.REACT_APP_GENERATE_COMMENTARY}?treeId=${treeId}`,
      {
        method: "GET",
        credentials: "include",
      }
    );

    if (!data.ok) {
      showToast("Error generating commentary.", "", "error");
      return null;
    }

    return await data.json();
  } catch (error) {
    showToast("Error generating commentary", "", "error");
  }
};

export const starCommentary = async (
  conversationMap: { [key: string]: boolean },
  treeId: string
) => {
  try {
    console.log("conversationMap", conversationMap);
    const response = await fetch(process.env.REACT_APP_STAR_CONVERSATION!, {
      method: "POST", // Specify the HTTP method
      headers: {
        "Content-Type": "application/json", // Set the content type to JSON
      },
      body: JSON.stringify({
        conversationMap: conversationMap,
        treeId: treeId,
      }),
      credentials: "include",
    });
    if (!response.ok) {
      showToast("Error starring commentary.", "", "error");
      return null;
    }
    return response;
  } catch (error) {
    showToast("Error starring commentary", "", "error");
  }
};

export const generateConfigCommentary = async (treeId: string,
  config: SelectedNodes | null) => {
  try {
    let url = `${process.env.REACT_APP_GENERATE_COMMENTARY}?treeId=${treeId}&newTask=true&validate=false`;
    if (config !== null) {
      const encodedConfig = encodeURIComponent(JSON.stringify(config));
      url += `&configJson=${encodedConfig}`;
    }

    const response = await fetch(url, {
      method: "GET",
      credentials: "include",
    });

    if (!response.ok) {
      showToast("Error generating commentary.", "", "error");
      return null;
    }

    return await response.json();
  } catch (error) {
    showToast("Error generating commentary.", "", "error");
  }
};


export const saveCommentary = async (treeId: string, commentary: Commentary) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_SAVE_COMMENTARY}?treeId=${treeId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(commentary),
        credentials: "include",
      }
    );

    // Check directly for 204 No Content, which is considered a success
    if (response.status === 204) {
      return response;
    }

    return null; 
  } catch (error) {
    showToast("Error generating commentary.", "", "error");
  }
};

/**
 * Delete tree.
 *
 * @param {string} id - treeId.
 * @param {string} id - conversationId.
 * @returns {Promise<void>} A promise that resolves to the API response.
 * @throws {Error} Throws an error if the request fails or the environment variable is not set.
 */
export const deleteCommentary = async (treeId: string, conversationId: string) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_SAVE_COMMENTARY!}?treeId=${treeId}&conversationId=${conversationId}`, {
      method: "DELETE",
      credentials: "include",
    });

    if (response.status !== 204) {
      showToast("Delete commentary unsuccessful", "", "error");
      return null;
    }

    return 204;
  } catch (error) {
    showToast("Error exporting report", "", "error");
  }
};