import React, { useEffect, useState } from "react";
import { Benchmark, TemporalColumn } from "../../commentary/Interfaces";
import { LocalDate, DateTimeFormatter } from "@js-joda/core";
import { CustomPeriod, TimeBucket } from "../../../utils/enum";
import BenchmarkFilter from "../../../components/analyse/analysis_drawer/BenchmarkFilter";
import { useAnalysisDrawerContext } from "../../../context/AnalysisDrawerContext";

/**
 * BenchmarkFilterContainer Component
 * Return:
 * Renders the BenchmarkFilter component with relevant props.
 *
 * This component manages the selection, addition, and removal of custom and preset benchmarks.
 * It calculates date ranges based on selected temporal columns and periods.
 * The main functionality includes handling the addition and removal of periods to selected benchmarks,
 * calculating dates, and updating custom benchmarks accordingly.
 *
 * @component
 */
const BenchmarkFilterContainer: React.FC = () => {
  const {
    temporalColumnByMetricIdMap,
    updateBenchmarkMap,
    setDate,
    date,
    selectedBenchmarksFromDropdown,
    setSelectedBenchmarksFromDropdown,
    selectedTemporalColumnsFromDropdown,
    metricInfoMap
  } = useAnalysisDrawerContext();

  const [selectedPeriod, setSelectedPeriod] = useState<
    Array<{ name: string; value: string }>
  >([]);
  const [uniquePresetBenchHeaders, setUniquePresetBenchHeaders] = useState<
    string[]
  >([]);

  /**
   * Handles addition of preset as well as custom benchmark.
   *
   * @param {string} selectedValue - value of selected option
   * @param {string} selectedName - name of selected option.
   */
  const handleAddPeriod = (selectedValue: string, selectedName: string) => {
    console.log(
      "clicked other than custome period",
      selectedName,
      selectedValue
    );
    setSelectedPeriod((prev) => [
      ...prev,
      { name: selectedName, value: selectedValue },
    ]);
    //if temporal is selected then update it or if not which will be the case when
    //user want to set benchmark as forcaste and not on temporal coloumn
    const updatedTemporalColumns =
      selectedTemporalColumnsFromDropdown.length > 0
        ? selectedTemporalColumnsFromDropdown.map((temporalColumn) => ({
            ...temporalColumn,
            custom_period: { name: selectedName, value: selectedValue },
          }))
        : [
            {
              custom_period: { name: selectedName, value: selectedValue },
            },
          ];

    // Update the selected benchmarks state by appending these updated temporal columns
    setSelectedBenchmarksFromDropdown((prevBenchmarks) => [
      ...prevBenchmarks,
      ...updatedTemporalColumns,
    ]);
    updatedTemporalColumns.forEach((column) => {
      handleDateCalculations(column); // Optionally handle date calculations
    });
  };

  /**
   * Function to handle removal of a benchmark from selected benchmarks.
   *
   * @param {number} index - Index of the benchmark to be removed.
   */
  const handleRemoveBenchmark = (index: number) => {
    const removedBenchmark = selectedBenchmarksFromDropdown[index];
    const updatedBenchmarks = [...selectedBenchmarksFromDropdown];
    updatedBenchmarks.splice(index, 1);
    setSelectedBenchmarksFromDropdown(updatedBenchmarks);

    // Remove the corresponding entry from the date map
    if (removedBenchmark?.name) {
      const updatedDate = { ...date };
      delete updatedDate[removedBenchmark.name!];
      setDate(updatedDate);
    }
  };

  /**
   * Function to calculate dates based on selected temporal column and period.
   *
   * @param {TemporalColumn} selectedTemporalColumn - Selected temporal column.
   */
  const handleDateCalculations = (selectedTemporalColumn: TemporalColumn) => {
    // Convert selectedMonth and selectedYear to numeric values
    let monthValue = parseInt(selectedTemporalColumn?.month?.value!);
    let yearValue = parseInt(selectedTemporalColumn?.year!, 10);

    // Define the desired date format
    const dateFormatter = DateTimeFormatter.ofPattern("MM/dd/yyyy");

    // Initialize variables for start and end dates
    let startDate: string = "";
    let endDate: string = "";

    // Check the selected time operator
    if (selectedTemporalColumn?.time_bucket === TimeBucket.Monthly) {
      // Check if custom_period is "pp" and adjust the values accordingly
      if (
        selectedTemporalColumn?.custom_period?.value ===
        CustomPeriod.PreviousPeriod
      ) {
        monthValue -= 1;
        if (monthValue === 0) {
          // If the month becomes 0, set it to December of the previous year
          monthValue = 12;
          yearValue -= 1;
        }
      } else if (
        selectedTemporalColumn?.custom_period?.value ===
        CustomPeriod.SamePeriodLastYear
      ) {
        yearValue -= 1;
      }

      startDate = LocalDate.of(yearValue, monthValue, 1).format(dateFormatter);
      endDate = LocalDate.of(yearValue, monthValue, 1)
        .plusMonths(1)
        .minusDays(1)
        .format(dateFormatter);
    } else if (selectedTemporalColumn?.time_bucket === TimeBucket.Quarterly) {
      // Check if custom_period is "pp" and adjust the values accordingly
      let quarter = parseInt(selectedTemporalColumn!.quarter!.value);
      if (
        selectedTemporalColumn?.custom_period?.value ===
        CustomPeriod.PreviousPeriod
      ) {
        // Decrease the quarter value by 1
        quarter -= 1;
        if (quarter === 0) {
          // If the quarter becomes 0, set it to 4 of the previous year
          quarter = 4;
          yearValue -= 1;
        }
      } else if (
        selectedTemporalColumn?.custom_period?.value ===
        CustomPeriod.SamePeriodLastYear
      ) {
        yearValue -= 1;
      }

      const quarterStartMonth = (quarter - 1) * 3 + 1;
      startDate = LocalDate.of(yearValue, quarterStartMonth, 1).format(
        dateFormatter
      );
      endDate = LocalDate.of(yearValue, quarterStartMonth, 1)
        .plusMonths(3)
        .minusDays(1)
        .format(dateFormatter);
    } else if (selectedTemporalColumn?.time_bucket === TimeBucket.Yearly) {
      // Check if custom_period is "pp" and adjust the values accordingly
      if (
        selectedTemporalColumn?.custom_period?.value ===
        CustomPeriod.PreviousPeriod
      ) {
        yearValue -= 1;
      } else if (
        selectedTemporalColumn?.custom_period?.value ===
        CustomPeriod.SamePeriodLastYear
      ) {
        yearValue -= 1;
      }

      startDate = LocalDate.of(yearValue, 1, 1).format(dateFormatter);
      endDate = LocalDate.of(yearValue, 1, 1)
        .plusYears(1)
        .minusDays(1)
        .format(dateFormatter);
    }

    setDate((prevDates) => {
      // Creating a new key based on the custom_period or other unique identifier
      const newKey =
        selectedTemporalColumn?.custom_period?.name || "defaultKey";
      return {
        ...prevDates,
        [newKey]: [startDate.toString(), endDate.toString()],
      };
    });
  };

  useEffect(() => {
    const headersSet = new Set<string>();
  
    // Iterate over each array of benchmarks in the object values
    Object.values(metricInfoMap).forEach(metric => {
      // Check if benchmarks is truthy and an array
      const benchmarks = metric && metric?.edges?.filter(edge => 
        edge.edge_type === "BENCHMARK_EDGE" && edge.benchmark !== undefined
      ).map(edge => edge.benchmark as Benchmark);
      if (benchmarks) {
        benchmarks.forEach(benchmark => {
          // Ensure the benchmark has a header and name before adding to the set
          if (benchmark.header && benchmark.header.name) {
            headersSet.add(benchmark.header.name);
          }
        });
      }
    });
  
    setUniquePresetBenchHeaders(Array.from(headersSet)); // Update state with unique headers
  }, [metricInfoMap]);
  
  // useEffect to update custom benchmarks when selected benchmarks or dates change:
  useEffect(() => {
    updateBenchmarkMap(selectedBenchmarksFromDropdown);
  }, [selectedBenchmarksFromDropdown, temporalColumnByMetricIdMap]);

  return (
    <BenchmarkFilter
      selectedPeriod={selectedPeriod}
      selectedBenchmarks={selectedBenchmarksFromDropdown}
      handleAddPeriod={handleAddPeriod}
      handleRemoveBenchmark={handleRemoveBenchmark}
      selectedTemporalColumnsFromDropdown={selectedTemporalColumnsFromDropdown}
      uniquePresetBenchHeaders={uniquePresetBenchHeaders}
    />
  );
};

export default BenchmarkFilterContainer;
