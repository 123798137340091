import React from 'react'
import {
  Box,
  Button,
  Flex,
  Text,
  VStack,
  HStack,
  Avatar,
  UnorderedList,
  OrderedList,
  ListItem,
  Heading,
  Skeleton,
  Stack,
} from '@chakra-ui/react'
import { MutableRefObject } from 'react'
import ReactMarkdown from 'react-markdown'
import { FiSend } from 'react-icons/fi'
import { ChatTextArea } from './ChatTextArea'
import { EmptyStatePrompt } from './EmptyStatePrompt'
import logo from '../../assets/logo.png'
import { MessageData } from '../../containers/chat/interface'
import CopyButton from './CopyButton'

interface ChatWindowProps {
  messages: MessageData[]
  markdownRef: React.RefObject<HTMLDivElement>
  handleSendMessage: (e: React.FormEvent) => void
  inputValue: string
  setInputValue: React.Dispatch<React.SetStateAction<string>>
  vStackRef: MutableRefObject<HTMLDivElement | null>
  handleKeyDown: (e: any) => void
  loader: boolean
}

/**
 * Dumb Component for ChatWindow
 */
export const ChatWindow: React.FC<ChatWindowProps> = ({
  messages,
  markdownRef,
  handleSendMessage,
  inputValue,
  setInputValue,
  vStackRef,
  handleKeyDown,
  loader,
}) => {
  return (
    <Flex
      direction="column"
      pos="relative"
      bg="bg.canvas"
      height="80vh"
      overflow="hidden"
    >
      {messages.length === 0 ? (
        <Flex
          direction="column"
          justifyContent="center"
          align="center"
          height="full"
        >
          <EmptyStatePrompt>How can I help you?</EmptyStatePrompt>
        </Flex>
      ) : (
        <VStack
          spacing={4}
          align="stretch"
          overflowY="auto"
          p={3}
          flex="1"
          ref={vStackRef}
        >
          {messages.map((msg, index) => (
            <Flex
              key={index}
              justify={msg.fromUser ? 'flex-end' : 'flex-start'}
            >
              <Box
                p={3}
                borderWidth="1px"
                borderRadius="lg"
                bg={msg.fromUser ? 'gray.50' : 'purple.50'}
                ml={msg.fromUser ? '9' : '1'}
                mr={msg.fromUser ? '1' : '9'}
              >
                {!msg.fromUser && (
                  <HStack spacing={2}>
                    <Avatar size="xs" src={logo} name="Assistant" />
                    <Text fontSize="xs" mb={1} fontWeight="bold">
                      {msg.name}
                    </Text>
                  </HStack>
                )}
                {msg.fromUser && (
                  <Text fontSize="xs" mb={1} align="right" fontWeight="bold">
                    {msg.name}
                  </Text>
                )}
                <Text fontSize="sm" align="left" mb={0}>
                  <Box ref={markdownRef}>
                  <ReactMarkdown
                      children={msg.message}
                      components={{
                        p: ({ ...props }) => (
                          <Text fontSize="sm" textAlign="left" {...props} />
                        ),
                        ul: ({ ...props }) => (
                          <UnorderedList
                            textAlign="left"
                            styleType="disc"
                            ml="4"
                            {...props}
                          />
                        ),
                        ol: ({ ...props }) => (
                          <OrderedList
                            textAlign="left"
                            styleType="decimal"
                            ml="4"
                            {...props}
                          />
                        ),
                        li: ({ ...props }) => (
                          <ListItem textAlign="left" fontSize="sm" {...props} />
                        ),
                        h1: ({ ...props }) => (
                          <Text
                            size="md"
                            textAlign="left"
                            fontWeight="800"
                            mb={3}
                            {...props}
                          />
                        ),
                        h2: ({ ...props }) => (
                          <Text
                            size="md"
                            textAlign="left"
                            fontWeight="bold"
                            mb={3}
                            {...props}
                          />
                        ),
                        h3: ({ ...props }) => (
                          <Text
                            size="md"
                            textAlign="left"
                            fontWeight="bold"
                            {...props}
                          />
                        ),
                        h4: ({ ...props }) => (
                          <Text
                            size="sm"
                            textAlign="left"
                            fontWeight="bold"
                            {...props}
                          />
                        ),
                        // Add other overrides as needed
                      }}
                    />
                    {!msg.fromUser ? (
                      <CopyButton markdownRef={markdownRef}></CopyButton>
                    ) : (
                      ''
                    )}
                  </Box>
                </Text>
              </Box>
            </Flex>
          ))}

          {loader && (
            <Stack maxWidth="90%">
              <Skeleton height="20px" />
              <Skeleton height="20px" />
              <Skeleton height="20px" />
            </Stack>
          )}
        </VStack>
      )}
      <Box
        as="form"
        pos="relative"
        bottom="0"
        width="full"
        p={3}
        bg="white"
        boxShadow="md"
        onSubmit={handleSendMessage}
      >
        <HStack>
          <ChatTextArea
            id="anko-textarea"
            rows={1}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <Button
            size="sm"
            type="submit"
            color="black"
            bg="purple.100"
            _hover={{ bg: '#4e1e6c', color: 'white' }}
          >
            <FiSend />
          </Button>
        </HStack>
      </Box>
    </Flex>
  )
}
