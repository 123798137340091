// Define the possible frequency options as a union type
export type Frequency = "Daily" | "Weekly" | "Monthly" | "Quarterly" | "Yearly" | "";

export enum TimeBucket {
  Monthly = "Monthly",
  Quarterly = "Quarterly",
  Yearly = "Yearly",
}

export enum CustomPeriod {
  PreviousPeriod = "pp",
  SamePeriodLastYear = "sppy",
}

export enum CustomBenchmark {
  PreviousPeriod = "Previous period",
  SamePeriodLastYear = "Same period last year",
}

export enum MetricEdgeType {
  UNKNOWN_EDGE_TYPE = "UNKNOWN_EDGE_TYPE",
  ATTRIBUTE_EDGE = "ATTRIBUTE_EDGE",
  METRIC_EDGE = "METRIC_EDGE",
  BENCHMARK_EDGE = "BENCHMARK_EDGE",
  FUNDAMENTAL_EDGE = "FUNDAMENTAL_EDGE",
}

export enum AnalysisType {
  METRIC_VALUE_ANALYSIS = "METRIC_VALUE_ANALYSIS",
  BENCHMARK_ANALYSIS = "BENCHMARK_ANALYSIS",
  DRIVER_ANALYSIS = "DRIVER_ANALYSIS",
  CORRELATION_ANALYSIS = "CORRELATION_ANALYSIS",
}

export enum ColumnType {
  ATTRIBUTE = "ATTRIBUTE",
  MEASURE = "MEASURE",
  TEMPORAL = "TEMPORAL"
}

export enum FilterOperator {
  NO_FILTER_OP = "NO_FILTER_OP",
  EQ = "EQ",
  NE = "NE",
  GT = "GT",
  GTE = "GTE",
  LT = "LT",
  LTE = "LTE"
}

export enum AnalysisTreeRequestType {
  INVALID_TREE_REQUEST = "INVALID_TREE_REQUEST",
  GENERATE_TREE = "GENERATE_TREE",
  LOAD_TREE = "LOAD_TREE",
  GET_TREE = "GET_TREE",
  SAVE_TREE = "SAVE_TREE",
  GENERATE_SUB_TREE = "GENERATE_SUB_TREE",
  GET_ANALYSIS_NODE = "GET_ANALYSIS_NODE",
  GENERATE_COMMENTARY = "GENERATE_COMMENTARY",
  SAVE_COMMENTARY = "SAVE_COMMENTARY",
  DELETE_COMMENTARY = "DELETE_COMMENTARY",
  UPDATE_TREE_HEADER = "UPDATE_TREE_HEADER"
}

export enum StatefulRequestType {
  INVALID_REQUEST_TYPE = "INVALID_REQUEST_TYPE",
  OBSERVATION_REQUEST = "OBSERVATION_REQUEST",
  ANALYSIS_TREE_REQUEST = "ANALYSIS_TREE_REQUEST"
}

export enum ExecutionStatus {
  PENDING = "PENDING",
  IN_PROGRESS = "IN_PROGRESS",
  SUCCESS = "SUCCESS",
  FAILURE = "FAILURE"
}

export enum ActionType {
  INVALID_ACTION = "INVALID_ACTION",
  GENERATE_COMMENTARY = "GENERATE_COMMENTARY",
  ASSIGN_TO_PAGE = "ASSIGN_TO_PAGE",
  NOTIFY_SLACK = "NOTIFY_SLACK",
}

export enum StepType {
  INVALID_STEP = "INVALID_STEP",
  ANALYSIS_STEP = "ANALYSIS_STEP",
  ACTION_STEP = "ACTION_STEP",
}

export enum DiagnosticStatus {
  NO_DIAGNOSIS = "NO_DIAGNOSIS",
  IGNORE = "IGNORE",
  BROKEN = "BROKEN",
  HEALTHY = "HEALTHY",
  THRIVING = "THRIVING"
}

// Type of analysis columns.
export enum AnalysisColumnType {
  NOT_ANALYSIS_COLUMN = "NOT_ANALYSIS_COLUMN",
  COMPOSITION = "COMPOSITION",
  VARIANCE = "VARIANCE",
  VARIANCE_PERCENTAGE = "VARIANCE_PERCENTAGE",
  VARIANCE_CONTRIBUTION = "VARIANCE_CONTRIBUTION",
  MELTED_VARIABLE = "MELTED_VARIABLE",
  MELTED_VALUE = "MELTED_VALUE",
  LAST_N_PERIOD_AVERAGE = "LAST_N_PERIOD_AVERAGE",
  DIAGNOSTIC_STATUS = "DIAGNOSTIC_STATUS",
}