import { useToast } from "@chakra-ui/react";
import React, { useState, useEffect, useRef } from "react";
import { useCommentaryContext } from "../../context/CommentaryContext";
import { fetchDownloadPrompt } from "../../services/ConversationServices";
import { useURLContext } from "../../context/URLContext";
import { useUser } from "../../context/UserContext";
import { Commentary } from "../commentary/Interfaces";
import CommentaryPanel from "../../components/analyse/CommentaryPanel";
import { statefulAnalysisTree } from "../../services/AnalysisTreeServices";
import { StatefulRequestBuilder } from "../stateful/StatefulRequests";

interface CommentaryPanelContainerProps {
  treeId: string;
}

const CommentaryPanelContainer: React.FC<CommentaryPanelContainerProps> = ({
  treeId,
}) => {
  const { isAdmin } = useUser();
  const { debug } = useURLContext();
  const { fetchCommentary, 
    commentaryArray, 
    loadingCommentary, 
    updateCommentaryAtIndex,
    handleSelectCommentary,
    selectedCommentaryIndex,
    transactionId
  } = useCommentaryContext();

  const [editStates, setEditStates] = useState<boolean[]>([]);
  const [editTexts, setEditTexts] = useState<string[]>([]);
  const [heights, setHeights] = useState<number[]>([]);
  const refs = useRef<(HTMLDivElement | null)[]>([]);
  const toast = useToast();

  useEffect(() => {
    setEditStates(new Array(commentaryArray.length).fill(false));
    setEditTexts(
      commentaryArray.map((item) => item.commentary?.commentary || "")
    );
    setHeights(new Array(commentaryArray.length).fill(0));
  }, [commentaryArray]);

  useEffect(() => {
    refs.current.forEach((ref, index) => {
      if (ref) {
        setHeights((prev) => {
          const newHeights = [...prev];
          newHeights[index] = ref.offsetHeight;
          return newHeights;
        });
      }
    });
  }, [commentaryArray, editStates]);

  // Using navigator.clipboard.writeText(text) API causes issues.
  // Check if clipboard API is available; if not, use standard copy functionality.
  const handleCopy = (text: string) => {
    // Function to handle fallback copying using a temporary text area
    const copyTextToClipboard = (text: string) => {
      const textArea = document.createElement('textarea');
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
  
      try {
        const successful = document.execCommand('copy');
        const msg = successful ? 'successful' : 'unsuccessful';
        toast({
          title: "Copied",
          description: "Commentary copied to clipboard",
          status: "success",
          duration: 2000,
          isClosable: true,
          position: "bottom-right"
        });
      } catch (err) {
        toast({
          title: "Error",
          description: "Failed to copy text.",
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "bottom-right"
        });
      }
  
      document.body.removeChild(textArea);
    };
  
    // Use the Clipboard API if it's available
    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard.writeText(text)
        .then(() => {
          toast({
            title: "Copied",
            description: "Commentary copied to clipboard",
            status: "success",
            duration: 2000,
            isClosable: true,
            position: "bottom-right"
          });
        })
        .catch((err) => {
          copyTextToClipboard(text);
        });
    } else {
      // Fallback if Clipboard API is not available
      copyTextToClipboard(text);
    }
  };  

  //handle toggling edit/no-edit commentary
  const toggleEdit = async(index: number, commentary: Commentary, text: string) => {
    setEditStates((prev) => {
      const newEditStates = [...prev];
      newEditStates[index] = !newEditStates[index];
      return newEditStates;
    });
    
    // Then, asynchronously check and update the isSaved status if needed
    if (text !== commentaryArray[index].commentary?.commentary) {
      const updatedCommentary: Commentary = {
        ...commentary,
        commentary: text,
      };
      updateCommentaryAtIndex(index, updatedCommentary, true);

      const requestBuilder = new StatefulRequestBuilder(transactionId);
      const updateCommentary = requestBuilder.SaveCommentaryRequest(text, commentary.conversation_header.id!);

      // const response = await saveCommentary(treeId, updatedCommentary);
      const response = await statefulAnalysisTree(updateCommentary);
      if (response) {
        toast({
          title: "Saved",
          description: "Commentary saved",
          status: "success",
          duration: 2000,
          isClosable: true,
          position: "bottom-right"
        });
      } else {
        toast({
          title: "Operation failed",
          description: "Unable to save commentary.",
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "bottom-right"
        });
      }
    }
  };

  //handles edited commentary
  const handleTextChange = (index: number, text: string) => {
    // First, update the editTexts for immediate UI response
    setEditTexts((prevEditTexts) => {
      const newEditTexts = [...prevEditTexts];
      newEditTexts[index] = text;
      return newEditTexts;
    });
  };
  
  const handleGenerateCommentary = async () => {
    await fetchCommentary();
  };

  const handleDownloadPrompt = async (conversationId: string) => {
    await fetchDownloadPrompt(conversationId);
  };

  const handleSaveOrDeleteCommentary = async (commentary: Commentary, index: number, isSaved: boolean) => {
    if (isSaved) {
      
      const requestBuilder = new StatefulRequestBuilder(transactionId);
      const deleteCommentary = requestBuilder.DeleteCommentaryRequest(commentary);

      // Handle delete commentary
      const response = await statefulAnalysisTree(deleteCommentary);
      if (response) {
        toast({
          title: "Deleted",
          description: "Commentary deleted",
          status: "success",
          duration: 2000,
          isClosable: true,
          position: "bottom-right",
        });
        updateCommentaryAtIndex(index, commentary, false); // You can update the state to reflect deletion
      } else {
        toast({
          title: "Operation failed",
          description: "Unable to delete commentary.",
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "bottom-right",
        });
      }
    } else {
      // Handle save commentary
      let updatedCommentary: Commentary = {
        ...commentary,
        commentary: editTexts[index],
      };
      
      const requestBuilder = new StatefulRequestBuilder(transactionId);
      const saveCommetaryRequest = requestBuilder.SaveCommentaryRequest(commentary.commentary, commentary.conversation_header.id!);
      const response = await statefulAnalysisTree(saveCommetaryRequest);
      
      if (response) {
        toast({
          title: "Saved",
          description: "Commentary saved",
          status: "success",
          duration: 2000,
          isClosable: true,
          position: "bottom-right",
        });
        updateCommentaryAtIndex(index, updatedCommentary, true);
      } else {
        toast({
          title: "Operation failed",
          description: "Unable to save commentary.",
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "bottom-right",
        });
      }
    }
  };
  
  return (
    <CommentaryPanel
      refs={refs}
      commentaryArray={commentaryArray}
      loadingCommentary={loadingCommentary}
      selectedCommentaryIndex={selectedCommentaryIndex}
      editTexts={editTexts}
      editStates={editStates}
      heights={heights}
      isAdmin={isAdmin}
      debug={debug}
      handleCopy={handleCopy}
      toggleEdit={toggleEdit}
      handleTextChange={handleTextChange}
      handleSaveOrDeleteCommentary={handleSaveOrDeleteCommentary}
      handleSelectCommentary={handleSelectCommentary}
      handleDownloadPrompt={handleDownloadPrompt}
      handleGenerateCommentary={handleGenerateCommentary}
    />
  );
};

export default CommentaryPanelContainer;