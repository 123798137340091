import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  registerables,
} from 'chart.js';
import {
  ColumnValuesList,
  Node,
} from "./../../containers/commentary/Interfaces";

// Register the necessary components for the chart
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface DataChartProps {
  data: Node; // Assuming this Node contains the relevant data structure
}

const DataChart: React.FC<DataChartProps> = ({ data }) => {

  console.log("DataTable", data);
  const columnValuesMap: Map<string, ColumnValuesList> = new Map();

  // Check if data contains analysed_data
  if (data && data.analysed_data) {
    // Iterate over analysed_data.column_values_list array
    data.analysed_data.column_values_list.forEach((columnValues) => {
      // Use column_id as the key and the whole ColumnValuesList object as the value
      if (
        columnValues.analysis_column_type !== "VARIANCE_CONTRIBUTION" &&
        columnValues.analysis_column_type !== "VARIANCE CONTRIBUTION"
      ) {
        columnValuesMap.set(columnValues.column_id, columnValues);
      }
    });

    // Check if data contains benchmark
    if (data && data.benchmark && data.benchmark.header) {
      const benchmarkHeaderId = data.benchmark.header.id!;
      const benchmarkHeaderName = data.benchmark.header.name;

      // Check if benchmark header ID is present in columnValuesMap
      if (columnValuesMap.has(benchmarkHeaderId)) {
        // Get the corresponding ColumnValuesList object
        const columnValues = columnValuesMap.get(benchmarkHeaderId)!;

        // Check if analysis_column_type is present
        if (columnValues.analysis_column_type) {
          // Replace the value with benchmark header name
          columnValues.analysis_column_type = benchmarkHeaderName;
        } else {
          // Add analysis_column_type with benchmark header name
          columnValues.analysis_column_type = benchmarkHeaderName;
        }
      }
    }
    if (
      data &&
      data.analysed_data.observation &&
      data.analysed_data.observation.content
    ) {
      const observationColumns =
        data.analysed_data.observation.content.observation_columns;

      // Iterate over observation columns
      observationColumns.forEach((observationColumn) => {
        const observationColumnId = observationColumn.column_id;
        const logicalColumnHeaderName =
          observationColumn.logical_column_header.name;

        // Check if observation column ID is present in columnValuesMap
        if (columnValuesMap.has(observationColumnId)) {
          // Get the corresponding ColumnValuesList object
          const columnValues = columnValuesMap.get(observationColumnId)!;

          // Check if analysis_column_type is present
          if (columnValues.analysis_column_type) {
            // Replace the value with observation column header name
            columnValues.analysis_column_type = logicalColumnHeaderName;
          } else {
            // Add analysis_column_type with observation column header name
            columnValues.analysis_column_type = logicalColumnHeaderName;
          }
        }
      });
    }
    columnValuesMap &&
      columnValuesMap.forEach((columnValues) => {
        // Check if analysis_column_type exists and remove underscores
        if (columnValues.analysis_column_type) {
          columnValues.analysis_column_type = columnValues.analysis_column_type
            .replace(/_/g, " ")
            .toUpperCase();
        }
        if (columnValues.analysis_column_type === "MELTED VARIABLE") {
          columnValues.analysis_column_type = "VARIABLE";
        } else if (columnValues.analysis_column_type === "MELTED VALUE") {
          columnValues.analysis_column_type = "VALUE";
        }

        // Check if analysis_column_type is not VARIABLE
        // Parse the data_value_json_array
        const parsedData = JSON.parse(columnValues.data_value_json_array);

        // Apply transformation to each value
        columnValues.parsed_data_value_json_array = parsedData.map(
          (value: number) => {
            return value;
          }
        );
      });
  }

 console.log(columnValuesMap);

  const transposedData: any[][] = [];
  if (data && data.analysed_data?.column_values_list) {
    data.analysed_data.column_values_list.forEach((columnValues) => {
      if (
        columnValues.analysis_column_type !== "VARIANCE CONTRIBUTION" &&
        columnValues.analysis_column_type !== "VARIANCE_CONTRIBUTION"
      ) {
        columnValues &&
          columnValues.parsed_data_value_json_array?.forEach(
            (item: any, index: number) => {
              if (!transposedData[index]) {
                transposedData[index] = [];
              }
              transposedData[index].push(item);
            }
          );
      }
    });
  }

console.log(transposedData);

// Variables to store chart data
let labels = [];
let actualsData = [];
let forecastsData = [];

// Check the structure of the data
if (transposedData.length === 1) {
    if (transposedData[0].length === 1) {
        // Only one value: no labels, just actuals (could treat as special info)
        labels = ['Summary'];
        actualsData = transposedData[0];
        forecastsData = []; // No forecasts data
    } else if (transposedData[0].length === 3) {
        // One data set with actuals and forecasts
        labels = ['Data Point']; // Since no specific label is provided
        actualsData = [transposedData[0][0]];
        forecastsData = [transposedData[0][1]];
    }
} else {
    // Multiple rows, assume format is consistent as Label, Actuals, Forecasts
    labels = transposedData.map(row => row[0]);
    actualsData = transposedData.map(row => row[1]);
    forecastsData = transposedData.map(row => row.length > 2 ? row[2] : null); // Handle cases where forecasts might not be provided
}


  const chartData = {
    labels: labels,
    datasets: [
      {
        label: 'Actuals',
        data: actualsData,
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: 'Forecasts',
        data: forecastsData,
        backgroundColor: 'rgba(54, 162, 235, 0.5)',
      }
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: 'Actuals vs Forecasts',
      },
    },
    scales: {
      y: {
        beginAtZero: true
      }
    }
  };

  return (
    <Box p={3} pb={0} bg="white">
      <Text fontWeight="bold">Data Visualization</Text>
      <Bar data={chartData} options={options} />
    </Box>
  );
};

export default DataChart;