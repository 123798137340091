import { HStack, Icon, Text } from '@chakra-ui/react'

interface Props {
  icon: React.ElementType
  children: React.ReactNode
}

/**
 * Compnent responsible for the providing a header to all analysis 
 */
export const ChatGroupHeader = (props: Props) => {
  const { icon, children } = props
  return (
    <HStack color="fg.muted">
      <Icon as={icon} />
      <Text fontWeight="semibold" textTransform="uppercase" fontSize="xs" mb={0} align="left">
        {children}
      </Text>
    </HStack>
  )
}