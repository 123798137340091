import React, { useEffect, useState } from "react";
import { Box } from "@chakra-ui/react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import {
  ColumnValuesList,
  NodeInsights,
} from "./../../containers/commentary/Interfaces";

// Register the necessary components for the chart
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface DataChartProps {
  data: NodeInsights; // Assuming this Node contains the relevant data structure
}

const DataChart: React.FC<DataChartProps> = ({ data }) => {
  const [columnValuesMap, setColumnValuesMap] = useState<
    Map<number, ColumnValuesList>
  >(new Map());

  const observation_columns =
    data?.current_node.analysed_data?.observation.content.observation_columns;
  const column_values_list =
    data?.current_node.analysed_data?.column_values_list;

  useEffect(() => {
    const newColumnValuesMap = new Map<number, ColumnValuesList>();

    if (column_values_list) {
      column_values_list.forEach((columnValues, index) => {
        if (columnValues.data_value_json_array) {
          const jsonString = columnValues.data_value_json_array.replace(/NaN/g, '"_NaN_"');
        
          // Parse the JSON string
          let parsedData = JSON.parse(jsonString);

          // Replace the placeholder string with NaN
          parsedData = parsedData.map((value: any) => value === "_NaN_" ? NaN : value);

          columnValues.parsed_data_value_json_array = parsedData;
        }
        if (columnValues.analysis_column_type) {
          if (columnValues.analysis_column_type === "MELTED_VARIABLE") {
            columnValues.analysis_column_type = "VARIABLE";
          } else if (columnValues.analysis_column_type === "MELTED_VALUE") {
            columnValues.analysis_column_type = "VALUE";
          } else {
            columnValues.analysis_column_type =
              columnValues.analysis_column_type
                .replace(/_/g, " ")
                .toUpperCase();
          }
        }
        // if(!columnValues.analysis_column_type){
        // If analysis_column_type is not present, check in observation_columns
        const observationColumn = observation_columns?.find(
          (col) => col.column_id === columnValues.column_id
        );
        if (observationColumn) {
          columnValues.analysis_column_type =
            observationColumn.logical_column_header.name?.toUpperCase();
        } else if (data.current_node && data.current_node.benchmark) {
          // If not found in observation_columns, check in benchmark
          const benchmarkHeader = data.current_node.benchmark.header;
          if (columnValues.column_id === benchmarkHeader.id) {
            columnValues.analysis_column_type =
              benchmarkHeader.name?.toUpperCase();
          }
        }
        // }
        newColumnValuesMap.set(index, columnValues);
      });
    }

    setColumnValuesMap(newColumnValuesMap);
  }, [data, column_values_list, observation_columns]);

  const transposedData: any[][] = [];
  columnValuesMap.forEach((columnValues) => {
    columnValues.parsed_data_value_json_array?.forEach((item, index) => {
      if (!transposedData[index]) {
        transposedData[index] = [];
      }
      transposedData[index].push(item);
    });
  });

  // Variables to store chart data
  let labels = [];
  let actualsData = [];
  let forecastsData = [];

  if (transposedData && transposedData.length > 0 && transposedData[0]) {
    if (transposedData.length === 1) {
      if (transposedData[0].length === 1) {
        // Only one value: no labels, just actuals (could treat as special info)
        labels = ["Summary"];
        actualsData = transposedData[0];
        forecastsData = []; // No forecasts data
      } else if (transposedData[0].length >= 3) {
        // Determine if the first index is a string
        if (typeof transposedData[0][0] === "string") {
          if (transposedData[0].length === 7) {
            // If the length is 7, actuals at index 1 and forecasts at index 3
            labels = ["Data Point"];
            actualsData = [transposedData[0][1]];
            forecastsData = [transposedData[0][3]];
          } else if (transposedData[0].length === 6) {
            // If the length is 6, actuals at index 1 and forecasts at index 2
            labels = ["Data Point"];
            actualsData = [transposedData[0][1]];
            forecastsData = [transposedData[0][2]];
          } else {
            // Default case for other lengths
            labels = ["Data Point"]; // Since no specific label is provided
            actualsData = [transposedData[0][1]];
            forecastsData = [transposedData[0][2]];
          }
        } else {
          // Data at index 0 and 1 when the first index is not a string
          labels = ["Data Point"];
          actualsData = [transposedData[0][0]];
          forecastsData = [transposedData[0][1]];
        }
      }
    } else {
      // Multiple rows, assume format is consistent as Label, Actuals, Forecasts
      if (typeof transposedData[0][0] === "string") {
        if (transposedData[0].length === 2) {
          // If there are only labels and actuals, no forecasts
          labels = transposedData.map((row) => row[0]);
          actualsData = transposedData.map((row) => row[1]);
          forecastsData = []; // No forecasts data
        } else if (transposedData[0].length === 7) {
          // If the length is 7, actuals at index 1 and forecasts at index 3
          labels = transposedData.map((row) => row[0]);
          actualsData = transposedData.map((row) => row[1]);
          forecastsData = transposedData.map((row) => row[3]);
        } else if (transposedData[0].length === 6) {
          // If the length is 6, actuals at index 1 and forecasts at index 2
          labels = transposedData.map((row) => row[0]);
          actualsData = transposedData.map((row) => row[1]);
          forecastsData = transposedData.map((row) => row[2]);
        } else if (transposedData[0].length >= 3) {
          // If there are labels, actuals, and forecasts
          labels = transposedData.map((row) => row[0]);
          actualsData = transposedData.map((row) => row[1]);
          forecastsData = transposedData.map((row) =>
            row.length > 2 ? row[2] : null
          ); // Handle cases where forecasts might not be provided
        } else {
          // Handle unexpected cases, e.g., where rows have less than 2 columns
          console.error("Unexpected data format");
          labels = [];
          actualsData = [];
          forecastsData = [];
        }
      } else {
        // Handle cases where the first index is not a string
        labels = transposedData.map((_, index) => `Data Point ${index + 1}`);
        actualsData = transposedData.map((row) => row[0]);
        forecastsData = transposedData.map((row) => row[1]);
      }
    }
  } else {
    // Handle the case where transposedData is empty or not properly structured
    console.error("No data available or improper structure");
    labels = [];
    actualsData = [];
    forecastsData = [];
  }
  
  

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "Actuals",
        data: actualsData,
        backgroundColor: "#4e1e6c",
      },
      {
        label: "Benchmark",
        data: forecastsData,
        backgroundColor: "#dfc2f0",
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
        align: "center" as const,
        labels: {
          font: {
            size: 12,
            weight: "bold" as const, // Specify 'bold' to make the legend labels bold
          },
        },
      },
      title: {
        display: false,
        text: "Variance Analysis",
        align: "start" as const,
        font: {
          size: 12, // Increase title font size
          weight: "bold" as const, // Make title bold
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          color: "#333333", // Apply brand color to X-axis labels
          font: {
            weight: "normal" as const, // Make X-axis labels bold
          },
        },
      },
      x: {
        ticks: {
          color: "#333333", // Apply brand color to X-axis labels
          font: {
            weight: "bold" as const, // Make X-axis labels bold
          },
        },
        grid: {
          display: false, // Optionally remove X-axis grid lines
        },
      },
    },
  };

  return (
    <Box p={2} h="100%">
      <Bar data={chartData} options={options} />
    </Box>
  );
};

export default DataChart;
