import React, { useState, useEffect } from "react";
import {
  BenchmarkEdge,
  ColumnInfo,
  Correlations,
  FormData,
  FundamentalEdge,
  FundamentalRelationship,
  Header,
  Metric,
  RelatedAttributeEdge,
} from "../../Interfaces";
import { listMetrics, updateMetric } from "../../../services/MetricService";
import { ColumnType, MetricEdgeType } from "../../../utils/enum";
import { BenchmarkFilter } from "../../commentary/Interfaces";
import CreateMKG from "../../../components/configuration/snowflake/CreateMKG";
import { getColumnValues } from "../../../services/DataModelService";
import showToast from "../../../hooks/useCustomToast";

interface CreateMKGContainerProps {
  metricInfo: FormData | null; // Details of the existing metric (if any)
  columns: ColumnInfo[] | null; // Array of available columns for related attributes
}

const CreateMKGContainer: React.FC<CreateMKGContainerProps> = ({
  metricInfo,
  columns,
}) => {

  const [measureColumn, setMesureColumn] = useState<Header[] | null>(null);
  const [attributeColumn, setAttributeColumn] = useState<Header[] | null>(null);
  const [allMetrics, setAllMetrics] = useState<Header[] | null>(null);

  //related attribute
  const [relatedAttributeEdge, setRelatedAttributeEdge] = useState<
    RelatedAttributeEdge[] | null
  >(null);
  const [selectedRelatedAttributeColumn, setSelectedRelatedAttributeColumn] =
    useState<Header | null>(null);
  const [relatedAttributeEdgeWeight, setRelatedAttributeEdgeWeight] =
    useState<number>(0);

  //benchmark
  const [benchmarkEdge, setBenchmarkEdge] = useState<BenchmarkEdge[]>([]);
  const [benchmarkFilter, setBenchmarkFilter] = useState<
    BenchmarkFilter[] | null
  >(null);
  const [benchmarkName, setBenchmarkName] = useState<string>("");
  const [benchmarkVarienceTolerance, setBenchmarkVarienceTolerance] = useState<
    number | undefined
  >(undefined);
  const [benchmarkFilterValue, setBenchmarkFilterValue] =
    useState<string>("");
  const [selectedBenchmarkFilterColumn, setSelectedBenchmarkFilterColumn] =
    useState<ColumnInfo | null>(null);
  const [benchmarkEdgeWeight, setBenchmarkEdgeWeight] = useState<number>(0);
  const [columnNames, setColumnNames] = useState<string[]>([]);
  const [selectedColumnValues, setSelectedColumnValues] = useState<string[]>([]);

  //fundamental relationship
  const [fundamentalEdgeName, setFundamentalEdgeName] = useState<string>("");
  const [fundamentalEdgeWeight, setFundamentalEdgeWeight] = useState<number>(0);
  const [selectedType, setSelectedType] = useState<string>("Column");
  const [selectedHeader, setSelectedHeader] = useState<string>("");
  const [correlationFactor, setCorrelationFactor] = useState<string | "">("");
  const [correlations, setCorrelations] = useState<Correlations[]>([]);
  const [fundamentalEdges, setFundamentalEdges] = useState<FundamentalEdge[]>([]);
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState<boolean>(false);
  const [isCorrelationMenuOpen, setIsCorrelationMenuOpen] = useState<boolean>(false);
  const [isAdditive, setIsAdditive] = useState<boolean>(false);
  
  // applying filters to extract Measure and attribute columns
  useEffect(() => {
    if (columns) {
      const measureColumns = columns.filter(
        (column) => column.column_type === "MEASURE"
      );
      const attributeColumns = columns.filter(
        (column) => column.column_type === "ATTRIBUTE"
      );
      setMesureColumn(measureColumns.map((column) => column.header!));
      setAttributeColumn(attributeColumns.map((column) => column.header!));
    }
    if (
      metricInfo &&
      metricInfo.metrics[0] &&
      metricInfo.metrics[0].definition
    ) {
      const { filters, observation_columns } = metricInfo.metrics[0].definition;

      // Remove matching items from attributeColumns based on filters
      if (filters) {
        const filterIds = filters.map(
          (filter) => filter.logical_column_header.id
        );
        setAttributeColumn(
          (prevAttributeColumns) =>
            prevAttributeColumns &&
            prevAttributeColumns.filter(
              (column) => !filterIds.includes(column.id)
            )
        );
      }

      // Remove matching items from attributeColumns based on observation_columns
      if (observation_columns) {
        const observationColumnIds = observation_columns.map(
          (column) => column.logical_column_header.id
        );
        setAttributeColumn(
          (prevAttributeColumns) =>
            prevAttributeColumns &&
            prevAttributeColumns.filter(
              (column) => !observationColumnIds.includes(column.id)
            )
        );
      }
    }
  }, [columns, metricInfo]);
  
  //this will load the state while edit MKG(like if there was edges before then it will load and then it made editable)
  useEffect(() => {
    if (metricInfo && metricInfo.metrics[0] && metricInfo.metrics[0].edges) {
      const attributeEdges: RelatedAttributeEdge[] = metricInfo.metrics[0].edges
        .filter((edge) => edge.edge_type === MetricEdgeType.ATTRIBUTE_EDGE)
        .map((edge) => ({
          edge_weight: edge.edge_weight!,
          related_attribute: edge.related_attribute!,
        }));
      setRelatedAttributeEdge(attributeEdges);
    }
    if (metricInfo && metricInfo.metrics[0] && metricInfo.metrics[0].edges) {
      const benchmarkEdges: BenchmarkEdge[] = metricInfo.metrics[0].edges
        .filter((edge) => edge.edge_type === MetricEdgeType.BENCHMARK_EDGE)
        .map((edge) => ({
          name: edge.benchmark?.header?.name,
          varience_tolerance: edge.benchmark?.variance_tolerance,
          edge_type: edge.edge_type,
          edge_weight: edge.edge_weight,
          benchmark_filters: edge.benchmark?.benchmark_filters,
        }));
      setBenchmarkEdge(benchmarkEdges);
    }
    if (metricInfo && metricInfo.metrics[0] && metricInfo.metrics[0].edges) {
      const fundaEdge: FundamentalEdge[] = metricInfo.metrics[0].edges
        .filter((edge) => edge.edge_type === MetricEdgeType.FUNDAMENTAL_EDGE)
        .map((edge) => ({
          edge_weight: edge.edge_weight,
          edge_type: edge.edge_type,
          fundamental_relationship:{ 
            correlations:edge.fundamental_relationship?.correlations || [], 
            is_additive:edge.fundamental_relationship?.is_additive || false ,
            name:edge.fundamental_relationship?.header?.name || "",
            header:edge.fundamental_relationship?.header
          }
        }));
      setFundamentalEdges(fundaEdge);
    }
  }, [metricInfo]);

  //fetch all metric
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await listMetrics(""/* modelId */);
        setAllMetrics(data);
      } catch (error) {
        alert("Error fetching data");
      }
    };
    fetchData();
  }, []);

  const toggleCorrelationMenu = () => setIsCorrelationMenuOpen(!isCorrelationMenuOpen);
  const toggleFilterMenu = () => setIsFilterMenuOpen(!isFilterMenuOpen);
  const handleAddRelatedAttributeEdge = () => {
    if (selectedRelatedAttributeColumn) {
      const newEdge: RelatedAttributeEdge = {
        edge_weight: relatedAttributeEdgeWeight,
        related_attribute: selectedRelatedAttributeColumn,
      };
      // Retain previous edges and add the new edge
      setRelatedAttributeEdge((prev) =>
        prev ? [...prev, newEdge] : [newEdge]
      );
      setSelectedRelatedAttributeColumn(null);
      setRelatedAttributeEdgeWeight(0);
    }
  };
  const handleRemoveRelatedAttribute = (id: string) => {
    setRelatedAttributeEdge(
      (prev) =>
        prev && prev.filter((option) => option.related_attribute.id !== id)
    );
  };
  const handleAddBenchmarkFilter = () => {
    console.log("benchamarkFilterValue", benchmarkFilterValue);
    console.log("selectedColumnValues", selectedColumnValues);
    if (benchmarkName && (benchmarkFilterValue || selectedColumnValues)) {
      const newFilter: BenchmarkFilter = {
        logical_column_header: selectedBenchmarkFilterColumn?.header!,
        // operator: selectedOperator,
        operator: "EQ",
        values: selectedColumnValues.length > 0? selectedColumnValues: [benchmarkFilterValue],
      };
      setBenchmarkFilter((prevFilters) =>
        prevFilters ? [...prevFilters, newFilter] : [newFilter]
      );
      setSelectedBenchmarkFilterColumn(null);
      //   setSelectedOperator("");
      setSelectedColumnValues([]);
      setBenchmarkFilterValue("");
    } else {
      alert("Please fill all the values.");
    }
  };
  const handleAddBenchmark = () => {
    if (
      benchmarkName &&
      benchmarkVarienceTolerance !== undefined &&
      benchmarkEdgeWeight !== undefined
    ) {
      const newBenchmark: BenchmarkEdge = {
        name: benchmarkName,
        varience_tolerance: benchmarkVarienceTolerance,
        edge_weight: benchmarkEdgeWeight,
        benchmark_filters: benchmarkFilter!,
      };

      setBenchmarkEdge((prevForms) => [...prevForms, newBenchmark]);

      setBenchmarkName("");
      setBenchmarkVarienceTolerance(0);
      setBenchmarkEdgeWeight(0);
      setBenchmarkFilter([]);
      setSelectedColumnValues([]);
      setBenchmarkFilterValue("");
    } else {
      alert("Please fill in all benchmark details.");
    }
  };
  const handleRemoveBenchmarkFilter = (id: String) => {
    setBenchmarkFilter(
      (prevFilters) =>
        prevFilters &&
        prevFilters.filter((filter) => filter.logical_column_header.id !== id)
    );
  };
  const handleRemoveBenchmarkEdge = (index: number) => {
    const updatedBenchmarkForms = [...benchmarkEdge];
    updatedBenchmarkForms.splice(index, 1);
    setBenchmarkEdge(updatedBenchmarkForms);
  };
  const handleAddCorrelation = () => {
    const headerList = selectedType === "Column" ? measureColumn : allMetrics;
    const headerToAdd = headerList?.find(
      (header) => header.name === selectedHeader
    );
    if (headerToAdd) {
      const newCorrelation: Correlations = {
        correlation_factor: parseFloat(correlationFactor),
        ...(selectedType === "Column"
          ? { related_column_header: headerToAdd }
          : { related_metric_header: headerToAdd }),
      };
      setCorrelations([...correlations, newCorrelation]);
      setCorrelationFactor("");
    }
  };
  const handleRemoveCorrelation = (index: number) => {
    setCorrelations(correlations.filter((_, i) => i !== index));
  };
  const handleAddFundamentalEdges = () => {
    if (fundamentalEdgeName && fundamentalEdgeWeight && correlations) {
      const newFundamentalRelationship: FundamentalRelationship = {
        correlations: correlations,
        is_additive: isAdditive,
        name: fundamentalEdgeName,
      };
      const newFundamentalEdge: FundamentalEdge = {
        edge_weight: fundamentalEdgeWeight,
        fundamental_relationship: newFundamentalRelationship,
      };
      setFundamentalEdges((prevForms) => [...prevForms, newFundamentalEdge]);
      setFundamentalEdgeWeight(0);
      setFundamentalEdgeName("");
      setCorrelations([]);
      setIsAdditive(false);
    }
  };
  const handleRemoveFundamentalEdges = (index: number) => {
    setFundamentalEdges(fundamentalEdges.filter((_, i) => i !== index));
  };
  //function to handle select multiple values
  const handleToggle = (value: string) => {
    const currentIndex = selectedColumnValues.indexOf(value);
    let newSelected = [...selectedColumnValues];

    if (currentIndex === -1) {
      newSelected.push(value);
    } else {
      newSelected.splice(currentIndex, 1);
    }

    setSelectedColumnValues(newSelected);
  };
  const submitForm = async () => {
    try {
      const updatedMetrics = metricInfo?.metrics?.map((metric) => {
        const edgesWithRelatedAttributes =
          relatedAttributeEdge &&
          relatedAttributeEdge.map((edge, index) => ({
            edge_type: MetricEdgeType.ATTRIBUTE_EDGE,
            edge_weight: edge.edge_weight,
            related_attribute: {
              id: edge.related_attribute.id,
            },
          }));

        const edgesWithBenchmarks = benchmarkEdge.map((bench) => ({
          edge_type: MetricEdgeType.BENCHMARK_EDGE,
          edge_weight: bench.edge_weight,
          benchmark: {
            header: { name: bench.name },
            benchmark_filters: bench.benchmark_filters,
            variance_tolerance: bench.varience_tolerance,
          },
        }));
        const edgesWithCorrelations = fundamentalEdges.map(
          (fundamentalEdge) => ({
            edge_type: MetricEdgeType.FUNDAMENTAL_EDGE,
            edge_weight: fundamentalEdge.edge_weight,
            fundamental_relationship: {
              header: { name: fundamentalEdge.fundamental_relationship?.name },
              is_additive: fundamentalEdge.fundamental_relationship?.is_additive,
              correlations:
                fundamentalEdge.fundamental_relationship?.correlations || [],
            },
          })
        );

        const edges = [
          ...(edgesWithRelatedAttributes || []),
          ...(edgesWithBenchmarks || []),
          ...(edgesWithCorrelations || []),
        ];

        return {
          ...metric,
          edges,
        };
      });

      // Creating formData object to send to the createMetric API
      const formData: FormData = {
        model_id: metricInfo?.model_id || "",
        metrics: updatedMetrics as Metric[],
        update_edges: true,
      };

      //   API call to create or update the metric
      const responseData = await updateMetric(formData);
      if (responseData)
        showToast("Metric successfully created", "", "success");
    } catch (error) {
      showToast("Error creating metric", "", "error");
    }
  };

  useEffect(() => {
    if (selectedBenchmarkFilterColumn?.column_type === ColumnType.ATTRIBUTE) {
      const getFilterColumnValues = async () => {
        try {
          const response = await getColumnValues(selectedBenchmarkFilterColumn.header?.id!);
            setColumnNames(response);
        } catch (error) {
          console.error("Error fetching column values:", error);
        }
      };
      getFilterColumnValues();
    }
  }, [selectedBenchmarkFilterColumn]);


  return (
    <CreateMKG
      metricInfo={metricInfo}
      columns={columns}
      allMetrics={allMetrics}
      attributeColumn={attributeColumn}
      measureColumn={measureColumn}
      handleAddRelatedAttributeEdge={handleAddRelatedAttributeEdge}
      handleRemoveRelatedAttribute={handleRemoveRelatedAttribute}
      handleAddBenchmarkFilter={handleAddBenchmarkFilter}
      handleAddBenchmark={handleAddBenchmark}
      handleRemoveBenchmarkFilter={handleRemoveBenchmarkFilter}
      handleRemoveBenchmarkEdge={handleRemoveBenchmarkEdge}
      handleAddCorrelation={handleAddCorrelation}
      handleRemoveCorrelation={handleRemoveCorrelation}
      handleAddFundamentalEdges={handleAddFundamentalEdges}
      handleRemoveFundamentalEdges={handleRemoveFundamentalEdges}
      submitForm={submitForm}
      benchmarkEdge={benchmarkEdge}
      selectedRelatedAttributeColumn={selectedRelatedAttributeColumn}
      setSelectedRelatedAttributeColumn={setSelectedRelatedAttributeColumn}
      relatedAttributeEdge={relatedAttributeEdge}
      relatedAttributeEdgeWeight={relatedAttributeEdgeWeight}
      setRelatedAttributeEdgeWeight={setRelatedAttributeEdgeWeight}
      benchmarkName={benchmarkName}
      setBenchmarkName={setBenchmarkName}
      benchmarkVarienceTolerance={benchmarkVarienceTolerance}
      setBenchmarkVarienceTolerance={setBenchmarkVarienceTolerance}
      benchmarkFilterValue={benchmarkFilterValue}
      setBenchmarkFilterValue={setBenchmarkFilterValue}
      benchmarkEdgeWeight={benchmarkEdgeWeight}
      setBenchmarkEdgeWeight={setBenchmarkEdgeWeight}
      fundamentalEdgeName={fundamentalEdgeName}
      setFundamentalEdgeName={setFundamentalEdgeName}
      fundamentalEdgeWeight={fundamentalEdgeWeight}
      setFundamentalEdgeWeight={setFundamentalEdgeWeight}
      selectedBenchmarkFilterColumn={selectedBenchmarkFilterColumn}
      setSelectedBenchmarkFilterColumn={setSelectedBenchmarkFilterColumn}
      selectedType={selectedType}
      setSelectedType={setSelectedType}
      setSelectedHeader={setSelectedHeader}
      correlations={correlations}
      correlationFactor={correlationFactor}
      setCorrelationFactor={setCorrelationFactor}
      benchmarkFilter={benchmarkFilter}
      fundamentalEdges={fundamentalEdges}
      isAdditive={isAdditive}
      setIsAdditive={setIsAdditive}
      isCorrelationMenuOpen={isCorrelationMenuOpen}
      isFilterMenuOpen={isFilterMenuOpen}
      toggleCorrelationMenu={toggleCorrelationMenu}
      toggleFilterMenu={toggleFilterMenu}
      columnNames={columnNames}
      selectedColumnValues={selectedColumnValues}
      handleToggle={handleToggle}
    />
  );
};

export default CreateMKGContainer;
