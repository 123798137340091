import {
  AnalysisTreeResponse,
  Node,
} from "../containers/commentary/Interfaces";

interface OperatorMap {
    [key: string]: string;
}

// Function to get the analysis type
export const getAnalysisNodeName = (node: Node, tree: AnalysisTreeResponse) => {
  switch (node?.analysis_type) {
    case "METRIC_VALUE_ANALYSIS":
      return `Compute ${tree.metric.header.name}`;
    case "BENCHMARK_ANALYSIS":
      return `Compare to ${node.benchmark.header.name}`;
    case "DRIVER_ANALYSIS":
      return `Split by ${node.driver_header?.name}`;
    case "CORRELATION_ANALYSIS":
      return `Split by ${node.fundamental_correlation_header?.name}`;
    default:
      return "Unknown Analysis Type";
  }
};

// Function to get the operator symbol
export const getOperatorSymbol = (operator: string): string => {
  const operatorMap: OperatorMap = {
    EQ: "=",
    GTE: ">=",
    LTE: "<=",
  };
  return operatorMap[operator] || operator;
};

// Function to get initials from the tree name
export const getInitials = (name: string) => {

  // Remove special characters such as '(', ')', and '-' from the name
  const sanitized = name.replace(/[()\-\_]/g, '');
  const words = sanitized.split(' ');
  if (words.length >= 2) {
    return `${words[0][0]} ${words[1][0]}`; 
  } else if (words.length === 1) {
    return words[0][0]; 
  }
  return ""; 
}


// Function to get sort by label
export const getSortByLabel = (value: string) => {
  switch (value) {
    case "LAST_MODIFIED":
      return "Last Modified";
    case "CREATED":
      return "Created At";
    case "NAME":
      return "Name";
    default:
      return "";
  }
};

// Function to generate the tree name
export const generateName= (elements: string[]): string => {

  // Handle cases where elements is undefined or not an array
  if (!elements || !Array.isArray(elements) || elements.length === 0) {
    return "";
  }
  const lookupArray = ["Americas", "RoW", "Andean", "Brazil", "Turkey"];
  
  // 1. Take the metric as is
  const metric = elements[0];

  // 2. Process the time element
  const timeParts = elements[1].split(' ');
  console.log('Time Parts:', timeParts); 
  const year = timeParts[timeParts.length - 1].slice(-2);
  let time = '';
  
  if (timeParts.length === 3) {
      time = `${timeParts[0][0]}${timeParts[1][0]}${year}`;
  } else if (timeParts.length === 2) {
      time = `${timeParts[0].slice(0, 3)}${year}`;
  }
  else if (timeParts.length === 1){
    time = timeParts[0];
  }
  console.log('Processed Time:', time); 

  // 3. Process the third element
  const items = elements[2].split(',').map(item => item.trim()).filter(item => item !== '');
  console.log('Items:', items); 
  const processedItems = items.map(item => {
      if (lookupArray.includes(item)) {
          return item;
      }
      const itemParts = item.split(' ');
      return itemParts.map(part => part[0]).join('');
  });

  console.log('Processed Items:', processedItems); 

  // Combine all parts
  const result = `${metric} | ${processedItems.join('/')} | ${time}`;
  return result;
}
// Function to get the data table column name
export const getColumnHeaderName = (name: string) => {
  switch (name) {
    case "VARIANCE PERCENTAGE":
      return "VARIANCE%";
    case "VARIANCE CONTRIBUTION":
      return "CONTRIBUTION";
    default:
      return "Unknown";
  }
};

/**
 * Maps action type strings to their corresponding display names.
 * @param actionType - The action type string to map.
 * @returns The display name for the action type.
 */
export const getActionTypeDisplayName = (actionType: string | undefined): string => {
  switch (actionType) {
    case "ASSIGN_TO_PAGE":
      return "Assign to Page";
    case "NOTIFY_SLACK":
      return "Notify Slack";
    case "GENERATE_COMMENTARY":
      return "Generate Commentary";
    // Add more cases as needed for additional action types
    default:
      // Handle unknown or undefined action types
      return actionType
        ? actionType.split("_")
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(" ")
        : "Action";
  }
};