import React, { ReactElement } from "react";
import "./CSS/Layout.css";
import { LayoutProps } from "../../containers/Interfaces";
import Navbar from "../navbar/Navbar";
import { Box, Flex } from "@chakra-ui/react";

const Layout: React.FC<LayoutProps> = ({
  children, scrollable
}: LayoutProps): ReactElement => {
  return (
    <Box overflow="hidden" height="100vh">
      <Flex direction="column" height="100%">
        <Box flex="none">
          <Navbar />
        </Box>
        <Box flex="1" overflowY={scrollable ? "auto" : "hidden"}>
          {children}
        </Box>
      </Flex>
    </Box>
  );
};

export default Layout;