import React, { useEffect, useState } from "react";
import MainContent from "../../components/commentary/MainContent";
import {
  AnalysisTreeRequest,
  AnalysisTreeResponse,
} from "../../containers/commentary/Interfaces";
import { generateTrees } from "../../services/AnalysisTreeServices";
import { useAnalysisContext } from "../../context/AnalysisContext";

interface MainContentContainerProps {
  analysisTreeReq: AnalysisTreeRequest[];
}

/**
 * MainContentContainer Component
 *
 * Input:
 * - analysisTreeReq: Array of AnalysisTreeRequest objects representing analysis tree requests.
 *
 * Return:
 * Renders the MainContent component with relevant props.
 *
 * This component manages the container for the main content.
 * It handles the generation of analysis trees based on the provided requests.
 * The main functionality includes triggering the generation of analysis trees,
 * handling loading states, and updating the analysis tree state.
 *
 * @component
 */
const MainContentContainer: React.FC<MainContentContainerProps> = ({
  analysisTreeReq,
}) => {
  // State variables
  const [analysisTree, setAnalysisTree] = useState<AnalysisTreeResponse[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false)  
  // Context
  const { isLockAnalyzeButton, setLockAnalyzeButton } = useAnalysisContext();

  // Function to handle analyze button click
  const handleAnalyzeClick = async () => {
    try {
      setIsLoading(true);
      setLockAnalyzeButton(true); // Set loading state to true when API calls start
      const responses = await generateTrees(analysisTreeReq);

      responses?.forEach((data, index) => {
        console.log("data ");
        setAnalysisTree((prevAnalysisTree) => [...prevAnalysisTree, data.tree]);
        console.log(`API response for JSON at index ${index}:`, data);
      });
      console.log(`UPDATE CHECKING`);
    } catch (error) {
      console.error(
        "There was a problem with one or more API requests:",
        error
      );
    } finally {
      setIsLoading(false); // Set loading state to false when API calls finish
      setLockAnalyzeButton(false);
    }
  };

  // Effect to trigger analyze button click when analysisTreeReq changes
  useEffect(() => {
    if (analysisTreeReq && analysisTreeReq.length > 0) {
      setAnalysisTree([]);
      handleAnalyzeClick();
      setLockAnalyzeButton(true);
    }
  }, [analysisTreeReq]);

  return (
    <MainContent
      analysisTreeReq={analysisTreeReq}
      isLoading={isLoading}
      analysisTree={analysisTree}
    />
  );
};

export default MainContentContainer;
