// src/components/CustomNodeComponent.tsx

import React from 'react';
import { Handle, Position } from '@xyflow/react';
import { CheckIcon } from '@chakra-ui/icons';
import { Box, Flex, Text } from '@chakra-ui/react';

interface CustomNodeProps {
  data: {
    label: string;
    selected?: boolean; // Indicates if the node is selected
  };
}

const CustomNode: React.FC<CustomNodeProps> = ({ data }) => {
  return (
    <Box
      p={3}
      bg={data.selected ? '#e0f7fa' : '#f7e9ff'}          // Greenish background if selected, light purple otherwise
      color={data.selected ? '#004d40' : '#333'}         // Dark green text if selected, default color otherwise
      border={data.selected ? '2px solid #004d40' : '2px solid #a64ca6'} // Dark green border if selected, purple otherwise
      borderRadius="10px"
      boxShadow="2px 2px 5px rgba(0,0,0,0.3)"
      fontWeight="bold"
      transition="background 0.3s, border 0.3s"         // Smooth transition for style changes
      position="relative"                                 // Necessary for Handle positioning
    >
      {/* Target handle on the left */}
      <Handle type="target" position={Position.Left} />

      {/* Node content */}
      <Flex align="center">
        <Text>{data.label}</Text>
        {data.selected && <CheckIcon color="green.500" ml={2} />} {/* Render CheckIcon if selected */}
      </Flex>

      {/* Source handle on the right */}
      <Handle type="source" position={Position.Right} />
    </Box>
  );
};

export default CustomNode;
