import {
  Box,
  createStandaloneToast,
  UseToastOptions,
  Text,
  CloseButton,
  Flex,
} from "@chakra-ui/react";

// Create a standalone toast instance
const { toast } = createStandaloneToast();

// Function to show a toast with dynamic color based on status
const showToast = (
  title: string,
  description: string,
  status: UseToastOptions["status"]
) => {
  // Define background colors for different statuses
  const bgColor = {
    success: "green.400",
    error: "red.400",
    warning: "yellow.400",
    info: "blue.400",
    loading: "gray.400", // Add a color for "loading" status if needed
  }[status || "info"]; // Default to "info" color if no status is provided

  // Call the toast function with custom content, close button, and dynamic background color
  toast({
    position: "bottom-right",
    duration: 9000,
    isClosable: true, // This enables the close button on the toast itself
    render: ({ onClose }) => (
      <Box
        color="white"
        p={2}
        bg={bgColor} // Dynamic background color based on status
        borderRadius="md"
        boxShadow="lg"
        width="300px" // Adjust the width as per your need
      >
        <Flex justifyContent="space-between" alignItems="center">
          <Box mb={0}>
            <Text fontWeight="bold" mb={0}>
              {title}
            </Text>
            {description && <Text fontSize="sm">{description}</Text>}
          </Box>
          {/* Close Button */}
          <CloseButton size="sm" onClick={onClose} color="white"/>
        </Flex>
      </Box>
    ),
  });
};

export default showToast;
