import { useState } from "react";
import { Flex, Grid, GridItem, Box } from "@chakra-ui/react";
import Navbar from "../../components/navbar/Navbar";
import Sidebar1 from "../../components/commentary/sidebar/Sidebar";
import Sidebar2 from "../../components/commentary/old_sidebar/Sidebar";
import "../../App.css";
import { AnalysisTreeRequest } from "../../containers/commentary/Interfaces";
import MainContentContainer from "../../containers/commentary/MainContentContainer";
import { useBeforeUnload } from "../../hooks/useBeforeReload";

const CommentaryPage: React.FC = () => {
  useBeforeUnload();
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState<boolean>(false);
  const [analysisTreeReqs, setAnalysisTreeReqs] = useState<
    AnalysisTreeRequest[][]
  >([]);

  const toggleSidebar = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  const handleAnalysisTreeReq = (data: AnalysisTreeRequest[]) => {
    setAnalysisTreeReqs((prevReqs) => [...prevReqs, data]);
  };

  return (
      <Flex direction="column" h="100vh">
        <Navbar />
        <Box h="100vh" overflowY="auto" overflowX="auto">
          <Grid templateColumns="repeat(24, 1fr)" gap={3} h="full">
            <GridItem colSpan={isSidebarCollapsed ? 1 : 7}>
              {process.env.REACT_APP_OLD_SIDEBAR === 'true' ? (
                <Sidebar2
                  isCollapsed={isSidebarCollapsed}
                  toggleSidebar={toggleSidebar}
                  analysisTreeReq={handleAnalysisTreeReq}
                />
              ) : (
                <Sidebar1
                  isCollapsed={isSidebarCollapsed}
                  toggleSidebar={toggleSidebar}
                  analysisTreeReq={handleAnalysisTreeReq}
                />
              )}
            </GridItem>
            <GridItem colSpan={isSidebarCollapsed ? 23 : 17}>
              {analysisTreeReqs &&
                analysisTreeReqs.map((reqArray, index) => (
                  <MainContentContainer
                    key={index}
                    analysisTreeReq={reqArray}
                  />
                ))}
            </GridItem>
          </Grid>
        </Box>
      </Flex>
  );
};

export default CommentaryPage;
