import React, { useState, useEffect } from "react";
import { Header } from "../../Interfaces";
import { listMetrics } from "../../../services/MetricService";
import MetricAccordion from "../../../components/commentary/sidebar/MetricAccordion";
import { useSidebarContext } from "../../../context/CommentarySidebarContext";

/**
 *
 * Input: metricHeaders callback function to send selected metrics to the parent component.
 * Return: Renders the MetricAccordion component with relevant props.
 *
 * This component manages the selection, filtering, and retrieval of metric Info.
 * It uses local state to store metrics, search input, selected metrics, and an active owner ID.
 * The main functionality includes filtering metrics based on user input, handling metric selection,
 * fetching all metrics from an API, and rendering the MetricAccordion component with the relevant props.
 *
 * @component
 */
const MetricAccordionContainer: React.FC<{
}> = () => {
  const {setMetricHeaders} = useSidebarContext();
  // State to store the list of all metrics
  const [metricList, setMetricList] = useState<Header[]>();

  // State to store the search input for filtering metrics
  const [searchInput, setSearchInput] = useState<string>("");

  // State to store the selected metrics
  const [selectedMetrics, setSelectedMetrics] = useState<Header[]>([]);

  // Function to filter metrics based on the search input
  const filterMetrics = (input: string): Header[] => {
    return (metricList || []).filter((metric) =>
      metric.name?.toLowerCase().includes(input.toLowerCase())
    );
  };

  // Filtered metrics based on the search input
  const filteredMetrics: Header[] = filterMetrics(searchInput);

  // Function to handle metric selection/deselection
  const handleMetricSelection = (metric: Header) => {
    // Check if the metric is already selected
    const isMetricSelected = selectedMetrics.includes(metric);

    // Update the selected metrics array based on selection/deselection
    const updatedMetrics = isMetricSelected
      ? selectedMetrics.filter((m) => m.id !== metric.id)
      : [...selectedMetrics, metric];

    // Set the selected metrics state
    setSelectedMetrics(updatedMetrics);

    setMetricHeaders(updatedMetrics);

  };

  // API call to fetch all metrics
  const fetchData = async () => {
    try {
      const data = await listMetrics(""/* modelId */);
      setMetricList(data);
    } catch (error) {
      alert("Error fetching data");
    }
  };

  // Fetch metric data when the component mounts
  useEffect(() => {
    fetchData();
  }, []);

  // Render the MetricAccordion component with the relevant props
  return (
    <MetricAccordion
      searchInput={searchInput}
      setSearchInput={setSearchInput}
      filteredMetrics={filteredMetrics}
      selectedMetrics={selectedMetrics}
      handleMetricSelection={handleMetricSelection}
    />
  );
};

export default MetricAccordionContainer;
