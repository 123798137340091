import { Textarea, TextareaProps } from '@chakra-ui/react'

/**
 * component responsible for providing a text area to enter the user message.
 */
export const ChatTextArea = (props: TextareaProps) => {
  return (
    <Textarea
      name="message"
      placeholder="Ask me anything..."
      focusBorderColor='#4e1e6c'
      maxHeight="75px"
      paddingEnd="9"
      resize="none"
      borderColor="gray.500"
      rows={1}
      {...props}
      _placeholder={{ color: 'fg.subtle' }}
      onInput={(event) => {
        const textarea = event.currentTarget
        textarea.style.height = 'auto'
        const borderHeight = textarea.offsetHeight - textarea.clientHeight
        textarea.style.height = textarea.scrollHeight + borderHeight + 'px'
        props.onInput?.(event)
      }}
    />
  )
}