import { useEffect, useRef, useState } from "react";
import {
  Grid,
  GridItem,
  Box,
  Stack,
  Flex,
  SkeletonText,
  Spinner,
  Text,
  Tooltip,
  IconButton,
  Circle,
  Avatar,
  useColorModeValue,
} from "@chakra-ui/react";
import { useBeforeUnload } from "../../hooks/useBeforeReload";
import AnalysisDrawer from "./../../components/analyse/analysis_drawer/AnalysisDrawer";
import AnalysisPanelContainer from "../../containers/analyse/AnalysisPanelContainer";
import { AnalysisTreeContextProvider } from "../../context/AnalysisTreeContext";
import { AddIcon, CloseIcon } from "@chakra-ui/icons";
import { useAnalysisDrawerContext } from "../../context/AnalysisDrawerContext";
import { getInitials } from "../../utils/analysisUtils";
import { useLocation, useParams } from "react-router-dom";
import { CancelRequest, StateKey } from "../../containers/stateful/Stateful";
import { cancelTransaction } from "../../services/AnalysisTreeServices";
import { useURLContext } from "../../context/URLContext";

const AnalysePage: React.FC = () => {
  useBeforeUnload();
  
  //treeId from url
  const { id } = useParams();
  const location = useLocation();
  const { transactionId } = useURLContext();
  const { 
    onOpen, 
    btnRef, 
    responseData, 
    setTreeId, 
    setResponseData, 
    setTransactionId 
  } = useAnalysisDrawerContext();

  const [selectedUuid, setSelectedUuid] = useState<string | null>(null);
  const refs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const [visibleUuid, setVisibleUuid] = useState<string | null>(null);

  const hasTrees = Object.keys(responseData).length > 0;
  const bgColor = useColorModeValue(hasTrees ? "purple.50" : "transparent", "purple.900");

  const handleDeleteTree = async (uuid: string) => {
    const updatedResponse = { ...responseData };
    delete updatedResponse[uuid];
    setResponseData(updatedResponse);
    //request array of generate tree
    const transationId: StateKey[] = [{transaction_id: uuid}];

    const statefulCancelRequest: CancelRequest ={
      state_key: transationId,
    }
    await cancelTransaction(statefulCancelRequest);
  };

  useEffect(() => {
    if (id) {
      setTreeId(id);
    } else if(transactionId){
      setTransactionId(transactionId);
    }
    else {
      //onOpen();
    }
  }, [id, onOpen, location.search]);

  useEffect(() => {
    if (selectedUuid && refs.current[selectedUuid]) {
      refs.current[selectedUuid]!.scrollIntoView({ behavior: "smooth" });
    }
  }, [selectedUuid]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setVisibleUuid(entry.target.id);
          }
        });
      },
      { threshold: 0.5 }
    );

    Object.keys(refs.current).forEach((uuid) => {
      if (refs.current[uuid]) {
        observer.observe(refs.current[uuid]!);
      }
    });

    return () => {
      Object.keys(refs.current).forEach((uuid) => {
        if (refs.current[uuid]) {
          observer.unobserve(refs.current[uuid]!);
        }
      });
    };
  }, [responseData]);

  return (
      <Grid templateColumns="1fr 35fr" height="100%" bg="gray.100" p={1}>
        <GridItem alignContent="center">
          <AnalysisDrawer />
          <Box bg={bgColor} py={3} px={1} borderRadius="full">
            <Stack spacing={3} align="center" overflow="visible">
              {hasTrees && (
                <Stack
                  spacing={3}
                  maxHeight="70vh"
                  overflowY="auto"
                  sx={{
                    "&::-webkit-scrollbar": {
                      width: "0px",
                    },
                    scrollbarWidth: "none", // For Firefox
                    msOverflowStyle: "none", // For IE and Edge
                  }}
                >
                  {Object.keys(responseData).map((uuid, index) => {
                    const treeName = responseData[uuid]?.tree ? responseData[uuid]?.tree?.header.name : `${index + 1}`;
                    const initials = getInitials(treeName!);
                    return (
                      <Tooltip
                        key={uuid}
                        label={treeName}
                        hasArrow
                        placement="right"
                        bg="purple.50"
                        color="black"
                        fontSize="sm"
                        p={3}
                        borderRadius={7}
                        arrowSize={9}
                        maxW="100vw"
                        aria-label="Tree Names"
                        zIndex="1000"
                      >
                        <Box position="relative" display="inline-block" role="group" pl={2} pr={2} pt={1}>
                          <Avatar
                            onClick={() => setSelectedUuid(uuid)}
                            cursor="pointer"
                            name={initials}
                            size="sm"
                            fontWeight={700}
                            borderColor="purple.700"
                            borderWidth={2}
                            bg={visibleUuid === uuid ? "purple.800" : "white"}
                            color={visibleUuid === uuid ? "white" : "purple.800"}
                          />
                          <IconButton
                            aria-label="Delete tree"
                            icon={<CloseIcon 
                            boxSize={2} 
                            borderRadius="full" 
                            onClick={() => handleDeleteTree(uuid)} 
                            bg="transparent"  
                            color="purple.800" 
                            _hover={{ color: "red.500", boxSize: "4" }}
                            />
                          }        
                            size="xs"
                            position="absolute"
                            top="-21%" // Adjust as needed
                            right="-21%" // Adjust as needed
                            display="none"
                            _groupHover={{ display: "block" }}
                            zIndex="3"
                          />
                        </Box>
                      </Tooltip>
                    );
                  })}
                </Stack>
              )}
              <Tooltip
                label="New Analysis"
                hasArrow
                placement="bottom"
                bg="purple.50"
                color="black"
                fontSize="sm"
                p={3}
                borderRadius={7}
                arrowSize={9}
                maxW="100vw"
                aria-label="Add Analysis"
              >
                <Circle
                  as={IconButton}
                  icon={<AddIcon />}
                  ref={btnRef}
                  onClick={onOpen}
                  cursor="pointer"
                  size={10}
                  borderWidth={2}
                  color="purple.800"
                  borderColor="purple.800"
                  bg="bg.surface"
                  _hover={{
                    bg: "purple.800",
                    borderColor: "purple.800",
                    color: "white",
                    transform: "rotate(90deg) scale(1.1)",
                    transition: "transform 0.2s ease-in-out",
                  }}
                />
              </Tooltip>
            </Stack>
          </Box>

        </GridItem>
        <GridItem height="100%" overflow="hidden">
          {Object.keys(responseData).map((uuid) => (
            <Stack key={uuid} ref={(el) => (refs.current[uuid] = el)} id={uuid} height="100%">
              {responseData[uuid]?.loading ? (
                <Box
                  key={uuid}
                  p="6"
                  boxShadow="lg"
                  bg="bg.surface"
                  borderRadius="lg"
                  height="100%"
                >
                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="column"
                  >
                    <Spinner size="lg" color="#4e1e6c" thickness="3px" />
                    <Text mt="2" fontSize="lg" fontWeight="semibold">
                      Analyzing Data...
                    </Text>
                  </Flex>
                  <SkeletonText mt="4" noOfLines={6} spacing="4" />
                </Box>
              ) : responseData[uuid]?.tree?.header ? (
                <>
                  <AnalysisTreeContextProvider 
                    transactionId={uuid} 
                    tree={responseData[uuid]?.tree!}
                    nodeMetricArray={responseData[uuid]?.node_metric_array!}
                    >
                    <AnalysisPanelContainer
                      key={uuid}
                    />
                  </AnalysisTreeContextProvider>
                </>
              ) : null}
            </Stack>
          ))}
        </GridItem>
      </Grid>
  );
};

export default AnalysePage;