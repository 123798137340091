import React from "react";
import {
  VStack,
  Box,
  Flex,
  SkeletonText,
  Spinner,
  Text,
} from "@chakra-ui/react";
import {
  AnalysisTreeRequest,
  AnalysisTreeResponse,
} from "../../containers/commentary/Interfaces";
import CommentaryCardContainer from "../../containers/commentary/CommentaryCardContainer";
interface MainContentProps {
  analysisTreeReq: AnalysisTreeRequest[];
  analysisTree: AnalysisTreeResponse[];
  isLoading: boolean;
}

const MainContent: React.FC<MainContentProps> = ({
  analysisTreeReq,
  analysisTree,
  isLoading,
}) => {
  console.log("analysis tree request in Maincontent page", analysisTreeReq);
  return (
    <VStack spacing={3} align="stretch">
      {/* Render skeletons when loading */}
      {isLoading &&
        analysisTreeReq.map((_, index) => (
          <Box key={index} p="6" boxShadow="lg" bg="bg.surface" borderRadius="xl">
          <Flex alignItems="center" justifyContent="center" flexDirection="column">
            <Spinner size="lg" color="#4e1e6c" thickness="3px" />
            <Text mt="2" fontSize="lg" fontWeight="semibold">
              Analyzing Data...
            </Text>
          </Flex>
          <SkeletonText mt="4" noOfLines={6} spacing="4" />
        </Box>
        ))}

      {/* Render actual data when not loading */}
      {!isLoading &&
        analysisTree.map((tree) => {
          const id = tree.header.id!;
          return (
            <CommentaryCardContainer
              key={id}
              id={id}
              tree={tree}
            />
          );
        })}
    </VStack>
  );
};

export default MainContent;
