import React from "react";
import { Link } from "react-router-dom";
import Layout from "../../components/layout/Layout";
import Snowflake from "../../assets/Snowflake.png";
import Thoughtspot from "../../assets/thoughtspot-logos.png";
import Excel from "../../assets/Excel.png";
import MKG from "../../assets/MKG_ICON.jpeg";

/*
This component represents the metric dashboard page
*/
const Metric: React.FC = () => {
  return (
    <Layout>
      <div
        className="container d-flex justify-content-center align-items-center"
        style={{ minHeight: "100vh" }}
      >
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="d-flex flex-column ">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "2vw",
                }}
              >
                <Link to={"/configuration"} style={{ textDecoration: "none" }}>
                  <div
                    className="card"
                    style={{ width: "12rem", paddingTop: "2vh" }}
                  >
                    <img
                      className="card-img-top"
                      src={Snowflake}
                      alt="Metric-Snowflake"
                      style={{
                        maxWidth: "40%",
                        objectFit: "contain",
                        display: "block",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    />
                    <div className="card-body">
                      <h6 className="card-title ">Snowflake</h6>+ Connect
                    </div>
                  </div>
                </Link>
                <Link to={"/"} style={{ textDecoration: "none" }}>
                  <div
                    className="card"
                    style={{ width: "12rem", paddingTop: "2vh" }}
                  >
                    <img
                      className="card-img-top"
                      src={Thoughtspot}
                      alt="Metric-Thoughtspot"
                      style={{
                        maxWidth: "40%",
                        objectFit: "contain",
                        display: "block",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    />
                    <div className="card-body">
                      <h6 className="card-title">Thoughtspot</h6>+ Connect
                    </div>
                  </div>
                </Link>
                <Link to={"/"} style={{ textDecoration: "none" }}>
                  <div
                    className="card"
                    style={{ width: "12rem", paddingTop: "2vh" }}
                  >
                    <img
                      className="card-img-top"
                      src={Excel}
                      alt="Metric-Excel"
                      style={{
                        maxWidth: "85%",
                        objectFit: "contain",
                        display: "block",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    />
                    <div className="card-body">
                      <h6 className="card-title">Excel</h6>+ Connect
                    </div>
                  </div>
                </Link>
              </div>
              <div className="d-flex flex-column align-items-center">
                <div
                  style={{
                    paddingTop: "3vh",
                    justifyContent: "center",
                    gap: "2vw",
                  }}
                >
                  <Link to={"/mkg"} style={{ textDecoration: "none" }}>
                    <div
                      className="card"
                      style={{ width: "12rem", paddingTop: "2vh" }}
                    >
                      <img
                        className="card-img-top"
                        src={MKG}
                        alt="Metric-MKG"
                        style={{
                          maxWidth: "40%",
                          objectFit: "contain",
                          display: "block",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      />
                      <div className="card-body">
                        <h6 className="card-title">Knowledge Graph</h6>+ Connect
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Metric;
