import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Button,
  Checkbox,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import React from "react";

interface ColumnValuesProps {
  contextValues?: string[]; 
  selectedOptions: string[]; 
  setSelectedOptions: (values: string[]) => void; 
}

const ColumnValues: React.FC<ColumnValuesProps> = ({
  contextValues,
  selectedOptions,
  setSelectedOptions,
}) => {

  // Function to handle select/deselect of multiple values
  const handleToggle = (value: string) => {
    const currentIndex = selectedOptions.indexOf(value);
    let newSelected = [...selectedOptions];

    if (currentIndex === -1) {
      newSelected.push(value);
    } else {
      newSelected.splice(currentIndex, 1);
    }
    setSelectedOptions(newSelected);
  };

  return (
    <Menu closeOnSelect={false} preventOverflow={true}>
      <MenuButton
        as={Button}
        size="xs"
        color="gray.700"
        bg="white"
        maxW="100%"
        width="100%"
        rightIcon={<ChevronDownIcon />}
        fontSize="xs"
        border="1px"
        borderColor="gray.300"
        borderRadius="md"
        fontWeight="semibold"
        _hover={{ bg: "purple.50" }}
        textAlign="left"
      >
        {selectedOptions.length > 0
          ? `Selected (${selectedOptions.length})`
          : "Select Options"}
      </MenuButton>
      <MenuList maxHeight="200px" overflowY="auto">
        {contextValues &&
          contextValues.map((option, index) => (
            <MenuItem key={index}  zIndex={10}>
              <Checkbox
                isChecked={selectedOptions.includes(option)}
                onChange={() => handleToggle(option)}
                colorScheme="purple"
              >
                {option}
              </Checkbox>
            </MenuItem>
          ))}
      </MenuList>
    </Menu>
  );
};

export default ColumnValues;
