import React from "react";
import { Header, ModelInfo } from "../../containers/Interfaces";
import {
  Box,
  Card,
  CardBody,
  CardHeader,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Stack,
  Flex,
  Text,
  Tooltip,
  HStack,
  Button,
  Icon
} from "@chakra-ui/react";
import ViewMetricCardContainer from "../../containers/configuration/ViewMetricCardContainer";
import { IoMdAdd } from "react-icons/io";
import EditMetricDefinitionContainer from "../../containers/configuration/EditMetricDefinitionContainer";
import AddDescriptionContainer from "../../containers/configuration/snowflake/AddDescriptionContainer";
import { IoMdOptions } from "react-icons/io";

interface ViewMetricByModelProps {
  listModel: Header[];
  handleCreateMetric: (id: string) => void;
  modelInfo: ModelInfo|null;
  isOpen: boolean;
  metricFlag: boolean;
  closeMetric: () => void;
  handleUpdateModel: (id: string) => void
  modelFlag: boolean
}

const ViewMetricByModel: React.FC<ViewMetricByModelProps> = ({ listModel, handleCreateMetric, modelInfo, isOpen, metricFlag, closeMetric, handleUpdateModel, modelFlag}) => {
  // Render the ViewMetric component
  return (
    <Box overflowY="hidden">
      {!metricFlag && !modelFlag && (
      <Stack>
        {listModel &&
          listModel.map((model, index) => (
            <Card
              key={index}
              m={1}
              ml={3}
              p={3}
              boxShadow="md"
              borderColor="purple.700"
              borderRadius="1px"
              bg="white"
            >
              <CardHeader textAlign="left" m={0} p={1}>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <Text fontSize="lg" fontWeight="bold" color="fg.muted" mb={0}>{model.name?.replace(/_/g, " ")}</Text>
                  <HStack spacing="2">
                  <Tooltip label="New Metric" color="black" bg="gray.50">
              <Button
                variant="secondary"
                justifyContent="start"
                iconSpacing="2"
                leftIcon={<Icon as={IoMdAdd} boxSize="1rem" />}
                onClick={() => handleCreateMetric(model.id!)}
                aria-label="Add"
                size="sm"
                color="fg.default"
                _hover={{bg: "gray.50"}}
              >
              <Text mb={0} fontWeight={600}>
                Metric
              </Text>  
              </Button>
                  </Tooltip>
                  <Tooltip label="Update Model" color="black" bg="gray.50">
                    <IconButton
                      icon={<IoMdOptions />}
                      onClick={() => handleUpdateModel(model.id!)}
                      aria-label="Add"
                      size="sm"
                      color="fg.default"
                      _hover={{bg: "gray.50"}}
                    />
                  </Tooltip>
                  </HStack>
                </Box>
              </CardHeader>
              <CardBody>
                <ViewMetricCardContainer modelId={model.id!} />
              </CardBody>
            </Card>
          ))}
      </Stack>
      )}
      {metricFlag && (<Modal
      isOpen={isOpen}
      onClose={closeMetric}
      isCentered
      motionPreset="scale"
      >
      <ModalOverlay bg="blackAlpha.300" />
      <ModalContent m="5%" maxW="90vw" maxH="90vh" overflow="hidden" >
        <ModalHeader>
        <Flex
          direction="row"
          justify="space-between"
          align="center"
          width="100%"
        >
          {/* Metric Name Section */}
          <Flex
            direction="column"
            justify="center"
            flex="1"
            p={1}
          >
            <Text
              fontSize="lg"
              fontWeight="bold"
              color="black"
              mb={0}
            >
              {" "}
              Define Metric
            </Text>
          </Flex>
        </Flex>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody overflow="auto">
        {/* <DefineMetricContainer modelInfo={modelInfo} metricInfo={handleMetricChange} onClose={onClose}/> */}
        <EditMetricDefinitionContainer createMetricFlag={true} modelInfo={modelInfo} metricHeader={null} onClose={closeMetric}/>
        </ModalBody>
      </ModalContent>
    </Modal>)}
      {modelFlag && (
        <AddDescriptionContainer modelInfo={modelInfo} navigateTo={null} updateFlag={true} handleCreateDataModel={null} />
      )}
    </Box>
  );
};

export default ViewMetricByModel;