import { Flex } from "@chakra-ui/react";
import Navbar from "../../components/navbar/Navbar";
import "../../App.css";
import LibraryContainer from "../../containers/library/LibraryContainer";
/**
 * This component represents the main page of the Library section.
 * It renders the Navbar and LibraryPageContainer components.
 */
const LibraryPage: React.FC = () => {
  return (
    <Flex direction="column" h="100vh">
      <Navbar />
      <LibraryContainer />
    </Flex>
  );
};

export default LibraryPage;
