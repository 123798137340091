// ApiContext.tsx
import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useSearchParams } from 'react-router-dom'

interface ApiContextType {
  isLockAnalyzeButton: boolean
  setLockAnalyzeButton: React.Dispatch<React.SetStateAction<boolean>>
  analysisNameFlag: boolean
  setAnalysisNameFlag: React.Dispatch<React.SetStateAction<boolean>>
}

const ApiContext = createContext<ApiContextType | undefined>(undefined)

export const useAnalysisContext = () => {
  const context = useContext(ApiContext)
  if (!context) {
    throw new Error('useApiContext must be used within an ApiProvider')
  }
  return context
}
interface AnalysisContextProps {
  children: ReactNode
}
export const AnalysisContext: React.FC<AnalysisContextProps> = ({
  children,
}) => {
  const [isLockAnalyzeButton, setLockAnalyzeButton] = useState<boolean>(false)
  const [analysisNameFlag, setAnalysisNameFlag] = useState<boolean>(false)
  return (
    <ApiContext.Provider
      value={{
        isLockAnalyzeButton,
        setLockAnalyzeButton,
        analysisNameFlag,
        setAnalysisNameFlag,
      }}
    >
      {children}
    </ApiContext.Provider>
  )
}
