import { useState } from 'react'
import { Flex } from '@chakra-ui/react'
import Navbar from '../../components/navbar/Navbar'
import ChatContainer from '../../containers/chat/ChatContainer'
import '../../App.css'
import theme from "../../components/common/Theme";
/**
 * This component represents the main page of the Message Library section.
 * It renders the Navbar and ChatPage components.
 */
const ChatPage: React.FC = () => {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState<boolean>(false)

  const toggleSidebar = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed)
  }

  return (
      <Flex direction="column" h="100vh">
        <Navbar />
        <ChatContainer />
      </Flex>
  )
}

export default ChatPage;
