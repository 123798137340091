import React, {useState } from 'react'
import {Tooltip} from '@chakra-ui/react'
import {Icon, CopyIcon } from '@chakra-ui/icons';

const CopyButton: React.FC<{ markdownRef: any }> = ({ markdownRef }) => {

  const [copy, setCopy] = useState<boolean>(false);
  
  const handleCopy = () => {
    setCopy(true);
   
    const element = markdownRef.current
    // console.log("element", element);
    if (element) {
      // Temporarily remove the background color
      const originalBg = element.style.backgroundColor
      element.style.backgroundColor = 'transparent'
  
      const range = document.createRange()
      range.selectNodeContents(element)
      const selection = window.getSelection()
      // console.log("before selection ", selection);
    
      if (selection) {
        selection.removeAllRanges()

        selection.addRange(range)
        document.execCommand('copy')
        selection.removeAllRanges() // Clean up selection
        
        element.style.backgroundColor = originalBg
      }
    }

  };

  return (
    <Tooltip
    label={!copy ? 'copy text' : 'copied'}
    >
    <Icon
              as={CopyIcon}
              w={4}
              h={4}
              ml={2}
              mr={2}
              color="gray.700"
              cursor="pointer"
              onClick={handleCopy}
            />
    </Tooltip>
  )
}

export default CopyButton


