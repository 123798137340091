import React, { useEffect, useState } from "react";
import VisualizeGraph from "../../../components/configuration/mkg/VisualizeGraph";
import { Header, Metric } from "../../../containers/Interfaces";
import { getMetricById } from "../../../services/MetricService";

/**
 * 
 * Manages the state and logic for visualizing graphs based on a given metric ID.
 * Renders the VisualizeGraphDumb component to display the graph.
 * 
 * @component
 * @param {Header} metricId - The ID of the metric to visualize.
 */

const VisualizeGraphContainer: React.FC<{ metricId?: Header }> = ({ metricId }) => {
  // State variable
  const [useMetricKnowledge, setMetricKnowledge] = useState<Metric>();

  // UseEffect hook to fetch metric details when the metricId changes
  useEffect(() => {
    const fetchMK = async () => {
      try {
        if (metricId?.id !== undefined) {
          //second paramenter is to include edges or not. If true then edges will also be returned
          const data = await getMetricById(metricId.id, true);
          console.log(
            "API Response for Metric table Row ID",
            metricId?.id,
            ":",
            data
          );
          console.log("MetricKnowledge", data);
          setMetricKnowledge(data);
        }
      } catch {
        alert("Error while Fetching the details");
      }
    };
    fetchMK();
  }, [metricId?.id]);

  return <VisualizeGraph useMetricKnowledge={useMetricKnowledge} />;
};

export default VisualizeGraphContainer;
