import React, { useState, useEffect } from "react";
import { ModelInfo, Header } from "../../Interfaces";
import {
  fetchModelList,
  getModelById,
} from "../../../services/DataModelService";
import ListDataModel from "../../../components/configuration/snowflake/ListDataModel";

/**
 * ListDataModelContainer Component
 *
 * Input: modelInfo callback to pass the fetched model information to the parent component.
 *
 * Manages the listing of data models, allowing users to add or edit selected models.
 *
 * @component
 */

const ListDataModelContainer: React.FC<{
  modelInfo: (data: ModelInfo) => void;
}> = ({ modelInfo }) => {
  // Define state variables
  const [useData, setData] = useState<Header[]>([]); // State to hold fetched data
  const [page, setPage] = useState<number>(0); // State to manage current page number
  const [rowsPerPage, setRowsPerPage] = useState<number>(5); // State to manage rows per page

  // Function to fetch data from the API
  const fetchData = async () => {
    try {
      const data = await fetchModelList();
      setData(data); // Update state with fetched data
    } catch (error) {
      alert("Error fetching data");
    }
  };

  // Fetch data from API when the component mounts
  useEffect(() => {
    fetchData();
  }, []);

  /**
   * Handles the click event when the "Add" button is clicked.
   * Fetches model data based on the selected row ID and passes it to the parent component.
   *
   * @param {Header} row - The selected row containing the ID to fetch model data.
   */
  const handleAdd = async (row: Header) => {
    try {
      if (row.id !== undefined) {
        // API call to get model by id
        const data = await getModelById(row.id);
        modelInfo(data); // Pass the fetched data to the parent component
      }
    } catch (error) {
      alert(`Error fetching data for ID", ${row.id}, :${error}`);
    }
  };

  /**
   * Handles the click event when the "Edit" button is clicked.
   * TODO: Implement edit functionality.
   *
   * @param {Header} row - The selected row for which the edit functionality will be implemented.
   */
  const handleEdit = (row: Header) => {
    // TODO: Implement edit functionality
  };

  // Render the ListDataModel component with relevant props
  return (
    <ListDataModel
      useData={useData}
      page={page}
      rowsPerPage={rowsPerPage}
      setPage={setPage}
      setRowsPerPage={setRowsPerPage}
      handleAdd={handleAdd}
      handleEdit={handleEdit}
    />
  );
};

export default ListDataModelContainer;
