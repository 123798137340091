import {
  FormControl,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  useDisclosure,
  useMergeRefs,
} from '@chakra-ui/react'
import { forwardRef, useRef } from 'react'
import { TbEyeClosed, TbEye } from "react-icons/tb";

export const PasswordField = forwardRef<HTMLInputElement, InputProps>(
  (props, ref) => {
    const { isOpen, onToggle } = useDisclosure()
    const inputRef = useRef<HTMLInputElement>(null)

    const mergeRef = useMergeRefs(inputRef, ref)
    const onClickReveal = () => {
      onToggle()
      if (inputRef.current) {
        inputRef.current.focus({ preventScroll: true })
      }
    }

    return (
      <FormControl>
        <FormLabel htmlFor="password" fontSize="md" color="fg.muted">Password</FormLabel>
        <InputGroup>
          <InputRightElement
            height="100%"
            alignItems="center"
            justifyContent="center"
          >
            <IconButton
              variant="ghost"
              aria-label={isOpen ? 'Mask password' : 'Reveal password'}
              icon={isOpen ? <TbEye /> : <TbEyeClosed />}
              onClick={onClickReveal}
              size="sm"
            />
          </InputRightElement>
          <Input
            borderColor="gray.300"
            bg="white"
            focusBorderColor="#4E1E6C"
            id="password"
            ref={mergeRef}
            name="password"
            type={isOpen ? 'text' : 'password'}
            autoComplete="current-password"
            required
            {...props}
          />
        </InputGroup>
      </FormControl>
    )
  },
)

PasswordField.displayName = 'PasswordField'