import React, { useEffect, useState } from 'react'
import {
  DataSet,
  Network,
  Options,
} from 'vis-network/standalone/esm/vis-network'
import 'vis-network/styles/vis-network.css'
import { AnalysisTreeResponse, Node } from './Interfaces'
interface TreeViewProps {
  tree: AnalysisTreeResponse | undefined
  onNodeClick: (nodeId: string, nodeMap: Map<string, Node>) => void
}

/**
 * TreeView Component
 *
 * Input:
 * - tree: AnalysisTreeResponse object representing the analysis tree.
 * - onNodeClick: Callback function to handle node click events.
 *
 * Return:
 * Renders the TreeViewer component with relevant props.
 *
 * This component renders a tree view of the analysis tree.
 * It uses the vis.js library to visualize the tree structure.
 * The main functionality includes traversing the tree structure, adding nodes and edges,
 * and handling node click events to display node information.
 *
 * @component
 */
const TreeView: React.FC<TreeViewProps> = ({ tree, onNodeClick }) => {
  // State variables
  const [selectedNodeInfo, setSelectedNodeInfo] = useState<any | null>(null)

  // Labels for analysis types
  const analysisTypeLabels: any = {
    METRIC_VALUE_ANALYSIS: 'Compute Metric',
    BENCHMARK_ANALYSIS: 'Compare to Benchmark',
    DRIVER_ANALYSIS: 'Root Cause by Dimension',
    CORRELATION_ANALYSIS: 'Root Cause by Measure',
  }

  // Effect to initialize and update the tree visualization
  useEffect(() => {
    // const rootNode: Node = tree?.root!;
    const nodeMap: Map<string, Node> = new Map();

    // Iterate over each node in the array
    for (const node of tree?.nodes!) {
      // Add the node to the map using its id as the key
      nodeMap.set(node.id, node);
    }

    function createEdges(
      nodeMap: Map<string, Node>,
      edgesDataSet: DataSet<any>
    ) {
      const nodeEntries = Array.from(nodeMap.entries());

      for (const [nodeId, node] of nodeEntries) {
        const recommendationStrings = node.recommendation_strings || [];
        const recommendationTooltip = recommendationStrings[0];
        nodesDataSet.add({
          id: node.id,
          label: analysisTypeLabels[node?.analysis_type!],
          title: recommendationTooltip,
          color: {
            background: "#fff2f8",
            border: "#4e1e6c",
            highlight: {
              color: "white",
              background: "#fff2f8",
            },
          }, // Default color for columns
          font: {
            color: "black",
            highlight: {
              color: "white",
            },
          },
        });
        const childrenIds = node.children_ids || [];
        for (const childId of childrenIds) {
          const childNode = nodeMap.get(childId);
          if (childNode) {
            edgesDataSet.add({
              from: node.id,
              to: childId,
              font: { align: "top" },
              color: { color: "grey", highlight: "#4e1e6c", hover: "#fff2f8" },
            });
          }
        }
      }
    }

    // Create DataSet for nodes and edges
    const nodesDataSet = new DataSet<any>();
    const edgesDataSet = new DataSet<any>();

    // Create edges
    createEdges(nodeMap, edgesDataSet);
    const data = {
      nodes: nodesDataSet,
      edges: edgesDataSet,
    }

    // Options for the vis.js Network component
    const options: Options = {
      nodes: {
        shape: 'box',
        color: {
          highlight: { background: '#4e1e6c' },
          hover: { background: '#fff2f8', border: '#4e1e6c' },
        },
      },
      edges: {
        arrows: { to: { enabled: true, scaleFactor: 0.5 } }, // Add arrows to edges
        width: 3,
        smooth: {
          enabled: true,
          type: 'continuous',
          forceDirection: 'none', // Adjust as needed
          roundness: 0.2, // Adjust as needed
        },
        color: { color: 'grey', highlight: '#4e1e6c', hover: '#fff2f8' },
        labelHighlightBold: false,
        // label: "dimension",
        font: {
          align: 'horizontal',
        },
      },
      layout: {
        hierarchical: {
          enabled: true,
          levelSeparation: 250,
          nodeSpacing: 100,
          // treeSpacing: 1000,
          direction: 'LR',
          sortMethod: 'directed',
        },
      },
      physics: {
        hierarchicalRepulsion: {
          nodeDistance: 80,
        },
      },
      interaction: {
        zoomView: true,
      },
    }

    // Create a network using vis.js
    const container = document.getElementById('network')
    const network = new Network(container!, data, options)
    network.on('click', (event) => {
      const nodeId = event.nodes[0]
      if (nodeId) {
        // Node is clicked
        const clickedNode = nodeMap.get(nodeId)
        if (clickedNode) {
          console.log(`Clicked Node ID: ${clickedNode.id}`)
          const nodeInfo = nodeMap.get(clickedNode.id)
          setSelectedNodeInfo(nodeInfo)
          onNodeClick(clickedNode.id, nodeMap)
          // console.log(
          //   "Analyised data: " +
          //     JSON.stringify(nodeInfo?.analysed_data?.column_values_list)
          // );
        }
      }
    })
    return () => {
      // Cleanup when the component unmounts
      network.destroy()
    }
  }, [])

  return (
    <div
      id="network"
      style={{ height: '400px', width: '100%', background: 'white' }}
    ></div>
  )
}

export default TreeView
