import React from 'react'
import { useEffect, useState } from 'react'
import { useDisclosure } from '@chakra-ui/react'
import {
  fetchConversationById,
  updateConversationName,
  deleteConversationById,
} from '../../services/ChatServices'
import { ChatInterface, MessageData } from './interface'
import { usePDF, Margin } from 'react-to-pdf'
import { fetchAllConversations } from '../../services/ChatServices'
import { AnalysisTreeResponse } from '../commentary/Interfaces'
import Chat from '../../components/chat/Chat'
import { fetchAnalysisTreeById, fetchAnalysisTreeHeaders } from '../../services/AnalysisTreeServices'
import { useAnalysisContext } from '../../context/AnalysisContext'
import { useURLContext } from '../../context/URLContext'
import { epochConverter } from '../../utils/dateUtils'
import { Header } from '../Interfaces'

/**
 * This component represents the library page, where users can view and interact with their starred commentaries.
 * It fetches all starred commentaries, allows users to filter them via searchbar, export them as PDF.
 * It provides an interface for the user to interact with the LLM subjected to the selected analysis.
 */
const ChatContainer = () => {
  //states for analysis
  const [treeHeaders, setTreeHeaders] = useState<Header[]>([])
  const [activeAnalysis, setActiveAnalysis] = useState<Header[]>([])
  const [commentaries, setCommentaries] = useState<string[]>([])
  const [searchQuery, setSearchQuery] = useState('')
  const [chatName, setChatName] = useState<string>('New Chat')
  const [contextString, setContextString] = useState<string>('')
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [tree, setTree] = useState<AnalysisTreeResponse | undefined>()
  const {analysisNameFlag} = useAnalysisContext()
  const {maxResults} = useURLContext()
  //states for chats
  const [chats, setChats] = useState<ChatInterface[]>([])
  const [activeChat, setActiveChat] = useState<ChatInterface>()
  const [historyMessages, setHistoryMessages] = useState<MessageData[]>([])
  const [chatConversationId, setChatConversationID] = useState<string>('')
  const [newChatToggler, setNewChatToggler] = useState<boolean>(false)
  const [deleteFlag, setDeleteFlag] = useState<boolean>(false)
  const [selectedDeleteChatId, setSelectedDeleteChatId] = useState<string>('')

  const { toPDF, targetRef } = usePDF({
    method: 'save',
    filename: 'Generated by Anko AI.pdf',
    page: { margin: Margin.MEDIUM },
  })

  /**
   * for fetching all the analysis from the backend API
   */
  useEffect(() => {
    const getAllTreeHeader = async () => {
      setTreeHeaders([])
      const response = await fetchAnalysisTreeHeaders(maxResults)
      response &&
        response.forEach((data: any) => {
          setTreeHeaders((prevData) => [...prevData, data])
        })
    }
    getAllTreeHeader()
    return () => {
      setTreeHeaders([])
    }
  }, [analysisNameFlag])

  /**
   * for fetching the history of chats
   */
  useEffect(() => {
    const getAllChats = async () => {
      setChats([])
      const newChats: ChatInterface[] = [] // Local array to accumulate new chats
      const response = await fetchAllConversations()
      response &&
        response.forEach((data: any) => {
          const header = {
            id: data.id,
            name: data.name,
            last_modified_time: epochConverter(
              parseInt(data.last_modified_date_ms),
            ),
          }
          const newChat = {
            header,
          }
          newChats.push(newChat) // Push new chat into the local array
        })
      setChats(newChats)
    }
    getAllChats()
    return () => {
      setChats([])
    }
  }, [newChatToggler, deleteFlag])

  /**
   * function to handle active analysis and contextString for exporting into pdf
   */
  const handleAddActiveAnalysis = async(header: Header) => {
    const response = await fetchAnalysisTreeById(
    header.id!, 
    false /*include nodes*/,
    false  /*include Starred Commentary*/,
    false /*include all Commentary*/,
    true /*include Saved Commentary*/);
    const isAlreadyPresent = activeAnalysis.find(
      (data) => data.id === header.id,
    )
    if (isAlreadyPresent) {
      alert('cannot add the same analysis again')
      return
    } else {
      setActiveAnalysis((prevAnalysis: Header[]) => [
        ...prevAnalysis,
        header,
      ])
      setCommentaries((prevDiffAnalysis) => [...prevDiffAnalysis, response.commentaries[0].commentary])
      const str = contextString + '\n\n\n\n' + response.commentaries[0].commentary;
      setContextString(str)
    }
  }

  /**
   * Filter the analysis array based on the search query
   */
  const filteredAnalysis: Header[] = treeHeaders && treeHeaders.filter((item) =>
    item.name!
      .toLowerCase()
      .includes(searchQuery.toLowerCase()),
  )

  /**
   * fetching tree by id and activating the modal for tree viewer
   */
  const handleTree = async (id: string) => {
    const newTree = await fetchAnalysisTreeById(
    id,
    true  /*include nodes*/,
    false /*include includeStarredCommentary*/,
    false /*include includeAllCommentary*/,
    false /*include includeSavedCommentary*/
    );
    setTree(newTree)
    onOpen()
  }

  /**
   * function to handle active chat
   */
  const handleActiveChat = async (chat: ChatInterface) => {

    if (chat.header.id !== chatConversationId) {
      setChatConversationID('')
      setHistoryMessages([])
      const response = await fetchConversationById(chat.header.id)
      const messages: MessageData[] = []
      response &&
        response.messages &&
        response.messages.forEach((item: any) => {
          messages.push({
            name: item.role === 'USER' ? 'You' : 'Assistant',
            message: item.content,
            fromUser: item.role === 'USER',
          })
        })
      messages.shift() // removing the system prompt message

      setChatConversationID(chat.header.id)
      setHistoryMessages(messages)
      setActiveChat(chat)
      setChatName(chat.header.name!)
    }
  }

  /**
   * function to hanlde new chat
   */
  const handleNewChat = () => {
    setActiveAnalysis([])
    setCommentaries([])
    setContextString('')

    setChatConversationID('')
    setHistoryMessages([])
    setChatName('New Chat')

    const dummyChat: ChatInterface = {
      header: {
        id: '',
        name: '',
        last_modified_time: '',
      },
    }
    setActiveChat(dummyChat)
  }

  /**  Renaming isOpen, onOpen, and onClose from useDisclosure for analysis modal */
  const {
    isOpen: isDrawerOpen,
    onOpen: openDrawer,
    onClose: closeDrawer,
  } = useDisclosure()

  /**  HTMLButtonElement for the button ref of analysis modal */
  const btnRef = React.useRef<HTMLButtonElement>(null)

  /**
   * handle chat name
   */
  const handleChatName = async(newChatName: string) => {
    setChatName(newChatName);
    if(!chatConversationId.length) return ;
    if(activeChat?.header.name === newChatName) return ;
    await updateConversationName(chatConversationId, newChatName);
    setNewChatToggler(prevToggleState=> !prevToggleState);
  }

  /** ref for delete chat drawer */
  const cancelDeleteRef = React.useRef<HTMLButtonElement>(null)

  /**
   * function to handle delete chat
   */
  const handleConversationDelete = async () => {
    console.log('chat deleted ', selectedDeleteChatId)
    const id = selectedDeleteChatId
    closeDeleteDialog()
    const response = await deleteConversationById(id)
    handleNewChat()
    setDeleteFlag((prevFlag) => !prevFlag)
    // console.log("delete conversation response", response);
  }

  /**  Open the dialog and set the selected chat */
  const openDeleteDialog = (id: string) => {
    setSelectedDeleteChatId(id)
  }

  /** Close the dialog and reset the selected chat */
  const closeDeleteDialog = () => {
    setSelectedDeleteChatId('')
  }

  return (
    <Chat
      activeAnalysis={activeAnalysis}
      commentaries={commentaries}
      setCommentaries={setCommentaries}
      chats={chats}
      activeChat={activeChat}
      setSearchQuery={setSearchQuery}
      chatName={chatName}
      contextString={contextString}
      isOpen={isOpen}
      onClose={onClose}
      toPDF={toPDF}
      targetRef={targetRef}
      filteredAnalysis={filteredAnalysis}
      tree={tree}
      handleTree={handleTree}
      handleAddActiveAnalysis={handleAddActiveAnalysis}
      handleActiveChat={handleActiveChat}
      chatConversationId={chatConversationId}
      historyMessages={historyMessages}
      setHistoryMessages={setHistoryMessages}
      handleNewChat={handleNewChat}
      setNewChatToggler={setNewChatToggler}
      isDrawerOpen={isDrawerOpen}
      openDrawer={openDrawer}
      closeDrawer={closeDrawer}
      btnRef={btnRef}
      setChatConversationID={setChatConversationID}
      handleChatName={handleChatName}
      handleConversationDelete={handleConversationDelete}
      cancelDeleteRef={cancelDeleteRef}
      openDeleteDialog={openDeleteDialog}
      closeDeleteDialog={closeDeleteDialog}
      selectedDeleteChatId={selectedDeleteChatId}
    />
  )
}

export default ChatContainer
