import { useDisclosure } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { deleteMetric, listMetrics, getMetricById } from "../../services/MetricService";
import {
  Header,
  ModelInfo,
  FormData,
  Metric,
} from "../../containers/Interfaces";
import { getModelById } from "../../services/DataModelService";
import ViewMetricCard from "../../components/configuration/ViewMetricCard";
import React from "react";
import { BsWindowSidebar } from "react-icons/bs";
import { deleteAnalysisTreeByID } from "../../services/AnalysisTreeServices";
import showToast from "../../hooks/useCustomToast";

interface ViewMetricCardProps {
  modelId: string;
}

const ViewMetricCardContainer: React.FC<ViewMetricCardProps> = ({
  modelId,
}) => {
  const [metricHeaderList, setMetricHeaderList] = useState<Header[]>();
  const [selectedMetric, setSelectedMetric] = useState<Header>();
  const [modelInfo, setModelInfo] = useState<ModelInfo | null>(null);
  const [metricInfo, setMetricInfo] = useState<FormData | null>(null);
  const [viewMKG, setViewMKG] = useState<boolean>(false);
  const [editMKG, setEditMKG] = useState<boolean>(false);
  const [editMetricDefinition, setEditMetricDefinition] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [dependentMetrics, setDependentMetrics] = useState<Header[]>([]);
  const [dependentAnalysis, setDependentAnalyis] = useState<Header[]>([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [deleteMetricFlag, setDeleteMetricFlag] = useState<boolean>(false);
  const [deleteMetricDialog, setDeleteMetricDialog] = useState<boolean>(false);
  const [metricId, setMetricId] = useState<string>('');
  /** ref for delete metric dialog */
  const cancelDeleteRef = React.useRef<HTMLButtonElement>(null);
  useEffect(() => {
    const metricList = async () => {
      try {
        const data = await listMetrics(modelId);
        setMetricHeaderList(data);
        setLoading(false);
      } catch (error) {
        showToast("Error in REACT_APP_LIST_METRIC", "", "error");
      }
    };
    metricList();
  }, [modelId]);

  const handleViewMKGModal = (metricHeader: Header) => {
    setSelectedMetric(metricHeader);
    setEditMetricDefinition(false);
    setEditMKG(false);
    setViewMKG(true);
    onOpen();
  };
  const handleEditMetricDefinitionModal = (metricHeader: Header) => {
    setSelectedMetric(metricHeader);
    setEditMKG(false);
    setViewMKG(false);
    setEditMetricDefinition(true);
    onOpen();
  };
  const handleEditMKGModal = async (metricHeader: Header) => {
    try {
      const data = await getModelById(modelId);
      setModelInfo(data);
    } catch (error) {
      showToast("Error fetching data model", "", "error");
    }
    try {
      const data: Metric = await getMetricById(metricHeader.id!, true);
      console.log("edges", data.edges);
      const formData: FormData = {
        model_id: String(modelId),
        metrics: [
          {
            header: {
              ...data.header,
            },
            definition: {
              observation_columns: data.definition.observation_columns || [],
              filters: data.definition.filters,
            },
            edges: data?.edges,
            format: data?.format,
            type: data?.type,
            polarity: data?.polarity,
            material_column_header: data?.material_column_header
          },
        ],
        update_edges: true,
      };
      setMetricInfo(formData);
    } catch (error) {
      showToast("Error updating MKG.", "", "error");
    }
    setSelectedMetric(metricHeader);
    setEditMetricDefinition(false);
    setViewMKG(false);
    setEditMKG(true);
    onOpen();
  };

  const handleDeleteMetricDialog = (id: string) => {
    setMetricId(id);
    setDeleteMetricDialog(true);
  }

  const handleDeleteMetric = async () => {

      closeDeleteMetricDialog();
      const response: any = await deleteMetric(metricId);
      if(response){
          const metrics: Header[] = [];
          const analysis: Header[] = [];
          response.map((header: Header) => {
              header.type === 'METRIC' ? metrics.push(header) : 
                                         analysis.push(header);
          });
          setDependentMetrics(metrics);
          setDependentAnalyis(analysis);
          setDeleteMetricFlag(true);
      }
      else{
        showToast("Metric deleted successfully", "", "success");
        window.location.reload();
      }
  }

  const closeDeleteMetricDialog = () => {
      setDeleteMetricDialog(false);
  }

  const closeDeleteMetricModal = async () => {
      setDeleteMetricFlag(false); 
  }

  const deleteAnalysis = async (id: string) => {
      await deleteAnalysisTreeByID(id);
      setDeleteMetricFlag(false);
  }

  return (
    <ViewMetricCard
      metricHeaderList={metricHeaderList!}
      loading={loading}
      handleViewMKGModal={handleViewMKGModal}
      handleEditMKGModal={handleEditMKGModal}
      handleEditMetricDefinitionModal={handleEditMetricDefinitionModal}
      isOpen={isOpen}
      onClose={onClose}
      viewMKG={viewMKG}
      editMKG={editMKG}
      editMetricDefinition={editMetricDefinition}
      modelInfo={modelInfo}
      metricInfo={metricInfo}
      selectedMetric={selectedMetric}
      dependentMetrics={dependentMetrics}
      dependentAnalysis={dependentAnalysis}
      handleDeleteMetric={handleDeleteMetric}
      deleteMetricFlag={deleteMetricFlag}
      closeDeleteMetricModal={closeDeleteMetricModal}
      deleteMetricDialog={deleteMetricDialog}
      cancelDeleteRef={cancelDeleteRef}
      handleDeleteMetricDialog ={handleDeleteMetricDialog }
      closeDeleteMetricDialog={closeDeleteMetricDialog}
      deleteAnalysis={deleteAnalysis}
    />
  );
};

export default ViewMetricCardContainer;
