export const conversationStart = async (
  systemMessage: string,
  userMessage: string
) => {
  try {
    const response = await fetch(process.env.REACT_APP_CONVERSATION_START!, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        systemMessage: systemMessage,
        userMessage: userMessage,
      }),
    });

    if (!response.ok) {
      alert(`Error in starting the conversation`);
      return null;
    }
    const data = await response.json();
    //  console.log("conversationStart: ", data);
    return data;
  } catch (error) {
    alert(`Error in starting the conversation ${error}`);
  }
};

export const conversationAddMessage = async (
  conversationId: string,
  userMessage: string
) => {
  // console.log("userMessage ", userMessage);
  try {
    const response = await fetch(
      process.env.REACT_APP_CONVERSATION_ADD_MESSAGE!,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          conversationId: conversationId,
          userMessage: userMessage,
        }),
      }
    );

    if (!response.ok) {
      alert(`Error in continuing the conversation`);
      return null;
    }
    const data = await response.json();
    //  console.log("conversationAddMessage: ", data);

    return data;
  } catch (error) {
    alert(`Error in continuing the conversation ${error}`);
  }
};

export const fetchAllConversations = async () => {
  try {
    const response = await fetch(process.env.REACT_APP_GET_ALL_CONVERSATIONS!, {
      method: "GET",
      credentials: "include",
    });
    if (!response.ok) {
      alert("Error in fetching conversations");
      return null;
    }
    const data = await response.json();
    // console.log("Conversations list: ", data);
    return data;
  } catch (error) {
    alert(`Error in fetching conversations ${error}`);
  }
};

export const fetchConversationById = async (id: string) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_GET_CONVERSATION_BY_ID}/${id}`,
      {
        method: "GET",
        credentials: "include",
      }
    );

    if (!response.ok) {
      alert(`Error in retrieving the conversation`);
      return null;
    }

    const data = await response.json();
    // console.log("conversation: ", data);
    return data;
  } catch (error) {
    alert(`Error in retrieving the conversation ${error}`);
  }
};

export const updateConversationName = async (id: string, name: string) => {
  try {
    const response = await fetch(
      process.env.REACT_APP_CONVERSATION_UPDATE_NAME!,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id,
          name: name,
        }),
      }
    );

    if (!response.ok) {
      alert(`Error in updating conversation name`);
      return null;
    }
    // const data = await response.json();
    return response;
  } catch (error) {
    alert(`Error in updating conversation name ${error}`);
  }
};

export const deleteConversationById = async (conversationId: string) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_DELETE_CONVERSATION_BY_ID}/${conversationId}`,
      {
        method: "DELETE",
        credentials: "include",
      }
    );

    if (response.status !== 204) {
      alert("conversation not deleted, please try again");
      return null;
    }

    console.log("delete conversation called");
    return response;
  } catch (error) {
    alert("Error deleting conversation");
  }
};
