import React from "react";
import {
  Tooltip,
  Box,
  Flex,
  Text,
  Button,
  Icon,
  keyframes,
} from "@chakra-ui/react";
import {
  CopyIcon,
  InfoIcon,
  CheckIcon,
  AddIcon,
  DownloadIcon,
} from "@chakra-ui/icons";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from "@chakra-ui/react";
import {
  AnalysisTreeResponse,
} from "../../containers/commentary/Interfaces";
import { FaSpellCheck, FaCalculator } from "react-icons/fa";
import TreeViewerContainer from "../../containers/commentary/TreeViewerContainer";
import AnalysisName from "../../containers/chat/ChatName";

import { epochConverter } from "../../utils/dateUtils";
const scaleIn = keyframes`
  from { transform: scale(0); }
  to { transform: scale(1); }
`;

interface CardHeaderProps {
  tree: AnalysisTreeResponse | undefined;
  activeIndex: number;
  conversationId: string;
  handleCopy: () => void;
  handleDownloadPrompt: () => void;
  handleDiffChecker: () => void;
  copy: boolean;
  isDiff: boolean;
  starredIndex: number;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  handleStarIndex: () => void;
  isAdmin: boolean;
  analysisName: string;
  handleAnalysisName: (name: string) => void
  hideCommentaryCardHeaderButton:boolean;
}

const CommentaryCardHeader: React.FC<CardHeaderProps> = ({
  tree,
  activeIndex,
  conversationId,
  handleCopy,
  handleDownloadPrompt,
  handleDiffChecker,
  copy,
  isDiff,
  starredIndex,
  isOpen,
  onOpen,
  onClose,
  handleStarIndex,
  isAdmin,
  analysisName,
  handleAnalysisName,
  hideCommentaryCardHeaderButton
}) => {
  console.log("match", activeIndex);
  console.log("match", starredIndex);
  const metricLastModified = epochConverter(
    parseInt(tree?.metric.header.last_modified_date_ms!)
  );
  const tooltipLabelContext =
    tree &&
    tree?.context &&
    tree?.context.map((context, index) => (
      <React.Fragment key={index}>
        <span>
          Time:{" "}
          {epochConverter(
            parseInt(context.logical_column_header?.last_modified_date_ms!)
          )}
        </span>
        <span> | </span>
        <span>
          {context.logical_column_header?.name} {context.operator}{" "}
          {context.values?.join(", ")}
        </span>
        {index < tree.context.length - 1 && <br />}{" "}
      </React.Fragment>
    ));

  const benchmarksFromEdges =
    tree &&
    tree?.metric?.edges
      ?.map((edge) => edge?.benchmark)
      .filter((benchmark) => benchmark !== undefined);
  const tooltipLabelBenchmark = benchmarksFromEdges?.map((benchmark, index) => {
    if (!benchmark) return null; // Skip undefined benchmarks

    const filters = benchmark.benchmark_filters?.map((filter, filterIndex) => (
      <span key={filterIndex}>
        {filter.logical_column_header?.name} {filter.operator} {filter.values}
        <br />
      </span>
    ));
    console.log(benchmarksFromEdges);
    return (
      <React.Fragment key={index}>
        <span>
          Time:{" "}
          {epochConverter(
            parseInt(
              benchmark.benchmark_filters[0]?.logical_column_header
                .last_modified_date_ms!
            )
          )}
        </span>
        <span> | </span>
        <span>{benchmark.header?.name} </span>
        <br />
        <span>{filters}</span>
        {index < benchmarksFromEdges.length - 1 && <br />}
      </React.Fragment>
    );
  });

  return (
    <Flex direction="column" justify="space-between" mb={4}>
      <Flex direction="row" justify="space-between" align="center">
        {/* Metric Name Section */}
        <Box
          w="25%"
          p={1}
          textAlign="left"
          alignItems="center"
          justifyContent="center"
        >
          <Text fontSize="xl" fontWeight="black" mb={0} ml={1}>
            <AnalysisName chatName={analysisName} handleChatName={handleAnalysisName}/>
          </Text>
          <Text fontSize="xs" color="gray.500" mb={0} ml={1}>
            Last Run: {metricLastModified}
          </Text>
        </Box>

        {/* Context and Benchmarks Section */}
        <Flex
          direction="column"
          justify="center"
          align="flex-start"
          flex="1"
          p={1}
        >
          <Tooltip
            label={tooltipLabelContext}
            hasArrow
            placement="top"
            bg="purple.50"
            color="black"
            fontSize="md"
            p={3}
            borderRadius={7}
            arrowSize={9}
            maxW="100vw"
          >
            <Flex alignItems="left" cursor="pointer">
              <Text fontSize="md" fontWeight="medium" mr={2} mb={0}>
                Context:
              </Text>
              <Icon
                as={InfoIcon}
                boxSize={4}
                color="#4e1e6c"
                alignSelf="center"
              />
            </Flex>
          </Tooltip>
        </Flex>

        {/* Benchmark Section */}
        {/* <Flex
          direction="column"
          justify="center"
          align="flex-start"
          flex="1"
          p={1}
        >
           <Tooltip
            label={tooltipLabelBenchmark}
            hasArrow
            placement="top"
            bg="purple.50"
            color="black"
            p={3}
            borderRadius={7}
            fontSize="lg"
            arrowSize={9}
            maxW="100vw"
          >
            <Flex alignItems="left" justifyContent="center" cursor="pointer">
              <Text fontSize="md" fontWeight="medium" mr={2} mb={0}>
                Benchmarks:
              </Text>
              <Icon
                as={InfoIcon}
                boxSize={4}
                color="#4e1e6c"
                alignSelf="center"
              />
            </Flex>
          </Tooltip>
        </Flex>*/}

        {/* Action Buttons Section */}
        <Flex w="25%" justify="center" align="center" p={1}>
          <Button
            size="md"
            fontSize="md"
            borderRadius="lg"
            m={0}
            bg="purple.50"
            color="black"
            onClick={onOpen} // Change to onOpen to open the modal
            border="1px solid #4e1e6c"
            _hover={{ bg: "#4e1e6c", color: "white", transform: "scale(1.1)" }}
            leftIcon={<Icon as={FaCalculator} />}
          >
            View Calculations
          </Button>
        </Flex>

        <Modal
          isOpen={isOpen}
          onClose={onClose}
          isCentered
          motionPreset="scale"
        >
          <ModalOverlay />
          <ModalContent m="0%" maxW="100vw" maxH="100vh" overflow="none">
            <ModalHeader>
              <Flex
                direction="row"
                justify="space-between"
                align="center"
                fontFamily="'Montserrat', sans-serif"
                width="100%"
              >
                {/* Metric Name Section */}
                <Flex
                  direction="column"
                  justify="center"
                  align="flex-start"
                  flex="1"
                  p={1}
                >
                  <Text fontSize="lg" fontWeight="bold" color="black" mb={0}>
                    {tree && tree.metric.header.name} Variance Analysis:
                  </Text>
                </Flex>

                {/* Last Run Section
                <Flex
                  direction="column"
                  justify="center"
                  align="flex-start"
                  flex="1"
                  p={1}
                >
                  <Text fontSize="md" color="gray.600" mb={0}>
                    Last Run: {metricLastModified}
                  </Text>
                </Flex> */}

                {/* Context Section
                <Flex
                  direction="column"
                  justify="center"
                  align="flex-start"
                  flex="1"
                  p={1}
                >
                  <Tooltip
                    label={tooltipLabelContext}
                    hasArrow
                    placement="top"
                    bg="purple.50"
                    p={3}
                    borderRadius={7}
                    color="black"
                    fontSize="lg"
                    arrowSize={9}
                    maxW="100vw"
                  >
                    <Flex alignItems="left" cursor="pointer">
                      <Text fontSize="md" fontWeight="medium" mr={2} mb={0}>
                        Context
                      </Text>
                      <Icon as={InfoIcon} boxSize={6} color="#4e1e6c" />
                    </Flex>
                  </Tooltip>
                </Flex>
                */}

                {/* Benchmark Section
                <Flex
                  direction="column"
                  justify="center"
                  align="flex-start"
                  flex="1"
                  p={1}
                >
                  <Tooltip
                    label={tooltipLabelBenchmark}
                    hasArrow
                    placement="top"
                    bg="purple.50"
                    color="black"
                    p={3}
                    borderRadius={7}
                    fontSize="lg"
                    arrowSize={9}
                    maxW="100vw"
                  >
                    <Flex alignItems="left" cursor="pointer">
                      <Text fontSize="md" fontWeight="medium" mr={2} mb={0}>
                        Benchmarks
                      </Text>
                      <Icon as={InfoIcon} boxSize={6} color="#4e1e6c" />
                    </Flex>
                  </Tooltip>
                </Flex> */}
              </Flex>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody overflow="auto">
              <TreeViewerContainer key={tree?.header.id} tree={tree} />
            </ModalBody>
          </ModalContent>
        </Modal>
        <Flex w="15%" justify="flex-end" align="center" p={2}>
          <Tooltip
            hasArrow
            placement="bottom"
            label={
              starredIndex !== activeIndex
                ? "Add to Memory"
                : "Remove from Memory"
            }
            aria-label="save commentary"
          >
            <Flex
              align="center"
              justify="center"
              ml={2}
              mr={2}
              cursor={!hideCommentaryCardHeaderButton ? "pointer" :"blocked" }
              onClick={
                !hideCommentaryCardHeaderButton ? handleStarIndex :undefined 
              }
              borderRadius="full"
              p={activeIndex === starredIndex ? "5px" : "5px"}
              border="3px solid"
              borderColor={activeIndex === starredIndex ? "green.600" : "white"}
              opacity={!hideCommentaryCardHeaderButton ? 1 : 0.5  }
            >
              <Icon
                as={activeIndex === starredIndex ? CheckIcon : AddIcon}
                boxSize={5}
                color={activeIndex === starredIndex ? "green.600" : "gray.700"}
                sx={{
                  animation: `${scaleIn} 0.3s ease-in-out forwards`,
                }}
              />
            </Flex>
          </Tooltip>

          {isAdmin && (
            <Tooltip
              hasArrow
              placement="bottom"
              label="Download prompt"
              aria-label="Download prompt"
            >
              <Box>
                <Icon
                  as={DownloadIcon}
                  w={5}
                  h={5}
                  ml={2}
                  mr={2}
                  color="gray.300"
                  cursor={
                    !hideCommentaryCardHeaderButton ? "pointer" : "default"
                  }
                  onClick={
                    !hideCommentaryCardHeaderButton
                      ? handleDownloadPrompt
                      : undefined
                  }
                  opacity={!hideCommentaryCardHeaderButton ? 1 : 0.5}
                />
              </Box>
            </Tooltip>
          )}

          <Tooltip
            hasArrow
            placement="bottom"
            label={!isDiff ? "View differences" : "Hide differences"}
            aria-label={!isDiff ? "View differences" : "Hide differences"}
          >
            <Box>
              <Icon
                as={FaSpellCheck}
                w={6}
                h={6}
                ml={2}
                mr={2}
                cursor={!hideCommentaryCardHeaderButton ? "pointer" : "default"}
                onClick={
                  !hideCommentaryCardHeaderButton
                    ? handleDiffChecker
                    : undefined
                }
                color={isDiff ? "#4e1e6c" : "gray.700"}
                opacity={!hideCommentaryCardHeaderButton ? 1 : 0.5}
              />
            </Box>
          </Tooltip>

          <Tooltip
            hasArrow
            placement="bottom"
            label={!copy ? "Copy Commentary" : "Copied"}
            aria-label="Copy Commentary"
          >
            <Box>
              <Icon
                as={CopyIcon}
                w={5}
                h={5}
                ml={2}
                mr={2}
                color="gray.700"
                cursor={!hideCommentaryCardHeaderButton ? "pointer" : "default"}
                onClick={
                  !hideCommentaryCardHeaderButton ? handleCopy : undefined
                }
                opacity={!hideCommentaryCardHeaderButton ? 1 : 0.5}
              />
            </Box>
          </Tooltip>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default CommentaryCardHeader;
