import React from "react";
import { FormData, ColumnInfo, ModelInfo } from "../../../containers/Interfaces";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  Stack,
  VStack,
  Container,
} from "@chakra-ui/react";
interface DefineMetricProps {
  modelInfo: ModelInfo | null;
  formValues: FormData;
  columns: ColumnInfo[];
  handleChange: (e: { target: { name: any; value: any } }) => void;
  setFormValues: React.Dispatch<React.SetStateAction<FormData>>; 
  addObservationColumn: () => void;
  removeObservationColumn: (index: number) => void;
  addFilterColumn: () => void;
  removeFilterColumn: (index: number) => void;
  submitForm: () => void;
}

const DefineMetric: React.FC<DefineMetricProps> = ({
  modelInfo,
  formValues,
  columns,
  handleChange,
  setFormValues,
  addObservationColumn,
  removeObservationColumn,
  addFilterColumn,
  removeFilterColumn,
  submitForm,
}) => {
  return (
    // <div className="container-fluid mt-3">
    //   <div className="row">
    //     <div className="col-md-12">
    //       <h3>Define Metric Form</h3>
    //       <form>
    //         <div className="row">
    //           <div className="col-md-6">
    //             <div className="card mb-3">
    //               <div className="card-body">
    //                 <div className="row">
    //                   <div className="col">
    //                     <input
    //                       type="text"
    //                       id="name"
    //                       name="name"
    //                       className="form-control"
    //                       placeholder="Metric Name"
    //                       value={formValues.metrics[0]?.header.name || ""}
    //                       onChange={(e) =>
    //                         setFormValues((prevValues) => {
    //                           const updatedMetrics = [...prevValues.metrics];
    //                           updatedMetrics[0].header.name = e.target.value;
    //                           return {
    //                             ...prevValues,
    //                             metrics: updatedMetrics,
    //                           };
    //                         })
    //                       }
    //                     />
    //                   </div>
    //                   <div className="col">
    //                     <input
    //                       type="text"
    //                       id="description"
    //                       name="description"
    //                       className="form-control"
    //                       placeholder="Metric Description"
    //                       value={formValues.metrics[0]?.header.description || ""}
    //                       onChange={(e) =>
    //                         setFormValues((prevValues) => {
    //                           const updatedMetrics = [...prevValues.metrics];
    //                           updatedMetrics[0].header.description = e.target.value;
    //                           return {
    //                             ...prevValues,
    //                             metrics: updatedMetrics,
    //                           };
    //                         })
    //                       }
    //                     />
    //                   </div>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>

    //           <div className="col-md-6">
    //             <div className="card mb-3">
    //               <div className="card-body">
    //                 <input
    //                   type="text"
    //                   id="modelId"
    //                   name="modelId"
    //                   value={`${modelInfo?.header?.id}: ${modelInfo?.header?.name}`}
    //                   onChange={(e) => e.preventDefault()} // Disabled input, no need to handle change
    //                   className="form-control"
    //                   disabled
    //                 />
    //               </div>
    //             </div>
    //           </div>
    //         </div>

    //         {/* Observation Columns */}
    //         <div className="row">
    //           <div className="col-md-6">
    //             {formValues.metrics[0]?.definition.observation_columns.map((observation, index) => (
    //               <div className="card mb-3" key={index}>
    //                 <div className="card-body">
    //                   <div className="row mb-2 align-items-center">
    //                     <div className="col-md-5 text-left">
    //                       Observation Column:
    //                     </div>
    //                     <div className="col-md-7">
    //                       <select
    //                         className="form-control"
    //                         value={observation.logical_column_header.id || ""}
    //                         onChange={(e) =>
    //                           setFormValues((prevValues) => {
    //                             const updatedObservationColumns = [
    //                               ...prevValues.metrics[0]?.definition.observation_columns,
    //                             ];
    //                             updatedObservationColumns[index].logical_column_header.id =
    //                               e.target.value;
    //                             return {
    //                               ...prevValues,
    //                               definition: {
    //                                 ...prevValues.metrics[0]?.definition,
    //                                 observation_columns: updatedObservationColumns,
    //                               },
    //                             };
    //                           })
    //                         }
    //                       >
    //                         <option value="">Select Column</option>
    //                         {columns.map((column) => (
    //                           <option
    //                             key={column.header?.id}
    //                             value={column.header?.id}
    //                           >
    //                             {column.header?.name}
    //                           </option>
    //                         ))}
    //                       </select>
    //                     </div>
    //                   </div>
    //                   {/* Aggregation type */}
    //                   <div className="row mb-2 align-items-center">
    //                     <div className="col-md-5 text-left">Aggregation:</div>
    //                     <div className="col-md-7">
    //                       <select
    //                         className="form-control"
    //                         value={observation.aggregation || ""}
    //                         onChange={(e) =>
    //                           setFormValues((prevValues) => {
    //                             const updatedObservationColumns = [
    //                               ...prevValues.metrics[0]?.definition.observation_columns,
    //                             ];
    //                             updatedObservationColumns[index].aggregation =
    //                               e.target.value;
    //                             return {
    //                               ...prevValues,
    //                               definition: {
    //                                 ...prevValues.metrics[0]?.definition,
    //                                 observation_columns: updatedObservationColumns,
    //                               },
    //                             };
    //                           })
    //                         }
    //                       >
    //                         <option value="SUM">SUM</option>
    //                         <option value="AVERAGE">AVERAGE</option>
    //                         <option value="COUNT">COUNT</option>
    //                         <option value="UNIQUE_COUNT">UNIQUE_COUNT</option>
    //                         <option value="GROWTH">GROWTH</option>
    //                         <option value="NO_AGGREGATION">
    //                           NO_AGGREGATION
    //                         </option>
    //                       </select>
    //                     </div>
    //                   </div>
    //                   {/* Sort type */}
    //                   <div className="row mb-2 align-items-center">
    //                     <div className="col-md-5 text-left">Sorting:</div>
    //                     <div className="col-md-7">
    //                       <select
    //                         className="form-control"
    //                         value={observation.sort_by || ""}
    //                         onChange={(e) =>
    //                           setFormValues((prevValues) => {
    //                             const updatedObservationColumns = [
    //                               ...prevValues.metrics[0]?.definition.observation_columns,
    //                             ];
    //                             updatedObservationColumns[index].sort_by =
    //                               e.target.value;
    //                             return {
    //                               ...prevValues,
    //                               definition: {
    //                                 ...prevValues.metrics[0]?.definition,
    //                                 observation_columns: updatedObservationColumns,
    //                               },
    //                             };
    //                           })
    //                         }
    //                       >
    //                         <option value="ASCENDING">ASCENDING</option>
    //                         <option value="DESCENDING">DESCENDING</option>
    //                         <option value="NO_SORT">NO_SORT</option>
    //                       </select>
    //                     </div>
    //                   </div>
    //                 </div>
    //                 <div className="card-footer text-center">
    //                   <button
    //                     type="button"
    //                     className="btn btn-primary me-2"
    //                     onClick={addObservationColumn}
    //                   >
    //                     Add Column
    //                   </button>
    //                   <button
    //                     type="button"
    //                     className="btn btn-danger"
    //                     onClick={() => removeObservationColumn(index)}
    //                   >
    //                     Remove Column
    //                   </button>
    //                 </div>
    //               </div>
    //             ))}
    //           </div>

    //           {/* Filter Column */}
    //           <div className="col-md-6">
    //             {formValues.metrics[0]?.definition.filters.map((filter, index) => (
    //               <div className="card mb-3" key={index}>
    //                 <div className="card-body">
    //                   <div className="row mb-2 align-items-center">
    //                     <div className="col-md-5 text-left">Filter Column:</div>
    //                     <div className="col-md-7">
    //                       <select
    //                         className="form-control"
    //                         value={filter.logical_column_header.id || ""}
    //                         onChange={(e) =>
    //                           setFormValues((prevValues) => {
    //                             const updatedFilterColumns = [
    //                               ...prevValues.metrics[0]?.definition.filters,
    //                             ];
    //                             updatedFilterColumns[index].logical_column_header.id = e.target.value;
    //                             return {
    //                               ...prevValues,
    //                               definition: {
    //                                 ...prevValues.metrics[0]?.definition,
    //                                 filters: updatedFilterColumns,
    //                               },
    //                             };
    //                           })
    //                         }
    //                       >
    //                         <option value="">Select Column</option>
    //                         {columns.map((column) => (
    //                           <option
    //                             key={column.header?.id}
    //                             value={column.header?.id}
    //                           >
    //                             {column.header?.name}
    //                           </option>
    //                         ))}
    //                       </select>
    //                     </div>
    //                   </div>
    //                   <div className="row mb-2 align-items-center">
    //                     <div className="col-md-5 text-left">
    //                       Filter operator:
    //                     </div>
    //                     <div className="col-md-7">
    //                       <select
    //                         className="form-control"
    //                         value={filter.operator || ""}
    //                         onChange={(e) =>
    //                           setFormValues((prevValues) => {
    //                             const updatedFilterColumns = [
    //                               ...prevValues.metrics[0]?.definition.filters,
    //                             ];
    //                             updatedFilterColumns[index].operator =
    //                               e.target.value;
    //                             return {
    //                               ...prevValues,
    //                               definition: {
    //                                 ...prevValues.metrics[0]?.definition,
    //                                 filters: updatedFilterColumns,
    //                               },
    //                             };
    //                           })
    //                         }
    //                       >
    //                         <option value="EQ">EQ</option>
    //                         <option value="NE">NE</option>
    //                         <option value="GT">GT</option>
    //                         <option value="GTE">GTE</option>
    //                         <option value="LT">LT</option>
    //                         <option value="LTE">LTE</option>
    //                         <option value="NO_FILTER_OP">NO_FILTER_OP</option>
    //                       </select>
    //                     </div>
    //                   </div>
    //                   <div className="row mb-2 align-items-center">
    //                     <div className="col-md-5 text-left">Filter value:</div>
    //                     <div className="col-md-7">
    //                       <input
    //                         type="text"
    //                         className="form-control"
    //                         placeholder="Filter Value"
    //                         value={filter.values || ""}
    //                         onChange={(e) =>
    //                           setFormValues((prevValues) => {
    //                             const updatedFilterColumns = [
    //                               ...prevValues.metrics[0]?.definition.filters,
    //                             ];
    //                             updatedFilterColumns[index].values = [e.target.value];
    //                             return {
    //                               ...prevValues,
    //                               definition: {
    //                                 ...prevValues.metrics[0]?.definition,
    //                                 filters: updatedFilterColumns,
    //                               },
    //                             };
    //                           })
    //                         }
    //                       />
    //                     </div>
    //                   </div>
    //                 </div>
    //                 <div className="card-footer text-center">
    //                   <button
    //                     type="button"
    //                     className="btn btn-primary me-2"
    //                     onClick={addFilterColumn}
    //                   >
    //                     Add Column
    //                   </button>
    //                   <button
    //                     type="button"
    //                     className="btn btn-danger"
    //                     onClick={() => removeFilterColumn(index)}
    //                   >
    //                     Remove Column
    //                   </button>
    //                 </div>
    //               </div>
    //             ))}
    //           </div>
    //         </div>

    //         {/* Submit Button */}
    //         <button
    //           type="button"
    //           className="btn btn-primary me-2"
    //           onClick={submitForm}
    //         >
    //           Submit
    //         </button>
    //       </form>
    //     </div>
    //   </div>
    // </div>
    <Container maxW="container.xl" mt={6}>
      <Box as="h3" mb={6}>
        Define Metric Form
      </Box>
      <Box as="form">
        <Flex direction={{ base: "column", md: "row" }} gap={6}>
          <Box flex="1" bg="white" p={4} borderRadius="md" boxShadow="md">
            <VStack align="stretch" spacing={4}>
              <FormControl>
                <FormLabel>Metric Name:</FormLabel>
                <Input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Metric Name"
                  value={formValues.metrics[0]?.header.name || ""}
                  onChange={(e) =>
                    setFormValues((prevValues) => {
                      const updatedMetrics = [...prevValues.metrics];
                      updatedMetrics[0].header.name = e.target.value;
                      return {
                        ...prevValues,
                        metrics: updatedMetrics,
                      };
                    })
                  }
                />
              </FormControl>
              <FormControl>
                <FormLabel>Metric Description:</FormLabel>
                <Input
                  type="text"
                  id="description"
                  name="description"
                  placeholder="Metric Description"
                  value={formValues.metrics[0]?.header.description || ""}
                  onChange={(e) =>
                    setFormValues((prevValues) => {
                      const updatedMetrics = [...prevValues.metrics];
                      updatedMetrics[0].header.description = e.target.value;
                      return {
                        ...prevValues,
                        metrics: updatedMetrics,
                      };
                    })
                  }
                />
              </FormControl>
            </VStack>
          </Box>
          <Box flex="1" bg="white" p={4} borderRadius="md" boxShadow="md">
            <Input
              type="text"
              id="modelId"
              name="modelId"
              value={`${modelInfo?.header?.id}: ${modelInfo?.header?.name}`}
              onChange={(e) => e.preventDefault()} // Disabled input, no need to handle change
              isDisabled
            />
          </Box>
        </Flex>

        {/* Observation Columns */}
        <Flex direction={{ base: "column", md: "row" }} gap={6} mt={6}>
          <Box flex="1">
            {formValues.metrics[0]?.definition.observation_columns.map((observation, index) => (
              <Box key={index} bg="white" p={4} borderRadius="md" boxShadow="md" mb={3}>
                <VStack align="stretch" spacing={4}>
                  <FormControl>
                    <FormLabel>Observation Column:</FormLabel>
                    <Select
                      value={observation.logical_column_header.id || ""}
                      onChange={(e) =>
                        setFormValues((prevValues) => {
                          const updatedObservationColumns = [
                            ...prevValues.metrics[0]?.definition.observation_columns,
                          ];
                          updatedObservationColumns[index].logical_column_header.id =
                            e.target.value;
                          return {
                            ...prevValues,
                            metrics: [{
                              ...prevValues.metrics[0],
                              definition: {
                                ...prevValues.metrics[0]?.definition,
                                observation_columns: updatedObservationColumns,
                              },
                            }],
                          };
                        })
                      }
                    >
                      <option value="">Select Column</option>
                      {columns.map((column) => (
                        <option
                          key={column.header?.id}
                          value={column.header?.id}
                        >
                          {column.header?.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl>
                    <FormLabel>Aggregation:</FormLabel>
                    <Select
                      value={observation.aggregation || ""}
                      onChange={(e) =>
                        setFormValues((prevValues) => {
                          const updatedObservationColumns = [
                            ...prevValues.metrics[0]?.definition.observation_columns,
                          ];
                          updatedObservationColumns[index].aggregation =
                            e.target.value;
                          return {
                            ...prevValues,
                            metrics: [{
                              ...prevValues.metrics[0],
                              definition: {
                                ...prevValues.metrics[0]?.definition,
                                observation_columns: updatedObservationColumns,
                              },
                            }],
                          };
                        })
                      }
                    >
                      <option value="SUM">SUM</option>
                      <option value="AVERAGE">AVERAGE</option>
                      <option value="COUNT">COUNT</option>
                      <option value="UNIQUE_COUNT">UNIQUE_COUNT</option>
                      <option value="GROWTH">GROWTH</option>
                      <option value="NO_AGGREGATION">NO_AGGREGATION</option>
                    </Select>
                  </FormControl>
                  <FormControl>
                    <FormLabel>Sorting:</FormLabel>
                    <Select
                      value={observation.sort_by || ""}
                      onChange={(e) =>
                        setFormValues((prevValues) => {
                          const updatedObservationColumns = [
                            ...prevValues.metrics[0]?.definition.observation_columns,
                          ];
                          updatedObservationColumns[index].sort_by =
                            e.target.value;
                          return {
                            ...prevValues,
                            metrics: [{
                              ...prevValues.metrics[0],
                              definition: {
                                ...prevValues.metrics[0]?.definition,
                                observation_columns: updatedObservationColumns,
                              },
                            }],
                          };
                        })
                      }
                    >
                      <option value="ASCENDING">ASCENDING</option>
                      <option value="DESCENDING">DESCENDING</option>
                      <option value="NO_SORT">NO_SORT</option>
                    </Select>
                  </FormControl>
                </VStack>
                <Flex justify="center" mt={4} gap={4}>
                  <Button colorScheme="blue" onClick={addObservationColumn}>
                    Add Column
                  </Button>
                  <Button colorScheme="red" onClick={() => removeObservationColumn(index)}>
                    Remove Column
                  </Button>
                </Flex>
              </Box>
            ))}
          </Box>

          {/* Filter Column */}
          <Box flex="1">
            {formValues.metrics[0]?.definition.filters.map((filter, index) => (
              <Box key={index} bg="white" p={4} borderRadius="md" boxShadow="md" mb={3}>
                <VStack align="stretch" spacing={4}>
                  <FormControl>
                    <FormLabel>Filter Column:</FormLabel>
                    <Select
                      value={filter.logical_column_header.id || ""}
                      onChange={(e) =>
                        setFormValues((prevValues) => {
                          const updatedFilterColumns = [
                            ...prevValues.metrics[0]?.definition.filters,
                          ];
                          updatedFilterColumns[index].logical_column_header.id = e.target.value;
                          return {
                            ...prevValues,
                            metrics: [{
                              ...prevValues.metrics[0],
                              definition: {
                                ...prevValues.metrics[0]?.definition,
                                filters: updatedFilterColumns,
                              },
                            }],
                          };
                        })
                      }
                    >
                      <option value="">Select Column</option>
                      {columns.map((column) => (
                        <option
                          key={column.header?.id}
                          value={column.header?.id}
                        >
                          {column.header?.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl>
                    <FormLabel>Filter operator:</FormLabel>
                    <Select
                      value={filter.operator || ""}
                      onChange={(e) =>
                        setFormValues((prevValues) => {
                          const updatedFilterColumns = [
                            ...prevValues.metrics[0]?.definition.filters,
                          ];
                          updatedFilterColumns[index].operator =
                            e.target.value;
                          return {
                            ...prevValues,
                            metrics: [{
                              ...prevValues.metrics[0],
                              definition: {
                                ...prevValues.metrics[0]?.definition,
                                filters: updatedFilterColumns,
                              },
                            }],
                          };
                        })
                      }
                    >
                      <option value="EQ">EQ</option>
                      <option value="NE">NE</option>
                      <option value="GT">GT</option>
                      <option value="GTE">GTE</option>
                      <option value="LT">LT</option>
                      <option value="LTE">LTE</option>
                      <option value="NO_FILTER_OP">NO_FILTER_OP</option>
                    </Select>
                  </FormControl>
                  <FormControl>
                    <FormLabel>Filter value:</FormLabel>
                    <Input
                      type="text"
                      placeholder="Filter Value"
                      value={filter.values || ""}
                      onChange={(e) =>
                        setFormValues((prevValues) => {
                          const updatedFilterColumns = [
                            ...prevValues.metrics[0]?.definition.filters,
                          ];
                          updatedFilterColumns[index].values = [e.target.value];
                          return {
                            ...prevValues,
                            metrics: [{
                              ...prevValues.metrics[0],
                              definition: {
                                ...prevValues.metrics[0]?.definition,
                                filters: updatedFilterColumns,
                              },
                            }],
                          };
                        })
                      }
                    />
                  </FormControl>
                </VStack>
                <Flex justify="center" mt={4} gap={4}>
                  <Button colorScheme="blue" onClick={addFilterColumn}>
                    Add Column
                  </Button>
                  <Button colorScheme="red" onClick={() => removeFilterColumn(index)}>
                    Remove Column
                  </Button>
                </Flex>
              </Box>
            ))}
          </Box>
        </Flex>

        {/* Submit Button */}
        <Flex justify="center" mt={4}>
          <Button colorScheme="blue" onClick={submitForm}>
            Submit
          </Button>
        </Flex>
      </Box>
    </Container>
  );
};

export default DefineMetric;

