import React, { useState } from "react";
import {
  Box,
  List,
  ListItem,
  Text,
  Divider,
  Button,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  ButtonGroup,
  Table,
  Tbody,
  Td,
  Tr,
  Flex,
  Center,
  Spinner,
  Icon,
  FormControl,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Stack,
} from "@chakra-ui/react";
import { useBatchContext } from "../../context/batch/BatchContext";
import { AnalysisActionRequest, CreateOrUpdateJob, JobRequest } from "./Batch";
import { FaRegTrashAlt } from "react-icons/fa";
import {
  IoFootstepsSharp,
  IoEllipsisVertical,
  IoDuplicate,
} from "react-icons/io5";
import { ActionType } from "../../utils/enum";
import { Header } from "../Interfaces";
import { epochConverter } from "../../utils/dateUtils";
import { MdEdit, MdHistory } from "react-icons/md";
import { createOrUpdateJob } from "../../services/BatchServices";

const JobStepsContainer: React.FC = () => {
  const {
    analysisActionRequest,
    deleteJobStep,
    handleJobStepClick,
    selectedJobStepIndex,
    setSelectedJobStepIndex,
    setAnalysisActionRequest,
    jobRunHeaders,
    setSelectedRunHeader,
    setShowStatusPage,
    selectedRunHeader,
    jobLoaders,
    stepName,
    setStepName,
    selectedJob,
  } = useBatchContext();

  const [view, setView] = useState<"actions" | "runs">("actions");
  // Modal controls for rename a job
  const renameStepModal = useDisclosure();

  const handleStepClick = (step: AnalysisActionRequest, index: number) => {
    handleJobStepClick(step, index); // Call the handler for the clicked job step
  };

  // Updated handleDuplicateStep function
  const handleDuplicateStep = (index: number) => {
    // Get the selected step
    const selectedStep = analysisActionRequest[index];
    if (!selectedStep) return;

    // Duplicate the step with generate_commentary_request set to empty
    const duplicatedStep: AnalysisActionRequest = {
      ...selectedStep,
      actions: selectedStep.actions?.map((action) => {
        if (action.type === ActionType.GENERATE_COMMENTARY) {
          return {
            ...action,
            generate_commentary_request: {}, // Set to empty object
          };
        }
        return action;
      }),
    };

    // Add the duplicated step to the list and select it
    setAnalysisActionRequest((prevSteps) => {
      const updatedSteps = [...prevSteps, duplicatedStep];
      const newIndex = updatedSteps.length - 1; // Index of the new step

      // Automatically select the duplicated step
      setSelectedJobStepIndex(newIndex);
      handleStepClick(duplicatedStep, newIndex); // Simulate click on the duplicated step

      return updatedSteps;
    });
  };

  const handleSelectRunHeader = (run: Header) => {
    setSelectedRunHeader({ ...run });
    setShowStatusPage(true);
  };

  // Function to handle rename of a Job
  const handleRenameStep = async () => {
    if (stepName.trim() === "") {
      // Optionally, handle empty step name validation
      return;
    }

    // Update the analysisActionRequest state and make the API call after the state is updated
    setAnalysisActionRequest((prevSteps) => {
      const updatedSteps = prevSteps.map((step, index) => {
        if (index === selectedJobStepIndex) {
          return {
            ...step,
            analysis_step_name: stepName, // Update the step name
          };
        }
        return step;
      });

      // Optionally, close the modal after renaming
      renameStepModal.onClose();

      // Once the state is updated, make the API call with the updated steps
      const jobRequest: JobRequest = {
        global_properties: {},
        analysis_action_requests: updatedSteps, // Use the updated steps here
      };

      createOrUpdateJob({
        id: selectedJob?.header?.id,
        name: selectedJob?.header?.name,
        request: jobRequest,
      });
      // Return the updated steps to update the state
      return updatedSteps;
    });
  };

  return (
    <Box display="flex" flexDirection="column" height="100%">
      {/* Fixed Switcher */}
      <Box flex="0 0 auto" p={1} mb={0}>
        <Flex align="center" width="100%">
          <ButtonGroup isAttached variant="outline" width="100%" size="xs">
            <Button
              leftIcon={<IoFootstepsSharp />}
              colorScheme={view === "actions" ? "purple" : "gray"}
              borderWidth={view === "actions" ? 2 : 1}
              onClick={() => {
                setView("actions");
                setShowStatusPage(false);
                setSelectedRunHeader({});
              }}
              flex="1"
              justifyContent="center"
              fontWeight="bold"
            >
              Steps
            </Button>
            <Button
              leftIcon={<MdHistory />}
              colorScheme={view === "runs" ? "purple" : "gray"}
              borderWidth={view === "runs" ? 2 : 1}
              onClick={() => {
                setView("runs");
              }}
              flex="1"
              justifyContent="center"
              fontWeight="bold"
            >
              Past Runs
            </Button>
          </ButtonGroup>
        </Flex>
        <Divider my={2} mb={0} borderColor="purple.700" />
      </Box>

      {/* Scrollable Content */}
      <Stack 
        height="100%"
        spacing="1" 
        alignItems="left"
        overflowY="auto"
        sx={{
          /* Custom scrollbar for WebKit-based browsers (Chrome, Safari, Edge) */
          "::-webkit-scrollbar": {
            width: "2px", // Set scrollbar width
          },
          "::-webkit-scrollbar-track": {
            background: "gray.100", // Background of the track
          },
          "::-webkit-scrollbar-thumb": {
            background: "gray.500", // Color of the scrollbar thumb
            borderRadius: "8px",
          },
          "::-webkit-scrollbar-thumb:hover": {
            background: "gray.600", // Color of the scrollbar thumb on hover
          },
          /* Scrollbar width for Firefox */
          scrollbarWidth: "thin", // For Firefox
          scrollbarColor: "gray.500 gray.100", // Thumb and track colors for Firefox
        }}>
        {/* Display loader if loading */}
        {jobLoaders.actionLoader ? (
          <Center height="100%">
            {/* Ensure the spinner is centered */}
            <Spinner size="md" color="purple.700" />
          </Center>
        ) : view === "actions" ? (
          // Render Job Actions
          <List spacing={2} textAlign="left" m={0} overflowY="auto">
            {analysisActionRequest?.map((step, index) => (
              <React.Fragment key={index}>
                <ListItem
                  role="group"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  cursor="pointer"
                  bg={
                    selectedJobStepIndex === index
                      ? "purple.700"
                      : "transparent"
                  }
                  color={selectedJobStepIndex === index ? "white" : "black"}
                  borderRadius="md"
                  fontSize="xs"
                  p={1}
                  onClick={() => handleStepClick(step, index)}
                  _hover={{
                    bg:
                      selectedJobStepIndex === index
                        ? "purple.700"
                        : "gray.100",
                  }}
                >
                  {/* Number and Job Step Name */}
                  <HStack>
                    <Text
                      fontWeight="bold"
                      ml={1}
                      fontSize="sm"
                      alignItems="center"
                      mb={0}
                      color={
                        selectedJobStepIndex === index ? "white" : "gray.500"
                      }
                    >
                      {index + 1}
                    </Text>
                    <Text
                      fontWeight={selectedJobStepIndex === index ? 700 : 600}
                      mb={0}
                      ml={1}
                      fontSize="xs"
                      alignItems="center"
                    >
                      {step?.analysis_step_name}
                    </Text>
                  </HStack>
                  {/* Ellipsis Menu */}
                  <Menu>
                    <MenuButton
                      as={IconButton}
                      aria-label="Options"
                      icon={<IoEllipsisVertical />}
                      variant="ghost"
                      size="xs"
                      color={selectedJobStepIndex === index ? "white" : "black"}
                      opacity={selectedJobStepIndex === index ? "1" : "0"}
                      _groupHover={{ opacity: "1" }}
                      onClick={(e) => e.stopPropagation()} // Prevent list item click event
                    />
                    <MenuList>
                      <MenuItem
                        fontSize="xs"
                        fontWeight={600}
                        color="black"
                        icon={<Icon as={IoDuplicate} boxSize={4} />}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDuplicateStep(index);
                        }}
                      >
                        Duplicate
                      </MenuItem>
                      <MenuItem
                        fontSize="xs"
                        fontWeight={600}
                        color="black"
                        icon={<Icon as={MdEdit} boxSize={4} />}
                        onClick={(e) => {
                          e.stopPropagation();
                          setStepName(step?.analysis_step_name || "");
                          renameStepModal.onOpen();
                        }}
                      >
                        Rename
                      </MenuItem>
                      <MenuItem
                        fontSize="xs"
                        fontWeight={600}
                        color="red"
                        icon={<Icon as={FaRegTrashAlt} boxSize={4} />}
                        onClick={(e) => {
                          e.stopPropagation();
                          deleteJobStep(index);
                        }}
                      >
                        Delete
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </ListItem>
              </React.Fragment>
            ))}
          </List>
        ) : (
          // Render Job Run Headers
          <Box
            bg="gray.100"
            p={0}
            mt={1}
            mb={1}
            ml={1}
            mr={1}
            borderRadius="md"
            height="auto"
            overflowY="auto"
            textAlign="left"
          >
            {jobRunHeaders.length > 0 ? (
              <Table size="sm" variant="simple">
                <Tbody>
                  {jobRunHeaders.map((run) => (
                    <Tr
                      key={run.id} // Use a unique identifier if available
                      _hover={{ bg: "purple.50" }}
                      cursor="pointer"
                      onClick={() => handleSelectRunHeader(run)}
                    >
                      <Td
                        fontSize="xs"
                        fontWeight={
                          selectedRunHeader?.id === run.id ? "700" : "500"
                        }
                        bg={
                          selectedRunHeader?.id === run.id
                            ? "purple.700"
                            : "transparent"
                        }
                        color={
                          selectedRunHeader?.id === run.id ? "white" : "black"
                        }
                        borderRadius={6}
                        py={3}
                      >
                        {epochConverter(parseInt(run?.created_date_ms!))}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            ) : (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                textAlign="center"
                p={4}
              >
                <Text fontSize="xs" mb={1}>
                  No job runs available
                </Text>
              </Box>
            )}
          </Box>
        )}
      </Stack>

      {/* Rename Job Step Modal */}
      <Modal isOpen={renameStepModal.isOpen} onClose={renameStepModal.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize="md">Update Step Name</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <Input
                placeholder="Enter Step name"
                value={stepName}
                onChange={(e) => setStepName(e.target.value)}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="purple" mr={3} onClick={handleRenameStep}>
              OK
            </Button>
            <Button variant="ghost" onClick={renameStepModal.onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default JobStepsContainer;
