import React, { useEffect, useState } from "react";
import {
  Flex,
  Box,
  Text,
  Button,
  MenuDivider,
  HStack,
  VStack,
  Divider,
  Icon,
  Grid,
  Popover,
  Input,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  PopoverHeader,
  useDisclosure,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  IconButton,
  ModalFooter,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useBatchContext } from "../../context/batch/BatchContext";
import ColumnValues from "../../components/common/ColumnValues";
import { CustomBenchmark } from "../../utils/enum";
import { FaSave, FaCheck } from "react-icons/fa";
import { PiFilePpt } from "react-icons/pi";
import { FaSlack } from "react-icons/fa";
import { MdBuild } from "react-icons/md";
import { FaExternalLinkAlt } from "react-icons/fa";
import { PiFlowArrowFill } from "react-icons/pi";
import { FaFilter } from "react-icons/fa";
import {
  TbCircle1Filled,
  TbCircle2Filled,
  TbCircle3Filled,
  TbCircle4Filled,
  TbCircle5Filled,
  TbCircle6Filled,
  TbTargetArrow,
} from "react-icons/tb";
import { TiCancel } from "react-icons/ti";
import { FaHandSparkles } from "react-icons/fa";
import {
  FaChevronDown,
  FaChartLine,
  FaStethoscope,
  FaChartPie,
} from "react-icons/fa";
import "react-datepicker/dist/react-datepicker.css";
import GlobalEntityContainer from "./GlobalEntity";
import { IoMdArrowRoundBack } from "react-icons/io";

const StepConfigureContainer: React.FC = () => {
  const {
    metricHeaders,
    selectedMetricHeader,
    handleMetricHeaderSelect,
    selectedAttributesValues,
    setSelectedAttributesValues,
    relatedAttributeSet,
    attributeValueMap,
    handleAttributeSelect,
    selectedAttribute,
    excludeEdges,
    selectedEdgeToExclude,
    setSelectedEdgeToExclude,
    handleSaveJobStep,
    selectedContextMap,
    metricInfoMap,
    setCustomBenchmarkName,
    customBenchmarkName,
    setSlackChannelId,
    slackChannelId,
    setSlideName,
    slideName,
    selectedPurpose,
    setSelectedPurpose,
    setChooseTargets,
    chooseTargets,
    errorMessage,
    validateFields,
    setSelectedContextMap,
    runStepLoader,
    setSelectedAttribute
  } = useBatchContext();
  const [selectedAction, setSelectedAction] = useState<string | null>(null);
  const [isReportAdded, setIsReportAdded] = useState(false);
  const [isSlackNotified, setIsSlackNotified] = useState(false);
  
  const pptExportPopover = useDisclosure();
  const slackPopover = useDisclosure();
  const errorModal = useDisclosure();

  // Handlers to select action and toggle popover
  const handleActionClick = (actionId: string) => {
    setSelectedAction((prev) => (prev === actionId ? null : actionId));
  };

  // Modify handleRunStep to include validation
  const handleRunStepWithValidation = async () => {
    if (validateFields()) {
      handleSaveJobStep(true);
    } else {
      // Open the error modal
      errorModal.onOpen();
    }
  };

  // Update selectedContextMap whenever selectedAttribute or selectedAttributesValues change
  useEffect(() => {
    if(selectedAttributesValues.length === 0){
      setSelectedContextMap((prevMap) => {
        const newMap = { ...prevMap };
        delete newMap[selectedAttribute];
        return newMap;
      }); 
    }
    if (selectedAttributesValues.length > 0) {
      setSelectedContextMap((prevMap) => ({
        ...prevMap,
        [selectedAttribute]: selectedAttributesValues,
      }));
    }
  }, [selectedAttribute, selectedAttributesValues]);

  // Effect to set chooseTargets based on selected metric
  useEffect(() => {
    if (selectedMetricHeader && metricInfoMap[selectedMetricHeader.id!]) {
      const selectedMetric = metricInfoMap[selectedMetricHeader.id!];

      // Extract benchmark edge names
      const benchmarkEdges = selectedMetric.edges
        ?.filter((edge) => edge.benchmark)
        ?.map((edge) => edge.benchmark?.header.name!);

      if (benchmarkEdges) {
        // Merge with existing context values
        setChooseTargets([
          ...benchmarkEdges, // Add benchmark edges
          CustomBenchmark.PreviousPeriod,
          CustomBenchmark.SamePeriodLastYear,
        ]);
      }
    }
  }, [selectedMetricHeader, metricInfoMap]);

  return (
    <Flex direction="column" width="100%" height="100%">
      {/* Header */}
      <Box m={1}>
        <HStack justifyContent="space-between">
          <HStack>
            <Icon as={PiFlowArrowFill} boxSize="5" color="black" />
            <Text
              fontWeight="bold"
              align="left"
              m={1}
              ml={0}
              color="gray.700"
              fontSize="sm"
            >
              Create Step
            </Text>
          </HStack>
          <HStack>
            <GlobalEntityContainer />
          </HStack>
        </HStack>
        <Divider mt={2} mb={1} borderColor="purple.700" />
      </Box>

      {/* Main Content */}
      <Flex direction="row" width="100%" flex="1">
        <VStack w="100%" mx={1} textAlign="left" alignItems="left" spacing={2}>
          {/* Top Section: Metrics, Filters, Benchmarks, Exclusions */}
          <Grid
            templateColumns="1fr 1fr 1fr 1fr"
            templateRows="1fr"
            h="100%"
            w="100%"
            gap={2}
          >
            {/* Metric Selection */}
            <Box
              bg="gray.50"
              borderRadius="md"
              p={2}
              borderWidth="1px"
              borderColor="gray.300"
            >
              <VStack textAlign="left" alignItems="left">
                <HStack justifyContent="space-between">
                  <HStack spacing={1}>
                    <Icon as={TbCircle1Filled} boxSize={5} />
                    <Text
                      fontSize="sm"
                      mb={0}
                      textAlign="left"
                      fontWeight="semibold"
                      color="gray.900"
                    >
                      Select Metrics
                    </Text>
                  </HStack>
                  <Icon as={MdBuild} boxSize="5" color="gray.900" />
                </HStack>
                <Menu>
                  <MenuButton
                    as={Button}
                    w="100%"
                    variant="outline"
                    colorScheme="gray"
                    rightIcon={<Icon as={FaChevronDown} boxSize={3} />}
                    size="sm"
                    bg="white"
                    fontWeight={600}
                    fontSize="xs"
                    _hover={{ bg: "purple.50" }}
                    color={selectedMetricHeader ? "gray.800" : "gray.700"}
                    textAlign="left"
                  >
                    {selectedMetricHeader?.name || "View Metrics"}
                  </MenuButton>
                  <MenuList maxH="500px" overflowY="scroll" width="100%">
                    {metricHeaders.map((header) => (
                      <MenuItem
                        key={header.id}
                        onClick={() =>
                          handleMetricHeaderSelect({
                            target: { value: header.id },
                          } as React.ChangeEvent<HTMLSelectElement>)
                        }
                      >
                        <Text fontSize="xs" mb={0} fontWeight={600}>
                          {header.name}
                        </Text>
                      </MenuItem>
                    ))}
                  </MenuList>
                </Menu>
              </VStack>
            </Box>

            {/* Filters Selection */}
            <Box
              bg="gray.50"
              borderRadius="md"
              p={2}
              h="100%"
              borderWidth="1px"
              borderColor="gray.300"
            >
              <VStack textAlign="left" alignItems="left">
                <HStack justifyContent="space-between">
                  <HStack spacing={1}>
                    <Icon as={TbCircle2Filled} boxSize={5} />
                    <Text
                      fontSize="sm"
                      mb={0}
                      textAlign="left"
                      fontWeight="semibold"
                      color="gray.900"
                    >
                      Add Filters
                    </Text>
                  </HStack>
                  <Icon as={FaFilter} boxSize="4" color="gray.900" />
                </HStack>

                {selectedMetricHeader ? (
                  <VStack textAlign="left" alignItems="left" w="100%">
                    {!selectedAttribute && (
                      <Menu>
                        <MenuButton
                          as={Button}
                          w="100%"
                          variant="outline"
                          colorScheme="gray"
                          rightIcon={<Icon as={FaChevronDown} boxSize={3} />}
                          size="sm"
                          bg="white"
                          fontWeight={600}
                          fontSize="xs"
                          _hover={{ bg: "purple.50" }}
                          color={selectedAttribute ? "gray.800" : "gray.700"}
                          textAlign="left"
                        >
                          {selectedAttribute
                            ? String(selectedAttribute)
                            : "Select Attribute"}
                        </MenuButton>
                        <MenuList maxH="500px" overflowY="scroll" width="100%">
                          {Array.from(relatedAttributeSet)
                            .filter(
                              (attribute) =>
                                !selectedContextMap?.hasOwnProperty(String(attribute))
                            )
                            .map((attribute) => (
                              <MenuItem
                                key={String(attribute)}
                                onClick={() =>
                                  handleAttributeSelect({
                                    target: { value: String(attribute) },
                                  } as React.ChangeEvent<HTMLSelectElement>)
                                }
                              >
                                <Text fontSize="xs" mb={0} fontWeight={600}>
                                  {attribute}
                                </Text>
                              </MenuItem>
                            ))}
                        </MenuList>
                      </Menu>
                    )}

                    {selectedAttribute && (
                      <VStack width="100%" align="stretch">
                      <HStack width="100%" alignItems="center">
                        <Box flex="1">
                          <ColumnValues
                            contextValues={attributeValueMap[selectedAttribute]}
                            selectedOptions={selectedAttributesValues}
                            setSelectedOptions={setSelectedAttributesValues}
                          />
                        </Box>
                        <Button
                          size="xs"
                          variant="solid"
                          borderRadius="full"
                          onClick={() => {
                            setSelectedAttribute("")
                            setSelectedAttributesValues([])
                          }}
                          leftIcon={<Icon as={IoMdArrowRoundBack} boxSize={4} />}
                          colorScheme="gray"
                          color="gray.500"
                          iconSpacing={0}
                        />
                      </HStack>
                    </VStack>
                    
                    )}
                  </VStack>
                ) : (
                  <Text
                    color="gray.500"
                    fontSize="sm"
                    my={1}
                    fontWeight={500}
                    justifySelf="center"
                  >
                    Please select a metric
                  </Text>
                )}
              </VStack>
            </Box>

            {/* Benchmarks Selection */}
            <Box
              bg="gray.50"
              borderRadius="md"
              p={2}
              borderWidth="1px"
              borderColor="gray.300"
            >
              <VStack textAlign="left" alignItems="left">
                <HStack justifyContent="space-between">
                  <HStack spacing={1}>
                    <Icon as={TbCircle3Filled} boxSize={5} />
                    <Text
                      fontSize="sm"
                      mb={0}
                      textAlign="left"
                      fontWeight="semibold"
                      color="gray.900"
                    >
                      Choose Targets
                    </Text>
                  </HStack>
                  <Icon as={TbTargetArrow} boxSize="5" color="gray.900" />
                </HStack>

                {selectedMetricHeader ? (
                  <ColumnValues
                    contextValues={chooseTargets}
                    selectedOptions={customBenchmarkName}
                    setSelectedOptions={setCustomBenchmarkName}
                  />
                ) : (
                  <Text
                    color="gray.500"
                    fontSize="sm"
                    my={1}
                    fontWeight={500}
                    justifySelf="center"
                  >
                    Please select a metric
                  </Text>
                )}
              </VStack>
            </Box>

            {/* Exclusions Selection */}
            <Box
              bg="gray.50"
              borderRadius="md"
              p={2}
              borderWidth="1px"
              borderColor="gray.300"
            >
              <VStack textAlign="left" alignItems="left">
                <HStack justifyContent="space-between">
                  <HStack spacing={1}>
                    <Icon as={TbCircle4Filled} boxSize={5} />
                    <Text
                      fontSize="sm"
                      mb={0}
                      textAlign="left"
                      fontWeight="semibold"
                      color="gray.900"
                    >
                      Exclude from Step
                    </Text>
                  </HStack>
                  <Icon as={TiCancel} boxSize="5" color="gray.900" />
                </HStack>
                {selectedMetricHeader ? (
                  <ColumnValues
                    contextValues={excludeEdges}
                    selectedOptions={selectedEdgeToExclude}
                    setSelectedOptions={setSelectedEdgeToExclude}
                  />
                ) : (
                  <Text
                    color="gray.500"
                    fontSize="sm"
                    my={1}
                    fontWeight={500}
                    justifySelf="center"
                  >
                    Please select a metric
                  </Text>
                )}
              </VStack>
            </Box>
          </Grid>

          {/* Bottom Section: Analysis Type, Actions, Run/Save Buttons */}
          <Grid
            templateColumns="1fr 2fr 1fr"
            templateRows="1fr"
            h="100%"
            w="100%"
            gap={2}
          >
            {/* Analysis Type Section */}
            <Box
              bg="gray.50"
              borderRadius="md"
              p={2}
              borderWidth="1px"
              borderColor="gray.300"
            >
              <VStack textAlign="left" alignItems="left">
                <HStack justifyContent="space-between">
                  <HStack spacing={1}>
                    <Icon as={TbCircle5Filled} boxSize={5} />
                    <Text
                      fontSize="sm"
                      mb={0}
                      textAlign="left"
                      fontWeight="semibold"
                      color="gray.900"
                    >
                      Define Analysis
                    </Text>
                  </HStack>
                  <Icon as={FaChartPie} boxSize="4" color="gray.900" />
                </HStack>

                {/* Dropdown for Analysis */}
                <Box w="100%">
                  {selectedMetricHeader ? (
                    <Menu>
                      <MenuButton
                        as={Button}
                        w="100%"
                        variant="outline"
                        colorScheme="gray"
                        rightIcon={<Icon as={FaChevronDown} boxSize={3} />}
                        size="sm"
                        bg="white"
                        fontWeight={600}
                        fontSize="xs"
                        color="gray.700"
                        textAlign="left"
                        _hover={{ bg: "purple.50" }}
                      >
                        {selectedPurpose || "Analysis Type"}
                      </MenuButton>
                      <MenuList>

                        {/* Performance Analysis Option */}
                        <MenuItem
                          icon={<Icon as={FaChartLine} boxSize={4} />}
                          onClick={() => setSelectedPurpose("Growth")}
                        >
                          <Text fontSize="sm" fontWeight="semibold" mb={0}>
                            Growth
                          </Text>
                          <Text fontSize="xs" color="gray.600" mb={0}>
                            Metric Performance vs past period
                          </Text>
                        </MenuItem>

                        <MenuDivider />

                        {/* Diagnostic Analysis Option */}
                        <MenuItem
                          icon={<Icon as={FaStethoscope} boxSize={4} />}
                          onClick={() => setSelectedPurpose("Diagnostic")}
                        >
                          <Text fontSize="sm" fontWeight="semibold" mb={0}>
                            Diagnostic
                          </Text>
                          <Text fontSize="xs" color="gray.600" mb={0}>
                            Health check of KPIs
                          </Text>
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  ) : (
                    <Text
                      color="gray.500"
                      fontSize="sm"
                      my={1}
                      fontWeight={500}
                      justifySelf="center"
                    >
                      Please select a metric
                    </Text>
                  )}
                </Box>
              </VStack>
            </Box>

            {/* Actions Section */}
            <Box
              bg="gray.50"
              borderRadius="md"
              p={2}
              borderWidth="1px"
              borderColor="gray.300"
            >
              <VStack textAlign="left" alignItems="left">
                <HStack justifyContent="space-between">
                  <HStack spacing={1}>
                    <Icon as={TbCircle6Filled} boxSize={5} />
                    <Text
                      fontSize="sm"
                      mb={0}
                      textAlign="left"
                      fontWeight="semibold"
                      color="gray.900"
                    >
                      Set Actions
                    </Text>
                  </HStack>
                  <Icon as={FaHandSparkles} boxSize="5" color="gray.900" />
                </HStack>

                {selectedMetricHeader ? (
                  <HStack spacing={3}>
                    {/* PPT EXPORT */}
                    <Box flex="1">
                      <Popover
                        isOpen={pptExportPopover.isOpen}
                        onClose={pptExportPopover.onClose}
                        placement="right"
                        isLazy
                      >
                        <PopoverTrigger>
                          <Button
                            variant={slideName ? "solid" : "outline"}
                            colorScheme={slideName ? "green" : "gray"}
                            onClick={() => {
                              handleActionClick("action1");
                              pptExportPopover.onOpen();
                            }}
                            leftIcon={<PiFilePpt />}
                            rightIcon={
                              slideName ? (
                                <Icon
                                  as={FaCheck}
                                  boxSize={3}
                                  color="green.600"
                                />
                              ) : (
                                <></>
                              )
                            }
                            w="100%"
                            bg={slideName ? "green.50" : "white"}
                            color={slideName ? "green.700" : "black"}
                            fontWeight={slideName ? "700" : "600"}
                            borderColor={slideName ? "green.500" : "black"}
                            borderWidth={slideName ? "2px" : "1px"}
                            fontSize="xs"
                            size="xs"
                            _hover={{ bg: slideName ? "green.100" : "purple.50" }}
                          >
                            Add to Report
                          </Button>
                        </PopoverTrigger>
                        <PopoverContent>
                          <PopoverArrow />
                          <IconButton
                            aria-label="Confirm and close"
                            icon={<FaCheck />}
                            position="absolute"
                            top="4px"
                            right="4px"
                            onClick={pptExportPopover.onClose}
                            variant="ghost"
                            colorScheme="green"
                            size="xs"
                          />
                          <PopoverHeader fontWeight={600} fontSize="xs">
                            Enter Page name
                          </PopoverHeader>
                          <PopoverBody>
                            <Input
                              placeholder="Enter Slide Name"
                              size="sm"
                              value={slideName}
                              onChange={(e) => setSlideName(e.target.value)}
                            />
                          </PopoverBody>
                        </PopoverContent>
                      </Popover>
                    </Box>

                    {/* SLACK NOTIFICATION */}
                    <Box flex="1">
                      <Popover
                        isOpen={slackPopover.isOpen}
                        onClose={slackPopover.onClose}
                        placement="right"
                        isLazy
                      >
                        <PopoverTrigger>
                          <Button
                            variant={slackChannelId ? "solid" : "outline"}
                            colorScheme={slackChannelId ? "green" : "gray"}
                            onClick={() => {
                              handleActionClick("action2");
                              slackPopover.onOpen();
                            }}
                            leftIcon={<Icon as={FaSlack} boxSize={5} />}
                            rightIcon={
                              slackChannelId ? (
                                <Icon
                                  as={FaCheck}
                                  boxSize={3}
                                  color="green.600"
                                />
                              ) : (
                                <></>
                              )
                            }
                            w="100%"
                            bg={slackChannelId ? "green.50" : "white"}
                            color={slackChannelId ? "green.700" : "black"}
                            fontWeight={slackChannelId ? "700" : "600"}
                            borderColor={slackChannelId ? "green.500" : "black"}
                            borderWidth={slackChannelId ? "2px" : "1px"}
                            fontSize="xs"
                            size="xs"
                            _hover={{ bg: slackChannelId ? "green.100" : "green.50" }}
                          >
                            Notify on Slack
                          </Button>
                        </PopoverTrigger>
                        <PopoverContent>
                          <PopoverArrow />
                          <IconButton
                            aria-label="Confirm and close"
                            icon={<FaCheck />}
                            position="absolute"
                            top="4px"
                            right="4px"
                            onClick={() => slackPopover.onClose()}
                            variant="ghost"
                            colorScheme="green"
                            size="xs"
                          />
                          <PopoverHeader fontWeight={600} fontSize="xs">
                            Enter Slack Channel
                          </PopoverHeader>
                          <PopoverBody>
                            <Input
                              placeholder="Enter Channel ID"
                              size="sm"
                              value={slackChannelId}
                              onChange={(e) =>
                                setSlackChannelId(e.target.value)
                              }
                            />
                          </PopoverBody>
                        </PopoverContent>
                      </Popover>
                    </Box>
                  </HStack>
                ) : (
                  <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                    <Text
                      color="gray.500"
                      fontSize="sm"
                      my={1}
                      fontWeight={500}
                      textAlign="center"
                    >
                      Please select a metric
                    </Text>
                  </Box>
                )}
              </VStack>
            </Box>

            {/* Run and Save Buttons */}
            <HStack spacing={3} p={2}>
              <Button
                flex="1"
                onClick={() => handleSaveJobStep(false)}
                colorScheme="green"
                _hover={{ bg: "green.700" }}
                bg="green.500"
                size="lg"
                rightIcon={<Icon as={FaSave} boxSize={4} />}
              >
                <Text mb={0} fontSize="sm" fontWeight="bold">
                  SAVE
                </Text>
              </Button>

              <Button
                flex="1"
                onClick={() => {
                  handleRunStepWithValidation();
                }}
                colorScheme="purple"
                _hover={{ bg: "purple.800" }}
                bg="purple.700"
                size="lg"
                rightIcon={<Icon as={FaExternalLinkAlt} boxSize={3} />}
                isLoading={runStepLoader} // This will show the spinner and disable the button
                loadingText="Running" // Optional: Text to show while loading
                spinnerPlacement="end" // Spinner placement
              >
                <Text mb={0} fontSize="sm" fontWeight="bold">
                  RUN STEP
                </Text>
              </Button>
            </HStack>
          </Grid>
        </VStack>
      </Flex>

      {/* Error Modal */}
      <Modal isOpen={errorModal.isOpen} onClose={errorModal.onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Cannot complete Request</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text color="red.500">{errorMessage}</Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={errorModal.onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default StepConfigureContainer;