import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { UserProvider } from "./context/UserContext";
import { ChakraProvider } from "@chakra-ui/react";
import { URLContextProvider } from "./context/URLContext";
import { SidebarContextProvider } from "./context/CommentarySidebarContext";
import { AnalysisContext } from "./context/AnalysisContext";
import { AnalysisDrawerContextProvider } from "./context/AnalysisDrawerContext";
import { ReactFlowProvider } from "@xyflow/react";
import mainTheme from "./components/common/Theme";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <URLContextProvider>
      <UserProvider>
        <AnalysisContext>
          <SidebarContextProvider>
            <AnalysisDrawerContextProvider>
              <ChakraProvider theme={mainTheme}>
                <ReactFlowProvider>
                  <App />
                </ReactFlowProvider>
              </ChakraProvider>
            </AnalysisDrawerContextProvider>
          </SidebarContextProvider>
        </AnalysisContext>
      </UserProvider>
    </URLContextProvider>
  </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
