import React, { useEffect } from "react";
import {
  DataSet,
  Network,
  Options,
} from "vis-network/standalone/esm/vis-network";
import "vis-network/styles/vis-network.css";
import { Metric } from "../../../containers/Interfaces";

interface VisualizeGraphProps {
  useMetricKnowledge?: Metric;
}

const VisualizeGraph: React.FC<VisualizeGraphProps> = ({
  useMetricKnowledge,
}) => {
  useEffect(() => {
    if (!useMetricKnowledge) return;

    const nodesData: any[] = [];
    const edgesData: any[] = [];

    // Main metric node
    nodesData.push({
      id: useMetricKnowledge.header.id,
      label: useMetricKnowledge.header.name,
      color: {
        background: "#481c64",
        border: "#481c64",
        highlight: {
          color: "white", // specify a color for highlight
          background: "#481c64", // specify a background color for highlight
          border: "#481c64"
        },
      },
      font: {
        color: "white",
        highlight: {
          color: "white",
        },
      },
      shape: "box",
      type: "metric",
    });

    useMetricKnowledge.edges?.forEach((edge) => {
      let targetId;
      let targetLabel;
      let targetColor = { background: "", border: "", highlight: { color: "white", background: "", border: "" } };

      switch (edge.edge_type) {
        case 'ATTRIBUTE_EDGE':
          targetId = edge.related_attribute?.id;
          targetLabel = edge.related_attribute?.name;
          targetColor = {
            background: "#3182ce",
            border: "#3182ce",
            highlight: {
              color: "white",
              background: "#2b6cb0",
              border: "#2b6cb0"
            }
          };
          break;
        case 'BENCHMARK_EDGE':
          targetId = edge.benchmark?.header.id;
          targetLabel = edge.benchmark?.header.name;
          targetColor = {
            background: "#DD6B20",
            border: "#DD6B20",
            highlight: {
              color: "white",
              background: "#C05621",
              border: "#C05621"
            }
          };
          break;
        case 'FUNDAMENTAL_EDGE':
          // Handle correlations directly to metric node
          edge.fundamental_relationship?.correlations.forEach((corr, index) => {
            const corrId = `${edge.fundamental_relationship?.header?.id}-corr-${index}`;
            nodesData.push({
              id: corrId,
              label: `${corr?.related_column_header?.name && corr.related_metric_header?.name ? 
                `${corr.related_column_header.name} ${corr.related_metric_header.name}` :
                corr?.related_column_header?.name || corr.related_metric_header?.name || ''
              }`,
              color: {
                background: "#38A169",
                border: "#38A169",
                highlight: {
                  color: "white",
                  background: "#2F855A",
                  border: "#2F855A"
                },
              },
              font: {
                color: "white",
                highlight: {
                  color: "white", // specify a color for highlight
                },
              },
              shape: "box",
              type: "fundamental"
            });
            edgesData.push({
              from: useMetricKnowledge.header.id,
              to: corrId,
              arrows: "to",
              label: `${edge?.fundamental_relationship?.header?.name} (Wt: ${edge?.edge_weight}, Correlation: ${corr.correlation_factor})`,
              font: {
                align: "top",
                size: 16,
              },
              color: { color: "#38A169", highlight: "#2F855A", hover: "#fff2f8" },
            });
          });
          return; // Prevent further processing in this case
      }

      // Adding nodes and edges for attribute and benchmark types
      if (targetId && targetLabel) {
        nodesData.push({
          id: targetId,
          label: targetLabel,
          color: targetColor,
          font: {
            color: "white",
            highlight: {
              color: "white",
            },
          },
          shape: "box",
          type: edge.edge_type === "BENCHMARK_EDGE" ? "benchmark" : "attribute"
        });
        edgesData.push({
          from: useMetricKnowledge.header.id,
          to: targetId,
          arrows: "to",
          label: `${edge.edge_type === "ATTRIBUTE_EDGE" ? "Dimension" : edge.edge_type === "BENCHMARK_EDGE" ? "Benchmark" : "UNKNOWN"} (wt: ${edge.edge_weight})`,
          font: {
            align: "top",
            size: 16,
            color: targetColor.background
          },
          color: { color: targetColor.border, highlight: targetColor.background, hover: "#fff2f8" },
        });
      }
    });

    // DataSet instances for nodes and edges
    const nodes = new DataSet(nodesData);
    const edges = new DataSet(edgesData);

    // Network visualization
    const container = document.getElementById("mynetwork");
    const data = { nodes, edges };
    const options: Options = {
      nodes: {
        shape: "box",
        font: {
          size: 21,
          face: "arial",
        },
        borderWidth: 2,
        borderWidthSelected: 4,
      },
      edges: {
        arrows: { to: { enabled: true, scaleFactor: 1 } }, // Add arrows to edges
        width: 2,
        smooth: {
          enabled: true,
          type: "continuous",
          roundness: 0.6,
        },
      },
      layout: {
        hierarchical: {
          enabled: true,
          levelSeparation: 840, // Adjust the separation between levels
          nodeSpacing: 100, // Adjust the spacing between nodes
          treeSpacing: 300, // Adjust the spacing between trees
          direction: 'LR', // Direction can be 'UD' (Up-Down), 'DU' (Down-Up), 'LR' (Left-Right), 'RL' (Right-Left)
          sortMethod: 'directed', // Can be 'hubsize' or 'directed'
        },
        improvedLayout: false,
      },
      physics: {
        enabled: false, // Disable physics to make the network static
      },
      interaction: {
        dragNodes: true,
        zoomView: true,
        dragView: true,
      },
    };
    

    const network = new Network(container!, data, options);
    network.on("stabilizationIterationsDone", function () {
      network.setOptions({ physics: { enabled: false } });
    });
    // Cleanup function
    return () => network.destroy();
  }, [useMetricKnowledge]);

  return (
    <div
      id="mynetwork"
      style={{
        height: "80vh",
      }}
    ></div>
  );
};

export default VisualizeGraph;