// Utility function to sort an array of objects by a nested key
export const sortBy = <T>(array: T[], keyExtractor: (item: T) => any, order: 'asc' | 'desc' = 'asc'): T[] => {
    return [...array].sort((a, b) => {
      const valA = keyExtractor(a);
      const valB = keyExtractor(b);
  
      // If values are strings, compare them lexicographically
      if (typeof valA === 'string' && typeof valB === 'string') {
        if (order === 'asc') {
          return valA.localeCompare(valB);
        } else {
          return valB.localeCompare(valA);
        }
      }
  
      // If values are numbers (e.g., timestamps), compare them numerically
      if (typeof valA === 'number' && typeof valB === 'number') {
        return order === 'asc' ? valA - valB : valB - valA;
      }
  
      // Fallback for other types
      if (valA > valB) return order === 'asc' ? 1 : -1;
      if (valA < valB) return order === 'asc' ? -1 : 1;
      return 0;
    });
  };
  