import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { Header } from "../../containers/Interfaces";
import { getTreeHeaders } from "../../services/AnalysisTreeServices";
import { listMetrics } from "../../services/MetricService";
import { Page, Story } from "../../containers/export/Interface";
import {
  createUpdatePage,
  createUpdateReport,
  exportContent,
  getAllReports,
  getReport,
} from "../../services/ReportPageServices";
import showToast from "../../hooks/useCustomToast";

// Define the interface for the context state
interface ExportContextState {
  treeHeaders: Header[];
  metricHeaders: Header[];
  selectedTreeHeaders: Header[];
  searchMetrics: string[];
  pages: Page[];
  stories: Story[];
  selectedPageId: string;
  selectedStoryId: string;
  currentPage: number;
  cachedPages: Map<number, Header[]>;
  hasMorePages: boolean;
  searchString: string;
  sortBy: string;
  setSearchMetrics: React.Dispatch<React.SetStateAction<string[]>>;
  setSearchString: React.Dispatch<React.SetStateAction<string>>;
  setPages: React.Dispatch<React.SetStateAction<Page[]>>;
  setStories: React.Dispatch<React.SetStateAction<Story[]>>;
  setSelectedPageId: React.Dispatch<React.SetStateAction<string>>;
  setSelectedStoryId: React.Dispatch<React.SetStateAction<string>>;
  setSelectedTreeHeaders: React.Dispatch<React.SetStateAction<Header[]>>;
  setTreeHeaders: React.Dispatch<React.SetStateAction<Header[]>>;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  setCachedPages: React.Dispatch<React.SetStateAction<Map<number, Header[]>>>;
  handleTreeHeaderSelection: (header: Header) => void;
  handleSortingOrderChange: (value: string) => void;
  handleAssignHeadersToPage: () => void;
  handleAssignPagesToStory: () => void;
  handleSelectPage: (id: string) => void;
  handleSelectStory: (id: string) => void;
  handleKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  handleExport: (reportType: string, id: string) => Promise<void>;
  getTrees: (number: number) => Promise<void>;
  isLoading: boolean;
}

// Create the context
const ExportContext = createContext<ExportContextState | undefined>(
  undefined
);

// Custom hook to use the context
export const useExportContext = () => {
  const context = useContext(ExportContext);
  if (context === undefined) {
    throw new Error(
      "useExportContext must be used within a ExportProvider"
    );
  }
  return context;
};

interface ExportProviderProps {
  children: ReactNode;
}
export const ExportContextProvider: React.FC<ExportProviderProps> = ({
  children,
}) => {
  const [treeHeaders, setTreeHeaders] = useState<Header[]>([]);
  const [metricHeaders, setMetricHeaders] = useState<Header[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedTreeHeaders, setSelectedTreeHeaders] = useState<Header[]>([]);
  const [searchMetrics, setSearchMetrics] = useState<string[]>([]);
  const [sortBy, setSortBy] = useState<string>("LAST_MODIFIED");
  const [pages, setPages] = useState<Page[]>([]);
  const [stories, setStories] = useState<Story[]>([]);
  const [selectedPageId, setSelectedPageId] = useState<string>("");
  const [selectedStoryId, setSelectedStoryId] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [cachedPages, setCachedPages] = useState<Map<number, Header[]>>(new Map());
  const [searchString, setSearchString] = useState<string>("");
  const [hasMorePages, setHasMorePages] = useState<boolean>(true);

  const pageSize: number = 24;

  // Handle tree header selection
  const handleTreeHeaderSelection = (header: Header) => {
    if (!selectedTreeHeaders.some((selected) => selected.id === header.id)) {
      setSelectedTreeHeaders([...selectedTreeHeaders, header]);
    } else {
      setSelectedTreeHeaders(
        selectedTreeHeaders.filter((selected) => selected.id !== header.id)
      );
    }
  };

  const handleSortingOrderChange = (value: string) => {
    setSortBy(value);
  };

  const getTrees = async (pageNumber: number, resetPage: boolean = false) => {
    setIsLoading(true);
    try {
      const response = await getTreeHeaders(
        sortBy,
        searchMetrics,
        pageSize,
        pageNumber,
        searchString
      );
      if (response && response.length > 0) {
        setTreeHeaders(response);
        const newHeaders = response;
        cachedPages.set(pageNumber, newHeaders);
        setCachedPages(new Map(cachedPages));
        setHasMorePages(response.length === pageSize);
      } else {
        setHasMorePages(false); // No more pages to fetch
        setCachedPages(new Map());
        setTreeHeaders(response);
      }
    } catch (err) {
      alert("Error in getting tree headers" + err);
    } finally {
      setIsLoading(false);
      if (resetPage) {
        setCurrentPage(1);
      }
    }
  };

  const handleSelectPage = (pageId: string) => {
    setSelectedPageId(pageId);
    const selectedPage = pages.find((page) => page.header.id === pageId);
    if (selectedPage && selectedPage.tree_headers) {
      setSelectedTreeHeaders(selectedPage.tree_headers);
      console.log("Headers for the selected page:", selectedPage.tree_headers);
    } else {
      console.log("No headers found for this page.");
    }
  };

  const handleSelectStory = async(storyId: string) => {
    setSelectedStoryId(storyId);
    setSelectedPageId("");
    setSelectedTreeHeaders([]);
    const selectedStory: Story =
      stories && stories.find((story) => story.header.id === storyId)!;
    const response: Story = await getReport(selectedStory?.header.id!, "REPORT_BOOK");
    if (response) {
      setPages(response?.pages!);
    } else {
      setPages([]);
    }
  };

  const handleAssignHeadersToPage = async () => {
    if (selectedPageId !== "") {
      const page = pages.find((p) => p.header.id === selectedPageId);
      if (!page) {
        showToast("No page selected to assign", "", "error");
        return;
      }

      const updatedPage = {
        ...page,
        tree_headers: [...selectedTreeHeaders],
      };
      const response = await createUpdatePage(updatedPage);
      const updatedPages =
        pages &&
        pages.map((page) =>
          page.header.id === selectedPageId ? response : page
        );

      setPages(updatedPages);
      handleAssignPagesToStory();
    } else {
      showToast("No page selected to assign", "", "error")
    }
  };

  const handleAssignPagesToStory = async () => {
    if (selectedStoryId !== "") {
      const story = stories.find((s) => s.header.id === selectedStoryId);
      if (!story) {
        showToast("No story selected to assign", "", "error");
        return;
      }

      try {
        const updatedStory = {
          ...story,
          pages: pages,
        };
        const response = await createUpdateReport(updatedStory);
        const updatedStories = stories.map((story) =>
          story.header.id === selectedStoryId ? response : story
        );

        setStories(updatedStories);
        showToast(`Pages assigned to ${story.header.name}`, "", "success");
      } catch (error) {
        showToast("Failed to update story", "", "error");
      }
    } else {
    showToast("No story selected", "", "info")
    }
  };

  const handleKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      const response = await getTreeHeaders(
        sortBy,
        searchMetrics,
        pageSize,
        0,
        searchString
      );
      setTreeHeaders(response);
      if (response && response.length > 0) {
        setTreeHeaders(response);
        const newHeaders = response;
        cachedPages.set(1, newHeaders);
        setCachedPages(new Map(cachedPages));
        setHasMorePages(response.length === pageSize);
        setCurrentPage(1);
      } else {
        setHasMorePages(false);
        setCachedPages(new Map());
        setTreeHeaders([]);
      }
    }
  };

  const handleExport = async (exportType: string, id: string) => {
    await exportContent(exportType, id);
  };

  useEffect(() => {
    const getMetricHeaders = async () => {
      try {
        const response = await listMetrics("");
        setMetricHeaders(response);
      } catch (err) {
        alert("Error in getting tree headers" + err);
      }
    };
    const getReports = async () => {
      try {
        const response = await getAllReports("REPORT_BOOK");
        if(response){
          const stories = 
          response.map((header: Header) => {
            setStories((prev) => [...prev, {header: header}]);
          })
        }
        
      } catch (err) {
        alert("Error in getting tree headers" + err);
      }
    };
    getMetricHeaders();
    getReports();
  }, []);

  // Fetch data whenever currentPage changes
  useEffect(() => {
    getTrees(currentPage);
  }, [currentPage]);

  // Reset currentPage and fetch data when sortBy or searchMetrics change
  useEffect(() => {
    setCurrentPage(1); // Reset to the first page
    getTrees(1, true);
  }, [sortBy, searchMetrics]);

  return (
    <ExportContext.Provider
      value={{
        metricHeaders,
        treeHeaders,
        selectedTreeHeaders,
        searchMetrics,
        pages,
        stories,
        selectedPageId,
        selectedStoryId,
        currentPage,
        cachedPages,
        hasMorePages,
        searchString,
        sortBy,
        setSearchString,
        setCachedPages,
        setTreeHeaders,
        setSearchMetrics,
        setPages,
        setStories,
        setSelectedPageId,
        setSelectedStoryId,
        setSelectedTreeHeaders,
        setCurrentPage,
        handleTreeHeaderSelection,
        handleSortingOrderChange,
        handleAssignHeadersToPage,
        handleSelectPage,
        handleSelectStory,
        handleAssignPagesToStory,
        handleKeyDown,
        handleExport,
        getTrees,
        isLoading,
      }}
    >
      {children}
    </ExportContext.Provider>
  );
};