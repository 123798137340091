import React, { useState } from "react";
import {
  BenchmarkEdge,
  ColumnInfo,
  Correlations,
  FormData,
  FundamentalEdge,
  Header,
  RelatedAttributeEdge,
} from "../../../containers/Interfaces";
import { BenchmarkFilter } from "../../../containers/commentary/Interfaces";
import {
  Badge,
  Box,
  Button,
  Checkbox,
  CloseButton,
  Collapse,
  Container,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  List,
  ListItem,
  Select,
  Tag,
  TagCloseButton,
  TagLabel,
  VStack,
  Text,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal
} from "@chakra-ui/react";
import { DeleteIcon, AddIcon, ChevronDownIcon } from "@chakra-ui/icons";
import { FaCheckCircle } from "react-icons/fa";
import { MdFilterAltOff, MdFilterAlt } from "react-icons/md";
import { TbCalculatorOff, TbCalculator } from "react-icons/tb";
import { ColumnType } from "../../../utils/enum";
interface CreateMKG1Props {
  metricInfo: FormData | null;
  columns: ColumnInfo[] | null;
  measureColumn: Header[] | null;
  attributeColumn: Header[] | null;
  allMetrics: Header[] | null;
  relatedAttributeEdge: RelatedAttributeEdge[] | null;
  selectedRelatedAttributeColumn: Header | null;
  setSelectedRelatedAttributeColumn: React.Dispatch<
    React.SetStateAction<Header | null>
  >;
  relatedAttributeEdgeWeight: number;
  setRelatedAttributeEdgeWeight: React.Dispatch<React.SetStateAction<number>>;
  benchmarkEdge: BenchmarkEdge[];
  benchmarkFilter: BenchmarkFilter[] | null;
  benchmarkName: string;
  setBenchmarkName: React.Dispatch<React.SetStateAction<string>>;
  benchmarkVarienceTolerance: number | undefined;
  setBenchmarkVarienceTolerance: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
  benchmarkFilterValue: string;
  setBenchmarkFilterValue: React.Dispatch<React.SetStateAction<string>>;
  selectedBenchmarkFilterColumn: ColumnInfo | null;
  setSelectedBenchmarkFilterColumn: React.Dispatch<
    React.SetStateAction<ColumnInfo | null>
  >;
  benchmarkEdgeWeight: number;
  setBenchmarkEdgeWeight: React.Dispatch<React.SetStateAction<number>>;
  fundamentalEdgeName: string;
  setFundamentalEdgeName: React.Dispatch<React.SetStateAction<string>>;
  fundamentalEdgeWeight: number;
  setFundamentalEdgeWeight: React.Dispatch<React.SetStateAction<number>>;
  selectedType: string;
  setSelectedType: React.Dispatch<React.SetStateAction<string>>;
  setSelectedHeader: React.Dispatch<React.SetStateAction<string>>;
  correlationFactor: string | "";
  setCorrelationFactor: React.Dispatch<React.SetStateAction<string | "">>;
  correlations: Correlations[];
  fundamentalEdges: FundamentalEdge[];
  handleAddRelatedAttributeEdge: () => void;
  handleRemoveRelatedAttribute: (id: string) => void;
  handleAddBenchmarkFilter: () => void;
  handleAddBenchmark: () => void;
  handleRemoveBenchmarkFilter: (id: string) => void;
  handleRemoveBenchmarkEdge: (index: number) => void;
  handleAddCorrelation: () => void;
  handleRemoveCorrelation: (index: number) => void;
  handleAddFundamentalEdges: () => void;
  handleRemoveFundamentalEdges: (index: number) => void;
  submitForm: () => void;
  isAdditive: boolean;
  setIsAdditive: React.Dispatch<React.SetStateAction<boolean>>
  isFilterMenuOpen: boolean;
  isCorrelationMenuOpen: boolean;
  toggleCorrelationMenu: () => void;
  toggleFilterMenu: () => void;
  columnNames: string[];
  selectedColumnValues: string[];
  handleToggle: (value: string) => void;
}

const CreateMKG1: React.FC<CreateMKG1Props> = ({
  metricInfo,
  columns,
  allMetrics,
  attributeColumn,
  measureColumn,
  handleAddRelatedAttributeEdge,
  handleRemoveRelatedAttribute,
  handleAddBenchmarkFilter,
  handleAddBenchmark,
  handleRemoveBenchmarkFilter,
  handleRemoveBenchmarkEdge,
  handleAddCorrelation,
  handleRemoveCorrelation,
  handleAddFundamentalEdges,
  handleRemoveFundamentalEdges,
  submitForm,
  benchmarkEdge,
  selectedRelatedAttributeColumn,
  setSelectedRelatedAttributeColumn,
  relatedAttributeEdge,
  relatedAttributeEdgeWeight,
  setRelatedAttributeEdgeWeight,
  benchmarkName,
  setBenchmarkName,
  benchmarkVarienceTolerance,
  setBenchmarkVarienceTolerance,
  benchmarkFilterValue,
  setBenchmarkFilterValue,
  benchmarkEdgeWeight,
  setBenchmarkEdgeWeight,
  fundamentalEdgeName,
  setFundamentalEdgeName,
  fundamentalEdgeWeight,
  setFundamentalEdgeWeight,
  selectedBenchmarkFilterColumn,
  setSelectedBenchmarkFilterColumn,
  selectedType,
  setSelectedType,
  setSelectedHeader,
  correlations,
  correlationFactor,
  setCorrelationFactor,
  benchmarkFilter,
  fundamentalEdges,
  isAdditive,
  setIsAdditive,
  isFilterMenuOpen,
  isCorrelationMenuOpen,
  toggleCorrelationMenu,
  toggleFilterMenu,
  selectedColumnValues,
  handleToggle,
  columnNames
}) => {
  return (
    <Container maxW="container.xl">
      <VStack align="stretch" spacing={4}>
        <Box bg="white">
          <Button
            colorScheme="green"
            onClick={submitForm}
            w="100%"
            fontWeight="bold"
            fontSize="lg"
            leftIcon={<FaCheckCircle />}
          >
            Save
          </Button>
        </Box>
        <HStack spacing={4}>
          {/* Metric Details */}
          <Box
            flex="1"
            border="1px"
            borderColor="gray.300"
            p={3}
            borderRadius="lg"
            bg="gray.50"
            alignSelf="stretch"

          >
            <VStack align="stretch" spacing={2}>
              <Text fontSize="sm" fontWeight="bold" mb={0} w="100%">Metric Details</Text>
              <HStack>
                <Text fontWeight="bold" fontSize="sm" mb={0}>Name:</Text>
                <Text fontWeight="medium" fontSize="sm" mb={0}>{metricInfo?.metrics[0].header.name || "N/A"}</Text>
              </HStack>
              <HStack>
                <Text fontWeight="bold" fontSize="sm" mb={0}>Description:</Text>
                <Text fontWeight="medium" fontSize="sm" mb={0}>{metricInfo?.metrics[0].header.description || "N/A"}</Text>
              </HStack>
              <HStack>
                <Text fontWeight="bold" fontSize="sm" mb={0}>Model ID:</Text>
                <Text fontWeight="medium" fontSize="sm" color="fg.muted" mb={0}>
                  {metricInfo?.model_id || "N/A"}
                </Text>
              </HStack>
            </VStack>
          </Box>
          {/* Dimension relationships */}
          <Box
            flex="1"
            border="1px"
            borderColor="gray.300"
            p={3}
            borderRadius="lg"
            bg="gray.50"
            alignSelf="stretch"
            display="flex"
            flexDirection="column"
          >

            <VStack align="stretch" spacing={3} p={2}>
              <Text fontSize="sm" fontWeight="bold" mb={0} w="100%">Related Dimensions</Text>
              {relatedAttributeEdge &&
                relatedAttributeEdge.map((option, index) => (
                  <Box
                    key={index}
                    width="100%"
                    display="flex"
                    flexWrap="wrap"
                  >
                    <Tag
                      flexGrow={1}
                      bg="bg.surface"
                      borderWidth="1px"
                      borderRadius="lg"
                      borderColor="purple.700"
                      p={1}
                    >
                      <TagLabel flexGrow={1} textAlign="left" ml={1}>
                        <Text display="inline" color="fg.default" fontWeight={600} mb={0}> {option.related_attribute?.name}</Text><Text display="inline" color="fg.muted" mb={0}> (wt: {option.edge_weight})</Text>
                      </TagLabel>
                      <Button
                        variant="tertiary"
                        size="xs"
                        leftIcon={<DeleteIcon boxSize="0.8rem" />}
                        color="red.600"
                        onClick={() =>
                          handleRemoveRelatedAttribute(
                            option.related_attribute?.id!
                          )
                        }
                      >
                        Clear
                      </Button>
                    </Tag>
                  </Box>
                ))}
              <HStack>
                <Select
                  value={selectedRelatedAttributeColumn?.id || ""}
                  onChange={(e) => {
                    const selectedId = e.target.value;
                    const selected =
                      attributeColumn &&
                      attributeColumn.find((col) => col.id === selectedId);
                    setSelectedRelatedAttributeColumn(selected || null);
                  }}
                  bg="bg.surface"
                  fontSize="sm"
                >
                  <option value="">Select Dimension</option>
                  {attributeColumn &&
                    attributeColumn
                      .filter((column) =>
                        relatedAttributeEdge
                          ? !relatedAttributeEdge.find(
                            (option) => option.related_attribute?.id === column.id
                          )
                          : []
                      )
                      .map((column) => (
                        <option key={column.id} value={column.id}>
                          {column.name}
                        </option>
                      ))}
                </Select>
                <Input
                  type="number"
                  placeholder="Weight (0-1)"
                  value={relatedAttributeEdgeWeight}
                  onChange={(e) =>
                    setRelatedAttributeEdgeWeight(parseFloat(e.target.value))
                  }
                  fontSize="sm"
                />
              </HStack>

            </VStack>
            <Flex gap={4} w="100%" mt="auto" p={2}>
              <Button
                onClick={handleAddRelatedAttributeEdge}
                colorScheme="purple"
                leftIcon={<AddIcon boxSize="0.9rem" />}
                fontWeight="bold"
                w="100%"
              >
                Add Dimension Edge
              </Button>
            </Flex>
          </Box>
        </HStack>
        <HStack spacing={4}>
          {/* Benchmark relationships */}
          <Box
            flex="1"
            border="1px"
            borderColor="gray.300"
            p={3}
            borderRadius="lg"
            bg="gray.50"
            alignSelf="stretch"
            display="flex"
            flexDirection="column"
          >
            <VStack alignSelf="stretch" align="stretch" spacing={3} p={2}>
              <Text fontSize="sm" fontWeight="bold" w="100%" mb={0}>Related Benchmarks</Text>
              {benchmarkEdge && benchmarkEdge.map((benchmarkForm, index) => (
                <HStack
                  flexGrow={1}
                  bg="bg.surface"
                  borderWidth="1px"
                  borderRadius="lg"
                  borderColor="purple.700"
                  p={1}
                  mb={3}
                >
                  <Box flexGrow={1} textAlign="left" m={1} whiteSpace="normal" wordBreak="break-word">
                    <Text fontWeight={700} fontSize="sm" mb={0} color="fg.muted">{benchmarkForm.name}
                      <Text display="inline" color="fg.muted" mb={0} fontWeight="normal" fontSize="sm"> (wt: {benchmarkForm.edge_weight}, tol:
                        {benchmarkForm.varience_tolerance})</Text>
                    </Text>
                    {benchmarkForm.benchmark_filters && benchmarkForm.benchmark_filters.map((filter, filterIndex) => (
                      <Badge bg="purple.50" size="xs" fontWeight={600}>
                        <Text display="inline" color="fg.default" mb={0}> {filter.logical_column_header.name} </Text>
                        <Text display="inline" color="fg.default" mb={0}> {filter.operator} {filter.values?.join(", ")}</Text>
                      </Badge>
                    ))}
                  </Box>
                  <Button
                    variant="tertiary"
                    size="xs"
                    leftIcon={<DeleteIcon boxSize="0.8rem" />}
                    color="red.600"
                    onClick={() => handleRemoveBenchmarkEdge(index)}
                  >
                    Clear
                  </Button>
                </HStack>

              ))}


              <HStack spacing={4}>
                <FormControl>
                  <FormLabel fontSize="xs" fontWeight="bold">Edge Name</FormLabel>
                  <Input
                    type="text"
                    placeholder="Edge Name"
                    value={benchmarkName}
                    onChange={(e) => setBenchmarkName(e.target.value)}
                    fontSize="sm"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel fontSize="xs" fontWeight="bold"> Edge Weight</FormLabel>
                  <Input
                    type="number"
                    placeholder="Edge Weight"
                    value={benchmarkEdgeWeight}
                    onChange={(e) => setBenchmarkEdgeWeight(parseFloat(e.target.value))}
                    step="0.1"
                    fontSize="sm"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel fontSize="xs" fontWeight="bold"> Var Tolerance</FormLabel>
                  <Input
                    type="number"
                    placeholder="Variance Tolerance"
                    value={benchmarkVarienceTolerance}
                    onChange={(e) => setBenchmarkVarienceTolerance(parseFloat(e.target.value))}
                    step="0.001"
                    fontSize="sm"
                  />
                </FormControl>

                <Box alignSelf="flex-end">
                  <Button
                    colorScheme="purple"
                    variant="outline"
                    onClick={toggleFilterMenu}
                    leftIcon={isFilterMenuOpen ? <MdFilterAltOff /> : <MdFilterAlt />}
                    iconSpacing={0}
                  />
                </Box>
              </HStack>

              <Collapse in={isFilterMenuOpen} animateOpacity>
                <VStack bg="purple.50" spacing={3} borderRadius="lg" align="stretch" p={2} borderWidth="1px" borderColor="purple.700">
                  {benchmarkFilter && benchmarkFilter.length > 0 && (
                    <VStack spacing={4}>
                      {benchmarkFilter && benchmarkFilter.map(filter => (
                        <Flex key={filter.logical_column_header.id} align="center" gap={3} borderColor="purple.700" borderRadius="lg" borderWidth="1px" fontSize="sm" w="100%" justifyContent="space-between" bg="bg.surface">
                          <Box ml={2}>
                            <Text display="inline" mb={0} fontWeight="medium" fontSize="xs">{filter.logical_column_header.name}</Text> {filter.operator} <Text display="inline" mb={0} fontWeight="medium">{filter.values?.join(", ")}</Text>
                          </Box>
                          <Button
                            variant="tertiary"
                            size="xs"
                            leftIcon={<DeleteIcon boxSize="0.8rem" />}
                            color="red.600"
                            onClick={() => handleRemoveBenchmarkFilter(filter.logical_column_header?.id!)}
                          >
                          </Button>
                        </Flex>
                      ))}
                    </VStack>
                  )}
                  <HStack spacing={4}>
                    <FormControl>
                      <Select
                        value={selectedBenchmarkFilterColumn?.header?.id || ""}
                        onChange={(e) => {
                          const selectedId = e.target.value;
                          const selected = columns?.find(col => col.header?.id === selectedId);
                          setSelectedBenchmarkFilterColumn(selected || null);
                        }}
                        bg="bg.surface"
                        fontSize="xs"
                      >
                        <option value="">Filter Column</option>
                        {columns?.map((column, index) => (
                          <option key={column.header?.id} value={column.header?.id || ""}>
                            {column.header?.name}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                    
                    <FormControl fontSize="xs" position="relative" zIndex={10}>
                    {columnNames && selectedBenchmarkFilterColumn?.column_type === ColumnType.ATTRIBUTE ? (
                      <Menu closeOnSelect={false} preventOverflow={true} placement="top-end">
                        <MenuButton
                          as={Button}
                          color="fg.default"
                          bg="bg.surface"
                          maxW="100%"
                          width="100%"
                          rightIcon={<ChevronDownIcon mr={-2} />}
                          fontSize="sm"
                          border="1px"
                          borderColor="gray.300"
                          borderRadius="md"
                          fontWeight="normal"
                          _hover={{ bg: "purple.50" }}
                          textAlign="left"
                        >
                          {selectedColumnValues.length > 0
                            ? `Selected (${selectedColumnValues.length})`
                            : "Select Options"}
                        </MenuButton>
                        <Portal>
                          <MenuList maxHeight="200px" overflowY="auto" zIndex={1500} position="absolute">
                            {columnNames.map((option, index) => (
                              <MenuItem key={index} minH="40px" zIndex={10}>
                                <Checkbox
                                  isChecked={selectedColumnValues.includes(option)}
                                  onChange={() => handleToggle(option)}
                                  colorScheme="purple"
                                >
                                  {option}
                                </Checkbox>
                              </MenuItem>
                            ))}
                          </MenuList>
                        </Portal>
                      </Menu>
                    ) : (
                      <Input
                        type="text"
                        placeholder="Filter Value"
                        value={benchmarkFilterValue}
                        onChange={(e) => setBenchmarkFilterValue(e.target.value)}
                        fontSize="xs"
                      />
                    )}
                    </FormControl>

                    <Button
                      borderRadius="full"
                      variant="solid"
                      bg="purple.700"
                      leftIcon={<AddIcon />}
                      size="xs"
                      iconSpacing={0}
                      onClick={handleAddBenchmarkFilter}
                    ></Button>
                  </HStack>


                </VStack>
              </Collapse>


            </VStack>

            <Flex w="100%" mt="auto" p={2}>
              <Button
                onClick={handleAddBenchmark}
                w="100%"
                colorScheme="purple"
                leftIcon={<AddIcon boxSize="0.9rem" />}
                fontWeight="bold"
              >
                Add Benchmark Edge
              </Button>
            </Flex>

          </Box>
          {/* fundamental relationships */}
          <Box
            flex="1"
            border="1px"
            borderColor="gray.300"
            p={3}
            borderRadius="lg"
            bg="gray.50"
            alignSelf="stretch"
            display="flex"
            flexDirection="column"
          >
            <VStack align="stretch" spacing={3} p={2}>
              <Text fontSize="sm" fontWeight="bold" w="100%" mb={0}>Related Formulae</Text>
              {fundamentalEdges &&
                fundamentalEdges.map((fundamentalEdge, index) => (
                  <HStack
                    key={index}
                    flexGrow={1}
                    bg="bg.surface"
                    borderWidth="1px"
                    borderRadius="lg"
                    borderColor="purple.700"
                    p={1}
                    mb={3}
                  >
                    <Box flexGrow={1} textAlign="left" m={1} whiteSpace="normal" wordBreak="break-word">
                      <Text fontWeight={700} fontSize="sm" mb={0} color="fg.muted">
                        {fundamentalEdge.fundamental_relationship?.name}
                        <Text display="inline" color="fg.muted" mb={0} fontWeight="normal" fontSize="sm">
                          {" "}(wt: {fundamentalEdge.edge_weight})
                        </Text>
                      </Text>
                      {fundamentalEdge.fundamental_relationship &&
                        fundamentalEdge.fundamental_relationship.correlations.map(
                          (correlation, correlationIndex) => (
                            <Badge bg="purple.50" size="xs" fontWeight={500} key={correlationIndex} mr={1}>
                              <Text display="inline" color="fg.default" mb={0}>
                                {correlation.related_column_header?.name || correlation.related_metric_header?.name}
                              </Text>
                              <Text display="inline" color="fg.default" mb={0}>
                                {" (CF: "}{correlation.correlation_factor}{") "}
                              </Text>
                            </Badge>
                          )
                        )}

                    </Box>
                    <Button
                      variant="tertiary"
                      size="xs"
                      leftIcon={<DeleteIcon boxSize="0.8rem" />}
                      color="red.600"
                      onClick={() => handleRemoveFundamentalEdges(index)}
                    >
                      Clear
                    </Button>
                  </HStack>
                ))}

              <HStack spacing={4}>
                <FormControl>
                  <FormLabel fontSize="xs" fontWeight="bold">Edge Name</FormLabel>
                  <Input
                    type="text"
                    placeholder="Edge Name"
                    value={fundamentalEdgeName}
                    onChange={(e) => setFundamentalEdgeName(e.target.value)}
                    fontSize="sm"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel fontSize="xs" fontWeight="bold">Edge Weight</FormLabel>
                  <Input
                    type="number"
                    placeholder="Edge Weight"
                    value={fundamentalEdgeWeight}
                    onChange={(e) => setFundamentalEdgeWeight(parseFloat(e.target.value))}
                    step="0.001"
                    fontSize="sm"
                  />
                </FormControl>
                <Box alignSelf="flex-end">
                  <Button
                    colorScheme="purple"
                    variant="outline"
                    onClick={toggleCorrelationMenu}
                    leftIcon={isCorrelationMenuOpen ? <TbCalculatorOff /> : <TbCalculator />}
                    iconSpacing={0}
                  />
                </Box>
              </HStack>
              <FormControl display="flex" alignItems="center">
                <FormLabel fontSize="xs" fontWeight="bold">Is Additive:</FormLabel>
                  <Checkbox
                    mt={1}
                    isChecked={isAdditive}
                    onChange={() => setIsAdditive(!isAdditive)}
                    colorScheme="purple"
                  />
                </FormControl>
              <Collapse in={isCorrelationMenuOpen} animateOpacity>
                <VStack bg="purple.50" spacing={3} borderRadius="lg" align="stretch" p={2} borderWidth="1px" borderColor="purple.700">
                  {correlations && correlations.length > 0 && (
                    <VStack spacing={3}>
                      {correlations && correlations.map((correlation, index) => (
                        <Flex key={index} align="center" gap={3} borderColor="purple.700" borderRadius="lg" borderWidth="1px" fontSize="sm" w="100%" justifyContent="space-between" bg="bg.surface">
                          <Box ml={2}>
                            <Text display="inline" mb={0} fontWeight="medium" fontSize="xs">
                              {correlation.related_column_header?.name || correlation.related_metric_header?.name} {"(CF: "} {correlation.correlation_factor}{")"}
                            </Text>
                          </Box>
                          <Button
                            variant="tertiary"
                            size="xs"
                            leftIcon={<DeleteIcon boxSize="0.8rem" />}
                            color="red.600"
                            onClick={() => handleRemoveCorrelation(index)}
                          >
                          </Button>
                        </Flex>
                      ))}
                    </VStack>
                  )}
                  <HStack spacing={4}>
                    <FormControl>
                      <Select
                        value={selectedType}
                        onChange={(e) => setSelectedType(e.target.value)}
                        bg="bg.surface"
                        fontSize="xs"
                      >
                        <option value="Column">Column</option>
                        <option value="Metric">Metric</option>
                      </Select>
                    </FormControl>
                    <FormControl>
                      <Select
                        onChange={(e) => setSelectedHeader(e.target.value)}
                        bg="bg.surface"
                        fontSize="xs"
                      >
                        <option value="">Select Header</option>
                        {(selectedType === "Column" ? measureColumn : allMetrics)?.map((header, index) => (
                          <option key={index} value={header.name}>
                            {header.name}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl fontSize="sm">
                      <Input
                        type="number"
                        placeholder="Correlation Factor"
                        value={correlationFactor}
                        onChange={(e) => setCorrelationFactor(e.target.value)}
                        step="0.001"
                        fontSize="xs"
                      />
                    </FormControl>
                    <Button
                      borderRadius="full"
                      variant="solid"
                      bg="purple.700"
                      leftIcon={<AddIcon />}
                      size="xs"
                      iconSpacing={0}
                      onClick={handleAddCorrelation}
                    ></Button>
                  </HStack>
                </VStack>
              </Collapse>
            </VStack>


            <Flex mt="auto" w="100%" p={2}>
              <Button
                onClick={handleAddFundamentalEdges}
                w="100%"
                colorScheme="purple"
                leftIcon={<AddIcon boxSize="0.9rem" />}
                fontWeight="bold"
              >
                Add Fundamental Edge
              </Button>
            </Flex>

          </Box>
        </HStack>
      </VStack>


    </Container>
  );
};
export default CreateMKG1;