import React, { useState } from "react";
import {
  Box,
  Button,
  Collapse,
  Grid,
  GridItem,
  HStack,
  Icon,
  Image,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { FiChevronDown } from "react-icons/fi";
import { BiNetworkChart } from "react-icons/bi";
import { RiFileExcel2Line } from "react-icons/ri";
import { HiWrenchScrewdriver } from "react-icons/hi2";
import { FaDatabase } from "react-icons/fa";
import Snowflake from "../../assets/Snowflake.png";
import Thoughtspot from "../../assets/thoughtspot-logos.png";
import ViewMKGContainer from "../../containers/configuration/ViewMKGContainer";
import ViewMetricByModelContainer from "../../containers/configuration/ViewMetricByModelContainer";
import SyncTSworksheet from "../../containers/configuration/SyncTSworksheet";
import CreateDataModelContainer from "../../containers/configuration/snowflake/CreateDataModelContainer";
import AddDescriptionContainer from "../../containers/configuration/snowflake/AddDescriptionContainer";
import { ModelInfo } from "../../containers/Interfaces";

const ConfigurationPage: React.FC = () => {
  const { isOpen, onToggle } = useDisclosure();
  const [activeButton, setActiveButton] = useState<string>("ViewMKGContainer");
  const [selectedLink, setSelectedLink] = useState<string>("ViewMKGContainer");
  const [modelInfo, setModelInfo] = useState<ModelInfo | null>(null);

  const handleSnowflake = async (data: ModelInfo) => {
    setModelInfo(data);
    setActiveButton("snowflake");
    setSelectedLink("createModel");
  };

  const handleThoughtSpot = async (data: ModelInfo) => {
    setModelInfo(data);
    setActiveButton("thoughtspot");
    setSelectedLink("createModel");
  };

  const handleCreateDataModel = async () => {
    setActiveButton("ViewMetricByModelContainer");
    setSelectedLink("ViewMetricByModelContainer");
  };

  const componentMapping: Record<string, JSX.Element | null> = {
    ViewMetricByModelContainer: <ViewMetricByModelContainer />,
    ViewMKGContainer: <ViewMKGContainer />,
    thoughtspot: <SyncTSworksheet handleThoughtSpot={handleThoughtSpot} />,
    snowflake: <CreateDataModelContainer modelInfo={handleSnowflake} />,
    createModel: (
      <AddDescriptionContainer
        modelInfo={modelInfo}
        navigateTo={null}
        updateFlag={false}
        handleCreateDataModel={handleCreateDataModel}
      />
    ),
  };

  const renderComponent = () =>
    componentMapping[selectedLink] || componentMapping.default;

  return (
    <Grid
      templateAreas={`
          "sidebar main"
        `}
      gridTemplateRows="1fr"
      gridTemplateColumns="390px 1fr"
      height="100%"
      p={0}
    >
      <GridItem area="sidebar" bg="purple.50" borderRightWidth="1px">
        <Stack
          overflowY="auto"
          py={3}
          px={3}
          color="black"
          justifyContent="space-between"
        >
          <Stack spacing="8">
            <Stack spacing="1">
              <Button
                variant="tertiary"
                justifyContent="start"
                iconSpacing="2"
                leftIcon={<Icon as={BiNetworkChart} boxSize="1.5rem" />}
                _hover={{ bg: "purple.100", color: "black" }}
                onClick={() => {
                  setActiveButton("ViewMKGContainer");
                  setSelectedLink("ViewMKGContainer");
                }}
                bg={
                  activeButton === "ViewMKGContainer" ? "purple.700" : undefined
                }
                color={
                  activeButton === "ViewMKGContainer" ? "white" : "fg.default"
                }
              >
                <Text mb={0} fontWeight={600}>
                  Knowledge Graph
                </Text>
              </Button>

              <Button
                variant="tertiary"
                justifyContent="start"
                iconSpacing="3"
                leftIcon={<Icon as={FaDatabase} boxSize="1.2rem" />}
                _hover={{ bg: "purple.100", color: "black" }}
                onClick={() => {
                  setActiveButton("ViewMetricByModelContainer");
                  setSelectedLink("ViewMetricByModelContainer");
                }}
                bg={
                  activeButton === "ViewMetricByModelContainer"
                    ? "purple.700"
                    : undefined
                }
                color={
                  activeButton === "ViewMetricByModelContainer"
                    ? "white"
                    : "fg.default"
                }
              >
                <Text mb={0} fontWeight={600}>
                  Metrics
                </Text>
              </Button>

              <Box>
                <Button
                  variant="tertiary"
                  onClick={onToggle}
                  justifyContent="space-between"
                  width="full"
                  _hover={{ bg: "purple.100", color: "black" }}
                >
                  <HStack spacing="3">
                    <Icon
                      as={HiWrenchScrewdriver}
                      boxSize="1.2rem"
                      color="fg.default"
                    />
                    <Text as="span" color="fg.default">
                      Integrations
                    </Text>
                  </HStack>
                  <PopoverIcon isOpen={isOpen} />
                </Button>
                <Collapse in={isOpen} animateOpacity>
                  <Stack spacing="1" alignItems="stretch" ps="8" py="1">
                    <Button
                      variant="tertiary"
                      justifyContent="start"
                      _hover={{ bg: "purple.100", color: "black" }}
                      leftIcon={<Image src={Snowflake} boxSize="1.5rem" />}
                      onClick={() => {
                        setActiveButton("snowflake");
                        setSelectedLink("snowflake");
                      }}
                      bg={
                        activeButton === "snowflake" ? "purple.700" : undefined
                      }
                      color={
                        activeButton === "snowflake" ? "white" : "fg.default"
                      }
                    >
                      Snowflake
                    </Button>
                    <Button
                      variant="tertiary"
                      justifyContent="start"
                      _hover={{ bg: "purple.100", color: "black" }}
                      leftIcon={<Image src={Thoughtspot} boxSize="1.5rem" />}
                      onClick={() => {
                        setActiveButton("thoughtspot");
                        setSelectedLink("thoughtspot");
                      }}
                      bg={
                        activeButton === "thoughtspot"
                          ? "purple.700"
                          : undefined
                      }
                      color={
                        activeButton === "thoughtspot" ? "white" : undefined
                      }
                    >
                      Thoughtspot
                    </Button>
                    <Button
                      variant="tertiary"
                      justifyContent="start"
                      _hover={{ bg: "purple.100", color: "black" }}
                      leftIcon={<Icon as={RiFileExcel2Line} boxSize="1.5rem" />}
                      onClick={() => setActiveButton("excel")}
                      bg={activeButton === "excel" ? "purple.700" : undefined}
                      color={activeButton === "excel" ? "white" : "fg.default"}
                    >
                      Excel
                    </Button>
                  </Stack>
                </Collapse>
              </Box>
            </Stack>
          </Stack>
        </Stack>
      </GridItem>
      <GridItem
        area="main"
        overflowY="auto"
        height="100%"
        sx={{
          "::-webkit-scrollbar": {
            display: "none",
          },
          "-ms-overflow-style": "none", // IE and Edge
          "scrollbar-width": "none", // Firefox
        }}
      >
        <Stack p={0}>{renderComponent()}</Stack>
      </GridItem>
    </Grid>
  );
};

export default ConfigurationPage;

export const PopoverIcon = (props: { isOpen: boolean }) => {
  const iconStyles = {
    transform: props.isOpen ? "rotate(-180deg)" : undefined,
    transition: "transform 0.2s",
    transformOrigin: "center",
  };
  return <Icon aria-hidden as={FiChevronDown} __css={iconStyles} />;
};
