import { Field, Form, Formik } from 'formik'
import {
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Button,
  Box,
  AlertIcon,
  AlertDescription,
} from '@chakra-ui/react'
import { fetchModelInfo, syncTSworksheet } from '../../services/DataModelService'
import { Alert } from '@chakra-ui/react'
import { useState } from 'react'
import { DataSourceInfo, ModelInfo } from '../Interfaces'

interface FormValues {
  id: string
}

interface TSworksheetprops {
  handleThoughtSpot: (data: ModelInfo) => void;
}

const SyncTSworksheet: React.FC<TSworksheetprops> = ({handleThoughtSpot}) => {

  const [message, setMessage] = useState<string | null>(null);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const handlesyncTSworksheet = async(id: string) => {
      const dataSource: DataSourceInfo = {
          source_type: "THOUGHTSPOT",
          ts_worksheet_id: id
      }
      const data = await fetchModelInfo(dataSource);
      if (data) {
        setMessage(`Thoughtspot worksheet synced successfully for id: ${id}`);
        setIsSuccess(true);
        handleThoughtSpot(data.model_info);
      } else {
        setMessage('Failed to sync the worksheet. Please try again');
        setIsSuccess(false);
      }
  }

  const FormikExample = () => {
    // Validation function for the ID field
    function validateId(value: string) {
      let error: string | undefined
      if (!value) {
        error = 'ID is required'
      }
      return error
    }

    return (
      <Box
        mt={14}
        p={4}
        width="60%"
        maxW="md"
        mx="auto"
        borderWidth="1px"
        borderRadius="lg"
        boxShadow="lg"
      >
        <Formik<FormValues> // Apply the FormValues type to Formik
          initialValues={{ id: '' }} // Set initial values using the defined interface
          onSubmit={async (values: any, actions: any) => {
            await handlesyncTSworksheet(values.id)
            actions.setSubmitting(false);
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <Field name="id" validate={validateId}>
                {({ field, form }: any) => (
                  <FormControl isInvalid={!!form.errors.id && form.touched.id}>
                    <FormLabel>Thoughtspot Worksheet ID</FormLabel>
                    <Input {...field} placeholder="Enter ID" />
                    <FormErrorMessage>{form.errors.id}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Button
                mt={4}
                colorScheme="teal"
                isLoading={isSubmitting}
                type="submit"
              >
                Submit
              </Button>
            </Form>
          )}
        </Formik>
         {message &&  <Alert status={isSuccess ? "success" : "error"} mt={4}>
              <AlertIcon />
              {isSuccess ? (
                <AlertDescription>{message}</AlertDescription>
              ) : (
                <AlertDescription>{message}</AlertDescription>
              )}
          </Alert>
        }
      </Box>
    )
  }

  return FormikExample()
}

export default SyncTSworksheet
