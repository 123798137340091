import React, { useState } from "react";
import {
  Box,
  Button,
  Text,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  FormControl,
  IconButton,
  Divider,
  HStack,
  Stack,
  StackDivider,
  VStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Icon,
} from "@chakra-ui/react";
import { FaRegTrashAlt } from "react-icons/fa";
import { useExportContext } from "../../context/export/ExportContext";
import { Page } from "./Interface";
import { createUpdatePage, createUpdateReport, deleteReport } from "../../services/ReportPageServices";
import { IoEllipsisVertical } from "react-icons/io5";
import { AddIcon } from '@chakra-ui/icons';
import { EditIcon, DownloadIcon } from '@chakra-ui/icons';
import { LinkIcon } from '@chakra-ui/icons';
import showToast from "../../hooks/useCustomToast";

const PagesPanelContainer: React.FC = () => {
  const {
    pages,
    selectedPageId,
    selectedStoryId,
    stories,
    setStories,
    setPages,
    setSelectedPageId,
    setSelectedTreeHeaders,
    handleSelectPage,
    handleExport
  } = useExportContext();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [inputValue, setInputValue] = useState("");
  const [modalMode, setModalMode] = useState<string>("create");
  const [pageToRename, setPageToRename] = useState<Page | null>(null);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleCreatePage = async () => {
    if (inputValue.trim() !== "") {
      const newPage: Page = {
        header: {
          name: inputValue,
        },
      };
      try {
        const response: Page = await createUpdatePage(newPage);
        // Append the new page to the existing pages
        const updatedPages = [...pages, response];
        setPages(updatedPages);
        setSelectedPageId(response.header.id!);
        if(response && selectedStoryId !== ""){
          const story = stories.find((s) => s.header.id === selectedStoryId);
          const updatedStory = {
            ...story!,
            pages: updatedPages,
          };
          const response = await createUpdateReport(updatedStory);
          const updatedStories = stories.map((story) =>
            story.header.id === selectedStoryId ? response : story
          );
          setStories(updatedStories);
        }
        setInputValue("");
        setSelectedTreeHeaders([]);
        onClose();
      } catch (error) {
        alert("Error creating page:" + error);
      }
    }
  };

  const handleDeletePage = async (pageId: string) => {
    if (selectedStoryId !== "") {
      const story = stories.find((s) => s.header.id === selectedStoryId);
      if (!story) {
        showToast("No story selected to update","", "error");
        return;
      }
      const updatedPages =
        pages && pages.filter((page) => page.header.id !== pageId);
      setPages(updatedPages);
      if (selectedPageId === pageId) {
        setSelectedPageId("");
      }
      const updatedStory = {
        ...story,
        pages: updatedPages,
      };
      const response = await createUpdateReport(updatedStory);
      await deleteReport(selectedPageId, "REPORT_PAGE");
      const updatedStories = stories.map((story) =>
        story.header.id === selectedStoryId ? response : story
      );
      setStories(updatedStories);
      showToast(`Pages of ${story.header.name} are updated`,"", "success");
    } else {
      showToast("No story selected to update","", "error");
    }
  };

  const handleRenamePage = async () => {
    if (pageToRename && inputValue.trim() !== "") {
      try {
        const updatedPage: Page = {
          ...pageToRename,
          header: {
            ...pageToRename.header,
            name: inputValue,
          },
        };
        const response: Page = await createUpdatePage(updatedPage);
        const updatedPages = pages.map((page) =>
          page.header.id === response.header.id ? response : page
        );
        setPages(updatedPages);
        setSelectedPageId(response.header.id!);
        setInputValue("");
        setPageToRename(null);
        onClose();
      } catch (error) {
        alert("Error renaming page: " + error);
      }
    }
  };

  const openCreateModal = () => {
    setModalMode("create");
    setInputValue("");
    onOpen();
  };

  const openRenameModal = (page: Page) => {
    setModalMode("rename");
    setPageToRename(page);
    setInputValue(page.header.name!);
    onOpen();
  };

  return (
    <Box
      borderColor="#4e1e6c"
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <Box height="100%" p={2}>
        <HStack justify="space-between">
          <Text
            fontWeight="bold"
            align="left"
            m={1}
            color="fg.muted"
            fontSize="sm"
          >
            Pages
          </Text>
          <VStack
            align="center"
            justify="center"
            cursor="pointer"
            borderRadius="md"
          >
            <IconButton
              aria-label="Add new item"
              icon={<AddIcon />}
              colorScheme="purple"
              size="xs"
              variant="ghost"
              onClick={openCreateModal}
            />
          </VStack>
        </HStack>
        <Divider m={1} />
        <Stack
          height="96%"
          spacing="1"
          fontSize="sm"
          lineHeight="1rem"
          divider={<StackDivider />}
          overflowY="auto"
          sx={{
            /* Custom scrollbar for WebKit-based browsers (Chrome, Safari, Edge) */
            "::-webkit-scrollbar": {
              width: "4px", // Set scrollbar width
            },
            "::-webkit-scrollbar-track": {
              background: "gray.100", // Background of the track
            },
            "::-webkit-scrollbar-thumb": {
              background: "gray.500", // Color of the scrollbar thumb
              borderRadius: "8px",
            },
            "::-webkit-scrollbar-thumb:hover": {
              background: "gray.600", // Color of the scrollbar thumb on hover
            },
            /* Scrollbar width for Firefox */
            scrollbarWidth: "thin", // For Firefox
            scrollbarColor: "gray.500 gray.100", // Thumb and track colors for Firefox
          }}
        >
          {pages &&
            pages.map((page) => (
              <VStack
                key={page.header.id}
                align="flex-start"
                p={1}
                mb={1}
                borderRadius="md"
                bg={selectedPageId === page.header.id ? "purple.700" : "white"}
                color={selectedPageId === page.header.id ? "white" : "black"}
                _hover={{
                  bg:
                    selectedPageId === page.header.id
                      ? "purple.700"
                      : "gray.50",
                }}
                onClick={() => handleSelectPage(page.header.id!)}
              >
                <HStack justifyContent="space-between" w="100%">
                  <Text fontWeight="bold" fontSize="xs" m={0}>
                    {page.header.name}
                  </Text>
                  <Box display="flex" alignItems="center">
                    <Menu>
                      <MenuButton
                        as={IconButton}
                        aria-label="Options"
                        borderRadius="full"
                        icon={<IoEllipsisVertical />}
                        variant="ghost"
                        size="sm"
                        color={selectedPageId === page.header.id ? "white" : "black"}
                        _groupHover={{ color: "gray.500" }}
                        ml={-2}
                      />
                      <MenuList>
                        <MenuItem
                          fontSize="sm"
                          color="black"
                          icon={<EditIcon />}
                          onClick={() => openRenameModal(page)}
                        >
                          Rename
                        </MenuItem>
                        <MenuItem
                          fontSize="sm"
                          color="black"
                          icon={<DownloadIcon />}
                          onClick={() => handleExport("REPORT_PAGE", page.header.id!)}
                        >
                          Export
                        </MenuItem>
                        <MenuItem
                          fontSize="sm"
                          color="red"
                          icon={<FaRegTrashAlt />}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeletePage(page.header.id!);
                          }}
                        >
                          Delete
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Box>
                </HStack>

                {page.tree_headers && page.tree_headers.length > 0 ? (
                  page.tree_headers.map((header, headerIndex) => (
                    <Text
                      key={headerIndex}
                      opacity={0.8}
                      textAlign="left"
                      m={0}
                      fontSize="xs"
                      isTruncated
                      maxWidth="100%" 
                    >
                      <Icon as={LinkIcon} mr={2} />{header.name}
                    </Text>
                  ))
                ) : (
                  <Text fontSize="xs" color="gray.500">Page is Empty.</Text>
                )}
              </VStack>
            ))}
        </Stack>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize="md">
            {modalMode === "create" ? "Create New Page" : "Update Page Name"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <Input
                placeholder="Enter page name"
                value={inputValue}
                onChange={handleInputChange}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="purple"
              mr={3}
              onClick={
                modalMode === "create" ? handleCreatePage : handleRenamePage
              }
            >
              OK
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default PagesPanelContainer;