import React from 'react'
import {
  Button,
  Box,
  Flex,
  HStack,
  IconButton,
  Stack,
  Text,
  Spacer,
  UnorderedList,
  OrderedList,
  ListItem,
  Heading,
  Image,
  Tooltip,
  Modal,
  ModalBody,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerContent,
  DrawerCloseButton,
  Avatar,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/react'
import { BsPinAngleFill } from 'react-icons/bs'
import { FaChartBar } from 'react-icons/fa'
import { Icon, QuestionIcon, EditIcon, DeleteIcon } from '@chakra-ui/icons' // Import EditIcon and CheckIcon from Chakra UI
import { ChatGroupHeader } from './ChatGroupHeader'
import { ChatAnalysis } from './ChatAnalysis'
import { ChatMessage } from './ChatMessage'
import { SearchInput } from './SearchInput'
import ChatWindowContainer from '../../containers/chat/ChatWindowContainer'
import { CiExport } from 'react-icons/ci'
import {
  Analysis,
  ChatInterface,
  MessageData,
} from '../../containers/chat/interface'
import ReactMarkdown from 'react-markdown'
import logo from '../../assets/logo.png'
import { AnalysisTreeResponse } from '../../containers/commentary/Interfaces'
import TreeViewerContainer from '../../containers/commentary/TreeViewerContainer'
import ChatName from '../../containers/chat/ChatName'
import { GiBrain } from 'react-icons/gi'
import { epochConverter } from '../../utils/dateUtils'
import { Header } from '../../containers/Interfaces'

interface ChatProps {
  activeAnalysis: Header[]
  commentaries: string[]
  setCommentaries: React.Dispatch<React.SetStateAction<string[]>>
  chats: ChatInterface[]
  activeChat: ChatInterface | undefined
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>
  chatName: string
  contextString: string
  isOpen: boolean
  onClose: () => void
  toPDF: any
  targetRef: React.MutableRefObject<any>
  filteredAnalysis: Header[]
  tree: AnalysisTreeResponse | undefined
  handleTree: (id: string) => void
  handleAddActiveAnalysis: (analysis: Header) => void
  handleActiveChat: (chat: ChatInterface) => void
  chatConversationId: string
  historyMessages: MessageData[]
  setHistoryMessages: React.Dispatch<React.SetStateAction<MessageData[]>>
  handleNewChat: () => void
  setNewChatToggler: React.Dispatch<React.SetStateAction<boolean>>
  isDrawerOpen: boolean
  openDrawer: () => void
  closeDrawer: () => void
  btnRef: React.RefObject<HTMLButtonElement>
  setChatConversationID: React.Dispatch<React.SetStateAction<string>>
  handleChatName: (name: string) => void
  handleConversationDelete: () => void
  cancelDeleteRef: React.RefObject<HTMLButtonElement>
  openDeleteDialog: (id: string) => void
  closeDeleteDialog: () => void
  selectedDeleteChatId: string
}

/**
 * Dumb component for Chat
 */
const Chat: React.FC<ChatProps> = ({
  activeAnalysis,
  commentaries,
  setCommentaries,
  chats,
  activeChat,
  setSearchQuery,
  chatName,
  contextString,
  isOpen,
  onClose,
  toPDF,
  targetRef,
  filteredAnalysis,
  tree,
  handleTree,
  handleAddActiveAnalysis,
  handleActiveChat,
  chatConversationId,
  historyMessages,
  setHistoryMessages,
  handleNewChat,
  setNewChatToggler,
  isDrawerOpen,
  openDrawer,
  closeDrawer,
  btnRef,
  setChatConversationID,
  handleChatName,
  handleConversationDelete,
  cancelDeleteRef,
  openDeleteDialog,
  closeDeleteDialog,
  selectedDeleteChatId,
}) => {
  /**
   * tooltip for context
   */
  const tooltipLabelContext =
    tree &&
    tree?.context &&
    tree?.context.map((context, index) => (
      <React.Fragment key={index}>
        <span>
          Time:{' '}
          {context! &&
            context.logical_column_header! &&
            context.logical_column_header?.last_modified_date_ms! &&
            epochConverter(
              parseInt(context.logical_column_header?.last_modified_date_ms!),
            )}
        </span>
        <span> | </span>
        <span>
          {context.logical_column_header?.name} {context.operator}{' '}
          {context.values}
        </span>
        {index < tree.context.length - 1 && <br />}{' '}
      </React.Fragment>
    ))

  const benchmarksFromEdges =
    tree &&
    tree?.metric?.edges
      ?.map((edge) => edge?.benchmark)
      .filter((benchmark) => benchmark !== undefined)

  /**
   * tooltip for benchmark
   */

  const tooltipLabelBenchmark = benchmarksFromEdges?.map((benchmark, index) => {
    if (!benchmark) return null // Skip undefined benchmarks

    const filters = benchmark.benchmark_filters?.map((filter, filterIndex) => (
      <span key={filterIndex}>
        {filter.logical_column_header?.name} {filter.operator} {filter.values}
      </span>
    ))
    // console.log(benchmarksFromEdges)
    return (
      <React.Fragment key={index}>
        <span>
          Time:{' '}
          {benchmark! &&
            benchmark.benchmark_filters! &&
            benchmark.benchmark_filters[0]?.logical_column_header
              .last_modified_date_ms! &&
            epochConverter(
              parseInt(
                benchmark.benchmark_filters[0]?.logical_column_header
                  .last_modified_date_ms!,
              ),
            )}
        </span>
        <span> | </span>
        <span>{benchmark.header?.name} </span>
        {filters}
        {index < benchmarksFromEdges.length - 1 && <br />}
      </React.Fragment>
    )
  })

  return (
    <Flex height="100vh" overflow="hidden">
      <Stack
        spacing="1"
        width="420px"
        borderEndWidth="1px"
        pt="3"
        bg="gray.50"
      >
        <>
        <Button
            ref={btnRef}
            colorScheme="purple"
            bg="purple.700"
            color="white"
            onClick={openDrawer}
            alignSelf="center"
            w="80%"
            fontSize="sm"
            _hover={{ bg: 'purple.800', color: 'white' }}
            leftIcon={<Icon as={GiBrain} />}
          >
            Add to Memory
          </Button>

          <Drawer
            isOpen={isDrawerOpen}
            placement="left"
            onClose={closeDrawer}
            finalFocusRef={btnRef}
            size="sm"
          >
            {/* <DrawerOverlay /> */}
            <DrawerContent maxW="26.2vw">
              <DrawerCloseButton />
              <DrawerHeader>Analyses</DrawerHeader>

              <DrawerBody>
                <Box px="5">
                  <SearchInput
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </Box>

                <Stack
                  mt="2"
                  spacing="4"
                  flex="1"
                  overflowY="auto"
                  px="5"
                  pb="5"
                >
                  <Stack mt="2" spacing="4">
                    <ChatGroupHeader icon={BsPinAngleFill}>
                      Starred Analyses ({filteredAnalysis.length})
                    </ChatGroupHeader>
                    <Stack
                      spacing="0"
                      mx="-4"
                      maxW="100vw"
                      h="100%"
                      whiteSpace="nowrap"
                    >
                      {filteredAnalysis.map((analysis, index) => (
                        <Flex
                          direction="row"
                          justifyContent="space-between"
                          flex="1"
                        >
                          <ChatAnalysis
                            key={index}
                            analysis={analysis}
                            onMessageClick={() =>
                              handleAddActiveAnalysis(analysis)
                            }
                          />
                          <IconButton
                            alignSelf="center"
                            as={FaChartBar}
                            aria-label=""
                            onClick={() => handleTree(analysis.id!)}
                            color="purple.400"
                            _hover={{ bg: 'purple.100', color: 'black' }}
                            size="sm"
                            p={1}
                            mr={3}
                            />
                        </Flex>
                      ))}
                      <Modal
                        isOpen={isOpen}
                        onClose={onClose}
                        isCentered
                        motionPreset="scale"
                      >
                        <ModalOverlay />
                        <ModalContent
                          m="5%"
                          maxW="90vw"
                          maxH="90vh"
                          overflow="none"
                        >
                          <ModalHeader>
                            <Flex
                              direction="row"
                              justify="space-between"
                              align="center"
                              width="100%"
                            >
                              {/* Metric Name Section */}
                              <Flex
                                direction="column"
                                justify="center"
                                align="flex-start"
                                flex="1"
                                p={1}
                              >
                                <Text
                                  fontSize="lg"
                                  fontWeight="bold"
                                  color="black"
                                  mb={0}
                                >
                                  {tree?.metric?.header?.name}
                                </Text>
                              </Flex>

                              {/* Last Run Section */}
                              <Flex
                                direction="column"
                                justify="center"
                                align="flex-start"
                                flex="1"
                                p={1}
                              >
                                <Text fontSize="md" color="gray.600" mb={0}>
                                  Last Run:{' '}
                                  {tree! &&
                                    tree?.metric &&
                                    tree?.metric.header &&
                                    tree?.metric.header
                                      .last_modified_date_ms! &&
                                    epochConverter(
                                      parseInt(
                                        tree?.metric.header
                                          .last_modified_date_ms!,
                                      ),
                                    )}
                                </Text>
                              </Flex>

                              {/* Context Section */}
                              <Flex
                                direction="column"
                                justify="center"
                                align="flex-start"
                                flex="1"
                                p={1}
                              >
                                <Tooltip
                                  label={tooltipLabelContext}
                                  hasArrow
                                  placement="top"
                                  bg="purple.50"
                                  p={3}
                                  borderRadius={7}
                                  color="black"
                                  fontSize="lg"
                                  arrowSize={9}
                                  maxW="100vw"
                                >
                                  <Flex alignItems="left" cursor="pointer">
                                    <Text
                                      fontSize="md"
                                      fontWeight="medium"
                                      mr={2}
                                      mb={0}
                                    >
                                      Context
                                    </Text>
                                    <Icon
                                      as={QuestionIcon}
                                      boxSize={6}
                                      color="#4e1e6c"
                                    />
                                  </Flex>
                                </Tooltip>
                              </Flex>

                              {/* Benchmark Section */}
                              <Flex
                                direction="column"
                                justify="center"
                                align="flex-start"
                                flex="1"
                                p={1}
                              >
                                <Tooltip
                                  label={tooltipLabelBenchmark}
                                  hasArrow
                                  placement="top"
                                  bg="purple.50"
                                  color="black"
                                  p={3}
                                  borderRadius={7}
                                  fontSize="lg"
                                  arrowSize={9}
                                  maxW="100vw"
                                >
                                  <Flex alignItems="left" cursor="pointer">
                                    <Text
                                      fontSize="md"
                                      fontWeight="medium"
                                      mr={2}
                                      mb={0}
                                    >
                                      Benchmark
                                    </Text>
                                    <Icon
                                      as={QuestionIcon}
                                      boxSize={6}
                                      color="#4e1e6c"
                                    />
                                  </Flex>
                                </Tooltip>
                              </Flex>
                            </Flex>
                          </ModalHeader>
                          <ModalCloseButton />
                          <ModalBody overflow="auto">
                            <TreeViewerContainer
                              key={tree && tree?.header && tree?.header.id}
                              tree={tree}
                            />
                          </ModalBody>
                        </ModalContent>
                      </Modal>
                    </Stack>
                  </Stack>
                </Stack>
              </DrawerBody>

              <DrawerFooter></DrawerFooter>
            </DrawerContent>
          </Drawer>
        </>

        <Stack
          spacing="4"
          width="420px"
          borderEndWidth="1px"
          bg="gray.50"
          overflowY="auto"
        >
          <Stack spacing="3" flex="1" overflowY="auto" px="5" pb="5">
            <Stack mt="0" spacing="1">
              <Flex alignItems="center" justifyContent="space-between" ml={3}>
                <ChatGroupHeader icon={BsPinAngleFill}>
                  Chats ({chats.length})
                </ChatGroupHeader>
                <IconButton
                  aria-label="Add chat"
                  onClick={() => handleNewChat()}
                  size="md" // Adjust the size of the button
                  p={1}
                  bg="gray.50"
                  color="black"
                  _hover={{ bg: 'purple.100', color: 'black' }}
                  icon={<EditIcon boxSize="1.0em" />} // Increase the size of the icon
                />
              </Flex>
              <Stack
                spacing="0"
                mx="-4"
                maxW="100vw"
                h="100%"
                whiteSpace="nowrap"
              >
                {chats.map((chat, index) => (
                  <Flex direction="row" flex="1" justifyContent="space-between">
                    <ChatMessage
                      key={index}
                      chat={chat}
                      onMessageClick={() => handleActiveChat(chat)}
                    />
                    <Icon
                      alignSelf="center"
                      as={DeleteIcon}
                      aria-label=""
                      onClick={() => openDeleteDialog(chat.header.id)}
                      bg="gray.50"
                      color="gray.500"
                      _hover={{ bg: 'purple.100', color: 'black' }}
                      p={1}
                      mr={3}
                      boxSize="22px"
                    />
                    {selectedDeleteChatId && (
                      <AlertDialog
                        isOpen={Boolean(selectedDeleteChatId)}
                        leastDestructiveRef={cancelDeleteRef}
                        onClose={closeDeleteDialog}
                      >
                        <AlertDialogOverlay
                          style={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
                        >
                          <AlertDialogContent>
                            <AlertDialogHeader fontSize="lg" fontWeight="bold">
                              Delete Chat
                            </AlertDialogHeader>

                            <AlertDialogBody>
                              Are you sure you want to delete this chat?
                            </AlertDialogBody>

                            <AlertDialogFooter>
                              <Button
                                ref={cancelDeleteRef}
                                onClick={closeDeleteDialog}
                              >
                                Cancel
                              </Button>
                              <Button
                                colorScheme="red"
                                onClick={() => handleConversationDelete()}
                                ml={3}
                              >
                                Delete
                              </Button>
                            </AlertDialogFooter>
                          </AlertDialogContent>
                        </AlertDialogOverlay>
                      </AlertDialog>
                    )}
                  </Flex>
                ))}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      <Flex direction="column" flex="1">
        <Flex borderBottomWidth="1px" px="2" py="2" align="center">
          <HStack spacing="3">
            <Avatar name={chatName} size="sm" />
            <Box flexShrink={1}>
              <ChatName
                chatName={chatName}
                handleChatName={handleChatName}
              ></ChatName>
            </Box>
          </HStack>
          <Spacer /> {/* Adds space between elements */}
          <Box
            border="3px dotted"
            borderColor="gray.300"
            p={2}
            borderRadius="md"
          >
            <Flex justify="center" align="center" gap="2">
              <IconButton
                aria-label="Export messages"
                icon={<CiExport />}
                onClick={() =>
                  !contextString.length
                    ? alert('please select an analysis first')
                    : toPDF()
                }
                bg="white"
                color="black"
                _hover={{ bg: 'purple.100', color: 'black' }}
                size="md"
                p={1}
              />

              <Text fontSize="sm" fontWeight="semibold" color="gray.600" mb={0}>
                Memory:
              </Text>
              <Flex gap="2" overflowX="auto">
                {activeAnalysis.map((analysisData, index) => (
                  <Box
                    key={index}
                    p={2}
                    borderWidth="1px"
                    borderColor="purple.200"
                    borderRadius="lg"
                    bg="#4e1e6c"
                    maxW="120px"
                  >
                    <Text
                      fontSize="xs"
                      fontWeight="bold"
                      isTruncated
                      color="white"
                      mb={0}
                    >
                      {analysisData.name}
                    </Text>
                  </Box>
                ))}
              </Flex>
            </Flex>
          </Box>
        </Flex>

        <div
          ref={targetRef}
          style={{
            position: 'absolute',
            left: '-10000px',
            top: '-10000px',
            wordWrap: 'break-word',
            maxWidth: '960px',
          }}
        >
          {' '}
          {/* Apply display: none to hide the content */}
          <Flex align="center" justify="flex-end" mb="4">
            <Image src={logo} alt="Logo" maxH="33px" mr="2" />
            <Text color="gray.500" fontSize="sm">
              Generated by Anko AI
            </Text>
          </Flex>
          <ReactMarkdown
            children={contextString}
            components={{
              p: ({ ...props }) => (
                <Text fontSize="sm" textAlign="left" {...props} />
              ),
              ul: ({ ...props }) => (
                <UnorderedList
                  textAlign="left"
                  styleType="disc"
                  ml="4"
                  {...props}
                />
              ),
              ol: ({ ...props }) => (
                <OrderedList
                  textAlign="left"
                  styleType="decimal"
                  ml="4"
                  {...props}
                />
              ),
              li: ({ ...props }) => (
                <ListItem
                  textAlign="left"
                  fontSize="sm"
                  {...props}
                  listStyleType="none"
                  position="relative"
                >
                  {/* Custom bullet icon */}
                  <Box
                    as="span"
                    position="absolute"
                    left="-1.5em"
                    top="50%"
                    transform="translateY(120%)"
                    borderRadius="full"
                    w="5px"
                    h="5px"
                    bg="black"
                  />
                  {props.children}
                </ListItem>
              ),
              h1: ({ ...props }) => (
                <Heading
                  as="h1"
                  size="md"
                  textAlign="left"
                  fontWeight="800"
                  {...props}
                />
              ),
              h2: ({ ...props }) => (
                <Heading
                  as="h2"
                  size="sm"
                  textAlign="left"
                  fontWeight="bold"
                  mb={3}
                  {...props}
                />
              ),
              h3: ({ ...props }) => (
                <Heading as="h3" size="sm" textAlign="left" {...props} />
              ),
              h4: ({ ...props }) => (
                <Heading as="h4" size="sm" textAlign="left" {...props} />
              ),
              // Add other overrides as needed
            }}
          />
        </div>

        <Box flex="1" overflow="none" px="5" py="4">
          <ChatWindowContainer
            key={chatConversationId}
            commentaries={commentaries}
            setCommentaries={setCommentaries}
            activeChat={activeChat}
            chatConversationId={chatConversationId}
            historyMessages={historyMessages}
            setHistoryMessages={setHistoryMessages}
            setNewChatToggler={setNewChatToggler}
            chatName={chatName}
            setChatConversationID={setChatConversationID}
          />
        </Box>
      </Flex>
    </Flex>
  )
}

export default Chat
