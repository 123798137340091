import React from "react";
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogContent,
  AlertDialogHeader,
  Box,
  Button,
  Card,
  CardFooter,
  CardHeader,
  Center,
  Divider,
  Flex,
  Heading,
  IconButton,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Spinner,
  Stack,
  Text,
  VStack,
  Tooltip,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import {
  FormData,
  Header,
  ModelInfo,
} from "../../containers/Interfaces";
import VisualizeGraphContainer from "../../containers/configuration/mkg/VisualizeGraphContainer";
import CreateMKGContainer from "../../containers/configuration/snowflake/CreateMKGContainer";
import EditMetricDefinitionContainer from "./../../containers/configuration/EditMetricDefinitionContainer";
import {ArrowRightIcon, ChevronRightIcon, DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { BiNetworkChart } from "react-icons/bi";
import { FaEye } from "react-icons/fa";
import { IoMdSettings } from "react-icons/io";

interface ViewMetricCardProps {
  metricHeaderList: Header[] | undefined;
  loading: boolean;
  handleViewMKGModal: (metricHeader: Header) => void;
  handleEditMKGModal: (metricHeader: Header) => void;
  handleEditMetricDefinitionModal: (metricHeader: Header) => void;
  isOpen: boolean;
  onClose: () => void;
  viewMKG: boolean;
  editMKG: boolean;
  editMetricDefinition: boolean;
  modelInfo: ModelInfo | null;
  metricInfo: FormData | null;
  selectedMetric: Header | undefined;
  dependentMetrics: Header[];
  dependentAnalysis: Header[];
  handleDeleteMetric: () => void;
  deleteMetricFlag: boolean;
  closeDeleteMetricModal: () => void;
  deleteMetricDialog: boolean;
  cancelDeleteRef: React.RefObject<HTMLButtonElement>;
  closeDeleteMetricDialog: () => void;
  handleDeleteMetricDialog: (id: string) => void;
  deleteAnalysis: (id: string) => void;
}

const ViewMetricCard: React.FC<ViewMetricCardProps> = ({
  metricHeaderList,
  loading,
  handleViewMKGModal,
  handleEditMKGModal,
  handleEditMetricDefinitionModal,
  isOpen,
  onClose,
  viewMKG,
  editMKG,
  editMetricDefinition,
  modelInfo,
  metricInfo,
  selectedMetric,
  dependentMetrics,
  dependentAnalysis,
  handleDeleteMetric,
  deleteMetricFlag,
  closeDeleteMetricModal,
  deleteMetricDialog,
  cancelDeleteRef,
  handleDeleteMetricDialog,
  closeDeleteMetricDialog,
  deleteAnalysis,
}) => {
  return (
    <Stack>
      {loading ? (
        // Render a loader while loading is true
        <Center h="10vh" pl={5}>
          <Spinner size="md" color="#4e1e6c" />
        </Center>
      ) : (
        <SimpleGrid
          spacing={3}
          templateColumns="repeat(3, 1fr)"
          width="100%"
        >
          {metricHeaderList &&
            metricHeaderList?.map(
              (metricHeader: Header, index: number) => (
                <Card
                  maxW="lg"
                  key={index}
                  bg="purple.50"
                  border="1px solid"
                  borderColor="purple.700"
                  boxShadow="md"
                >
                  <Flex justifyContent="center">
                    <Flex
                      flex="1"
                      alignItems="center"
                      justifyContent="space-between"
                      flexWrap="wrap"
                    >
                      <Menu isLazy matchWidth>
                        <MenuButton
                          as={Button}
                          rightIcon={<IoMdSettings />}
                          textAlign="left"
                          p={2}
                          flex="1"
                          justifyContent="space-between"
                          borderRadius="full"
                          variant="ghost"
                          size="sm"
                          fontSize="sm"
                          fontWeight={600}
                          aria-label="Options"
                          whiteSpace="normal"
                          wordBreak="break-word"
                        >
                          {metricHeader.name}
                        </MenuButton>
                        <MenuList>
                          <MenuItem
                            fontSize="sm"
                            fontWeight={600}
                            icon={<EditIcon />}
                            onClick={() => handleEditMetricDefinitionModal(metricHeader)}
                          >
                            Edit Definition
                          </MenuItem>
                          <MenuItem
                            fontSize="sm"
                            fontWeight={600}
                            icon={<FaEye />}
                            onClick={() => handleViewMKGModal(metricHeader)}
                          >
                            View Graph
                          </MenuItem>
                          <MenuItem
                            fontSize="sm"
                            fontWeight={600}
                            icon={<BiNetworkChart />}
                            onClick={() => handleEditMKGModal(metricHeader)}
                          >
                            Edit Graph
                          </MenuItem>
                          <MenuItem
                            fontSize="sm"
                            fontWeight={600}
                            icon={<DeleteIcon />}
                            onClick={() => handleDeleteMetricDialog(metricHeader.id!)} 
                          >
                            <Text mb={0} color="red">Delete Metric</Text>
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    
                    </Flex>

                  </Flex>
                </Card>
              )
            )}
          {deleteMetricDialog && (
            <AlertDialog
            isOpen={deleteMetricDialog}
            leastDestructiveRef={cancelDeleteRef}
            onClose={closeDeleteMetricDialog}
          >
            <AlertDialogOverlay
              style={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
            >
              <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  Delete Metric
                </AlertDialogHeader>

                <AlertDialogBody>
                  Are you sure you want to delete this metric?
                </AlertDialogBody>

                <AlertDialogFooter>
                  <Button
                    ref={cancelDeleteRef}
                    colorScheme="green"
                    onClick={closeDeleteMetricDialog}
                  >
                    Cancel
                  </Button>
                  <Button
                    colorScheme="red"
                    onClick={() => handleDeleteMetric()}
                    ml={3}
                  >
                    Delete
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
          )}

          {deleteMetricFlag && (
            <Modal
              isOpen={deleteMetricFlag}
              onClose={closeDeleteMetricModal}
              isCentered
              motionPreset="scale"
            >
              <ModalOverlay bg="blackAlpha.300" />
              <ModalContent m="5%" maxW="90vw" maxH="90vh" overflow="hidden">
                <ModalHeader>
                  <Flex direction="row" justify="space-between" align="center" width="100%">
                    <Flex direction="column" justify="center" flex="1" p={1}>
                      <Text fontSize="lg" fontWeight="bold" color="black" mb={0}>
                        Dependency Confirmation
                      </Text>
                    </Flex>
                    <ModalCloseButton />
                  </Flex>
                </ModalHeader>
                <ModalBody overflow="auto">
                  <Box p={4}>
                    {/* Dependent Metrics */}
                    <Text fontSize="xl" mb={4} fontWeight="bold">
                      These are the dependent metrics:
                    </Text>
                    {dependentMetrics.length > 0 ? (
                      <VStack align="stretch" spacing={2}>
                        {dependentMetrics.map(metric => (
                          <Flex justify="space-between" key={metric.id} pl={5} pr={20}>
                            <Text fontWeight="bold">{metric.name}</Text>
                            <Box>
                            <Button
                              size="sm"
                              flex="2"
                              colorScheme="purple"
                              bg="purple.50"
                              color="black"
                              borderRadius="xl"
                              border="1px solid"
                              borderColor="#4e1e6c"
                              _hover={{ bg: "#4e1e6c", color: "white" }}
                              onClick={() => handleViewMKGModal(metric)}
                            >
                              View MKG
                            </Button>
                            </Box>
                          </Flex>
                        ))}
                      </VStack>
                    ) : (
                      <Text>No dependent metrics.</Text>
                    )}

                    {/* Dependent Analysis */}
                    <Text fontSize="xl" mt={6} mb={4} fontWeight="bold">
                      These are the dependent analyses:
                    </Text>
                    {dependentAnalysis.length > 0 ? (
                      <VStack align="stretch" spacing={2}>
                        {dependentAnalysis.map(analysis => (
                          <Flex justify="space-between" key={analysis.id} pl={5} pr={20}>
                            <Text fontWeight="bold">{analysis.name}</Text>
                            <Box>
                            <Tooltip label="Show analysis">
                            <Link href={`/analyze/${analysis.id}`} isExternal>
                            <IconButton
                              aria-label="Show analysis"
                              pl={4}
                              icon={<ArrowRightIcon/>}
                              size="md"
                              // colorScheme="red" 
                              bg="none"
                              color="green"
                            />
                            </Link>
                            </Tooltip>
                            <Tooltip label="Delete analysis">
                            <IconButton
                              aria-label="Delete analysis"
                              pl={4}
                              icon={<DeleteIcon />}
                              size="md"
                              // colorScheme="red" 
                              bg="none"
                              color="grey"
                              onClick={() => deleteAnalysis(analysis.id!)} 
                            />
                            </Tooltip>
                            </Box>
                          </Flex>
                        ))}
                      </VStack>
                    ) : (
                      <Text>No dependent analyses.</Text>
                    )}
                  </Box>
                </ModalBody>
              </ModalContent>
            </Modal>
          )}
          {viewMKG && (
            <Modal
              isOpen={isOpen}
              onClose={onClose}
              isCentered
              motionPreset="scale"
            >
              <ModalOverlay />
              <ModalContent m="5%" maxW="90vw" maxH="90vh" overflow="none">
                <ModalHeader>
                  <Flex
                    direction="row"
                    justify="space-between"
                    align="center"
                    width="100%"
                  >
                    {/* Metric Name Section */}
                    <Flex
                      direction="column"
                      justify="center"
                      //   align="flex-start"
                      flex="1"
                      p={1}
                    >
                      <Text
                        fontSize="lg"
                        fontWeight="bold"
                        color="black"
                        mb={0}
                      >
                        {" "}
                        Knowledge Graph for: {selectedMetric?.name}
                      </Text>
                    </Flex>
                  </Flex>
                  <ModalCloseButton />
                </ModalHeader>
                <ModalBody overflow="auto">
                  <VisualizeGraphContainer metricId={selectedMetric} />
                </ModalBody>
              </ModalContent>
            </Modal>
          )}
          {editMKG && (
            <Modal
              isOpen={isOpen}
              onClose={onClose}
              isCentered
              motionPreset="scale"
            >
              <ModalOverlay />
              <ModalContent m="5%" maxW="90vw" maxH="90vh" overflow="none">
                <ModalHeader>
                  <Flex
                    direction="row"
                    justify="space-between"
                    align="center"
                    width="100%"
                  >
                    {/* Metric Name Section */}
                    <Flex
                      direction="column"
                      justify="center"
                      //   align="flex-start"
                      flex="1"
                      p={1}
                    >
                      <Text
                        fontSize="lg"
                        fontWeight="bold"
                        color="black"
                        mb={0}
                      >
                        {" "}
                        Edit Knowledge Graph for: {selectedMetric?.name}
                      </Text>
                    </Flex>
                  </Flex>
                  <ModalCloseButton />
                </ModalHeader>
                <ModalBody overflow="auto">
                  <CreateMKGContainer
                    metricInfo={metricInfo}
                    columns={modelInfo?.columns || null}
                  />
                </ModalBody>
              </ModalContent>
            </Modal>
          )}
          {editMetricDefinition && (
            <Modal
              isOpen={isOpen}
              onClose={onClose}
              isCentered
              motionPreset="scale"
            >
              <ModalOverlay />
              <ModalContent maxW="60vw" maxH="90vh" overflow="none">
                <ModalHeader>
                  <Flex
                    direction="row"
                    justify="space-between"
                    align="center"
                    width="100%"
                  >
                    {/* Metric Name Section */}
                    <Flex
                      direction="column"
                      justify="center"
                      //   align="flex-start"
                      flex="1"
                      p={1}
                    >
                      <Text
                        fontSize="lg"
                        fontWeight="bold"
                        color="black"
                        mb={0}
                      >
                        {" "}
                        Edit Knowledge Graph for: <Text fontWeight="bold" display="inline">{selectedMetric?.name}</Text>                      </Text>
                    </Flex>
                  </Flex>
                  <ModalCloseButton />
                </ModalHeader>
                <ModalBody overflow="auto">
                  <EditMetricDefinitionContainer
                    createMetricFlag={false}
                    modelInfo={null}
                    metricHeader={selectedMetric!}
                    onClose={onClose}
                  />
                </ModalBody>
              </ModalContent>
            </Modal>
          )}
        </SimpleGrid>
      )}
    </Stack>
  );
};

export default ViewMetricCard;
