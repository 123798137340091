import React from 'react'
import {
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Box,
  Button,
  Select,
  VStack,
  HStack,
  FormControl,
  Input,
  Icon,
  AccordionIcon,
  Text,
  Flex,
  TagCloseButton,
  Tag,
  TagLabel,
  Tooltip,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Checkbox,
  Spinner,
} from '@chakra-ui/react'
import { AddIcon, ArrowForwardIcon, ChevronDownIcon } from '@chakra-ui/icons'
import { Header } from '../../../containers/Interfaces'
import {
  TemporalColumn,
  FilterColumn,
} from '../../../containers/commentary/Interfaces'

interface ContextAccordianProps {
  filters: FilterColumn[]
  selectedTemporalColumnsFromDropdown: TemporalColumn[]
  uniqueRelatedAttributeName: string[]
  selectedFilter: FilterColumn
  selectedTemporalColumn: TemporalColumn
  handleFilterSelection: (
    header: string,
    operator: string,
    values: string[],
  ) => void
  handleTimeSelection: (
    name: string,
    time_bucket: string,
    column?: Header,
    quarter?: {
      name: string
      value: string
    },
    month?: {
      name: string
      value: string
    },
    year?: string,
  ) => void
  handleAddFilter: () => void
  handleAddTemporalColumn: () => void
  handleRemoveFilter: (index: number) => void
  handleRemoveTemporalColumn: (index: number) => void
  selectedMonth: {
    value: string
    name: string
  }
  selectedQuarter: { value: string; name: string }
  selectedYear: string
  setSelectedMonth: React.Dispatch<
    React.SetStateAction<{
      value: string
      name: string
    }>
  >
  setSelectedQuarter: React.Dispatch<
    React.SetStateAction<{ value: string; name: string }>
  >
  setSelectedYear: React.Dispatch<React.SetStateAction<string>>
  availableTemporalColumns: string[]
  filterContextValues: string[]
  selectedOptions: string[]
  handleToggle: (value: string) => void
  isLoadingAttributeValues: boolean
  isFailedAttributeValues: boolean
}

const ContextAccordian: React.FC<ContextAccordianProps> = ({
  filters,
  selectedTemporalColumnsFromDropdown,
  uniqueRelatedAttributeName,
  selectedFilter,
  selectedTemporalColumn,
  handleFilterSelection,
  handleTimeSelection,
  handleAddFilter,
  handleAddTemporalColumn,
  handleRemoveFilter,
  handleRemoveTemporalColumn,
  selectedMonth,
  selectedQuarter,
  selectedYear,
  setSelectedMonth,
  setSelectedQuarter,
  setSelectedYear,
  availableTemporalColumns,
  filterContextValues,
  selectedOptions,
  handleToggle,
  isLoadingAttributeValues,
  isFailedAttributeValues,
}) => {
  return (
    <AccordionItem>
      <h2>
        <Tooltip
          hasArrow
          label="Expand"
          placement="right"
          color="white"
          bg="#4e1e6c"
          arrowSize={9}
        >
          <AccordionButton alignItems="center" pt={3}>
            <Box flex="1" textAlign="left" fontSize="lg" fontWeight="700">
              Add Context
            </Box>
            <AccordionIcon
              fontSize="2xl"
              p={1}
              borderRadius="full"
              bg="#4e1e6c"
              color="purple.50"
              style={{ margin: 0 }}
            />
          </AccordionButton>
        </Tooltip>
      </h2>
      <AccordionPanel pb={4}>
        <VStack spacing={4} align="stretch">
          {/* Temporal Columns Section */}
          <Box
            border="2px"
            borderColor="#cec7dc"
            p={2}
            width="100%"
            borderRadius={7}
          >
            <Text fontSize="sm" fontWeight="bold" mb={2} align="left">
              TIME
            </Text>

            {selectedTemporalColumnsFromDropdown.map(
              (temporalColumn, index) => (
                <Box
                  mt={1}
                  mb={1}
                  width="100%"
                  display="flex"
                  flexWrap="wrap"
                  gap={1}
                  bg="purple.50"
                  pt={2}
                  // pl={2}
                  borderRadius="md"
                >
                  <Tag
                    key={index}
                    variant="solid"
                    colorScheme="purple"
                    bg="#eae3f9"
                    color="black"
                    mr={2}
                    mb={2}
                    flexGrow={1}
                  >
                    <TagLabel
                      flexGrow={1}
                      textAlign="left"
                      fontSize="xs"
                      fontWeight="bold"
                    >
                      <Icon as={ArrowForwardIcon} color="purple.500" mr={1} />
                      {`${temporalColumn.name} =
${temporalColumn.month?.name} ${temporalColumn.quarter?.name} ${temporalColumn.year}`}
                    </TagLabel>
                    <TagCloseButton
                      onClick={() => handleRemoveTemporalColumn(index)}
                    />
                  </Tag>
                </Box>
              ),
            )}

            {/* <HStack spacing={2}> */}
            <HStack spacing={2}>
              <FormControl id="column" size="sm">
                <Select
                  fontSize="sm"
                  placeholder="Column"
                  onChange={(e) => {
                    // Reset month and year when changing the column
                    setSelectedMonth({
                      name: '',
                      value: '',
                    })
                    setSelectedYear('')
                    const name = e.target.value
                    handleTimeSelection(
                      // temporalColumnFromModelInfo.find(
                      //   (column) => column.name === e.target.value
                      // ) || {},
                      name,
                      selectedTemporalColumn.time_bucket || '',
                    )
                  }}
                  value={selectedTemporalColumn.name || ''}
                >
                  {availableTemporalColumns.map((column) => (
                    <option key={column} value={column}>
                      {column}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <FormControl id="time_bucket" size="sm">
                <Select
                  fontSize="sm"
                  placeholder="Time Bucket"
                  onChange={(e) => {
                    // Reset month and year when changing the operator
                    setSelectedMonth({
                      name: '',
                      value: '',
                    })
                    setSelectedYear('')
                    handleTimeSelection(
                      selectedTemporalColumn.name || '',
                      e.target.value || '',
                    )
                  }}
                  value={selectedTemporalColumn.time_bucket || ''}
                >
                  <option value="Monthly">Monthly</option>
                  <option value="Quarterly">Quarterly</option>
                  <option value="Yearly">Yearly</option>
                </Select>
              </FormControl>
              {/* Conditionally render month dropdown if Monthly is selected */}
              {selectedTemporalColumn.time_bucket === 'Monthly' && (
                <FormControl id="month" size="sm">
                  <Select
                    fontSize="sm"
                    placeholder="Month"
                    onChange={(e) =>
                      setSelectedMonth({
                        value: e.target.value,
                        name: e.target.options[e.target.selectedIndex].text,
                      })
                    }
                    value={selectedMonth.value}
                  >
                    {/* Add options for months */}
                    <option value="1">January</option>
                    <option value="2">February</option>
                    <option value="3">March</option>
                    <option value="4">April</option>
                    <option value="5">May</option>
                    <option value="6">June</option>
                    <option value="7">July</option>
                    <option value="8">August</option>
                    <option value="9">September</option>
                    <option value="10">October</option>
                    <option value="11">November</option>
                    <option value="12">December</option>
                    {/* Add other months */}
                  </Select>
                  <Select
                    fontSize="sm"
                    placeholder="Year"
                    onChange={(e) => setSelectedYear(e.target.value)}
                    value={selectedYear}
                  >
                    {/* Add options for years */}
                    <option value="2020">2020</option>
                    <option value="2021">2021</option>
                    <option value="2022">2022</option>
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                    {/* Add other years */}
                  </Select>
                </FormControl>
              )}
              {selectedTemporalColumn.time_bucket === 'Quarterly' && (
                <FormControl id="quarter" size="sm">
                  <Select
                    fontSize="sm"
                    placeholder="Quarter"
                    onChange={(e) =>
                      setSelectedQuarter({
                        value: e.target.value,
                        name: e.target.options[e.target.selectedIndex].text,
                      })
                    }
                    value={selectedQuarter.value}
                  >
                    {/* Add options for months */}
                    <option value="1">Quarter 1</option>
                    <option value="2">Quarter 2</option>
                    <option value="3">Quarter 3</option>
                    <option value="4">Quarter 4</option>
                    {/* Add other months */}
                  </Select>
                  <Select
                    fontSize="sm"
                    placeholder="Year"
                    onChange={(e) => setSelectedYear(e.target.value)}
                    value={selectedYear}
                  >
                    {/* Add options for years */}
                    <option value="2020">2020</option>
                    <option value="2021">2021</option>
                    <option value="2022">2022</option>
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                    {/* Add other years */}
                  </Select>
                </FormControl>
              )}
              {selectedTemporalColumn.time_bucket === 'Yearly' && (
                <FormControl id="quarter" size="sm">
                  <Select
                    fontSize="sm"
                    placeholder="Year"
                    onChange={(e) => setSelectedYear(e.target.value)}
                    value={selectedYear}
                  >
                    {/* Add options for years */}
                    <option value="2020">2020</option>
                    <option value="2021">2021</option>
                    <option value="2022">2022</option>
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                    {/* Add other years */}
                  </Select>
                </FormControl>
              )}
            </HStack>
            <Flex justifyContent="flex-end" pt={2}>
              <Button
                mt={2}
                bg="gray.300"
                leftIcon={<AddIcon />}
                size="xs"
                iconSpacing={0}
                onClick={handleAddTemporalColumn}
              ></Button>
            </Flex>
          </Box>

          {/* Filters Section */}
          <Box
            border="2px"
            borderColor="#cec7dc"
            p={2}
            width="100%"
            borderRadius={7}
          >
            <Text fontSize="sm" fontWeight="bold" mb={2} align="left">
              ATTRIBUTES
            </Text>

            {filters.map((filter, index) => (
              <Box
                mt={1}
                mb={1}
                width="100%"
                display="flex"
                flexWrap="wrap"
                gap={1}
                bg="purple.50"
                pt={2}
                // pl={2}
                borderRadius="md"
              >
                <Tag
                  key={index}
                  variant="solid"
                  colorScheme="purple"
                  bg="#eae3f9"
                  color="black"
                  mr={2}
                  // mb={2}
                  flexGrow={1}
                >
                  <TagLabel
                    flexGrow={1}
                    textAlign="left"
                    fontSize="xs"
                    fontWeight="bold"
                  >
                    <Icon as={ArrowForwardIcon} color="purple.500" mr={1} />
                    {`${filter.name} = ${filter.values}`}
                  </TagLabel>
                  <TagCloseButton onClick={() => handleRemoveFilter(index)} />
                </Tag>
              </Box>
            ))}

            <HStack spacing={2} mt={3} width="full">
              <Box flex="1">
                <FormControl id="logical_column_header" size="sm">
                  <Select
                    fontSize="sm"
                    placeholder="Attribute"
                    onChange={(e) => {
                      const value = e.target.value
                      handleFilterSelection(
                        value,
                        selectedFilter.operator!,
                        selectedFilter.values || [],
                      )
                      console.log('value ', value)
                    }}
                    value={selectedFilter.name}
                  >
                    {uniqueRelatedAttributeName.map((filter: any) => (
                      <option key={filter} value={filter}>
                        {filter}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              <Box flex="1">
                {isLoadingAttributeValues && (
                  <Spinner color="purple.500"></Spinner>
                )}
                {isFailedAttributeValues && (
                  <Text fontSize="sm">Failed to fetch values</Text>
                )}
                {
                !isLoadingAttributeValues && !isFailedAttributeValues && process.env.REACT_APP_ATTRIBUTE_VALUES_DROPDOWN === 'true' && (
                 <Menu closeOnSelect={false} preventOverflow={true}>
                  <MenuButton
                    as={Button}
                    maxW="100%"
                    width="auto"
                    rightIcon={<ChevronDownIcon />}
                    fontSize="sm"
                    bg="purple.50"
                    border="1px" // Specify the thickness of the border
                    borderColor="gray.300" // Define the color of the border
                    borderRadius="md"
                    fontWeight="normal"
                    _hover={{ bg: 'purple.50' }}
                  >
                    {selectedOptions.length > 0
                      ? `Selected (${selectedOptions.length})`
                      : 'Select Options'}
                  </MenuButton>
                  <MenuList
                    maxHeight="200px"
                    overflowY="auto"
                    ml={-6}
                  >
                    {filterContextValues &&
                      filterContextValues.map(
                        (option: string, index: number) => (
                          <MenuItem key={index} minH="40px" zIndex={10}>
                            <Checkbox
                              isChecked={selectedOptions.includes(option)}
                              onChange={() => handleToggle(option)}
                            >
                              {option}
                            </Checkbox>
                          </MenuItem>
                        ),
                      )}
                  </MenuList>
                </Menu>
                ) 
               }
                {!isLoadingAttributeValues && !isFailedAttributeValues && process.env.REACT_APP_ATTRIBUTE_VALUES_DROPDOWN === 'false' && (
                <FormControl id="value" size="sm">
                  <Input
                    fontSize="sm"
                    placeholder="Value"
                    onChange={(e) => {
                      handleFilterSelection(
                        selectedFilter.name!,
                        selectedFilter.operator!,
                        [e.target.value] || [],
                      )
                    }}
                    value={selectedFilter.values}
                    focusBorderColor="#4e1e6c"
                  />
                </FormControl>
                )}
              </Box>
            </HStack>
            <Flex justifyContent="flex-end">
              <Button
                mt={2}
                bg="gray.300"
                leftIcon={<AddIcon />}
                size="xs"
                iconSpacing={0}
                onClick={handleAddFilter}
              ></Button>
            </Flex>
          </Box>
        </VStack>
      </AccordionPanel>
    </AccordionItem>
  )
}

export default ContextAccordian
