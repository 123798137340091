import { Center, Circle, Text, keyframes, Avatar, Flex } from '@chakra-ui/react';
import logo from '../../assets/Anko.png';
import React from 'react';

const rotateAnimation = keyframes`
  0% { transform: rotate(0deg); }
  50% { transform: rotate(15deg); }
  100% { transform: rotate(120deg); }
`;

interface EmptyStatePromptProps {
  text: string;
  flag: boolean;
}

export const EmptyStatePrompt: React.FC<EmptyStatePromptProps> = ({ text, flag }) => {
  const animation = `${rotateAnimation} 1s linear infinite`;
  return (
    <Center height="full">
      <Flex direction="column" alignItems="center" gap="4">
        {flag ? <Circle
          bg="#E8E0FA"
          color="fg.default"
          size="10"
          outline="2px solid"
          outlineColor="#4e1e6c"
        >
          <Avatar size="xs" src={logo} name="Assistant" animation={animation} color="black"/>
        </Circle>:""}
        {text && (
          <Text fontSize="lg" color="gray.400" style={{ filter: "blur(0.5px)" }}>
            {text}
          </Text>
        )}
      </Flex>
    </Center>
  );
};

export default EmptyStatePrompt;
