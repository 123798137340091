import {
  Instant,
  DateTimeFormatter,
  ZoneId,
  LocalDate,
  ChronoUnit,
  TemporalAdjusters,
} from "@js-joda/core";
import { Locale } from "@js-joda/locale_en";
import '@js-joda/timezone';

/**
 * Converts epoch milliseconds to a formatted date string in IST.
 * @param {number} num - Epoch milliseconds.
 * @returns {string} Formatted date string in IST.
 */
export const epochConverter = (num: number): string => {
  const instant = Instant.ofEpochMilli(num);
  const formatter = DateTimeFormatter.ofPattern(
    "d-MMM-yyyy, HH:mm"
  ).withLocale(Locale.US);
  return instant.atZone(ZoneId.of("Asia/Kolkata")).format(formatter);
};

/**
 * Parses a date string in MM/DD/YYYY format to a LocalDate object.
 * @param {string} dateStr - Date string in MM/DD/YYYY format.
 * @returns {LocalDate} Parsed LocalDate object.
 */
const parseDate = (dateStr: string): LocalDate => {
  const formatter = DateTimeFormatter.ofPattern("MM/dd/yyyy");
  return LocalDate.parse(dateStr, formatter);
};

/**
 * Formats a LocalDate object back to MM/DD/YYYY string.
 * @param {LocalDate} date - LocalDate object.
 * @returns {string} Formatted date string in MM/DD/YYYY.
 */
const formatDate = (date: LocalDate): string => {
  const formatter = DateTimeFormatter.ofPattern("MM/dd/yyyy");
  return date.format(formatter);
};

/**
 * Determines the period type based on the difference between startDate and endDate.
 * @param {LocalDate} startDate - Start date of the period.
 * @param {LocalDate} endDate - End date of the period.
 * @returns {string} Period type: 'weekly', 'monthly', 'quarterly', 'yearly'.
 * @throws {Error} If the period type is unsupported.
 */
const determinePeriodType = (
  startDate: LocalDate,
  endDate: LocalDate
): "weekly" | "monthly" | "quarterly" | "yearly" => {
  const durationInWeeks = ChronoUnit.WEEKS.between(startDate, endDate) + 1;
  if (durationInWeeks === 1) {
    return "weekly";
  }

  const durationInMonths =
    endDate.monthValue() - startDate.monthValue() + 1 + // Adding 1 to include both months
    (endDate.year() - startDate.year()) * 12;
  if (durationInMonths === 1) {
    return "monthly";
  } else if (durationInMonths === 3) {
    return "quarterly";
  } else if (durationInMonths === 12) {
    return "yearly";
  } else {
    throw new Error("Unsupported period type");
  }
};

/**
 * Calculates the previous period based on the current period type.
 * @param {LocalDate} startDate - Start date of the current period.
 * @param {LocalDate} endDate - End date of the current period.
 * @param {string} periodType - Type of the current period: 'weekly', 'monthly', 'quarterly', 'yearly'.
 * @returns {{ gte: LocalDate; lte: LocalDate }} Previous period's start and end dates.
 */
const getPreviousPeriod = (
  startDate: LocalDate,
  endDate: LocalDate,
  periodType: "weekly" | "monthly" | "quarterly" | "yearly"
): { gte: LocalDate; lte: LocalDate } => {
  let startDatePreviousPeriod: LocalDate;
  let endDatePreviousPeriod: LocalDate;

  switch (periodType) {
    case "weekly":
      // Previous week: subtract 1 week from startDate and endDate
      startDatePreviousPeriod = startDate.minusWeeks(1);
      endDatePreviousPeriod = endDate.minusWeeks(1);
      break;

    case "monthly":
      // Previous month: subtract 1 month from startDate and set endDate to last day of previous month
      startDatePreviousPeriod = startDate.minusMonths(1);
      endDatePreviousPeriod = startDatePreviousPeriod.with(TemporalAdjusters.lastDayOfMonth());
      break;

    case "quarterly":
      // Previous quarter: subtract 3 months from startDate and set endDate to last day of the previous quarter
      startDatePreviousPeriod = startDate.minusMonths(3);
      endDatePreviousPeriod = startDatePreviousPeriod.plusMonths(2).with(TemporalAdjusters.lastDayOfMonth());
      break;

    case "yearly":
      // Previous year: subtract 1 year from startDate and endDate
      startDatePreviousPeriod = startDate.minusYears(1);
      endDatePreviousPeriod = endDate.minusYears(1);
      break;

    default:
      throw new Error("Unsupported period type");
  }

  return { gte: startDatePreviousPeriod, lte: endDatePreviousPeriod };
};

/**
 * Calculates the same period last year based on the current period type.
 * @param {LocalDate} startDate - Start date of the current period.
 * @param {LocalDate} endDate - End date of the current period.
 * @param {string} periodType - Type of the current period: 'weekly', 'monthly', 'quarterly', 'yearly'.
 * @returns {{ gte: LocalDate; lte: LocalDate }} Same period last year's start and end dates.
 */
const getSamePeriodLastYear = (
  startDate: LocalDate,
  endDate: LocalDate,
  periodType: "weekly" | "monthly" | "quarterly" | "yearly"
): { gte: LocalDate; lte: LocalDate } => {
  let gte: LocalDate;
  let lte: LocalDate;

  switch (periodType) {
    case "weekly":
      gte = startDate.minusYears(1);
      lte = endDate.minusYears(1);
      break;

    case "monthly":
      gte = startDate.minusYears(1);
      lte = endDate.minusYears(1);
      break;

    case "quarterly":
      gte = startDate.minusYears(1);
      lte = endDate.minusYears(1);
      break;

    case "yearly":
      gte = startDate.minusYears(1);
      lte = endDate.minusYears(1);
      break;

    default:
      throw new Error("Unsupported period type");
  }

  return { gte, lte };
};

/**
 * Computes the previous period and the same period last year based on the provided date range.
 * Supports weekly, monthly, quarterly, and yearly periods.
 * @param {string} gte - Start date in MM/DD/YYYY format.
 * @param {string} lte - End date in MM/DD/YYYY format.
 * @returns {{
 *   samePeriodLastYear: { gte: string; lte: string };
 *   previousPeriod: { gte: string; lte: string };
 * }}
 * Formatted date ranges for the same period last year and the previous period.
 */
export const getDateRanges = (
  gte: string,
  lte: string
): {
  samePeriodLastYear: { gte: string; lte: string };
  previousPeriod: { gte: string; lte: string };
} => {
  const startDate = parseDate(gte);
  const endDate = parseDate(lte);

  // Determine the period type
  const periodType = determinePeriodType(startDate, endDate);

  // Calculate previous period
  const previousPeriodDates = getPreviousPeriod(startDate, endDate, periodType);

  // Calculate same period last year
  const samePeriodLastYearDates = getSamePeriodLastYear(
    startDate,
    endDate,
    periodType
  );

  return {
    samePeriodLastYear: {
      gte: formatDate(samePeriodLastYearDates.gte),
      lte: formatDate(samePeriodLastYearDates.lte),
    },
    previousPeriod: {
      gte: formatDate(previousPeriodDates.gte),
      lte: formatDate(previousPeriodDates.lte),
    },
  };
};
