import {
  createContext,
  useState,
  useContext,
  FC,
  ReactNode,
  useEffect,
  useRef,
} from "react";
import {
  ActionRequest,
  AnalysisActionRequest,
  AssignToPageActionRequest,
  BenchmarkSubtreeNodeId,
  GenerateCommentaryActionRequest,
  Job,
  JobProto,
  JobRequest,
  NotifySlackActionRequest,
} from "../../containers/batch/Batch";
import { ColumnInfo, Filter, Header, Metric } from "../../containers/Interfaces";
import { ActionType, ColumnType, CustomBenchmark, Frequency, MetricEdgeType } from "../../utils/enum";
import { AnalysisTreeRequest, Benchmark, Context } from "../../containers/commentary/Interfaces";
import { v4 as uuidv4 } from "uuid";
import { createBenchmarks } from "../../components/common/CreateBenchmark";
import showToast from "../../hooks/useCustomToast";
import { StatefulRequestBuilder } from "../../containers/stateful/StatefulRequests";
import { StatefulResponse } from "../../containers/stateful/Stateful";
import { statefulAnalysisTree } from "../../services/AnalysisTreeServices";
import { listMetrics, getMetricById } from "../../services/MetricService";
import { createOrUpdateJob, getJob, getJobruns, listJobs } from "../../services/BatchServices";
import { getColumnValues, getModelById } from "../../services/DataModelService";
import { sortBy } from "../../utils/sortUtils";
import { generateStepName, getTreeName } from "../../utils/nameUtils";

interface BatchContextProps {
  jobHeaders: Header[];
  customBenchmark: {name: string, id: string}[];
  customBenchmarkName: string[];
  setJobHeaders: React.Dispatch<React.SetStateAction<Header[]>>;
  setCustomBenchmark: React.Dispatch<React.SetStateAction<{name: string, id: string}[]>>;
  setCustomBenchmarkName: React.Dispatch<React.SetStateAction<string[]>>;
  setSelectedJob: React.Dispatch<React.SetStateAction<Job | null>>;
  setSelectedJobStepIndex: React.Dispatch<React.SetStateAction<number | null>>;
  setSelectedContextMap: React.Dispatch<React.SetStateAction<{ [key: string]: string[] }>>;
  setDate: React.Dispatch<React.SetStateAction<{ [key: string]: string[] }>>;
  date: { [key: string]: string[] };
  selectedJobStepIndex: number | null;
  setAnalysisActionRequest: React.Dispatch<React.SetStateAction<AnalysisActionRequest[]>>;
  metricHeaders: Header[];
  selectedMetricHeader: Header | null;
  selectedJob: Job | null;
  fetchHeaders: () => void;
  selectJob: (header: Header) => void;
  createJob: (name: string) => Promise<void>;
  handleMetricHeaderSelect: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  setSelectedAttributesValues: (values: string[]) => void;
  selectedAttributesValues: string[];
  selectedAttribute: string;
  relatedAttributeSet: Set<String>;
  attributeValueMap: { [key: string]: string[] };
  selectedContextMap: { [key: string]: string[] };
  metricInfoMap: { [key: string]: Metric };
  handleAttributeSelect: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  excludeEdges: string[];
  selectedEdgeToExclude: string[];
  setSelectedEdgeToExclude: React.Dispatch<React.SetStateAction<string[]>>;
  addToContextMap: () => void;
  handleSaveJobStep: (runStep: boolean) => void;
  deleteJobStep:(index: number) => void;
  analysisActionRequest: AnalysisActionRequest[];
  handleUpdateJob: (step: AnalysisActionRequest[]) => Promise<void>;
  runJobStep: (req: AnalysisTreeRequest) => Promise<void>;
  handleGetJobRuns: (header: Header) => Promise<void>;
  handleJobStepClick: (step: AnalysisActionRequest, index: number) => void;
  jobRunHeaders: Header[];
  slideName: string;
  setSlideName: React.Dispatch<React.SetStateAction<string>>;
  slackChannelId: string;
  selectedNodeIds: string[];
  setSlackChannelId: React.Dispatch<React.SetStateAction<string>>;
  setSelectedNodeIds: React.Dispatch<React.SetStateAction<string[]>>;
  setSelectedReport: React.Dispatch<React.SetStateAction<Header | null>>;
  selectedReport: Header | null;
  reports: Header[],
  setSelectedPurpose: React.Dispatch<React.SetStateAction<string>>;
  selectedPurpose: string;
  setChooseTargets: React.Dispatch<React.SetStateAction<string[]>>;
  chooseTargets: string[];
  errorMessage: string;
  validateFields: () => boolean;
  statefulTreeRequest: AnalysisTreeRequest[];
  selectedJobId: string | null;
  setSelectedJobId: React.Dispatch<React.SetStateAction<string | null>>;
  globatDateFilter: string | null;
  setGlobalDateFilter: React.Dispatch<React.SetStateAction<string | null>>;        
  frequency: Frequency;
  setFrequency: React.Dispatch<React.SetStateAction<Frequency>>;
  startDate: Date | null;
  setStartDate: React.Dispatch<React.SetStateAction<Date | null>>;
  endDate: Date | null;
  setEndDate: React.Dispatch<React.SetStateAction<Date | null>>;
  selectedRunHeader: Header | null;
  setSelectedRunHeader: React.Dispatch<React.SetStateAction<Header | null>>;
  showStatusPage: boolean;
  setShowStatusPage: React.Dispatch<React.SetStateAction<boolean>>;
  runStepLoader: boolean;
  jobLoaders: { actionLoader: boolean; headerLoader: boolean };
  analysisPlanLoader: boolean;
  setReports: React.Dispatch<React.SetStateAction<Header[]>>;
  setSelectedAttribute: React.Dispatch<React.SetStateAction<string>>;
  setJobRunHeaders: React.Dispatch<React.SetStateAction<Header[]>>;
  stepName: string;
  setStepName: React.Dispatch<React.SetStateAction<string>>;
}

// Create the context with a default value of undefined
const BatchContext = createContext<BatchContextProps | undefined>(undefined);

export const useBatchContext = () => {
  const context = useContext(BatchContext);
  if (!context) {
    throw new Error(
      "useBatchContext must be used within a BatchContextProvider"
    );
  }
  return context;
};

interface BatchContextProviderProps {
  children: ReactNode;
}

export const BatchContextProvider: FC<BatchContextProviderProps> = ({
  children,
}) => {

  const [jobHeaders, setJobHeaders] = useState<Header[]>([]); // For list of job headers
  const [analysisActionRequest, setAnalysisActionRequest] = useState<AnalysisActionRequest[]>([]);
  const [selectedJob, setSelectedJob] = useState<Job | null>(null);
  const [metricHeaders, setMetricHeaders] = useState<Header[]>([]);
  const [selectedAttributesValues, setSelectedAttributesValues] = useState<string[]>([]);
  const [selectedMetricHeader, setSelectedMetricHeader] = useState<Header | null>(null);
  const [metricInfoMap, setMetricInfoMap] = useState<{ [key: string]: Metric }>({});
  const [attributeValueMap, setAttributeValueMap] = useState<{[key: string]: string[]}>({});
  const [selectedContextMap, setSelectedContextMap] = useState<{ [key: string]: string[] }>({});
  const [relatedAttributeSet, setRelatedAttributeSet] = useState<Set<string>>(new Set());
  const [selectedAttribute, setSelectedAttribute] = useState<string>("");
  const [selectFundamentalEdgesToExclude, setFundamentalSelectEdgesToExclude] = useState<string[]>();
  const [selectedEdgeToExclude, setSelectedEdgeToExclude] = useState<string[]>([]);
  const [selectedJobStepIndex, setSelectedJobStepIndex] = useState<number | null>(null); 
  const [customBenchmarkName, setCustomBenchmarkName] = useState<string[]>([]);
  const [customBenchmark, setCustomBenchmark] = useState<{name: string, id: string}[]>([]);
  const [jobRunHeaders, setJobRunHeaders] = useState<Header[]>([]);
  const [reports, setReports] = useState<Header[]>([]);
  const [selectedReport, setSelectedReport] = useState<Header | null>(null);
  const [date, setDate] = useState<{ [key: string]: string[] }>({});
  const [selectedPurpose, setSelectedPurpose] = useState<string>("");
  const [chooseTargets, setChooseTargets] = useState<string[]>([]);
  const [statefulTreeRequest, setStatefulTreeRequest] = useState<AnalysisTreeRequest[]>([]);
  const [selectedJobId, setSelectedJobId] = useState<string | null>(null);
  const [globatDateFilter, setGlobalDateFilter] = useState<string | null>(null);
  const [selectedRunHeader, setSelectedRunHeader] = useState<Header | null>(null);
  const [showStatusPage, setShowStatusPage] = useState<boolean>(false);

  // Loader states
  const [runStepLoader, setRunStepLoader] = useState<boolean>(false);
  const [jobLoaders, setJobLoaders] = useState<{ actionLoader: boolean; headerLoader: boolean }>({
    actionLoader: false,
    headerLoader: false,
  });
  const [analysisPlanLoader, setAnalysisPlanLoader] = useState<boolean>(false);
  

  // States used in Global Entity
  const [frequency, setFrequency] = useState<Frequency>("");
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  
  // Initialize the useRef flag
  const isMetricSelectedFromStep = useRef(false);
  // State to hold error messages
  const [errorMessage, setErrorMessage] = useState<string>("");
  // State for "Add to Report" (Generate Commentary)
  const [slideName, setSlideName] = useState<string>("");
  // State to store selected node IDs
  const [selectedNodeIds, setSelectedNodeIds] = useState<string[]>([]);
  // State to store step name
  const [stepName, setStepName] = useState<string>("");
  // State for "Notify on Slack"
  const [slackChannelId, setSlackChannelId] = useState<string>("");
  
  // Fetch headers on mount
  useEffect(() => {
    fetchHeaders();
  }, []);

  // update metricInfo map based on metricHeaders
  useEffect(() => {
    const fetchMetricInfoAndUpdateMetricInfoMap = async () => {
      if (!selectedMetricHeader || !selectedMetricHeader.id) return; // Early return if no header is selected
      
      setAnalysisPlanLoader(true);
      const metricId = selectedMetricHeader.id.toString();

      // Always execute the logic, whether the metric is present or not
      // Fetch metric info with edges only if not already present
      let metricInfo: Metric;

      if (!metricInfoMap[metricId]) {
        // If metric is not present in the map, fetch it
        metricInfo = await getMetricById(metricId, true);
      } else {
        // If metric is already present, use the existing one
        metricInfo = metricInfoMap[metricId];
      }

       // Create sets for valid context and exclude edge names based on metric edges
      const validContextNames = new Set<string>();
      const validExcludeEdgeNames = new Set<string>();
      const existingBenchmark = new Set<{name:string, id: string}>();;
      metricInfo.edges?.forEach((edge) => {
        if (edge.edge_type === MetricEdgeType.ATTRIBUTE_EDGE && edge.related_attribute?.name) {
          validContextNames.add(edge.related_attribute.name);
          validExcludeEdgeNames.add(edge.related_attribute.name)
        }
        if (edge.edge_type === MetricEdgeType.FUNDAMENTAL_EDGE && edge.fundamental_relationship?.header?.name) {
          validExcludeEdgeNames.add(edge.fundamental_relationship.header.name);
        }
        if (edge.edge_type === MetricEdgeType.BENCHMARK_EDGE && edge.benchmark?.header?.name) {
          existingBenchmark.add({name: edge.benchmark.header.name, id: edge.benchmark.header.id!});
        }
      });

      // Create a new context map and exclude edges array based on initial values
      const newContextMap: { [key: string]: string[] } = {};
      const newExcludeEdges: string[] = [];

      // Filter the selectedContextMap to retain only valid keys and values
      for (const key in selectedContextMap) {
        if (validContextNames.has(key)) {
          // If the key is valid, keep it in the new map
          newContextMap[key] = selectedContextMap[key];
        }
      }

      // Filter the selectedEdgeToExclude array to retain only valid edges
      selectedEdgeToExclude.forEach((edge) => {
        if (validExcludeEdgeNames.has(edge)) {
          // If the edge is valid, keep it in the new array
          newExcludeEdges.push(edge);
        }
      });

      // Update the state with the filtered context map and exclude edges
      setSelectedContextMap(newContextMap);
      setSelectedEdgeToExclude(newExcludeEdges);

      // Temporary set to collect related attribute names for the current metric
      const tempRelatedAttributes = new Set<string>();

      // Temporary set to collect fundamental relationship names for the current metric
      const tempFundamentalRelationship = new Set<string>();

      // Array to collect all promises for fetching column values
      const columnValuePromises: Promise<void>[] = [];

      // Iterate over the edges and collect related attribute names
      for (const edge of metricInfo.edges || []) {
        if (
          edge.edge_type === MetricEdgeType.ATTRIBUTE_EDGE &&
          edge.related_attribute?.name
        ) {
          const attributeName = edge.related_attribute.name;
          tempRelatedAttributes.add(attributeName);

          // If column values are not already present, prepare a promise to fetch them
          if (!attributeValueMap[attributeName]) {
            const promise = getColumnValues(edge.related_attribute.id!).then(
              (columnValues) => {
                // Update attribute value map with fetched values
                setAttributeValueMap((prevMap) => ({
                  ...prevMap,
                  [attributeName]: columnValues,
                }));
              }
            );
            columnValuePromises.push(promise);
          }
        }
        if (
          edge.edge_type === MetricEdgeType.FUNDAMENTAL_EDGE &&
          edge.fundamental_relationship?.header
        ) {
          tempFundamentalRelationship.add(edge.fundamental_relationship.header.name!);
        }
      }

      // Wait for all column value fetch promises to complete
      await Promise.all(columnValuePromises);

      // Update the metricInfoMap state with the new data
      setMetricInfoMap((prevData) => ({
        ...prevData,
        [metricId]: metricInfo, // Update or add the metric info
      }));

      // Merge the temporary set with the global relatedAttributeSet state
      setRelatedAttributeSet(
        (prevSet) => new Set([...Array.from(tempRelatedAttributes)])
      );
      setFundamentalSelectEdgesToExclude([...Array.from(tempFundamentalRelationship)])
      
      // **Conditionally update customBenchmarkName**
      if (!isMetricSelectedFromStep.current) {
        setCustomBenchmarkName(
          Array.from(existingBenchmark).map((bench) => bench.name)
        );
      } else {
        // Reset the flag after handling step selection
        isMetricSelectedFromStep.current = false;
      }
      setAnalysisPlanLoader(false);
    };

    // Call the function to fetch and update the metric info
    fetchMetricInfoAndUpdateMetricInfoMap();
  }, [selectedMetricHeader]);

  // Convert string[] customBenchmark into [{ name: string, id: string }[]]
  useEffect(() => {
    setCustomBenchmark((prevBenchmark) => {
      // Create a Set of current customBenchmarkName for quick lookup
      const currentNames = new Set(customBenchmarkName);
  
      // Filter out benchmarks that are no longer present in customBenchmarkName
      const filteredBenchmarks = prevBenchmark.filter((benchmark) =>
        currentNames.has(benchmark.name)
      );
  
      // Create a Set of existing benchmark names after filtering
      const existingNames = new Set(filteredBenchmarks.map((b) => b.name));
  
      // Identify new benchmarks to add (names present in customBenchmarkName but not in existingNames)
      const newBenchmarks = customBenchmarkName
        .filter((bech) => !existingNames.has(bech))
        .map((bech) => ({
          name: bech,
          id: uuidv4(), // Generate a unique ID only for new names
        }));
  
      // Return the updated list by combining filtered benchmarks and new benchmarks
      return [...filteredBenchmarks, ...newBenchmarks];
    });
  }, [customBenchmarkName]);
  
  // Function to handle selection of a metric header from the dropdown
  const handleMetricHeaderSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const headerId = event.target.value;
    const selectedHeader = metricHeaders.find(
      (header) => header.id === headerId
    );
    if (selectedHeader) {
      setSelectedMetricHeader(selectedHeader);
      setSelectedNodeIds([])
    }
  };

  // Fetch headers using the service function
  const fetchHeaders = async () => {
    setJobLoaders({ headerLoader: true, actionLoader: false})
    const [listOfJobs, listOfMetrics] = await Promise.all([
      listJobs(),
      listMetrics(""/* modelId*/),
    ]);
    // Handle listOfJobs
    if (listOfJobs) {
      setJobHeaders(listOfJobs);
    } else {
      setJobHeaders([]);
    }

    // Handle listOfMetrics
    if (listOfMetrics) {
      setMetricHeaders(listOfMetrics);
    } else {
      setMetricHeaders([]);
    }
    setJobLoaders({ headerLoader: false, actionLoader: false})
  };

  // Function to select a job based on the header
  const selectJob = async(header: Header) => {
    setSelectedJobStepIndex(null);
    setSelectedRunHeader(null);
    setShowStatusPage(false);
    setJobLoaders({ headerLoader: false, actionLoader: true})

    const job = jobHeaders.find((job) => job?.id === header.id);

    // Below is conditions to conditonally render steps or past runs component.
    // If job is clicked then select the job which opens steps or past runs component.
    if(selectedJob && selectedJobId === null){
      setSelectedJobId(job?.id!);
    }

    // If job is again clicked then re-set the selectedJobId to null 
    // hence closes the steps or past runs component.
    if(selectedJob?.header?.id === header.id && selectedJobId !== null){ 
      setSelectedJobId(null);
      return;
    }

    if (job) {
      setSelectedJobId(job?.id!);
      setSelectedJob({ header: {...job} }); // Set the selected job from the jobs list

      // API call to get Job
      const response: JobProto = await getJob(header?.id!);

      // set loader state after promise returns.
      setJobLoaders({ headerLoader: false, actionLoader: false})

      if(response?.request){
        setAnalysisActionRequest(response.request.analysis_action_requests || []);
      } else {
        setAnalysisActionRequest([]);
      }

      if(response?.job_run_headers && response.job_run_headers.length > 0){
         // Sort the job runs by runtime in descending order (latest first)
        const sortedRuns = sortBy(response.job_run_headers, (header) => header.created_date_ms!, "desc");
        // Update the state with the sorted job runs
        setJobRunHeaders(sortedRuns);
      } else {
        setJobRunHeaders([]);
      }

    } else {
      setJobRunHeaders([]);
      setSelectedJob(null);
      setAnalysisActionRequest([]);
    }
  };

  // Function to create a new job
  const createJob = async (name: string) => {
    const response: JobProto = await createOrUpdateJob({name}); // Only send name, and receive the full job back
    if (response) {
      showToast("Job created", "", "success");
      setJobHeaders((prevHeaders) => [...prevHeaders, response?.header!]);
      setSelectedJob({header: response.header})
      setAnalysisActionRequest([]);
    } else {
      showToast("Error while creating Job", "", "error");
    }
  };

  // Handler to delete a step from jobSteps array
  const deleteJobStep = (index: number) => {
    setAnalysisActionRequest((prevSteps) => {
      // Filter out the step at the given index
      const updatedSteps = prevSteps.filter((_, i) => i !== index);

      // Call handleUpdateJob with the updated job steps
      handleUpdateJob(updatedSteps);

      // Return the updated job steps to set the state
      return updatedSteps;
    });
  };

  // Handle related attribute select
  const handleAttributeSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedAttributesValues([]);
    setSelectedAttribute(event.target.value);
  };

  // Function to exclude the selected attribute from the relatedAttributeSet
  const excludeEdges: string[] = [
    ...Array.from(relatedAttributeSet).filter(
      (attribute) => !Object.keys(selectedContextMap).includes(attribute)
    ),
    ...(selectFundamentalEdgesToExclude || []), // Append selectFundamentalEdgesToExclude if not undefined
  ];

  // Function to add selected attribute and values to the context map
  const addToContextMap = () => {
    if (selectedAttribute && selectedAttributesValues.length > 0) {
      setSelectedContextMap((prevMap) => ({
        ...prevMap,
        [selectedAttribute]: selectedAttributesValues,
      }));
      // Clear selections after adding
      setSelectedAttributesValues([]);
      setSelectedAttribute("");
    }
  };

  // Handler to save the JobRequest
  const handleSaveJobStep = (runStep: boolean = false) => {
    if (!selectedMetricHeader) return; // If no metric header is selected, exit
  
    const metricInfo = metricInfoMap[selectedMetricHeader.id!];
    if (!metricInfo) return; // If metric info is not found, exit
  
    const contextArray: Context[] = [];
    const filterValues: string[] = [];
    // Iterate over contextMap to build the context array for the selected metric
    for (const [attributeName, values] of Object.entries(selectedContextMap)) {
      // Find the edge in the metricInfo.edges that matches the attributeName
      const edge = metricInfo.edges?.find(
        (edge) =>
          edge.edge_type === MetricEdgeType.ATTRIBUTE_EDGE &&
          edge.related_attribute?.name === attributeName
      );
  
      if (edge) {
        const context: Context = {
          logical_column_header: edge.related_attribute, // Logical column header from the edge
          operator: "EQ", // Operator is always "EQ" as per your requirement
          values: values, // Selected values for the attribute
        };
  
        // Add the constructed context to the contextArray
        contextArray.push(context);
        filterValues.push(...values)
      }
    }

    // Inside handleSaveJobStep
    const benchmarks: Benchmark[] = [];

    // Loop through selected benchmarks
    customBenchmark.forEach((bench) => {
      // Try to find the benchmark in metricInfo.benchmarkEdges
      const benchmarkEdge = metricInfo.edges?.find(
        (edge) =>
          edge.edge_type === MetricEdgeType.BENCHMARK_EDGE &&
          edge?.benchmark?.header?.name === bench.name
      )?.benchmark;
      
      // some is used when we are searching in array of object
      if (benchmarkEdge && customBenchmark.some(bm => bm.name === benchmarkEdge.header.name!)) {
        benchmarks.push(benchmarkEdge);
      }      
      if (
        bench.name === CustomBenchmark.PreviousPeriod ||
        bench.name === CustomBenchmark.SamePeriodLastYear
      ) {
        // Handle custom benchmarks
        benchmarks.push({header:{name: bench.name, id: bench.id}, benchmark_filters:[], variance_tolerance: 0.025, ...(selectedPurpose === "Diagnostic" && {last_n_period_average: 4})});
      }
    });

    // Use the updatedMap to get the stepName
    const stepName = generateStepName([metricInfo.header.name!, filterValues.join(",")])
    
    // Create a new AnalysisTreeRequest for the selected metric
    const newAnalysisTreeRequest: AnalysisTreeRequest = {
      metricId: metricInfo.header.id, // Metric ID from the header
      tree_name: stepName, // Construct a tree name
      context: contextArray, // Add all contexts to the request
      benchmark: benchmarks,
      exclude_edges: [], // Initialize exclude_edges array
    };
  
    // Add any excluded edges to the exclude_edges array
    if (selectedEdgeToExclude.length > 0) {
      newAnalysisTreeRequest.exclude_edges = metricInfo.edges
        ?.filter(
          (edge) =>
            edge.edge_type === MetricEdgeType.ATTRIBUTE_EDGE &&
            selectedEdgeToExclude.includes(edge.related_attribute?.name || "") ||
            edge.edge_type === MetricEdgeType.FUNDAMENTAL_EDGE &&
            selectedEdgeToExclude.includes(edge.fundamental_relationship?.header?.name || "")
        )
        .map((edge) => edge.related_attribute || edge.fundamental_relationship?.header as Header) || [];
    }    

    if(runStep == true){
      runJobStep(newAnalysisTreeRequest);
      return;
    }

    setStatefulTreeRequest([newAnalysisTreeRequest]);
    // Prepare actions based on available inputs
    const actions: ActionRequest[] = [];

    // Add "Generate Commentary" action if slideName is provided
    if (slideName.trim()) {
      const assignToPageRequest: AssignToPageActionRequest = {
        page_name: slideName, // Populate based on your logic
        book_id: "",
      };
      actions.push({
        type: ActionType.ASSIGN_TO_PAGE,
        assign_to_page_request: assignToPageRequest,
      });
    }

    // Add "Notify Slack" action if slackChannelId is provided
    if (slackChannelId.trim()) {
      const notifySlackRequest: NotifySlackActionRequest = {
        channel_ids: [slackChannelId],
      };
      actions.push({
        type: ActionType.NOTIFY_SLACK,
        notify_slack_request: notifySlackRequest,
      });
    }

    // // Add "Generate Commentary" action
    if (selectedNodeIds.length >0 || slideName) {
      // Prepare the GenerateCommentaryActionRequest object
      const generateCommentaryActionRequest: GenerateCommentaryActionRequest = {
        selected_nodes: []
      };
    
      generateCommentaryActionRequest.selected_nodes = selectedNodeIds.map(id => {
        const parts = id.split('|');
    
        // If only one part is found, set the same id for both benchmark_id and edge_destination_id
        const benchmark_id = parts[0];
        const edge_destination_id = parts.length > 1 ? parts[1] : benchmark_id;
    
        return {
          benchmark_id: benchmark_id,
          edge_destination_id: edge_destination_id
        } as BenchmarkSubtreeNodeId;
      });
    
      actions.push({
        type: ActionType.GENERATE_COMMENTARY,
        generate_commentary_request: generateCommentaryActionRequest,
      });
    }
    
    // Check if the jobStep already exists in the jobSteps array and update
    setAnalysisActionRequest((prevJobSteps) => {
      if (!Array.isArray(prevJobSteps)) {
        return []; // Fallback to empty array to prevent errors
      }
    
      let index: number = -1;
    
      if (selectedJobStepIndex !== null && selectedJobStepIndex >= 0) {
        index = selectedJobStepIndex;
      } 

      const newAnalysisActionRequest: AnalysisActionRequest = {
        generate_tree_request: newAnalysisTreeRequest,
        actions: actions.length > 0 ? actions : undefined,
        analysis_step_name: stepName
      };
    
      let updatedJobSteps: AnalysisActionRequest[] = [...prevJobSteps];
      if (index !== -1) {
        updatedJobSteps[index] = newAnalysisActionRequest;
      } else {
        updatedJobSteps.push(newAnalysisActionRequest);
      }
      if(runStep === false){
        handleUpdateJob(updatedJobSteps);
      }
      return updatedJobSteps;
    });

  };
   
  // Handler to run Job
  const handleUpdateJob = async (steps: AnalysisActionRequest[]) => {
    // Create the JobRequest JSON structure
    const jobRequest: JobRequest = {
      global_properties: {},
      analysis_action_requests: steps
    };
    const response: JobProto = 
    await createOrUpdateJob({id: selectedJob?.header?.id, name: selectedJob?.header?.name, request: jobRequest});
    if(selectedJob && response.header){
      showToast("Steps updated", "", "success");
      if(response.request && response.request.analysis_action_requests){
        setAnalysisActionRequest([...response.request?.analysis_action_requests]);
      }
      if(selectedJobStepIndex === null){
        setSelectedJobStepIndex(response.request?.analysis_action_requests?.length!-1);
      }
      setSelectedJobId(response?.header?.id!);
    } else if(!selectedJob && response.header){
      showToast("Step saved to Job", "", "success");
      setJobHeaders((prev) => [...prev, response.header!]);
      setSelectedJob({header: {...response.header}});
      setAnalysisActionRequest([...response.request?.analysis_action_requests!]);
      setSelectedJobStepIndex(0);
      setSelectedJobId(response?.header?.id!);
      setJobRunHeaders([]);
      setJobLoaders({headerLoader: false, actionLoader: false});
    } else {
      showToast("Error while saving Steps", "", "error");
    }
  };

  // Handler to Job step
  const handleJobStepClick = (analysisTreeReq: AnalysisActionRequest, index: number) => {

    setSlideName("");
    setSlackChannelId("");
    setStepName("");
    // Indicate that the metric selection is coming from a step
    isMetricSelectedFromStep.current = true;
    // Initialize empty maps to update state
    const updatedContextMap: { [key: string]: string[] } = {};
    const updatedExcludeEdges: string[] = [];
    const updatedCustomBenchmark: {name: string, id: string}[] = [];
    const updatedCustomBenchmarkName: string[] = [];
    const updatedSelectedNodes: string[] = [];
    let updatedSlideName: string = "";
    let updatedChannelId: string = "";

    // Find the metric header that matches the metricId in the analysisTreeReq
    const matchingHeader = metricHeaders.find(
      (header) => header.id === analysisTreeReq.generate_tree_request?.metricId
    );
  
    // If metric header is found, set it
    if (matchingHeader) {
      setSelectedMetricHeader(matchingHeader);
    }
  
    // Iterate over context array and populate the updatedContextMap
    analysisTreeReq?.generate_tree_request?.context?.forEach((ctx) => {
      const columnName = ctx.logical_column_header?.name;
      if (columnName && ctx.values) {
        if(updatedContextMap[columnName]){
          updatedContextMap[columnName] = [...updatedContextMap[columnName], ...ctx.values];
        }
        else{
          updatedContextMap[columnName] = ctx.values; // Use the column name as key and values array as value
        }
      }
    });

    analysisTreeReq?.actions?.forEach((action) => {
      if (action.type === ActionType.GENERATE_COMMENTARY) {
        action.generate_commentary_request?.selected_nodes?.forEach((node) => {
          const { benchmark_id, edge_destination_id } = node;
    
          // Ensure both IDs are present
          if (benchmark_id && edge_destination_id) {
            if (benchmark_id === edge_destination_id) {
              // If both IDs are the same, push only one of them
              updatedSelectedNodes.push(benchmark_id);
            } else {
              // If IDs are different, concatenate them with a pipe and push
              const concatenatedId = `${benchmark_id}|${edge_destination_id}`;
              updatedSelectedNodes.push(concatenatedId);
            }
          }
        });
      }

      if (action.type === ActionType.ASSIGN_TO_PAGE && action.assign_to_page_request?.page_name) {
        // setSlideName(action.assign_to_page_request?.page_name)
        updatedSlideName += action.assign_to_page_request.page_name;
      } 

      if (action.type === ActionType.NOTIFY_SLACK && action.notify_slack_request?.channel_ids) {
        // setSlackChannelId(action.notify_slack_request.channel_ids[0])
        updatedChannelId += action.notify_slack_request.channel_ids[0];
      } 
    });
  
    // Iterate over exclude_edges array and populate updatedExcludeEdges
    analysisTreeReq?.generate_tree_request?.exclude_edges?.forEach((edge) => {
      const edgeName = edge.name;
      if (edgeName) {
        updatedExcludeEdges.push(edgeName); // Add the name of each exclude edge to the array
      }
    });

    // Iterate over benchmark array and populate
    analysisTreeReq?.generate_tree_request?.benchmark?.forEach((bench) => {
      const benchName = bench.header.name;
      const benchId = bench.header.id;
      if (benchName) {
        updatedCustomBenchmark.push({name: benchName, id: benchId!});
        updatedCustomBenchmarkName.push(benchName);
      }
      if(bench.last_n_period_average) setSelectedPurpose("Diagnostic");
      else setSelectedPurpose("Growth");
    });

    // Update the state with the new context map and exclude edges
    setSelectedContextMap(updatedContextMap);
    setSelectedEdgeToExclude(updatedExcludeEdges);
    setCustomBenchmark(updatedCustomBenchmark);
    setCustomBenchmarkName(updatedCustomBenchmarkName);
    setSelectedNodeIds(updatedSelectedNodes);
    setSlackChannelId(updatedChannelId);
    setSlideName(updatedSlideName);
    setSelectedJobStepIndex(index);
    setStepName(analysisTreeReq?.analysis_step_name!);
  };

  // Get jobruns
  const handleGetJobRuns = async (header: Header) => {
    const responseGetJobRuns:Header[] = await getJobruns(header.id!);
    if (responseGetJobRuns && responseGetJobRuns.length > 0) {
      // Sort the job runs by runtime in descending order (latest first)
      const sortedRuns = [...responseGetJobRuns].sort((a, b) => {
        // Since runtime is a string in ISO 8601 format, we can sort lexicographically
        if (a.created_date_ms! > b.created_date_ms!) return -1;
        if (a.created_date_ms! < b.created_date_ms!) return 1;
        return 0;
      });
      
      // Update the state with the sorted job runs
      setJobRunHeaders(sortedRuns);
    } else{
      setJobRunHeaders([]);
    }
  }

  // Validation function
  const validateFields = (): boolean => {
    // Check if date is selected
    const isDateSelected =
      date &&
      date["Date"] &&
      date["Date"][0] &&
      date["Date"][1] &&
      date["Date"][0].trim() !== "" &&
      date["Date"][1].trim() !== "";

    if (!isDateSelected && customBenchmarkName?.length === 0) {
      setErrorMessage("Please select a date and a custom benchmark name.");
      return false;
    } else if (!isDateSelected) {
      setErrorMessage("Please select a date.");
      return false;
    } else if (customBenchmarkName?.length === 0) {
      setErrorMessage("Please select a custom benchmark name.");
      return false;
    }

    // All validations passed
    return true;
  };

  const runJobStep = async(treeRequest: AnalysisTreeRequest) => {
    setRunStepLoader(true);

    const modelId = metricInfoMap[selectedMetricHeader?.id!].header.owner_id!;
      const dataModel = await getModelById(modelId);
      const temporalColumn = dataModel?.columns?.find(
        (column: ColumnInfo) => column.column_type === ColumnType.TEMPORAL
      )?.header!;
      const tempralContext: Filter[] = [
        {
          logical_column_header: temporalColumn,
          operator: "GTE",
          values: [date["Date"][0]],
        },
        {
          logical_column_header: temporalColumn,
          operator: "LTE",
          values: [date["Date"][1]],
        },
      ];
      let processedBenchmarks: Benchmark[] | undefined = [];
      processedBenchmarks = customBenchmark?.map((bench) => {
        if (bench.name === CustomBenchmark.PreviousPeriod) {
          // Create Previous Period benchmark with specific ID and name
          const benchmarks = createBenchmarks(
            date["Date"][0],
            date["Date"][1],
            temporalColumn,
            false, // Do not include SamePeriodLastYear
            undefined, // Skip ID for SamePeriodLastYear as it's not included
            true, // Include PreviousPeriod
            bench.id, // Pass the specific ID for PreviousPeriod
            selectedPurpose ? true : false
          );
          return benchmarks?.previousPeriodBenchmark!;
        } else if (bench.name === CustomBenchmark.SamePeriodLastYear) {
          // Create Same Period Last Year benchmark with specific ID and name
          const benchmarks = createBenchmarks(
            date["Date"][0],
            date["Date"][1],
            temporalColumn,
            true, // Include SamePeriodLastYear
            bench.id, // Pass the specific ID for SamePeriodLastYear
            false, // Do not include PreviousPeriod
            undefined, // Skip ID for PreviousPeriod as it's not included
            selectedPurpose ? true : false
          );
          return benchmarks?.samePeriodLastYearBenchmark!;
        } else {
          // Match benchmark from metricInfo.benchmarkEdges
          const matchedBenchmark = metricInfoMap[
            selectedMetricHeader?.id!
          ]?.edges?.find(
            (edge) =>
              edge.edge_type === MetricEdgeType.BENCHMARK_EDGE &&
              edge.benchmark?.header?.name === bench.name
          )?.benchmark;
          return matchedBenchmark;
        }
      }).filter((bench): bench is Benchmark => bench !== undefined);
      const updatedTreeRequest: AnalysisTreeRequest = {
        ...treeRequest,
        tree_name: getTreeName(treeRequest.tree_name!, globatDateFilter!),
        context:treeRequest.context? [
          ...treeRequest.context,
          ...tempralContext
        ]: [...tempralContext],
        benchmark: processedBenchmarks
      }
      const transactionId = uuidv4();
      const requestBuilder = new StatefulRequestBuilder(transactionId!);
      const statefulRequest = requestBuilder.GenerateTreeRequest(updatedTreeRequest);
      const response: StatefulResponse = await statefulAnalysisTree(statefulRequest);
      if (response) {
        window.open(`/analyze?transaction_id=${transactionId}`, '_blank');
      }
      setRunStepLoader(false);
  }
  
  return (
    <BatchContext.Provider
      value={{
        jobHeaders,
        selectedJob,
        selectedMetricHeader,
        fetchHeaders,
        selectJob,
        createJob,
        metricHeaders,
        handleMetricHeaderSelect,
        selectedAttributesValues,
        setSelectedAttributesValues,
        relatedAttributeSet,
        attributeValueMap,
        handleAttributeSelect,
        selectedAttribute,
        excludeEdges,
        selectedEdgeToExclude,
        setSelectedEdgeToExclude,
        addToContextMap,
        metricInfoMap,
        handleSaveJobStep,
        analysisActionRequest,
        deleteJobStep,
        handleUpdateJob,
        setJobHeaders,
        setSelectedJob,
        setAnalysisActionRequest,
        handleJobStepClick,
        setSelectedJobStepIndex,
        selectedJobStepIndex,
        customBenchmark,
        setCustomBenchmark,
        selectedContextMap,
        setSelectedContextMap,
        jobRunHeaders,
        handleGetJobRuns,
        customBenchmarkName,
        setCustomBenchmarkName,
        setSlackChannelId,
        slackChannelId,
        setSlideName,
        slideName,
        selectedNodeIds,
        setSelectedNodeIds,
        reports,
        setSelectedReport,
        selectedReport,
        setDate,
        date,
        setSelectedPurpose,
        selectedPurpose,
        chooseTargets,
        setChooseTargets,
        validateFields,
        errorMessage,
        statefulTreeRequest,
        runJobStep,
        selectedJobId,
        setSelectedJobId,
        globatDateFilter,
        setGlobalDateFilter,
        frequency,
        setFrequency,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        selectedRunHeader,
        setSelectedRunHeader,
        showStatusPage,
        setShowStatusPage,
        runStepLoader,
        setReports,
        setSelectedAttribute,
        jobLoaders,
        analysisPlanLoader,
        setJobRunHeaders,
        stepName,
        setStepName
      }}
    >
      {children}
    </BatchContext.Provider>
  );
};
