import { useEffect, useState } from 'react'
import {
  AlertDialog,
  Box,
  Flex,
  Icon,
  Stack,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react'
import { BsPinAngleFill } from 'react-icons/bs'
import { ChatGroupHeader } from '../../components/chat/ChatGroupHeader'
import { SearchInput } from '../../components/chat/SearchInput'
import LibraryMainContentContainer from './LibraryMainContentContainer'
import { DeleteIcon } from '@chakra-ui/icons'
import React from 'react'
import { deleteAnalysisTreeByID, fetchAnalysisTreeHeaders } from '../../services/AnalysisTreeServices'
import { useAnalysisContext } from '../../context/AnalysisContext'
import { useURLContext } from '../../context/URLContext'
import { Header } from '../Interfaces'
import { LibraryAnalysis } from '../../components/library/LibraryAnalysis'

/**
 * Represents the library page, where users can view and interact with their starred commentaries.
 * Fetches all starred commentaries, allows users to filter them via searchbar, and export them as PDF.
 * Provides an interface for the user to interact with the LLM subjected to the selected analysis.
 */
const LibraryPageContainer = () => {
  // State variables
  const [analysis, setAnalysis] = useState<Header[]>([])
  const [searchQuery, setSearchQuery] = useState('')
  const [treeId, setTreeId] = useState<string>()
  const [deleteFlag, setDeleteFlag] = useState<boolean>(false)
  const [selectedDeleteAnalysisId, setSelectedDeleteAnalysisId] = useState<string>('')
  const {analysisNameFlag} = useAnalysisContext()
  const {maxResults} = useURLContext()

  const [updateStarredCommentary, setUpdateStarredCommentary] = useState<boolean>(false);
  // Filter the analysis array based on the search query
  const filteredAnalysis = analysis.filter((item) =>
    item?.name!
      .toLowerCase()
      .includes(searchQuery.toLowerCase()),
  )

  /**
   * Fetches all Tree Headers.
   */
  useEffect(() => {
    const getAllStarredCommentaries = async () => {
      setAnalysis([])
      const response = await fetchAnalysisTreeHeaders(maxResults)
      console.log('response ', response);
      response &&
        response.forEach((data: any) => {
          setAnalysis((prevData) => [...prevData, data])
        })
    }
    getAllStarredCommentaries()

    return () => {
      setAnalysis([])
    }
  }, [deleteFlag, analysisNameFlag, updateStarredCommentary])

  /**
   * Handles click event on a starred commentary.
   * Sets the tree ID for rendering the LibraryMainContentContainer.
   *
   * @param {Analysis} analysis - The analysis object representing the clicked commentary.
   */
  const handleStarredCommentaryClick = async (analysis: Header) => {
    setTreeId(analysis.id)
  }

  /*
   * This component renders a text input field that allows the user to edit a value.
   * When the user clicks on the text, it switches to an editable input field.
   * When the user finishes editing (e.g., by clicking outside the input field),
   * the value is saved and the input field switches back to display mode.
   */

  /** ref for delete analysis drawer */
  const cancelDeleteRef = React.useRef<HTMLButtonElement>(null)

  /**  Open the dialog and set the selected chat */
  const openDeleteDialog = (id: string) => {
    setSelectedDeleteAnalysisId(id)
  }

  /** Close the dialog and reset the selected chat */
  const closeDeleteDialog = () => {
    setSelectedDeleteAnalysisId('')
  }

  const handleConversationDelete = async () => {
    console.log('selected delete id ', selectedDeleteAnalysisId)

    const id = selectedDeleteAnalysisId
    setSelectedDeleteAnalysisId('')

    const response = await deleteAnalysisTreeByID(id)
    setDeleteFlag((deleteFlag) => !deleteFlag)
  }

  return (
    <Flex height="100vh" overflow="hidden">
      <Stack
        spacing="4"
        width="420px"
        borderEndWidth="1px"
        pt="6"
        bg="purple.50"
      >
        <Box px="5">
          <SearchInput onChange={(e) => setSearchQuery(e.target.value)} />
        </Box>

        <Stack mt="2" spacing="4" flex="1" overflowY="auto" px="5" pb="5">
          <Stack mt="2" spacing="4">
            <ChatGroupHeader icon={BsPinAngleFill}>
              Starred Analyses ({filteredAnalysis.length})
            </ChatGroupHeader>
            <Stack
              spacing="0"
              mx="-4"
              maxW="100vw"
              h="100%"
              whiteSpace="nowrap"
            >
              {filteredAnalysis && filteredAnalysis.map((analysis, index) => (
                <Flex direction="row" justifyContent="space-between" flex="1">
                  <LibraryAnalysis
                    key={index}
                    analysis={analysis}
                    onMessageClick={() =>
                      handleStarredCommentaryClick(analysis)
                    }
                  />
                  <Icon
                    alignSelf="center"
                    as={DeleteIcon}
                    aria-label=""
                    onClick={() => openDeleteDialog(analysis.id!)}
                    bg="purple.50"
                    color="gray.500"
                    _hover={{ bg: 'purple.100', color: 'black' }}
                    p={1}
                    mr={3}
                    boxSize="22px"
                  />
                </Flex>
              ))}
              {selectedDeleteAnalysisId && (
                <AlertDialog
                  isOpen={Boolean(selectedDeleteAnalysisId)}
                  leastDestructiveRef={cancelDeleteRef}
                  onClose={closeDeleteDialog}
                >
                  <AlertDialogOverlay
                    style={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
                  >
                    <AlertDialogContent>
                      <AlertDialogHeader fontSize="lg" fontWeight="bold">
                        Delete Analysis
                      </AlertDialogHeader>

                      <AlertDialogBody>
                        Are you sure you want to delete this analysis?
                      </AlertDialogBody>

                      <AlertDialogFooter>
                        <Button
                          ref={cancelDeleteRef}
                          onClick={closeDeleteDialog}
                        >
                          Cancel
                        </Button>
                        <Button
                          colorScheme="red"
                          onClick={() => handleConversationDelete()}
                          ml={3}
                        >
                          Delete
                        </Button>
                      </AlertDialogFooter>
                    </AlertDialogContent>
                  </AlertDialogOverlay>
                </AlertDialog>
              )}
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      <Flex direction="column" flex="1" overflowY="auto">
        {/* Conditional rendering of LibraryMainContentContainer */}
        {treeId && <LibraryMainContentContainer id={treeId} setUpdateStarredCommentary={setUpdateStarredCommentary}/>}{' '}
      </Flex>
    </Flex>
  )
}

export default LibraryPageContainer
