import { createContext, useState, useContext, FC, ReactNode, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

/* Structure of URL interface */
interface URLContextInfo {
  debug: boolean;
  maxResults: number;
  transactionId: string;
}

/* Context for managing URL */
const URLContext = createContext<URLContextInfo | undefined>(undefined);

/* Custom hook to access URL from the context */
export const useURLContext = (): URLContextInfo => {
  const context = useContext(URLContext);
  if (!context) {
    throw new Error('useURLContext must be used within a URLContextProvider');
  }
  return context;
};

/* Props for the URLContext provider component */
interface URLContextProps {
  children: ReactNode;
}

/* Provider component for managing URL */
export const URLContextProvider: FC<URLContextProps> = ({ children }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [transactionId, setTransactionId] = useState<string>("");
  const defaultDebug = false;
  const defaultMaxResults = 200;

  // Initialize state from URL parameters or use default values
  const initialDebug = searchParams.get('debug') === 'true' ? true : defaultDebug;
  const initialMaxResults = parseInt(searchParams.get('maxResults') || '') || defaultMaxResults;
  const transaction_id = searchParams.get('transaction_id');
  
  // States to maintain the params in URL for subsequent navigation to other routes
  const [debug, setDebug] = useState(initialDebug);
  const [maxResults, setMaxResults] = useState(initialMaxResults);

  // Effect to update URL parameters when state changes
  useEffect(() => {
    const params = new URLSearchParams();

    if (debug !== defaultDebug) {
      params.set('debug', debug.toString());
    }
    if (maxResults !== defaultMaxResults) {
      params.set('maxResults', maxResults.toString());
    }
    if (transaction_id) {
      setTransactionId(transaction_id);
    }

    setSearchParams(params, { replace: true });
  }, [debug, maxResults, transaction_id, setSearchParams]); // Added initialParam to dependencies

  return (
    <URLContext.Provider value={{ debug, maxResults, transactionId }}>
      {children}
    </URLContext.Provider>
  );
};
