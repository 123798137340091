import React, { useState, useEffect, useCallback } from "react";
import "../../../App.css";
import {
  ModelInfo,
  FormData,
  ColumnInfo,
  FilterColumn,
} from "../../Interfaces";
import { createMetric } from "../../../services/MetricService";
import DefineMetric from "../../../components/configuration/snowflake/DefineMetric";
import showToast from "../../../hooks/useCustomToast";

/**
 * DefineMetricContainer Component
 *
 * Input: modelInfo containing information about the selected data model,
 *        metricInfo callback to pass the updated metric information to the parent component.
 *
 * Manages the definition of a new metric, allowing users to configure observation columns,
 * filters, and related attributes for the metric within a selected data model.
 *
 * @component
 */

const DefineMetricContainer: React.FC<{
  modelInfo: ModelInfo | null;
  metricInfo: (data: FormData) => void;
  onClose: () => void;
}> = ({ modelInfo, metricInfo, onClose }) => {
  // State variables to manage columns, form values, and related logic
  const [columns, setColumns] = useState<ColumnInfo[]>(
    modelInfo?.columns || []
  );

  // Initial form values matching FormData structure
  const initialFormValues: FormData = {
    model_id: modelInfo?.header?.id ? String(modelInfo?.header?.id) : "",
    metrics: [
      {
        header: {
          name: "",
          description: "",
        },
        definition: {
          observation_columns: [
            {
              logical_column_header: {
                id: "",
              },
              aggregation: "SUM",
              sort_by: "ASCENDING",
            },
          ],
          filters: [
            {
              logical_column_header: {
                id: "",
              },
              operator: "EQ",
              values: [""],
            },
          ],
        },        
      },
    ],
    update_edges:true,
  };

  // State to manage form values
  const [formValues, setFormValues] = useState(initialFormValues);

  // Handler for form input changes
  const handleChange = useCallback(
    (e: { target: { name: any; value: any } }) => {
      const { name, value } = e.target;
      setFormValues((prevValues) => ({
        ...prevValues,
        metrics: [
          {
            ...prevValues.metrics[0],
            header: {
              ...prevValues.metrics[0].header,
              [name]: value,
            },
          },
        ],
      }));
    },
    []
  );

  /**
   * Removes a filter column at the specified index from the form values.
   *
   * @param {number} index - The index of the filter column to be removed.
   */
  const removeFilterColumn = (index: number) => {
    setFormValues((prevValues) => {
      const updatedFilterColumns = [
        ...prevValues.metrics[0].definition.filters.slice(0, index),
        ...prevValues.metrics[0].definition.filters.slice(index + 1),
      ];
      return {
        ...prevValues,
        metrics: [
          {
            ...prevValues.metrics[0],
            definition: {
              ...prevValues.metrics[0].definition,
              filters: updatedFilterColumns,
            },
          },
        ],
      };
    });
  };

  // Function to add a filter column
  const addFilterColumn = () => {
    setFormValues((prevValues) => ({
      ...prevValues,
      metrics: [
        {
          ...prevValues.metrics[0],
          definition: {
            ...prevValues.metrics[0].definition,
            filters: [
              ...prevValues.metrics[0].definition.filters,
              {
                logical_column_header: {
                  id: "",
                },
                operator: "EQ",
                values: [""],
              },
            ],
          },
        },
      ],
    }));
  };

  /**
   * Removes an observation column at the specified index from the form values.
   *
   * @param {number} index - The index of the observation column to be removed.
   */
  const removeObservationColumn = (index: number) => {
    setFormValues((prevValues) => {
      const updatedObservationColumns = [
        ...prevValues.metrics[0].definition.observation_columns.slice(
          0,
          index
        ),
        ...prevValues.metrics[0].definition.observation_columns.slice(
          index + 1
        ),
      ];
      return {
        ...prevValues,
        metrics: [
          {
            ...prevValues.metrics[0],
            definition: {
              ...prevValues.metrics[0].definition,
              observation_columns: updatedObservationColumns,
            },
          },
        ],
      };
    });
  };

  // Function to add an observation column
  const addObservationColumn = () => {
    setFormValues((prevValues) => ({
      ...prevValues,
      metrics: [
        {
          ...prevValues.metrics[0],
          definition: {
            ...prevValues.metrics[0].definition,
            observation_columns: [
              ...prevValues.metrics[0].definition.observation_columns,
              {
                logical_column_header: {
                  id: "",
                },
                aggregation: "SUM",
                sort_by: "ASCENDING",
              },
            ],
          },
        },
      ],
    }));
  };

  // Effect to update columns when modelInfo changes
  useEffect(() => {
    if (modelInfo && modelInfo.columns) {
      setColumns(modelInfo.columns);
    }
  }, [modelInfo]);

  // Function to submit the form and create the metric
  const submitForm = async () => {
    const observationColumns =
      formValues.metrics[0].definition.observation_columns.map(
        (observation) => {
          return {
            logical_column_header: {
              id: observation.logical_column_header.id,
            },
            aggregation: observation.aggregation,
            sort_by: observation.sort_by,
          };
        }
      );

    const filters: FilterColumn[] =
      formValues.metrics[0].definition.filters.map((filter) => {
        return {
          logical_column_header: {
            id: filter.logical_column_header.id || "",
          },
          operator: filter.operator || "",
          values: filter.values ? filter.values : [], // Use the values directly if defined, otherwise use an empty array
        };
      });

    //making JSON to request createMetric API
    const formData: FormData = {
      model_id: String(formValues.model_id),
      metrics: [
        {
          header: {
            name: formValues.metrics[0].header.name,
            description: formValues.metrics[0].header.description,
          },
          definition: {
            observation_columns: observationColumns,
            filters: filters,
          },
          // related_attributes: relatedAttributes,
        },
      ],
      update_edges:true,
    };
    
    try {
      // API call to create the metric
      if(!formData.metrics[0].header.name){
        showToast("Metric name cannot be empty", "", "error");
        return;
      }

      const responseData = await createMetric(formData);
      // console.log("Created Metric in DefineMetric:", responseData);

      // Updating the formValues with the created metric information
      // By adding metric header api will detect id if id is present, then update the metric
      if(responseData){
        const updatedMetricInfo: FormData = {
        model_id: String(formValues.model_id),
        metrics: [
          {
            header: {
              ...responseData.metrics[0].header,
            },
            definition: {
              observation_columns: observationColumns,
              filters: filters,
            },
          },
         ],
         update_edges:true,
        };
        // Passing the updated metric information to the parent component
        metricInfo(updatedMetricInfo);
        showToast("Metric created successfully", "", "success");
        onClose();
      }
    } catch (error) {
      showToast("Error creating metric.", "", "error");
      console.error(error);
    }
  };

  // Render the DefineMetric component with relevant props
  return (
    <DefineMetric
      modelInfo={modelInfo}
      formValues={formValues}
      columns={columns}
      setFormValues={setFormValues}
      handleChange={handleChange}
      addObservationColumn={addObservationColumn}
      removeObservationColumn={removeObservationColumn}
      addFilterColumn={addFilterColumn}
      removeFilterColumn={removeFilterColumn}
      submitForm={submitForm}
    />
  );
};

export default DefineMetricContainer;
