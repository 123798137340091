import {
  Box,
  Flex,
  Grid,
  GridItem,
  HStack,
  IconButton,
  Spinner,
  Text,
  Icon,
} from "@chakra-ui/react";
import React from "react";
import { CommentaryContextProvider } from "../../context/CommentaryContext";
import CurrentFilters from "./CurrentFilters";
import TreeName from "../common/TreeName";
import {
  AnalysisTreeResponse,
  NodeInsights,
} from "../../containers/commentary/Interfaces";
import CommentaryPannelContainer from "../../containers/analyse/CommentaryPanelContainer";
import AnalysisTreeViewer from "../../containers/analyse/TreePanelContainer";
import DataDisplayComponent from "./DataDisplayComponent";
import { RiExpandLeftLine, RiExpandRightLine } from "react-icons/ri";
import { epochConverter } from "../../utils/dateUtils";
import { Header } from "../../containers/Interfaces";

interface AnalysisPanelContainerProps {
  tree: AnalysisTreeResponse;
  analysisName: string;
  selectedNode: NodeInsights | null;
  nodeDataLoader: boolean;
  showTable: boolean;
  toggleView: () => void;
  handleAnalysisName: (name: string) => Promise<void>;
  isAdmin: boolean;
  treePannelLoader: boolean;
  setSelectedNodesToShowTables: React.Dispatch<React.SetStateAction<{node: NodeInsights, loading: boolean}[]>>;
  selectedNodesToShowTables: {node: NodeInsights, loading: boolean}[];
  isCommentaryVisible: boolean;
  setIsCommentaryVisible: React.Dispatch<React.SetStateAction<boolean>>;
  nodeInfoMap: Map<string, NodeInsights> | null;
  analysisInfo: Header;
}

const AnalysisPanel: React.FC<AnalysisPanelContainerProps> = ({
  tree,
  analysisName,
  nodeDataLoader,
  handleAnalysisName,
  isAdmin,
  treePannelLoader,
  setSelectedNodesToShowTables,
  selectedNodesToShowTables,
  isCommentaryVisible,
  setIsCommentaryVisible,
  nodeInfoMap,
  analysisInfo
}) => {

  return (
    <CommentaryContextProvider>
      <Grid
        gridTemplateAreas={isCommentaryVisible ? `"tree commentary"` : `"tree"`}
        gridTemplateColumns={isCommentaryVisible ? "3fr 1fr" : "1fr"}
        gridTemplateRows="1fr"
        gap={0}
        height="100%"
        p={0}
      >
        <GridItem
          area={"tree"}
          boxShadow="md"
          bg="bg.surface"
          position="relative"
          borderRadius="lg"
          m={1}
        >
          {/* Header */}
          <HStack
            justifyContent="space-between"
            p={0}
            spacing={2}
            ml={2}
            textAlign="left"
            alignItems="left"
            position="absolute"
            top="0"
            left="0"
            right="1%"
            zIndex="10"
            width="100%"
          >
            <Box>
              <Text fontSize="md" fontWeight={600} color="gray.800" m={3} mb={0}>
                <TreeName chatName={analysisName} handleChatName={handleAnalysisName} />
              </Text>
              <Text
                fontSize="sm" // Smaller font for runtime
                color="gray.500"
                ml={3}
                mb={0}
              >
                {analysisInfo.created_date_ms && `(run at: ${epochConverter(
                  parseInt(analysisInfo?.created_date_ms!)
                ) || "Runtime info"})`}
              </Text>
            </Box>
            <Box>
              <CurrentFilters 
                selectedNode={selectedNodesToShowTables.length > 0 
                  ? selectedNodesToShowTables[0].node.current_node
                  : nodeInfoMap?.get(tree.root_node_id!)?.current_node!
                } />
            </Box>
          </HStack>

          {/* Analysis Tree Viewer */}
          <Box position="absolute" top="0" left="0" right="0" bottom="0" zIndex="1">
            <AnalysisTreeViewer tree={tree} />
          </Box>

          {/* Loader for the primary node */}
          {(nodeDataLoader || treePannelLoader) && (
            <Box
            p={2}
            textAlign="left"
            alignItems="left"
            justifyContent="center"
            position="absolute"
            top="0%" // Position at the bottom of the GridItem
            right="40%" // Position at the right of the GridItem
            zIndex="10" // Higher z-index to ensure it stays on top
            bg="green.100"
            width="15%"
            borderRadius={3}
          >
            <Flex
              alignItems="center"
              justifyContent="center"
              direction="column"  
            >
              <HStack>
              <Spinner size="sm" color="black" thickness="1px" />
              <Text mb={0} fontSize="xs" fontWeight="semibold">
                Processing. Please hold on.
              </Text>
              </HStack>
            </Flex>
          </Box>
          )}

          {/* Render DataDisplayComponents */}
          <Flex
            position="absolute"
            bottom="0"
            left="0"
            width={selectedNodesToShowTables.length === 1 ? "49%" : "99%"}            
            height="48%"
            zIndex={selectedNodesToShowTables.length === 0? "0" : "20"}
            m={2}
            justifyContent="space-between"
          >
            {selectedNodesToShowTables?.map((selected, index) => (
              <Box
                key={selected.node.current_node.id}
                width={selectedNodesToShowTables.length === 1 ? "100%" : "49.5%"}                
                height="100%"
                borderRadius="lg"
                borderColor="gray.500"
                borderWidth="2px"
                p={3}
                bg="bg.surface"
                boxShadow="sm"
                _hover={{ boxShadow: "md", transform: "scale(1.009)" }}
              >
                <DataDisplayComponent
                  index={index}
                  nodeDataLoader={selected.loading}
                  node={selected.node}
                  isAdmin={isAdmin}
                  onClose={() =>
                    setSelectedNodesToShowTables((prev) =>
                      prev.filter((n) => n.node.current_node.id !== selected.node.current_node.id)
                    )
                  }
                  nodeInfoMap={nodeInfoMap}
                />
              </Box>
            ))}
          </Flex>

          {/* Toggle Commentary Button */}
          <IconButton
            aria-label={isCommentaryVisible ? "Hide commentary" : "Show commentary"}
            icon={
              isCommentaryVisible ? (
                <Icon as={RiExpandRightLine} boxSize={7} color="gray.600" />
              ) : (
                <Icon as={RiExpandLeftLine} boxSize={7} color="gray.600" />
              )
            }
            onClick={() => setIsCommentaryVisible(!isCommentaryVisible)}
            position="absolute"
            top="45%"
            right="0.5%"
            zIndex="100"
            color="black"
            colorScheme="purple"
            variant="outline"
          />
        </GridItem>

        {/* Commentary Panel */}
        {isCommentaryVisible && (
          <GridItem
            zIndex={27}
            area={"commentary"}
            boxShadow="md"
            position="relative"
            borderRadius="lg"
            overflow="none"
            rowSpan={2}
            m={1}
          >
            <CommentaryPannelContainer treeId={tree.header.id!} />
          </GridItem>
        )}
      </Grid>
    </CommentaryContextProvider>
  );
};

export default AnalysisPanel;
