import React, { useEffect, useState } from "react";
import { Benchmark, BenchmarkFilter, TemporalColumn } from "../Interfaces";
import { LocalDate, DateTimeFormatter } from "@js-joda/core";
import { CustomPeriod, TimeBucket } from "../../../utils/enum";
import BenchmarkAccordion from "../../../components/commentary/sidebar/BenchmarkAccordion";

import { useSidebarContext } from "../../../context/CommentarySidebarContext";
/**
 * BenchmarkAccordionContainer Component
 *
 * Input:
 * - temporalColumns: Array of TemporalColumn objects representing temporal data columns.
 * - allBenchmarks: Array of Benchmark objects representing all benchmarks.
 * - customBenchmark: Callback function to update custom benchmarks.
 *
 * Return:
 * Renders the BenchmarkAccordion component with relevant props.
 *
 * This component manages the selection, addition, and removal of custom benchmarks.
 * It calculates date ranges based on selected temporal columns and periods.
 * The main functionality includes handling the addition and removal of periods to selected benchmarks,
 * calculating dates, and updating custom benchmarks accordingly.
 *
 * @component
 */
const BenchmarkAccordionContainer: React.FC<{
  temporalColumns: TemporalColumn[];
}> = ({ temporalColumns }) => {
  const {
    metricInfoMap,
    temporalColumnByMetricIdMap,
    updateBenchmarkMap,
    setDate,
    date,
  } = useSidebarContext();
  const [selectedPeriod, setSelectedPeriod] = useState<{
    name: string;
    value: string;
  }>();
  const [selectedTemporalColumn, setSelectedTemporalColumn] =
    useState<TemporalColumn | null>(null);
  const [selectedBenchmarks, setSelectedBenchmarks] = useState<
    TemporalColumn[]
  >([]);
  // const [date, setDate] = useState<{ [key: string]: string[] }>({});
  const [presetBenchmark, setPresetBenchmark] = useState<Benchmark[]>([]);

  /**
   * Function to handle addition of a period to selected benchmarks.
   */
  const handleAddPeriod = () => {
    if (selectedTemporalColumn && selectedPeriod) {
      const updatedTemporalColumn = {
        ...selectedTemporalColumn,
        custom_period: selectedPeriod,
      };

      setSelectedBenchmarks((prevBenchmarks) => [
        ...prevBenchmarks,
        updatedTemporalColumn,
      ]);
      // Clear selections
      handleDateCalculations(updatedTemporalColumn);
      setSelectedTemporalColumn(null);
      setSelectedPeriod({
        name: "",
        value: "",
      });
    }
  };

  /**
   * Function to handle removal of a benchmark from selected benchmarks.
   *
   * @param {number} index - Index of the benchmark to be removed.
   */
  const handleRemoveBenchmark = (index: number) => {
    const removedBenchmark = selectedBenchmarks[index];
    const updatedBenchmarks = [...selectedBenchmarks];
    updatedBenchmarks.splice(index, 1);
    setSelectedBenchmarks(updatedBenchmarks);

    // Remove the corresponding entry from the date map
    if (removedBenchmark?.name) {
      const updatedDate = { ...date };
      delete updatedDate[removedBenchmark.name!];
      setDate(updatedDate);
    }
  };

  /**
   * Function to calculate dates based on selected temporal column and period.
   *
   * @param {TemporalColumn} selectedTemporalColumn - Selected temporal column.
   */
  const handleDateCalculations = (selectedTemporalColumn: TemporalColumn) => {
    // Convert selectedMonth and selectedYear to numeric values
    let monthValue = parseInt(selectedTemporalColumn?.month?.value!);
    let yearValue = parseInt(selectedTemporalColumn?.year!, 10);

    // Define the desired date format
    const dateFormatter = DateTimeFormatter.ofPattern("MM/dd/yyyy");

    // Initialize variables for start and end dates
    let startDate: string = "";
    let endDate: string = "";

    // Check the selected time operator
    if (selectedTemporalColumn?.time_bucket === TimeBucket.Monthly) {
      // Check if custom_period is "pp" and adjust the values accordingly
      if (
        selectedTemporalColumn?.custom_period?.value ===
        CustomPeriod.PreviousPeriod
      ) {
        monthValue -= 1;
        if (monthValue === 0) {
          // If the month becomes 0, set it to December of the previous year
          monthValue = 12;
          yearValue -= 1;
        }
      } else if (
        selectedTemporalColumn?.custom_period?.value ===
        CustomPeriod.SamePeriodLastYear
      ) {
        yearValue -= 1;
      }

      startDate = LocalDate.of(yearValue, monthValue, 1).format(dateFormatter);
      endDate = LocalDate.of(yearValue, monthValue, 1)
        .plusMonths(1)
        .minusDays(1)
        .format(dateFormatter);
    } else if (selectedTemporalColumn?.time_bucket === TimeBucket.Quarterly) {
      // Check if custom_period is "pp" and adjust the values accordingly
      let quarter = parseInt(selectedTemporalColumn!.quarter!.value);
      if (
        selectedTemporalColumn?.custom_period?.value ===
        CustomPeriod.PreviousPeriod
      ) {
        // Decrease the quarter value by 1
        quarter -= 1;
        if (quarter === 0) {
          // If the quarter becomes 0, set it to 4 of the previous year
          quarter = 4;
          yearValue -= 1;
        }
      } else if (
        selectedTemporalColumn?.custom_period?.value ===
        CustomPeriod.SamePeriodLastYear
      ) {
        yearValue -= 1;
      }

      const quarterStartMonth = (quarter - 1) * 3 + 1;
      startDate = LocalDate.of(yearValue, quarterStartMonth, 1).format(
        dateFormatter
      );
      endDate = LocalDate.of(yearValue, quarterStartMonth, 1)
        .plusMonths(3)
        .minusDays(1)
        .format(dateFormatter);
    } else if (selectedTemporalColumn?.time_bucket === TimeBucket.Yearly) {
      // Check if custom_period is "pp" and adjust the values accordingly
      if (
        selectedTemporalColumn?.custom_period?.value ===
        CustomPeriod.PreviousPeriod
      ) {
        yearValue -= 1;
      } else if (
        selectedTemporalColumn?.custom_period?.value ===
        CustomPeriod.SamePeriodLastYear
      ) {
        yearValue -= 1;
      }

      startDate = LocalDate.of(yearValue, 1, 1).format(dateFormatter);
      endDate = LocalDate.of(yearValue, 1, 1)
        .plusYears(1)
        .minusDays(1)
        .format(dateFormatter);
    }

    // Set the calculated dates in the date state with logical_column_header.id as the key
    setDate((prevDates: any) => ({
      ...prevDates,
      [selectedTemporalColumn?.name || ""]: [
        startDate.toString(),
        endDate.toString(),
      ],
    }));
  };

  //whenever temporal columns changes then make selected benchmark empty
  useEffect(() => {
    setSelectedBenchmarks([]);
  }, [temporalColumns]);

  // useEffect to update custom benchmarks when selected benchmarks or dates change:
  useEffect(() => {
    if(selectedBenchmarks.length>0){
      updateBenchmarkMap(selectedBenchmarks);
    }
  }, [selectedBenchmarks, temporalColumnByMetricIdMap]);

  useEffect(() => {
    // Reset the selectedBenchmarks state to null whenever allBenchmarks changes
    setSelectedBenchmarks([]);
    const extractedBenchmark = Object.keys(metricInfoMap).map((key) => {
      const edgesArray = metricInfoMap[key]?.edges;

      // Check if edgesArray is defined and not null
      // Iterate over the edgesArray and extract benchmark property
      const benchmarksFromEdges = edgesArray?.map((edge: any) => edge?.benchmark);

      // Check if benchmarksFromEdges is defined and not null
      if (benchmarksFromEdges) {
        return benchmarksFromEdges;
      }

      return [];
    });

    // Flatten the extractedBenchmark array to get a single array of benchmarks
    const flattenedBenchmark = extractedBenchmark
      .flat()
      .filter((b) => b !== undefined) as Benchmark[];
    setPresetBenchmark(flattenedBenchmark);
  }, [metricInfoMap]);

  return (
    <BenchmarkAccordion
      selectedPeriod={selectedPeriod}
      setSelectedPeriod={setSelectedPeriod}
      selectedTemporalColumn={selectedTemporalColumn}
      setSelectedTemporalColumn={setSelectedTemporalColumn}
      selectedBenchmarks={selectedBenchmarks}
      setSelectedBenchmarks={setSelectedBenchmarks}
      date={date}
      setDate={setDate}
      presetBenchmark={presetBenchmark}
      temporalColumns={temporalColumns}
      handleAddPeriod={handleAddPeriod}
      handleRemoveBenchmark={handleRemoveBenchmark}
    />
  );
};

export default BenchmarkAccordionContainer;
