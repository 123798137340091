import React, { useEffect, useState } from 'react'
import { AnalysisTreeResponse } from '../../containers/commentary/Interfaces'
import { useDisclosure } from '@chakra-ui/react'
import { starCommentary } from '../../services/CommentaryServices'
import CommentaryCardHeader from '../../components/commentary/CommentaryCardHeader'
import { fetchDownloadPrompt } from '../../services/ConversationServices'
import { useUser } from '../../context/UserContext'
import { useURLContext } from '../../context/URLContext'
import { updateAnalysisName } from '../../services/AnalysisTreeServices'
import { useAnalysisContext } from '../../context/AnalysisContext'
interface CardHeaderProps {
  libraryPage: boolean;
  libraryStarIndex: number;
  starredConversationId: string;
  setUpdateStarredCommentary: React.Dispatch<React.SetStateAction<boolean>>;
  tree: AnalysisTreeResponse | undefined;
  activeIndex: number;
  setActiveIndex: React.Dispatch<React.SetStateAction<number>>;
  handleCopy: () => void;
  handleDiffChecker: () => void;
  copy: boolean;
  isDiff: boolean;
  conversationId: string;
  hideCommentaryCardHeaderButton:boolean;
}

/**
 * CommentaryCardHeaderContainer Component
 *
 * Input:
 * - tree: AnalysisTreeResponse object representing the analysis tree.
 * - activeIndex: Number representing the active index.
 * - conversationId: String representing the conversation ID.
 * - handleCopy: Callback function to handle copying.
 * - handleDiffChecker: Callback function to handle diff checking.
 * - copy: Boolean indicating whether copying is enabled.
 * - isDiff: Boolean indicating whether diff checking is enabled.
 *
 * Return:
 * Renders the CommentaryCardHeader component with relevant props.
 *
 * This component manages the header section of the commentary card.
 * It includes functionality for starring commentaries, copying content, and checking for differences.
 * The main functionality includes handling star actions, converting epoch timestamps, and rendering the header.
 *
 * @component
 */
const CommentaryCardHeaderContainer: React.FC<CardHeaderProps> = ({
  libraryPage,
  libraryStarIndex,
  starredConversationId,
  setUpdateStarredCommentary,
  tree,
  activeIndex,
  setActiveIndex,
  conversationId,
  handleCopy,
  handleDiffChecker,
  copy,
  isDiff,
  hideCommentaryCardHeaderButton
}) => {

  // State variables
  const { isAdmin } = useUser()
  const { debug } = useURLContext()
  const [prevConversationStarId, setPrevConversationStarId] = useState<string>(
    starredConversationId,
  )
  const [starredIndex, setStarredIndex] = useState<number>(-1)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const name = !libraryPage ? tree?.metric.header.name! : tree?.header.name!
  const [analysisName, setAnalysisName] = useState<string>(name) 
  const [prevAnalysisName, setPrevAnalysisName] = useState<string>('');
  const {setAnalysisNameFlag} = useAnalysisContext()

  useEffect(() => {
    if (libraryPage) {
      setStarredIndex(libraryStarIndex)
      setPrevConversationStarId(starredConversationId);
    }
  }, [libraryPage, libraryStarIndex, starredConversationId])

  useEffect(()=>{
    if(!libraryPage)
    handleAnalysisName(name);
  },[])

  /**
   * Handles starring or unstarring a commentary.
   * @param {Map<string, boolean>} conversation - Map of conversation IDs and star status.
   */
  const handleStarCommentary = async (conversation: Map<string, boolean>) => {
    const conversationMap = Object.fromEntries(conversation)
    console.log('req: ', conversationMap)

    const starredResponse = await starCommentary(
      conversationMap,
      tree?.header.id!,
    )
    console.log('starredResponse: ', starredResponse)
  }

  /**
   * Handles toggling the star index.
   */
  const handleStarIndex = async () => {
    if (libraryPage) {
      if (starredIndex === -1) {
        const conversationMap = new Map<string, boolean>()
        conversationMap.set(conversationId, true)

        handleStarCommentary(conversationMap);
        setUpdateStarredCommentary(true);
        setPrevConversationStarId(conversationId);
        setStarredIndex(activeIndex);
      } else if (starredIndex === activeIndex) {
        const conversationMap = new Map<string, boolean>();
        conversationMap.set(starredConversationId, false);
        setUpdateStarredCommentary(true);
        handleStarCommentary(conversationMap);
        setPrevConversationStarId("");
        setStarredIndex(-1);
      } else {
        const conversationMap = new Map<string, boolean>();
        conversationMap.set(conversationId, true);
        conversationMap.set(prevConversationStarId, false);
        setUpdateStarredCommentary(true);
        handleStarCommentary(conversationMap);
        setPrevConversationStarId(conversationId);
        setStarredIndex(activeIndex);
        setActiveIndex(activeIndex);
      }
    } else {
      if (starredIndex === -1) {
        const conversationMap = new Map<string, boolean>()
        conversationMap.set(conversationId, true)

        handleStarCommentary(conversationMap)

        setPrevConversationStarId(conversationId)
        setStarredIndex(activeIndex)
      } else if (starredIndex === activeIndex) {
        const conversationMap = new Map<string, boolean>()
        conversationMap.set(prevConversationStarId, false)

        handleStarCommentary(conversationMap)
        setPrevConversationStarId('')
        setStarredIndex(-1)
      } else {
        const conversationMap = new Map<string, boolean>()
        conversationMap.set(conversationId, true)
        conversationMap.set(prevConversationStarId, false)

        handleStarCommentary(conversationMap)
        setPrevConversationStarId(conversationId)
        setStarredIndex(activeIndex)
      }
    }
  }
  
  const handleDownloadPrompt = async () => {
    const response = await fetchDownloadPrompt((conversationId === null || conversationId === "") ? starredConversationId : conversationId);
  }

  /**
   * method to handle analysis name
   */
  const handleAnalysisName = async(newAnalysisName: string) => {
    setAnalysisName(newAnalysisName);
    if(prevAnalysisName === newAnalysisName) return ;
    setPrevAnalysisName(newAnalysisName);
    await updateAnalysisName(tree?.header.id!, newAnalysisName);
    setAnalysisNameFlag(flag => !flag)
  }

  return (
    <CommentaryCardHeader
      tree={tree}
      activeIndex={activeIndex}
      conversationId={conversationId}
      handleCopy={handleCopy}
      handleDownloadPrompt={handleDownloadPrompt}
      handleDiffChecker={handleDiffChecker}
      copy={copy}
      isDiff={isDiff}
      starredIndex={starredIndex}
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      handleStarIndex={handleStarIndex}
      isAdmin={isAdmin && debug}
      analysisName={analysisName}
      handleAnalysisName={handleAnalysisName}
      hideCommentaryCardHeaderButton={hideCommentaryCardHeaderButton}
    />
  )
}

export default CommentaryCardHeaderContainer
