import React from 'react';
import {
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  SimpleGrid,
  Button,
  Input,
  Tooltip,
} from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import { Header } from '../../../containers/Interfaces';

interface MetricAccordionProps {
  searchInput: string;
  setSearchInput: (input: string) => void;
  filteredMetrics: Header[];
  selectedMetrics: Header[];
  handleMetricSelection: (metric: Header) => void;
}

const MetricAccordion: React.FC<MetricAccordionProps> = ({
  searchInput,
  setSearchInput,
  filteredMetrics,
  selectedMetrics,
  handleMetricSelection,
}) => {
  return (
    <AccordionItem>
      <h2>
      <Tooltip hasArrow label="Expand" placement='right' color="white" bg="#4e1e6c" fontFamily="Montserrat" arrowSize={9}>
        <AccordionButton alignItems="center" pt={3}>
          <Box flex="1" textAlign="left" fontSize="lg" fontWeight="700">
            Select Metrics
          </Box>
          
          <AccordionIcon
            fontSize="2xl"
            p={1}
            borderRadius="full"
            bg="#4e1e6c"
            color="purple.50"
          />
          
        </AccordionButton>
        </Tooltip>
      </h2>
      <AccordionPanel pb={4}>
        <Input
        focusBorderColor='#4e1e6c'
          placeholder="Search metrics..."
          mb={4}
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
        />
        <Box maxH="300px" overflowY="auto">
          <SimpleGrid columns={2} spacing={2}>
            {filteredMetrics.map((metric) => {
            return (
              
              <Button
                key={metric.id}
                colorScheme={selectedMetrics.includes(metric) ? 'green' : 'black'}
                bg={selectedMetrics.includes(metric) ? "#4e1e6c" : "purple.100"}
                variant={selectedMetrics.includes(metric) ? 'solid' : 'outline'}
                onClick={() => handleMetricSelection(metric)} 
                _hover={{ bg: "purple.200", color: "black" }}
                border="none"
                fontSize="sm"
                rightIcon={selectedMetrics.includes(metric) ? <CheckIcon /> : undefined}
                style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}
              >
                {metric.name}
              </Button>
            )})}
          </SimpleGrid>
        </Box>
      </AccordionPanel>
    </AccordionItem>
  );
};

export default MetricAccordion;
