import React from "react";
import {
  Box,
  Divider,
} from "@chakra-ui/react";
import {
  AnalysisTreeResponse,
  Commentary,
} from "../../containers/commentary/Interfaces";
import CommentaryCardHeaderContainer from './../../containers/commentary/CommentaryCardHeaderContainer';
import CommentaryBox from "./CommentaryBox";

interface CommentaryCardProps {
  libraryPage: boolean;
  libraryStarIndex:number;
  starredConversationId:string;
  setUpdateStarredCommentary: React.Dispatch<React.SetStateAction<boolean>>;
  tree: AnalysisTreeResponse;
  id: string;
  commentaryArray: Commentary[];
  setCommentaryArray: React.Dispatch<React.SetStateAction<Commentary[]>>;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  activeIndex: number;
  setActiveIndex: React.Dispatch<React.SetStateAction<number>>;
  conversationId: string;
  setConversationId: React.Dispatch<React.SetStateAction<string>>;
  copy: boolean;
  setCopy: React.Dispatch<React.SetStateAction<boolean>>;
  setCopiedText: React.Dispatch<React.SetStateAction<string>>;
  isDiff: boolean;
  setIsDiff: React.Dispatch<React.SetStateAction<boolean>>;
  handleCopy: () => void;
  handleDiffChecker: () => void;
  markdownRef: React.RefObject<HTMLDivElement>;
  hideCommentaryCardHeaderButton:boolean;
  isFailed: boolean
  fetchCommentary: () => void
}

const CommentaryCard: React.FC<CommentaryCardProps> = ({
  libraryPage,
  libraryStarIndex,
  starredConversationId,
  setUpdateStarredCommentary,
  tree,
  id,
  commentaryArray,
  setCommentaryArray,
  isLoading,
  setIsLoading,
  activeIndex,
  setActiveIndex,
  conversationId,
  setConversationId,
  copy,
  setCopy,
  setCopiedText,
  isDiff,
  setIsDiff,
  handleCopy,
  handleDiffChecker,
  markdownRef,
  hideCommentaryCardHeaderButton,
  isFailed,
  fetchCommentary
}) => {
  return (
  <Box m={2} mt={3} p={4} boxShadow="md" bg="bg.surface" borderRadius="xl">      
      <CommentaryCardHeaderContainer
        libraryPage={libraryPage}
        libraryStarIndex={libraryStarIndex}
        starredConversationId={starredConversationId}
        setUpdateStarredCommentary={setUpdateStarredCommentary}
        tree={tree}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
        conversationId={conversationId}
        handleCopy={handleCopy}
        handleDiffChecker={handleDiffChecker}
        copy={copy}
        isDiff={isDiff}
        hideCommentaryCardHeaderButton={hideCommentaryCardHeaderButton}
      ></CommentaryCardHeaderContainer>

        <Divider borderColor="gray.700" my={1} mt={0} />
      
        <CommentaryBox
          id={id}
          libraryPage={libraryPage}
          commentaryArray={commentaryArray}
          setCommentaryArray={setCommentaryArray}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
          conversationId={conversationId}
          setConversationId={setConversationId}
          copy={copy}
          setCopy={setCopy}
          setCopiedText={setCopiedText}
          isDiff={isDiff}
          setIsDiff={setIsDiff}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          markdownRef={markdownRef}
          isFailed={isFailed}
          fetchCommentary={fetchCommentary}
        />
      
    </Box>
  );
};

export default CommentaryCard;
