import React from "react";
import { Select } from "@chakra-ui/react";
import { MONTHS, QUARTERS, YEARS } from "../../constants/date";

interface MonthSelectProps {
  selectedMonth: { value: string; name: string };
  setSelectedMonth: (month: { value: string; name: string }) => void;
}

export const MonthSelect: React.FC<MonthSelectProps> = ({
  selectedMonth,
  setSelectedMonth,
}) => (
  <Select
    fontSize="sm"
    placeholder="Month"
    onChange={(e) =>
      setSelectedMonth({
        value: e.target.value,
        name: e.target.options[e.target.selectedIndex].text,
      })
    }
    value={selectedMonth.value}
  >
    {MONTHS.map((month) => (
      <option key={month.value} value={month.value}>
        {month.label}
      </option>
    ))}
  </Select>
);

interface QuarterSelectProps {
  selectedQuarter: { value: string; name: string };
  setSelectedQuarter: (quarter: { value: string; name: string }) => void;
}

export const QuarterSelect: React.FC<QuarterSelectProps> = ({
  selectedQuarter,
  setSelectedQuarter,
}) => (
  <Select
    fontSize="sm"
    placeholder="Quarter"
    width="100%"
    onChange={(e) =>
      setSelectedQuarter({
        value: e.target.value,
        name: e.target.options[e.target.selectedIndex].text,
      })
    }
    value={selectedQuarter.value}
  >
    {QUARTERS.map((quarter) => (
      <option key={quarter.value} value={quarter.value}>
        {quarter.label}
      </option>
    ))}
  </Select>
);

interface YearSelectProps {
  selectedYear: string;
  setSelectedYear: (year: string) => void;
}

export const YearSelect: React.FC<YearSelectProps> = ({
  selectedYear,
  setSelectedYear,
}) => (
  <Select
    fontSize="sm"
    placeholder="Year"
    onChange={(e) => setSelectedYear(e.target.value)}
    value={selectedYear}
  >
    {YEARS.map((year) => (
      <option key={year} value={year}>
        {year}
      </option>
    ))}
  </Select>
);
