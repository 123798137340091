import { MutableRefObject, useEffect, useRef } from 'react'
import React, { useState } from 'react'
import { Analysis, ChatInterface } from './interface'
import {
  conversationAddMessage,
  conversationStart,
  updateConversationName,
} from '../../services/ChatServices'
import { systemPrompt, userPrompt } from '../../constants/Prompt'
import { MessageData } from './interface'
import { ChatWindow } from '../../components/chat/ChatWindow'

interface ChatWindowContainerProps {
  commentaries: string[]
  setCommentaries: React.Dispatch<React.SetStateAction<string[]>>
  activeChat: ChatInterface | undefined
  chatConversationId: string
  historyMessages: MessageData[]
  setHistoryMessages: React.Dispatch<React.SetStateAction<MessageData[]>>
  setNewChatToggler: React.Dispatch<React.SetStateAction<boolean>>
  chatName: string
  setChatConversationID: React.Dispatch<React.SetStateAction<string>>
}

/**
 * Component to render chat messages of user and assistant.
 */
const ChatWindowContainer: React.FC<ChatWindowContainerProps> = ({
  commentaries,
  setCommentaries,
  activeChat,
  chatConversationId,
  historyMessages,
  setHistoryMessages,
  setNewChatToggler,
  chatName,
  setChatConversationID,
}) => {
  const [messages, setMessages] = useState<MessageData[]>([])
  const [commentaryPrompt, setCommentaryPrompt] = useState<string[]>([])
  const [inputValue, setInputValue] = useState<string>('')
  const [start, setStart] = useState<boolean>(false)
  const [conversationId, setConversationId] = useState<string>('')
  const [loader, setLoader] = useState<boolean>(false)

  /**
   * markdown ref for copying the chat message in markdown
   */
  const markdownRef = useRef<HTMLDivElement>(null) // Step 1: Create a ref

  /**
   * it extracts the commentaries from activeAnalysis
   */

  useEffect(() => {
    let commentariesArray: string[] = []
    commentaries && commentaries.forEach((commentary) => {
      commentariesArray.push(commentary);
    })

    setCommentaryPrompt(commentariesArray)

    return () => {
      setCommentaryPrompt([])
    }
  }, [commentaries])

  /**
   * for handling the selected chat messages
   */
  useEffect(() => {
    if (chatConversationId.length && historyMessages.length) {
      // console.log("historyMessages ", historyMessages)

      const modifiedHistoryMessages = historyMessages.map((data) => {
        // console.log("data ", data);
        if (data.fromUser) {
          const userMessageRegex = /USER MESSAGE:[\s\S]*?\n([\s\S]*?)\n\s*YOUR RESPONSE:/
          const match = data.message.match(userMessageRegex)
          const extractedUserMessage = match ? match[1].trim() : null

          return {
            ...data,
            message: extractedUserMessage!,
          }
        } else {
          return data
        }
      })

      // console.log('historyMessages ', historyMessages)
      // console.log('modified history ', modifiedHistoryMessages)
      setMessages(modifiedHistoryMessages)
      setConversationId(chatConversationId)
      setStart(true)
    } else {
      setMessages([])
      setConversationId('')
      setStart(false)
    }
  }, [activeChat, chatConversationId])

  /**
   * Function to generate LLM responses from the selected analysis and user input value.
   */
  const handleSendMessage = async (e: React.FormEvent) => {
    e.preventDefault()
    if (!inputValue.trim()) return

    // console.log("inputValue ", inputValue);
    const messageList: MessageData[] = []
    messageList.push({
      name: 'You',
      message: `USER MESSAGE:\n${inputValue}\nYOUR RESPONSE:`,
      fromUser: true,
    })

    setMessages((messages) => [
      ...messages,
      { name: 'You', message: inputValue, fromUser: true },
    ])

    const allCommentaries = commentaryPrompt.join('\n')
    setCommentaries([])

    const systemMessage = await systemPrompt()
    const userMessage = await userPrompt(allCommentaries, inputValue)

    setInputValue('')

    setLoader(true)

    if (!start) {
      const data = await conversationStart(systemMessage, userMessage)
      // console.log("start ", data);
      if (data && data.header && data.messages) {
        setConversationId(data.header.id)

        const responseMessage = data.messages.reverse().find((item: any) => {
          return item.role === 'ASSISTANT'
        })

        setLoader(false)

        messageList.push({
          name: 'Assistant',
          message: responseMessage.content,
          fromUser: false,
        })
        // console.log('messageList ', messageList)
        setMessages((messages) => [
          ...messages,
          {
            name: 'Assistant',
            message: responseMessage.content,
            fromUser: false,
          },
        ])

        setHistoryMessages(messageList)

        await updateConversationName(data.header.id, chatName)
        // console.log("message ", responseMessage);
        setChatConversationID(data.header.id)
        setNewChatToggler((prevToggleState) => !prevToggleState)
        setStart(true)
      }
    } else {
      const data = await conversationAddMessage(conversationId, userMessage)

      if (data && data.messages) {
        // console.log("add message ", data);
        const responseMessage = data.messages.reverse().find((item: any) => {
          return item.role === 'ASSISTANT'
        })

        setLoader(false)
        setMessages((messages) => [
          ...messages,
          {
            name: 'Assistant',
            message: responseMessage.content,
            fromUser: false,
          },
        ])
      }
    }
  }

  /**
   * Reference to handle scroller when response from LLM gets in
   */
  const vStackRef: MutableRefObject<HTMLDivElement | null> = useRef<HTMLDivElement | null>(
    null,
  )

  useEffect(() => {
    // Scroll to the bottom when messages change
    vStackRef.current?.scrollTo(0, vStackRef.current?.scrollHeight)
  }, [messages, loader])

  /*
   * function to send message on enter
   */
  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
      handleSendMessage(e)
    }
  }

  return (
    <ChatWindow
      messages={messages}
      markdownRef={markdownRef}
      handleSendMessage={handleSendMessage}
      inputValue={inputValue}
      setInputValue={setInputValue}
      vStackRef={vStackRef}
      handleKeyDown={handleKeyDown}
      loader={loader}
    />
  )
}

export default ChatWindowContainer
