import React, { useState, FormEvent } from "react";
import { useUser } from "../../context/UserContext";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Image,
  Flex,
  VStack,
  Container,
  Stack,
  keyframes,
  Circle,
  Avatar,
  Icon
} from "@chakra-ui/react";
import { PasswordField } from "../../components/auth/passwordField";
import logo from "../../assets/Anko.png";
import theme from "../../components/common/Theme";
import { PiSignIn } from "react-icons/pi";

/*props for the UserLogin component*/
interface UserLoginProps {}
const rotateAnimation = keyframes`
  0% { transform: rotate(0deg); }
  90% { transform: rotate(0deg); }
  100% { transform: rotate(120deg); }
`;

const UserLogin: React.FC<UserLoginProps> = () => {
  const animation = `${rotateAnimation} 3s linear infinite`;
  /* Accessing login function from the user context*/
  const { login } = useUser();

  /* State variables to manage username and password*/
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  /*Function to handle form submission*/
  const handleSubmit = (event: any) => {
    event.preventDefault();
    /* Calling login function from context with username and password*/
    login(username, password);
  };

  return (
    <Flex
      minHeight="100vh"
      width="full"
      align="center"
      justifyContent="center"
      bg="purple.50"
    >
      <Container maxW="xl">
        <VStack spacing={7} width="100%">
        <Circle color="fg.default" p={0} m={7}>
              <Avatar
                size="lg"
                src={logo}
                animation={animation}
                name="Anko"
                color="black"
              />
        </Circle>
        <VStack
          width="100%"
          bg="bg.surface"
          spacing={3}
          p={9}
          m={3}
          boxShadow="lg"
          borderRadius="xl"
          gap={0}
        >
            <Stack spacing="9" bg="bg.surface" width="100%">
              <FormControl colorScheme="purple">
                <FormLabel htmlFor="email" fontSize="md" color="fg.muted">Email</FormLabel>
                <Input
                  id="email"
                  type="email"
                  size="lg"
                  colorScheme="purple"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </FormControl>
              <PasswordField
                size="lg"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                colorScheme="purple"
              />
              <Button
                bg="purple.100"
                color="black"
                colorScheme="purple"
                size="lg"
                mt={3}
                width="full"
                _hover={{ bg: "purple.700", color: "white" }}
                onClick={handleSubmit}
              >
              <Icon as={PiSignIn} mr={2}/>
                Login
              </Button>
            </Stack>
        </VStack>
        </VStack>
      </Container>
    </Flex>
  );
};

export default UserLogin;