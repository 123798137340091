import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { Commentary, SelectedNodes } from "../containers/commentary/Interfaces";
import { useAnalysisTreeContext } from "./AnalysisTreeContext";
import { CommentaryConfig } from "../containers/analyse/Interfaces";
import { StatefulResponse } from "../containers/stateful/Stateful";
import { AnalysisTreeRequestType } from "../utils/enum";
import { StatefulRequestBuilder } from "../containers/stateful/StatefulRequests";
import { statefulAnalysisTree } from "../services/AnalysisTreeServices";

interface ContextType {
  commentaryArray: CommentaryConfig[];
  setCommentaryArray: React.Dispatch<React.SetStateAction<CommentaryConfig[]>>;
  fetchCommentary: () => Promise<void>;
  loadingCommentary: boolean;
  updateCommentaryAtIndex: (
    index: number,
    newCommentary: Commentary,
    isSaved: boolean
  ) => void;
  handleSelectCommentary: (commentary: Commentary, index: number)=> void;
  selectedCommentaryIndex: number;
  transactionId: string;
}

const ApiContext = createContext<ContextType | undefined>(undefined);

export const useCommentaryContext = () => {
  const context = useContext(ApiContext);
  if (!context) {
    throw new Error("useApiContext must be used within an ApiProvider");
  }
  return context;
};

export const CommentaryContextProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  
  const { 
    tree, 
    setSelectedCommentary, 
    generateCommentaryConfigJSON, 
    transactionId,
    setCommentaryNodes
   } = useAnalysisTreeContext();

  const [commentaryArray, setCommentaryArray] = useState<CommentaryConfig[]>([]);
  const [loadingCommentary, setLoadingCommentary] = useState<boolean>(false);
  const [selectedCommentaryIndex, setSelectedCommentaryIndex] = useState<number>(-1);

  //Generate commentary
  const fetchCommentary = async () => {

    const commentaryConfig: SelectedNodes | null = generateCommentaryConfigJSON();
    const currentIndex = commentaryArray.length;
    try {
      setLoadingCommentary(true);
      setCommentaryArray((prev) => [
        ...prev,
        { index: currentIndex, loading: true, isSaved: false }, // Initialize isSaved here
      ]);

      const requestBuilder = new StatefulRequestBuilder(transactionId);
      const commentaryRequest = requestBuilder.GenerateCommentaryRequest(commentaryConfig!);
      const response: StatefulResponse = await statefulAnalysisTree(commentaryRequest);

      if(!response){
        // remove particular index from the commentary array if response is not ok.
        setCommentaryArray((prev) =>
          prev.filter((item, idx) => idx !== currentIndex)
        );
        return;
      } 
      const commentary = response.analysis_tree_responses
        ?.find((response) => response.type === AnalysisTreeRequestType.GENERATE_COMMENTARY)
        ?.generate_commentary?.commentary;
      
      setCommentaryArray((prev) =>
        prev.map((item, idx) =>
          idx === currentIndex
            ? { ...item, loading: false, commentary: commentary! }
            : item
        )
      );
      setLoadingCommentary(false);
      setSelectedCommentaryIndex(currentIndex);
      setSelectedCommentary(commentary!);
    } catch (error) {
      console.log("error");
    }
  };

  // Function to update commentary at a specific index
  const updateCommentaryAtIndex = (
    index: number,
    newCommentary: Commentary,
    isSaved: boolean
  ) => {
    setCommentaryArray((prevCommentaryArray) => {
      const updatedArray = [...prevCommentaryArray];
      updatedArray[index] = {
        ...updatedArray[index],
        commentary: newCommentary,
        isSaved: isSaved,
      };
      return updatedArray;
    });
  };

  //selects the clicked commentary
  const handleSelectCommentary = (commentary: Commentary, index: number) => {
    if (selectedCommentaryIndex === index) {
      // If the same commentary is clicked, unselect it
      setSelectedCommentary(null);
      setSelectedCommentaryIndex(-1);
      setCommentaryNodes(new Set());
    } else {
      // If a different commentary is clicked, select it
      setSelectedCommentary(commentary);
      setSelectedCommentaryIndex(index);
    }
  };

  //triggers when tree is fetched from URL treeId
  useEffect(() => {
    if (tree && tree.commentaries) {
      const initializedCommentaries: CommentaryConfig[] = tree.commentaries.map(
        (commentary, index) => ({
          index: index,
          loading: false,
          commentary: commentary,
          isSaved: true,
        })
      );
      setCommentaryArray(initializedCommentaries);
      // setSelectedCommentaryIndex(tree.commentaries.length-1);
    }
  }, [tree]);

  return (
    <ApiContext.Provider
      value={{
        commentaryArray,
        fetchCommentary,
        loadingCommentary,
        setCommentaryArray,
        updateCommentaryAtIndex,
        handleSelectCommentary,
        selectedCommentaryIndex,
        transactionId
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};
