export const MONTHS = [
  { value: "1", label: "January" },
  { value: "2", label: "February" },
  { value: "3", label: "March" },
  { value: "4", label: "April" },
  { value: "5", label: "May" },
  { value: "6", label: "June" },
  { value: "7", label: "July" },
  { value: "8", label: "August" },
  { value: "9", label: "September" },
  { value: "10", label: "October" },
  { value: "11", label: "November" },
  { value: "12", label: "December" },
];

export const QUARTERS = [
  { value: "1", label: "Quarter 1" },
  { value: "2", label: "Quarter 2" },
  { value: "3", label: "Quarter 3" },
  { value: "4", label: "Quarter 4" },
];

export const YEARS = (() => {
  const startYear = 2019;
  const currentYear = new Date().getFullYear();
  const yearsArray = [];

  for (let year = startYear; year <= currentYear; year++) {
    yearsArray.push(year.toString());
  }
  return yearsArray;
})();
