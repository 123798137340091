import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
  useRef,
} from "react";
import { Edges, Header, Metric } from "../containers/Interfaces";
import {
  AnalysisTreeRequest,
  AnalysisTreeResponse,
  Benchmark,
  BenchmarkFilter,
  Context,
  FilterColumn,
  TemporalColumn,
} from "../containers/commentary/Interfaces";
import { listMetrics, getMetricById } from "../services/MetricService";
import { getModelById } from "../services/DataModelService";
import { v4 as uuidv4 } from "uuid";
import { useDisclosure } from "@chakra-ui/react";
import { useAnalysisContext } from "./AnalysisContext";
import { statefulAnalysisTree } from "../services/AnalysisTreeServices";
import { useUser } from "./UserContext";
import { AnalysisTreeRequestType, CustomPeriod, MetricEdgeType, TimeBucket } from "../utils/enum";
import { generateName } from "../utils/analysisUtils";
import showToast from "../hooks/useCustomToast";
import {
  NodeMetricArray,
  StatefulRequest, 
  StatefulResponse, 
} from "../containers/stateful/Stateful";
import { StatefulRequestBuilder } from "../containers/stateful/StatefulRequests";

type AnalysisTreeMap = {
  loading?: boolean;
  tree?: AnalysisTreeResponse;
  node_metric_array?: NodeMetricArray[];
};

interface AnalysisDrawerContextProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  btnRef: React.RefObject<HTMLButtonElement>;
  metricInfoMap: { [key: string]: Metric };
  setMetricInfoMap: React.Dispatch<
    React.SetStateAction<{ [key: string]: Metric }>
  >;
  temporalColumnByMetricIdMap: { [key: string]: Header[] };
  setTemporalColumnByMetricIdMap: React.Dispatch<
    React.SetStateAction<{ [key: string]: Header[] }>
  >;
  contextByMetricMap: { [key: string]: Context[] };
  setContextByMetricMap: React.Dispatch<
    React.SetStateAction<{ [key: string]: Context[] }>
  >;
  benchmarkByMetricMap: { [key: string]: Benchmark[] };
  setBenchmarkByMetricMap: React.Dispatch<
    React.SetStateAction<{ [key: string]: Benchmark[] }>
  >;
  selectedTemporalColumnsFromDropdown: TemporalColumn[];
  setSelectedTemporalColumnsFromDropdown: React.Dispatch<
    React.SetStateAction<TemporalColumn[]>
  >;
  selectedBenchmarksFromDropdown: TemporalColumn[];
  setSelectedBenchmarksFromDropdown: React.Dispatch<
    React.SetStateAction<TemporalColumn[]>
  >;
  excludedAttributeNames: string[];
  setExcludedAttributeNames: React.Dispatch<React.SetStateAction<string[]>>;
  date: { [key: string]: string[] };
  setDate: React.Dispatch<React.SetStateAction<{ [key: string]: string[] }>>;
  metricHeaders: Header[];
  selectedMetricHeaders: Header[];
  selectedFiltersFromDropdown: FilterColumn[];
  transactionId: string;
  setMetricHeaders: React.Dispatch<React.SetStateAction<Header[]>>;
  setSelectedMetricHeaders: React.Dispatch<React.SetStateAction<Header[]>>;
  setSelectedFiltersFromDropdown: React.Dispatch<
    React.SetStateAction<FilterColumn[]>
  >;
  setTreeId: React.Dispatch<React.SetStateAction<string>>;
  setTransactionId: React.Dispatch<React.SetStateAction<string>>;
  updateFilterContext: (selectedFilter: FilterColumn) => void;
  removeFilter: (selectedFilter: FilterColumn) => void;
  updateTemporalContext: (selectedFilter: TemporalColumn) => void;
  removeTemporal: (selectedFilter: FilterColumn) => void;
  updateBenchmarkMap: (selectedBenchmark: TemporalColumn[]) => void;
  updateExcludeEdges: (attributeName: string[]) => void;
  generateAnalysisTree: () => Promise<void>;
  responseData: { [key: string]: AnalysisTreeMap };
  setResponseData: React.Dispatch<React.SetStateAction<{ [key: string]: AnalysisTreeMap }>>;
  handleEdgeClick: (nodeId: string,
    edge: Edges,
    rowIndex: number,
    transactionId: string) => void;
}

const AnalysisDrawerContext = createContext<
  AnalysisDrawerContextProps | undefined
>(undefined);

export const useAnalysisDrawerContext = () => {
  const context = useContext(AnalysisDrawerContext);
  if (context === undefined) {
    throw new Error("useMetric must be used within a MetricProvider");
  }
  return context;
};

export const AnalysisDrawerContextProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { isLoggedIn } = useUser();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { setLockAnalyzeButton } = useAnalysisContext();

  //this treeId will get set when user is trying to access /{treeId}
  const [treeId, setTreeId] = useState<string>("");
  const [transactionId, setTransactionId] = useState<string>("");
  const btnRef = useRef<HTMLButtonElement>(null);
  const [treeNameMap, setTreeNameMap] = useState< {[key: string]: string[]} >({});
  const [temporalColumnByOwnerIdMap, setTemporalColumnByOwnerIdMap] = useState<{
    [ownerId: string]: Header[];
  }>({});
  const [metricHeaders, setMetricHeaders] = useState<Header[]>([]);
  const [selectedMetricHeaders, setSelectedMetricHeaders] = useState<Header[]>(
    []
  );
  const [metricInfoMap, setMetricInfoMap] = useState<{ [key: string]: Metric }>(
    {}
  );
  const [temporalColumnByMetricIdMap, setTemporalColumnByMetricIdMap] =
    useState<{ [key: string]: Header[] }>({});
  const [contextByMetricMap, setContextByMetricMap] = useState<{
    [key: string]: Context[];
  }>({});
  const [benchmarkByMetricMap, setBenchmarkByMetricMap] = useState<{
    [key: string]: Benchmark[];
  }>({});
  const [excludeEdgesByMetricMap, setExcludeEdgesByMetricMap] = useState<{
    [key: string]: Header[];
  }>({});
  const [date, setDate] = useState<{ [key: string]: string[] }>({});
  const [responseData, setResponseData] = useState<{
    [key: string]: AnalysisTreeMap;
  }>({});

  //Below states are used to show the selected values from starting point
  const [
    selectedTemporalColumnsFromDropdown,
    setSelectedTemporalColumnsFromDropdown,
  ] = useState<TemporalColumn[]>([]);
  const [selectedFiltersFromDropdown, setSelectedFiltersFromDropdown] =
    useState<FilterColumn[]>([]);
  const [selectedBenchmarksFromDropdown, setSelectedBenchmarksFromDropdown] =
    useState<TemporalColumn[]>([]);
  const [excludedAttributeNames, setExcludedAttributeNames] = useState<
    string[]
  >([]);

  // Fetch metric headers when the context mounts
  useEffect(() => {
    if (!isLoggedIn) return;

    const fetchData = async () => {
      try {
        const data = await listMetrics(""/* modelId */);
        setMetricHeaders(data);
      } catch (error) {
        showToast("Error fetching Metrics", "", "error");
      }
    };
    fetchData();
  }, [isLoggedIn]);

  //update metricInfo map based on metricHeaders
  useEffect(() => {
    setContextByMetricMap({});
    setTemporalColumnByOwnerIdMap({});
    setTemporalColumnByMetricIdMap({});
    // setAnalysisTreeReqJSON([]);
    setBenchmarkByMetricMap({});
    setDate({});
    setSelectedTemporalColumnsFromDropdown([]);
    setSelectedBenchmarksFromDropdown([]);
    setSelectedFiltersFromDropdown([]);
    setExcludeEdgesByMetricMap({});
    setExcludedAttributeNames([]);
    
    if (selectedMetricHeaders.length === 0) {
      setMetricInfoMap({});
    }

    const fetchMetricInfoAndUpdateMetricInfoMap = async () => {
      if (!selectedMetricHeaders.length) return; // Early return if metricHeaders is empty

      try {
        // Create a set of metric IDs already present in the metricInfoMap
        const existingMetricIds = new Set(Object.keys(metricInfoMap));

        const updatedMetricInfoMap: { [key: string]: Metric } = {};
        const updatedPresetBenchmark: { [key: string]: Benchmark[] } = {};
        const fetchPromises = selectedMetricHeaders.map(
          async (metric: Header) => {
            if (metric.id !== undefined) {
              // Check if the metric ID is already present in the map
              if (!existingMetricIds.has(metric.id.toString())) {
                // If not present, fetch metric info with edges
                const metricInfo: Metric = await getMetricById(metric.id, true);
                updatedMetricInfoMap[metric.id.toString()] = metricInfo;
                // Initialize an empty array for storing benchmarks for the current metric
                updatedPresetBenchmark[metric.id.toString()] = [];

                // Iterate over each edge
                metricInfo &&
                  metricInfo?.edges?.forEach((edge) => {
                    // Check if the edge type matches the benchmark edge type
                    if (
                      edge.edge_type === MetricEdgeType.BENCHMARK_EDGE &&
                      edge.benchmark
                    ) {
                      // Assuming each benchmark edge contains an array of benchmarks
                      updatedPresetBenchmark[metric?.id!.toString()].push(
                        edge.benchmark
                      );
                    }
                  });
                // Check if any benchmarks were added, delete the key if none were added
                if (updatedPresetBenchmark[metric.id.toString()].length === 0) {
                  delete updatedPresetBenchmark[metric.id.toString()];
                }
              }
            }
          }
        );

        await Promise.all(fetchPromises);

        // Remove IDs from metricInfoMap that are not present in metricHeaders
        Object.keys(metricInfoMap).forEach((key) => {
          if (
            !selectedMetricHeaders.some(
              (header) => header.id?.toString() === key
            )
          ) {
            delete metricInfoMap[key];
          }
        });

        // Update the metricInfoMap state
        setMetricInfoMap((prevData) => ({
          ...prevData,
          ...updatedMetricInfoMap,
        }));
      } catch (error) {
        alert("Error fetching data for metrics:" + error);
      }
    };

    fetchMetricInfoAndUpdateMetricInfoMap();
  }, [selectedMetricHeaders]);

  useEffect(() => {
    const ownerIds = new Set(
      selectedMetricHeaders.map((header) => header.owner_id)
    );

    const fetchModelInfo = async () => {
      const newTemporalColumnByOwnerIdMap: { [key: string]: Header[] } = {
        ...temporalColumnByOwnerIdMap,
      };

      // Remove entries for owner_ids that are no longer present
      Object.keys(newTemporalColumnByOwnerIdMap).forEach((ownerId) => {
        if (!ownerIds.has(ownerId)) {
          delete newTemporalColumnByOwnerIdMap[ownerId];
        }
      });

      // Add new entries for owner_ids that are not in the map yet
      for (const ownerId of Array.from(ownerIds)) {
        if (!newTemporalColumnByOwnerIdMap[ownerId!]) {
          try {
            const modelInfo = await getModelById(ownerId!);
            const temporalColumns = modelInfo.columns.filter(
              (column: { column_type: string }) =>
                column.column_type === "TEMPORAL"
            );
            newTemporalColumnByOwnerIdMap[ownerId!] = temporalColumns.map(
              (column: { header: Header[] }) => column.header || ({} as Header)
            );
          } catch (error) {
            alert(`Error fetching model info for owner ID ${ownerId}`);
          }
        }
      }
      setTemporalColumnByOwnerIdMap(newTemporalColumnByOwnerIdMap);

      const metricIdToTemporalMap: { [key: string]: Header[] } = {};
      selectedMetricHeaders.forEach((header) => {
        const tempHeader = newTemporalColumnByOwnerIdMap[header.owner_id!];
        if (tempHeader) {
          metricIdToTemporalMap[header.id!] = tempHeader;
        }
      });

      setTemporalColumnByMetricIdMap(metricIdToTemporalMap);
    };

    fetchModelInfo();
  }, [selectedMetricHeaders]);

  //update filter in contextByMetricMap
  const updateFilterContext = (selectedFilter: FilterColumn) => {
    // Iterate over each metric in metricInfoMap to update contexts
    const newContextMap = { ...contextByMetricMap };

    Object.entries(metricInfoMap).forEach(([metricId, metric]) => {
      const newContexts: Context[] = newContextMap[metricId] || [];

      metric.edges?.forEach((edge) => {
        if (edge.related_attribute?.name === selectedFilter.name) {
          // Create a new context based on found related_attribute
          // console.log("logical column header ", edge.related_attribute);
          const newContext: Context = {
            logical_column_header: edge.related_attribute,
            operator: selectedFilter.operator || "EQ",
            values:
              selectedFilter.values &&
              selectedFilter.values.flatMap((value) =>
                value.split(",").map((item) => item.trim())
              ),
          };
          newContexts.push(newContext);
        }
      });

      newContextMap[metricId] = newContexts;
    });

    // Update the contextByMetricMap with new values
    setContextByMetricMap(newContextMap);
  };

  //update filter in contextByMetricMap
  const updateTemporalContext = (selectedTemporal: TemporalColumn) => {
    // Iterate over each metric in metricInfoMap to update contexts
    Object.entries(temporalColumnByMetricIdMap).forEach(
      ([metricId, headers]) => {
        headers.forEach((header) => {
          if (header.name === selectedTemporal.name) {
            // When a header name matches, prepare to update contexts
            const valuesFromDates = date[selectedTemporal.name!] || [];

            // Check if the data array has at least two values for processing
            if (valuesFromDates.length >= 2) {
              const updatedContexts = contextByMetricMap[metricId] || [];
              const gte = {
                logical_column_header: header,
                operator: "GTE",
                values: [valuesFromDates[0]],
              };
              const lte = {
                logical_column_header: header,
                operator: "LTE",
                values: [valuesFromDates[1]],
              };
              updatedContexts.push(gte);
              updatedContexts.push(lte);

              // Set the updated contexts back into the map
              setContextByMetricMap((prevMap) => ({
                ...prevMap,
                [metricId]: updatedContexts,
              }));
            }
          }
        });
      }
    );
  };

  //updates the benchmark
  const updateBenchmarkMap = (selectedBenchmarks: TemporalColumn[]) => {
    // Initialize a new benchmark map
    const newBenchmarkMap: { [key: string]: Benchmark[] } = {};

    // Iterate over each metric in metricInfoMap to update contexts
    for (const metricId of Object.keys(metricInfoMap)) {
      for (const benchmark of selectedBenchmarks) {
        if (
          benchmark.custom_period?.value !== CustomPeriod.PreviousPeriod &&
          benchmark.custom_period?.value !== CustomPeriod.SamePeriodLastYear
        ) {
          // Handle custom periods
          handlePresetBenchmark(metricId, benchmark, newBenchmarkMap);
        } else {
          // Handle standard periods
          handleCustomBenchmark(metricId, benchmark, newBenchmarkMap);
        }
      }
    }

    // Set the benchmark map state
    setBenchmarkByMetricMap(newBenchmarkMap);
  };

  //handle addition of presetBenchmark
  const handlePresetBenchmark = (
    metricId: string,
    benchmark: TemporalColumn,
    newBenchmarkMap: { [key: string]: Benchmark[] }
  ) => {
    // Retrieve the array of preset benchmarks for the given metricId
    const presetBenchmarks = metricInfoMap[metricId].edges
      ?.filter(
        (edge) =>
          edge.edge_type === "BENCHMARK_EDGE" && edge.benchmark !== undefined
      )
      .map((edge) => edge.benchmark as Benchmark);

    if (presetBenchmarks) {
      presetBenchmarks.forEach((preBenchmark) => {
        // Check if the preset benchmark's header name matches the custom period name of the benchmark
        if (preBenchmark.header.name === benchmark.custom_period?.name) {
          // Initialize the array in the map if it does not exist
          if (!newBenchmarkMap[metricId]) {
            newBenchmarkMap[metricId] = [];
          }
          // Check for duplicates before adding the benchmarkf
          const isDuplicate = newBenchmarkMap[metricId].some(
            (existingBenchmark) =>
              existingBenchmark.header.name === preBenchmark.header.name
          );
          if (!isDuplicate) {
            newBenchmarkMap[metricId].push(preBenchmark);
          }
        }
      });
    }
  };

  //handle additon of custom benchmark
  const handleCustomBenchmark = (
    metricId: string,
    benchmark: TemporalColumn,
    newBenchmarkMap: { [key: string]: Benchmark[] }
  ) => {
    /// Retrieve corresponding headers from modelTemporalColumnInfoByMetricId
    const headers = temporalColumnByMetricIdMap[metricId];
    const matchingHeader = headers?.find(
      (header) => header.name === benchmark.name
    );

    if (!matchingHeader) {
      alert(
        `No matching header found for benchmark with name: ${benchmark.name}`
      );
      return; // Skip this benchmark if no matching header is found
    }

    const gteFilter: BenchmarkFilter = {
      logical_column_header: matchingHeader,
      operator: "GTE",
      values: [
        date &&
          date[benchmark?.custom_period?.name!] &&
          date[benchmark.custom_period?.name!][0],
      ],
    };

    const lteFilter: BenchmarkFilter = {
      logical_column_header: matchingHeader,
      operator: "LTE",
      values: [
        date &&
          date[benchmark?.custom_period?.name!] &&
          date[benchmark.custom_period?.name!][1],
      ],
    };

    const newBenchmark: Benchmark = {
      header: {
        id: uuidv4(),
        name: benchmark?.custom_period?.name,
      },
      benchmark_filters: [gteFilter, lteFilter],
      variance_tolerance: 0.025,
    };

    if (!newBenchmarkMap[metricId]) {
      newBenchmarkMap[metricId] = [];
    }
    newBenchmarkMap[metricId].push(newBenchmark);
  };

  //remove particular selected filter
  const removeFilter = (filter: FilterColumn) => {
    const newContextMap = { ...contextByMetricMap };

    Object.entries(newContextMap).forEach(([metricId, contexts]) => {
      // Filter out the contexts where the logical_column_header's name matches the removed filter's name
      const filteredContexts = contexts.filter(
        (context) => context.logical_column_header?.name !== filter.name
      );

      newContextMap[metricId] = filteredContexts;
    });

    // Step 3: Update the state with the new context map
    setContextByMetricMap(newContextMap);
  };

  //remove particular selected filter
  const removeTemporal = (filter: FilterColumn) => {
    const newContextMap = { ...contextByMetricMap };

    Object.entries(newContextMap).forEach(([metricId, contexts]) => {
      // Filter out the contexts where the logical_column_header's name matches the removed filter's name
      const filteredContexts = contexts.filter(
        (context) => context.logical_column_header?.name !== filter.name
      );

      newContextMap[metricId] = filteredContexts;
    });

    // Step 3: Update the state with the new context map
    setContextByMetricMap(newContextMap);
  };

  const updateExcludeEdges = (attributeNames: string[]) => {
    const newExcludeEdgesMap: { [key: string]: Header[] } = {};

    Object.entries(metricInfoMap).forEach(([metricId, metric]) => {
      metric.edges?.forEach((edge) => {
        if (
          edge.edge_type === MetricEdgeType.ATTRIBUTE_EDGE &&
          edge.related_attribute &&
          attributeNames.includes(edge.related_attribute.name!)
        ) {
          if (!newExcludeEdgesMap[metricId]) {
            newExcludeEdgesMap[metricId] = [];
          }
          newExcludeEdgesMap[metricId].push(edge.related_attribute);
        } else if (
          edge.edge_type === MetricEdgeType.FUNDAMENTAL_EDGE &&
          edge.fundamental_relationship &&
          edge.fundamental_relationship.header &&
          attributeNames.includes(edge.fundamental_relationship.header.name!)
        ) {
          if (!newExcludeEdgesMap[metricId]) {
            newExcludeEdgesMap[metricId] = [];
          }
          newExcludeEdgesMap[metricId].push(
            edge.fundamental_relationship.header
          );
        }
      });
    });
    setExcludeEdgesByMetricMap(newExcludeEdgesMap);
  };

  const getAnalysisTreeById = async(): Promise<void> => {
    // Generate UUID
    const transactionId = uuidv4();
    const requestBuilder = new StatefulRequestBuilder(transactionId);
    const gettTreeRequest = requestBuilder.LoadTreeRequest(treeId);

    // Set the loader to true initially
    setResponseData((prevState) => ({
      ...prevState,
      [transactionId]: { loading: true },
    }));

    // Make the substitute API call using the UUID from the URL
    try {
      const response: StatefulResponse = await statefulAnalysisTree(gettTreeRequest);
      if (response) {
        // If we expect only one tree or want the first match then only use find.
        const tree = response.analysis_tree_responses
        ?.find((response) => response.type === AnalysisTreeRequestType.GET_TREE)
        ?.get_tree?.tree;

        const nodeMetricMapArray : NodeMetricArray[]= response.analysis_tree_responses
        ?.find((response) => response.type === AnalysisTreeRequestType.GET_TREE)
        ?.get_tree?.node_metric_map!;

        // Update the state with the tree response
        setResponseData((prevState) => ({
          ...prevState,
          [transactionId]: { 
            loading: false, 
            tree: tree, 
            node_metric_array:nodeMetricMapArray
          },
        }));
      } else {
        setResponseData((prevState) => {
          const newState = { ...prevState };
          delete newState[transactionId];
          return newState;
        });
      }
    } catch (error) {
      setResponseData((prevState) => {
        const newState = { ...prevState };
        delete newState[transactionId];
        return newState;
      });
    } finally {
      setLockAnalyzeButton(false);
    }
    return; // Exit the function early since we've handled the empty case
  }

  const generateAnalysisTreeReqJSON = (): StatefulRequest[] | null => {
    if(transactionId){
      const requestBuilder = new StatefulRequestBuilder(transactionId!);
      const statefulRequest = requestBuilder.GetTreeRequest();
      setTransactionId("");
      return [statefulRequest];
    }
    const jsonDataArray = selectedMetricHeaders.map((metric) => {

      const analysisTreeRequest: AnalysisTreeRequest = {
        metricId: metric.id,
        tree_name: generateName(treeNameMap[metric.id!]),
        context: contextByMetricMap[metric.id!],
        benchmark: benchmarkByMetricMap[metric.id!],
        exclude_edges: excludeEdgesByMetricMap[metric.id!],
      };

      // Generate UUID(transaction_id)
      const transactionId = uuidv4()
      const requestBuilder = new StatefulRequestBuilder(transactionId);
      const statefulRequest = requestBuilder.GenerateTreeRequest(analysisTreeRequest)

      return statefulRequest;
    });

    return jsonDataArray;
  };

  const generateAnalysisTree = async () => {
      const analysisTreeReqJSON: StatefulRequest[] | null = generateAnalysisTreeReqJSON();

      try {
        setLockAnalyzeButton(true);

        if (treeId && analysisTreeReqJSON?.length === 0) {
          return getAnalysisTreeById();
        }

        onClose();

        const newEntries: { [key: string]: AnalysisTreeMap } = {};
        analysisTreeReqJSON?.forEach((request) => {
          const uuid = request.state_key?.transaction_id;
          if (uuid) {
            newEntries[uuid] = { loading: true };
          }
        });

        setResponseData((prevState) => ({ ...prevState, ...newEntries }));

        const promises = Object.keys(newEntries).map(async (uuid, index) => {
          const jsonData = analysisTreeReqJSON && analysisTreeReqJSON[index];
          try {
            const response: StatefulResponse = await statefulAnalysisTree(jsonData!);
            if (response) {
                // If we expect only one tree or want the first match then only use find.
                const tree = response.analysis_tree_responses
                ?.find((response) => response.type === AnalysisTreeRequestType.GET_TREE)
                ?.get_tree?.tree;
                const nodeMetricMap: NodeMetricArray[] = response.analysis_tree_responses
                ?.find((response) => response.type === AnalysisTreeRequestType.GET_TREE)
                ?.get_tree?.node_metric_map!;
                setResponseData((prevState) => ({
                  ...prevState,
                  [jsonData?.state_key?.transaction_id!]: 
                  { loading: false, 
                    tree: tree, 
                    node_metric_array:nodeMetricMap, 
                  },
              }));
            } else {
              setResponseData((prevState) => {
                const newState = { ...prevState };
                delete newState[jsonData?.state_key?.transaction_id!];
                return newState;
              });
            }
          } catch (error) {
            setResponseData((prevState) => {
              const newState = { ...prevState };
              delete newState[jsonData?.state_key?.transaction_id!];
              return newState;
            });
          }
        });

        await Promise.all(promises);
        setLockAnalyzeButton(false);
      } catch (error) {
        showToast("Request fails", "", "error");
        setLockAnalyzeButton(false);
      }
  };

  const getTemporalName = () => {
    let temporalName = "";
    selectedTemporalColumnsFromDropdown &&
    selectedTemporalColumnsFromDropdown.forEach((temporalColumn) => {
      if (temporalColumn.time_bucket) {
        let name = "";
        switch (temporalColumn.time_bucket) {
          case TimeBucket.Monthly:
            name = (temporalColumn.month?.name ?? "") + " " + (temporalColumn.year ?? "");
            break;
          case TimeBucket.Quarterly:
            name = (temporalColumn.quarter?.name ?? "") + " " + (temporalColumn.year ?? "");
            break;
          case TimeBucket.Yearly:
            name = temporalColumn.year ?? "";
            break;
          default:
            name = temporalColumn.custom_period?.name ?? "";
        }
        if (name) {
          temporalName += name;
        }
      }
    });
    return temporalName;
  };

  const getFilterName = () => {
    let filterName = "";
    selectedFiltersFromDropdown && 
    selectedFiltersFromDropdown.forEach((filterColumn) => {
      if (filterColumn.values) {
        const concatenatedValues = filterColumn.values.join(",");
        filterName += concatenatedValues + ",";
      }
    });
    return filterName;
  };

  const handleEdgeClick = async (
    nodeId: string,
    edge: Edges,
    rowIndex: number,
    transactionId: string
  ) => {
    let edgeId = "";
    let benchmark = null;
    if (edge.edge_type === MetricEdgeType.BENCHMARK_EDGE) {
      edgeId = edge.benchmark?.header.id!;
      benchmark = edge.benchmark!;
    } else if (edge.edge_type === MetricEdgeType.ATTRIBUTE_EDGE) {
      edgeId = edge.related_attribute?.id!;
    } else if (edge.edge_type === MetricEdgeType.FUNDAMENTAL_EDGE) {
      edgeId = edge.fundamental_relationship?.header?.id!;
    }
    
    const requestBuilder = new StatefulRequestBuilder(transactionId);
    const generateSubTreeRequest = requestBuilder.GenerateSubTreeRequest(nodeId, edgeId, rowIndex, benchmark);
    const response: StatefulResponse = await statefulAnalysisTree(generateSubTreeRequest);
    if (response) {
      const newTree = response.analysis_tree_responses
        ?.find((res) => res.type === AnalysisTreeRequestType.GET_TREE)
        ?.get_tree?.tree;
      const newNodeMetricMap: NodeMetricArray[] = response.analysis_tree_responses
        ?.find((res) => res.type === AnalysisTreeRequestType.GET_TREE)
        ?.get_tree?.node_metric_map!;
      if (newTree) {
        setResponseData((prevData) => ({
          ...prevData,
          [transactionId]: {
            ...prevData[transactionId],
            tree: newTree,
            node_metric_array: newNodeMetricMap!,
          },
        }));
      }
    }
  };

  useEffect(() => {
    const newTreeNameMap: { [key: string]: string[] } = {};

    selectedMetricHeaders && 
    selectedMetricHeaders.forEach((header) => {
      const concatenatedNameArray: string[] = ["", "", ""];
      const metricId = header.id;

      // Add metricInfoMap header name
      concatenatedNameArray[0] = metricInfoMap[header.id!]?.header.name ?? "";

      // Get Temporal Name
      concatenatedNameArray[1] = getTemporalName();

      // Get Filter Name
      concatenatedNameArray[2] = getFilterName();

      if (concatenatedNameArray[0] || concatenatedNameArray[1].trim() || concatenatedNameArray[2].trim()) {
        newTreeNameMap[metricId!] = concatenatedNameArray;
      }
    });

    setTreeNameMap(newTreeNameMap);
  }, [selectedTemporalColumnsFromDropdown, selectedFiltersFromDropdown, selectedMetricHeaders]);

  //reset selected benchmark if temporal column changes.
  useEffect(()=>{
    setSelectedBenchmarksFromDropdown([]);
  }, [selectedTemporalColumnsFromDropdown]);

  useEffect(() => {
    if (treeId || transactionId) {
      generateAnalysisTree();
    }
  }, [treeId, transactionId]);

  const value = {
    isOpen,
    onOpen,
    onClose,
    btnRef,
    metricInfoMap,
    setMetricInfoMap,
    temporalColumnByMetricIdMap,
    setTemporalColumnByMetricIdMap,
    contextByMetricMap,
    setContextByMetricMap,
    benchmarkByMetricMap,
    setBenchmarkByMetricMap,
    metricHeaders,
    setMetricHeaders,
    updateFilterContext,
    date,
    setDate,
    updateTemporalContext,
    updateBenchmarkMap,
    removeFilter,
    removeTemporal,
    generateAnalysisTree,
    responseData,
    setResponseData,
    setSelectedMetricHeaders,
    selectedMetricHeaders,
    setSelectedTemporalColumnsFromDropdown,
    selectedTemporalColumnsFromDropdown,
    selectedFiltersFromDropdown,
    setSelectedFiltersFromDropdown,
    selectedBenchmarksFromDropdown,
    setSelectedBenchmarksFromDropdown,
    updateExcludeEdges,
    setExcludedAttributeNames,
    excludedAttributeNames,
    setTreeId,
    handleEdgeClick,
    transactionId,
    setTransactionId
  };

  return (
    <AnalysisDrawerContext.Provider value={value}>
      {children}
    </AnalysisDrawerContext.Provider>
  );
};
