import { extendTheme } from '@chakra-ui/react';
import { theme } from '@chakra-ui/pro-theme'
import '@fontsource-variable/open-sans'
import '@fontsource-variable/spline-sans'
import '@fontsource-variable/inter'

const proTheme = extendTheme(theme)


const mainTheme = extendTheme({  
    colors: {
        brand: "#4e1e6c",
        transparent: 'transparent',
        black: '#000',
        white: '#fff',
        purple: 
        {
            50: '#f7e9ff',
            100: '#dfc2f0',
            200: '#c89be3',
            300: '#b173d7',
            400: '#9a4ccb',
            500: '#8133b2',
            600: '#65288b',
            700: '#481c64',
            800: '#2b103d',
            900: '#100419',
        },
    },
  }, proTheme);

export default mainTheme;
