import React, { useEffect, useState } from "react";
import { FaRegLightbulb } from 'react-icons/fa';
import { Box, Button, Text, Textarea, Stack, StackDivider, HStack, VStack } from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";
import { MdEdit } from "react-icons/md";
import { NodeInsights } from "../../containers/commentary/Interfaces";

interface PromptStringProps {
  node: NodeInsights; // Pass node data specific to this instance
  updateNodeInfoMap: (updatedNode: NodeInsights) => void; // Callback to update nodeInfoMap
}

const PromptString: React.FC<PromptStringProps> = ({ node, updateNodeInfoMap }) => {
  const [resultStrings, setResultStrings] = useState<string>("");
  const [insights, setInsights] = useState<string>("");
  const [isEditResultStrings, setIsEditResultStrings] = useState<boolean>(false);
  const [isAddInsights, setIsAddInsights] = useState<boolean>(false);

  // Handle changes to the textarea
  const handleResultStringChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setResultStrings(e.target.value);
  };
  // Handle changes to the textarea
  const handleAddInsightsChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInsights(e.target.value);
  };

  // Toggle edit mode of result string
  const handleEditResultStrings = () => {
    setIsEditResultStrings(!isEditResultStrings);
  };

  // Toggle edit mode of insight
  const handleAddInsights = () => {
    setIsAddInsights(!isAddInsights);
  };

  // Update the state when the node changes
  useEffect(() => {
    const newText = node?.current_node.result_strings?.join("\n") || "";
    const nodeInsight = node?.insights ? node.insights: "";
    setResultStrings(newText);
    setIsEditResultStrings(false);
    setInsights(nodeInsight);
  }, [node]);

  // Save the updated text to the nodeMap and reset edit mode
  const handleUpdate = () => {
    if (node) {
      const updatedNode: NodeInsights = {
        current_node: {...node.current_node,
        result_strings: resultStrings.split('\n').filter(line => line.trim() !== '')},
        insights: insights,
      };
      updateNodeInfoMap(updatedNode);
      setIsEditResultStrings(false);
      setIsAddInsights(false);
    }
  };

  return (
    <Box bg="bg.surface" p={2} width="100%" height="100%">
      <VStack spacing="1" divider={<StackDivider mb={2}/>} width="100%" height="100%">
        <Stack flex={1} direction={{ base: 'column', sm: 'row' }} spacing="0" width="100%" alignSelf="flex-start">
          <Stack spacing="2">
          <HStack justifyContent="space-between">
              <Text fontWeight={700} fontSize="sm" textAlign="left" color="fg.default" mb={0}>Analysis Step</Text>
          </HStack>
          <Text fontSize="sm" textAlign="left" color="fg.default">
            {node?.current_node.recommendation_strings?.[0] || "No recommendations available."}
          </Text>
          </Stack>
        </Stack>
        <Stack flex={1} direction={{ base: 'column', sm: 'row' }} spacing="0" width="100%" alignSelf="flex-start">
          <Stack spacing="1" width="100%">
          <HStack justifyContent="space-between">
              <Text fontWeight={700} fontSize="sm" textAlign="left" color="fg.default" mb={0}>Observation</Text>
            <Button
              leftIcon={isEditResultStrings ? <CheckIcon /> : <MdEdit />}
              aria-label={isEditResultStrings ? "Save changes" : "Add Insights"}
              size="xs"
              m={0}
              iconSpacing={isEditResultStrings ? 2 : 2}
              bg={isEditResultStrings ? "green.500" : "bg.surface"}
              color={isEditResultStrings ? "white" : "gray.600"}
              colorScheme={isEditResultStrings ? "green" : "gray"}
              _hover={{ color: isEditResultStrings ? "white": "gray.700",
                        bg: isEditResultStrings ? "green.600": "gray.100"
               }}
              onClick={isEditResultStrings ? handleUpdate : handleEditResultStrings}
            >
              {isEditResultStrings ? "Save" : "Edit"}
            </Button>
          </HStack>
          {isEditResultStrings ? (
            <Textarea
              value={resultStrings}
              onChange={handleResultStringChange}
              focusBorderColor="#4e1e6c"
              placeholder="No Observation"
              textAlign="left"
              fontSize="sm"
              width="100%"
              p={1}
            />
          ) : (
            <Box cursor="pointer" fontSize="sm" textAlign="left" color="fg.default">
              {resultStrings.split('\n').map((line, index) => (
                <Text key={index} mb={0}>{line}</Text>
              ))}
            </Box>
          )}
          </Stack>
        </Stack> 

        <Stack flex={1} direction={{ base: 'column', sm: 'row' }} spacing="0" width="100%" mt={2} alignSelf="flex-start" justifyContent="right">
        <Stack spacing="1" width="100%">
        {insights && <HStack justifyContent="space-between">
           <Text fontWeight={700} fontSize="sm" textAlign="left" color="fg.default" mb={0}>Insights</Text>
           </HStack>}
          {isAddInsights ? (
            <Textarea
              value={insights}
              onChange={handleAddInsightsChange}
              focusBorderColor="#4e1e6c"
              placeholder="No Insight"
              textAlign="left"
              fontSize="sm"
              width="100%"
              p={1}
            />
          ) : (
            <Text cursor="pointer" fontSize="sm" textAlign="left" color="fg.default">
              {insights} 
            </Text>
          )}
            <Button
              leftIcon={isAddInsights ? <CheckIcon /> : <FaRegLightbulb />}
              aria-label={isAddInsights ? "Save changes" : "Add Insights"}
              size="sm"
              m={0}
              width="25%"
              iconSpacing={isAddInsights ? 2 : 2}
              bg={isAddInsights ? "green.500" : "yellow.50"}
              color={isAddInsights ? "white" : "yellow.700"}
              colorScheme={isAddInsights ? "green" : "yellow"}
              variant="outline"
              borderRadius="full"
              alignSelf="flex-end"
              _hover={{ color: isAddInsights ? "white": "yellow.800",
                        bg: isAddInsights ? "green.600": "yellow.200"
               }}
              onClick={isAddInsights ? handleUpdate : handleAddInsights}
            >
              {isAddInsights ? "Save" : "Add Insights"}
            </Button>
            </Stack>
        </Stack> 
      </VStack>
    </Box>  
  );
};

export default PromptString;