
export const systemPrompt = async()=> {

    const message = `You are a financial analyst. You have already analyzed metrics and written commentary about them. Your job is now to read the commentaries provided and help the user by answering their questions or assisting them in tasks.

    Keep the output to 700 words maximum. Use markdown format to reply and use headings and bullet points where appropriate. Bold the numbers and important words surrounding the numbers.`
  
    return message;
}

export const userPrompt = async(commentaries: string, userMessage: string)=> {

    const message = `COMMENTARIES:

    ${commentaries}
    
    USER MESSAGE:
    
    ${userMessage}
    
    YOUR RESPONSE:
    `
    
    return message;
}