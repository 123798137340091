import React, { useState } from "react";
import {
  Box,
  IconButton,
  Center,
  Spinner,
  HStack,
  Icon,
  Divider,
  Text,
} from "@chakra-ui/react";
import { FaTimes } from "react-icons/fa";
import { FaTableList } from "react-icons/fa6";
import { IoBarChart } from "react-icons/io5";
import DataChart from "./DataChart";
import DataTable from "./DataTable";
import PromptString from "./PromptString";
import { NodeInsights } from "../../containers/commentary/Interfaces";
import { FaRegLightbulb } from "react-icons/fa";
import { View } from "../../containers/analyse/AnalysisPanelContainer";
import {
  TbCircle1Filled,
  TbCircle2Filled,
  TbCircle3Filled,
  TbCircle4Filled,
  TbCircle5Filled,
} from "react-icons/tb";

interface DataDisplayComponentProps {
  index: number;
  nodeDataLoader: boolean;
  node: NodeInsights;
  isAdmin: boolean;
  onClose: () => void;
  nodeInfoMap: Map<string, NodeInsights> | null;
}

const DataDisplayComponent: React.FC<DataDisplayComponentProps> = ({
  index,
  nodeDataLoader,
  node,
  isAdmin,
  onClose,
  nodeInfoMap,
}) => {
  const [view, setView] = useState<View>("table"); // Local state
  // Function to get the appropriate icon based on index
  const getIconByIndex = (idx: number) => {
    const icons = [
      TbCircle1Filled, // index 0
      TbCircle2Filled, // index 1
      TbCircle3Filled, // index 2
      TbCircle4Filled, // index 3
      TbCircle5Filled, // index 4
    ];
    return icons[idx] || TbCircle5Filled;
  };

  const IconComponent = getIconByIndex(index);
  return (
    <Box
      bg="transparent"
      position="relative"
      overflow="none"
      p={0}
      width="100%"
      height="100%"
    >
      {!nodeDataLoader && (
        <HStack gap={2} justifyContent="space-between" w="100%">
          <HStack spacing={1}>
            {/* Display the Icon */}
            <Icon as={IconComponent} boxSize={5} color="gray.700" />
            {/* Display the Text */}
            <Text mb={0} fontWeight={600} fontSize="sm" color="gray.700">
              {node?.current_node?.driver_header ||
              node?.current_node?.fundamental_correlation_header
                ? `View ${
                    view.charAt(0).toUpperCase() + view.slice(1)
                  } for 'Split by ${
                    node?.current_node?.driver_header
                      ? node?.current_node?.driver_header.name
                      : node?.current_node?.fundamental_correlation_header?.name
                  }'`
                : `View ${view.charAt(0).toUpperCase() + view.slice(1)}`}
            </Text>
          </HStack>
          <HStack spacing={1}>
            <IconButton
              icon={<Icon as={FaTableList} boxSize={5} />}
              onClick={() => setView("table")}
              aria-label="Table view"
              size="xs"
              color={view === "table" ? "gray.700" : "gray.400"}
              bg={view === "table" ? "purple.50" : "transparent"}
              colorScheme="gray"
              variant="ghost"
            />
            <IconButton
              icon={<Icon as={IoBarChart} boxSize={5} />}
              onClick={() => setView("chart")}
              aria-label="Chart view"
              size="xs"
              color={view === "chart" ? "gray.700" : "gray.400"}
              bg={view === "chart" ? "purple.50" : "transparent"}
              colorScheme="gray"
              variant="ghost"
            />
            <IconButton
              icon={<Icon as={FaRegLightbulb} boxSize={5} />}
              onClick={() => setView("prompt")}
              aria-label="Insights view"
              size="xs"
              color={view === "prompt" ? "gray.700" : "gray.400"}
              bg={view === "prompt" ? "purple.50" : "transparent"}
              colorScheme="gray"
              variant="ghost"
            />
            <Center height="2rem">
              <Divider orientation="vertical" ml={1} />
            </Center>
            <IconButton
              icon={<Icon as={FaTimes} boxSize={5} />}
              onClick={onClose}
              aria-label="Close"
              size="xs"
              color="red.400"
              colorScheme="gray"
              variant="ghost"
            />
          </HStack>
        </HStack>
      )}

      {!nodeDataLoader && <Divider my={1} />}
      <Box
        flex="1"
        position="relative"
        overflow="hidden"
        p={0}
        width="100%"
        height="90%"
      >
        {nodeDataLoader ? (
          <Center height="100%">
            <Spinner size="xl" color="purple" />
          </Center>
        ) : view === "table" ? (
          <DataTable
            key={node?.current_node?.id}
            node={node}
            showColumn={isAdmin}
          />
        ) : view === "chart" ? (
          <DataChart key={node?.current_node.id} data={node} />
        ) : (
          <PromptString 
            node={node}
            updateNodeInfoMap={(updatedNode) => {
              nodeInfoMap?.set(node.current_node.id, updatedNode);
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default DataDisplayComponent;
