import {
  Header,
  BenchmarkFilter,
  Benchmark,
} from "../../containers/Interfaces";
import { CustomBenchmark } from "../../utils/enum";
import { v4 as uuidv4 } from "uuid";
import { getDateRanges } from "../../utils/dateUtils";

// Function to create BenchmarkFilter JSON objects
const createBenchmarkFilter = (
  logicalColumnHeader: Header,
  operator: string,
  value: string
): BenchmarkFilter => {
  return {
    logical_column_header: logicalColumnHeader,
    operator: operator,
    values: [value],
  };
};

// Function to create Benchmarks for the Same Period Last Year and Previous Period
export const createBenchmarks = (
  gteValue: string,
  lteValue: string,
  header: Header,
  includeSamePeriodLastYear: boolean = true, // Optional boolean flag
  samePeriodLastYearId?: string,             // Optional ID
  includePreviousPeriod: boolean = true,     // Optional boolean flag
  previousPeriodId?: string,                  // Optional ID
  lastNPeriod: boolean = false
): {
  samePeriodLastYearBenchmark?: Benchmark;
  previousPeriodBenchmark?: Benchmark;
} => {
  // Get date ranges for benchmarks
  const dateRanges = getDateRanges(gteValue, lteValue);

  // Initialize benchmark objects to be returned
  let samePeriodLastYearBenchmark: Benchmark | undefined;
  let previousPeriodBenchmark: Benchmark | undefined;

  // Conditionally create the Same Period Last Year benchmark if the flag is true
  if (includeSamePeriodLastYear) {
    const samePeriodLastYearFilters: BenchmarkFilter[] = [
      createBenchmarkFilter(header, "GTE", dateRanges.samePeriodLastYear.gte),
      createBenchmarkFilter(header, "LTE", dateRanges.samePeriodLastYear.lte),
    ];

    samePeriodLastYearBenchmark = {
      header: {
        id: samePeriodLastYearId ?? uuidv4(), // Use provided ID or generate one
        name: CustomBenchmark.SamePeriodLastYear,
      },
      benchmark_filters: samePeriodLastYearFilters,
      variance_tolerance: 0.025,
      ...(lastNPeriod === true && { last_n_period_average: 4 }),
    };
  }

  // Conditionally create the Previous Period benchmark if the flag is true
  if (includePreviousPeriod) {
    const previousPeriodFilters: BenchmarkFilter[] = [
      createBenchmarkFilter(header, "GTE", dateRanges.previousPeriod.gte),
      createBenchmarkFilter(header, "LTE", dateRanges.previousPeriod.lte),
    ];

    previousPeriodBenchmark = {
      header: {
        id: previousPeriodId ?? uuidv4(), // Use provided ID or generate one
        name: CustomBenchmark.PreviousPeriod,
      },
      benchmark_filters: previousPeriodFilters,
      variance_tolerance: 0.025,
      ...(lastNPeriod === true && { last_n_period_average: 4 }),
    };
  }

  return { samePeriodLastYearBenchmark, previousPeriodBenchmark };
};
