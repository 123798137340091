// apiService.ts

import { DataSourceInfo, ModelInfo } from "../containers/Interfaces";
import showToast from "../hooks/useCustomToast";

/**
 * Sends a request to create a new model.
 *
 * @param {ModelInfo} model_info - The updated model information.
 * @returns {Promise<any>} A promise that resolves to the API response.
 * @throws {Error} Throws an error if the request fails.
 */
export const createModel = async (model_info: ModelInfo) => {
  try {
    const response = await fetch(process.env.REACT_APP_DATAMODEL_CREATE!, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(model_info),
    });

    if (!response.ok) {
      const errorMsg = await response.text();
      showToast("Error creating data model", "", "error");
      return null;
    }
    return await response.json();
  } catch (error) {
    showToast("Error sending data to API", "", "error");
  }
};

/**
 * Sends a request to update a new model.
 * @param {UpdatedModelInfo} model_info - The updated model information.
 * @returns {Promise<any>} A promise that resolves to the API response.
 * @throws {Error} Throws an error if the request fails.
 */
export const updateModel = async (model_info: ModelInfo) => {
  try {
    const response = await fetch(process.env.REACT_APP_DATAMODEL_UPDATE!, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(model_info),
    });

    if (!response.ok) {
      const errorMsg = await response.text();
      showToast("Error updating model", "", "error");
      return null;
    }
    return await response.json();
  } catch (error) {
    showToast("Error sending data to API", "", "error");
  }
};

/**
 * Fetches the list of models.
 *
 * @returns {Promise<any>} A promise that resolves to the list of models.
 * @throws {Error} Throws an error if the request fails.
 */
export const fetchModelList = async () => {
  try {
    const response = await fetch(process.env.REACT_APP_LIST_DATAMODEL!, {
      credentials: "include",
    });

    if (!response.ok) {
      showToast("Error fetching model list", "", "error");
      return null;
    }

    return await response.json();
  } catch (error) {
    showToast("Error fetching model list", "", "error");
  }
};

/**
 * Fetches model information based on the provided model source.
 *
 * @param {DataSourceInfo} modelSource - The model source information.
 * @returns {Promise<any>} A promise that resolves to the model information.
 * @throws {Error} Throws an error if the request fails.
 */
export const fetchModelInfo = async (modelSource: DataSourceInfo) => {
  try {
    const encodedModelSource = encodeURIComponent(JSON.stringify(modelSource));
    const response = await fetch(`${process.env.REACT_APP_DATAMODEL_INFO!}?dataSourceJson=${encodedModelSource}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include"
    });

    if (!response.ok) {
      const errorMsg = await response.text();
      showToast("Error fetching model Info", "", "error");
      return null;
    }
    const data = await response.json();
    const newResponse = {
      model_info: data
    }
    return newResponse;
  } catch (error) {
    showToast("Error fetching data", "", "error");
  }
};

/**
 * Fetches model information based on the provided model ID.
 *
 * @param {string} id - The ID of the model to fetch.
 * @returns {Promise<ModelInfo>} A promise that resolves to the model information.
 * @throws {Error} Throws an error if the request fails.
 */
export async function getModelById(id: string) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_GET_MODEL_BY_ID}/${id}`,
      {
        credentials: "include",
      }
    );

    if (!response.ok) {
      showToast("Error fetching model by ID", "", "error");
      return null;
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching model by ID:", error);
    showToast("Error fetching model by ID", "", "error");
  }
}

export const syncTSworksheet = async (id: string) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_SYNC_TS_WORKSHEET}?dataSourceType=THOUGHTSPOT&sourceModelId=${id}`,
      {
        method: "GET",
        credentials: "include",
      }
    );

    if (!response.ok) {
      return null;
    }
    return await response.json();
  } catch (error) {
    showToast("Error in syncing TS worksheet", "", "error");
  }
};

export const getColumnValues = async (id: string) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_GET_COLUMN_VALUES}?logicalColumnId=${id}`,
      {
        method: "GET",
        credentials: "include",
      }
    );

    if (!response.ok) {
      return null;
    }
    return await response.json();
  } catch (error) {
    showToast("Error in fetching column values", "", "error");
  }
};
