import React from "react";
import {
  Flex,
  Tag,
  TagLabel,
  Text,
  Icon,
  HStack,
  Spacer,
} from "@chakra-ui/react";
import { getOperatorSymbol } from "../../utils/analysisUtils";
import { Node } from "../../containers/commentary/Interfaces";
import { TbFilterShare } from "react-icons/tb";

interface CurrentFiltersProps {
  selectedNode: Node;
}

const CurrentFilters: React.FC<CurrentFiltersProps> = ({ selectedNode }) => {
  return (
    <Flex
      align="center"
      maxW="4xl"
      m={1}
      mx={3}
      p={2}
      borderRadius="lg"
      bg="purple.50"
    >
      <HStack spacing={1} alignItems="center" mr={2}>
        <Text fontWeight={700} fontSize="xs" color="gray.900" mb={0}>
          FILTERS
        </Text>
        <Icon as={TbFilterShare} boxSize={6} />
      </HStack>
      <HStack
        wrap="wrap"
        justifyContent="flex-start"
        spacing={1}
        flexShrink={1}
      >
        {selectedNode?.observation.content.filters &&
          selectedNode.observation.content.filters
            .filter((filter) => filter.logical_column_header.name !== "Version")
            .map((filter, index) => (
              <Tag
                size="sm"
                fontSize="xs"
                fontWeight="semibold"
                borderRadius="md"
                variant="ghost"
                key={index}
                bg="bg.surface"
                color="black"
                borderWidth="1px"
                borderColor="gray.400"
                p={2}
              >
                <Flex>
                  <TagLabel mr={1}>
                    {filter.logical_column_header.name}
                  </TagLabel>
                  <TagLabel mr={1}>
                    {getOperatorSymbol(filter.operator)}
                  </TagLabel>
                  <TagLabel>{filter.values.join(", ")}</TagLabel>
                </Flex>
              </Tag>
            ))}
      </HStack>
    </Flex>
  );
};

export default CurrentFilters;
