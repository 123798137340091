import React from "react";
import {
  Box,
  Input,
  SimpleGrid,
  Button,
  VStack,
  HStack,
  IconButton,
  Text,
  InputGroup,
  InputLeftElement,
  Icon,
} from "@chakra-ui/react";
import { IoIosCloseCircle } from "react-icons/io";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  CheckCircleIcon,
  CloseIcon,
} from "@chakra-ui/icons";
import { Header } from "../../../containers/Interfaces";
import { FiSearch } from "react-icons/fi";

interface ListMetricProps {
  totalMetrics: number | 0;
  filteredMetrics: Header[];
  searchInput: string;
  setSearchInput: (input: string) => void;
  selectedMetrics: Header[];
  handleMetricSelection: (metric: Header) => void;
  isOverlayVisible: boolean;
  setIsOverlayVisible: React.Dispatch<React.SetStateAction<boolean>>;
  inputHeight: number;
  inputRef: React.RefObject<HTMLInputElement>;
  overlayRef: React.RefObject<HTMLDivElement>;
  handleNext: () => void;
  handlePrevious: () => void;
  handleBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  currentItems: Header[];
}

const ListMetric: React.FC<ListMetricProps> = ({
  filteredMetrics,
  searchInput,
  setSearchInput,
  selectedMetrics,
  handleMetricSelection,
  isOverlayVisible,
  setIsOverlayVisible,
  inputHeight,
  inputRef,
  overlayRef,
  handlePrevious,
  handleNext,
  handleBlur,
  currentItems,
}) => {

  return (
    <VStack spacing="2" align="stretch" width="100%" position="relative"> 
      <Text fontSize="sm" fontWeight="bold" mb={2}>
        METRICS
      </Text>
      <InputGroup
        w="100%"
        display={{ base: "none", md: "inline-flex" }}
        alignItems="center"
        borderRadius="lg"
      >
        <InputLeftElement height="100%">
          <Icon as={FiSearch} color="fg.muted" fontSize="md" />
        </InputLeftElement>
        <Input
          ref={inputRef}
          placeholder="Search for metrics..."
          size="md"
          borderRadius="md"
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          onFocus={() => setIsOverlayVisible(true)}
          onBlur={handleBlur}
        />
      </InputGroup>

      {isOverlayVisible && (
        <Box
          ref={overlayRef}
          position="absolute"
          top={`${inputHeight + 50}px`} // Positioning it 5px below the input field
          left="0"
          zIndex="10"
          bg="bg.surface"
          border="1px"
          borderColor="gray.300"
          p={1}
          width="100%"
          boxShadow="md"
          tabIndex={-1}
        >
          <VStack>
            <HStack width="100%" justify="space-between" px={2} >
              <Text fontSize="sm" fontWeight={500} alignSelf="center" mb={0} color="gray.500">Showing {filteredMetrics.length} results</Text>
            </HStack>

            <HStack width="100%" justify="center" px={0} py={2} spacing={0}>
            <IconButton
              size="sm"
              p={0}
              ml={-3}
              mr={-3}
              borderRadius="full"
              variant="ghost"
              color="fg.accent"
              icon={<ChevronLeftIcon />}
              onClick={handlePrevious}
              aria-label="Previous Page"
            />
            <Box maxH="300px" overflowY="auto" width="100%" px={1}>
              <SimpleGrid columns={2} spacing={2} alignItems="stretch">
                {currentItems && currentItems.map((metric) => (
                  <Button
                    key={metric.id}
                    variant="solid"
                    color={selectedMetrics.includes(metric) ? "white" : "black"}
                    fontWeight={600}
                    bg={
                      selectedMetrics.includes(metric)
                        ? "purple.700"
                        : "purple.50"
                    }
                    onClick={() => handleMetricSelection(metric)}
                    _hover={{
                      bg: selectedMetrics.includes(metric)
                        ? "purple.600"
                        : "purple.100",
                    }}
                    border="None"
                    whiteSpace="normal"
                    wordBreak="break-word"
                    textAlign="left"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box textAlign="left">{metric.name}</Box>
                    {selectedMetrics.includes(metric) ? (
                      <CheckCircleIcon boxSize={3} />
                    ) : (
                      <Box boxSize={3} opacity="0" />
                    )}
                  </Button>
                ))}
              </SimpleGrid>
            </Box>
            <IconButton
              size="sm"
              p={0}
              ml={-3}
              mr={-3}
              colorScheme="purple"
              borderRadius="full"
              variant="ghost"
              color="fg.accent"
              icon={<ChevronRightIcon />}
              onClick={handleNext}
              aria-label="Next Page"

            />
            </HStack>
            </VStack>
        </Box>
      )}

      <Box mt={2}>
        <HStack spacing={2} wrap="wrap">
          {selectedMetrics.map((metric) => (
            <Button
              key={metric.id}
              size="sm"
              bg="purple.50"
              variant="outline"
              color="fg.default"
              fontWeight={600}
              rightIcon={<CloseIcon boxSize={2} />}
              onClick={() => handleMetricSelection(metric)}
            >
              {metric.name}
            </Button>
          ))}
        </HStack>
      </Box>
    </VStack>
  );
};

export default ListMetric;