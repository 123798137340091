import {
  Box,
  Button,
  ButtonGroup,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useColorModeValue,
  Flex,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Text,
  Spinner,
  Badge,
  HStack,
  Divider,
  Icon,
  Tooltip,
  Center,
  IconButton,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useBatchContext } from "../../context/batch/BatchContext";
import { getJobrun, retryJobrun } from "../../services/BatchServices";
import { JobRun, JobRunStep } from "./Batch";
import { IoMdRefresh } from "react-icons/io";
import { VscDebugRerun } from "react-icons/vsc";
import { FaExternalLinkAlt } from "react-icons/fa";
import { epochConverter } from "../../utils/dateUtils";
import { BiArrowBack } from "react-icons/bi";
import { getActionTypeDisplayName } from "../../utils/analysisUtils";
import { exportContent } from "../../services/ReportPageServices";
import { ExecutionStatus } from "../../utils/enum";
import { FiDownload, FiRefreshCcw } from "react-icons/fi";

interface GroupedSteps {
  analysisStep: JobRunStep;
  actionSteps: JobRunStep[];
}

const StatusPage: React.FC<{ onBack: () => void }> = ({ onBack }) => {
  const { selectedJob, selectedRunHeader } = useBatchContext();
  const [jobRunSteps, setJobRunSteps] = useState<JobRunStep[]>([]);
  const [groupedSteps, setGroupedSteps] = useState<GroupedSteps[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [uniqueReportIds, setUniqueReportIds] = useState<Set<string>>(new Set<string>());
  const [downloadLoader, setDownloadLoader] = useState<{report: boolean, page: boolean}>({
    report:false, 
    page: false
  });
  const textColor = useColorModeValue("gray.900", "white");

  const getRun = async () => {
    try {
      setIsLoading(true);
      const response: JobRun = await getJobrun(selectedJob?.header?.id!, selectedRunHeader?.id!);
      if (response) {
        setJobRunSteps(response?.steps!);
      } else {
        setJobRunSteps([]);
      }
    } catch (error) {
      console.error("Error fetching job run:", error);
      setJobRunSteps([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedRunHeader) {
      getRun();
      setUniqueReportIds(new Set<string>());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRunHeader]);

  useEffect(() => {
    // Group steps by analysis_step_index and ensure all ASSIGN_TO_PAGE actions are successful
    const groups: { [key: number]: GroupedSteps } = {};
    const reportIds = new Set<string>();
    let allAssignToPageSuccessful = true; // Track if all ASSIGN_TO_PAGE actions are successful
  
    jobRunSteps.forEach((step) => {
      const index = step.analysis_step_index!;
      if (step.type === "ANALYSIS_STEP") {
        groups[index] = {
          analysisStep: step,
          actionSteps: [],
        };
      } else if (step.type === "ACTION_STEP") {
        if (groups[index]) {
          groups[index].actionSteps.push(step);
        } else {
          // Handle if ACTION_STEP comes before ANALYSIS_STEP
          groups[index] = {
            analysisStep: {
              ...step,
              action_step: { type: step.action_step?.type! },
              analysis_step: {
                ...step.action_step,
                tree_name: `Step ${index + 1}`, // Default name
              },
            },
            actionSteps: [step],
          };
        }
  
        // Check for ASSIGN_TO_PAGE steps with SUCCESS status
        if (
          step.action_step?.type === "ASSIGN_TO_PAGE" &&
          step.status === ExecutionStatus.SUCCESS &&
          step.action_step.assign_to_page_request?.book_id
        ) {
          reportIds.add(step.action_step.assign_to_page_request.book_id);
        }
      }
    });
  
    // Convert groups to an array and sort by analysis_step_index
    const sortedGroupedSteps = Object.values(groups).sort(
      (a, b) =>
        a.analysisStep.analysis_step_index! - b.analysisStep.analysis_step_index!
    );
  
    setGroupedSteps(sortedGroupedSteps);
  
    // Only update uniqueReportIds if all ASSIGN_TO_PAGE actions are successful
    if (allAssignToPageSuccessful) {
      setUniqueReportIds(reportIds);
    } else {
      setUniqueReportIds(new Set<string>()); // Clear if not all are successful
    }
  }, [jobRunSteps]);
  

  const handleRetry = async () => {
    try {
      await retryJobrun(selectedJob?.header?.id!, selectedRunHeader?.id!);
      // Refetch the job run data after retry
      getRun();
    } catch (error) {
      console.error("Error retrying job run:", error);
      // Optionally, show a toast or notification
    }
  };

  // Function to map status to Badge color
  const getStatusBadgeColor = (status: string | undefined) => {
    switch (status?.toUpperCase()) {
      case "SUCCESS":
        return "green";
      case "FAILURE":
        return "red";
      case "IN_PROGRESS":
        return "yellow";
      default:
        return "gray";
    }
  };

  // Handler to export report
  const handleReportDownload = async() => {
    setDownloadLoader({report: true, page: false});
    // Get the first element of uniqueReportIds
    const [reportId] = Array.from(uniqueReportIds);
    // Perform download logic with firstReportId
    if (reportId) {
      await exportContent("REPORT_BOOK", reportId);
    }
    setDownloadLoader({report: false, page: false});
  };

  return (
    <Box position="relative" overflowY="auto" height="100%" width="100%">
      {/* Header Section */}
      <Box m={1}>
        <HStack justify="space-between">
          <HStack spacing={0}>
            {/* <Icon as={LuCalendarClock} boxSize="5" color="black" /> */}
            <HStack spacing={2} ml={2}>
              {/* Use VStack to stack name and runtime vertically */}
              <Text
                fontWeight="bold"
                align="left"
                color="gray.700"
                fontSize="sm"
                mb={0}
              >
                {selectedJob?.header?.name || "Job Details"}
              </Text>
              <Text
                fontSize="sm" // Smaller font for runtime
                color="gray.500"
                mb={0}
              >
                {`(run at: ${epochConverter(
                  parseInt(selectedRunHeader?.created_date_ms!)
                ) || "Runtime info"})`}
              </Text>
            </HStack>
          </HStack>
          <HStack>
            <ButtonGroup spacing={1}>
              {/* Conditionally render download icon */}
              {uniqueReportIds.size > 0 && (
                <Tooltip label="Download Report" aria-label="Download Report Tooltip">
                  <Box position="relative" display="inline-block">
                    {!downloadLoader.report ? (
                      <Button 
                        size="xs"
                        leftIcon={<Icon as={FiDownload} boxSize={4}/>} 
                        colorScheme="gray"
                        variant="outline"
                        cursor="pointer" 
                        onClick={handleReportDownload}
                        fontWeight={700}
                      >
                        EXPORT
                      </Button>
                    ) : (
                      <Center height="100%">
                        {/* Ensure the spinner is centered */}
                        <Spinner size="sm" color="purple.700" />
                      </Center>
                    )}
                  </Box>
                </Tooltip>
              )}
              {/* Refresh Status Button */}
              <Button
                size="xs"
                onClick={() => getRun()}
                colorScheme="gray"
                variant="outline"
                leftIcon={<Icon as={IoMdRefresh} boxSize={4} />}
                fontWeight={700}
              >
                REFRESH
              </Button>
              {/* Retry Job Button */}
              <Button
                size="xs"
                onClick={handleRetry}
                colorScheme="gray"
                variant="outline"
                leftIcon={<Icon as={FiRefreshCcw} boxSize={4} />}
                fontWeight={700}
              >
                RETRY
              </Button>
            </ButtonGroup>
          </HStack>
        </HStack>
        <Divider my={2} borderColor="purple.700" />
      </Box>

      {/* Loading Indicator */}
      {isLoading ? (
        <Flex justifyContent="center" alignItems="center" mt={10}>
          <Spinner size="xl" />
        </Flex>
      ) : (
        // Accordion for Grouped Steps
        <Accordion allowMultiple mt={3}>
          {groupedSteps?.map((group, idx) => (
            <AccordionItem
              key={idx}
              bg="gray.50"
              color="gray.900"
              borderRadius="xl"
              mx={2}
              mb={3}
              borderWidth="1px"
              borderColor="gray.400"
            >
              {({ isExpanded }) => (
                <Box>
                  <AccordionButton
                    _expanded={{ bg: "gray.200" }}
                    alignItems="center"
                    borderRadius="xl"
                  >
                    <HStack
                      width="100%"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <HStack alignItems="center">
                        {/* Analysis Step Name with Link */}
                        <Text
                          fontWeight={600}
                          color="gray.900"
                          mr={2}
                          mb={0}
                          fontSize="sm"
                        >
                          {group.analysisStep?.analysis_step?.tree_name ||
                            `Step ${idx + 1}`}
                        </Text>
                      </HStack>
                      <HStack>
                        {/* OPEN Button */}
                        {group.analysisStep.status === ExecutionStatus.SUCCESS && (
                          <Button
                            size="xs"
                            colorScheme="purple"
                            variant="outline"
                            rightIcon={<Icon as={FaExternalLinkAlt} boxSize={3} />}
                            onClick={() =>
                              window.open(
                                `/analyze/${group.analysisStep.output}`,
                                "_blank"
                              )
                            }
                            px={2}
                            m={0}
                          >
                            Open
                          </Button>
                        )}
                        {/* Analysis Step Status */}
                        <Badge
                          colorScheme={getStatusBadgeColor(
                            group.analysisStep.status
                          )}
                        >
                          {group.analysisStep.status
                            ? group.analysisStep.status
                                .charAt(0)
                                .toUpperCase() +
                              group.analysisStep.status.slice(1).toLowerCase()
                            : "Unknown"}
                        </Badge>
                        <AccordionIcon />
                      </HStack>
                    </HStack>
                  </AccordionButton>
                  <AccordionPanel bg="white" borderRadius="xl" pb={2}>
                    {/* Action Steps Table */}
                    <Table variant="simple" color={textColor} size="sm">
                      <Thead>
                        <Tr bg="white">
                          <Th color="gray.600">Action Step</Th>
                          <Th color="gray.600">Status</Th>
                          <Th color="gray.600">Output</Th> {/* New Output Column */}
                        </Tr>
                      </Thead>
                      <Tbody>
                        {group.actionSteps.length > 0 ? (
                          group.actionSteps.map((action, actionIdx) => (
                            <Tr key={actionIdx}>
                              <Td fontWeight={600}>
                                {action.action_step?.type
                                  ? getActionTypeDisplayName(action.action_step.type!)
                                  : `Action ${actionIdx + 1}`}
                              </Td>
                              <Td>
                                <Badge
                                  colorScheme={getStatusBadgeColor(
                                    action.status
                                  )}
                                >
                                  {action.status
                                    ? action.status.charAt(0).toUpperCase() +
                                      action.status.slice(1).toLowerCase()
                                    : "Unknown"}
                                </Badge>
                              </Td>
                             
                              <Td align="center">
                                {/* Conditionally display the output for ASSIGN_TO_PAGE actions with SUCCESS status */}
                                {action.action_step?.type === "ASSIGN_TO_PAGE" && action.status?.toUpperCase() === "SUCCESS" ? (
                                  <Tooltip label="Download Page" aria-label="Download Page Tooltip">
                                    <Box position="relative" display="inline-block">
                                      {!downloadLoader.page ? (
                                        <Icon
                                          as={FiDownload} 
                                          size="xs"
                                          boxSize="5" 
                                          ml={3} 
                                          color="purple.700" 
                                          cursor="pointer" 
                                          alignSelf="center"
                                          onClick={async() => {
                                            setDownloadLoader({report: false, page: true});
                                            await exportContent("REPORT_PAGE", action.output!);
                                            setDownloadLoader({report: false, page: false});
                                          }}
                                        />
                                      ) : (
                                        <Center height="100%">
                                          {/* Ensure the spinner is centered */}
                                          <Spinner size="sm" color="purple.700" />
                                        </Center>
                                      )}
                                    </Box>
                                  </Tooltip>
                                ) : (
                                  ""
                                )}
                              </Td>
                            </Tr>
                          ))
                        ) : (
                          <Tr>
                            <Td colSpan={3} textAlign="center">
                              No Action Steps
                            </Td>
                          </Tr>
                        )}
                      </Tbody>
                    </Table>
                  </AccordionPanel>
                </Box>
              )}
            </AccordionItem>
          ))}
        </Accordion>
      )}

      {/* Optional: Display when there are no steps */}
      {!isLoading && groupedSteps.length === 0 && (
        <Box textAlign="center" mt={10}>
          <Text color="gray.500">No steps available.</Text>
        </Box>
      )}
    </Box>
  );
};

export default StatusPage;
