import React, { useState, useEffect } from "react";
import { Header, Edges } from "../../Interfaces";
import { FilterColumn, TemporalColumn } from "../../commentary/Interfaces";
import { LocalDate, DateTimeFormatter } from "@js-joda/core";
import { TimeBucket } from "../../../utils/enum";
import { getColumnValues } from "../../../services/DataModelService";
import ContextFilter from "../../../components/analyse/analysis_drawer/ContextFilter";
import { useAnalysisDrawerContext } from "../../../context/AnalysisDrawerContext";
import { useToast } from "@chakra-ui/react";

const ContextFilterContainer: React.FC = () => {
  const {
    metricInfoMap,
    temporalColumnByMetricIdMap,
    updateFilterContext,
    updateTemporalContext,
    removeFilter,
    removeTemporal,
    setDate,
    selectedTemporalColumnsFromDropdown,
    setSelectedTemporalColumnsFromDropdown,
    selectedFiltersFromDropdown,
    setSelectedFiltersFromDropdown,
    updateExcludeEdges,
    setExcludedAttributeNames,
    excludedAttributeNames,
  } = useAnalysisDrawerContext();

  const [uniqueRelatedAttributeName, setUniqueRelatedAttributeName] = useState<
    string[]
  >([]);
  const [removableEdges, setRemovableEdges] = useState<string[]>([]);
  const [
    uniquetemporalColumnNameFromModelInfo,
    setUniquetemporalColumnNameFromModelInfo,
  ] = useState<string[]>([]);
  const [selectedFilter, setSelectedFilter] = useState<FilterColumn>({});
  const [selectedTemporalColumn, setSelectedTemporalColumn] =
    useState<TemporalColumn>({});
  const [selectedMonth, setSelectedMonth] = useState<{
    value: string;
    name: string;
  }>({
    value: "",
    name: "",
  });
  const [selectedQuarter, setSelectedQuarter] = useState<{
    value: string;
    name: string;
  }>({
    value: "",
    name: "",
  });
  const [selectedYear, setSelectedYear] = useState<string>("");
  const [filterContextValues, setFilterContextValues] = useState<string[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [isLoadingAttributeValues, setIsLoadingAttributeValues] =
    useState<boolean>(false);
  const [isFailedAttributeValues, setIsFailedAttributeValues] =
    useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<string>("");
  const toast = useToast();

  useEffect(() => {
    // Create a Set to store unique related attribute names
    const uniqueRelatedAttributeNames = new Set<string>();
    const uniqueFundamentalRelationshipNames = new Set<string>();

    // Iterate through the values of the metricInfoMap
    Object.values(metricInfoMap).forEach((metric: any) => {
      // Extract the related_attributes array from the metric (default to an empty array)
      const edgesArray = metric?.edges || [];

      // Iterate through the edgesArray and add unique names to the Set
      edgesArray.forEach((edges: Edges) => {
        const attriuteName = edges?.related_attribute?.name;
        const fundamentalName = edges?.fundamental_relationship?.header?.name;
        if (attriuteName) {
          uniqueRelatedAttributeNames.add(attriuteName);
        }
        if (fundamentalName) {
          uniqueFundamentalRelationshipNames.add(fundamentalName);
        }
      });
    });

    // Update the state with the unique related attribute names
    setUniqueRelatedAttributeName(Array.from(uniqueRelatedAttributeNames));
    // Create an array combining both uniqueRelatedAttributeNames and uniqueFundamentalRelationshipNames
    const removableEdges: string[] = [
      ...Array.from(uniqueRelatedAttributeNames),
      ...Array.from(uniqueFundamentalRelationshipNames)
    ];

    setRemovableEdges(removableEdges);
  }, [metricInfoMap]);

  //effect to handle unique temporal column name
  useEffect(() => {
    const uniqueNames = new Set<string>(); 

    // Iterate over all entries in the map
    Object.values(temporalColumnByMetricIdMap).forEach((headers: any) => {
      headers.forEach((header: any) => {
        if (header.name) {
          uniqueNames.add(header.name); 
        }
      });
    });
    setUniquetemporalColumnNameFromModelInfo(Array.from(uniqueNames));
    // setSelectedFiltersFromDropdown([]);
  }, [temporalColumnByMetricIdMap]);

  const handleFilterContextValues = async (id: string) => {
    setIsLoadingAttributeValues(true);
    setIsFailedAttributeValues(false);
    const response = await getColumnValues(id);
    if (!response) {
      setFilterContextValues([]);
      setIsFailedAttributeValues(true);
    } else {
      setFilterContextValues(response);
    }
    setIsLoadingAttributeValues(false);
  };

  const handleFilterSelection = async (
    name: string,
    operator: string,
    values: string[]
  ) => {
    if (process.env.REACT_APP_ATTRIBUTE_VALUES_DROPDOWN === "true") {
      let flg = false;
      Object.entries(metricInfoMap).forEach(
        ([metricId, metric]: [string, any]) => {
          metric.edges?.forEach((edge: any) => {
            if (edge.related_attribute?.name === name) {
              flg = true;
              const id = edge.related_attribute?.id!;
              handleFilterContextValues(id);
            }
          });
        }
      );

      if (!flg) setFilterContextValues([]);

      setSelectedOptions([]);
    }

    setSelectedFilter({
      ...selectedFilter,
      name,
      operator,
      values,
    });
  };

  // Function to handle time selection and update selected temporal column state
  const handleTimeSelection = (
    name: string,
    time_bucket: string,
    logical_column_header?: Header,
    quarter?: {
      name: string;
      value: string;
    },
    month?: {
      name: string;
      value: string;
    },
    year?: string
  ) => {
    setSelectedTemporalColumn({
      name,
      logical_column_header,
      time_bucket,
      quarter,
      month,
      year,
    });
  };

  // Function to add the selected filter to the filters state
  const handleAddFilter = () => {
    if (!selectedFilter.name || !selectedFilter.values?.length) return;
    // Adding the filter to some filter state assumed to exist
    setSelectedFiltersFromDropdown([...selectedFiltersFromDropdown, { ...selectedFilter }]);

    updateFilterContext(selectedFilter);
    // Reset selected filter to default values
    setSelectedFilter({
      name: "",
      operator: "",
      values: [],
    });

    setSelectedOptions([]);
    setFilterContextValues([]);
    // Iterate over each metric in metricInfoMap
  };

  // Function to add the selected temporal column to the temporalColumnFromDropdown state
  const handleAddTemporalColumn = () => {
    const temporalColumnWithDetails = {
      ...selectedTemporalColumn,
      quarter: {
        name: selectedQuarter.name,
        value: selectedQuarter.value,
      },
      month: {
        name: selectedMonth.name,
        value: selectedMonth.value,
      },
      year: selectedYear,
    };
    updateTemporalContext(selectedTemporalColumn);
    setSelectedTemporalColumnsFromDropdown([
      ...selectedTemporalColumnsFromDropdown,
      temporalColumnWithDetails,
    ]);

    // modelTemporalColumnInfoByMetricId
    // Reset selected temporal column to default values
    setSelectedTemporalColumn({
      logical_column_header: {},
      time_bucket: "",
      quarter: {
        name: "",
        value: "",
      },
      month: {
        name: "",
        value: "",
      },
      year: "",
    });
    setSelectedQuarter({
      name: "",
      value: "",
    });
  };

  // Function to remove a filter at a specific index from the filters state
  const handleRemoveFilter = (index: number) => {
    const filterName = selectedFiltersFromDropdown[index].name;
    const filtersToRemove = selectedFiltersFromDropdown.filter(
      (filter) => filter.name === filterName
    );
    const updatedFilters = selectedFiltersFromDropdown.filter(
      (filter) => filter.name !== filterName
    );
    setSelectedFiltersFromDropdown(updatedFilters);
    filtersToRemove.forEach((filter) => removeFilter(filter));
  };

  // Function to remove a temporal column at a specific index from the temporalColumnFromDropdown state
  const handleRemoveTemporalColumn = (index: number) => {
    const removedTemporalColumn = selectedTemporalColumnsFromDropdown[index];
    const updatedTemporalColumns = [...selectedTemporalColumnsFromDropdown];
    updatedTemporalColumns.splice(index, 1);
    setSelectedTemporalColumnsFromDropdown(updatedTemporalColumns);

    const temporalColumnName = removedTemporalColumn.name;

    // Remove references from the date map
    setDate((prevDates: any) => {
      const updatedDate = { ...prevDates };
      delete updatedDate[temporalColumnName!];
      return updatedDate;
    });

    // Update contextByMetricMap to remove any contexts associated with the removed temporal column
    removeTemporal(removedTemporalColumn);
  };

  // structure the context time in the formate of querying in backend
  const handleDateCalculations = (
    selectedTime: TemporalColumn,
    selectedMonth: string,
    selectedQuarter: string,
    selectedYear: string
  ) => {
    // Convert selectedMonth and selectedYear to numeric values
    const monthValue = parseInt(selectedMonth, 10);
    const quarterValue = parseInt(selectedQuarter, 10);
    const yearValue = parseInt(selectedYear, 10);
  
    // Initialize variables for start and end dates
    let startDate: string = "";
    let endDate: string = "";
    const dateFormatter = DateTimeFormatter.ofPattern("MM/dd/yyyy");
  
    // Check the selected time operator
    if (selectedTime.time_bucket === TimeBucket.Monthly) {
      // Calculate start and end dates for monthly time bucket
      if (!Number.isNaN(monthValue) && !Number.isNaN(yearValue)) {
        try {
          startDate = LocalDate.of(yearValue, monthValue, 1).format(dateFormatter);
          endDate = LocalDate.of(yearValue, monthValue, 1)
            .plusMonths(1)
            .minusDays(1)
            .format(dateFormatter);
        } catch (error) {
          toast({
            title: "Error",
            description: "Error calculating dates for monthly time bucket.",
            status: "error",
            duration: 2000,
            isClosable: true,
            position: "bottom-right"
          });
        }
      }
    } else if (selectedTime.time_bucket === TimeBucket.Quarterly) {
      // Calculate start and end dates for quarterly time bucket
      if (!Number.isNaN(quarterValue) && !Number.isNaN(yearValue)) {
        try {
          const quarterStartMonth = (quarterValue - 1) * 3 + 1;
          startDate = LocalDate.of(yearValue, quarterStartMonth, 1).format(dateFormatter);
          endDate = LocalDate.of(yearValue, quarterStartMonth, 1)
            .plusMonths(3)
            .minusDays(1)
            .format(dateFormatter);
        } catch (error) {
          toast({
            title: "Error",
            description: "Error calculating dates for quarterly time bucket.",
            status: "error",
            duration: 2000,
            isClosable: true,
            position: "bottom-right"
          });
        }
      }
    } else if (selectedTime.time_bucket === TimeBucket.Yearly) {
      // Calculate start and end dates for yearly time bucket
      if (!Number.isNaN(yearValue)) {
        try {
          startDate = LocalDate.of(yearValue, 1, 1).format(dateFormatter);
          endDate = LocalDate.of(yearValue, 1, 1)
            .plusYears(1)
            .minusDays(1)
            .format(dateFormatter);
        } catch (error) {
          toast({
            title: "Error",
            description: "Error calculating dates for yearly time bucket.",
            status: "error",
            duration: 2000,
            isClosable: true,
            position: "bottom-right"
          });
        }
      }
    }
  
    // If start and end dates are valid, update the date state for the selected temporal column
    if (startDate && endDate) {
      setDate((prevDates: any) => ({
        ...prevDates,
        [selectedTime.name || ""]: [startDate.toString(), endDate.toString()],
      }));
    }
  };

  //filter out temporal column not selected
  const getAvailableTemporalColumns = () => {
    return uniquetemporalColumnNameFromModelInfo.filter((column) => {
      const columnName = column; // Using name instead of id
      return !selectedTemporalColumnsFromDropdown.some(
        (selectedColumn) => selectedColumn.name === columnName // Matching by name
      );
    });
  };

  //this useEffect handle's the request to change selected time bucket to corresponding date formate.
  useEffect(() => {
    if (
      selectedMonth &&
      selectedQuarter &&
      selectedYear &&
      selectedTemporalColumn
    ) {
      handleDateCalculations(
        selectedTemporalColumn,
        selectedMonth.value,
        selectedQuarter.value,
        selectedYear
      );
    }
  }, [selectedMonth, selectedQuarter, selectedYear, selectedTemporalColumn]);

  //function to handle select multiple values
  const handleToggle = (value: string) => {
    const currentIndex = selectedOptions.indexOf(value);
    let newSelected = [...selectedOptions];

    if (currentIndex === -1) {
      newSelected.push(value);
    } else {
      newSelected.splice(currentIndex, 1);
    }

    setSelectedOptions(newSelected);
    setSelectedFilter((filter: FilterColumn) => ({
      ...filter,
      values: newSelected,
    }));
  };

  // Function to handle mutliple exclude attributes
  const handleCheckboxChange = (option: string) => {
    setExcludedAttributeNames((prev) => {
      const index = prev.indexOf(option);
      if (index > -1) {
        return prev.filter((item) => item !== option); // Remove option if it's already selected
      } else {
        return [...prev, option]; // Add option if it's not selected
      }
    });
  };

  const handleDropdownChange = (e: { target: { value: string } }) => {
    const value = e.target.value;
    setSelectedOption(value);
    if (value.startsWith("temporal-")) {
      const name = value.replace("temporal-", "");
      handleTimeSelection(name, selectedTemporalColumn.time_bucket || "");
    } else if (value.startsWith("attribute-")) {
      const name = value.replace("attribute-", "");
      handleFilterSelection(
        name,
        selectedFilter.operator!,
        selectedFilter.values || []
      );
    }
    
  };
  // useEffect to call updateExcludeEdges whenever selectedAttributeNames changes
  useEffect(() => {
    updateExcludeEdges(excludedAttributeNames);
  }, [excludedAttributeNames]);

  return (
    <ContextFilter
      filters={selectedFiltersFromDropdown}
      selectedTemporalColumnsFromDropdown={selectedTemporalColumnsFromDropdown}
      uniqueRelatedAttributeName={uniqueRelatedAttributeName}
      selectedFilter={selectedFilter}
      selectedTemporalColumn={selectedTemporalColumn}
      handleFilterSelection={handleFilterSelection}
      handleTimeSelection={handleTimeSelection}
      handleAddFilter={handleAddFilter}
      handleAddTemporalColumn={handleAddTemporalColumn}
      handleRemoveFilter={handleRemoveFilter}
      handleRemoveTemporalColumn={handleRemoveTemporalColumn}
      selectedMonth={selectedMonth}
      selectedQuarter={selectedQuarter}
      selectedYear={selectedYear}
      setSelectedMonth={setSelectedMonth}
      setSelectedQuarter={setSelectedQuarter}
      setSelectedYear={setSelectedYear}
      availableTemporalColumns={getAvailableTemporalColumns()}
      filterContextValues={filterContextValues}
      selectedOptions={selectedOptions}
      handleToggle={handleToggle}
      handleCheckboxChange={handleCheckboxChange}
      isLoadingAttributeValues={isLoadingAttributeValues}
      isFailedAttributeValues={isFailedAttributeValues}
      excludedAttributeNames={excludedAttributeNames}
      handleDropdownChange={handleDropdownChange}
      selectedOption={selectedOption}
      removableEdges={removableEdges}
    />
  );
};

export default ContextFilterContainer;