import{
  createContext,
  useState,
  useContext,
  FC,
  ReactNode,
  useEffect,
} from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

/* structure of user data*/
interface UserData {
  isLoggedIn: boolean
  isAdmin:boolean;
  username: string | undefined
  userId: number | undefined
  login: (enteredUsername: string, enteredPassword: string) => Promise<void>
  logout: () => Promise<void>
  reload: () => Promise<void>
}

/* context for managing user data*/
const UserContext = createContext<UserData | undefined>(undefined)

/* Custom hook to access user data from the context */
export const useUser = (): UserData => {
  const context = useContext(UserContext)
  if (!context) {
    throw new Error('useUser must be used within a UserProvider')
  }
  return context
}

/* props for the UserProvider component */
interface UserProviderProps {
  children: ReactNode
}

/* Provider component for managing user data*/
export const UserProvider: FC<UserProviderProps> = ({ children }) => {
  
  const navigate = useNavigate();
  const location = useLocation();

  /* State variables for managing user data*/
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [username, setUsername] = useState('')
  const [userId, setUserId] = useState<number | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  /* Function to handle user login*/
  const login = async (enteredUsername: string, enteredPassword: string) => {
    try {
      const formData = new FormData()
      formData.append('username', enteredUsername)
      formData.append('password', enteredPassword)

      const response = await fetch(
        process.env.REACT_APP_LOGIN!,
        {
          method: 'POST',
          credentials: 'include',
          body: formData,
        },
      )
      const responseData = await response.json()

      if (response.status === 200) {
        setIsLoggedIn(true)
        setUsername(responseData.header.name)
        setUserId(responseData.header.id)
        const redirectUrl = sessionStorage.getItem('preLoginUrl') || '/';
        sessionStorage.removeItem('preLoginUrl'); 
        navigate(redirectUrl);
        setIsAdmin(responseData.isAdmin);
      } else {
        setIsLoggedIn(false)
        setUsername('')
        setUserId(undefined)
        alert('Credentials do not match')
      }
    } catch (error) {
      console.error('Error logging in:', error)
    }
  }

  /* Function to handle user logout*/
  const logout = async () => {
    const response = await fetch(
      process.env.REACT_APP_LOGOUT!,
      {
        method: 'POST',
        credentials: 'include',
      },
    )

    if (response.status === 204) {
      setIsLoggedIn(false)
      setUsername('')
      setUserId(undefined)
      navigate('/login')
      setIsAdmin(false);
    } else {
      console.log('error in logout')
    }
  }

  /* Function to reload user session*/
  const reload = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_SESSION_INFO!,
        {
          method: 'GET',
          credentials: 'include',
        },
      )

      const responseData = await response.json()
      // console.log('reloadResponse: ', responseData)

      if (response.ok) {
        setIsLoggedIn(true)
        setUsername(responseData.header.name)
        setUserId(responseData.header.id)
        setIsAdmin(responseData.isAdmin);
      } else {
        const fullPath = location.pathname + location.search;
        sessionStorage.setItem('preLoginUrl', fullPath);
        const error = await responseData.error
        console.error('Error while fetching user data', error)
      }
    } catch (error) {
      console.error('Error while reloading session:', error)
    }
  }
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true); // Set isLoading to true before fetching data
      await reload(); // Call the reload function
      setIsLoading(false);
    };

    fetchData(); // Call the fetchData function when the component mounts
  }, []);

  /* Provide user data through context*/
  return (
    <UserContext.Provider
      value={{ isLoggedIn, isAdmin, username, userId, login, logout, reload }}
    >
      {isLoading ? (
        <div></div> // Render loading indicator while data is being fetched
      ) : (
        children
      )}
    </UserContext.Provider>
  )
}
