import React, { useState, useEffect, useRef } from "react";
import CommentaryCard from "../../components/commentary/CommentaryCard";
import {
  AnalysisTreeResponse,
  Commentary,
} from "../../containers/commentary/Interfaces";
import { generateCommentary } from "../../services/CommentaryServices";

interface CommentaryCardContainerProps {
  tree: AnalysisTreeResponse;
  id: string;
}

/**
 * CommentaryCardContainer Component
 *
 * Input:
 * - tree: AnalysisTreeResponse object representing the analysis tree.
 * - id: String representing the ID of the commentary card.
 * 
 * Return:
 * Renders the CommentaryCard component with relevant props.
 *
 * This component manages the container for the commentary card.
 * It fetches commentary data based on the provided ID and renders the commentary card.
 * The main functionality includes fetching commentary data and passing it to the CommentaryCard component.
 *
 * @component
 */
const CommentaryCardContainer: React.FC<CommentaryCardContainerProps> = ({
  tree,
  id,
}) => {
  // State variables
  const [commentaryArray, setCommentaryArray] = useState<Commentary[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [conversationId, setConversationId] = useState<string>("");
  const [hideCommentaryCardHeaderButton, setHideCommentaryCardHeaderButton] =
    useState<boolean>(false);
  const [copiedText, setCopiedText] = useState<string>("");
  const [copy, setCopy] = useState<boolean>(false);
  const [isDiff, setIsDiff] = useState<boolean>(false);
  const markdownRef = useRef<HTMLDivElement>(null);
  const [updateStarredCommentary, setUpdateStarredCommentary] = useState<boolean>(false);
  const [isFailed, setIsFailed] = useState<boolean>(false) //state for tracking failed commentary generation

  /**
   * Handles copying text from the commentary.
   */
  const handleCopy = () => {
    setCopy(true);
    const element = markdownRef.current
    if (element) {
      const originalBg = element.style.backgroundColor
      element.style.backgroundColor = 'transparent'
      const range = document.createRange()
      range.selectNodeContents(element)
      const selection = window.getSelection()
      if (selection) {
        selection.removeAllRanges()
        selection.addRange(range)
        document.execCommand('copy')
        selection.removeAllRanges()
        element.style.backgroundColor = originalBg
      }
    }
  };

  /**
   * Toggles the diff checker.
   */
  const handleDiffChecker = () => {
    setIsDiff(!isDiff);
  };

  /**
   * Fetches commentary data from the backend API.
   */
  const fetchCommentary = async () => {
    try {
      setIsLoading(true);
      setIsFailed(false);
      setHideCommentaryCardHeaderButton(true);
      const jsonData:Commentary = await generateCommentary(id);
      
      if(!jsonData) setIsFailed(true); 
      else{
        setCommentaryArray([jsonData]);
        setConversationId(jsonData.conversation_header.id!);
        setHideCommentaryCardHeaderButton(false);
        console.log(jsonData);
      }
    } catch (error) {
      setIsFailed(true);
      console.error("Error fetching commentary:", error);
    }
    finally{
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCommentary();
  }, [id]);


  return (
    <CommentaryCard
      libraryPage={false}
      libraryStarIndex={-1}
      starredConversationId={""}
      setUpdateStarredCommentary={setUpdateStarredCommentary}
      tree={tree}
      id={id}
      commentaryArray={commentaryArray}
      setCommentaryArray={setCommentaryArray}
      isLoading={isLoading}
      setIsLoading={setIsLoading}
      activeIndex={activeIndex}
      setActiveIndex={setActiveIndex}
      conversationId={conversationId}
      setConversationId={setConversationId}
      copy={copy}
      setCopy={setCopy}
      setCopiedText={setCopiedText}
      isDiff={isDiff}
      setIsDiff={setIsDiff}
      handleCopy={handleCopy}
      handleDiffChecker={handleDiffChecker}
      markdownRef={markdownRef}
      hideCommentaryCardHeaderButton={hideCommentaryCardHeaderButton}
      isFailed={isFailed}
      fetchCommentary={fetchCommentary}
    />
  );
};

export default CommentaryCardContainer;
