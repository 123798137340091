import { CheckIcon } from "@chakra-ui/icons";
import { useEffect, useState } from "react";
import { MdEdit } from "react-icons/md";
import { Icon, Box } from "@chakra-ui/react";

interface TreeNameProps {
  chatName: string;
  handleChatName: (name: string) => void;
}

/*
 * This component renders a text input field that allows the user to edit a header.
 * When the user clicks on the text, it switches to an editable input field.
 * When the user finishes editing (e.g., by clicking outside the input field),
 * the value is saved and the input field switches back to display mode.
 */

const TreeName: React.FC<TreeNameProps> = ({ chatName, handleChatName }) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [value, setValue] = useState<string>(chatName);

  useEffect(() => {
    setValue(chatName);
  }, [chatName]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    setIsEditing(false);
    handleChatName(value);
  };

  const handleChange: any = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  return (
    <Box>
      {isEditing ? (
        <Box>
          <input
            type="text"
            value={value}
            onChange={handleChange}
            onBlur={handleSave}
            autoFocus
          />
          <CheckIcon onClick={handleSave} ml={2} cursor="pointer" />
        </Box>
      ) : (
        <Box>
          {value}
          <Icon
            as={MdEdit}
            onClick={handleEdit}
            boxSize={4}
            color="gray.500"
            cursor="pointer"
            ml={3}
          />
        </Box>
      )}
    </Box>
  );
};

export default TreeName;
