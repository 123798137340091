import React from "react";
import {
  Box,
  Button,
  Select,
  VStack,
  HStack,
  FormControl,
  Icon,
  Text,
  TagCloseButton,
  Tag,
  TagLabel,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Checkbox,
  Spinner,
  Divider,
} from "@chakra-ui/react";
import { AddIcon, ArrowForwardIcon, ChevronDownIcon } from "@chakra-ui/icons";
import { Header } from "../../../containers/Interfaces";
import {
  TemporalColumn,
  FilterColumn,
} from "../../../containers/commentary/Interfaces";
import {
  MonthSelect,
  QuarterSelect,
  YearSelect,
} from "../../common/DateTimeSelectors";

interface ContextFilterProps {
  filters: FilterColumn[];
  selectedTemporalColumnsFromDropdown: TemporalColumn[];
  uniqueRelatedAttributeName: string[];
  selectedFilter: FilterColumn;
  selectedTemporalColumn: TemporalColumn;
  handleFilterSelection: (
    header: string,
    operator: string,
    values: string[]
  ) => void;
  handleTimeSelection: (
    name: string,
    time_bucket: string,
    column?: Header,
    quarter?: {
      name: string;
      value: string;
    },
    month?: {
      name: string;
      value: string;
    },
    year?: string
  ) => void;
  handleAddFilter: () => void;
  handleAddTemporalColumn: () => void;
  handleRemoveFilter: (index: number) => void;
  handleRemoveTemporalColumn: (index: number) => void;
  selectedMonth: {
    value: string;
    name: string;
  };
  selectedQuarter: { value: string; name: string };
  selectedYear: string;
  setSelectedMonth: React.Dispatch<
    React.SetStateAction<{
      value: string;
      name: string;
    }>
  >;
  setSelectedQuarter: React.Dispatch<
    React.SetStateAction<{ value: string; name: string }>
  >;
  setSelectedYear: React.Dispatch<React.SetStateAction<string>>;
  availableTemporalColumns: string[];
  filterContextValues: string[];
  selectedOptions: string[];
  handleToggle: (value: string) => void;
  handleCheckboxChange: (value: string) => void;
  isLoadingAttributeValues: boolean;
  isFailedAttributeValues: boolean;
  excludedAttributeNames: string[];
  selectedOption: string;
  handleDropdownChange: (e: { target: { value: string } }) => void;
  removableEdges: string[];
}

const ContextFilter: React.FC<ContextFilterProps> = ({
  filters,
  selectedTemporalColumnsFromDropdown,
  uniqueRelatedAttributeName,
  selectedFilter,
  selectedTemporalColumn,
  handleFilterSelection,
  handleTimeSelection,
  handleAddFilter,
  handleAddTemporalColumn,
  handleRemoveFilter,
  handleRemoveTemporalColumn,
  selectedMonth,
  selectedQuarter,
  selectedYear,
  setSelectedMonth,
  setSelectedQuarter,
  setSelectedYear,
  availableTemporalColumns,
  filterContextValues,
  selectedOptions,
  handleToggle,
  handleCheckboxChange,
  isLoadingAttributeValues,
  isFailedAttributeValues,
  selectedOption,
  excludedAttributeNames,
  handleDropdownChange,
  removableEdges,
}) => {
  return (
    <VStack spacing={3} align="stretch">
        <Divider />
        <Text fontSize="sm" fontWeight="bold" mb={1} color="fg.default">
          SCOPE
        </Text>

        {/* TAGS SECTION */}
        <VStack spacing="2" align="stretch">
          <VStack spacing={2}>
          {selectedTemporalColumnsFromDropdown &&
            selectedTemporalColumnsFromDropdown.map((temporalColumn, index) => (
              <Box
                width="100%"
                display="flex"
                flexWrap="wrap"
                borderRadius="md"
              >
                <Tag
                  key={index}
                  variant="solid"
                  bg="purple.50"
                  color="fg.default"
                  borderColor="purple.700"
                  borderWidth="1px"
                  flexGrow={1}
                >
                  <TagLabel
                    flexGrow={1}
                    textAlign="left"
                    fontSize="xs"
                    fontWeight="bold"
                  >
                    <Icon as={ArrowForwardIcon} color="black" mr={1} />
                    {`${temporalColumn.name} =${temporalColumn.month?.name} ${temporalColumn.quarter?.name} ${temporalColumn.year}`}
                  </TagLabel>
                  <TagCloseButton
                    onClick={() => handleRemoveTemporalColumn(index)}
                  />
                </Tag>
              </Box>
            ))}
          
          {filters && filters.map((filter, index) => (
            <Box
              width="100%"
              display="flex"
              flexWrap="wrap"
              borderRadius="md"
            >
              <Tag
                key={index}
                variant="solid"
                bg="purple.50"
                color="fg.default"
                borderColor="purple.700"
                borderWidth="1px"
                flexGrow={1}
              >
                <TagLabel
                  flexGrow={1}
                  textAlign="left"
                  fontSize="xs"
                  fontWeight="bold"
                >
                  <Icon as={ArrowForwardIcon} color="black" mr={1} />
                  {`${filter.name} = ${filter.values}`}
                </TagLabel>
                <TagCloseButton onClick={() => handleRemoveFilter(index)} />
              </Tag>
            </Box>
          ))}
          
          {excludedAttributeNames && 
          excludedAttributeNames.map((name, index) => (
            <Box
              width="100%"
              display="flex"
              flexWrap="wrap"
              borderRadius="md"
            >
              <Tag
                key={index}
                variant="solid"
                bg="purple.50"
                color="fg.default"
                borderColor="purple.700"
                borderWidth="1px"
                flexGrow={1}
              >
                <TagLabel
                  flexGrow={1}
                  textAlign="left"
                  fontSize="xs"
                  fontWeight="bold"
                >
                  <Icon as={ArrowForwardIcon} color="black" mr={1} />
                  Exclude {name} from Analysis
                </TagLabel>
                <TagCloseButton onClick={() => handleCheckboxChange(name)} />
              </Tag>
            </Box>
          ))}
          </VStack>
        {/* END TAGS SECTION */}
        </VStack>
        <VStack spacing="3" align="stretch">
        {/* ADD FILTER SECTION */}
        <HStack w="100%" spacing={2}>
          <FormControl id="combined_dropdown" size="sm" width="100%">
            <Select
              fontSize="sm"
              placeholder="Add Filters"
              onChange={handleDropdownChange}
              value={selectedOption}
              borderColor="gray.300"
            >
              {availableTemporalColumns.map((column) => (
                <option key={column} value={`temporal-${column}`}>
                  {column}
                </option>
              ))}
              {uniqueRelatedAttributeName.map((attribute) => (
                <option key={attribute} value={`attribute-${attribute}`}>
                  {attribute}
                </option>
              ))}
            </Select>
          </FormControl>

          {selectedOption.startsWith("temporal-") && (
            <>
              <FormControl id="time_bucket" size="sm" width="100%">
                <Select
                  fontSize="sm"
                  placeholder="Time Bucket"
                  onChange={(e) => {
                    setSelectedMonth({ name: "", value: "" });
                    setSelectedYear("");
                    handleTimeSelection(
                      selectedOption.replace("temporal-", ""),
                      e.target.value || ""
                    );
                  }}
                  value={selectedTemporalColumn.time_bucket || ""}
                >
                  <option value="Monthly">Monthly</option>
                  <option value="Quarterly">Quarterly</option>
                  <option value="Yearly">Yearly</option>
                </Select>
              </FormControl>

              {selectedTemporalColumn.time_bucket === "Monthly" && (
                <VStack spacing={2} width="100%">
                  <MonthSelect
                    selectedMonth={selectedMonth}
                    setSelectedMonth={setSelectedMonth}
                  />
                  <YearSelect
                    selectedYear={selectedYear}
                    setSelectedYear={setSelectedYear}
                  />
                </VStack>
              )}

              {selectedTemporalColumn.time_bucket === "Quarterly" && (
                <VStack spacing={2} width="100%">
                  <QuarterSelect
                    selectedQuarter={selectedQuarter}
                    setSelectedQuarter={setSelectedQuarter}
                  />
                  <YearSelect
                    selectedYear={selectedYear}
                    setSelectedYear={setSelectedYear}
                  />
                </VStack>
              )}

              {selectedTemporalColumn.time_bucket === "Yearly" && (
                <YearSelect
                  selectedYear={selectedYear}
                  setSelectedYear={setSelectedYear}
                />
              )}
              <Button
                borderRadius="full"
                variant="solid"
                bg="purple.700"
                leftIcon={<AddIcon />}
                size="xs"
                iconSpacing={0}
                onClick={handleAddTemporalColumn}
              ></Button>
            </>
          )}

          {selectedOption.startsWith("attribute-") && (
            <>
              {isLoadingAttributeValues && (
                <Spinner color="purple.500"></Spinner>
              )}
              {isFailedAttributeValues && (
                <Text fontSize="sm">Failed to fetch values</Text>
              )}
              {!isLoadingAttributeValues && !isFailedAttributeValues && (
                <>
                  <Menu closeOnSelect={false} preventOverflow={true}>
                    <MenuButton
                      as={Button}
                      color="fg.default"
                      bg="bg.default"
                      maxW="100%"
                      width="auto"
                      rightIcon={<ChevronDownIcon />}
                      fontSize="sm"
                      border="1px"
                      borderColor="gray.300"
                      borderRadius="md"
                      fontWeight="normal"
                      _hover={{ bg: "purple.50" }}
                    >
                      {selectedOptions.length > 0
                        ? `Selected (${selectedOptions.length})`
                        : "Select Options"}
                    </MenuButton>
                    <MenuList maxHeight="200px" overflowY="auto" ml={-6}>
                      {filterContextValues &&
                        filterContextValues.map((option, index) => (
                          <MenuItem key={index} minH="40px" zIndex={10}>
                            <Checkbox
                              isChecked={selectedOptions.includes(option)}
                              onChange={() => handleToggle(option)}
                              colorScheme="purple"
                            >
                              {option}
                            </Checkbox>
                          </MenuItem>
                        ))}
                    </MenuList>
                  </Menu>
                  <Button
                    borderRadius="full"
                    variant="solid"
                    bg="purple.700"
                    leftIcon={<AddIcon />}
                    size="xs"
                    iconSpacing={0}
                    onClick={handleAddFilter}
                  ></Button>
                </>
              )}
            </>
          )}
        </HStack>
        {/* END FILTER SECTION */}

        {/* EXCLUDE FILTER SECTION */}
        <Menu closeOnSelect={false} preventOverflow={true}>
            <MenuButton
              as={Button}
              width="100%"
              rightIcon={<ChevronDownIcon />}
              fontSize="sm"
              border="1px"
              borderColor="gray.300"
              color="black"
              bg="bg.surface"
              borderRadius="md"
              fontWeight="normal"
              _hover={{ bg: "purple.50" }}
              justifyContent="space-between"
              textAlign="left"
            >
              Exclude attributes
            </MenuButton>
            <MenuList maxHeight="200px" overflowY="auto" ml={-6}
              sx={{
                marginLeft: "5%", 
              }}>
              {removableEdges &&
                removableEdges
                  .filter(
                    (option) =>
                      filters &&
                      !filters.some((filter) => filter.name === option)
                  )
                  .map((option: string, index: number) => (
                    <MenuItem key={index} minH="40px" zIndex={10}>
                      <Checkbox
                        isChecked={excludedAttributeNames.includes(option)}
                        onChange={() => handleCheckboxChange(option)}
                        colorScheme="purple"
                      >
                        {option}
                      </Checkbox>
                    </MenuItem>
                  ))}
            </MenuList>
        </Menu>
         {/* END EXCLUDE FILTER SECTION */}
        </VStack>
    </VStack>
  );
};

export default ContextFilter;