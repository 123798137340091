import React, { useCallback } from "react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import { NavLink as RouterLink } from "react-router-dom";
import logo from "../../assets/logo.png";
import { SettingsIcon } from "@chakra-ui/icons";
import {
  useDisclosure,
  Box,
  Flex,
  IconButton,
  useColorModeValue,
  Avatar,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  keyframes,
  ButtonGroup,
  Button,
  Circle,
  HStack,
  Link as ChakraLink,
  Drawer,
  DrawerBody,
  DrawerContent,
  Stack,
  DrawerCloseButton,
  DrawerOverlay,
  Text,
  Icon,
  Divider,
} from "@chakra-ui/react";
import { useUser } from "../../context/UserContext";
import { FiSearch } from "react-icons/fi";
import { TbLogout2 } from "react-icons/tb";

interface NavLink {
  to: string;
  text: string;
}

const rotateAnimation = keyframes`
  0% { transform: rotate(0deg); }
  80% { transform: rotate(0deg); }
  100% { transform: rotate(120deg); }
`;
// Define the props interface
interface AvatarWithMemoProps {
  src: string;
  animation: string;
  name: string;
}

// Properly typed memoized Avatar component
const AvatarWithMemo: React.FC<AvatarWithMemoProps> = React.memo(
  ({ src, animation, name }) => {
    return (
      <Avatar
        size="xs"
        src={src}
        animation={animation}
        name={name}
        color="black"
      />
    );
  }
);

// export default AvatarWithMemo;

const WithSubnavigation: React.FC = () => {
  const { username } = useUser();
  const animation = `${rotateAnimation} 3s linear infinite`;
  const drawerDisclosure = useDisclosure();
  const navLinks: NavLink[] = [
    { to: "/create", text: "Create" },
    ...(process.env.REACT_APP_SHOW_ANALYSE_PAGE === "true"
      ? [{ to: "/analyze", text: "Analyze" }]
      : []),
    { to: "/export", text: "Explore" },
    // { to: "/metric", text: "Metrics" },
  ];

  const { logout } = useUser();
  const handleLogout = useCallback(() => {
    logout();
  }, [logout]);

  return (
    <Box>
      <Flex
        bg={useColorModeValue("purple.700", "purple.700")}
        color={useColorModeValue("fg.default", "white")}
        py={2}
        px={9}
        borderBottom={1}
        borderStyle={"solid"}
        borderColor={useColorModeValue("gray.200", "gray.900")}
        align={"center"}
        justify={"space-between"}
        wrap="nowrap"
      >
        <HStack justifyContent="space-between" align="center" ml={7} w="100%">
          <HStack spacing={7}>
            <RouterLink to="/">
              <Circle bg="purple.100" color="fg.default" p={1}>
                <AvatarWithMemo src={logo} animation={animation} name="Anko" />
              </Circle>
            </RouterLink>
            <Flex ml={9} align="center" gap={3}>
              <ButtonGroup
                size="lg"
                variant="text.accent"
                colorScheme="gray"
                spacing={9}
                display={{ base: "none", lg: "flex" }}
              >
                {navLinks.map((link, index) => (
                  <ChakraLink
                    as={RouterLink}
                    key={index}
                    to={link.to}
                    position="relative"
                    px={3}
                    py={1}
                    fontSize="lg"
                    fontWeight="medium"
                    color="white"
                    _hover={{ textDecoration: "none", color: "white" }}
                    _after={{
                      content: `""`,
                      position: "absolute",
                      width: "0",
                      height: "2px",
                      bottom: "0px",
                      left: "50%",
                      bg: "white",
                      transition: "width 0.3s ease",
                      transform: "translateX(-50%)",
                    }}
                    _activeLink={{
                      // This is for active link styling
                      color: "white", // Custom color for active link
                      fontWeight: "bold",
                      _after: {
                        width: "75%",
                        left: "50%",
                        transform: "translateX(-50%)",
                      },
                    }}
                    sx={{
                      "&:hover::after": {
                        width: "75%",
                        left: "50%",
                        transform: "translateX(-50%)",
                      },
                    }}
                  >
                    <Button>
                      <Text m={0} fontWeight="600">
                        {link.text}
                      </Text>
                    </Button>
                  </ChakraLink>
                ))}
              </ButtonGroup>
            </Flex>
          </HStack>

          <HStack spacing={9}>
            {/* Avatar and user menu */}
            <ButtonGroup variant="tertiary.accent" spacing="1">
              <IconButton
                icon={<FiSearch />}
                aria-label="Search"
                display={{ base: "flex", lg: "none" }}
                isRound
              />
              <Menu>
              <MenuButton>
                <Avatar
                  size="sm"
                  name={username ? username : "Anko"}
                  bg="red.700"
                  borderColor="#fff2f8"
                  borderWidth="2px"
                  color="white"
                  fontWeight="bold"
                />
              </MenuButton>

                <MenuList>
                <Text
                    mb={2}
                    color="black"
                    fontSize="md"
                    textAlign="left"
                    ml={2}
                    fontWeight={500}
                  >
                   {username ? username : "Unknown User"}
                  </Text>
                  <Divider my={1}/> 
                  <MenuItem
                    as={RouterLink}
                    to="/metric"
                    icon={<Icon as={SettingsIcon} boxSize={5}/>}
                    color="black"
                    _hover={{
                      textDecoration: "none", // Disable underline or any decoration
                      color: "black"
                    }}
                  >
                    Setup
                  </MenuItem>
                  <MenuItem
                    onClick={handleLogout}
                    icon={<Icon as={TbLogout2} boxSize={5}/>}
                    color="black"
                  >
                    Logout
                  </MenuItem>
                </MenuList>
              </Menu>
            </ButtonGroup>
          </HStack>
        </HStack>

        <IconButton
          aria-label="Open Menu"
          size="md"
          mr={2}
          bg="#4e1e6c"
          color="#4e1e6c"
          icon={
            drawerDisclosure.isOpen ? (
              <CloseIcon />
            ) : (
              <HamburgerIcon color="white" />
            )
          }
          display={{ md: "none" }}
          onClick={drawerDisclosure.onToggle}
        />
      </Flex>

      <Drawer
        isOpen={drawerDisclosure.isOpen}
        placement="top"
        onClose={drawerDisclosure.onClose}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody mt="16">
            <Stack spacing="6" align="stretch">
              {navLinks.map((item, index) => (
                <Button
                  key={index}
                  as={RouterLink}
                  to={item.to}
                  size="lg"
                  variant="text"
                  colorScheme="gray"
                >
                  {item.text}
                </Button>
              ))}
              <Button
                onClick={handleLogout}
                bg="#4e1e6c"
                color="white"
                _hover={{ bg: "#4e1e6c", color: "white" }}
              >
                Logout
              </Button>
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default WithSubnavigation;
