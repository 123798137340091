import React, { useEffect, useState } from "react";
import { createMetric, getMetricById, updateMetric } from "../../services/MetricService";
import {
  ColumnInfo,
  FilterColumn,
  Header,
  Metric,
  ModelInfo,
  ObservationColumn,
  FormData,
  FormatProto,
} from "../Interfaces";
import { getColumnValues, getModelById } from "../../services/DataModelService";
import EditMetricDefinition from "../../components/configuration/EditMetricDefinition";
import showToast from "../../hooks/useCustomToast";
import { ColumnType } from "../../utils/enum";

interface EditMetricDefinitionContainerProps {
  createMetricFlag: boolean;
  modelInfo: ModelInfo|null;
  metricHeader: Header|null;
  onClose: ()=> void;
}
const EditMetricDefinitionContainer: React.FC<
  EditMetricDefinitionContainerProps
> = ({ createMetricFlag, modelInfo, metricHeader, onClose }) => {
  
  const [metricName, setMetricName] = useState<string>("");
  const [columnNames, setColumnNames] = useState<string[]>([]);
  const [selectedColumnValues, setSelectedColumnValues] = useState<string[]>([]);
  const [metricDescription, setMetricDescription] = useState<string>("");
  const [columns, setColumns] = useState<ColumnInfo[]>([]);
  const [selectedObservationColumn, setSelectedObservationColumn] =
    useState<Header | null>(null);
  const [selectedFilterColumn, setSelectedFilterColumn] =
    useState<ColumnInfo | null>(null);
  const [selectedAggregation, setSelectedAggregation] = useState<string>("");
  const [selectedOperator, setSelectedOperator] = useState<string>("");
  const [selectedSorting, setSelectedSorting] = useState<string>("");
  const [inputValue, setInputValue] = useState<string>("");
  const [selectedObservationColumns, setSelectedObservationColumns] = useState<
    ObservationColumn[]
  >([]);
  const [selectedFilterColumns, setSelectedFilterColumns] = useState<
    FilterColumn[]
  >([]);
  const [type, setType] = useState<string|undefined>("");
  const [polarity, setPolarity] = useState<string|undefined>("");
  const [currency, setCurrency] = useState<string|undefined>("");
  const [percentage, setPercentage] = useState<boolean>(false);
  const [suffix, setSuffix] = useState<string|undefined>("");
  const hasContent = selectedObservationColumns.length > 0 || selectedFilterColumns.length > 0;
  const [displayType, setDisplayType] = useState<string>("Regular");
  const [selectedMaterialColumn, setSelectedMaterialColumn] = useState<ColumnInfo | null>(null);
  const [decimalPlaces, setDecimalPlaces] = useState<number | null>(0);

  useEffect(() => {
    if(!createMetricFlag && metricHeader){
      const fetchMetricDefinition = async () => {
      const response: Metric = await getMetricById(metricHeader.id!, false);
        if (response !== null) {
          setMetricName(response?.header?.name!);
          setMetricDescription(response?.header?.description!);
          setSelectedObservationColumns(response.definition.observation_columns);
          setSelectedFilterColumns(response.definition.filters);
          setCurrency(response?.format?.currency_code);
          setDecimalPlaces(response?.format?.num_decimals ? response?.format?.num_decimals: 0);

          if(response.material_column_header) {
            setSelectedMaterialColumn({header: response.material_column_header});
          }
          if(response?.format?.percentage === true){
            setPercentage(true);
            setDisplayType("Percentage");
          } 
          else if(response?.format?.currency_code) setDisplayType("Currency");
          else if(response?.format?.suffix) setDisplayType("Regular");
          
          setSuffix(response?.format?.suffix);
          setType(response?.type);
          setPolarity(response?.polarity);
        }
      };
      fetchMetricDefinition();
    }
  }, []);

  useEffect(() => {
    const fetchColumnFromModelId = async () => {
      let response: ModelInfo|null;
      (!createMetricFlag && metricHeader) ? response =  await getModelById(metricHeader.owner_id!)
      : response = modelInfo;
      if (response !== null) {
        response &&
          response.columns &&
          response.columns.map((column: ColumnInfo) => {
            setColumns((prevColumns) => [...prevColumns, column]);
          });
      }
    };
    fetchColumnFromModelId();
  }, []);

  // Function to filter attribute columns
  const filterAttributeColumns: ColumnInfo[] = columns && columns.filter(column => column.column_type === ColumnType.MEASURE);

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMetricName(event.target.value);
  };

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setMetricDescription(event.target.value);
  };

  const handleObservationColumnChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const columnId = event.target.value;
    const column = columns && columns.find((c) => c.header?.id === columnId);
    setSelectedObservationColumn(column?.header || null);
  };

  const handleFilterColumnChange = async(
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const columnId = event.target.value;
    const column = columns && columns.find((c) => c.header?.id === columnId);
    if(column?.column_type === ColumnType.ATTRIBUTE){
    const columnValues = await getColumnValues(columnId);
    setColumnNames(columnValues);
    }
    setSelectedFilterColumn(column || null);
    setSelectedColumnValues([]);
  };

  const handleAggregationChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedAggregation(event.target.value);
  };

  const handleOperatorChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedOperator(event.target.value);
  };

  const handleSortingChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedSorting(event.target.value);
  };

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    setSelectedColumnValues([]);
  };

  const handleAddObservationColumn = () => {
    if (selectedObservationColumn && selectedAggregation) {
      const newObservationColumn = {
        logical_column_header: selectedObservationColumn,
        aggregation: selectedAggregation,
        sort_by: selectedSorting,
      };
      setSelectedObservationColumns((prev) => [...prev, newObservationColumn]);
      setSelectedObservationColumn(null);
      setSelectedAggregation("");
      setSelectedSorting("");
    } else {
      showToast("Please select all fields.", "", "error");
    }
  };

  const handleAddFilterColumn = () => {
    if (selectedFilterColumn && selectedOperator && (inputValue || selectedColumnValues)) {
      const values = inputValue ? inputValue.split(",").map((item) => item.trim()) : selectedColumnValues;
      const newObservationColumn: FilterColumn = {
        logical_column_header: selectedFilterColumn.header!,
        operator: selectedOperator,
        values: values,
      };
      setSelectedFilterColumns((prev = []) => [...prev, newObservationColumn]);
      setSelectedFilterColumn(null);
      setSelectedOperator("");
      setInputValue("");
      setSelectedColumnValues([]);
    } else {
      showToast("Please select all fields.", "", "error");
    }
  };

  //function to handle select multiple values
  const handleToggle = (value: string) => {
    const currentIndex = selectedColumnValues.indexOf(value);
    let newSelected = [...selectedColumnValues];

    if (currentIndex === -1) {
      newSelected.push(value);
    } else {
      newSelected.splice(currentIndex, 1);
    }

    setSelectedColumnValues(newSelected);
  };

  const handleDeleteObservationColumn = (indexToDelete: number) => {
    setSelectedObservationColumns((prevColumns) =>
      prevColumns.filter((_, index) => index !== indexToDelete)
    );
  };

  const handleDeleteFilterColumn = (indexToDelete: number) => {
    setSelectedFilterColumns((prevColumns) =>
      prevColumns.filter((_, index) => index !== indexToDelete)
    );
  };

  const handleTypeChange = (value: string) => {
    setType(value);
  };

  const handlePolarityChange = (value: string) => {
    setPolarity(value);
  };

  const handleCurrencyChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setCurrency(event.target.value);
  };

  const handlePercentageChange = (value: boolean) => {
    setPercentage(value);
    setCurrency("");
    setSuffix("");
  };

  const handleSuffixChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSuffix(event.target.value);
    setCurrency("");
    setPercentage(false);
  };

  const handleMaterialColumn = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const materialColumnId = event.target.value;
    const column: ColumnInfo = columns && columns.find((c) => c.header?.id === materialColumnId)!;
    setSelectedMaterialColumn(column);
  }

  const submit = async () => {
    const observationColumns: ObservationColumn[] =
      selectedObservationColumns &&
      selectedObservationColumns.map((observation) => {
        return {
          logical_column_header: {
            id: observation.logical_column_header.id,
          },
          aggregation: observation.aggregation,
          sort_by: observation.sort_by || undefined,
        };
      });
    const filters: FilterColumn[] =
      selectedFilterColumns &&
      selectedFilterColumns.map((filter) => {
        return {
          logical_column_header: {
            id: filter.logical_column_header.id || "",
          },
          operator: filter.operator || "",
          values: filter.values ? filter.values : [], 
        };
      });

    let format: FormatProto = {
      currency_code: currency,
      percentage: percentage,
      suffix: suffix,
      num_decimals: decimalPlaces? decimalPlaces: 0,
    }

    //empty checks on format 
    if(!format.currency_code){
      delete format.currency_code;
    }
    if(!format.suffix){
      delete format.suffix;
    }
    if(!percentage){
      delete format.percentage;
    }

    const formData: FormData = {
      model_id: (!createMetricFlag) ? String(metricHeader?.owner_id!) : modelInfo?.header?.id!,
      metrics: [
        {
          header: { ...metricHeader, name: metricName },
          definition: {
            observation_columns: observationColumns,
            filters: filters,
          },
          format: format,
          polarity: polarity,
          type: type,
          material_column_header: selectedMaterialColumn?.header,
        },
      ],
      update_edges: false,
    };

    //empty checks on metrics
    formData.metrics.forEach(metric => {
      if(metric.polarity === "") 
          delete metric.polarity; 
      if(metric.type === "") 
          delete metric.type; 
      if(Object.keys(metric.format!).length === 0) 
         delete metric.format;
    })

    if(!createMetricFlag){
      try {
        // API call to create the metric
        const responseData = await updateMetric(formData);
        if(responseData){
          showToast("Metric definition is updated","" , "success")
          onClose();
        }
        console.log("responseData", responseData);
      } catch (error) {
        showToast("Metric definition is not updated", "", "error");
      }
    }
    else{
      try{
        if(!metricName.length){
          showToast("Metric name cannot be empty", "", "error");
          return;
        }
        const responseData = await createMetric(formData);
        if(responseData){
          showToast("Metric definition is created", "", "success");
          onClose();
          window.location.reload();
        }     
      } catch (error){
        showToast("Metric definition is not updated", "", "error");
      }
    }
  };

  return (
    <EditMetricDefinition
      metricName={metricName}
      metricDescription={metricDescription}
      selectedObservationColumn={selectedObservationColumn!}
      columns={columns}
      selectedAggregation={selectedAggregation}
      selectedFilterColumn={selectedFilterColumn!}
      selectedSorting={selectedSorting}
      selectedObservationColumns={selectedObservationColumns}
      selectedFilterColumns={selectedFilterColumns}
      selectedOperator={selectedOperator}
      inputValue={inputValue}
      handleNameChange={handleNameChange}
      handleDescriptionChange={handleDescriptionChange}
      handleObservationColumnChange={handleObservationColumnChange}
      handleFilterColumnChange={handleFilterColumnChange}
      handleAggregationChange={handleAggregationChange}
      handleOperatorChange={handleOperatorChange}
      handleSortingChange={handleSortingChange}
      handleValueChange={handleValueChange}
      handleAddObservationColumn={handleAddObservationColumn}
      handleAddFilterColumn={handleAddFilterColumn}
      handleDeleteObservationColumn={handleDeleteObservationColumn}
      handleDeleteFilterColumn={handleDeleteFilterColumn}
      type={type}
      polarity={polarity}
      submit={submit}
      currency={currency}
      suffix={suffix}
      handleTypeChange={handleTypeChange}
      handlePolarityChange={handlePolarityChange}
      handleCurrencyChange={handleCurrencyChange}
      handlePercentageChange={handlePercentageChange}
      handleSuffixChange={handleSuffixChange}
      hasContent={hasContent}
      setDisplayType={setDisplayType}
      displayType={displayType}
      columnNames={columnNames}
      selectedColumnValues={selectedColumnValues}
      handleToggle={handleToggle}
      handleMaterialColumn={handleMaterialColumn}
      selectedMaterialColumn={selectedMaterialColumn}
      filterAttributeColumns={filterAttributeColumns}
      decimalPlaces={decimalPlaces}
      setDecimalPlaces={setDecimalPlaces}
    />
  );
};

export default EditMetricDefinitionContainer;
