import React from "react";
import { Grid, Box } from "@chakra-ui/react";
import JobManagerContainer from "../../containers/batch/JobManagerContainer";
import {
  useBatchContext,
} from "../../context/batch/BatchContext";
import CreateStepContainer from "../../containers/batch/CreateStepContainer";
import JobStepsContainer from "../../containers/batch/JobStepsContainer";
import StatusPage from "../../containers/batch/StatusPage";
import StepConfigureContainer from "../../containers/batch/StepConfigureContainer";

const CreatePage: React.FC = () => {
  const { selectedJob, 
    selectedJobId,  
    setSelectedRunHeader, 
    setShowStatusPage,
    showStatusPage
  } = useBatchContext();

  // Function to navigate back from StatusPage
  const handleBackToJobSteps = () => {
    setSelectedRunHeader(null);
    setShowStatusPage(false);
  };

  return (
    <>
      {selectedJob && selectedJobId ? (
        <Grid templateColumns="1fr 1fr 5fr" templateRows="1fr" height="100vh" gap={1} bg="gray.100">
          {/* Sidebar - 1/3rd of the screen */}
          <Box bg="bg.surface" boxShadow="lg" borderRadius="lg" p={1} height="94vh">
            <JobManagerContainer  />
          </Box>

          {/* JobStepsContainer */}
          <Box
            p={1}
            overflowY="auto"
            bg="bg.surface"
            boxShadow="lg"
            borderRadius="lg"
            height="94vh"
          >
            <JobStepsContainer />
          </Box>
          {showStatusPage ? (
            // Full 2/3rd space for StatusPage
            <Box bg="bg.surface" boxShadow="lg" borderRadius="lg" p={1} mr={1} height="94vh" overflowY="auto">
              <StatusPage onBack={handleBackToJobSteps} />
            </Box>
          ) : (
            <Grid templateRows="1fr 4fr" height="94vh" gap={0} mr={1}>
              {/* StepConfigureContainer */}
              <Box
                p={1}
                overflowY="auto"
                bg="bg.surface"
                boxShadow="lg"
                borderTopRadius="lg"
                height="30vh"
              >
                <StepConfigureContainer />
              </Box>

              {/* Top section - CreateStepContainer */}
              <Box bg="bg.surface" boxShadow="lg" borderBottomRadius="lg" p={1} height="64vh">
                <CreateStepContainer />
              </Box>
            </Grid>
          )}
        </Grid>
      ) : (
        <Grid templateColumns="1fr 6fr" templateRows="1fr" height="100vh" gap={1} bg="gray.100">
          {/* Sidebar - 1/3rd of the screen */}
          <Box bg="bg.surface" boxShadow="lg" borderRadius="lg" p={1} height="94vh">
            <JobManagerContainer />
          </Box>

          {showStatusPage ? (
            // Full 2/3rd space for StatusPage
            <Box bg="bg.surface" boxShadow="lg" borderRadius="lg" p={1} height="94vh" overflowY="auto">
              <StatusPage onBack={handleBackToJobSteps} />
            </Box>
          ) : (
            <Grid templateRows="1fr 4fr" height="94vh" gap={0}>
              {/* StepConfigureContainer */}
              <Box
                p={1}
                overflowY="auto"
                bg="bg.surface"
                boxShadow="lg"
                borderTopRadius="lg"
                height="30vh"
              >
                <StepConfigureContainer />
              </Box>

              {/* Top section - CreateStepContainer */}
              <Box bg="bg.surface" boxShadow="lg" borderBottomRadius="lg" p={1} height="64vh">
                <CreateStepContainer />
              </Box>
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};

export default CreatePage;