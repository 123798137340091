import { useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import HomePage from "./pages/HomePage";
import Configuration from "./pages/metric/Configuration";
import Metric from "./pages/metric/Metric";
import MetricKnowledgeGraph from "./pages/metric/MetricKnowledgeGraph";
import CommentaryPage from "./pages/commentary/CommentaryPage";
import ChatPage from "./pages/chat/ChatPage";
import { useUser } from "./context/UserContext";
import UserLogin from "./pages/user_component/UserLogin";
import LibraryPage from "./pages/library/LibraryPage";
import Configuration1 from "./pages/configuration/ConfigurationPage";
import AnalysePage from "./pages/analyse/AnalysePage";
import ExportPage from "./pages/export/ExportPage";
import Layout from "./components/layout/Layout";
import CreatePage from "./pages/create/CreatePage";
import { BatchContextProvider } from "./context/batch/BatchContext";

function App() {
  // Access isLoggedIn and reload function from the user context
  const { isLoggedIn, reload } = useUser();

  // reload function to check user session when the component mounts
  useEffect(() => {
    reload();
  }, []);

  const authenticatedRoutes = (
    <BatchContextProvider>
      <Layout>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/metric1" element={<Metric />} />
          <Route path="/configuration" element={<Configuration />} />
          <Route path="/mkg" element={<MetricKnowledgeGraph />} />
          <Route path="/commentary" element={<CommentaryPage />} />
          <Route path="/chat" element={<ChatPage />} />
          <Route path="/library" element={<LibraryPage />} />
          <Route path="/metric" element={<Configuration1 />} />
          <Route path="/analyze/:id?" element={<AnalysePage />} />
          <Route path="/export" element={<ExportPage />} />
          <Route path="/create" element={<CreatePage />} />
          <Route path="*" element={<Navigate to="/" />} />{" "}
        </Routes>
      </Layout>
    </BatchContextProvider>
  );

  const unauthenticatedRoutes = (
    <Routes>
      <Route path="/*" element={<UserLogin />} />{" "}
    </Routes>
  );

  return (
    <div className="App">
      {isLoggedIn ? authenticatedRoutes : unauthenticatedRoutes}
    </div>
  );
}

export default App;
