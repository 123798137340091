import React from 'react'
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
  VStack,
  Button,
  Box,
  Select,
  Flex,
  Spacer,
} from '@chakra-ui/react'
import { ColumnInfo, Header } from '../../../containers/Interfaces'

interface AddDescriptionProps {
  header: Header
  columns: ColumnInfo[]
  handleHeaderChange: (property: string, value: string) => void
  handleColumnChange: (index: number, property: string, value: string) => void
  handleColumnHeaderChange: (
    index: number,
    property: string,
    value: string,
  ) => void
  handleSubmit: () => void
  updateFlag: boolean
  buttonLoader: boolean
}

const AddDescription: React.FC<AddDescriptionProps> = ({
  header,
  columns,
  handleHeaderChange,
  handleColumnChange,
  handleColumnHeaderChange,
  handleSubmit,
  updateFlag,
  buttonLoader
}) => {
  return (
    <Box mt={4} ml={2}>
    <Flex mb={6} direction="column" overflow="auto">
      <Flex>
        <VStack spacing={4} flex="1">
          <Input
            placeholder="Header Name"
            value={header.name}
            onChange={(e) => handleHeaderChange('name', e.target.value)}
          />
          <Input
            placeholder="Header Description"
            value={header.description || ''}
            onChange={(e) => handleHeaderChange('description', e.target.value)}
          />
        </VStack>
        <Spacer />
        {updateFlag ? (
          <Button colorScheme="purple" bg="purple" _hover={{bg:"purple"}} onClick={handleSubmit} minW="90px" mr={8} isLoading={buttonLoader}>Update</Button>
        ) : (
          <Button colorScheme="purple" bg="purple" _hover={{bg:"purple"}} onClick={handleSubmit} minW="90px" mr={8} mt={2} isLoading={buttonLoader}>Create</Button>
        )}
      </Flex>
      <Table size="sm" mt={4}>
        <Thead>
          <Tr>
            <Th>Column Name</Th>
            <Th>Description</Th>
            <Th>Data Type</Th>
            <Th>Column Type</Th>
            <Th>Default Aggregation</Th>
          </Tr>
        </Thead>
        <Tbody>
          {columns.map((column, index) => (
            <Tr key={index}>
              <Td>
                <Input
                  value={column.header?.name || ''}
                  onChange={(e) => handleColumnHeaderChange(index, 'name', e.target.value)}
                />
              </Td>
              <Td>
                <Input
                  value={column.header?.description || ''}
                  onChange={(e) => handleColumnHeaderChange(index, 'description', e.target.value)}
                />
              </Td>
              <Td>
                <Select
                  value={column.data_type || ''}
                  onChange={(e) => handleColumnChange(index, 'data_type', e.target.value)}
                >
                  <option value="VARCHAR">VARCHAR</option>
                  <option value="BOOLEAN">BOOLEAN</option>
                  <option value="INT32">INT32</option>
                  <option value="INT64">INT64</option>
                  <option value="DOUBLE">DOUBLE</option>
                  <option value="DATE">DATE</option>
                  <option value="DATETIME">DATETIME</option>
                </Select>
              </Td>
              <Td>
                <Select
                  value={column.column_type || ''}
                  onChange={(e) => handleColumnChange(index, 'column_type', e.target.value)}
                >
                  <option value="ATTRIBUTE">ATTRIBUTE</option>
                  {(column.data_type === 'INT32' || column.data_type === 'INT64' || column.data_type === 'DOUBLE') && ( 
                  <option value="MEASURE">MEASURE</option>)}
                  {(column.data_type === 'DATE' || column.data_type === 'DATETIME') && (
                  <option value="TEMPORAL">TEMPORAL</option>
                  )}
                </Select>
              </Td>
              <Td>
                {column.column_type === 'MEASURE' ? (
                  <Select
                    value={column.default_aggregation}
                    onChange={(e) => handleColumnChange(index, 'default_aggregation', e.target.value)}
                  >
                    <option value="SUM">SUM</option>
                    <option value="AVERAGE">AVERAGE</option>
                    <option value="COUNT">COUNT</option>
                    <option value="UNIQUE_COUNT">UNIQUE_COUNT</option>
                    <option value="GROWTH">GROWTH</option>
                  </Select>
                ) : (
                  <Select value="" disabled>
                    <option value="">None</option>
                  </Select>
                )}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Flex>
  </Box>
  )
}

export default AddDescription
