import React from "react";
import { Grid, Box } from "@chakra-ui/react";
import FilterTreePanelContainer from "../../containers/export/FilterTreePanelContainer";
import { ExportContextProvider } from "../../context/export/ExportContext";
import StoryPanelContainer from "../../containers/export/StoryPanelContainer";
import PagesPanelContainer from "../../containers/export/PagesPanelContainer";

const ExportPage: React.FC = () => {
  return (
    <ExportContextProvider>
        <Grid height="100%" templateColumns="1fr 1fr 4fr" gap={1}>
          <Box bg="bg.surface" boxShadow="lg" borderRadius="lg" overflowY="hidden">
            <StoryPanelContainer />
          </Box>
          <Box bg="bg.surface" boxShadow="lg" borderRadius="lg" overflowY="hidden">
            <PagesPanelContainer />
          </Box>
          <Box bg="bg.surface" boxShadow="lg" borderRadius="lg" overflowY="auto">
            <FilterTreePanelContainer />
          </Box>
        </Grid>
    </ExportContextProvider>
  );
};

export default ExportPage;
