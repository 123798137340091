import React, { useState, useEffect, useRef } from 'react'
import CommentaryCard from '../../components/commentary/CommentaryCard'
import { AnalysisTreeResponse, Commentary } from '../commentary/Interfaces'
import {
  Box,
  Flex,
  SkeletonText,
  Spinner,
  VStack,
  Text,
} from '@chakra-ui/react'
import { fetchAnalysisTreeById } from '../../services/AnalysisTreeServices'

interface LibraryMainContentContainerProps {
  id: string; // Analysis tree Id
  setUpdateStarredCommentary: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * Represents the main content container for the library page.
 * Fetches and displays commentary data based on the provided ID.
 * Handles loading states and user interactions.
 */
const LibraryMainContentContainer: React.FC<LibraryMainContentContainerProps> = ({
  id,
  setUpdateStarredCommentary
}) => {
  
  // State variables
  const [commentaryArray, setCommentaryArray] = useState<Commentary[]>([])
  const [commentaryLoader, setCommentaryLoader] = useState<boolean>(false)
  const [treeLoader, setTreeLoader] = useState<boolean>(true)
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const [starredIndexLibrary, setStarredIndexLibrary] = useState<number>(-1)
  const [starredConversationId, setStarredConversationId] = useState<string>("")
  const [conversationId, setConversationId] = useState<string>('')
  const [copiedText, setCopiedText] = useState<string>('')
  const [copy, setCopy] = useState<boolean>(false)
  const [isDiff, setIsDiff] = useState<boolean>(false)
  const [tree, setTree] = useState<AnalysisTreeResponse|null>()
  const markdownRef = useRef<HTMLDivElement>(null)
  const [isFailed, setIsFailed] = useState<boolean>(false)
  const [hideCommentaryCardHeaderButton, setHideCommentaryCardHeaderButton] =
    useState<boolean>(false);

  /**
   * Handles copying text from the commentary.
   */
  const handleCopy = () => {
    setCopy(true)
    const element = markdownRef.current
    if (element) {
      const originalBg = element.style.backgroundColor
      element.style.backgroundColor = 'transparent'
      const range = document.createRange()
      range.selectNodeContents(element)
      const selection = window.getSelection()
      if (selection) {
        selection.removeAllRanges()
        selection.addRange(range)
        document.execCommand('copy')
        selection.removeAllRanges()
        element.style.backgroundColor = originalBg
      }
    }
  }

  /**
   * Toggles the diff checker.
   */
  const handleDiffChecker = () => {
    setIsDiff(!isDiff)
  }

  /**
   * Fetches the analysis tree
   */

  const fetchAnalysisTreeAndCommentary = async () => {
    try {
      setTreeLoader(true);
      setCommentaryLoader(true);
      setIsFailed(false);
      setStarredConversationId("");
      setCommentaryArray([]);
      setActiveIndex(0);
      setHideCommentaryCardHeaderButton(true);
      const treeData = await fetchAnalysisTreeById(
        id, 
        true  /*include nodes*/,  
        false /*include Starred Commentary*/, 
        true  /*include All Commentary*/,
        false /*include Saved Commentary*/);
  
      if (!treeData) {
        setTreeLoader(false);
        setCommentaryLoader(false);
        setTree(null);
        setIsFailed(true);
        return false;
      }
  
      setTree(treeData);
  
      if (treeData.commentaries) {
        const commentaries: Commentary[] = treeData.commentaries.map(
          (conversation: Commentary, index: number) => {
            const {
              conversation_header,
              commentary,
              insights,
              validation,
            } = conversation;
  
            if (conversation_header.status_flag === "STARRED") {
              setStarredConversationId(conversation_header.id!);
              setStarredIndexLibrary(index);
              setActiveIndex(index);
            }
  
            return {
              conversation_header: conversation_header,
              commentary: commentary,
              insights: insights ? insights : '',
              validation: 'VALID HIGH',
            };
          }
        ) || [];
        setCommentaryArray(commentaries);
        setHideCommentaryCardHeaderButton(false);
      } else {
        setIsFailed(true);
      }
  
      setTreeLoader(false);
      setCommentaryLoader(false);
      return true;
    } catch (error) {
      setTreeLoader(false);
      setCommentaryLoader(false);
      setIsFailed(true);
      return false;
    }
  }
  
  useEffect(() => {
    const loadTreeAndCommentary = async () => {
      await fetchAnalysisTreeAndCommentary();
    };
  
    loadTreeAndCommentary();
  }, [id]); // Analysis tree id
  

  return (
    <VStack spacing={3} align="stretch">
      {/* Render skeletons when loading */}
      {treeLoader && (
        <Box p="6" boxShadow="lg" bg="gray.50" borderRadius="xl">
          <Flex
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <Spinner size="lg" color="#4e1e6c" thickness="3px" />
            <Text mt="2" fontSize="lg" fontWeight="semibold">
              Fetching Data...
            </Text>
          </Flex>
          <SkeletonText mt="4" noOfLines={6} spacing="4" />
        </Box>
      )}

      {/* Render commentary card when data is loaded */}
      {!treeLoader && tree && (
        <CommentaryCard
          libraryPage={true}
          libraryStarIndex={starredIndexLibrary}
          starredConversationId={starredConversationId}
          setUpdateStarredCommentary={setUpdateStarredCommentary}
          tree={tree!}
          id={id}
          commentaryArray={commentaryArray}
          setCommentaryArray={setCommentaryArray}
          isLoading={commentaryLoader}
          setIsLoading={setCommentaryLoader}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
          conversationId={conversationId}
          setConversationId={setConversationId}
          copy={copy}
          setCopy={setCopy}
          setCopiedText={setCopiedText}
          isDiff={isDiff}
          setIsDiff={setIsDiff}
          handleCopy={handleCopy}
          handleDiffChecker={handleDiffChecker}
          markdownRef={markdownRef}
          hideCommentaryCardHeaderButton={hideCommentaryCardHeaderButton}
          isFailed={isFailed}
          fetchCommentary={fetchAnalysisTreeAndCommentary}
        />
      )}
    </VStack>
  )
}

export default LibraryMainContentContainer
