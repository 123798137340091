import React from "react";
import {
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Box,
  Tbody,
  Text,
  TableContainer,
} from "@chakra-ui/react";
import {
  ColumnValuesList,
  Node,
} from "./../../containers/commentary/Interfaces";

interface DataTableProps {
  data: Node;
}

const DataTable: React.FC<DataTableProps> = ({ data }) => {
  console.log("DataTable", data);
  const columnValuesMap: Map<string, ColumnValuesList> = new Map();

  // Check if data contains analysed_data
  if (data && data.analysed_data) {
    // Iterate over analysed_data.column_values_list array
    data.analysed_data.column_values_list.forEach((columnValues) => {
      // Use column_id as the key and the whole ColumnValuesList object as the value
      if (
        columnValues.analysis_column_type !== "VARIANCE_CONTRIBUTION" &&
        columnValues.analysis_column_type !== "VARIANCE CONTRIBUTION"
      ) {
        columnValuesMap.set(columnValues.column_id, columnValues);
      }
    });

    // Check if data contains benchmark
    if (data && data.benchmark && data.benchmark.header) {
      const benchmarkHeaderId = data.benchmark.header.id!;
      const benchmarkHeaderName = data.benchmark.header.name;

      // Check if benchmark header ID is present in columnValuesMap
      if (columnValuesMap.has(benchmarkHeaderId)) {
        // Get the corresponding ColumnValuesList object
        const columnValues = columnValuesMap.get(benchmarkHeaderId)!;

        // Check if analysis_column_type is present
        if (columnValues.analysis_column_type) {
          // Replace the value with benchmark header name
          columnValues.analysis_column_type = benchmarkHeaderName;
        } else {
          // Add analysis_column_type with benchmark header name
          columnValues.analysis_column_type = benchmarkHeaderName;
        }
      }
    }
    if (
      data &&
      data.analysed_data.observation &&
      data.analysed_data.observation.content
    ) {
      const observationColumns =
        data.analysed_data.observation.content.observation_columns;

      // Iterate over observation columns
      observationColumns.forEach((observationColumn) => {
        const observationColumnId = observationColumn.column_id;
        const logicalColumnHeaderName =
          observationColumn.logical_column_header.name;

        // Check if observation column ID is present in columnValuesMap
        if (columnValuesMap.has(observationColumnId)) {
          // Get the corresponding ColumnValuesList object
          const columnValues = columnValuesMap.get(observationColumnId)!;

          // Check if analysis_column_type is present
          if (columnValues.analysis_column_type) {
            // Replace the value with observation column header name
            columnValues.analysis_column_type = logicalColumnHeaderName;
          } else {
            // Add analysis_column_type with observation column header name
            columnValues.analysis_column_type = logicalColumnHeaderName;
          }
        }
      });
    }
    columnValuesMap &&
      columnValuesMap.forEach((columnValues) => {
        // Check if analysis_column_type exists and remove underscores
        if (columnValues.analysis_column_type) {
          columnValues.analysis_column_type = columnValues.analysis_column_type
            .replace(/_/g, " ")
            .toUpperCase();
        }
        if (columnValues.analysis_column_type === "MELTED VARIABLE") {
          columnValues.analysis_column_type = "VARIABLE";
        } else if (columnValues.analysis_column_type === "MELTED VALUE") {
          columnValues.analysis_column_type = "VALUE";
        }

        // Check if analysis_column_type is not VARIABLE
        // Parse the data_value_json_array
        const parsedData = JSON.parse(columnValues.data_value_json_array);

        // Apply transformation to each value
        columnValues.parsed_data_value_json_array = parsedData.map(
          (value: number) => {        
            return value;
          }
        );
      });
  }

  console.log(
    "Updated Column Values Map:",
    columnValuesMap.get("d2d26c6a-4c36-4888-9b4c-72c1ddcc0267")
  );

  const transposedData: any[][] = [];
  if (data && data.analysed_data?.column_values_list) {
    data.analysed_data.column_values_list.forEach((columnValues) => {
      if (
        columnValues.analysis_column_type !== "VARIANCE CONTRIBUTION" &&
        columnValues.analysis_column_type !== "VARIANCE_CONTRIBUTION"
      ) {
        columnValues &&
          columnValues.parsed_data_value_json_array?.forEach(
            (item: any, index: number) => {
              if (!transposedData[index]) {
                transposedData[index] = [];
              }
              transposedData[index].push(item);
            }
          );
      }
    });
  }
  console.log(columnValuesMap);
  return (
    <>  
      <Box ml={3}>
        {data &&
          data.recommendation_strings &&
          data.recommendation_strings[0] && (
            <Box
              bg="white" // Background color
              color="black" // Text color
              p={4} // Padding
              borderRadius={5}
              alignSelf="left"
            >
              <Text fontSize="sm" fontWeight="bold" mb={3}>Analysis Step: </Text>
              <Text fontSize="sm" fontWeight="bold">{data.recommendation_strings[0]}</Text>
              <Text fontWeight="bold" mt={3} mb={0} fontSize="sm">
                Data:
              </Text>
            </Box>
          )}
      </Box>
      <TableContainer>
        <Table
          variant="simple"
          size="sm" // Consider using 'md' for better spacing
          fontSize="sm"
          w="auto"
          minW="500px"
          align="center"
        >
          <Thead>
            <Tr>
              {/* Render table headers */}
              {Array.from(columnValuesMap.values()).map((columnValues) => (
                <Th key={columnValues.column_id} color="#4e1e6c" fontSize="sm">
                  {columnValues.analysis_column_type}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {/* Render table rows */}
            {transposedData.map((row, rowIndex) => (
              <Tr key={rowIndex}>
                {row.map((column, colIndex) => {
                  // Check if column is a negative number
                  const isNegative = typeof column === "number" && column < 0;
                  // Check if column is within the range -3 to 3 (exclusive)
                  const isInRange =
                    typeof column === "number" && column >= -3 && column < 3;
                  // Get the absolute value of the number
                  const absoluteValue = Math.abs(column);
                  // Format number to US format with commas or as a percentage
                  let formattedNumber = column;
                  if (typeof column === "number") {
                    if (isInRange) {
                      formattedNumber = `${(absoluteValue * 100).toFixed(4)}%`;
                    } else {
                      formattedNumber = absoluteValue.toLocaleString("en-US");
                    }
                  }
                  // Determine the color based on the negative status
                  const color = isNegative ? "red" : "inherit";
                  return (
                    <Td key={colIndex} style={{ color: color }}>
                      {column != null
                        ? isNegative
                          ? `(${formattedNumber})`
                          : formattedNumber
                        : "null"}
                    </Td>
                  );
                })}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
};

export default DataTable;