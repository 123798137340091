import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";
import { Header, Metric } from "../containers/Interfaces";
import {
  AnalysisTreeRequest,
  Benchmark,
  BenchmarkFilter,
  Context,
  FilterColumn,
  TemporalColumn,
} from "../containers/commentary/Interfaces";
import { getMetricById } from "../services/MetricService";
import { getColumnValues, getModelById } from "../services/DataModelService";
import { v4 as uuidv4 } from "uuid";

interface SidebarContextType {
  metricInfoMap: { [key: string]: Metric };
  setMetricInfoMap: React.Dispatch<
    React.SetStateAction<{ [key: string]: Metric }>
  >;
  temporalColumnByMetricIdMap: { [key: string]: Header[] };
  setTemporalColumnByMetricIdMap: React.Dispatch<
    React.SetStateAction<{ [key: string]: Header[] }>
  >;
  contextByMetricMap: { [key: string]: Context[] };
  setContextByMetricMap: React.Dispatch<
    React.SetStateAction<{ [key: string]: Context[] }>
  >;
  benchmarkByMetricMap: { [key: string]: Benchmark[] };
  setBenchmarkByMetricMap: React.Dispatch<
    React.SetStateAction<{ [key: string]: Benchmark[] }>
  >;
  date: { [key: string]: string[] };
  setDate: React.Dispatch<React.SetStateAction<{ [key: string]: string[] }>>;
  metricHeaders: Header[];
  setMetricHeaders: React.Dispatch<React.SetStateAction<Header[]>>;
  updateFilterContext: (selectedFilter: FilterColumn) => void;
  removeFilter: (selectedFilter: FilterColumn) => void;
  updateTemporalContext: (selectedFilter: TemporalColumn) => void;
  removeTemporal: (selectedFilter: FilterColumn) => void;
  updateBenchmarkMap: (selectedBenchmark: TemporalColumn[]) => void;
  analysisTreeReqJSON: AnalysisTreeRequest[];
}

const SidebarContext = createContext<SidebarContextType | undefined>(undefined);

export const useSidebarContext = () => {
  const context = useContext(SidebarContext);
  if (context === undefined) {
    throw new Error("useMetric must be used within a MetricProvider");
  }
  return context;
};

export const SidebarContextProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [temporalColumnByOwnerIdMap, setTemporalColumnByOwnerIdMap] = useState<{
    [ownerId: string]: Header[];
  }>({});
  const [metricHeaders, setMetricHeaders] = useState<Header[]>([]);
  const [metricInfoMap, setMetricInfoMap] = useState<{ [key: string]: Metric }>(
    {}
  );
  const [temporalColumnByMetricIdMap, setTemporalColumnByMetricIdMap] =
    useState<{ [key: string]: Header[] }>({});
  const [contextByMetricMap, setContextByMetricMap] = useState<{
    [key: string]: Context[];
  }>({});
  const [benchmarkByMetricMap, setBenchmarkByMetricMap] = useState<{
    [key: string]: Benchmark[];
  }>({});
  const [date, setDate] = useState<{ [key: string]: string[] }>({});
  const [analysisTreeReqJSON, setAnalysisTreeReqJSON] = useState<
    AnalysisTreeRequest[]
  >([]);
  //update metricInfo map based on metricHeaders
  useEffect(() => {
    setContextByMetricMap({});
    setTemporalColumnByOwnerIdMap({});
    setTemporalColumnByMetricIdMap({});
    setAnalysisTreeReqJSON([]);
    setBenchmarkByMetricMap({});
    setDate({});
    const fetchMetricInfoAndUpdateMetricInfoMap = async () => {
      if (!metricHeaders.length) return; // Early return if metricHeaders is empty

      try {
        // Create a set of metric IDs already present in the metricInfoMap
        const existingMetricIds = new Set(Object.keys(metricInfoMap));

        const updatedMetricInfoMap: { [key: string]: Metric } = {};
        const fetchPromises = metricHeaders.map(async (metric: Header) => {
          if (metric.id !== undefined) {
            // Check if the metric ID is already present in the map
            if (!existingMetricIds.has(metric.id.toString())) {
              // If not present, fetch metric info with edges
              const metricInfo = await getMetricById(metric.id, true);
              updatedMetricInfoMap[metric.id.toString()] = metricInfo;
            }
          }
        });

        await Promise.all(fetchPromises);

        // Remove IDs from metricInfoMap that are not present in metricHeaders
        Object.keys(metricInfoMap).forEach((key) => {
          if (!metricHeaders.some((header) => header.id?.toString() === key)) {
            delete metricInfoMap[key];
          }
        });

        // Update the metricInfoMap state
        setMetricInfoMap((prevData) => ({
          ...prevData,
          ...updatedMetricInfoMap,
        }));
      } catch (error) {
        alert("Error fetching data for metrics:" + error);
      }
    };

    fetchMetricInfoAndUpdateMetricInfoMap();
  }, [metricHeaders]);

  useEffect(() => {
    const ownerIds = new Set(metricHeaders.map((header) => header.owner_id));

    const fetchModelInfo = async () => {
      const newTemporalColumnByOwnerIdMap: { [key: string]: Header[] } = {
        ...temporalColumnByOwnerIdMap,
      };

      // Remove entries for owner_ids that are no longer present
      Object.keys(newTemporalColumnByOwnerIdMap).forEach((ownerId) => {
        if (!ownerIds.has(ownerId)) {
          delete newTemporalColumnByOwnerIdMap[ownerId];
        }
      });

      // Add new entries for owner_ids that are not in the map yet
      for (const ownerId of Array.from(ownerIds)) {
        if (!newTemporalColumnByOwnerIdMap[ownerId!]) {
          try {
            const modelInfo = await getModelById(ownerId!);
            console.log("modelInfo ", modelInfo)
            const temporalColumns = modelInfo.columns.filter(
              (column: { column_type: string }) =>
                column.column_type === "TEMPORAL"
            );
            newTemporalColumnByOwnerIdMap[ownerId!] = temporalColumns.map(
              (column: { header: Header[] }) => column.header || ({} as Header)
            );
          } catch (error) {
            alert(`Error fetching model info for owner ID ${ownerId}`);
          }
        }
      }
      setTemporalColumnByOwnerIdMap(newTemporalColumnByOwnerIdMap);

      const metricIdToTemporalMap: { [key: string]: Header[] } = {};
      metricHeaders.forEach((header) => {
        const tempHeader = newTemporalColumnByOwnerIdMap[header.owner_id!];
        if (tempHeader) {
          metricIdToTemporalMap[header.id!] = tempHeader;
        }
      });

      setTemporalColumnByMetricIdMap(metricIdToTemporalMap);
    };

    fetchModelInfo();
  }, [metricHeaders]);

  //update filter in contextByMetricMap
  const updateFilterContext = (selectedFilter: FilterColumn) => {
    // Iterate over each metric in metricInfoMap to update contexts
    const newContextMap = { ...contextByMetricMap };

    Object.entries(metricInfoMap).forEach(([metricId, metric]) => {
      const newContexts: Context[] = newContextMap[metricId] || [];

      metric.edges?.forEach((edge) => {
        if (edge.related_attribute?.name === selectedFilter.name) {
          // Create a new context based on found related_attribute
          // console.log("logical column header ", edge.related_attribute);
          const newContext: Context = {
            logical_column_header: edge.related_attribute,
            operator: selectedFilter.operator || "EQ",
            values:
              selectedFilter.values &&
              selectedFilter.values.flatMap((value) =>
                value.split(",").map((item) => item.trim())
              ),
          };
          newContexts.push(newContext);
        }
      });

      newContextMap[metricId] = newContexts;
    });

    // Update the contextByMetricMap with new values
    setContextByMetricMap(newContextMap);
  };

  //update filter in contextByMetricMap
  const updateTemporalContext = (selectedTemporal: TemporalColumn) => {
    // Iterate over each metric in metricInfoMap to update contexts
    Object.entries(temporalColumnByMetricIdMap).forEach(
      ([metricId, headers]) => {
        headers.forEach((header) => {
          if (header.name === selectedTemporal.name) {
            // When a header name matches, prepare to update contexts
            const valuesFromDates = date[selectedTemporal.name!] || [];

            // Check if the data array has at least two values for processing
            if (valuesFromDates.length >= 2) {
              const updatedContexts = contextByMetricMap[metricId] || [];
              const gte = {
                logical_column_header: header,
                operator: "GTE",
                values: [valuesFromDates[0]],
              };
              const lte = {
                logical_column_header: header,
                operator: "LTE",
                values: [valuesFromDates[1]],
              };
              updatedContexts.push(gte);
              updatedContexts.push(lte);

              // Set the updated contexts back into the map
              setContextByMetricMap((prevMap) => ({
                ...prevMap,
                [metricId]: updatedContexts,
              }));
            }
          }
        });
      }
    );
  };

  //update filter in contextByMetricMap
  const updateBenchmarkMap = (selectedBenchmarks: TemporalColumn[]) => {
    // Iterate over each metric in metricInfoMap to update contexts
    const newBenchmarkMap: { [key: string]: Benchmark[] } = {};

    Object.entries(metricInfoMap).forEach(([metricId, metric]) => {
      // Check if there is no "Benchmark_Edge" in the metric's edges
      // const hasBenchmarkEdge = metric.edges && metric.edges.some(edge => edge.edge_type === "BENCHMARK_EDGE");

      // If no "Benchmark_Edge" is found, initialize this metric in the benchmark map
      // if (!hasBenchmarkEdge) {
      const resultJSONBenchmark1: Benchmark[] =
        selectedBenchmarks &&
        (selectedBenchmarks
          .map((benchmark) => {
            // Retrieve corresponding headers from modelTemporalColumnInfoByMetricId
            const headers = temporalColumnByMetricIdMap[metricId];
            const matchingHeader =
              headers &&
              headers.find((header) => header.name === benchmark.name);

            if (!matchingHeader) {
              alert(
                `No matching header found for benchmark with name: ${benchmark.name}`
              );
              return undefined; // Return undefined or some default object depending on your error handling strategy
            }

            const gteFilter: BenchmarkFilter = {
              logical_column_header: matchingHeader!,
              // name: benchmark.name!,
              operator: "GTE",
              values: [date && date[benchmark.name!] && date[benchmark.name!][0]],
            };

            const lteFilter: BenchmarkFilter = {
              logical_column_header: matchingHeader!,
              // name: benchmark.name!,
              operator: "LTE",
              values: [date && date[benchmark.name!] && date[benchmark.name!][1]],
            };

            return {
              header: {
                id: uuidv4(),
                name: benchmark?.custom_period?.name,
              },
              benchmark_filters: [gteFilter, lteFilter],
              variance_tolerance: 0.025,
            };
          })
          .filter((benchmark) => benchmark !== undefined) as Benchmark[]);
      newBenchmarkMap[metricId] = [...resultJSONBenchmark1]; // Initialize with an empty array or default benchmarks
      // }
    });

    // Set the benchmark map state
    setBenchmarkByMetricMap(newBenchmarkMap);
  };

  //remove particular selected filter
  const removeFilter = (filter: FilterColumn) => {
    const newContextMap = { ...contextByMetricMap };

    Object.entries(newContextMap).forEach(([metricId, contexts]) => {
      // Filter out the contexts where the logical_column_header's name matches the removed filter's name
      const filteredContexts = contexts.filter(
        (context) => context.logical_column_header?.name !== filter.name
      );

      newContextMap[metricId] = filteredContexts;
    });

    // Step 3: Update the state with the new context map
    setContextByMetricMap(newContextMap);
  };
  //remove particular selected filter
  const removeTemporal = (filter: FilterColumn) => {
    const newContextMap = { ...contextByMetricMap };

    Object.entries(newContextMap).forEach(([metricId, contexts]) => {
      // Filter out the contexts where the logical_column_header's name matches the removed filter's name
      const filteredContexts = contexts.filter(
        (context) => context.logical_column_header?.name !== filter.name
      );

      newContextMap[metricId] = filteredContexts;
    });

    // Step 3: Update the state with the new context map
    setContextByMetricMap(newContextMap);
  };

  useEffect(() => {
    const jsonDataArray =
      metricHeaders &&
      metricHeaders.map((metric) => {
        const jsonData: AnalysisTreeRequest = {
          metricId: metric.id,
          context: contextByMetricMap[metric.id!],
          benchmark: benchmarkByMetricMap[metric.id!],
        };
        return jsonData;
      });
    setAnalysisTreeReqJSON(jsonDataArray);
  }, [metricHeaders, metricInfoMap, benchmarkByMetricMap, contextByMetricMap]);
  
  const value = {
    metricInfoMap,
    setMetricInfoMap,
    temporalColumnByMetricIdMap,
    setTemporalColumnByMetricIdMap,
    contextByMetricMap,
    setContextByMetricMap,
    benchmarkByMetricMap,
    setBenchmarkByMetricMap,
    metricHeaders,
    setMetricHeaders,
    updateFilterContext,
    date,
    setDate,
    updateTemporalContext,
    updateBenchmarkMap,
    removeFilter,
    removeTemporal,
    analysisTreeReqJSON,
  };

  return (
    <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>
  );
};