import React, { useEffect, useState } from "react";
import {
  Table,
  Thead,
  Tr,
  Td,
  Tbody,
  TableContainer,
  IconButton,
  Th,
  Text,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  useDisclosure,
  Button,
} from "@chakra-ui/react";
import { IoMdAdd } from "react-icons/io";
import {
  ColumnValuesList,
  Context,
  Filter,
  NodeInsights,
  ObservationColumn,
} from "../../containers/commentary/Interfaces";
import { useAnalysisTreeContext } from "../../context/AnalysisTreeContext";
import { FaIndent, FaOutdent } from "react-icons/fa";
import { getColumnHeaderName } from "../../utils/analysisUtils";
import { Edges, FormatProto, Metric } from "../../containers/Interfaces";
import { AnalysisColumnType, AnalysisType, ColumnType, FilterOperator, MetricEdgeType } from "../../utils/enum";
import { createBenchmarks } from "../common/CreateBenchmark";

// Format the table header name in title case.
const formatHeaderName = (str: string): string => {
  return str
    .replace(/_/g, ' ')        // Replace underscores with spaces
    .toLowerCase()              // Convert the entire string to lowercase
    .split(/[\s-]+/)            // Split on spaces or hyphens
    .map(word => {
      if (word.length === 0) return word;  // Handle empty strings
      if (!isNaN(Number(word))) return word;  // If the word is a number, return it as is
      return word.charAt(0).toUpperCase() + word.slice(1);  // Capitalize the first letter
    })
    .join(' ')
    .replace(/ - /g, '-');  // Reformat space-dash-space into just dash
};

interface DataTableProps {
  node: NodeInsights;
  showColumn: boolean;
}

const DataTable: React.FC<DataTableProps> = ({ node, showColumn }) => {

  // Destructure necessary values from context.
  const { 
    transactionId, 
    mkgByNodeId, 
    rootNodeId, 
    nodeInfoMap, 
    tree, 
    onEdgeClick,
    treePannelLoader
  } = useAnalysisTreeContext();
  // Use useDisclosure to control the Menu's visibility
  const { onOpen, isOpen, onClose } = useDisclosure();
  
  // State management for showing hidden columns, mapping column values, and tracking hover states.
  const [showHiddenColumns, setShowHiddenColumns] = useState(false);
  const [columnValuesMap, setColumnValuesMap] = useState<Map<number, ColumnValuesList>>(new Map());
  const [hoveredRowIndex, setHoveredRowIndex] = useState<number | null>(null);
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);
  const [filteredEdges, setFilteredEdges] = useState<Edges[]>([]);

  // Extracting observation columns and column values list from the provided data.
  const observation_columns = node && node?.current_node.analysed_data?.observation.content.observation_columns;
  const column_values_list = node && node?.current_node.analysed_data?.column_values_list;

  // useEffect to populate the columnValuesMap based on the provided data.
  // This includes parsing JSON values and adjusting column types for display.
  useEffect(() => {
    const newColumnValuesMap = new Map<number, ColumnValuesList>();

    if (column_values_list) {
      column_values_list.forEach((columnValues, index) => {
        if (columnValues.data_value_json_array) {

          // Replace "NaN" with a placeholder string
          const jsonString = columnValues.data_value_json_array.replace(/NaN/g, '"_NaN_"');
        
          // Parse the JSON string
          let parsedData = JSON.parse(jsonString);

          // Replace the placeholder string with NaN
          parsedData = parsedData.map((value: any) => value === "_NaN_" ? NaN : value);

          // Store parsed JSON array in the columnValues object
          columnValues.parsed_data_value_json_array = parsedData;
        }

        // Adjust column types for special cases like "MELTED_VARIABLE"
        if (columnValues.analysis_column_type) {
          if (columnValues.analysis_column_type === "MELTED_VARIABLE") {
            columnValues.analysis_column_type = "VARIABLE";
          } else if (columnValues.analysis_column_type === "MELTED_VALUE") {
            columnValues.analysis_column_type = "VALUE";
          } else if (columnValues.analysis_column_type === AnalysisColumnType.LAST_N_PERIOD_AVERAGE) {
            columnValues.analysis_column_type = "4-WEEK AVG";
          } else if (columnValues.analysis_column_type === AnalysisColumnType.DIAGNOSTIC_STATUS) {
            columnValues.analysis_column_type = "STATUS";
          } else {
            columnValues.analysis_column_type = columnValues.analysis_column_type
              .replace(/_/g, " ")
              .toUpperCase();
          }
        }  

        // Fallback logic for column type based on observation columns or benchmark headers
        const observationColumn = observation_columns?.find(col => col.column_id === columnValues.column_id);
        if (observationColumn) {
          columnValues.analysis_column_type = observationColumn.logical_column_header.name?.toUpperCase();
        } else if (node.current_node && node.current_node.benchmark) {
          // If not found in observation_columns, check in benchmark
          const benchmarkHeader = node.current_node.benchmark.header;
          if (columnValues.column_id === benchmarkHeader.id) {
            columnValues.analysis_column_type = benchmarkHeader.name?.toUpperCase();
          }
        }

        // Store the processed column values in the map
        newColumnValuesMap.set(index, columnValues);
      });
    }

    setColumnValuesMap(newColumnValuesMap);
  }, [node, column_values_list, observation_columns]);

  const isVarianceColumn = (columnIndex: number): boolean => {
    const columnValues = columnValuesMap.get(columnIndex);
    return columnValues?.analysis_column_type? columnValues?.analysis_column_type === "VARIANCE": false;
  };

  const isMaterialColumn = (columnIndex: number): boolean => {
    const columnValues = columnValuesMap.get(columnIndex);
    const columnId = columnValues?.column_id;
    const observationColumn = observation_columns?.find(col => col.column_id === columnId);
    return observationColumn?.is_material ? observationColumn.is_material === true: false;
  };

  const isVariancePercentageColumn = (columnIndex: number): boolean => {
    const columnValues = columnValuesMap.get(columnIndex);
    return columnValues?.analysis_column_type? columnValues?.analysis_column_type === "VARIANCE PERCENTAGE": false;
  };

  const isVarianceContributionColumn = (columnIndex: number): boolean => {
    const columnValues = columnValuesMap.get(columnIndex);
    return columnValues?.analysis_column_type? columnValues?.analysis_column_type === "VARIANCE CONTRIBUTION": false;
  };

  const formatValue = (
    value: number,
    formatSettings: FormatProto | null,
    isMaterial: boolean,
    isVariancePercentage: boolean,
    isVarianceContribution: boolean,
    isVariance: boolean,
  ): string => {

    // If it's a material value, return as it is.
    if (isMaterial) {
      return value.toString();
    }

    if(isVariance){
      let formattedValue = "";
      if (formatSettings && formatSettings.percentage) {
        formattedValue = (value * 100).toFixed(2) + "%";
      } else {
        formattedValue = Number(value.toFixed(2)).toLocaleString("en-US");
      }

      return value < 0 ? `(${formattedValue})` : formattedValue;
    }

    if(isVarianceContribution){
      const formattedValue = value.toFixed(2);
      return value < 0 ? `(${formattedValue})` : formattedValue;
    }

    if (isVariancePercentage) {
      const formattedValue = (value * 100).toFixed(2) + "%";
      return value < 0 ? `(${formattedValue})` : formattedValue;
    }

    if (formatSettings && formatSettings.percentage) {
      const formattedValue = (value * 100).toFixed(2) + "%";
      return value < 0 ? `${formattedValue}` : formattedValue;
    }

    // Otherwise, format as a localized string
    const formattedValue = Number(value.toFixed(2)).toLocaleString("en-US");
    return value < 0 ? `${formattedValue}` : formattedValue;
  };

  const transposedData: any[][] = [];
  columnValuesMap.forEach((columnValues) => {
    columnValues.parsed_data_value_json_array?.forEach((item, index) => {
      if (!transposedData[index]) {
        transposedData[index] = [];
      }
      transposedData[index].push(item);
    });
  });

  // Format each cell in the transposed data for display in the table
  const formattedData = transposedData?.map((row) => {
    const identifier = row[0];
    let formatSettings: FormatProto | null = null;

    // Check if inherited_mkg and correlated_mkgs exist before proceeding
    const inherited_mkg: Metric | undefined = mkgByNodeId?.get(node?.current_node.id!)?.inherited_mkg;
    const allMKGS: Metric[] = mkgByNodeId?.get(node?.current_node.id)?.correlated_mkgs ? [...mkgByNodeId.get(node.current_node.id)?.correlated_mkgs!] : [];
    if(inherited_mkg) allMKGS.push(inherited_mkg);
    
    // Find the format setting by matching header.name with the identifier
    for (const mkg of allMKGS) {
      if (mkg.header.name === identifier) {
          formatSettings = mkg?.format ? mkg.format : null;
          break; // Exit loop once a match is found
      }
    }
    if (!isNaN(row[0]) || formatSettings === null) formatSettings = inherited_mkg?.format ? inherited_mkg?.format : null;
    // Apply formatting logic to each value in the row
    return row.map((value, colIndex) => {
      const numValue = typeof value !== "string"? parseFloat(value as string): value;
      //if numValue is string e.g 2nd year upsell then it should not parse as number
      if (typeof numValue !== "string" && !isNaN(numValue)) {
        return formatValue(
          numValue,
          formatSettings,
          isMaterialColumn(colIndex),
          isVariancePercentageColumn(colIndex),
          isVarianceContributionColumn(colIndex),
          isVarianceColumn(colIndex)
        );
      }
      return value;
    });
  });

  /**
   * Function to filter edges based on child nodes' analysis type.
   * It removes edges that are already linked to specific child node analyses
   * like DRIVER_ANALYSIS, CORRELATION_ANALYSIS, BENCHMARK_ANALYSIS, etc.
   */
  const filterEdgesBasedOnChildNodes = (
    data: NodeInsights,
    rowIndex: number,
    formattedData: any[][],
    edges: Edges[],
  ): Edges[] => {
    const childrenIds = data.current_node.children_ids || [];

    for (const childId of childrenIds) {
      const childNode = nodeInfoMap?.get(childId);
      if (!childNode) {
        return edges;
      }

      // If the current node is performing DRIVER_ANALYSIS
      if (data.current_node.analysis_type === AnalysisType.DRIVER_ANALYSIS) {

        // Get the filter headers used by the current node
        const currentNodeFilterHeaders = new Set(
          data.current_node.observation.content.filters.map(
            (filter: Filter) => filter.logical_column_header.id
          )
        );
        
        // Find filters that are unique to the child node, not in the current node
        const uniqueFilters: Filter[] =
          childNode.current_node.observation.content.filters.filter(
            (filter) => !currentNodeFilterHeaders.has(filter.logical_column_header.id)
          );
        
        // Extract the edge name based on unique filters
        const edgeName = uniqueFilters
          .map((filter: Filter) => {
            return `${filter.values && filter.values.join(", ")}`;
          })
          .join(", ");
        
        // If the child node is also performing DRIVER_ANALYSIS, remove used ATTRIBUTE_EDGE from edges
        if (childNode.current_node.analysis_type === AnalysisType.DRIVER_ANALYSIS) {
          if (formattedData[rowIndex][0] === edgeName) {
            edges = edges.filter(
              (edge) =>
                !(edge.edge_type === MetricEdgeType.ATTRIBUTE_EDGE &&
                  edge.related_attribute?.name === childNode.current_node.driver_header?.name!)
            );
          }
        }

        // If the child node is performing CORRELATION_ANALYSIS, remove used FUNDAMENTAL_EDGE from edges 
        if (childNode.current_node.analysis_type === AnalysisType.CORRELATION_ANALYSIS) {
          if (formattedData[rowIndex][0] === edgeName) {
            edges = edges.filter(
              (edge) =>
                !(edge.edge_type === MetricEdgeType.FUNDAMENTAL_EDGE &&
                  edge.fundamental_relationship?.header?.name === childNode.current_node.fundamental_correlation_header?.name!)
            );
          }
        }
        // If the current node is performing CORRELATION_ANALYSIS
      } else if (data.current_node.analysis_type === AnalysisType.CORRELATION_ANALYSIS) {

        // Get the observation columns from the child node
        const observationColumns = childNode?.current_node.observation?.content?.observation_columns;

        // Get the edge name
        const edgeName = observationColumns
          ?.map((column: ObservationColumn) => column.logical_column_header.name)
          .join(", ");

        // Remove used FUNDAMENTAL_EDGE from edges for CORRELATION_ANALYSIS child nodes
        if (childNode.current_node.analysis_type === AnalysisType.CORRELATION_ANALYSIS) {
          if (formattedData[rowIndex][0] === edgeName) {
            edges = edges.filter(
              (edge) =>
                !(edge.edge_type === MetricEdgeType.FUNDAMENTAL_EDGE &&
                  edge.fundamental_relationship?.header?.name === childNode.current_node.fundamental_correlation_header?.name!)
            );
          }
        }
        
        // Remove used ATTRIBUTE_EDGE from edges for DRIVER_ANALYSIS child nodes
        if (childNode.current_node.analysis_type === AnalysisType.DRIVER_ANALYSIS) {
          if (formattedData[rowIndex][0] === edgeName) {
            edges = edges.filter(
              (edge) =>
                !(edge.edge_type === MetricEdgeType.ATTRIBUTE_EDGE &&
                  edge.related_attribute?.name === childNode.current_node.driver_header?.name)
            );
          }
        }
        // If the current node is performing BENCHMARK_ANALYSIS
      } else if (data.current_node.analysis_type === AnalysisType.BENCHMARK_ANALYSIS) {

        // Remove used FUNDAMENTAL_EDGE from edges for CORRELATION_ANALYSIS child nodes
        if (childNode.current_node.analysis_type === AnalysisType.CORRELATION_ANALYSIS) {
          edges = edges.filter(
            (edge) =>
              !(edge.edge_type === MetricEdgeType.FUNDAMENTAL_EDGE &&
                edge.fundamental_relationship?.header?.name === childNode.current_node.fundamental_correlation_header?.name!)
          );
        }

        // Remove used ATTRIBUTE_EDGE from edges for DRIVER_ANALYSIS child nodes
        if (childNode.current_node.analysis_type === AnalysisType.DRIVER_ANALYSIS) {
          edges = edges.filter(
            (edge) =>
              !(edge.edge_type === MetricEdgeType.ATTRIBUTE_EDGE &&
                edge.related_attribute?.name === childNode.current_node.driver_header?.name)
          );
        }

        // If the current node is performing METRIC_VALUE_ANALYSIS
      } else if (data.current_node.analysis_type === AnalysisType.METRIC_VALUE_ANALYSIS) {

        // Remove BENCHMARK_EDGE from edges for BENCHMARK_ANALYSIS child nodes
        if (childNode.current_node.analysis_type === AnalysisType.BENCHMARK_ANALYSIS) {
          edges = edges.filter(
            (edge) =>
              !(edge.edge_type === MetricEdgeType.BENCHMARK_EDGE &&
                edge.benchmark?.header?.name === childNode.current_node.benchmark?.header.name!)
          );
        }
      }
    }
    return edges;
  };

  const getFilteredEdges = (rowIndex: number): Edges[] => {
    let edges: Edges[] = [];
  
    // Retrieve inherited and correlated MKGs
    const inherited_mkg: Metric = mkgByNodeId?.get(node?.current_node.id!)?.inherited_mkg!;
    const correlated_mkgs = mkgByNodeId?.get(node?.current_node.id)?.correlated_mkgs
      ? [...mkgByNodeId.get(node.current_node.id)?.correlated_mkgs!]
      : [];
  
    // Find the correlated metric that matches the formatted data for the given row
    if (correlated_mkgs.length > 0) {
      let correlatedMetric: Metric | null = null;
      for (const mkg of correlated_mkgs) {
        if (mkg.header.name === formattedData[rowIndex][0]) {
          correlatedMetric = mkg;
          break;
        }
      }
      
      // Assign edges from the correlated metric or the inherited MKG
      edges = correlatedMetric ? [...correlatedMetric.edges!] : [...(inherited_mkg?.edges || [])];
    } else {
      // If no correlated MKGs, fallback to inherited MKG edges
      edges = [...(inherited_mkg?.edges || [])];
    }
  
    // Handle the case where the current node is the root node and use filters from the context
    if (node?.current_node.id === rootNodeId && tree?.context) {
      edges = [...edges];
  
      let gteFilter: Context = {};
      let lteFilter: Context = {};
  
      // Iterate over the tree context to find temporal filters (GTE and LTE)
      for (const filter of tree.context) {
        const columnType = filter.logical_column?.column_type;
        const operator = filter.operator;
  
        if (columnType !== ColumnType.TEMPORAL) continue;
  
        if (operator === FilterOperator.GTE) {
          gteFilter = filter;
        } else if (operator === FilterOperator.LTE) {
          lteFilter = filter;
        }
      }
  
      // Create benchmarks if GTE and LTE filters exist
      if (gteFilter.values && lteFilter.values) {
        const { previousPeriodBenchmark, samePeriodLastYearBenchmark } = createBenchmarks(
          gteFilter.values[0],
          lteFilter.values[0],
          gteFilter.logical_column_header!
        );
  
        const benchmarkEdges: Edges[] = [
          { edge_type: MetricEdgeType.BENCHMARK_EDGE, benchmark: previousPeriodBenchmark },
          { edge_type: MetricEdgeType.BENCHMARK_EDGE, benchmark: samePeriodLastYearBenchmark },
        ];
  
        const rootNodeBenchmarkEdges: Edges[] =
          mkgByNodeId.get(node.current_node.id)?.inherited_mkg?.edges?.filter(
            (edge) => edge.edge_type === MetricEdgeType.BENCHMARK_EDGE
          ) || [];
  
        benchmarkEdges.push(...rootNodeBenchmarkEdges);
        edges = benchmarkEdges;
      }
    }
  
    // Further filter edges based on child nodes
    edges = filterEdgesBasedOnChildNodes(node, rowIndex, formattedData, edges);
  
    return edges;
  };

  const handleMenuOpen = (rowIndex: number) => {
    const edges = getFilteredEdges(rowIndex); // Fetch the filtered edges for the specific row
    setFilteredEdges(edges);
    setSelectedRowIndex(rowIndex); // Highlight the row
    onOpen();
  };

  const handleMenuClose = () => {
    setSelectedRowIndex(null); // Remove the highlight when the menu is closed
    onClose();
  };

  return (
    <TableContainer
      p={0}
      key={node && node.current_node.id}
      maxHeight="310px"
      overflowY="auto"
    >
      <Table variant="simple" size="sm" w="100%" align="center">
        <Thead>
          <Tr>
            {columnValuesMap &&
              Array.from(columnValuesMap.entries()).map(
                ([index, columnValues]) => {
                  if (
                    !showHiddenColumns &&
                    (isMaterialColumn(index) ||
                      (columnValues.analysis_column_type &&
                        (columnValues.analysis_column_type ===
                          "VARIANCE CONTRIBUTION" ||
                          columnValues.analysis_column_type ===
                            "VARIANCE PERCENTAGE")))
                  ) {
                    return null;
                  }

                  return (
                    <Th
                      key={columnValues.column_id}
                      color="fg.default"
                      fontSize="xs"
                      bg="purple.50"
                      fontWeight="bold"
                      textAlign={index !== 0 ? "right" : "left"}
                      pt={2}
                      pb={2}
                      px={2}
                      whiteSpace="normal"
                      overflow="visible"
                      textOverflow="ellipsis"
                      position="sticky"
                      top="0"
                      left={index === 0 ? "0" : "auto"}
                      zIndex={index === 0 ? 2 : 1}
                    >
                      <Text mb={0} size="md">
                        {columnValues.analysis_column_type ===
                          "VARIANCE CONTRIBUTION" ||
                        columnValues.analysis_column_type ===
                          "VARIANCE PERCENTAGE"
                          ? formatHeaderName(getColumnHeaderName(
                              columnValues.analysis_column_type
                            ))
                          : formatHeaderName(columnValues.analysis_column_type!)}
                      </Text>
                    </Th>
                  );
                }
              )}
            {showColumn && (
              <IconButton
                size="xxxs"
                position="absolute"
                bottom="1%"
                right="1%"
                color="gray.100"
                bg="transparent"
                _hover={{ bg: "gray.50", color: "gray.700" }}
                aria-label={
                  showHiddenColumns
                    ? "Hide Special Columns"
                    : "Show Special Columns"
                }
                icon={showHiddenColumns ? <FaOutdent /> : <FaIndent />}
                onClick={() => setShowHiddenColumns((prev) => !prev)}
                zIndex={3}
              />
            )}
          </Tr>
        </Thead>
        <Tbody fontWeight={500}>
          {formattedData?.map((row, rowIndex) => (
            <Tr
              key={rowIndex}
              bg="bg.surface"
              onMouseEnter={() => setHoveredRowIndex(rowIndex)}
              _hover={{ bg: "gray.100" }}
            >
              {row.map((column, colIndex) => {
                if (
                  !showHiddenColumns &&
                  (isMaterialColumn(colIndex) ||
                    (columnValuesMap.get(colIndex)?.analysis_column_type &&
                      (columnValuesMap.get(colIndex)?.analysis_column_type ===
                        "VARIANCE CONTRIBUTION" ||
                        columnValuesMap.get(colIndex)?.analysis_column_type ===
                          "VARIANCE PERCENTAGE")))
                ) {
                  return null;
                }

                // Define styles and conditions for the first column click
                const isFirstColumn = colIndex === 0;
                const isSelectedRow = selectedRowIndex === rowIndex;
                const isNegative =
                  typeof column === "string" && column.startsWith("(");
                const isNaNValue = Number.isNaN(column);
                const color =
                  !isNaNValue &&
                  (isVarianceColumn(colIndex) ||
                    isVarianceContributionColumn(colIndex) ||
                    isVariancePercentageColumn(colIndex)) &&
                  column !== null
                    ? isNegative
                      ? "red"
                      : "green"
                    : "inherit";

                return (
                  <Td
                    key={colIndex}
                    color={color}
                    textAlign={
                      isFirstColumn && typeof column !== "number"
                        ? "left"
                        : "right"
                    }
                    p={0}
                    pr={isFirstColumn ? 0: 2}
                    m={0}
                    maxW={isFirstColumn ? "150px" : "auto"}
                    whiteSpace="normal"
                    overflow={isFirstColumn ? "visible" : "hidden"}
                    textOverflow="ellipsis"
                    position={isFirstColumn ? "sticky" : "static"}
                    left={isFirstColumn ? "0" : "auto"}
                    zIndex={isFirstColumn ? 1 : "auto"}
                    cursor={isFirstColumn ? "pointer" : "auto"}
                    bg="inherit"
                  >
                    {/* Only make the first column clickable */}
                    {isFirstColumn ? (
                      <Menu placement="bottom-start" flip={true} onClose={handleMenuClose}>
                        <MenuButton
                          as={Button} // Make the entire column act as a button
                          rightIcon={<IoMdAdd 
                            color="white"
                          />}
                          onClick={() => handleMenuOpen(rowIndex)}
                          py={0}
                          px={2}
                          m={0}
                          color={isFirstColumn && isSelectedRow ? "white": "black"}
                          // _hover={{ bg: "purple.700", color:"white" }}
                          cursor="pointer"
                          width="100%"
                          textAlign="left"
                          display="flex" // Use flex to align icon and text
                          justifyContent="space-between" // Make sure the icon is aligned to the right
                          _hover={{ bg: !treePannelLoader ? "purple.700" : "inherit", color: !treePannelLoader ? "white" : "black" }}
                          bg={isSelectedRow && isFirstColumn ? "purple.700" : "inherit"}
                          isDisabled={treePannelLoader}
                        >
                          <Text as="span" fontSize="sm"  m={0} p={0}>{column != null ? column : "null"}</Text>
                        </MenuButton>
                        <Portal>
                          <MenuList
                            zIndex={25}
                            maxH="150px"
                            overflow="auto"
                            m={0}
                            p={0}
                          >
                            {filteredEdges?.length > 0 ? (
                              filteredEdges?.map((edge: Edges, i: number) => (
                                <MenuItem
                                  key={i}
                                  onClick={() =>
                                    onEdgeClick(
                                      node,
                                      edge,
                                      rowIndex,
                                      transactionId
                                    )
                                  }
                                  fontSize="xs"
                                  fontWeight={600}
                                >
                                  {edge.edge_type ===
                                  MetricEdgeType.BENCHMARK_EDGE
                                    ? `Compare to ${edge.benchmark?.header.name}`
                                    : edge.edge_type ===
                                      MetricEdgeType.ATTRIBUTE_EDGE
                                    ? `Split by ${edge.related_attribute?.name}`
                                    : edge.edge_type ===
                                      MetricEdgeType.METRIC_EDGE
                                    ? `Explore ${edge.benchmark?.header.name}`
                                    : edge.edge_type ===
                                      MetricEdgeType.FUNDAMENTAL_EDGE
                                    ? `Split by ${edge.fundamental_relationship?.header?.name}`
                                    : "Unknown Edge Type"}
                                </MenuItem>
                              ))
                            ) : (
                              <MenuItem
                                fontSize="xs"
                                fontWeight={600}
                                isDisabled
                              >
                                No suggestions
                              </MenuItem>
                            )}
                          </MenuList>
                        </Portal>
                      </Menu>
                    ) : (
                      // Render other columns normally
                      <>{column != null ? column : "null"}</>
                    )}
                  </Td>
                );
              })}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default DataTable;