import {
  Box,
  Text,
  Button,
  Flex,
  Tooltip,
  Icon,
  Textarea,
  Spinner,
  SkeletonText,
  HStack,
  Divider,
} from "@chakra-ui/react";
import React from "react";
import { HiSparkles } from "react-icons/hi2";
import { CopyIcon, CheckIcon, DownloadIcon } from "@chakra-ui/icons";
import { MdEdit } from "react-icons/md";
import { BsBookmark, BsBookmarkCheckFill } from "react-icons/bs";
import { EmptyStatePrompt } from "../common/EmptyStatePrompt";
import { Commentary } from "../../containers/commentary/Interfaces";
import { CommentaryConfig } from "../../containers/analyse/Interfaces";

interface CommentaryPanelProps {
  refs: React.RefObject<(HTMLDivElement | null)[]>;
  commentaryArray: CommentaryConfig[];
  loadingCommentary: boolean;
  selectedCommentaryIndex: number;
  editStates: boolean[];
  editTexts: string[];
  heights: number[];
  isAdmin: boolean;
  debug: boolean;
  handleCopy: (value: string) => void;
  toggleEdit: (index: number, commentary: Commentary, text: string) => void;
  handleTextChange: (index: number, value: string) => void;
  handleSaveOrDeleteCommentary: (commentary: Commentary, index: number, isSave: boolean) => void;
  handleSelectCommentary: (config: Commentary, index: number) => void;
  handleDownloadPrompt: (id: string) => Promise<void>;
  handleGenerateCommentary: () => Promise<void>;
}

const CommentaryPanel: React.FC<CommentaryPanelProps> = ({
  refs,
  commentaryArray,
  loadingCommentary,
  selectedCommentaryIndex,
  editTexts,
  editStates,
  heights,
  isAdmin,
  debug,
  handleCopy,
  toggleEdit,
  handleTextChange,
  handleSaveOrDeleteCommentary,
  handleSelectCommentary,
  handleDownloadPrompt,
  handleGenerateCommentary,
}) => {
  return (
    <Flex
      direction="column"
      height="100%"
      justifyContent="space-between"
      bg="bg.surface"
      borderRadius="lg"
      boxShadow="md"
    >
      <Box flex="1" overflowY="auto">
        {commentaryArray.length === 0 && !loadingCommentary ? (
          <EmptyStatePrompt text="Click to generate commentary." flag={false} />
        ) : (
          commentaryArray.map((item, index) => (
            <>
              <Box
                key={index}
                p={2}
                pl={3}
                onClick={() =>
                  handleSelectCommentary(item && item.commentary!, index)
                }
                borderColor={
                  selectedCommentaryIndex === index ? "#204CFA" : "bg.surface"
                }
                borderWidth={3}
                borderRadius="md"
                bg="bg.surface"
                _hover={{ cursor: "pointer", bg: "gray.100" }}
              >
                {item.loading ? (
                  <Box
                    position="relative"
                    padding="3"
                    bg="bg.surface"
                    textAlign="center"
                  >
                    <Flex
                      alignItems="center"
                      justifyContent="center"
                      flexDirection="column"
                    >
                      <Spinner size="md" color="purple.700" thickness="3px" />
                      <Text fontSize="sm" fontWeight="semibold" mt={3}>
                        Composing...
                      </Text>
                    </Flex>
                    <SkeletonText mt="1" noOfLines={3} spacing="3" />
                  </Box>
                ) : editStates[index] ? (
                  <Textarea
                    p={1}
                    fontSize="sm"
                    value={editTexts[index]}
                    onChange={(e) => handleTextChange(index, e.target.value)}
                    height={`${heights[index]}px`}
                  />
                ) : (
                  <Box
                    ref={(el) => {
                      if (refs.current) {
                        refs.current[index] = el!;
                      }
                    }}
                  >
                    <Text
                      align="left"
                      textStyle="sm"
                      fontWeight={500}
                      mb={0}
                      lineHeight={1.5}
                      letterSpacing={0.1}
                      mt={1}
                    >
                      {editTexts[index]}
                    </Text>
                  </Box>
                )}
                <HStack
                  mt={0}
                  align="flex-end"
                  justifyContent="flex-end"
                  spacing={3}
                >
                  <Tooltip label={!editStates[index] ? "Edit" : "Save"} placement="bottom-start">
                    <Box
                      as="span"
                      cursor={item.loading ? "not-allowed" : "pointer"}
                    >
                      <Icon
                        as={editStates[index] ? CheckIcon : MdEdit}
                        boxSize={4}
                        color="gray.500"
                        onClick={() =>
                          !item.loading &&
                          toggleEdit(index, item?.commentary!, editTexts[index])
                        }
                        _hover={{ color: "gray.700" }}
                      />
                    </Box>
                  </Tooltip>
                  <Tooltip label="Copy text" placement="bottom-start">
                    <Box
                      as="span"
                      cursor={item.loading ? "not-allowed" : "pointer"}
                    >
                      <Icon
                        as={CopyIcon}
                        color="gray.500"
                        _hover={{ color: "gray.700" }}
                        onClick={() =>
                          !item.loading && handleCopy(editTexts[index])
                        }
                      />
                    </Box>
                  </Tooltip>
                  <Tooltip label={item.isSaved ? "Saved" : "Save commentary"} placement="bottom-start">
                    <Box
                      as="span"
                      cursor={item.loading ? "not-allowed" : "pointer"}
                    >
                      <Icon
                        as={item.isSaved ? BsBookmarkCheckFill : BsBookmark}
                        onClick={() =>
                          !item.loading &&
                          handleSaveOrDeleteCommentary(item.commentary!, index, item.isSaved!)
                        }
                        color={item.isSaved ? "green.600" : "gray.500"}
                        _hover={{
                          color: item.isSaved ? "green.600" : "gray.700",
                        }}
                        boxSize={4}
                      />
                    </Box>
                  </Tooltip>
                  {isAdmin && debug && (
                    <Tooltip label="download prompt">
                      <Box
                        as="span"
                        cursor={item.loading ? "not-allowed" : "pointer"}
                      >
                        <Icon
                          as={DownloadIcon}
                          color="gray.700"
                          onClick={() =>
                            !item.loading &&
                            handleDownloadPrompt(
                              item.commentary?.conversation_header?.id!
                            )
                          }
                        />
                      </Box>
                    </Tooltip>
                  )}
                </HStack>
              </Box>
              <Divider m={0} />
            </>
          ))
        )}
      </Box>
      <Box p={2} m={2}>
        <Button
          onClick={handleGenerateCommentary}
          py={3}
          width="100%"
          alignSelf="center"
          leftIcon={<HiSparkles />}
          bg="purple.50"
          color="fg.default"
          _hover={{ bg: "purple.700", fontWeight: "bold", color: "white" }}
          fontWeight={600}
          borderRadius="md"
          fontSize="md"
        >
          Generate Commentary
        </Button>
      </Box>
    </Flex>
  );
};

export default CommentaryPanel;
