import { Page, Story } from "../containers/export/Interface";
import showToast from "../hooks/useCustomToast";

/**
 * Creates or updates a report based on the provided Story object.
 *
 * @param {Story} story - The story object containing data for the report.
 * @returns {Promise<any>} A promise that resolves to the API response.
 * @throws {Error} Throws an error if the request fails or the environment variable is not set.
 */
export const createUpdateReport = async (story: Story) => {
  try {
    const response = await fetch(process.env.REACT_APP_CREATE_UPDATE_REPORT!, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(story),
    });

    if (!response.ok) {
      const errorMsg = await response.text();
      showToast(`${errorMsg}`, "", "error");
    }

    return await response.json();
  } catch (error) {
    showToast("Error creating/updating report", "", "error");
  }
};

/**
 * Creates or updates a report based on the provided Story object.
 *
 * @param {Page} page - The story object containing data for the report.
 * @returns {Promise<any>} A promise that resolves to the API response.
 * @throws {Error} Throws an error if the request fails or the environment variable is not set.
 */
export const createUpdatePage = async (page: Page) => {
  try {
    const response = await fetch(process.env.REACT_APP_CREATE_UPDATE_PAGE!, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(page),
    });

    if (!response.ok) {
      const errorMsg = await response.text();
      showToast(`${errorMsg}`, "", "error");
    }

    return await response.json();
  } catch (error) {
    showToast("Error creating/updating report", "", "error");
  }
};

/**
 * Creates or updates a report based on the provided Story object.
 *
 * @param {string} reportType - The story object containing data for the report.
 * @returns {Promise<any>} A promise that resolves to the API response.
 * @throws {Error} Throws an error if the request fails or the environment variable is not set.
 */
export const getAllReports = async (reportType: string) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_LIST_ALL_REPORT!}?reportType=${reportType}`, {
      method: "GET",
      credentials: "include",
    });

    if (!response.ok) {
      const errorMsg = await response.text();
      showToast(`${errorMsg}`, "", "error");
    }

    return await response.json();
  } catch (error) {
    showToast("Error creating/updating report", "", "error");
  }
};

/**
 * Export story or pages.
 *
 * @param {string} reportType - report_page or report_book.
 * @param {string} id - id of page or report/story.
 * @returns {Promise<void>} A promise that resolves to the API response.
 * @throws {Error} Throws an error if the request fails or the environment variable is not set.
 */
export const exportContent = async (reportType: string, id: string) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_EXPORT!}?reportType=${reportType}&reportId=${id}`, {
      method: "GET",
      credentials: "include",
    });

    if (!response.ok) {
      const errorMsg = await response.text();
      showToast("Error exporting", "", "error");
      return;
    }

    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = `exported_${reportType}.pptx`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    showToast("PPT exported successfully.", "", "success");
  } catch (error) {
    showToast("Error exporting report", "", "error");
  }
};

/**
 * Get report.
 *
 * @param {string} id - id of report/story.
 * @returns {Promise<void>} A promise that resolves to the API response.
 * @throws {Error} Throws an error if the request fails or the environment variable is not set.
 */
export const getReport = async (id: string, reportType: string) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_REPORT!}/${id}?reportType=${reportType}`, {
      method: "GET",
      credentials: "include",
    });

    if (!response.ok) {
      const errorMsg = await response.text();
      showToast("Error in getting Report book", "", "error");
      return null;
    }
    return  await response.json();
  } catch (error) {
    showToast("Error getting report", "", "error");
  }
};
/**
 * Delete story.
 *
 * @param {string} id - id of report/story.
 * @returns {Promise<void>} A promise that resolves to the API response.
 * @throws {Error} Throws an error if the request fails or the environment variable is not set.
 */
export const deleteReport = async (id: string, reportType: string) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_REPORT!}/${id}?reportType=${reportType}`, {
      method: "DELETE",
      credentials: "include",
    });

    if (response.status !== 204) {
      showToast("Delete unsuccessful", "", "error");
    }

  } catch (error) {
    showToast("Error exporting report", "", "error");
  }
};
