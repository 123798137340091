import React from "react";
import {
  Box,
  Tag,
  TagLabel,
  TagCloseButton,
  Text,
  VStack,
  FormControl,
  Divider,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
} from "@chakra-ui/react";
import { ArrowForwardIcon, Icon, ChevronDownIcon } from "@chakra-ui/icons";
import { TemporalColumn } from "../../../containers/commentary/Interfaces";
import { CustomPeriod } from "../../../utils/enum";

const BenchmarkFilter: React.FC<{
  selectedPeriod: Array<{ name: string; value: string }> | undefined;
  selectedBenchmarks: TemporalColumn[];
  handleAddPeriod: (selectedValue: string, selectedName: string) => void;
  handleRemoveBenchmark: (index: number) => void;
  selectedTemporalColumnsFromDropdown: TemporalColumn[];
  uniquePresetBenchHeaders: string[];
}> = ({
  selectedPeriod,
  selectedBenchmarks,
  handleAddPeriod,
  handleRemoveBenchmark,
  selectedTemporalColumnsFromDropdown,
  uniquePresetBenchHeaders,
}) => {
  return (
    <Box>
      <Divider />
      <Text fontSize="sm" fontWeight="bold" mb={3} color="fg.default">
        TARGETS
      </Text>
      <VStack width="100%" spacing={2} align="left">
        <Box>
          {selectedBenchmarks && 
           selectedBenchmarks.map((benchmark, index) => (
            <Box
              width="100%"
              display="flex"
              flexWrap="wrap"
              gap={1}
              borderRadius="md"
            >
              <Tag
                key={index}
                variant="solid"
                bg="purple.50"
                color="fg.default"
                borderColor="purple.700"
                borderWidth="1px"
                mb={2}
                flexGrow={1}
              >
                <TagLabel
                  flexGrow={1}
                  textAlign="left"
                  fontSize="xs"
                  fontWeight="bold"
                >
                  <Icon as={ArrowForwardIcon} color="black" mr={1} />
                    {benchmark.custom_period?.name}
                </TagLabel>
                <TagCloseButton onClick={() => handleRemoveBenchmark(index)} />
              </Tag>
            </Box>
          ))}
        </Box>

        <Box>
          <FormControl size="sm">
            <Menu closeOnSelect={true}>
              <MenuButton
                as={Button}
                width="100%"
                rightIcon={<ChevronDownIcon />}
                fontSize="sm"
                border="1px"
                borderColor="gray.300"
                color="black"
                bg="bg.surface"
                borderRadius="md"
                fontWeight="normal"
                _hover={{ bg: "purple.50" }}
                justifyContent="space-between"
                textAlign="left"
              >
                {selectedPeriod && selectedPeriod.length > 0
                  ? selectedPeriod[0].name
                  : "Select Period"}
              </MenuButton>
              <MenuList>
                {selectedTemporalColumnsFromDropdown.length > 0 && (
                  <>
                    <MenuItem
                      onClick={() =>
                        handleAddPeriod(
                          CustomPeriod.PreviousPeriod,
                          "Previous period"
                        )
                      }
                      fontSize="sm"
                      fontWeight="medium"
                    >
                      Previous period
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        handleAddPeriod(
                          CustomPeriod.SamePeriodLastYear,
                          "Same period last year"
                        )
                      }
                      fontSize="sm"
                      fontWeight="medium"
                    >
                      Same period last year
                    </MenuItem>
                  </>
                )}
                {uniquePresetBenchHeaders &&
                  uniquePresetBenchHeaders.map((header, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => handleAddPeriod(header, header)}
                      fontSize="sm"
                      fontWeight="medium"
                    >
                      {header}
                    </MenuItem>
                  ))}
              </MenuList>
            </Menu>
          </FormControl>
        </Box>
      </VStack>
    </Box>
  );
};

export default BenchmarkFilter;
