import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Tooltip,
  Box,
  Flex,
  Text,
  Button,
  Badge,
  Icon,
  IconButton,
  HStack,
  ListItem,
  Image,
  SkeletonText,
  UnorderedList,
  OrderedList,
  Heading,
  Textarea,
  Spinner,
} from "@chakra-ui/react";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  CheckCircleIcon,
  RepeatIcon,
  WarningIcon,
  WarningTwoIcon,
  InfoIcon,
} from '@chakra-ui/icons'
import slack_logo from '../../assets/Slack_Icon.png'
import { Commentary } from '../../containers/commentary/Interfaces'
import { diffWords } from 'diff'
import ReactMarkdown from 'react-markdown'
import { updateCommentary } from '../../services/CommentaryServices'
import SlackConnect from './SlackConnect'
import { MdReplay } from 'react-icons/md'

interface CommentaryBoxProps {
  id: string
  libraryPage: boolean
  commentaryArray: Commentary[]
  setCommentaryArray: React.Dispatch<React.SetStateAction<Commentary[]>>
  activeIndex: number
  setActiveIndex: React.Dispatch<React.SetStateAction<number>>
  conversationId: string
  setConversationId: React.Dispatch<React.SetStateAction<string>>
  setCopiedText: React.Dispatch<React.SetStateAction<string>>
  copy: boolean
  setCopy: React.Dispatch<React.SetStateAction<boolean>>
  isDiff: boolean
  setIsDiff: React.Dispatch<React.SetStateAction<boolean>>
  isLoading: boolean
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  markdownRef: React.RefObject<HTMLDivElement>
  isFailed: boolean
  fetchCommentary: () => void
}

const CommentaryBox: React.FC<CommentaryBoxProps> = ({
  id,
  libraryPage,
  commentaryArray,
  setCommentaryArray,
  activeIndex,
  conversationId,
  setConversationId,
  setActiveIndex,
  copy,
  setCopy,
  setCopiedText,
  isDiff,
  setIsDiff,
  isLoading,
  setIsLoading,
  markdownRef,
  isFailed,
  fetchCommentary,
}) => {
  const [textareaValue, setTextareaValue] = useState<string>('')
  const [insightsArray, setInsightsArray] = useState<Array<string>>([])
  const [updateLoader, setUpdateLoader] = useState<boolean>(false)

  const handleSlackInfo = async () => {
    if (commentaryArray.length > 0 && commentaryArray[0].commentary) {
      try {
        const response = await SlackConnect({
          channel: 'D05SMS360ET', // Channel ID where the message will be sent
          message: commentaryArray[0].commentary, // The message text
        })
        console.log('Slack response:', response)
      } catch (error) {
        console.error('Error sending message to Slack:', error)
      }
    } else {
      console.error('No commentary available to send.')
    }
  }

  const handlePrev = () => {
    const newIndex =
      activeIndex > 0 ? activeIndex - 1 : commentaryArray.length - 1;
    setActiveIndex((prevIndex: number) =>
      prevIndex > 0 ? prevIndex - 1 : commentaryArray.length - 1
    );

    setConversationId(commentaryArray[newIndex].conversation_header.id!);
    setIsDiff(false);
  };

  const handleNext = () => {
    const newIndex = (activeIndex + 1) % commentaryArray.length;
    setActiveIndex(
      (prevIndex: number) => (prevIndex + 1) % commentaryArray.length
    );
    setConversationId(commentaryArray[newIndex].conversation_header.id!);
    setIsDiff(false);
  };

  const renderTextWithDiff = (
    originalText: string,
    updatedText: string
  ): React.ReactNode => {
    const diffResult = diffWords(originalText, updatedText);

    const regex = /\$|\.\d+[KM]?%?|\b\d+(?:,\d{3})*(\.\d+)?[KM]?%?\b/g;
    // const regex = /\$|\.?\d+(?:,\d{3})*(?:\.\d+)?[KM]?%?/g;  /***/

    const applyBoldFormatting = (text: string): string => {
      const words = text.split(" ");
      const formattedWords = words.map((word) => {
        regex.lastIndex = 0;

        const isMatch = regex.test(word);

        return isMatch ? `<b>${word}</b>` : word;
      });

      const formattedText = formattedWords.join(" ");

      return formattedText;
    };

    return diffResult.map((part: any, index: any) => {
      if (part.removed) {
        return null;
      }
      return (
        <Text as="span" key={index} bg={part.added ? "green.100" : undefined}>
          <span
            dangerouslySetInnerHTML={{
              __html: part.value || "",
            }}
          />
        </Text>
      );
    });
  };

  const renderContent: any = () => {
    // console.log('isLoading ', isLoading)
    // console.log('isFailed ', isFailed)
    if (isLoading) {
      return (
        <Box
          mt={3}
          mb={3}
          position="relative"
          padding="6"
          boxShadow="lg"
          bg="bg.surface"
          textAlign="center"
        >
          <Flex
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <Spinner size="lg" color="#4e1e6c" thickness="3px" />
            {libraryPage ? (
              <Text mt="2" fontSize="lg" fontWeight="semibold">
                Loading Commentaries...
              </Text>
            ) : (
              <Text mt="2" fontSize="lg" fontWeight="semibold">
                Generating Commentary...
              </Text>
            )}
          </Flex>
          <SkeletonText mt="4" noOfLines={6} spacing="4" />
        </Box>
      )
    } else if (isFailed) {
      return (
        <Flex alignItems="center" justifyContent="center" m="5">
          {libraryPage ? (
            <Text fontSize="lg" fontWeight="semibold">
              Loading Commentaries Failed!
            </Text>
          ) : (
            <Text fontSize="lg" fontWeight="semibold">
              Commentary Generation Failed!
            </Text>
          )}
          <IconButton
            icon={<MdReplay />}
            onClick={fetchCommentary}
            ml={2}
            mt={-3}
            aria-label="Retry"
            colorScheme="purple" // Changed to use purple color scheme
            backgroundColor="purple" // Specifically setting the background color
            _hover={{ bg: 'purple' }}
            isRound={true}
            size="md"
          />
        </Flex>
      )
    } else if (updateLoader) {
       return (
       <Box
        mt={3}
        mb={3}
        position="relative"
        padding="6"
        boxShadow="lg"
        bg="bg.surface"
        textAlign="center"
      >
        <Flex
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <Spinner size="lg" color="#4e1e6c" thickness="3px" />
          <Text mt="2" fontSize="lg" fontWeight="semibold">
            Updating Commentary...
          </Text>
        </Flex>
        <SkeletonText mt="4" noOfLines={6} spacing="4" />
      </Box>
       )
    } else {
      return (
        <Box p={2}>
          <Text
            align="left"
            lineHeight="150%"
            fontSize="sm"
            fontWeight="500"
            color="gray.800"
          >
            {isDiff
              ? commentaryArray.length > 0
                ? commentaryArray[activeIndex] &&
                  commentaryArray[activeIndex].commentary &&
                  commentaryArray[activeIndex].commentary &&
                  renderTextWithDiff(
                    commentaryArray[0].commentary,
                    commentaryArray[activeIndex].commentary
                  )
                : ""
              : commentaryArray && commentaryArray.length > 0
              ? commentaryArray[activeIndex] &&
                commentaryArray[activeIndex].commentary && (
                  <div ref={markdownRef}>
                    <ReactMarkdown
                      children={commentaryArray[activeIndex].commentary}
                      components={{
                        p: ({ node, ...props }) => (
                          <Text fontSize="sm" textAlign="left" {...props} />
                        ),
                        ul: ({ node, ...props }) => (
                          <UnorderedList
                            textAlign="left"
                            styleType="disc"
                            ml="4"
                            {...props}
                          />
                        ),
                        ol: ({ node, ...props }) => (
                          <OrderedList
                            textAlign="left"
                            styleType="decimal"
                            ml="4"
                            {...props}
                          />
                        ),
                        li: ({ node, ...props }) => (
                          <ListItem textAlign="left" fontSize="sm" {...props} />
                        ),
                        h1: ({ node, ...props }) => (
                          <Heading
                            as="h1"
                            size="sm"
                            textAlign="left"
                            fontWeight="900"
                            textDecoration="underline"
                            mb={4}
                            {...props}
                          />
                        ),
                        h2: ({ node, ...props }) => (
                          <Heading
                            as="h2"
                            size="sm"
                            textAlign="left"
                            fontWeight="black"
                            {...props}
                          />
                        ),
                        h3: ({ node, ...props }) => (
                          <Heading
                            as="h3"
                            size="xs"
                            textAlign="left"
                            fontWeight="bold"
                            {...props}
                          />
                        ),
                        h4: ({ node, ...props }) => (
                          <Heading
                            as="h4"
                            size="sm"
                            textAlign="left"
                            {...props}
                          />
                        ),
                        // Add other overrides as needed
                      }}
                    />
                  </div>
                )
              : ""}
          </Text>
        </Box>
      );
    }
  };

  // const [isLoading, setIsLoading] = useState<boolean>(true);
  const handleRegenerate = async () => {
    if (!textareaValue) {
      alert("Insights cannot be empty");
      return;
    }
    setUpdateLoader(true) // Set loading state to true before making the API call
    try {
      console.log("textarea value", textareaValue);
      const updatedCommentary:Commentary = await updateCommentary(id, textareaValue);

      console.log("commentaryUpdated: ", updatedCommentary!);

      if (updatedCommentary) {
        setActiveIndex(commentaryArray.length)

        setCommentaryArray((prevCommentaries) => [
          ...prevCommentaries,
          updatedCommentary
        ])
        setConversationId(
          updatedCommentary && updatedCommentary?.conversation_header.id!,
        )
        setInsightsArray((prevInsights) => [...prevInsights, textareaValue])
      }
    } catch (error) {
      console.error("Error updating commentary:", error);
    } finally {
      setUpdateLoader(false)
    }
  };
  const handleTextareaChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setTextareaValue(event.target.value);
  };

  useEffect(() => {
    if (commentaryArray.length > 0 && commentaryArray[activeIndex]) {
      setTextareaValue(commentaryArray[activeIndex].insights || "");
    }
  }, [activeIndex, commentaryArray]);

  console.log('commentary array', commentaryArray)
  const validationStatus =
    commentaryArray.length && commentaryArray[0]?.validation
      ? commentaryArray[0]?.validation!.split(' ')[0]
      : 'INVALID' // Get "VALID" or "INVALID"
  // const confidenceLevel = commentaryArray.length ? commentaryArray[0]?.validation!.split(' ')[1] : null // Get "HIGH", "MEDIUM", or "LOW"
  return (
    <Box mb={2} p={2} borderRadius={14} bg="bg.surface">
      {renderContent()}
      <Flex mb={2} alignItems="center" position="relative">
        <Textarea
          value={textareaValue}
          onChange={handleTextareaChange}
          fontSize="sm"
          placeholder="Add insights..."
          pr="4.5rem" // Ensure enough padding on the right for the button
          borderColor="gray.300" // Set a lighter border color for the Textarea
          focusBorderColor="#4e1e6c"
          borderRadius="md"
          bg="bg.surface"
          readOnly={activeIndex < commentaryArray.length - 1}
          isDisabled={activeIndex < commentaryArray.length - 1 ? true : false}
          sx={{ resize: "none" }}
          colorScheme="purple"
        />

        <Button
          colorScheme="purple" // This will determine the hover background color
          size="lg"
          position="absolute"
          right="1"
          zIndex={2}
          px={2}
          py={1}
          bg="transparent" // Make button background transparent
          _hover={{
            bg: "purple.100",
            transform: "scale(1.1)",
            borderRadius: "full",
          }} // Slight background on hover and scale effect
          iconSpacing={0} // Remove space between icon and button edge if there's no text
          onClick={handleRegenerate}
          isDisabled={isLoading || isFailed || updateLoader}
        >
          <Icon as={RepeatIcon} color="#4e1e6c" fontSize="xl" boxSize={7} />
        </Button>
      </Flex>
      <Flex justify="space-between" align="center" mt={0}>
        <HStack
          justify="left"
          spacing={1}
          mt={0}
          ml={3}
          mr={3}
          mb={0}
          alignItems="center"
        >
          <Tooltip label="prev" aria-label="prev-page">
            <Button
              onClick={handlePrev}
              size="xs"
              leftIcon={<ChevronLeftIcon />}
              colorScheme="blue"
              bg="gray.300"
              borderRadius={14}
              color="black"
              _hover={{
                bg: "#4e1e6c",
                transform: "scale(1.1)",
                color: "white",
              }}
              p={0}
              iconSpacing={0}
            />
          </Tooltip>
          <Box
            minWidth="3em"
            textAlign="center"
            bg="purple.50"
            borderRadius={14}
            p={1}
          >
            <Text fontSize="xs" color="purple.800" mb={0} mt={0}>
              {`${activeIndex + 1}/${commentaryArray.length}`}
            </Text>
          </Box>
          <Tooltip label="next" aria-label="next-page">
            <Button
              onClick={handleNext}
              size="xs"
              rightIcon={<ChevronRightIcon />}
              colorScheme="purple"
              bg="gray.300"
              borderRadius={14}
              color="black"
              p={0}
              _hover={{
                bg: "#4e1e6c",
                transform: "scale(1.1)",
                color: "white",
              }}
              iconSpacing={0}
            />
          </Tooltip>
        </HStack>
        {process.env.REACT_APP_SLACK_ENABLE === "true" && (
          <HStack spacing={1} mt={0} ml={3} mr={3} mb={0}>
            <IconButton
              p={2}
              icon={<Image src={slack_logo} alt="Slack" boxSize="30px" />}
              onClick={handleSlackInfo}
              aria-label="Ping Slack"
              bg="bg.surface"
              color="black"
              size="sm" // Adjust size as needed
              _hover={{
                color: "white",
                transform: "rotate(90deg)", // Rotates the button by 90 degrees on hover
              }}
              sx={{
                transition: "transform 0.2s ease-in-out", // Smooth transition for the transform
              }}
            />
          </HStack>
        )}

        <Badge
          colorScheme={validationStatus === "VALID" ? "green" : "red"}
          fontSize="xs"
          fontWeight="medium"
          mt={3}
          mr={1}
          mb={1}
          px={3}
          py={1}
          borderRadius="full"
          alignItems="center"
          color="white"
          bg={validationStatus === "VALID" ? "green" : "red"}
        >
          <Flex alignItems="center">
            <Icon
              as={validationStatus === "VALID" ? CheckCircleIcon : WarningIcon}
              color="white"
              mr={1}
            />
            <Box color="white" fontWeight="bold" letterSpacing="wide">
              {validationStatus === "VALID" ? "Valid" : "Invalid"}
            </Box>
            {/* <Box color="white" ml={5} fontWeight="medium">
              Confidence: {confidenceLevel}
            </Box> */}
          </Flex>
        </Badge>
      </Flex>
    </Box>
  );
};

export default CommentaryBox;
