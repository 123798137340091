import React, { useEffect, useState } from "react";
import { listMetrics, getMetricById } from "../../services/MetricService";
import { Header, Metric } from "../Interfaces";
import { Center, Spinner } from "@chakra-ui/react";
import ViewMKGNetwork from "./ViewMKGNetwork";
import showToast from "../../hooks/useCustomToast";

const ViewMKGContainer: React.FC = () => {
  const [metricList, setMetricList] = useState<Header[]>();
  const [metricInfoMap, setMetricInfoMap] = useState<Map<string, Metric>>(
    new Map()
  ); 
  const [loading, setLoading] = useState<boolean>(true);
  // API call to fetch all metrics
  const fetchData = async () => {
    try {
      const data = await listMetrics(""/* modelId */);;
      setMetricList(data);
    } catch (error) {
      showToast("Error fetching data", "", "error");
    }
  };

  // Fetch metric data when the component mounts
  useEffect(() => {
    fetchData();
  }, []);

  // Fetch data for each metric header
  useEffect(() => {
    const fetchMetricInfo = async () => {
      if (!metricList) return;

      try {
        // Array of promises to fetch metric data for each metric header
        const promises = metricList.map((metricHeader) =>
          // parameter : metricId and include_edges=true
          getMetricById(metricHeader.id!, true)
        );

        // Wait for all promises to resolve
        const metricInfoArray = await Promise.all(promises);

        // Create a Map of metric data mapped by metric ID
        const dataMap = new Map<string, Metric>();
        metricList.forEach((metricHeader, index) => {
          dataMap.set(metricHeader.id!, metricInfoArray[index]);
        });

        // Update state with the Map of metric data
        setMetricInfoMap(dataMap);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching metric data:", error);
      }
    };

    fetchMetricInfo();
  }, [metricList]);

  console.log("metricInfo map", metricInfoMap);
  return (
    <>
      {loading ? (
        // Render a loader while loading is true
        <Center h="100vh">
          <Spinner size="xl" color="#purple.700"/>
        </Center>
      ) : (
        // Render ViewMKGNetwork component when loading is false
        <ViewMKGNetwork
          metricInfoMap={metricInfoMap}
        />
      )}
    </>
  );
};

export default ViewMKGContainer;
