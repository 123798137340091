import React from "react";
import {
  HStack,
  Text,
  Button,
  Input,
  InputGroup,
  InputRightElement,
  IconButton,
  Icon,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Box,
} from "@chakra-ui/react";
import { FaCalendarAlt, FaChevronDown, FaTimes } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useBatchContext } from "../../context/batch/BatchContext";
import { Frequency } from "../../utils/enum";

const GlobalDateFilter: React.FC = () => {
  const { 
    setDate, 
    date, 
    setGlobalDateFilter, 
    globatDateFilter,
    frequency,
    setFrequency,
    startDate,
    setStartDate,
    endDate,
    setEndDate ,
  } = useBatchContext();

  const handleFrequencySelect = (selectedFrequency: Frequency) => {
    setFrequency(selectedFrequency);
    // Reset dates and time bucket name when frequency changes
    setStartDate(null);
    setEndDate(null);
  };
  

  const handleReset = () => {
    setFrequency("");
    setStartDate(null);
    setEndDate(null);
    setDate((prevDates) => ({
      ...prevDates,
      ["Date"]: [],
    }));
  };
  
  // Function to determine date picker props based on frequency
  const getDatePickerProps = () => {
    switch (frequency) {
      case "Daily":
        return {
          dateFormat: "MM/dd/yyyy",
          showPopperArrow: false,
        };
      case "Weekly":
        return {
          dateFormat: "MM/dd/yyyy",
          showPopperArrow: false,
        };
      case "Monthly":
        return {
          dateFormat: "MM/yyyy",
          showMonthYearPicker: true,
          showPopperArrow: false,
        };
      case "Quarterly":
        return {
          dateFormat: "QQQ yyyy",
          showMonthYearPicker: true,
          showPopperArrow: false,
        };
      case "Yearly":
        return {
          dateFormat: "yyyy",
          showYearPicker: true,
          showPopperArrow: false,
        };
      default:
        return {
          dateFormat: "MM/dd/yyyy",
          showPopperArrow: false,
        };
    }
  };

  // Custom input component integrating with Chakra UI
  const CustomInput = ({ value, onClick }: any) => (
    <InputGroup size="sm">
      <Input
        value={value}
        onClick={onClick}
        readOnly
        placeholder="Select Date"
      />
      <InputRightElement>
        <IconButton
          aria-label="Open Calendar"
          icon={<FaCalendarAlt />}
          size="sm"
          onClick={onClick}
        />
      </InputRightElement>
    </InputGroup>
  );

  // Handle date change based on frequency
  const handleDateChange = (date: Date | null) => {
    if (!date) return;

    let calculatedStartDate: Date;
    let calculatedEndDate: Date;

    switch (frequency) {
      case "Weekly":
        // Calculate start (Monday) and end (Sunday) of the week
        calculatedStartDate = new Date(date);
        calculatedStartDate.setDate(date.getDate());
        calculatedEndDate = new Date(calculatedStartDate);
        calculatedEndDate.setDate(calculatedStartDate.getDate() + 6);
        break;

      case "Quarterly":
        // Calculate start and end of the quarter
        const month = date.getMonth();
        const quarterStartMonth = Math.floor(month / 3) * 3;
        const quarterEndMonth = quarterStartMonth + 2;
        calculatedStartDate = new Date(date.getFullYear(), quarterStartMonth, 1);
        calculatedEndDate = new Date(date.getFullYear(), quarterEndMonth + 1, 0); // Last day of the quarter
        break;

      case "Monthly":
        // Start and end of the month
        calculatedStartDate = new Date(date.getFullYear(), date.getMonth(), 1);
        calculatedEndDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        break;

      case "Yearly":
        // Start and end of the year
        calculatedStartDate = new Date(date.getFullYear(), 0, 1);
        calculatedEndDate = new Date(date.getFullYear(), 11, 31);
        break;

      default:
        // For daily frequency, start and end date are the same
        calculatedStartDate = date;
        calculatedEndDate = date;
        break;
    }

    setStartDate(calculatedStartDate);
    setEndDate(calculatedEndDate);
    if (calculatedStartDate && calculatedEndDate) {
        // Update the date in context
        setDate((prevDates) => ({
          ...prevDates,
          ["Date"]: [formatDate(calculatedStartDate), formatDate(calculatedEndDate)],
        }));
    
        // Set the time bucket name based on frequency and date range
        let timeBucket = "";
        switch (frequency) {
          case "Daily":
            timeBucket = formatDate(calculatedStartDate);
            break;
          case "Weekly":
            timeBucket = `${formatDate(calculatedStartDate)} - ${formatDate(calculatedEndDate)}`;
            break;
          case "Monthly":
            timeBucket = calculatedStartDate.toLocaleString('default', { month: 'long', year: 'numeric' });
            break;
          case "Quarterly":
            const quarter = Math.floor(calculatedStartDate.getMonth() / 3) + 1;
            timeBucket = `Q${quarter} ${calculatedStartDate.getFullYear()}`;
            break;
          case "Yearly":
            timeBucket = calculatedStartDate.getFullYear().toString();
            break;
          default:
            timeBucket = formatDate(calculatedStartDate);
        }
        setGlobalDateFilter(timeBucket); // Update the global date filter here
      }
  };

  // Function to format selected dates for display
  const formatDateDisplay = () => {
      if (!startDate || date) return "";
      const options: Intl.DateTimeFormatOptions = {
        year: "numeric",
      };
      let display = "";
      switch (frequency) {
        case "Weekly":
          options.month = "short";
          options.day = "numeric";
          const endOfWeek = new Date(startDate);
          endOfWeek.setDate(startDate.getDate() + 6);
          display = `${startDate.toLocaleDateString(undefined, options)} - ${endOfWeek.toLocaleDateString(
            undefined,
            options
          )}`;
          break;
        case "Monthly":
          options.month = "long";
          display = startDate.toLocaleDateString(undefined, options);
          break;
        case "Quarterly":
          const quarter = Math.floor(startDate.getMonth() / 3) + 1;
          display = `Q${quarter} ${startDate.getFullYear()}`;
          break;
        case "Yearly":
          display = startDate.getFullYear().toString();
          break;
        default:
          options.month = "short";
          options.day = "numeric";
          display = startDate.toLocaleDateString(undefined, options);
          break;
      }
      return display;
  };

  // Utility function to format Date objects to 'MM/DD/YYYY'
  const formatDate = (date: Date | null): string => {
    if (!date) return "";
    return date.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    });
  };

  return (
    <HStack spacing={3} alignItems="center">
      {/* Time Period Selection */}
      <HStack>
        <Icon as={FaCalendarAlt} boxSize={4} />
        <Text fontSize="xs" fontWeight="bold" color="gray.800" mb={0} whiteSpace="nowrap">
          Time Period
        </Text>
      </HStack>
      {!frequency ? (
        // Frequency Selection Menu
        <Menu>
          <MenuButton
            as={Button}
            w="100%"
            variant="outline"
            colorScheme="gray"
            rightIcon={<Icon as={FaChevronDown} boxSize={3} />} // Dropdown arrow icon
            size="xs"
            bg="white"
            fontWeight={600}
            fontSize="xs"
            _hover={{ bg: "purple.50" }}
            textAlign="left"
            color="gray.600"
          >
            Select Period
          </MenuButton>
          <MenuList>
            {/* Daily Frequency */}
            <MenuItem onClick={() => handleFrequencySelect("Daily")} icon={<Icon as={FaCalendarAlt} boxSize={4} />}>
              <Text fontSize="xs" fontWeight="semibold" mb={0}>
                Day
              </Text>
            </MenuItem>
            {/* Weekly Frequency */}
            <MenuItem onClick={() => handleFrequencySelect("Weekly")} icon={<Icon as={FaCalendarAlt} boxSize={4} />}>
              <Text fontSize="xs" fontWeight="semibold" mb={0}>
                Week
              </Text>
            </MenuItem>
            {/* Monthly Frequency */}
            <MenuItem onClick={() => handleFrequencySelect("Monthly")} icon={<Icon as={FaCalendarAlt} boxSize={4} />}>
              <Text fontSize="xs" fontWeight="semibold" mb={0}>
                Month
              </Text>
            </MenuItem>
            {/* Quarterly Frequency */}
            <MenuItem onClick={() => handleFrequencySelect("Quarterly")} icon={<Icon as={FaCalendarAlt} boxSize={4} />}>
              <Text fontSize="xs" fontWeight="semibold" mb={0}>
                Quarter
              </Text>
            </MenuItem>
            {/* Yearly Frequency */}
            <MenuItem onClick={() => handleFrequencySelect("Yearly")} icon={<Icon as={FaCalendarAlt} boxSize={4} />}>
              <Text fontSize="xs" fontWeight="semibold" mb={0}>
                Year
              </Text>
            </MenuItem>
          </MenuList>
        </Menu>
      ) : (
        // Date Picker and Display
        <HStack spacing={2} align="center">
          {!startDate && (
            <DatePicker
              selected={startDate}
              onChange={handleDateChange}
              customInput={<CustomInput />}
              {...getDatePickerProps()}
              minDate={new Date(1980, 0, 1)}
              maxDate={new Date()}
              showDisabledMonthNavigation
            />
          )}
          {/* Display selected date or range */}
          {startDate && (
            <Box bg="#fff2f8" borderRadius="md" borderColor="purple.400" borderWidth="2px" p={1} px={2}>
              <Text fontSize="sm" color="gray.800" fontWeight="bold" mb={0}>
                {globatDateFilter && date? globatDateFilter: formatDateDisplay()}
              </Text>
            </Box>
          )}
          {/* Reset Button */}
          <Button
            size="xs"
            variant="solid"
            borderRadius="full"
            onClick={handleReset}
            leftIcon={<Icon as={FaTimes} boxSize={4} />}
            colorScheme="gray"
            color="red.500"
            iconSpacing={0}
          />
        </HStack>
      )}
    </HStack>
  );
};

export default GlobalDateFilter;
