import React from "react";
import { Link } from "react-router-dom";
import Layout from "../components/layout/Layout";
import "./CSS/HomePage.css";
import logo from "../assets/logo.png";
import { Box, keyframes, Stack, Heading, Text, Button, Circle, Avatar, VStack } from "@chakra-ui/react";
import { FaExternalLinkAlt } from "react-icons/fa";
const rotateAnimation = keyframes`
  0% { transform: rotate(0deg); }
  80% { transform: rotate(0deg); }
  100% { transform: rotate(120deg); }
`;

const HomePage: React.FC = () => {
  const animation = `${rotateAnimation} 3s linear infinite`;

  return (
      <Box
        width="100%"
        height="100vh"
        overflow="hidden"
        bg="purple.50"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <VStack w="70%">
        <Circle bg="purple.50" color="fg.default" borderWidth="3px" borderColor="purple.700" p={1} mb={9}>
          <Avatar
            size="md"
            src={logo}
            name="Anko"
            animation={animation}
            color="black"
          />
        </Circle>
        
        <Stack spacing="8" direction="column" width="100%" maxW="4xl" textAlign="left">
          <Box
            bg="bg.surface"
            boxShadow="sm"
            borderRadius="xl"
            px={{ base: '6', lg: '8' }}
            py={{ base: '6', lg: '8' }}
          >
            <Stack spacing="8" direction={{ base: 'column', lg: 'row' }} justify="space-between">
              <Stack spacing="3" w="80%">
                <Heading size="xs">View Analysis</Heading>
                <Text color="fg.muted" fontSize="lg">
                  Go deep into analyses previously performed by Anko
                </Text>
              </Stack>
              <Stack spacing="3" direction={{ base: 'column', sm: 'row' }} justify="center" align="center" w="20%">
              <Button colorScheme="purple" as={Link} to="/analyze" size="xl" w="100%"
                rightIcon={<FaExternalLinkAlt size="0.75em"/>}
                _hover={{color:"white"}}>
                  Analysis
                </Button>
              </Stack>
            </Stack>
          </Box>
          
          <Box
            bg="bg.surface"
            boxShadow="sm"
            borderRadius="xl"
            px={{ base: '6', lg: '8' }}
            py={{ base: '6', lg: '8' }}
          >
            <Stack spacing="8" direction={{ base: 'column', lg: 'row' }} justify="space-between">
              <Stack spacing="3" w="80%">
                <Heading size="xs">Create Analysis</Heading>
                <Text color="fg.muted" fontSize="lg">
                  Provide guidelines to Anko to start a new analysis
                </Text>
              </Stack>
              <Stack spacing="3" direction={{ base: 'column', sm: 'row' }} justify="center" align="center" w="20%">
              <Button colorScheme="purple" as={Link} to="/create" size="xl" w="100%"
                rightIcon={<FaExternalLinkAlt size="0.75em"/>}
                _hover={{color:"white"}}>
                  Create
                </Button>
              </Stack>
            </Stack>
          </Box>
          
          <Box
            bg="bg.surface"
            boxShadow="sm"
            borderRadius="xl"
            px={{ base: '6', lg: '8' }}
            py={{ base: '6', lg: '8' }}
          >
            <Stack spacing="8" direction={{ base: 'column', lg: 'row' }} justify="space-between">
              <Stack spacing="3" w="80%">
                <Heading size="xs">Knowledge Graph</Heading>
                <Text color="fg.muted" fontSize="lg">
                  The knowledge that grounds Anko in truth
                </Text>
              </Stack>
              <Stack spacing="3" direction={{ base: 'column', sm: 'row' }} justify="center" align="center" w="20%">
              <Button colorScheme="purple" as={Link} to="/metric" size="xl" w="100%"
                rightIcon={<FaExternalLinkAlt size="0.75em"/>}
                _hover={{color:"white"}}>
                  Setup
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Stack>
        </VStack>
      </Box>
  );
};

export default HomePage;