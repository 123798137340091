import React from "react";
import {
  Flex,
  Box,
  Text,
  Tag,
  TagLabel,
  TagCloseButton,
  HStack,
  Icon,
  Divider,
} from "@chakra-ui/react";
import { useBatchContext } from "../../context/batch/BatchContext";
import MKGTraversalContainer from "./analysisPlan/MKGTraversalContainer";
import { RiTodoLine } from "react-icons/ri";

const CreateStepContainer: React.FC = () => {
  const {
    selectedEdgeToExclude,
    setSelectedEdgeToExclude,
    selectedContextMap,
    setSelectedContextMap,
    customBenchmark,
    setCustomBenchmark,
    selectedPurpose,
    setSelectedPurpose,
    setCustomBenchmarkName,
    slideName,
    setSlideName,
    slackChannelId,
    setSlackChannelId
  } = useBatchContext();

  // Remove an attribute from selectedContextMap
  const removeFromContextMap = (attribute: string) => {
    setSelectedContextMap((prevMap) => {
      const newMap = { ...prevMap };
      delete newMap[attribute];
      return newMap;
    });
  };

  // Remove an attribute from selectedEdgeToExclude
  const removeFromExcludeEdges = (edge: string) => {
    setSelectedEdgeToExclude((prevEdges: string[]) =>
      prevEdges.filter((excludedEdge) => excludedEdge !== edge)
    );
  };

  // Remove an attribute from selectedEdgeToExclude
  const removeFromCustomBenchmark = (bench: {name: string, id: string}) => {
    setCustomBenchmark((prev) =>
      prev.filter((benchmark) => benchmark.name !== bench?.name!)
    );
    setCustomBenchmarkName((prev) => 
      prev.filter((benchmark) => benchmark !== bench?.name!)
    )
  };

  const resetPurpose = () => {
    setSelectedPurpose("");
  };

  const resetSlideName = () => {
    setSlideName("");
  };

  const resetChannelId = () => {
    setSlackChannelId("");
  };

  return (
    <Flex direction="row" width="100%" height="95%">
      <Box
        borderRadius="md"
        height="100%"
        width="100%"
        display="flex"
        flexDirection="column"
      >
        {/* Header with Title and Filters */}
        <HStack mb={2}>
          <Divider m={0} />
          <Icon as={RiTodoLine} boxSize={5} />
          <Text
            textStyle="sm"
            fontWeight="bold"
            whiteSpace="nowrap"
            mb={0}
            color="gray.600"
          >
            Step Breakdown
          </Text>
          <Divider m={0} />
        </HStack>
        <Box
          display="flex"
          flexWrap="wrap"
          p={2}
          pl={4}
          justifyContent="flex-start"
        >
          {/* Display selected context map as tags */}
          {Object.keys(selectedContextMap)
            .filter((contextKey) => contextKey !== "Date")
            .map((contextKey, index) => (
              <Tag
                key={index}
                variant="solid"
                bg="#fff2f8"
                color="black"
                size="sm"
                m={1}
                p={2}
                py={1}
                borderRadius="xl"
              >
                <TagLabel fontSize="xs" fontWeight="semibold">
                  {contextKey} = {selectedContextMap[contextKey].join(", ")}
                </TagLabel>
                <TagCloseButton
                  onClick={() => removeFromContextMap(contextKey)}
                />
              </Tag>
            ))}

          {customBenchmark.map((bench, index) => (
            <Tag
              key={index}
              variant="solid"
              bg="#fff2f8"
              color="black"
              size="sm"
              m={1}
              p={2}
              py={1}
              borderRadius="xl"
            >
              <TagLabel fontSize="xs" fontWeight="semibold">
                Compare to {bench.name}
              </TagLabel>
              <TagCloseButton
                onClick={() => removeFromCustomBenchmark(bench)}
              />
            </Tag>
          ))}

          {selectedPurpose && (
            <Tag
              variant="solid"
              bg="#fff2f8"
              color="black"
              size="sm"
              m={1}
              p={2}
              py={1}
              borderRadius="xl"
            >
              <TagLabel fontSize="xs" fontWeight="semibold">
              {selectedPurpose} Analysis
              </TagLabel>
              <TagCloseButton onClick={() => resetPurpose()} />
            </Tag>
          )}

          {/* Display selected edges to exclude as tags */}
          {selectedEdgeToExclude.map((edge, index) => (
            <Tag
              key={index}
              variant="solid"
              bg="#fff2f8"
              color="black"
              size="sm"
              m={1}
              p={2}
              py={1}
              borderRadius="xl"
            >
              <TagLabel fontSize="xs" fontWeight="semibold">
                Exclude {edge} from Step
              </TagLabel>
              <TagCloseButton onClick={() => removeFromExcludeEdges(edge)} />
            </Tag>
          ))}

          {slideName && (
            <Tag
              variant="solid"
              bg="#fff2f8"
              color="black"
              size="sm"
              m={1}
              p={2}
              py={1}
              borderRadius="xl"
            >
              <TagLabel fontSize="xs" fontWeight="semibold">
                Add to slide
              </TagLabel>
              <TagCloseButton onClick={() => resetSlideName()}/>
            </Tag>
          )}
          {slackChannelId && (
            <Tag
              variant="solid"
              bg="#fff2f8"
              color="black"
              size="sm"
              m={1}
              p={2}
              py={1}
              borderRadius="xl"
            >
              <TagLabel fontSize="xs" fontWeight="semibold">
                Notify on Slack
              </TagLabel>
              <TagCloseButton onClick={() => resetChannelId()}/>
            </Tag>
          )}
        </Box>

        {/* MKGTraversalContainer */}
        <Box flex="1">
          <MKGTraversalContainer />
        </Box>
      </Box>
    </Flex>
  );
};

export default CreateStepContainer;
