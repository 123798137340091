import React, { useEffect, useState } from "react";
import { Metric } from "../Interfaces";
import {
  DataSet,
  Network,
  Options,
} from "vis-network/standalone/esm/vis-network";
import "vis-network/styles/vis-network.css";
import NodePopup from "./NodePopup";

interface ViewMKGNetworkProps {
  metricInfoMap: Map<string, Metric>;
}

const ViewMKGNetwork: React.FC<ViewMKGNetworkProps> = ({
  metricInfoMap,
}) => {
  const [selectedNode, setSelectedNode] = useState<{ id: string; } | null>(null);
  const [network, setNetwork] = useState<Network | null>(null);

  useEffect(() => {
    if (metricInfoMap && metricInfoMap.size > 0) {
      const metricNodesData: any[] = [];
      const metricEdgesData: any[] = [];
      const attributeMap: Map<string, string> = new Map();
      const benchmarkMap: Map<string, string> = new Map();
      const fundamentalMap: Map<string, string> = new Map();

      const addUniqueNode = (node: any) => {
        if (!metricNodesData.find(existingNode => existingNode.id === node.id)) {
          metricNodesData.push(node);
        }
      };

      metricInfoMap.forEach((metric, metricId) => {
        const metricNode = {
          id: metricId,
          label: metric.header.name,
          color: {
            background: "#481c64",
            border: "#481c64",
            highlight: {
              color: "white",
              background: "#8133b2",
              border: "#8133b2"
            },
          },
          font: {
            color: "white",
            highlight: {
              color: "white",
            },
          },
          shape: "box",
          type: "metric",
        };

        addUniqueNode(metricNode);
      });

      metricInfoMap.forEach((metric, metricId) => {
        metric?.edges?.forEach((edge, index) => {
          if (edge.edge_type === "ATTRIBUTE_EDGE" && edge.related_attribute?.name) {
            const attributeName = edge.related_attribute.name;
            let attributeNodeId = attributeMap.get(attributeName);

            if (!attributeNodeId) {
              attributeNodeId = `attribute_${attributeName}`;
              attributeMap.set(attributeName, attributeNodeId);

              const attributeNode = {
                id: attributeNodeId,
                label: attributeName,
                color: {
                  background: "#3182ce",
                  border: "#3182ce",
                  highlight: {
                    color: "white",
                    background: "#2b6cb0",
                    border: "#2b6cb0",
                  },
                },
                font: {
                  color: "white",
                  highlight: {
                    color: "white",
                  },
                },
                shape: "box",
                type: "attribute",
              };

              addUniqueNode(attributeNode);
            }

            const attributeEdge = {
              from: metricId,
              to: attributeNodeId,
              label: `${String(edge.edge_weight)}`,
              arrows: "to",
              font: {
                align: "top",
                size: 14,
                color: "#3182ce",
              },
              color: { color: "#4299e1", highlight: "#3182ce", hover: "#fff2f8" },
            };

            metricEdgesData.push(attributeEdge);
          }

          if (edge.edge_type === "BENCHMARK_EDGE" && edge.benchmark?.header.name) {
            const benchmarkName = edge.benchmark.header.name;
            let benchmarkNodeId = benchmarkMap.get(benchmarkName);

            if (!benchmarkNodeId) {
              benchmarkNodeId = `benchmark_${benchmarkName}`;
              benchmarkMap.set(benchmarkName, benchmarkNodeId);

              const benchmarkNode = {
                id: benchmarkNodeId,
                label: benchmarkName,
                color: {
                  background: "#DD6B20",
                  border: "#DD6B20",
                  highlight: {
                    color: "white",
                    background: "#C05621",
                    border: "#C05621"
                  },
                },
                font: {
                  color: "white",
                  highlight: {
                    color: "white",
                  },
                },
                shape: "box",
                type: "benchmark",
              };

              addUniqueNode(benchmarkNode);
            }

            const benchmarkEdge = {
              from: metricId,
              to: benchmarkNodeId,
              label: `${String(edge.edge_weight)}`,
              arrows: "to",
              font: {
                align: "top",
                size: 14,
                color: "#DD6B20",
              },
              color: { color: "#ED8936", highlight: "#DD6B20" },
            };

            metricEdgesData.push(benchmarkEdge);
          }

          if (edge.edge_type === "FUNDAMENTAL_EDGE" && edge.fundamental_relationship?.header?.name) {
            edge.fundamental_relationship.correlations.forEach((correlation, corrIndex) => {
              if (correlation.related_metric_header) {
                const correlationMetricName = correlation.related_metric_header.name;
                const correlationMetricId = correlation.related_metric_header.id;

                // Check if the metricNode exists first
                let correlationMetricNodeId = metricNodesData.find(node => node.id === correlationMetricId) ? correlationMetricId : "";
                console.log("id", correlationMetricId);
                if (!correlationMetricNodeId) {
                  correlationMetricNodeId = `metric_${correlationMetricName}`;
                  fundamentalMap.set(correlationMetricName!, correlationMetricNodeId);
                  const fundamentalNode = {
                    id: correlationMetricNodeId,
                    label: correlationMetricName,
                    color: {
                      background: "#38A169",
                      border: "#38A169",
                      highlight: {
                        color: "white",
                        background: "#2F855A",
                        border: "#2F855A",
                      },
                    },
                    font: {
                      color: "white",
                      highlight: {
                        color: "white",
                      },
                    },
                    shape: "box",
                    type: "fundamental",
                  };

                  addUniqueNode(fundamentalNode);
                }

                metricEdgesData.push({
                  from: metricId,
                  to: correlationMetricNodeId,
                  label: `${edge.fundamental_relationship?.header?.name} (${String(edge.edge_weight)})`,
                  arrows: "to",
                  font: {
                    align: "top",
                    size: 14,
                  },
                  color: { color: "#38A169", highlight: "#2F855A", hover: "#fff2f8" },
                });
              }
              
              if (correlation.related_column_header) {
                const correlationColumnName = correlation.related_column_header.name;
                let correlationColumnNodeId = fundamentalMap.get(correlationColumnName!);
                if (!correlationColumnNodeId) {
                  correlationColumnNodeId = `column_${correlationColumnName}`;
                  fundamentalMap.set(correlationColumnName!, correlationColumnNodeId);
                  const fundamentalColumnNode = {
                    id: correlationColumnNodeId,
                    label: correlationColumnName,
                    color: {
                      background: "#38A169",
                      border: "#38A169",
                      highlight: {
                        color: "white",
                        background: "#2F855A",
                        border: "#2F855A",
                      },
                    },
                    font: {
                      color: "white",
                      highlight: {
                        color: "white",
                      },
                    },
                    shape: "box",
                    type: "fundamental",
                  };

                  addUniqueNode(fundamentalColumnNode);
                }
                metricEdgesData.push({
                  from: metricId,
                  to: correlationColumnNodeId,
                  label: `${edge.fundamental_relationship?.header?.name}(${String(edge.edge_weight)})`,
                  arrows: "to",
                  font: {
                    align: "top",
                    size: 14,
                  },
                  color: { color: "#38A169", highlight: "#2F855A", hover: "#fff2f8" },
                });
              }
            });
          }
        });
      });

      const metricNodes = new DataSet(metricNodesData);
      const metricEdges = new DataSet(metricEdgesData);

      const metricContainer = document.getElementById("mkg-network");
      const metricData = {
        nodes: metricNodes,
        edges: metricEdges,
      };

      const options: Options = {
        nodes: {
          shape: "box",
          font: {
            size: 21,
            face: "helvetica",
          },
          borderWidth: 2,
          borderWidthSelected: 4,
        },
        edges: {
          arrows: {
            to: { enabled: true, scaleFactor: 0.33 },
          },
          width: 1.5,
          smooth: {
            enabled: true,
            type: "continuous",
            roundness: 0.5,
          },
        },
        layout: {
          improvedLayout: true,
          randomSeed: 7,
        },
        physics: {
          enabled: true,
          solver: 'barnesHut',
          barnesHut: {
            gravitationalConstant: -20000,
            centralGravity: 0.33,
            springLength: 84,
            springConstant: 0.04,
            damping: 0.09,
            avoidOverlap: 1,
          },
          stabilization: {
            enabled: true,
            iterations: 200,
            updateInterval: 25,
            onlyDynamicEdges: false,
            fit: true,
          },
        },
        interaction: {
          dragNodes: true,
          zoomView: true,
          dragView: true,
        },
      };

      const metricNetwork = new Network(metricContainer!, metricData, options);
      setNetwork(metricNetwork);

      metricNetwork.on("stabilizationIterationsDone", function () {
        metricNetwork.setOptions({ physics: { enabled: false } });
      });

      metricNetwork.on("click", function (params) {
        if (params.nodes.length > 0) {
          const nodeId = params.nodes[0];
          setSelectedNode({ id: nodeId });

          // Reset all edges to their original colors
          const originalEdges = metricEdges.get().map(edge => ({
            id: edge.id,
            color: {
              color: edge.color.highlight, // assuming highlight is the original color
              highlight: edge.color.highlight,
              hover: edge.color.hover,
            },
            font: {
              color: edge.color.highlight,
              strokeWidth: 0,
              align: 'top'
            },
          }));
          metricEdges.update(originalEdges);

          // Highlight the edges connected to the selected node
          const connectedEdges = metricNetwork.getConnectedEdges(nodeId);
          const updatedEdges = metricEdges.get().map(edge => ({
            id: edge.id,
            color: {
              color: connectedEdges.includes(edge.id) ? edge.color.color : "rgba(200,200,200,0.3)",
              highlight: edge.color.highlight,
              hover: edge.color.hover,
            },
            font: {
              color: connectedEdges.includes(edge.id) ? edge.color.color : 'rgba(200,200,200,0.3)',
              strokeWidth: 0,
              align: 'top'
            },
          }));
          metricEdges.update(updatedEdges);
        } else {
          // Reset colors to original when clicking outside
          const originalEdges = metricEdges.get().map(edge => ({
            id: edge.id,
            color: {
              color: edge.color.highlight,
              highlight: edge.color.highlight,
              hover: edge.color.hover,
            },
            font: {
              color: edge.color.highlight,
              strokeWidth: 0,
              align: 'top'
            },
          }));
          metricEdges.update(originalEdges);
          setSelectedNode(null); // Clear the selected node
        }
      });

      let lastPosition: { x: number; y: number } | null = null;
      const maxZoom: number = 2;
      const minZoom: number = 0.5;

      metricNetwork.on("zoom", function (params) {
        if (params.scale < minZoom || params.scale > maxZoom) {
          if (lastPosition) {
            metricNetwork.moveTo({
              position: lastPosition,
              scale: params.scale > maxZoom ? maxZoom : minZoom,
            });
          } else {
            metricNetwork.moveTo({
              scale: params.scale > maxZoom ? maxZoom : minZoom,
            });
          }
        } else {
          lastPosition = metricNetwork.getViewPosition();
        }
      });

      metricNetwork.on("dragEnd", function () {
        lastPosition = metricNetwork.getViewPosition();
      });
      return () => {
        metricNetwork.destroy();
      };
    }
  }, [metricInfoMap]);

  return (
    <div>
      <div id="mkg-network" style={{ width: "100%", height: "100vh" }}></div>
      {/* {selectedNode && (
        <NodePopup
          nodeId={selectedNode.id}
          onClose={() => setSelectedNode(null)}
        />
      )} */}
    </div>
  );
};

export default ViewMKGNetwork;
