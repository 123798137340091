import { CreateOrUpdateJob } from "../containers/batch/Batch";
import showToast from "../hooks/useCustomToast";

/**
 * Creates or Updates a Job.
 *
 * @param {CreateOrUpdateJob.Request} json - The form data for creating the metric.
 * @returns {Promise<any>} A promise that resolves to the API response.
 * @throws {Error} Throws an error if the request fails.
 */
export const createOrUpdateJob = async (json: CreateOrUpdateJob.Request) => {
  try {
    // Build the URL with conditional id parameter
    const url = new URL(process.env.REACT_APP_CREATE_OR_UPDATE_JOB_PATH!);
    
    if (json.id !== undefined) {
      url.searchParams.append("id", json.id);
    }
    url.searchParams.append("name", json.name? json.name: "NEW_JOB");

    const response = await fetch(url.toString(), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(json.request),
    });

    if (!response.ok) {
      const errorMsg = await response.text();
      showToast("Error in creating or updating job", "", "error");
      return null;
    }
    // showToast("Step saved to Job", "", "success");
    return await response.json();
  } catch (error) {
    showToast("Error in creating or updating job", "", "error");
  }
};


/**
 * List all jobs.
 *
 * @returns {Promise<any>} A promise that resolves to the API response.
 * @throws {Error} Throws an error if the request fails.
 */
export const listJobs = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_LIST_JOBS_PATH!, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });
      
      if (!response.ok) {
        const errorMsg = await response.text();
        showToast("Error in listing jobs", "", "error");
        return null;
      }
      return  await response.json();
    } catch (error) {
      showToast("Error in listing jobs", "", "error");
    }
};

/**
 * Get Job.
 *
 * @returns {Promise<any>} A promise that resolves to the API response.
 * @throws {Error} Throws an error if the request fails.
 */
export const getJob = async (jobId: string) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_GET_JOB!}/${jobId}?includeRuns=true`!, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });
      
      if (!response.ok) {
        const errorMsg = await response.text();
        showToast("Error in getting job", "", "error");
        return null;
      }
      return  await response.json();
    } catch (error) {
      showToast("Error in getting job", "", "error");
    }
};

/**
 * Delete Job.
 *
 * @returns {Promise<any>} A promise that resolves to the API response.
 * @throws {Error} Throws an error if the request fails.
 */
export const deleteJob = async (jobId: string) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_GET_JOB!}/${jobId}`!, {
        method: "DELETE",
        credentials: "include",
      });
      if (response.status !== 204) {
        showToast("Error in getting job", "", "error");
        return null;
      }
      showToast("Job deleted successfully", "", "success");
      return response;
    } catch (error) {
      showToast("Error in deleting job", "", "error");
    }
};

/**
 * Get Job.
 *
 * @returns {Promise<any>} A promise that resolves to the API response.
 * @throws {Error} Throws an error if the request fails.
 */
export const runJob = async (jobId: string, runName: string) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_RUN_JOB!}/${jobId}?runName=${runName}`!, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });
      
      if (!response.ok) {
        const errorMsg = await response.text();
        showToast("Error in getting job", "", "error");
        return null;
      }
      // showToast("Job has run successfully", "", "success");
      return  await response.json();
    } catch (error) {
      showToast("Error in getting job", "", "error");
    }
};

/**
 * Get Jobrun list.
 *
 * @returns {Promise<any>} A promise that resolves to the API response.
 * @throws {Error} Throws an error if the request fails.
 */
export const getJobruns = async (jobId: string) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_LIST_JOB_RUNS_PATH!}/${jobId}`!, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });
      
      if (!response.ok) {
        const errorMsg = await response.text();
        showToast("Error in getting job", "", "error");
        return null;
      }
      // showToast("Job has run successfully", "", "success");
      return  await response.json();
    } catch (error) {
      showToast("Error in getting job", "", "error");
    }
};

/**
 * Get Jobrun list.
 *
 * @returns {Promise<any>} A promise that resolves to the API response.
 * @throws {Error} Throws an error if the request fails.
 */
export const getJobrun = async (jobId: string, jobRunId: string) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_GET_JOB_RUN!}/${jobRunId}?jobId=${jobId}`!, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });
      
      if (!response.ok) {
        const errorMsg = await response.text();
        showToast("Error in getting job", "", "error");
        return null;
      }
      return  await response.json();
    } catch (error) {
      showToast("Error in getting job run", "", "error");
    }
};

/**
 * Retry Jobrun.
 *
 * @returns {Promise<any>} A promise that resolves to the API response.
 * @throws {Error} Throws an error if the request fails.
 */
export const retryJobrun = async (jobId: string, jobRunId: string) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_RETRY_JOB_RUN_PATH!}/${jobRunId}?jobId=${jobId}`!, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });

      if (response.status !== 204) {
        const errorMsg = await response.text();
        showToast("Error in retry jobRun", "", "error");
        return null;
      }
      return;
    } catch (error) {
      showToast("Error in getting jobRun", "", "error");
    }
};