import React, { useEffect, useState } from "react";
import { statefulAnalysisTree } from "../../services/AnalysisTreeServices";
import { useAnalysisContext } from "../../context/AnalysisContext";
import { CommentaryContextProvider } from "../../context/CommentaryContext";
import { useAnalysisTreeContext } from "../../context/AnalysisTreeContext";
import AnalysisPanel from "../../components/analyse/AnalysisPanel";
import { useUser } from "../../context/UserContext";
import { StatefulRequestBuilder } from "../stateful/StatefulRequests";
import { NodeInsights } from "../commentary/Interfaces";
import { Header } from "../Interfaces";

interface AnalysisPanelContainerProps {}
export type View = "table" | "chart" | "prompt";

const AnalysisPanelContainer: React.FC<AnalysisPanelContainerProps> = () => {

  const { isAdmin } = useUser();
  const { setAnalysisNameFlag } = useAnalysisContext();
  const { 
    nodeDataLoader, 
    selectedNode, 
    tree, 
    treePannelLoader, 
    transactionId,
    setSelectedNodesToShowTables,
    selectedNodesToShowTables,
    isCommentaryVisible,
    setIsCommentaryVisible,
    nodeInfoMap
  } = useAnalysisTreeContext();

  const [analysisName, setAnalysisName] = useState<string>(tree?.header?.name!);
  const [analysisInfo, setAnalysisInfo] = useState<Header>(tree?.header);
  
  const [prevAnalysisName, setPrevAnalysisName] = useState<string>("");
  const [showTable, setShowTable] = useState<boolean>(false);
  
  //Update the analysis/tree name.
  const handleAnalysisName = async (newAnalysisName: string) => {
    setAnalysisName(newAnalysisName);
    if (prevAnalysisName === newAnalysisName) return;
    setPrevAnalysisName(newAnalysisName);
    const requestBuilder = new StatefulRequestBuilder(transactionId);
    const statefulRequest = requestBuilder.UpdateTreeHeaderRequest(newAnalysisName)
    await statefulAnalysisTree(statefulRequest);
    setAnalysisNameFlag((flag) => !flag);
  };

  //handles switching between chart and table
  const toggleView = () => {
    setShowTable((prev) => !prev);
  };

  // Effect to handle FIFO node addition
  useEffect(() => {
    if (!selectedNode) return;

    setSelectedNodesToShowTables((prevNodes) => {
      const selectedNodeId = selectedNode.current_node.id;

      // Check if the selected node is already in the list
      const existingNodeIndex = prevNodes.findIndex(
        (n) => n.node.current_node.id === selectedNodeId
      );

      let newQueue;

      if (existingNodeIndex !== -1) {
        // Node is already in the list; move it to the end to mark it as most recent
        const updatedNodes = [...prevNodes];
        const [existingNode] = updatedNodes.splice(existingNodeIndex, 1);
        newQueue = [...updatedNodes, existingNode];
      } else {
        // Create new node object with loading state based on current queue length
        const isLoading = prevNodes.length >= 1 ? nodeDataLoader : false;
        const newNode = { node: selectedNode, loading: isLoading };

        if (prevNodes.length === 2) {
          // Remove the first node (oldest) and add the new node to the end
          newQueue = [prevNodes[1], newNode];
        } else {
          // Add the new node to the end
          newQueue = [...prevNodes, newNode];
        }
      }

      return newQueue;
    });
  }, [selectedNode]);

  // Effect to handle loading state based on nodeDataLoader and queue length
  useEffect(() => {
    setSelectedNodesToShowTables((prevNodes) => {
      if (prevNodes.length === 0) return prevNodes;

      return prevNodes.map((n, index) => {
        if (prevNodes.length === 1) {
          // Only one node, no loader
          return { ...n, loading: false };
        } else if (index === prevNodes.length - 1) {
          // Last node in the queue should reflect the nodeDataLoader state
          return { ...n, loading: nodeDataLoader };
        } else {
          // All other nodes should not be loading
          return { ...n, loading: false };
        }
      });
    });
  }, [nodeDataLoader, selectedNodesToShowTables.length]);

  return (
    <CommentaryContextProvider>
      <AnalysisPanel
        tree={tree}
        analysisName={analysisName}
        selectedNode={selectedNode}
        nodeDataLoader={nodeDataLoader}
        showTable={showTable}
        toggleView={toggleView}
        handleAnalysisName={handleAnalysisName}
        isAdmin={isAdmin}
        treePannelLoader={treePannelLoader}
        setSelectedNodesToShowTables={setSelectedNodesToShowTables}
        selectedNodesToShowTables={selectedNodesToShowTables}
        isCommentaryVisible={isCommentaryVisible}
        setIsCommentaryVisible={setIsCommentaryVisible}
        nodeInfoMap={nodeInfoMap}
        analysisInfo={analysisInfo}
      />
    </CommentaryContextProvider>
  );
};

export default AnalysisPanelContainer;
