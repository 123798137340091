import React, {
  ReactNode,
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
} from "react";
import { statefulAnalysisTree } from "../services/AnalysisTreeServices";
import {
  AnalysisTreeResponse,
  SelectedNodes,
  Node,
  SelectedNode,
  Commentary,
  NodeInsights,
} from "../containers/commentary/Interfaces";
import { Edges } from "../containers/Interfaces";
import { AnalysisTreeRequestType } from "../utils/enum";
import { NodeMetricArray, StatefulResponse } from "../containers/stateful/Stateful";
import { useAnalysisDrawerContext } from "./AnalysisDrawerContext";
import { StatefulRequestBuilder } from "../containers/stateful/StatefulRequests";

interface ContextType {
  getNodeData: (nodeId: string) => Promise<void>;
  nodeDataLoader: boolean;
  selectedNode: NodeInsights | null;
  setSelectedNode: React.Dispatch<React.SetStateAction<NodeInsights | null>>;
  nodeInfoMap: Map<string, NodeInsights> | null;
  setNodeInfoMap: React.Dispatch<React.SetStateAction<Map<string, NodeInsights> | null>>;
  commentaryNodes: Set<string>;
  setCommentaryNodes: React.Dispatch<React.SetStateAction<Set<string>>>;
  selectedCommentary: Commentary | null;
  setSelectedCommentary: React.Dispatch<React.SetStateAction<Commentary | null>>;
  generateCommentaryConfigJSON: () => SelectedNodes | null;
  currentSelectedNode: React.MutableRefObject<string>;
  lastPosition: React.MutableRefObject<{ x: number; y: number } | null>;
  transactionId: string;
  mkgByNodeId: Map<string, NodeMetricArray>; //  key is node_id
  tree: AnalysisTreeResponse;
  rootNodeId: string;
  treePannelLoader: boolean;
  onEdgeClick: (node: NodeInsights,
    edge: Edges,
    rowIndex: number,
    transactionId: string) => void;
  getChildIdToSelect: (id: string) => string;
  setSelectedNodesToShowTables: React.Dispatch<React.SetStateAction<{node: NodeInsights, loading: boolean}[]>>;
  selectedNodesToShowTables: {node: NodeInsights, loading: boolean}[];
  isCommentaryVisible: boolean;
  setIsCommentaryVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ApiContext = createContext<ContextType | undefined>(undefined);

export const useAnalysisTreeContext = () => {
  const context = useContext(ApiContext);
  if (!context) {
    throw new Error("useApiContext must be used within an ApiProvider");
  }
  return context;
};

export const AnalysisTreeContextProvider: React.FC<{
  children: ReactNode; 
  transactionId: string;
  tree: AnalysisTreeResponse;
  nodeMetricArray: NodeMetricArray[];
}> = ({ children, transactionId, tree, nodeMetricArray }) => {

  const { handleEdgeClick } = useAnalysisDrawerContext();
  const [selectedNode, setSelectedNode] = useState<NodeInsights | null>(null);
  const [selectedCommentary, setSelectedCommentary] = useState<Commentary | null>(null);
  const [nodeDataLoader, setNodeDataLoader] = useState<boolean>(false);
  const [nodeInfoMap, setNodeInfoMap] = useState<Map<string, NodeInsights> | null>(null);
  const [commentaryNodes, setCommentaryNodes] = useState<Set<string>>(new Set());
  const [mkgByNodeId, setMkgByNodeId] = useState<Map<string, NodeMetricArray>>(new Map());
  const [treePannelLoader, setTreePannelLoader] = useState<boolean>(false);
  const currentSelectedNode = useRef<string>("");
  const lastPosition = useRef<{ x: number; y: number } | null>(null);
  const [selectedNodesToShowTables, setSelectedNodesToShowTables] = useState<{node: NodeInsights, loading: boolean}[]>([]);
  const [isCommentaryVisible, setIsCommentaryVisible] = useState(false);

  useEffect(() => {
    if(nodeMetricArray){
      const newMkgMap = new Map<string, NodeMetricArray>(mkgByNodeId);
      // Populate the new Map with nodes from the tree
      nodeMetricArray.map((node: NodeMetricArray) => {
        newMkgMap.set(node.node_id!, node);
      });
      setMkgByNodeId(new Map(newMkgMap));
    }

    if (tree) {
      // Create a new Map instance to ensure React detects the change
      const newMap = new Map<string, NodeInsights>(nodeInfoMap);
      
      // Populate the new Map with nodes from the tree
      tree?.nodes.forEach((node) => {
        newMap.set(node.id, { current_node: node });
      });
      
      // Set the new Map to the state
      setNodeInfoMap(new Map(newMap));
    }
  }, [tree])

  // Function to get node data
  const getNodeData = async (nodeId: string) => {

    const node = nodeInfoMap?.get(nodeId);
    if (node?.current_node.analysed_data! !== undefined) {
      setSelectedNode(node!);
    } else {
      setNodeDataLoader(true);

      const requestBuilder = new StatefulRequestBuilder(transactionId);
      const nodeRequest = requestBuilder.GetNodeRequest(nodeId);

      // Fetch the node data
      const responseData: StatefulResponse = await statefulAnalysisTree(nodeRequest);
      if (responseData) {
        const nodeData: Node = responseData.analysis_tree_responses
          ?.find((resp) => resp.type === AnalysisTreeRequestType.GET_ANALYSIS_NODE)
          ?.get_analysis_node?.analysis_node!;
        nodeInfoMap?.set(nodeId, { current_node: nodeData });     
        setSelectedNode({ current_node: nodeData });
      }
      setNodeDataLoader(false);
    }
  };

  const getChildIdToSelect = (id: string): string => {
    const childNodeIds = nodeInfoMap?.get(id)?.current_node.children_ids || [];
    // Select the last child node, which was generated after the subtree was created
    const childNode = nodeInfoMap?.get(childNodeIds[childNodeIds.length - 1]);
    setSelectedNode(childNode!)
    return childNodeIds[childNodeIds.length - 1];
  }
  
  // Function to generate commentary configuration JSON
  const generateCommentaryConfigJSON = (): SelectedNodes | null => {
    if (!nodeInfoMap) return null;

    let selectedNodes: SelectedNode[] = [];

    if (commentaryNodes.size > 0) {
      selectedNodes = Array.from(commentaryNodes)
        .map((nodeId) => {
          const nodeMetric = nodeInfoMap.get(nodeId);
          if (nodeMetric?.current_node.result_strings) {
            let resultStrings = nodeMetric?.current_node.result_strings.join("\n");
            if(nodeMetric.insights){
             resultStrings= `${resultStrings} ${nodeMetric.insights}`.trim();
            }
            return {
              tree_node_id: nodeId,
              node_prompt: resultStrings,
            };
          }
          return null;
        })
        .filter((node): node is SelectedNode => node !== null);
    } else {
      selectedNodes = Array.from(nodeInfoMap.entries())
        .map(([nodeId, nodeMetric]) => {
          if (nodeMetric.current_node.result_strings) {
            let resultStrings = nodeMetric?.current_node.result_strings.join("\n");
            if(nodeMetric.insights){
              resultStrings= `${resultStrings} ${nodeMetric.insights}`.trim();
             }
            return {
              tree_node_id: nodeId,
              node_prompt: resultStrings,
            };
          }
          return null;
        })
        .filter((node): node is SelectedNode => node !== null);
    }

    if (selectedNodes.length === 0) {
      return null;
    }

    return { selected_nodes: selectedNodes };
  };

  // Only show loader for handleEdgeClick operations for each transactionId
  const onEdgeClick = async (node: NodeInsights, edge: Edges, rowIndex: number, transactionId: string) => {
    setTreePannelLoader(true);
    await handleEdgeClick(node.current_node.id, edge, rowIndex, transactionId);
    setTreePannelLoader(false); // Reset loader after the operation
    setSelectedNode(node); // select the node for which sub-tree request was made.
  };

  // Effect to initialize nodeMetricMap and commentaryNodes
  // useEffect(() => {
  //   if (tree?.commentaries) {
  //     const length = tree.commentaries.length;
  //     let newNodeIds = new Set<string>();
  //     if (tree.commentaries[length - 1].configuration?.selected_nodes) {
  //       tree.commentaries[length - 1].configuration?.selected_nodes.forEach(
  //         (node) => {
  //           newNodeIds.add(node.tree_node_id);
  //         }
  //       );
  //     }
  //     setCommentaryNodes(newNodeIds);
  //   }
  // }, [tree]);

  // Effect to handle selected commentary
  useEffect(() => {
    if (selectedCommentary) {
      let newNodeIds = new Set<string>();
      if (selectedCommentary.configuration?.selected_nodes) {
        selectedCommentary.configuration?.selected_nodes.forEach(
          (node) => {
            newNodeIds.add(node.tree_node_id);
          }
        );
      }
      setCommentaryNodes(newNodeIds);
    }
  }, [selectedCommentary]);
  console.log("selectedCommentary", selectedCommentary);
  console.log("setCommentaryNodes", commentaryNodes);

  return (
    <ApiContext.Provider
      value={{
        getNodeData,
        nodeDataLoader,
        selectedNode,
        setCommentaryNodes,
        commentaryNodes,
        setSelectedNode,
        nodeInfoMap,
        setNodeInfoMap,
        selectedCommentary,
        setSelectedCommentary,
        generateCommentaryConfigJSON,
        currentSelectedNode,
        transactionId,
        mkgByNodeId,
        tree,
        rootNodeId: tree.root_node_id!,
        treePannelLoader,
        onEdgeClick,
        lastPosition,
        getChildIdToSelect,
        setSelectedNodesToShowTables,
        selectedNodesToShowTables,
        isCommentaryVisible,
        setIsCommentaryVisible
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};