import { FormData } from "../containers/Interfaces";
import showToast from "../hooks/useCustomToast";

/**
 * Fetches a list of all metrics.
 *
 * @returns {Promise<any>} A promise that resolves to the list of metrics.
 * @throws {Error} Throws an error if the request fails.
 */
export const listMetrics = async (modelId: string): Promise<any> => {
  try {
    const response = await fetch(`${process.env.REACT_APP_LIST_METRIC!}?modelId=${modelId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      showToast("Failed to fetch data", "", "error");
      return null;
    }

    return await response.json();
  } catch (error) {
    showToast("Failed to fetch Metrics list", "", "error");
  }
};

/**
 * Creates a new metric.
 *
 * @param {FormData} formData - The form data for creating the metric.
 * @returns {Promise<any>} A promise that resolves to the API response.
 * @throws {Error} Throws an error if the request fails.
 */
export const createMetric = async (formData: FormData) => {
  try {
    const response = await fetch(process.env.REACT_APP_CREATE_METRIC!, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(formData),
    });
    
    if (!response.ok) {
      const errorMsg = await response.text();
      showToast("Error in creating metric", "", "error");
      return null;
    }
    return  await response.json();
  } catch (error) {
    showToast("Error in creating metric", "", "error");
  }
};

/**
 * Creates a new metric.
 *
 * @param {FormData} formData - The form data for creating the metric.
 * @returns {Promise<any>} A promise that resolves to the API response.
 * @throws {Error} Throws an error if the request fails.
 */
export const updateMetric = async (formData: FormData) => {
  try {
    if (!process.env.REACT_APP_UPDATE_METRIC) {
      throw new Error("Could not create metric");
    }

    const response = await fetch(process.env.REACT_APP_UPDATE_METRIC, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      const errorMsg = await response.text();
      showToast("Error updating metric", "", "error");
      return null;
    }

    return await response.json();
  } catch (error) {
    showToast(`Error creating metric: ${error}`, "", "error");
  }
};

/**
 * Fetches metric information based on the provided metric ID.
 *
 * @param {string} id - The ID of the metric to fetch.
 * @returns {Promise<any>} A promise that resolves to the metric information.
 * @throws {Error} Throws an error if the request fails.
 */
export async function getMetricById(id: string, condition: boolean) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_GET_METRIC_BY_ID}/${id}?includeEdges=${condition}`,
      {
        credentials: "include",
      }
    );

    if (!response.ok) {
      showToast("Network response was not ok", "", "error");
      return null;
    }

    return await response.json();
  } catch (error) {
    showToast("Error fetching metric", "", "error");
  }
}

/**
 * delete metric by ID.
 *
 * @param {string} id - The ID of the metric to delete.
 * @returns {Promise<any>} A promise that resolves to the metric deletion.
 * @throws {Error} Throws an error if the request fails.
 */
export async function deleteMetric(id: string) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_DELETE_METRIC}/${id}`,
      {
        method: "DELETE",
        credentials: "include",
      }
    );

    if (response.status !==204 && response.status !==409) {
      showToast("Error deleting metric", "", "error");
      return null;
    }

    const clonedResponse = response.clone();
    const responseText = await clonedResponse.text();
    if (!responseText) {
      showToast("Error in deleting metric", "", "error");
      return null;
    }

    const responseJson = await response.json();
    return responseJson;
  } catch (error) {
    showToast("Error deleting metric", "", "error");
  }
}