import React, { useState } from "react";
import Layout from "../../components/layout/Layout";
import { ModelInfo } from "../../containers/Interfaces";
import "../CSS/Configuration.css";
import { FormData } from "../../containers/Interfaces";
import SuccessModel from "../../components/common/SuccessModel";
import CreateMKGContainer from "../../containers/configuration/snowflake/CreateMKGContainer";
import DefineMetricContainer from "../../containers/configuration/snowflake/DefineMetricContainer";
import AddDescriptionContainer from "../../containers/configuration/snowflake/AddDescriptionContainer";
import CreateDataModelContainer from "../../containers/configuration/snowflake/CreateDataModelContainer";
import ListDataModelContainer from "../../containers/configuration/snowflake/ListDataModelContainer";
import CreateNewMetricContainer from "../../containers/configuration/snowflake/CreateNewMetricContainer";
import {
  Box,
  Button,
  Collapse,
  Flex,
  HStack,
  Icon,
  Stack,
  Text,
  useDisclosure,
  Image,
  Badge,
  Heading,
  List,
  ListItem,
} from "@chakra-ui/react";

const Configuration: React.FC = () => {
  const [selectedLink, setSelectedLink] = useState<string>(
    localStorage.getItem("selectedLink") || ""
  );
  const [useModelInfo, setModelInfo] = useState<ModelInfo | null>(null);
  const [useMetricInfo, setMetricInfo] = useState<FormData | null>(null);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleLinkClick = (link: string): void => {
    setSelectedLink(link);
    localStorage.setItem("selectedLink", link);
  };

  const handleFetchedModelInfo = (newData: ModelInfo) => {
    setModelInfo(newData);
    setSelectedLink("DefineMetric");
  };

  const handleCreateDataModel = (data: ModelInfo) => {
    setModelInfo(data);
    setSelectedLink("AddDescription");
  };

  const handleMetricChange = (data: FormData) => {
    setMetricInfo(data);
    setShowSuccess(true);
    onOpen();
  };

  const handleEditMkg = () => {
    onClose();
    setSelectedLink("CreateMKG");
  };

  const handleCloseSuccess = () => {
    onClose();
  };

  const handleDescription = () => {
    setSelectedLink("ListDataModel");
  };

  const componentMapping: Record<string, JSX.Element | null> = {
    ListDataModel: (
      <ListDataModelContainer modelInfo={handleFetchedModelInfo} />
    ),
    CreateDataModel: (
      <CreateDataModelContainer modelInfo={handleCreateDataModel}/>
    ),
    AddDescription: useModelInfo && (
      <AddDescriptionContainer
        modelInfo={useModelInfo}
        navigateTo={handleDescription}
        updateFlag={false}
        handleCreateDataModel={null}
      />
    ),
    DefineMetric: (
      <DefineMetricContainer
        modelInfo={useModelInfo}
        metricInfo={handleMetricChange}
        onClose={onClose}
      />
    ),
    CreateMKG: (
      <CreateMKGContainer
        metricInfo={useMetricInfo}
        columns={useModelInfo?.columns || null}
      />
    ),
    CreateNewMetric: (
      <CreateNewMetricContainer dataModel={handleFetchedModelInfo} />
    ),
    default: null,
  };

  const renderComponent = () =>
    componentMapping[selectedLink] || componentMapping.default;

  return (
    // <Layout>
    //   <div className="row no-gutters">
    //     {/* Sidebar */}
    //     <div className="col-md-3" style={{ backgroundColor: "#FFF5F9" }}>
    //       <div
    //         className="d-flex flex-column flex-shrink-0 p-3 bg-light"
    //         style={{ height: "100vh" }}
    //       >
    //         {/* Metrics Section */}
    //         <span
    //           className="badge text-center"
    //           style={{
    //             backgroundColor: "#fff2f8",
    //             color: "#000",
    //             display: "block",
    //             marginTop: "10px",
    //             marginBottom: "10px",
    //           }}
    //         >
    //           <h6>Metrics</h6>
    //         </span>
    //         <ul className="nav nav-pills flex-column">
    //           <li
    //             className={
    //               selectedLink === "ListMetric"
    //                 ? "configuration-li configuration-active-link"
    //                 : "configuration-li"
    //             }
    //             onClick={() => handleLinkClick("ListMetric")}
    //           >
    //             <Link className="configuration-link" to={""}>
    //               List all Metric
    //             </Link>
    //           </li>
    //           <li
    //             className={
    //               selectedLink === "CreateNewMetric"
    //                 ? "configuration-li configuration-active-link"
    //                 : "configuration-li"
    //             }
    //             onClick={() => handleLinkClick("CreateNewMetric")}
    //           >
    //             <Link className="configuration-link" to={""}>
    //               Create New Metric
    //             </Link>
    //           </li>
    //         </ul>

    //         {/* Data Models Section */}
    //         <span
    //           className="badge text-center"
    //           style={{
    //             backgroundColor: "#fff2f8",
    //             color: "#000",
    //             display: "block",
    //             marginTop: "10px",
    //             marginBottom: "10px",
    //           }}
    //         >
    //           <h6 className="">Data Models</h6>
    //         </span>
    //         <li
    //           className={
    //             selectedLink === "ListDataModel"
    //               ? "configuration-li configuration-active-link"
    //               : "configuration-li"
    //           }
    //           onClick={() => handleLinkClick("ListDataModel")}
    //         >
    //           <Link className="configuration-link" to={""}>
    //             List Data Model
    //           </Link>
    //         </li>
    //         <li
    //           className={
    //             selectedLink === "CreateDataModel"
    //               ? "configuration-li configuration-active-link"
    //               : "configuration-li"
    //           }
    //           onClick={() => handleLinkClick("CreateDataModel")}
    //         >
    //           <Link className="configuration-link" to={""}>
    //             Create Model
    //           </Link>
    //         </li>
    //       </div>
    //     </div>

    //     {/* Main Content Area */}
    //     <div
    //       className="col-md-9"
    //       style={{
    //         backgroundColor: "white",
    //         height: "100vh",
    //         overflowY: "auto",
    //         position: "relative",
    //       }}
    //     >
    //       {renderComponent()}

    //       {/* Success Modal */}
    // {showSuccess && (
    //   <div className="modal-overlay">
    //     <SuccessModel
    //       onEditMkg={handleEditMkg}
    //       onClose={handleCloseSuccess}
    //     />
    //   </div>
    // )}
    //     </div>
    //   </div>
    // </Layout>
    <Layout>
      <Flex as="section" minH="100vh">
        <Stack
          flex="1"
          overflowY="auto"
          maxW={{ base: "full", sm: "md" }}
          py={{ base: "6", sm: "8" }}
          px={{ base: "4", sm: "6" }}
          bg="purple.50"
          borderRightWidth="1px"
          justifyContent="space-between"
        >
          <Stack spacing="8">
            <Stack spacing="1">
              <Box>
                {/* Metrics Section */}
                <Badge
                  className="text-center"
                  backgroundColor="#fff2f8"
                  color="#000"
                  display="block"
                  mt="10px"
                  mb="10px"
                  p="1"
                >
                  <Heading as="h6" size="xs">
                    Metrics
                  </Heading>
                </Badge>
                <List>
                  <ListItem
                    onClick={() => handleLinkClick("ListMetric")}
                    cursor="pointer"
                  >
                    <Button>List all Metric</Button>
                  </ListItem>
                  <ListItem
                    onClick={() => handleLinkClick("CreateNewMetric")}
                    cursor="pointer"
                  >
                    <Button>Create New Metric</Button>
                  </ListItem>
                </List>
                {/* Data Models Section */}
                <Badge
                  className="text-center"
                  backgroundColor="#fff2f8"
                  color="#000"
                  display="block"
                  mt="10px"
                  mb="10px"
                  p="1"
                >
                  <Heading as="h6" size="xs">
                    Data Models
                  </Heading>
                </Badge>
                <List>
                  <ListItem
                    onClick={() => handleLinkClick("ListDataModel")}
                    cursor="pointer"
                  >
                    <Button>List Data Model</Button>
                  </ListItem>
                  <ListItem
                    onClick={() => handleLinkClick("CreateDataModel")}
                    cursor="pointer"
                  >
                    <Button>Create Model</Button>
                  </ListItem>
                </List>
              </Box>
            </Stack>
          </Stack>
        </Stack>
        <Box flex="2" overflowY="auto">
        <Stack borderRightWidth="1px">{renderComponent()}</Stack>
        <SuccessModel
          isOpen={isOpen} // Use the isOpen state from useDisclosure
          onEditMkg={handleEditMkg}
          onClose={handleCloseSuccess}
        />
      </Box>
      </Flex>
    </Layout>
  );
};

export default Configuration;
