import React from "react";
import {
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Select,
  Button,
  Tag,
  TagLabel,
  TagCloseButton,
  Tooltip,
  VStack,
  Text,
  Flex,
  HStack,
  FormControl,
} from "@chakra-ui/react";
import { AddIcon, ArrowForwardIcon, Icon } from "@chakra-ui/icons";
import {
  Benchmark,
  TemporalColumn,
} from "../../../containers/commentary/Interfaces";
import { CustomPeriod } from "../../../utils/enum";

const BenchmarkAccordion: React.FC<{
  selectedPeriod: { name: string; value: string } | undefined;
  setSelectedPeriod: React.Dispatch<
    React.SetStateAction<{ name: string; value: string } | undefined>
  >;
  selectedTemporalColumn: TemporalColumn | null;
  setSelectedTemporalColumn: React.Dispatch<
    React.SetStateAction<TemporalColumn | null>
  >;
  selectedBenchmarks: TemporalColumn[];
  setSelectedBenchmarks: React.Dispatch<React.SetStateAction<TemporalColumn[]>>;
  date: { [key: string]: string[] };
  setDate: React.Dispatch<React.SetStateAction<{ [key: string]: string[] }>>;
  presetBenchmark: Benchmark[];
  temporalColumns: TemporalColumn[];
  handleAddPeriod: () => void;
  handleRemoveBenchmark: (index: number) => void;
}> = ({
  selectedPeriod,
  setSelectedPeriod,
  selectedTemporalColumn,
  setSelectedTemporalColumn,
  selectedBenchmarks,
  setSelectedBenchmarks,
  date,
  setDate,
  presetBenchmark,
  temporalColumns,
  handleAddPeriod,
  handleRemoveBenchmark,
}) => {
  return (
    <AccordionItem>
      <h2>
        <Tooltip
          hasArrow
          label="Expand"
          placement="right"
          color="white"
          bg="#4e1e6c"
          fontFamily="Montserrat"
          arrowSize={9}
        >
          <AccordionButton alignItems="center" pt={3}>
            <Box flex="1" textAlign="left" fontSize="lg" fontWeight="700">
              Add Benchmarks:
            </Box>
            <AccordionIcon
              fontSize="2xl"
              p={1}
              borderRadius="full"
              bg="#4e1e6c"
              color="purple.100"
            />
          </AccordionButton>
        </Tooltip>
      </h2>
      <AccordionPanel pb={4}>
        <VStack spacing={4} align="stretch">
          <Box
            border="2px"
            borderColor="#cec7dc"
            p={2}
            width="100%"
            borderRadius={7}
          >
            <Text
              fontFamily="Montserrat"
              fontSize="sm"
              fontWeight="bold"
              mb={2}
              align="left"
            >
              PRESET
            </Text>

            {presetBenchmark.map((benchmark, index) => (
              <Box
                mt={1}
                mb={1}
                width="100%"
                display="flex"
                flexWrap="wrap"
                gap={1}
                bg="purple.50"
                pt={2}
                // pl={2}
                borderRadius="md"
              >
                <Tag
                  key={index}
                  variant="solid"
                  colorScheme="purple"
                  bg="#eae3f9"
                  color="black"
                  mr={2}
                  // mb={2}
                  flexGrow={1}
                >
                  <TagLabel flexGrow={1} textAlign="left">
                    {benchmark.header.name}-
                    {benchmark.benchmark_filters.map((filter, filterIndex) => (
                      <span key={filterIndex}>
                        {/* Display your filter content here */}
                        {filter.logical_column_header.name}-{filter.operator}-
                        {filter.values} {/* Example of accessing a property */}
                      </span>
                    ))}
                  </TagLabel>
                </Tag>
              </Box>
            ))}
          </Box>

          {/* <Table size="sm" variant="simple"> */}
          <Box
            border="2px"
            borderColor="#cec7dc"
            p={2}
            width="100%"
            borderRadius={7}
          >
            <Text
              fontFamily="Montserrat"
              fontSize="sm"
              fontWeight="bold"
              mb={2}
              align="left"
            >
              CUSTOM
            </Text>

            {selectedBenchmarks.map((benchmark, index) => (
              <Box
                mt={1}
                mb={1}
                width="100%"
                display="flex"
                flexWrap="wrap"
                gap={1}
                bg="purple.50"
                pt={2}
                // pl={2}
                borderRadius="md"
              >
                <Tag
                  key={index}
                  variant="solid"
                  colorScheme="purple"
                  bg="#eae3f9"
                  color="black"
                  mr={2}
                  mb={2}
                  flexGrow={1}
                >
                  <TagLabel
                    flexGrow={1}
                    textAlign="left"
                    fontSize="xs"
                    fontWeight="bold"
                  >
                    <Icon as={ArrowForwardIcon} color="purple.500" mr={1} />
                    {benchmark?.name}={benchmark.custom_period?.name}
                  </TagLabel>
                  <TagCloseButton
                    onClick={() => handleRemoveBenchmark(index)}
                  />
                </Tag>
              </Box>
            ))}

            <HStack spacing={2}>
              <FormControl size="sm">
                <Select
                  fontSize="sm"
                  placeholder="Select Period"
                  onChange={(e) =>
                    setSelectedPeriod({
                      value: e.target.value,
                      name: e.target.options[e.target.selectedIndex].text,
                    })
                  }
                  value={selectedPeriod?.value || ""}
                >
                  <option value={CustomPeriod.PreviousPeriod}>
                    Previous period
                  </option>
                  <option value={CustomPeriod.SamePeriodLastYear}>
                    Same period last year
                  </option>
                </Select>
              </FormControl>

              <FormControl size="sm">
                {selectedPeriod && (
                  <Select
                    fontSize="sm"
                    placeholder="Select Temporal Column"
                    onChange={(e) =>
                      setSelectedTemporalColumn(
                        temporalColumns.find(
                          (column) => column.name === e.target.value
                        ) || null
                      )
                    }
                    value={selectedTemporalColumn?.name || ""}
                  >
                    {temporalColumns
                      .filter(
                        (column) =>
                          !selectedBenchmarks.some(
                            (benchmark) => benchmark?.name === column?.name
                          )
                      )
                      .map((column) => (
                        <option key={column?.name} value={column?.name}>
                          {column.name}
                        </option>
                      ))}
                  </Select>
                )}
              </FormControl>
            </HStack>
            <Flex justifyContent="flex-end">
              <Button
                leftIcon={<AddIcon />}
                mt={4}
                onClick={handleAddPeriod}
                bg="gray.300"
                iconSpacing={0}
              ></Button>
            </Flex>
          </Box>
        </VStack>
      </AccordionPanel>
    </AccordionItem>
  );
};

export default BenchmarkAccordion;
