import React, { useEffect, useState } from "react";
import {
  Text,
  Box,
  List,
  ListItem,
  Input,
  Button,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormControl,
  VStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Divider,
  HStack,
  Icon,
  Center,
  Spinner,
  Stack,
} from "@chakra-ui/react";
import { useBatchContext } from "../../context/batch/BatchContext";
import {
  FaChevronDown,
  FaPlayCircle,
  FaRegTrashAlt,
  FaRunning,
} from "react-icons/fa";
import {
  createOrUpdateJob,
  deleteJob,
  runJob,
} from "../../services/BatchServices";
import {
  ActionType,
  ColumnType,
  CustomBenchmark,
  MetricEdgeType,
} from "../../utils/enum";
import { Header, ModelInfo } from "../Interfaces";
import { getModelById } from "../../services/DataModelService";
import {
  ActionRequest,
  AnalysisActionRequest,
  CreateOrUpdateJob,
  JobProto,
  JobRequest,
  JobRun,
} from "./Batch";
import { createBenchmarks } from "../../components/common/CreateBenchmark";
import { getTreeName } from "../../utils/nameUtils";
import { AddIcon } from "@chakra-ui/icons";
import { IoDuplicate, IoEllipsisVertical } from "react-icons/io5";
import { MdEdit } from "react-icons/md";
import GlobalDateFilter from "./GlobalEntity";
import { TbFileTypePpt } from "react-icons/tb";
import showToast from "../../hooks/useCustomToast";
import { getAllReports } from "../../services/ReportPageServices";
import { sortBy } from "../../utils/sortUtils";

const JobManagerContainer: React.FC = () => {
  const {
    metricInfoMap,
    jobHeaders,
    setJobHeaders,
    selectJob,
    createJob,
    selectedJob,
    metricHeaders,
    analysisActionRequest,
    setAnalysisActionRequest,
    selectedReport,
    date,
    reports,
    setSelectedReport,
    setSelectedJob,
    setSelectedPurpose,
    setSelectedContextMap,
    setChooseTargets,
    setSelectedEdgeToExclude,
    selectedJobId,
    globatDateFilter,
    setSelectedJobStepIndex,
    setReports,
    jobLoaders,
    setJobRunHeaders,
    jobRunHeaders
  } = useBatchContext();

  const [newJobName, setNewJobName] = useState("");
  const [modelByMetricMap, setModelByMetricMap] = useState<{
    [ownerId: string]: ModelInfo;
  }>({});
  const [modelLoader, setModelLoader] = useState<boolean>(false);
  const [hoveredJobId, setHoveredJobId] = useState<string | null>(null);

  // Modal controls for creating a job
  const createJobModal = useDisclosure();
  // Modal controls for running a job
  const runJobModal = useDisclosure();
  // Modal controls for rename a job
  const renameJobModal = useDisclosure();
  // Modal controls for delete a job
  const deleteJobModal = useDisclosure();

  // Function to fetch all reports header and set them in the state
  const fetchReportsHeader = async() => {
    const response = await getAllReports("REPORT_BOOK");
    if(response){
      setReports([...response]);
    } else setReports([]);
  }

  // Function to fetch models and set them in the state
  const fetchAndSetModels = async () => {
    // Extract unique modelIds from jobSteps to avoid redundant API calls
    const modelIds = getModelIds();
    try {
      // Make API calls for all modelIds in parallel using Promise.all
      setModelLoader(true);
      const modelPromises = modelIds.map((id) => getModelById(id));
      const modelResults = await Promise.all(modelPromises);

      // Create a map to store headers grouped by ownerId
      const ownerIdToHeadersMap: { [ownerId: string]: ModelInfo } = {};
      modelResults.forEach((model: ModelInfo) => {
        analysisActionRequest.forEach((step) => {
          const matchingHeader = metricHeaders.find(
            (header) => header.id === step.generate_tree_request?.metricId
          );
          if (matchingHeader?.owner_id === model.header?.id) {
            ownerIdToHeadersMap[matchingHeader?.id!] = model;
          }
        });
      });

      // Update the state with the constructed map
      setModelByMetricMap((prevMap) => ({
        ...prevMap, // Preserve existing key-value pairs
        ...ownerIdToHeadersMap, // Update with new key-value pairs
      }));
      setModelLoader(false);
    } catch (error) {
      console.error("Error fetching model details:", error);
      setModelLoader(false);
    }
  };

  // Function to get model ids from jobSteps
  const getModelIds = () => {
    const uniqueOwnerIds = new Set<string>();
    analysisActionRequest.forEach((step) => {
      const matchingHeader = metricHeaders.find(
        (header) => header.id === step?.generate_tree_request?.metricId
      );
      if (
        matchingHeader &&
        matchingHeader.owner_id &&
        !modelByMetricMap[matchingHeader.owner_id]
      ) {
        uniqueOwnerIds.add(matchingHeader.owner_id);
      }
    });
    return Array.from(uniqueOwnerIds);
  };

  // Function to handle job creation
  const handleCreateJob = async () => {
    if (newJobName.trim()) {
      await createJob(newJobName); // Call createJob with the name only
      setNewJobName(""); // Clear the input field
      createJobModal.onClose(); // Close the modal after creating a job
    }
  };

  // Function to handle job deletion
  const handleDeleteJob = async (jobId: string) => {
    await deleteJob(jobId); // Call the delete service with jobId
    setJobHeaders((prevHeaders) =>
      prevHeaders.filter((header) => header.id !== jobId)
    );
    setAnalysisActionRequest([]);
    setSelectedJob(null);
    setSelectedPurpose("");
    setSelectedContextMap({});
    setChooseTargets([]);
    setSelectedEdgeToExclude([]);
  };

  // Update contexts in job steps based on date and model information
  const updateContextInJobSteps = () => {
    setAnalysisActionRequest((prevJobSteps) => {
      const updatedJobSteps = prevJobSteps.map(
        (jobStep: AnalysisActionRequest) => {
          const updatedActions = jobStep?.actions?.map(
            (action: ActionRequest, index: number) => {
              // Check if the action type is ASSIGN_TO_PAGE
              if (action.type === ActionType.ASSIGN_TO_PAGE) {
                // Return a new action object with updated book_id
                return {
                  ...action, // Spread existing action fields
                  assign_to_page_request: {
                    ...action.assign_to_page_request, // Spread existing assign_to_page_request fields
                    book_id: "", // Update the book_id field here with your desired value
                  },
                };
              }
              // For all other actions, return the action unchanged
              return action;
            }
          );

          const metricId = jobStep.generate_tree_request?.metricId;
          const model = modelByMetricMap[metricId!];
          if (!model) return jobStep;
          // Process benchmarks
          const processedBenchmarks =
            jobStep.generate_tree_request?.benchmark?.map((bench) => {
              if (bench.header.name === CustomBenchmark.PreviousPeriod) {
                // Create Previous Period benchmark with specific ID and name
                const benchmarks = createBenchmarks(
                  date["Date"][0],
                  date["Date"][1],
                  model?.columns?.find(
                    (column) => column.column_type === ColumnType.TEMPORAL
                  )?.header!,
                  false, // Do not include SamePeriodLastYear
                  undefined, // Skip ID for SamePeriodLastYear as it's not included
                  true, // Include PreviousPeriod
                  bench.header.id, // Pass the specific ID for PreviousPeriod
                  bench?.last_n_period_average ? true : false
                );
                return benchmarks.previousPeriodBenchmark!;
              } else if (
                bench.header.name === CustomBenchmark.SamePeriodLastYear
              ) {
                // Create Same Period Last Year benchmark with specific ID and name
                const benchmarks = createBenchmarks(
                  date["Date"][0],
                  date["Date"][1],
                  model?.columns?.find(
                    (column) => column.column_type === ColumnType.TEMPORAL
                  )?.header!,
                  true, // Include SamePeriodLastYear
                  bench.header.id, // Pass the specific ID for SamePeriodLastYear
                  false, // Do not include PreviousPeriod
                  undefined, // Skip ID for PreviousPeriod as it's not included
                  bench?.last_n_period_average ? true : false
                );
                return benchmarks.samePeriodLastYearBenchmark!;
              } else {
                // Match benchmark from metricInfo.benchmarkEdges
                const matchedBenchmark = metricInfoMap[metricId!]?.edges?.find(
                  (edge) =>
                    edge.edge_type === MetricEdgeType.BENCHMARK_EDGE &&
                    edge.benchmark?.header?.name === bench.header.name
                )?.benchmark;
                return matchedBenchmark || bench;
              }
            });

          return {
            ...jobStep,
            actions: updatedActions,
            generate_tree_request: {
              metricId: metricId,
              tree_name: getTreeName(
                jobStep.analysis_step_name!,
                globatDateFilter!
              ),
              context: jobStep.generate_tree_request?.context,
              benchmark: processedBenchmarks,
              exclude_edges: jobStep.generate_tree_request?.exclude_edges,
            },
          };
        }
      );
      handleUpdateJob(updatedJobSteps);
      return updatedJobSteps;
    });
  };

  // Function to update the job with the updated job steps
  const handleUpdateJob = async (updatedJobSteps: AnalysisActionRequest[]) => {
    // Create temporal contexts based on date and model columns
    const temporalContexts = [
      {
        logical_column_header: { name: "Date" },
        operator: "GTE",
        values: [date["Date"][0]], // Replace with your actual logic for values
      },
      {
        logical_column_header: { name: "Date" },
        operator: "LTE",
        values: [date["Date"][1]], // Replace with your actual logic for values
      },
    ];
    const jobRequest: JobRequest = {
      global_properties: {
        context_filters: temporalContexts,
        report_book_id: selectedReport?.id,
        report_book_name: "",
      },
      analysis_action_requests: updatedJobSteps,
    };
    const response: JobProto = await createOrUpdateJob({
      id: selectedJob?.header?.id,
      name: selectedJob?.header?.name,
      request: jobRequest,
    });
    if (response.request && runJobModal.isOpen) {
      runJobModal.onClose();
      showToast("Job run has started", "", "warning");
      const runJobResponse: JobRun = await runJob(response?.header?.id!, globatDateFilter!);
      if(runJobResponse.header){
        const combinedHeaders = [runJobResponse.header , ...jobRunHeaders];
        const sortedJobRunHeader = sortBy(combinedHeaders, (header) => header.created_date_ms!, "desc");
        setJobRunHeaders([...sortedJobRunHeader]);
      }
    }
  };

  // Function to handle rename of a Job
  const handleRenameJob = async () => {
    const jobRequest: JobRequest = {
      global_properties: {},
      analysis_action_requests: analysisActionRequest,
    };
    const response: CreateOrUpdateJob.Response = await createOrUpdateJob({
      id: selectedJob?.header?.id,
      name: newJobName,
      request: jobRequest,
    });
    if (response) {
      showToast("Job renamed", "", "success");
      const updatedJobs: Header[] = jobHeaders?.map((job) => {
        if (job.id === selectedJob?.header?.id) {
          return {
            ...job,
            name: newJobName,
          };
        }
        return job;
      });
      setJobHeaders(updatedJobs);
      renameJobModal.onClose();
    } else {
      showToast("Error while renaming", "", "error");
    }
  };

  const handleReportHeaderSelect = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const headerId = event.target.value;
    const selectedHeader = reports.find(
      (report) => report.id === headerId
    );
    if (selectedHeader) {
      setSelectedReport(selectedHeader);
    }
  };

  const duplicateJob = async() => {
    // Create the JobRequest JSON structure
    const jobRequest: JobRequest = {
      global_properties: {},
      analysis_action_requests: analysisActionRequest
    };
    const response:JobProto = await createOrUpdateJob({name: `${selectedJob?.header?.name} (copy)`, request: jobRequest});
    if(response){
      setJobHeaders((prevHeaders) => [...prevHeaders, response?.header!]);
      setSelectedJob({header: response.header})
      setAnalysisActionRequest([...response.request?.analysis_action_requests!]);
      setSelectedJobStepIndex(null);
    }
  }

  return (
    <Box display="flex" flexDirection="column" height="100%" mb={0}>
      {/* Fixed Heading */}
      <Box p={1} height="auto">
        <HStack justify="space-between">
          <HStack>
            <Icon as={FaRunning} boxSize="4" color="black" />
            <Text
              fontWeight="bold"
              align="left"
              m={1}
              ml={0}
              color="gray.700"
              fontSize="sm"
            >
              Jobs
            </Text>
          </HStack>

          <VStack
            align="center"
            justify="center"
            cursor="pointer"
            borderRadius="md"
          >
            <Button
              aria-label="Add new item"
              leftIcon={<AddIcon boxSize={3} />}
              colorScheme="purple.700"
              size="xs"
              variant="outline"
              onClick={createJobModal.onOpen}
            >
              New
            </Button>
          </VStack>
        </HStack>
        <Divider my={2} mb={0} borderColor="purple.700" />     
      </Box> 
      {/* Scrollable Content */}
      <Stack 
        height="100%"
        spacing="1" 
        alignItems="left"
        overflowY="auto"
        sx={{
          /* Custom scrollbar for WebKit-based browsers (Chrome, Safari, Edge) */
          "::-webkit-scrollbar": {
            width: "4px", // Set scrollbar width
          },
          "::-webkit-scrollbar-track": {
            background: "gray.100", // Background of the track
          },
          "::-webkit-scrollbar-thumb": {
            background: "gray.500", // Color of the scrollbar thumb
            borderRadius: "8px",
          },
          "::-webkit-scrollbar-thumb:hover": {
            background: "gray.600", // Color of the scrollbar thumb on hover
          },
          /* Scrollbar width for Firefox */
          scrollbarWidth: "thin", // For Firefox
          scrollbarColor: "gray.500 gray.100", // Thumb and track colors for Firefox
        }}
        >
        {jobLoaders.headerLoader ? (
          <Center height="20%">
            {/* Ensure the spinner is centered */}
            <Spinner size="md" color="purple.700" />
          </Center>
        ) : (
          <List>
            {jobHeaders?.map((header) => (
              <Box key={header.id}>
                <ListItem
                  role="group"
                  cursor="pointer"
                  onClick={() => selectJob(header)}
                  bg={
                    selectedJob?.header?.id === header.id
                      ? "purple.700"
                      : "transparent"
                  }
                  color={selectedJob?.header?.id === header.id ? "white" : "black"}
                  borderRadius="md"
                  p={1}
                  pl={2}
                  justifyContent="space-between"
                  display="flex"
                  alignItems="center"
                  fontSize="xs"
                  fontWeight={selectedJob?.header?.id === header.id ? 700 : 600}
                  _hover={{
                    bg:
                      selectedJob?.header?.id === header.id
                        ? "purple.700"
                        : "purple.50",
                  }}
                  textAlign="left"
                  onMouseEnter={() => setHoveredJobId(header?.id!)} // Track hovered job id
                  onMouseLeave={() => setHoveredJobId(null)} // Reset hovered job id
                >
                  {header.name}
                  <Box display="flex" alignItems="center">
                    <HStack spacing="0">
                      {/* Ellipsis Menu */}
                      <Menu>
                        <MenuButton
                          as={IconButton}
                          aria-label="Options"
                          icon={<IoEllipsisVertical />}
                          variant="ghost"
                          size="xs"
                          color={
                            selectedJob?.header?.id === header.id
                              ? "white"
                              : "black"
                          }
                          onClick={(e) => {
                            e.stopPropagation()
                            if(selectedJobId !== hoveredJobId){
                              selectJob(header)
                            }
                          }}
                          opacity={selectedJob?.header?.id === header.id? "1": "0"}
                          _groupHover={{ opacity: "1" }}
                        />
                        <MenuList>
                          <MenuItem
                            fontSize="xs"
                            fontWeight={600}
                            color="black"
                            icon={<Icon as={FaPlayCircle} boxSize={4}/>}
                            onClick={(e) => {
                              e.stopPropagation();
                              runJobModal.onOpen();
                              fetchAndSetModels();
                              fetchReportsHeader();
                            }}
                          >
                            Run Job
                          </MenuItem>
                          <MenuItem
                            fontSize="xs"
                            fontWeight={600}
                            color="black"
                            icon={<Icon as={MdEdit} boxSize={4}/>}
                            onClick={(e) => {
                              e.stopPropagation();
                              setNewJobName(header?.name || "");
                              renameJobModal.onOpen();
                            }}
                          >
                            Rename
                          </MenuItem>
                          <MenuItem
                            fontSize="xs"
                            fontWeight={600}
                            color="black"
                            icon={<Icon as={IoDuplicate} boxSize={4}/>}
                            onClick={(e) => {
                              e.stopPropagation();
                              duplicateJob()
                            }}
                          >
                            Duplicate
                          </MenuItem>
                          <MenuItem
                            fontSize="xs"
                            fontWeight={600}
                            color="red"
                            icon={<Icon as={FaRegTrashAlt} boxSize={4}/>}
                            onClick={(e) => {
                              e.stopPropagation();
                              deleteJobModal.onOpen();
                            }}
                          >
                            Delete
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </HStack>
                  </Box>
                </ListItem>
              </Box>
            ))}
          </List>
        )}
      </Stack>

      {/* Job Creation Modal */}
      <Modal isOpen={createJobModal.isOpen} onClose={createJobModal.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create New Job</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              placeholder="Enter new job name"
              value={newJobName}
              onChange={(e) => setNewJobName(e.target.value)}
            />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="purple" onClick={handleCreateJob}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Run Job Modal */}
      <Modal
        isOpen={runJobModal.isOpen}
        onClose={runJobModal.onClose}
        size="lg"
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Run Job</ModalHeader>
          <ModalCloseButton />
          <ModalBody padding="6">
            <VStack spacing={6} align="stretch">
              {/* Select Report Section */}
              <Box>
                <HStack>
                  <Icon as={TbFileTypePpt} boxSize={4} />
                  <Text
                    fontSize="xs" 
                    fontWeight="bold" 
                    color="gray.800" 
                    mb={0}
                    whiteSpace="nowrap"
                  >
                    Select Report
                  </Text>

                  <Menu>
                    <MenuButton
                      as={Button}
                      w="100%"
                      variant="outline"
                      colorScheme="gray"
                      rightIcon={<Icon as={FaChevronDown} boxSize={3} />} // Dropdown arrow icon
                      size="xs"
                      bg="white"
                      fontWeight={600}
                      fontSize="xs"
                      _hover={{ bg: "purple.50" }}
                      textAlign="left"
                      color="gray.600"
                      ml={-1}
                    >
                      {selectedReport?.name || "Select Report"}
                    </MenuButton>
                    <MenuList maxH="300px" overflowY="auto">
                      {reports?.map((story) => (
                        <MenuItem
                          key={story.id}
                          onClick={() =>
                            handleReportHeaderSelect({
                              target: { value: story.id },
                            } as React.ChangeEvent<HTMLSelectElement>)
                          }
                        >
                          <Text fontSize="sm" fontWeight="medium">
                            {story.name}
                          </Text>
                        </MenuItem>
                      ))}
                    </MenuList>
                  </Menu>
                </HStack>
              </Box>

              {/* Select Time Period Section */}
              <Box>
                <GlobalDateFilter />
              </Box>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="purple"
              onClick={() => updateContextInJobSteps()}
            >
              Run
            </Button>
            {/* Add any additional footer actions here */}
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Rename Job Modal */}
      <Modal isOpen={renameJobModal.isOpen} onClose={renameJobModal.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize="md">Update Job Name</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <Input
                placeholder="Enter Job name"
                value={newJobName}
                onChange={(e) => setNewJobName(e.target.value)}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="purple" mr={3} onClick={handleRenameJob}>
              OK
            </Button>
            <Button variant="ghost" onClick={renameJobModal.onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Delete Job Modal */}
      <Modal isOpen={deleteJobModal.isOpen} onClose={deleteJobModal.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize="md">Are you sure want to delete?</ModalHeader>
          <ModalCloseButton />
          <ModalFooter>
            <Button
              colorScheme="purple"
              mr={3}
              onClick={async () => {
                await handleDeleteJob(selectedJob?.header?.id!);
                deleteJobModal.onClose();
              }}
            >
              OK
            </Button>
            <Button variant="ghost" onClick={deleteJobModal.onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default JobManagerContainer;