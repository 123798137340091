import React, { useEffect, useRef, useState } from "react";
import { DataSet, Network, Options } from "vis-network/standalone/esm/vis-network";
import "vis-network/styles/vis-network.css";
import {
  AnalysisTreeResponse,
  NodeInsights,
  ObservationColumn,
} from "../commentary/Interfaces";
import { useAnalysisTreeContext } from "../../context/AnalysisTreeContext";
import { getAnalysisNodeName } from "../../utils/analysisUtils";
import { AnalysisType } from "../../utils/enum";
import { Filter } from "../Interfaces";

interface TreePannelContainerProps {
  tree: AnalysisTreeResponse | undefined;
}

const TreePannelContainer: React.FC<TreePannelContainerProps> = ({ tree }) => {
  const {
    getNodeData,
    commentaryNodes,
    setCommentaryNodes,
    nodeInfoMap,
    selectedCommentary,
    currentSelectedNode,
    selectedNode,
    lastPosition,
    getChildIdToSelect,
    selectedNodesToShowTables
  } = useAnalysisTreeContext();

  const [network, setNetwork] = useState<Network | null>(null);
  const [nodesDataSet, setNodesDataSet] = useState<DataSet<any>>(new DataSet());
  const [edgesDataSet, setEdgesDataSet] = useState<DataSet<any>>(new DataSet());
  const initialNodeSelectedRef = useRef(true);
  const networkId = `network-${tree?.header.id}`;

  // Function to focus and move a node to the top-left corner with a specific zoom level
  const focusOnNode = (nodeId: string, zoomLevel: number = 1) => {
    const nodePosition = network?.getPositions([nodeId])[nodeId];

    if (nodePosition) {
      // Calculate the offset to move the node to the top-left
      const container = document.getElementById(networkId) as HTMLElement;
      const offset = {
        x: -(container.clientWidth / 3) + 100, // Move it left towards the top-left, adjusting for padding
        y: -(container.clientHeight / 5) + 100, // Move it up towards the top-left, adjusting for padding
      };

      network?.moveTo({
        position: nodePosition, // Move to the node's position
        scale: zoomLevel,       // Set the zoom level
        offset: offset,         // Apply the offset to position the node in the top-left
      });
    }
  };

  // Combined function to update node colors based on both selection sets
  const updateCombinedNodeColors = (
    commentaryNodes: Set<string>,
    selectedNodesToShowTables: {node: NodeInsights, loading: boolean}[]
  ) => {
    // Create a Set of selected node IDs for tables
    const tableSelectedNodeIds = new Set(
      selectedNodesToShowTables.map((item) => item.node.current_node.id)
    );

    nodesDataSet.forEach((node) => {
      const isTableSelected = tableSelectedNodeIds.has(node.id);
      const isCommentarySelected = commentaryNodes.has(node.id);

      // Determine the color based on selection
      let backgroundColor = "#fff2f8"; // Default
      let borderColor = "#481c64"; // Default
      let highlightBackground = "#fff2f8"; // Default
      let highlightBorder = "#481c64"; // Default
      let borderWidth = 1;
      let borderWidthSelected = 2;
      let fontColor = "black";
      let fontSize = 16;
      let label = getAnalysisNodeName(nodeInfoMap?.get(node.id!)?.current_node!, tree!); // Original label

      if (isTableSelected) {
        // Priority: Tables
        backgroundColor = "#FFD700"; 
        borderColor = isCommentarySelected? "#204CFA" : "#616325";
        highlightBackground = "#FFD700";
        highlightBorder = isCommentarySelected? "#204CFA" : "#616325"; 
        borderWidth = isCommentarySelected? 3: 1;
        borderWidthSelected = 2;
        fontColor = "black";
        fontSize = 17;
        // Prepend the numbering based on index in selectedNodesToShowTables
        const tableIndex = selectedNodesToShowTables
            .findIndex((item) => item.node.current_node.id === node.id);
        if (tableIndex !== -1) {
          label = `${tableIndex + 1}. ${label}`;
        }
      } else if (isCommentarySelected) {
        // Commentary Selected
        borderColor = "#204CFA"; 
        highlightBorder = "#204CFA"; 
        borderWidth = isTableSelected? 3: 2;
        borderWidthSelected = 3;
        fontColor = "black";
        fontSize = 17;
        label = label;
      }

      nodesDataSet.update({
        id: node.id,
        label: label,
        color: {
          background: backgroundColor,
          border: borderColor,
          highlight: {
            background: highlightBackground,
            border: highlightBorder,
          },
        },
        borderWidth: borderWidth,
        borderWidthSelected: borderWidthSelected,
        font: {
          color: fontColor,
          size: fontSize,
          highlight: {
            color: fontColor,
          },
          multi: "html", // Allow multi-style text
          bold: {
            color: "black",
            size: fontSize,
            face: "Open Sans",
            mod: "bold",
          },
        },
      });
    });
  };
  
  // This will create nodes and edges
  useEffect(() => {
    const createNetwork = (container: HTMLElement) => {
      const initialNodes = new DataSet<any>();
      const initialEdges = new DataSet<any>();
      createEdges(nodeInfoMap!, initialEdges, initialNodes);
  
      const options: Options = {
        autoResize: true,
        nodes: {
          shape: "box",
        },
        edges: {
          arrows: { to: { enabled: true, scaleFactor: 0.5 } },
          width: 2,
          smooth: {
            enabled: true,
            type: "continuous",
            forceDirection: "none",
            roundness: 0.3,
          },
          labelHighlightBold: false,
          font: {
            align: "horizontal",
          },
        },
        layout: {
          hierarchical: {
            enabled: true,
            levelSeparation: 300,
            nodeSpacing: 100,
            direction: "LR",
            sortMethod: "directed",
          },
        },
        physics: {
          enabled: false,
          hierarchicalRepulsion: {
            nodeDistance: 120,
          },
        },
        interaction: {
          dragNodes: true,
          zoomView: true,
          dragView: true,
        },
      };
  
      const networkInstance = new Network(container, { nodes: initialNodes, edges: initialEdges }, options);
      setNetwork(networkInstance);
      setNodesDataSet(initialNodes);
      setEdgesDataSet(initialEdges);

      networkInstance.on("zoom", function (params) {
        const maxZoom: number = 2;
        const minZoom: number = 0.5;

        if (params.scale < minZoom || params.scale > maxZoom) {
          if (lastPosition) {
            networkInstance.moveTo({
              position: lastPosition.current!,
              scale: params.scale > maxZoom ? maxZoom : minZoom,
            });
          } else {
            networkInstance.moveTo({
              scale: params.scale > maxZoom ? maxZoom : minZoom,
            });
          }
        } else {
          lastPosition.current = networkInstance.getViewPosition();
        }
      });
  
      networkInstance.on("dragEnd", function () {
        lastPosition.current = networkInstance.getViewPosition();
      });
    };

    const createEdges = (
      nodeMetricMap: Map<string, NodeInsights>,
      edgesDataSet: DataSet<any>,
      nodesDataSet: DataSet<any>
    ) => {
      const nodeEntries = Array.from(nodeMetricMap.entries());
  
      for (const [nodeId, node] of nodeEntries) {
        nodesDataSet.add({
          id: node.current_node.id,
          label: getAnalysisNodeName(node.current_node, tree!),
          color: {
            background: "#fff2f8",
            border: "#481c64",
            highlight: {
              border: "#481c64",
              background: "#fff2f8",
            },
          },
          font: {
            color: "black",
            highlight: {
              color: "black",
            },
          },
          borderWidth: 1,
          borderWidthSelected: 2,
        });
        const childrenIds = node.current_node.children_ids || [];
        for (const childId of childrenIds) {
          const childNode = nodeMetricMap.get(childId);
          if (childNode) {
            if (node.current_node.analysis_type === AnalysisType.DRIVER_ANALYSIS) {
              const nodeFilterHeaders = new Set(
                node.current_node.observation.content.filters.map(
                  (filter) => filter.logical_column_header.id
                )
              );
  
              const uniqueFilters: Filter[] =
                childNode.current_node.observation.content.filters.filter(
                  (filter) => !nodeFilterHeaders.has(filter.logical_column_header.id)
                );
  
              let label = uniqueFilters
                .map((filter: Filter) => {
                  return `${filter.values && filter.values.join(", ")}`;
                })
                .join(", ");
  
              if (label === "") {
                label = "No unique filters";
              }
  
              edgesDataSet.add({
                from: node.current_node.id,
                to: childId,
                label: `Select ${label}`,
                font: {
                  align: "top",
                  color: "black",
                  strokeWidth: 0,
                },
                color: {
                  color: "grey",
                  highlight: "#4e1e6c",
                  hover: "#fff2f8",
                },
                arrows: "to",
              });
            } else if (node.current_node.analysis_type === AnalysisType.CORRELATION_ANALYSIS) {
              const observationColumns = childNode?.current_node.observation?.content?.observation_columns;
  
              let label = observationColumns && observationColumns
                .map((column: ObservationColumn) => column.logical_column_header.name)
                .join(", ");
  
              if (label === "") {
                label = "No label is present";
              }
  
              edgesDataSet.add({
                from: node.current_node.id,
                to: childId,
                label: `Select ${label}`,
                font: { align: "top" },
                color: {
                  color: "grey",
                  highlight: "#4e1e6c",
                  hover: "#fff2f8",
                },
                arrows: "to",
              });
            } else {
              edgesDataSet.add({
                from: node.current_node.id,
                to: childId,
                font: { align: "top" },
                color: {
                  color: "grey",
                  highlight: "#4e1e6c",
                  hover: "#fff2f8",
                },
                arrows: "to",
              });
            }
          }
        }
      }
    };
  
    if (tree && nodeInfoMap) {
      const container = document.getElementById(networkId) as HTMLElement;

      if (network) {
        // If the network already exists, update it with new edges
        const updatedNodes = new DataSet<any>();
        const updatedEdges = new DataSet<any>();
        createEdges(nodeInfoMap, updatedEdges, updatedNodes);

        nodesDataSet.clear(); // Clear the existing nodes
        edgesDataSet.clear(); // Clear the existing edges

        nodesDataSet.add(updatedNodes.get()); // Add the new nodes
        edgesDataSet.add(updatedEdges.get()); // Add the new edges

        network.setData({ nodes: nodesDataSet, edges: edgesDataSet }); // Update the network

          if(selectedNode){
          // We need to perform getNodeData because if a subtree is generated, its children will increase. 
          // To display the correct and updated suggestions (excluded edges) for each data row, we must execute getNodeData.
          getNodeData(selectedNode?.current_node.id);

          // Get the ID of the last child node of the current node, which is generated after the subtree is created
          const childNodeId = getChildIdToSelect(selectedNode?.current_node.id);
            if(childNodeId) {
              network.selectNodes([childNodeId!]);
              currentSelectedNode.current = childNodeId!;
              focusOnNode(childNodeId!, 0.8);
            }
          } else {
              network.selectNodes([currentSelectedNode.current]);
          }
      } else {
        // If the network does not exist, create a new one
        createNetwork(container);
        // getNodeData(tree.root_node_id!);
      }
    }
    
  }, [nodeInfoMap]); // Run this effect when nodeMetricMap changes
  
  useEffect(() => {
    updateCombinedNodeColors(commentaryNodes, selectedNodesToShowTables);
  }, [commentaryNodes, selectedNodesToShowTables]);
  
  useEffect(() => {
    // Registering interaction events
    if (network) {
      
      if (initialNodeSelectedRef.current && tree?.root_node_id) {
        //network.selectNodes([tree.root_node_id]); // Select root node
        //focusOnNode(tree.root_node_id, 0.8); // Focus and zoom on the root node
        //currentSelectedNode.current =  tree.root_node_id;
        initialNodeSelectedRef.current = false; // Ensure this only runs once
      }

      network.on("click", (event) => {
        const { nodes, event: srcEvent } = event;
        const nodeId = nodes[0];
        const handleShiftClick = (nodeId: string) => {
          const newSelectedNodes = new Set(commentaryNodes);
          if (newSelectedNodes.has(nodeId)) {
            newSelectedNodes.delete(nodeId);
          } else {
            newSelectedNodes.add(nodeId);
          }
          setCommentaryNodes(newSelectedNodes);
        };
      
        const handleNodeClick = (nodeId: string) => {
          const clickedNode = nodeInfoMap?.get(nodeId);
          if (clickedNode) {
            currentSelectedNode.current = clickedNode.current_node.id;
            getNodeData(clickedNode.current_node.id);
          }
        };
      
        const handleOutsideClick = () => {
          if (currentSelectedNode.current) {
            network.selectNodes([currentSelectedNode.current], true);
          }
        };
      
        if (nodeId) {
          if (srcEvent.srcEvent.shiftKey) {
            handleShiftClick(nodeId);
          } else {
            handleNodeClick(nodeId);
          }
        } else if (nodes.length === 0) {
          handleOutsideClick();
        }
      });
      
      network.on("oncontext", (event) => {
        event.event.preventDefault();
        const nodeId = event.nodes[0];
        if (nodeId) {
          const newSelectedNodes = new Set(commentaryNodes);
          if (newSelectedNodes.has(nodeId)) {
            newSelectedNodes.delete(nodeId);
          } else {
            newSelectedNodes.add(nodeId);
          }
          setCommentaryNodes(newSelectedNodes);
        }
      });

      updateCombinedNodeColors(commentaryNodes, selectedNodesToShowTables);
      
      return () => {
        network.off("stabilized");
        network.off("click");
      };
    }
  }, [network, commentaryNodes, selectedCommentary, nodeInfoMap]);

  return <div id={networkId} style={{ height: "100%", width: "100%" }}></div>;
};

export default TreePannelContainer;