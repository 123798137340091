import React from 'react'
import { Box, Center, Flex, Grid, Spinner } from '@chakra-ui/react'
import VisNetwork from '../../containers/commentary/VisNetwork'
import DataTable from './DataTable'
import DataChart from './DataChart'
import {
  AnalysisTreeResponse,
  Node,
} from '../../containers/commentary/Interfaces'

interface TreeViewerProps {
  tree: AnalysisTreeResponse | undefined
  selectedNodeId: string | null
  selectedNode: Node | null
  handleNodeClick: (nodeId: string, nodeMap: Map<string, Node>) => void
  isLoading: boolean
}

const TreeViewer: React.FC<TreeViewerProps> = ({
  tree,
  selectedNodeId,
  selectedNode,
  handleNodeClick,
  isLoading,
}) => {
  return (
    <Grid
      templateRows="55% 45%"
      templateColumns="1fr 1fr"
      gap={6}
      p={4}
      bg="gray.50"
      borderRadius="lg"
      border="1px solid"
      borderColor="gray.200"
      boxShadow="sm"
      height="100vh"
    >
      {/* First row - DataTable and DataChart */}
      <Box
        gridRow="1"
        gridColumn="1"
        bg="white" // Set background color to white
        borderRadius="lg"
        boxShadow="md" // Medium depth shadow
        p={1}
        height="calc(55vh - 21px)" // Adjust height calculation if necessary
        overflow="auto" // Allows scrolling if content is too large
      >
        {isLoading ? (
          <Center height="100%">
            <Spinner size="xl" color="purple" />
          </Center>
        ) : (
          <DataTable key={tree?.header.id} data={selectedNode!} />
        )}
      </Box>
      <Box
        gridRow="1"
        gridColumn="2"
        bg="white" // Set background color to white
        borderRadius="lg"
        boxShadow="md" // Medium depth shadow
        p={1}
        height="calc(55vh - 21px)" // Adjust height calculation if necessary
        overflow="auto" // Allows scrolling if content is too large
      >
        {isLoading ? (
          <Center height="100%">
            <Spinner size="xl" color="purple" />
          </Center>
        ) : (
          <DataChart key={tree?.header.id} data={selectedNode!} />
        )}
      </Box>

      {/* Second row - VisNetwork spans both columns */}
      <Box
        gridRow="2"
        gridColumn="1 / 3"
        bg="gray.50"
        borderRadius="lg"
        border="1px solid"
        borderColor="gray.200"
        boxShadow="sm"
        p={1}
      >
        <VisNetwork
          key={tree?.header.id}
          onNodeClick={handleNodeClick}
          tree={tree}
        />
      </Box>
    </Grid>
  )
}

export default TreeViewer
