import React from "react";
import {
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from "@chakra-ui/react";
import { Header } from "../../../containers/Interfaces";

interface CreateNewMetricProps {
  useData: Header[];
  page: number;
  rowsPerPage: number;
  handleAdd: (row: Header) => void;
  handleChangePage: (event: any, newPage: React.SetStateAction<number>) => void;
  handleChangeRowsPerPage: (event: { target: { value: string } }) => void;
}

const CreateNewMetric: React.FC<CreateNewMetricProps> = ({
  useData,
  page,
  rowsPerPage,
  handleAdd,
  handleChangePage,
  handleChangeRowsPerPage,
}) => {
  return (
    <TableContainer>
      <Table variant="simple" colorScheme="teal" size="sm">
        <Thead>
          <Tr>
            <Th textAlign="center" fontWeight="bold">
              Model ID
            </Th>
            <Th textAlign="center" fontWeight="bold">
              Name
            </Th>
            <Th textAlign="center" fontWeight="bold">
              Description
            </Th>
            <Th textAlign="center" fontWeight="bold">
              Actions
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {(rowsPerPage > 0
            ? useData.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )
            : useData
          ).map((row, index) => (
            <Tr
              key={row.id}
              _hover={{ bg: "gray.100", cursor: "pointer" }}
              bg={index % 2 === 0 ? "gray.50" : "white"}
            >
              <Td textAlign="center">{row.id}</Td>
              <Td textAlign="center">{row.name}</Td>
              <Td textAlign="center">{row.description}</Td>
              <Td textAlign="center">
                <Button mr={2} onClick={() => handleAdd(row)} _hover={{bg:"#4e1e6c", color:"white"}}>
                  + Metric
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      {/* <TablePagination
    sx={{
      ".MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows":
        {
          display: "flex",
          alignItems: "center",
          height: "100%",
          margin: 0,
        },
    }}
    rowsPerPageOptions={[5, 10, 25]}
    component="div"
    count={useData.length}
    rowsPerPage={rowsPerPage}
    page={page}
    onPageChange={(event, newPage) => setPage(newPage)}
    onRowsPerPageChange={(event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    }}
  /> */}
    </TableContainer>
  );
};

export default CreateNewMetric;
