import { useEffect, useState } from "react";
import { Header, ModelInfo } from "../../containers/Interfaces";
import { fetchModelList, getModelById } from "../../services/DataModelService";
import ViewMetricByModel from "../../components/configuration/ViewMetricByModel";
import { useDisclosure } from "@chakra-ui/react";
import { FormData } from "../../containers/Interfaces";
import showToast from "../../hooks/useCustomToast";

const ViewMetricByModelContainer = () => {
  const [listModel, setListModel] = useState<Header[]>([]);
  const [modelInfo, setModelInfo] = useState<ModelInfo|null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [metricFlag, setMetricFlag] = useState<boolean>(false);
  const [modelFlag, setModelFlag] = useState<boolean>(false); 
  // UseEffect hook to fetch model list when the component mounts
  useEffect(() => {
    const modelList = async () => {
      try {
        const data = await fetchModelList();
        setListModel(data);
      } catch (error) {
        showToast("Error in REACT_APP_LIST_DATMODEL", "", "error");
      }
    };
    modelList();
  }, []);

  const handleModelInfo = async(id: string) => {
      const data = await getModelById(id);
      setModelInfo(data);
      onOpen();
  }

  const handleCreateMetric = async(id: string) => {
       await handleModelInfo(id);
       setMetricFlag(true);
  }

  const handleUpdateModel = async(id: string) => {
      await handleModelInfo(id);
      setModelFlag(true);       
  }
  
  const closeMetric = () => {
      setMetricFlag(false);
      onClose();
  }
  // Render the ViewMetric component
  return (
  <ViewMetricByModel 
      listModel={listModel} 
      handleCreateMetric={handleCreateMetric}
      modelInfo={modelInfo}
      isOpen={isOpen}
      metricFlag={metricFlag}
      closeMetric={closeMetric}
      handleUpdateModel={handleUpdateModel}
      modelFlag={modelFlag}
   />
  )
};

export default ViewMetricByModelContainer;
