import React, { useState } from 'react'
import currencyCodes from 'currency-codes'
import {
  ColumnInfo,
  FilterColumn,
  Header,
  ObservationColumn,
} from '../../containers/Interfaces'
import {
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  Text,
  VStack,
  HStack,
  RadioGroup,
  Radio,
  Stack,
  Checkbox,
  Menu,
  MenuButton,
  MenuItem,
  MenuList
} from '@chakra-ui/react'
import { DeleteIcon, AddIcon, ChevronDownIcon } from "@chakra-ui/icons";
import { FaCheckCircle } from "react-icons/fa";
import { ColumnType } from '../../utils/enum';

interface EditMetricDefinitionProps {
  metricName: string
  metricDescription: string
  selectedObservationColumn: Header
  columns: ColumnInfo[]
  selectedAggregation: string
  selectedSorting: string
  selectedObservationColumns: ObservationColumn[]
  selectedFilterColumn: ColumnInfo
  selectedOperator: string
  inputValue: string
  selectedFilterColumns: FilterColumn[]
  handleNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleDescriptionChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleObservationColumnChange: (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => void
  handleFilterColumnChange: (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => void
  handleAggregationChange: (event: React.ChangeEvent<HTMLSelectElement>) => void
  handleOperatorChange: (event: React.ChangeEvent<HTMLSelectElement>) => void
  handleSortingChange: (event: React.ChangeEvent<HTMLSelectElement>) => void
  handleValueChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleAddObservationColumn: () => void
  handleAddFilterColumn: () => void
  handleDeleteObservationColumn: (indexToDelete: number) => void
  handleDeleteFilterColumn: (indexToDelete: number) => void
  submit: () => void
  type: string|undefined
  polarity: string|undefined
  currency: string|undefined
  suffix: string|undefined
  handleTypeChange: (value: string) => void
  handlePolarityChange: (value: string) => void
  handleCurrencyChange: (event: React.ChangeEvent<HTMLSelectElement>) => void
  handlePercentageChange: (value: boolean) => void
  handleSuffixChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  hasContent: boolean;
  displayType: string;
  setDisplayType: React.Dispatch<React.SetStateAction<string>>;
  columnNames: string[];
  selectedColumnValues: string[];
  handleToggle: (value: string) => void;
  handleMaterialColumn: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  selectedMaterialColumn: ColumnInfo | null;
  filterAttributeColumns: ColumnInfo[];
  decimalPlaces: number | null;
  setDecimalPlaces: React.Dispatch<React.SetStateAction<number | null>>;
}
const EditMetricDefinition: React.FC<EditMetricDefinitionProps> = ({
  metricName,
  metricDescription,
  selectedObservationColumn,
  columns,
  selectedAggregation,
  selectedFilterColumn,
  selectedSorting,
  selectedObservationColumns,
  selectedFilterColumns,
  selectedOperator,
  inputValue,
  handleNameChange,
  handleDescriptionChange,
  handleObservationColumnChange,
  handleFilterColumnChange,
  handleAggregationChange,
  handleOperatorChange,
  handleSortingChange,
  handleValueChange,
  handleAddObservationColumn,
  handleAddFilterColumn,
  handleDeleteObservationColumn,
  handleDeleteFilterColumn,
  submit,
  type,
  polarity,
  currency,
  suffix,
  handleTypeChange,
  handlePolarityChange,
  handleCurrencyChange,
  handlePercentageChange,
  handleSuffixChange,
  hasContent,
  displayType,
  setDisplayType,
  columnNames,
  selectedColumnValues,
  handleToggle,
  handleMaterialColumn,
  selectedMaterialColumn,
  filterAttributeColumns,
  setDecimalPlaces,
  decimalPlaces
}) => {

  return (
    <Container maxW="container.lg">
      <VStack spacing={5} align="stretch">
        {/* Metric Information Section */}
        <Box
          flex="1"
          border="1px"
          borderColor="gray.300"
          p={3}
          borderRadius="lg"
          bg="gray.50"
        >
          <HStack spacing={3}>
            <FormControl>
              <FormLabel fontWeight="bold">Name</FormLabel>
              <Input
                type="text"
                placeholder="Name of metric"
                value={metricName}
                onChange={handleNameChange}
                mb={3}
                fontSize="sm"
              />
            </FormControl>
            <FormControl>
              <FormLabel fontWeight="bold">Description</FormLabel>
              <Input
                type="text"
                placeholder="Describe the metric"
                value={metricDescription}
                onChange={handleDescriptionChange}
                mb={3}
                fontSize="sm"
              />
            </FormControl>
          </HStack>
        </Box>

        {/* Observation section */}
        <Box
          flex="1"
          border="1px"
          borderColor="gray.300"
          p={3}
          borderRadius="lg"
          bg="gray.50"
        >
          {/* Observation Columns Section */}
          <Flex direction="column">
            {hasContent && (
              <Box p={2} borderRadius="lg" bg="gray.50">
                <HStack spacing={5} align="stretch">
                  {/* Observation Columns Section */}
                  {selectedObservationColumns && selectedObservationColumns.length > 0 && (
                    <Box w="full">
                      {selectedObservationColumns.map((obs, index) => (
                        <Flex
                          key={index}
                          align="center"
                          justify="space-between"
                          px={2}
                          py={1}
                          borderWidth="1px"
                          borderRadius="lg"
                          borderColor="purple.700"
                          bg="bg.surface"
                        >
                          <Box>
                            <Text fontSize="xs" fontWeight="600" mb={0}>
                              {obs.aggregation!.toUpperCase()} ({obs.logical_column_header.name}),
                              <Text as="span" fontWeight={400} mb={0}> Sort By: </Text>{obs.sort_by}
                            </Text>
                          </Box>

                          <Button
                            variant="tertiary"
                            size="xs"
                            leftIcon={<DeleteIcon boxSize="0.8rem" />}
                            onClick={() => handleDeleteObservationColumn(index)}
                            color="red.600"
                          >
                            Clear
                          </Button>
                        </Flex>
                      ))}
                    </Box>
                  )}

                  {/* Selected Filters List */}
                  {selectedFilterColumns && selectedFilterColumns.length > 0 && (
                    <Box w="full">
                      {selectedFilterColumns.map((cols, index) => (
                        <Flex
                          key={index}
                          align="center"
                          justify="space-between"
                          px={2}
                          py={1}
                          borderWidth="1px"
                          borderRadius="lg"
                          borderColor="purple.700"
                          bg="bg.surface"
                        >
                          <HStack spacing="1">
                            <Text fontSize="xs" fontWeight="600" display="inline" mb={0}>{cols.logical_column_header.name}</Text>
                            <Text fontSize="xs" fontWeight="600" display="inline" mb={0}>{cols.operator}</Text>
                            <Text fontSize="xs" fontWeight="600" display="inline" mb={0}>
                              {cols.values && cols.values.join(', ')}
                            </Text>
                          </HStack>
                          <Button
                            variant="tertiary"
                            size="xs"
                            onClick={() => handleDeleteFilterColumn(index)}
                            leftIcon={<DeleteIcon boxSize="0.8em" />}
                            color="red.600"
                          >
                            Clear
                          </Button>
                        </Flex>
                      ))}
                    </Box>
                  )}
                </HStack>
              </Box>
            )}
            <HStack>
              <Box flex="1" p={2}>
                <VStack spacing={3}>
                  <Text fontSize="sm" fontWeight="bold" mb={0} w="100%">
                    Aggregation
                  </Text>
                  <Select
                    placeholder="Column"
                    onChange={handleObservationColumnChange}
                    value={selectedObservationColumn?.id || ''}
                    fontSize="sm"
                    bg="bg.surface"
                  >
                    {columns &&
                      columns.map((column) => (
                        <option key={column.header?.id} value={column.header?.id}>
                          {column.header?.name}
                        </option>
                      ))}
                  </Select>

                  <Select
                    placeholder="Aggregation Function"
                    onChange={handleAggregationChange}
                    value={selectedAggregation}
                    fontSize="sm"
                    bg="bg.surface"
                  >
                    {/* Aggregation options */}
                    <option value="SUM">SUM</option>
                    <option value="AVERAGE">AVERAGE</option>
                    <option value="COUNT">COUNT</option>
                    <option value="UNIQUE_COUNT">UNIQUE_COUNT</option>
                    <option value="GROWTH">GROWTH</option>
                    <option value="NO_AGGREGATION">NO_AGGREGATION</option>
                  </Select>

                  <Select
                    placeholder="Sort By"
                    onChange={handleSortingChange}
                    value={selectedSorting}
                    fontSize="sm"
                    bg="bg.surface"
                  >
                    {/* Sorting options */}
                    <option value="ASCENDING">ASCENDING</option>
                    <option value="DESCENDING">DESCENDING</option>
                    <option value="NO_SORT">NO_SORT</option>
                  </Select>

                  <Button
                    colorScheme="purple"
                    onClick={handleAddObservationColumn}
                    w="100%"
                    leftIcon={<AddIcon boxSize="0.9rem" />}
                    fontWeight="bold"
                  >
                    Include
                  </Button>

                </VStack>
              </Box>

              {/* Filters Section */}
              <Box flex="1" p={2}>
                <VStack spacing={3}>
                  <Text fontSize="sm" fontWeight="bold" w="100%" mb={0}>
                    Filters
                  </Text>
                  <Select
                    placeholder="Column"
                    onChange={handleFilterColumnChange}
                    value={selectedFilterColumn?.header?.id || ''}
                    fontSize="sm"
                    bg="bg.surface"
                  >
                    {columns && columns.map((column) => (
                      <option key={column.header?.id} value={column.header?.id}>
                        {column.header?.name}
                      </option>
                    ))}
                  </Select>

                  <Select
                    placeholder="Operator"
                    onChange={handleOperatorChange}
                    value={selectedOperator}
                    fontSize="sm"
                    bg="bg.surface"
                  >
                    {/* Operator options */}
                    <option value="EQ">EQ</option>
                    <option value="NE">NE</option>
                    <option value="GT">GT</option>
                    <option value="GTE">GTE</option>
                    <option value="LT">LT</option>
                    <option value="LTE">LTE</option>
                    <option value="NO_FILTER_OP">NO_FILTER_OP</option>
                  </Select>

                  {columnNames && selectedFilterColumn &&
                   selectedFilterColumn.column_type === ColumnType.ATTRIBUTE? 
                  <Menu closeOnSelect={false} preventOverflow={true}>
                    <MenuButton
                      as={Button}
                      color="fg.default"
                      bg="bg.surface"
                      maxW="100%"
                      width="100%"
                      rightIcon={<ChevronDownIcon mr={-2}/>}
                      fontSize="sm"
                      border="1px"
                      borderColor="gray.300"
                      borderRadius="md"
                      fontWeight="normal"
                      _hover={{ bg: "purple.50" }}
                      textAlign="left"
                    >
                      {selectedColumnValues.length > 0
                        ? `Selected (${selectedColumnValues.length})`
                        : "Select Options"}
                    </MenuButton>
                    <MenuList maxHeight="200px" overflowY="auto">
                      {columnNames &&
                        columnNames.map((option, index) => (
                          <MenuItem key={index} minH="40px" zIndex={10}>
                            <Checkbox
                              isChecked={selectedColumnValues.includes(option)}
                              onChange={() => handleToggle(option)}
                              colorScheme="purple"
                            >
                              {option}
                            </Checkbox>
                          </MenuItem>
                        ))}
                    </MenuList>
                  </Menu>
                    :
                  <Input
                    type="text"
                    placeholder="Values"
                    value={inputValue}
                    onChange={handleValueChange}
                    fontSize="sm"
                  />
                  }
                  <Button
                    colorScheme="purple"
                    w="100%"
                    onClick={handleAddFilterColumn}
                    leftIcon={<AddIcon boxSize="0.9rem" />}
                    fontWeight="bold"
                  >
                    Include
                  </Button>
                </VStack>
              </Box>
            </HStack>
          </Flex>
        </Box>

        <Box
          flex="1"
          border="1px"
          borderColor="gray.300"
          p={3}
          borderRadius="lg"
          bg="gray.50"
        >
          <Flex direction={{ base: 'column', md: 'row' }} gap={4}>
            <Box flex="1" p={2}>
              <VStack alignItems="flex-start">
                <Text fontSize="sm" mb={0} fontWeight="bold">Units </Text>
                <RadioGroup colorScheme="purple" onChange={setDisplayType} value={displayType}>
                  <Stack direction="row" justifyContent="space-between">
                    <Radio value="Regular" bg="white" onClick={() => handlePercentageChange(false)}>
                      <Text fontSize="sm" fontWeight={600} mb={0}>Regular</Text>
                    </Radio>
                    <Radio value="Currency" bg="white" onClick={() => handlePercentageChange(false)}>
                      <Text fontSize="sm" fontWeight={600} mb={0}>Currency</Text>
                    </Radio>
                    <Radio value="Percentage" bg="white" onClick={() => handlePercentageChange(true)}>
                      <Text fontSize="sm" fontWeight={600} mb={0}>Percentage</Text>
                    </Radio>
                  </Stack>
                </RadioGroup>
              </VStack>
              {displayType === "Currency" && (
                <Box my={2}>
                  <Select placeholder="Currency" value={currency} onChange={handleCurrencyChange} bg="white" fontSize="sm">
                    {currencyCodes.data.map((currency) => (
                      <option key={currency.code} value={currency.code}>
                        {currency.code} - {currency.currency}
                      </option>
                    ))}
                  </Select>
                </Box>
              )}
              {displayType === "Regular"&& (
                <Input
                  type="text"
                  placeholder="Suffix (if any)"
                  value={suffix}
                  onChange={handleSuffixChange}
                  my={2}
                  bg="white"
                  fontSize="sm"
                />
              )}
              <Box flex="1" pt={2}>
                <VStack spacing={3}>
                  <Text fontSize="sm" fontWeight="bold" w="100%" mb={0}>
                    Material Column
                  </Text>
                  <Select
                    placeholder="Column"
                    onChange={handleMaterialColumn}
                    value={selectedMaterialColumn?.header?.id || ''}
                    fontSize="sm"
                    bg="bg.surface"
                  >
                    {filterAttributeColumns && filterAttributeColumns
                    .map((column) => (
                      <option key={column.header?.id} value={column.header?.id}>
                        {column.header?.name}
                      </option>
                    ))}
                  </Select>
                </VStack>
              </Box>
              <Box flex="1" pt={2}>
                <VStack spacing={3}>
                  <Text fontSize="sm" fontWeight="bold" w="100%" mb={0}>
                    Decimal Precision
                  </Text>
                  <Input
                    type="number"
                    placeholder="Decimal Places"
                    value={decimalPlaces!}
                    onChange={(e) => setDecimalPlaces(e.target.value ? Number(e.target.value) : null)}
                    fontSize="sm"
                    bg="bg.surface"
                  />
                </VStack>
              </Box>
            </Box>
            <Box flex="1" p={2}>
              <VStack align="stretch" spacing={4}>
                  <Text fontSize="sm" mb={0} fontWeight="bold">Type </Text>
                  <RadioGroup value={type} colorScheme="purple">
                    <Stack direction="row">
                      <Radio bg="white" value="PERFORMANCE" onClick={() => handleTypeChange("PERFORMANCE")}> 
                        <Text fontSize="sm" fontWeight={600} mb={0}>Performance </Text>
                      </Radio>
                      <Radio bg="white" value="TARGET" onClick={() => handleTypeChange("TARGET")}>
                        <Text fontSize="sm" fontWeight={600} mb={0}>Target</Text>
                      </Radio>
                      <Radio bg="white" value="CONTROLLED" onClick={() => handleTypeChange("CONTROLLED")}>
                        <Text fontSize="sm" fontWeight={600} mb={0}>Controlled</Text>
                      </Radio>
                    </Stack>
                  </RadioGroup>
                  <Text fontSize="sm" mb={0} fontWeight="bold">Polarity </Text>
                  <RadioGroup value={polarity} colorScheme="purple">
                    <Stack direction="row">
                      <Radio bg="white" value="POSITIVE" onClick={() => handlePolarityChange("POSITIVE")}>
                        <Text fontSize="sm" fontWeight={600} mb={0}>Positive</Text>
                      </Radio>
                      <Radio bg="white" value="NEGATIVE" onClick={() => handlePolarityChange("NEGATIVE")}>
                        <Text fontSize="sm" fontWeight={600} mb={0}>Negative</Text>
                      </Radio>
                    </Stack>
                  </RadioGroup>
              </VStack>
            </Box>
          </Flex>
        </Box>

        {/* Submit Button */}
        <Box bg="white" mb={5}>
          <Button
            colorScheme="green"
            onClick={submit}
            w="100%"
            fontWeight="bold"
            fontSize="lg"
            leftIcon={<FaCheckCircle />}
          >
            Save
          </Button>
        </Box>
      </VStack>
    </Container>
  )
}

export default EditMetricDefinition