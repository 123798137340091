import React, { FormEvent } from "react";
import {
  Input,
  Box,
  VStack,
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  Container,
} from "@chakra-ui/react";
interface CreateDataModelProps {
  sourceType: string;
  dbName: string;
  schemaName: string;
  tableName: string;
  setSourceType: (value: string) => void;
  setDbName: (value: string) => void;
  setSchemaName: (value: string) => void;
  setTableName: (value: string) => void;
  handleFormSubmit: (e: FormEvent<HTMLFormElement>) => void;
  buttonLoader: boolean
}

const CreateDataModel: React.FC<CreateDataModelProps> = ({
  sourceType,
  dbName,
  schemaName,
  tableName,
  setSourceType,   
  setDbName,
  setSchemaName,
  setTableName,
  handleFormSubmit,
  buttonLoader,
}) => {
  return (
    <Container centerContent>
      <Box boxShadow="lg" p="6" rounded="md" bg="white">
        <Box as="h6" mb="4" fontWeight="semibold">
          Data Source:
        </Box>
        <Box as="form" onSubmit={handleFormSubmit}>
          <VStack spacing={4}>
            <FormControl>
              <FormLabel fontWeight="bold">Source Type</FormLabel>
              <Input
                value={sourceType}
                onChange={(e) => setSourceType(e.target.value)}
                isDisabled
              />
              <FormHelperText>
                Enter the database name, for e.g., PC_DBT_DB
              </FormHelperText>
            </FormControl>
            <FormControl>
              <FormLabel fontWeight="bold">Database Name</FormLabel>
              <Input
                value={dbName}
                onChange={(e) => setDbName(e.target.value)}
              />
              <FormHelperText>
                Enter the database name, for e.g., PC_DBT_DB
              </FormHelperText>
            </FormControl>
            <FormControl>
              <FormLabel fontWeight="bold">Schema Name</FormLabel>
              <Input
                value={schemaName}
                onChange={(e) => setSchemaName(e.target.value)}
              />
              <FormHelperText>
                Enter the name of the schema, for e.g., DBT_NGUPTA
              </FormHelperText>
            </FormControl>
            <FormControl>
              <FormLabel fontWeight="bold">Table Name</FormLabel>
              <Input
                value={tableName}
                onChange={(e) => setTableName(e.target.value)}
              />
              <FormHelperText>
                Enter the name of the data table, for e.g., LABEL_NEW_REVENUE
              </FormHelperText>
            </FormControl>
          </VStack>
          <Button mt="4" colorScheme="blue" type="submit" isLoading={buttonLoader}>
            Connect to Table
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default CreateDataModel;
