import React, { useEffect, useRef, useState } from "react";
import { Header } from "../../Interfaces";
import ListMetric from "../../../components/analyse/analysis_drawer/ListMetric";
import { useAnalysisDrawerContext } from "../../../context/AnalysisDrawerContext";

const ListMetricContainer: React.FC<{}> = () => {
  const { setSelectedMetricHeaders, metricHeaders, selectedMetricHeaders } =
    useAnalysisDrawerContext();

  // State to store the search input for filtering metrics
  const [searchInput, setSearchInput] = useState<string>("");
  const [isOverlayVisible, setIsOverlayVisible] = useState<boolean>(false);
  const [inputHeight, setInputHeight] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  
  const inputRef = useRef<HTMLInputElement>(null);
  const overlayRef = useRef<HTMLDivElement>(null);
  
  // Function to filter metrics based on the search input
  const filterMetrics = (input: string): Header[] => {
    return (metricHeaders || []).filter((metric) =>
      metric.name?.toLowerCase().includes(input.toLowerCase())
    );
  };

  // Filtered metrics based on the search input
  const filteredMetrics: Header[] = filterMetrics(searchInput);
  const itemsPerPage = 12; // Number of buttons per page
  const pageCount = Math.ceil(filteredMetrics.length / itemsPerPage);

  useEffect(() => {
    if (inputRef.current) {
      setInputHeight(inputRef.current.offsetHeight);
    }
  }, [inputRef.current]);

  // Slice the array to show only items for the current page
  const currentItems = filteredMetrics.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  const handleNext = () => {
    setCurrentPage((prevPage) => (prevPage + 1) % pageCount);
  };

  const handlePrevious = () => {
    setCurrentPage((prevPage) => (prevPage - 1 + pageCount) % pageCount);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    // Check if the related target is within the overlay or not
    if (!overlayRef.current?.contains(e.relatedTarget as Node)) {
      setIsOverlayVisible(false);
    }
  };
  // Function to handle metric selection/deselection
  const handleMetricSelection = (metric: Header) => {
    // Check if the metric is already selected
    const isMetricSelected = selectedMetricHeaders.includes(metric);

    // Update the selected metrics array based on selection/deselection
    const updatedMetrics = isMetricSelected
      ? selectedMetricHeaders.filter((m) => m.id !== metric.id)
      : [...selectedMetricHeaders, metric];

    setSelectedMetricHeaders(updatedMetrics);
  };

  //It will set the page to 0 when something is search
  useEffect(() => {
    setCurrentPage(0);
  }, [searchInput]);
  
  //handle close overlay on clicking outside metric list
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        overlayRef.current &&
        !overlayRef.current.contains(event.target as Node) &&
        inputRef.current &&
        !inputRef.current.contains(event.target as Node)
      ) {
        setIsOverlayVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Render the MetricAccordion component with the relevant props
  return (
    <ListMetric
      totalMetrics={metricHeaders && metricHeaders.length}
      filteredMetrics={filteredMetrics}
      searchInput={searchInput}
      setSearchInput={setSearchInput}
      selectedMetrics={selectedMetricHeaders}
      handleMetricSelection={handleMetricSelection}
      isOverlayVisible={isOverlayVisible}
      inputHeight={inputHeight}
      currentItems={currentItems}
      inputRef={inputRef}
      overlayRef={overlayRef}
      setIsOverlayVisible={setIsOverlayVisible}
      handlePrevious={handlePrevious}
      handleNext={handleNext}
      handleBlur={handleBlur}
    />
  );
};

export default ListMetricContainer;
