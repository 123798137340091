import {
  Avatar,
  AvatarBadge,
  Box,
  Flex,
  HStack,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react'
import { Analysis } from '../../containers/chat/interface'
import { Header } from '../../containers/Interfaces'

interface Props {
  analysis: Header
  onMessageClick: () => void
}

/**
 * Component responsible for displaying all the starred commentaries i.e. analysis
 */
export const ChatAnalysis = ({ analysis, onMessageClick }: Props) => {
  const name = analysis.name;
  const updatedAt = analysis.last_modified_date_ms;
  const image = ''

  return (
    <HStack
      onClick={onMessageClick}
      align="flex-start"
      gap="3"
      px="4"
      py="3"
      _hover={{ bg: 'purple.100' }}
      rounded="md"
      cursor="pointer"
      w="100%"
    >
      <Box pt="1">
        <Avatar size="sm" src={image} name={name}>
          <AvatarBadge boxSize="0.75em" bg="#4e1e6c" />
        </Avatar>
      </Box>
      <Stack spacing="0" fontSize="sm" flex="1" isTruncated>
        <HStack spacing="0" align="center">
          <Text fontWeight="bold" flex="1" align="left" m={0}>
            {name}
          </Text>
        </HStack>
        <Text color="gray.500" fontSize="xs" m={0} align="left">
          Last Run: {updatedAt}
        </Text>
        {/* <Box color="fg.subtle" isTruncated>
          {message}
        </Box> */}
      </Stack>
    </HStack>
  )
}
