import React, { useState, useEffect } from 'react'
import { Header, Edges } from '../../Interfaces'
import { FilterColumn, TemporalColumn } from '../Interfaces'
import ContextAccordian from '../../../components/commentary/sidebar/ContextAccordion'
import { LocalDate, DateTimeFormatter } from '@js-joda/core'
import { TimeBucket } from '../../../utils/enum'
import { useSidebarContext } from '../../../context/CommentarySidebarContext'
import { getColumnValues } from '../../../services/DataModelService'

const ContextAccordianContainer: React.FC<{
  selectedTemporal: (data: TemporalColumn[]) => void
}> = ({ selectedTemporal }) => {
  const {
    metricInfoMap,
    temporalColumnByMetricIdMap,
    updateFilterContext,
    updateTemporalContext,
    removeFilter,
    removeTemporal,
    setDate,
  } = useSidebarContext()

  const [uniqueRelatedAttributeName, setUniqueRelatedAttributeName] = useState<
    string[]
  >([])
  const [
    uniquetemporalColumnNameFromModelInfo,
    setUniquetemporalColumnNameFromModelInfo,
  ] = useState<string[]>([])
  const [selectedFilter, setSelectedFilter] = useState<FilterColumn>({})
  const [selectedTemporalColumn, setSelectedTemporalColumn] = useState<
    TemporalColumn
  >({})
  const [filters, setFilters] = useState<FilterColumn[]>([])
  const [
    selectedTemporalColumnsFromDropdown,
    setSelectedTemporalColumnsFromDropdown,
  ] = useState<TemporalColumn[]>([])
  const [selectedMonth, setSelectedMonth] = useState<{
    value: string
    name: string
  }>({
    value: '',
    name: '',
  })
  const [selectedQuarter, setSelectedQuarter] = useState<{
    value: string
    name: string
  }>({
    value: '',
    name: '',
  })
  const [selectedYear, setSelectedYear] = useState<string>('')
  // const [date, setDate] = useState<{ [key: string]: string[] }>({});
  const [filterContextValues, setFilterContextValues] = useState<string[]>([])
  const [selectedOptions, setSelectedOptions] = useState<string[]>([])
  const [isLoadingAttributeValues, setIsLoadingAttributeValues] = useState<boolean>(false)
  const [isFailedAttributeValues, setIsFailedAttributeValues] = useState<boolean>(false)

  useEffect(() => {
    // Create a Set to store unique related attribute names
    const uniqueRelatedAttributeNames = new Set<string>()

    // Iterate through the values of the metricInfoMap
    Object.values(metricInfoMap).forEach((metric: any) => {
      // Extract the related_attributes array from the metric (default to an empty array)
      const edgesArray = metric?.edges || []

      // Iterate through the edgesArray and add unique names to the Set
      edgesArray.forEach((edges: Edges) => {
        const name = edges?.related_attribute?.name
        if (name) {
          uniqueRelatedAttributeNames.add(name)
        }
      })
    })

    // Update the state with the unique related attribute names
    setUniqueRelatedAttributeName(Array.from(uniqueRelatedAttributeNames))
  }, [metricInfoMap])

  useEffect(() => {
    const uniqueNames = new Set<string>() // Create a set to store unique names

    // Iterate over all entries in the map
    Object.values(temporalColumnByMetricIdMap).forEach((headers: any) => {
      headers.forEach((header: any) => {
        if (header.name) {
          uniqueNames.add(header.name) // Add the name to the set, automatically handling uniqueness
        }
      })
    })
    setUniquetemporalColumnNameFromModelInfo(Array.from(uniqueNames))
    setFilters([])
    setSelectedTemporalColumnsFromDropdown([])
  }, [temporalColumnByMetricIdMap])

  const handleFilterContextValues = async (id: string) => {
    setIsLoadingAttributeValues(true)
    setIsFailedAttributeValues(false)
    const response = await getColumnValues(id)
    if (!response) {
      setFilterContextValues([])
      setIsFailedAttributeValues(true)
    } else {
      setFilterContextValues(response)
    }
    setIsLoadingAttributeValues(false)
  }

  const handleFilterSelection = async (
    name: string,
    operator: string,
    values: string[],
  ) => {
    if (process.env.REACT_APP_ATTRIBUTE_VALUES_DROPDOWN === 'true') {
      let flg = false
      Object.entries(metricInfoMap).forEach(
        ([metricId, metric]: [string, any]) => {
          console.log('metric edges ', metric.edges)
          metric.edges?.forEach((edge: any) => {
            if (edge.related_attribute?.name === name) {
              flg = true
              const id = edge.related_attribute?.id!
              handleFilterContextValues(id)
            }
          })
        },
      )

      if (!flg) setFilterContextValues([])

      setSelectedOptions([])
    }

    setSelectedFilter({
      ...selectedFilter,
      name,
      operator,
      values,
    })
  }

  // Function to handle time selection and update selected temporal column state
  const handleTimeSelection = (
    name: string,
    time_bucket: string,
    logical_column_header?: Header,
    quarter?: {
      name: string
      value: string
    },
    month?: {
      name: string
      value: string
    },
    year?: string,
  ) => {
    setSelectedTemporalColumn({
      name,
      logical_column_header,
      time_bucket,
      quarter,
      month,
      year,
    })
  }

  // Function to add the selected filter to the filters state
  const handleAddFilter = () => {
    if (!selectedFilter.name || !selectedFilter.values?.length) return
    // Adding the filter to some filter state assumed to exist
    setFilters([...filters, { ...selectedFilter }])

    updateFilterContext(selectedFilter)
    // Reset selected filter to default values
    setSelectedFilter({
      name: '',
      operator: '',
      values: [],
    })

    setSelectedOptions([])
    setFilterContextValues([])
    // Iterate over each metric in metricInfoMap
  }

  // Function to add the selected temporal column to the temporalColumnFromDropdown state
  const handleAddTemporalColumn = () => {
    const temporalColumnWithDetails = {
      ...selectedTemporalColumn,
      quarter: {
        name: selectedQuarter.name,
        value: selectedQuarter.value,
      },
      month: {
        name: selectedMonth.name,
        value: selectedMonth.value,
      },
      year: selectedYear,
    }
    updateTemporalContext(selectedTemporalColumn)
    setSelectedTemporalColumnsFromDropdown([
      ...selectedTemporalColumnsFromDropdown,
      temporalColumnWithDetails,
    ])

    // modelTemporalColumnInfoByMetricId
    // Reset selected temporal column to default values
    setSelectedTemporalColumn({
      logical_column_header: {},
      time_bucket: '',
      quarter: {
        name: '',
        value: '',
      },
      month: {
        name: '',
        value: '',
      },
      year: '',
    })
    setSelectedQuarter({
      name: '',
      value: '',
    })
  }

  // Function to remove a filter at a specific index from the filters state
  const handleRemoveFilter = (index: number) => {
    const filterName = filters[index].name
    const filtersToRemove = filters.filter(
      (filter) => filter.name === filterName,
    )
    const updatedFilters = filters.filter(
      (filter) => filter.name !== filterName,
    )
    setFilters(updatedFilters)
    filtersToRemove.forEach((filter) => removeFilter(filter))
  }

  // Function to remove a temporal column at a specific index from the temporalColumnFromDropdown state
  const handleRemoveTemporalColumn = (index: number) => {
    const removedTemporalColumn = selectedTemporalColumnsFromDropdown[index]
    const updatedTemporalColumns = [...selectedTemporalColumnsFromDropdown]
    updatedTemporalColumns.splice(index, 1)
    setSelectedTemporalColumnsFromDropdown(updatedTemporalColumns)

    const temporalColumnName = removedTemporalColumn.name

    // Remove references from the date map
    setDate((prevDates: any) => {
      const updatedDate = { ...prevDates }
      delete updatedDate[temporalColumnName!]
      return updatedDate
    })

    // Update contextByMetricMap to remove any contexts associated with the removed temporal column
    removeTemporal(removedTemporalColumn)
  }

  // structure the context time in the formate of querying in backend
  const handleDateCalculations = (
    selectedTime: TemporalColumn,
    selectedMonth: string,
    selectedQuarter: string,
    selectedYear: string,
  ) => {
    // Convert selectedMonth and selectedYear to numeric values
    const monthValue = parseInt(selectedMonth)
    const yearValue = parseInt(selectedYear, 10)

    // Initialize variables for start and end dates
    let startDate: string = ''
    let endDate: string = ''
    const dateFormatter = DateTimeFormatter.ofPattern('MM/dd/yyyy')
    // Check the selected time operator
    if (selectedTime.time_bucket === TimeBucket.Monthly) {
      // Calculate start and end dates for monthly time bucket
      if (!Number.isNaN(monthValue) && !Number.isNaN(yearValue)) {
        startDate = LocalDate.of(yearValue, monthValue, 1).format(dateFormatter)
        endDate = LocalDate.of(yearValue, monthValue, 1)
          .plusMonths(1)
          .minusDays(1)
          .format(dateFormatter)

        // If start and end dates are valid, update the date state for the selected temporal column
        if (startDate && endDate) {
          setDate((prevDates: any) => ({
            ...prevDates,
            // [selectedTime.logical_column_header?.id || ""]: [
            [selectedTime.name || '']: [
              startDate.toString(),
              endDate.toString(),
            ],
          }))
        }
      }
    } else if (selectedTime.time_bucket === TimeBucket.Quarterly) {
      // Calculate start and end dates for quarterly time bucket
      if (!Number.isNaN(selectedQuarter) && !Number.isNaN(yearValue)) {
        const quarterStartMonth = (parseInt(selectedQuarter) - 1) * 3 + 1
        startDate = LocalDate.of(yearValue, quarterStartMonth, 1).format(
          dateFormatter,
        )
        endDate = LocalDate.of(yearValue, quarterStartMonth, 1)
          .plusMonths(3)
          .minusDays(1)
          .format(dateFormatter)

        // If start and end dates are valid, update the date state for the selected temporal column
        if (startDate && endDate) {
          setDate((prevDates: any) => ({
            ...prevDates,
            // [selectedTime.logical_column_header?.id || ""]: [
            [selectedTime.name || '']: [
              startDate.toString(),
              endDate.toString(),
            ],
          }))
        }
      }
    } else if (selectedTime.time_bucket === TimeBucket.Yearly) {
      // Calculate start and end dates for yearly time bucket
      if (!Number.isNaN(yearValue)) {
        startDate = LocalDate.of(yearValue, 1, 1).format(dateFormatter)
        endDate = LocalDate.of(yearValue, 1, 1)
          .plusYears(1)
          .minusDays(1)
          .format(dateFormatter)

        // If start and end dates are valid, update the date state for the selected temporal column
        if (startDate && endDate) {
          setDate((prevDates: any) => ({
            ...prevDates,
            // [selectedTime.logical_column_header?.id || ""]: [
            [selectedTime.name || '']: [
              startDate.toString(),
              endDate.toString(),
            ],
          }))
        }
      }
    }
  }

  const getAvailableTemporalColumns = () => {
    return uniquetemporalColumnNameFromModelInfo.filter((column) => {
      const columnName = column // Using name instead of id
      return !selectedTemporalColumnsFromDropdown.some(
        (selectedColumn) => selectedColumn.name === columnName, // Matching by name
      )
    })
  }

  //this useEffect handle's the request to change selected time bucket to corresponding date formate.
  useEffect(() => {
    handleDateCalculations(
      selectedTemporalColumn,
      selectedMonth.value,
      selectedQuarter.value,
      selectedYear,
    )
  }, [selectedMonth, selectedQuarter, selectedYear, selectedTemporalColumn])

  //selectedTemporal columns return to parent so that it can pass to benchmarkAccordion
  useEffect(() => {
    selectedTemporal(selectedTemporalColumnsFromDropdown)
  }, [filters, selectedTemporalColumnsFromDropdown])

  const handleToggle = (value: string) => {
    const currentIndex = selectedOptions.indexOf(value)
    let newSelected = [...selectedOptions]

    if (currentIndex === -1) {
      newSelected.push(value)
    } else {
      newSelected.splice(currentIndex, 1)
    }

    setSelectedOptions(newSelected)
    setSelectedFilter((filter: FilterColumn) => ({
      ...filter,
      values: newSelected,
    }))
  }

  return (
    <ContextAccordian
      filters={filters}
      selectedTemporalColumnsFromDropdown={selectedTemporalColumnsFromDropdown}
      uniqueRelatedAttributeName={uniqueRelatedAttributeName}
      selectedFilter={selectedFilter}
      selectedTemporalColumn={selectedTemporalColumn}
      handleFilterSelection={handleFilterSelection}
      handleTimeSelection={handleTimeSelection}
      handleAddFilter={handleAddFilter}
      handleAddTemporalColumn={handleAddTemporalColumn}
      handleRemoveFilter={handleRemoveFilter}
      handleRemoveTemporalColumn={handleRemoveTemporalColumn}
      selectedMonth={selectedMonth}
      selectedQuarter={selectedQuarter}
      selectedYear={selectedYear}
      setSelectedMonth={setSelectedMonth}
      setSelectedQuarter={setSelectedQuarter}
      setSelectedYear={setSelectedYear}
      availableTemporalColumns={getAvailableTemporalColumns()}
      filterContextValues={filterContextValues}
      selectedOptions={selectedOptions}
      handleToggle={handleToggle}
      isLoadingAttributeValues={isLoadingAttributeValues}
      isFailedAttributeValues={isFailedAttributeValues}
    />
  )
}

export default ContextAccordianContainer
