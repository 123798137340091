import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Icon,
  Text
} from "@chakra-ui/react";
import ListMetricContainer from "../../../containers/analyse/analysis_drawer/ListMetricContainer";
import ContextFilterContainer from "./../../../containers/analyse/analysis_drawer/ContextFilterContainer";
import BenchmarkFilterContainer from "./../../../containers/analyse/analysis_drawer/BenchmarkFilterContainer";
import { BiSolidAnalyse } from "react-icons/bi";
import { useAnalysisContext } from "../../../context/AnalysisContext";
import { useAnalysisDrawerContext } from "../../../context/AnalysisDrawerContext";

const AnalysisDrawer: React.FC = () => {

  const { generateAnalysisTree, isOpen, onClose, btnRef } = useAnalysisDrawerContext();
  const { isLockAnalyzeButton } = useAnalysisContext();

  return (
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        finalFocusRef={btnRef}
        size="md"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader></DrawerHeader>

          <DrawerBody>
            <ListMetricContainer />
            <ContextFilterContainer />
            <BenchmarkFilterContainer />
          </DrawerBody>

          <DrawerFooter>
            <Button
              mt="auto"
              mb={4}
              size="lg"
              colorScheme="purple"
              bg="purple.50"
              color="fg.default"
              minH="42px"
              width="full"
              fontSize="lg"
              fontWeight={600}
              borderRadius="xl"
              border="1px solid"
              borderColor="#4e1e6c"
              onClick={generateAnalysisTree}
              _hover={{
                bg: "#4e1e6c",
                color: "white",
                fontWeight: "bold",
                '.rotate-icon': {  // Custom class for the icon
                  color: "white",  // Change icon color on hover
                  animation: "rotate 1s linear infinite"
                }
              }}
              isLoading={isLockAnalyzeButton}
              loadingText="ANALYZING..."
              spinnerPlacement="start"
              sx={{
                '@keyframes rotate': {
                  from: { transform: 'rotate(0deg)' },
                  to: { transform: 'rotate(360deg)' }
                }
              }}
            >
              <Icon
                as={BiSolidAnalyse}
                mr={3}
                boxSize="1.5rem"
                color="fg.default"
                transition="all 0.5s ease-in-out"
                className="rotate-icon"  // Apply custom class for rotation and color change
              />
              <Text mb={0}>Analyze</Text>
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
  );
};

export default AnalysisDrawer;