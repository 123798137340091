import React, { useState } from "react";
import {
  Box,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  FormControl,
  Flex,
  Text,
  Divider,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  VStack,
  Stack,
} from "@chakra-ui/react";
import { useExportContext } from "../../context/export/ExportContext";
import { IoBook, IoBookOutline, IoEllipsisVertical } from "react-icons/io5";
import { Story } from "./Interface";
import { createUpdateReport, deleteReport } from "../../services/ReportPageServices";
import { AddIcon } from '@chakra-ui/icons';
import { EditIcon, DownloadIcon } from '@chakra-ui/icons';
import { FaRegTrashAlt } from "react-icons/fa";

interface StoryPanelProps {}

const StoryPanel: React.FC<StoryPanelProps> = () => {
  const {
    stories,
    selectedStoryId,
    setStories,
    setSelectedStoryId,
    handleSelectStory,
    setPages,
    setSelectedTreeHeaders,
    handleExport
  } = useExportContext();

  const createModal = useDisclosure();
  const renameModal = useDisclosure();
  const deleteModal = useDisclosure();

  const [inputValue, setInputValue] = useState("");
  const [storyToRename, setStoryToRename] = useState<Story | null>(null);
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleCreateStory = async () => {
    if (inputValue.trim() !== "") {
      const newStory: Story = {
        header: { name: inputValue },
        pages: [],
      };
      const response = await createUpdateReport(newStory);
      if(response){
        setStories([...stories, response]);
        setInputValue("");
        setPages([]);
        setSelectedStoryId(newStory.header.id!);
        setSelectedTreeHeaders([]);
      }
      createModal.onClose();
    }
  };

  const handleRenameStory = async () => {
    if (storyToRename && inputValue.trim() !== "") {
      try {
        const updatedPage: Story = {
          ...storyToRename,
          header: {
            ...storyToRename.header,
            name: inputValue,
          },
        };
        const response: Story = await createUpdateReport(updatedPage);
        const updatedStories = stories.map((story) =>
          story.header.id === response.header.id ? response : story
        );
        setStories(updatedStories);
        setSelectedStoryId(response.header.id!);
        setInputValue("");
        setStoryToRename(null);
        renameModal.onClose();
      } catch (error) {
        alert("Error renaming page: " + error);
      }
    }
  };

  const handleReportDelete = async()=>{
    await deleteReport(selectedStoryId, "REPORT_BOOK");
    const updatedStories =
        stories && stories.filter((story) => story.header.id !== selectedStoryId);
    setStories(updatedStories);
    setPages([]);
    deleteModal.onClose();
  }

  const openCreateModal = () => {
    setInputValue("");
    createModal.onOpen();
  };

  const openRenameModal = (story: Story) => {
    setStoryToRename(story);
    setInputValue(story.header.name!);
    renameModal.onOpen();
  };

  const openDeleteModal = () => {
    deleteModal.onOpen();
  };

  return (
    <Box 
      borderColor="#4e1e6c"
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <Box bg="bg.surface" p={2} height="100%">
        <HStack justify="space-between">
          <Text
            fontWeight="bold"
            align="left"
            m={1}
            color="fg.muted"
            fontSize="sm"
          >
            Reports
          </Text>
          <VStack
            align="center"
            justify="center"
            cursor="pointer"
            borderRadius="md"
        >
            <IconButton
                aria-label="Add new item"
                icon={<AddIcon />}
                colorScheme="purple"
                size="xs"
                variant="ghost"
                onClick={openCreateModal}
            />
        </VStack>
        </HStack>
        <Divider m={1} />
        <Stack 
          height="96%"
          spacing="1" 
          alignItems="left"
          overflowY="auto"
          sx={{
            /* Custom scrollbar for WebKit-based browsers (Chrome, Safari, Edge) */
            "::-webkit-scrollbar": {
              width: "4px", // Set scrollbar width
            },
            "::-webkit-scrollbar-track": {
              background: "gray.100", // Background of the track
            },
            "::-webkit-scrollbar-thumb": {
              background: "gray.500", // Color of the scrollbar thumb
              borderRadius: "8px",
            },
            "::-webkit-scrollbar-thumb:hover": {
              background: "gray.600", // Color of the scrollbar thumb on hover
            },
            /* Scrollbar width for Firefox */
            scrollbarWidth: "thin", // For Firefox
            scrollbarColor: "gray.500 gray.100", // Thumb and track colors for Firefox
          }}
        >
          {stories &&
            stories.map((story, index) => (
              <HStack
                key={index}
                py={1}
                spacing="0"
                _hover={{
                  bg: selectedStoryId === story.header.id ? "purple.700" : "gray.50"
                }}                
                cursor="pointer"
                borderRadius="lg"
                align="center"
                justify="space-between"
                role="group"
                bg={selectedStoryId === story.header.id ? "#4e1e6c" : "white"}
                color={selectedStoryId === story.header.id ? "white" : "black"}
                onClick={() => handleSelectStory(story.header.id!)}
              >
                <HStack spacing="3">
                  <Icon
                    as={
                      selectedStoryId === story.header.id
                        ? IoBook
                        : IoBookOutline
                    }
                    color={
                      selectedStoryId === story.header.id
                        ? "white"
                        : "fg.default"
                    }
                    ml={2}
                  />
                  <Text fontSize="sm" fontWeight="medium" align="left" m={0}>
                    {story.header.name}
                  </Text>
                </HStack>
                <Menu>
                  <MenuButton
                    as={IconButton}
                    aria-label="Options"
                    borderRadius="full"
                    icon={<IoEllipsisVertical />}
                    variant="ghost"
                    size="sm"
                    color={selectedStoryId === story.header.id ? "white" : "black"}
                  />
                  <MenuList>
                    <MenuItem 
                      fontSize="sm" 
                      color="black" 
                      icon={<EditIcon />} 
                      onClick={() => openRenameModal(story)}
                    >
                      Rename
                    </MenuItem>
                    <MenuItem 
                      fontSize="sm" 
                      color="black"
                      icon={<DownloadIcon />}
                      onClick={() => handleExport("REPORT_BOOK", story.header.id!)}
                    >
                      Export
                    </MenuItem>
                    <MenuItem
                      fontSize="sm"
                      color="red"
                      icon={<FaRegTrashAlt />}  
                      onClick={() => openDeleteModal()}    
                    >
                      Delete   
                    </MenuItem>
                  </MenuList>
                </Menu>
              </HStack>
            ))}
        </Stack>
      </Box>

      <Modal isOpen={createModal.isOpen} onClose={createModal.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize="md">Create New Report</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <Input
                placeholder="Enter story name"
                value={inputValue}
                onChange={handleInputChange}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="purple" mr={3} onClick={handleCreateStory}>
              OK
            </Button>
            <Button variant="ghost" onClick={createModal.onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={renameModal.isOpen} onClose={renameModal.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize="md">Update Report Name</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <Input
                placeholder="Enter story name"
                value={inputValue}
                onChange={handleInputChange}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="purple" mr={3} onClick={handleRenameStory}>
              OK
            </Button>
            <Button variant="ghost" onClick={renameModal.onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      
      <Modal isOpen={deleteModal.isOpen} onClose={deleteModal.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize="md">Are you sure want to delete the report?</ModalHeader>
          <ModalCloseButton />
          <ModalFooter>
            <Button colorScheme="purple" mr={3} onClick={handleReportDelete}>
              OK
            </Button>
            <Button variant="ghost" onClick={deleteModal.onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default StoryPanel;