import React, { useState } from "react";
import "../../../App.css";
import { ColumnInfo, Header, ModelInfo } from "../../Interfaces";
import { createModel, updateModel } from "../../../services/DataModelService";
import AddDescription from "../../../components/configuration/snowflake/AddDescription";

/**
 *
 * Input: modelInfo object containing information about the data model.
 *        navigateTo(set to true) callback to navigate to another component from parent component.
 * Return: Renders the AddDescription component with relevant props.
 *
 * Handles user input for updating model information, including header, columns, and descriptions.
 * Submits the updated model information to the API.
 *
 * @component
 */

const AddDescriptionContainer: React.FC<{
  modelInfo: ModelInfo | null;
  navigateTo: ((data: boolean) => void) | null; 
  updateFlag: boolean;
  handleCreateDataModel: (() => void) | null;
}> = ({ modelInfo, navigateTo, updateFlag, handleCreateDataModel }) => {
  //State to store the header of Data Model
  const [header, setHeader] = useState<Header>(modelInfo?.header! || {});
  const [buttonLoader, setButtonLoader] = useState<boolean>(false);
  //State to store the columns of Data Model
  const [columns, setColumns] = useState<ColumnInfo[]>(modelInfo?.columns || []);
  /**
   * Handles changes in the header properties.
   *
   * @param {string} property - The property to be updated.
   * @param {string} value - The new value for the property.
   */
  const handleHeaderChange = (property: string, value: string) => {
    setHeader({
      ...header,
      [property]: value,
    });
  };

  /**
   * Handles changes in the column properties.
   *
   * @param {number} index - The index of the column to be updated.
   * @param {string} property - The property to be updated.
   * @param {string} value - The new value for the property.
   */
  const handleColumnChange = (
    index: number,
    property: string,
    value: string
  ) => {
    const newColumns = [...columns];
    newColumns[index] = {
      ...newColumns[index],
      [property]: value,
    };

    //dependencies change due to data_type
    let columnType;
    if (property === 'data_type') {
      if(['VARCHAR', 'BOOLEAN'].includes(value)){
        columnType = 'ATTRIBUTE';
        delete newColumns[index]['default_aggregation'];
      }
      else if (['INT32', 'INT64', 'DOUBLE'].includes(value)) {
        columnType = 'MEASURE';
        if (!newColumns[index]['default_aggregation']){
          newColumns[index]['default_aggregation'] = 'SUM';
        }
      } 
      else if (['DATE', 'DATETIME'].includes(value)) {
        columnType = 'TEMPORAL';
        delete newColumns[index]['default_aggregation'];
      }
      newColumns[index]['column_type'] = columnType;
    } 

    //dependencies change due to column_type
    if(property === 'column_type'){
      if(['ATTRIBUTE', 'TEMPORAL'].includes(value)){
        delete newColumns[index]['default_aggregation'];
      }
      else{
        newColumns[index]['default_aggregation'] = 'SUM';
      }
    }

    setColumns(newColumns);
  };

  /**
   * Handles changes in the column header properties.
   *
   * @param {number} index - The index of the column to be updated.
   * @param {string} property - The property to be updated.
   * @param {string} value - The new value for the property.
   */
  const handleColumnHeaderChange = (
    index: number,
    property: string,
    value: string
  ) => {
    const newColumns = [...columns];

    newColumns[index] = {
      ...newColumns[index],
      header: {
        ...newColumns[index].header,
        [property]: value,
      },
    };

    setColumns(newColumns);
  };

  // Handle form submission
  const handleSubmit = async () => {
    try {
      const model_info = {
          header: header, // Updated header with name and description
          data_source: modelInfo?.data_source, // Unchanged data source info
          columns: columns,
      };

      setButtonLoader(true);
      if(!updateFlag){
        const data = await createModel(model_info);
        if(data){
          alert("Model created successfully! Redirecting to listing Data Model");
          handleCreateDataModel && handleCreateDataModel();
        } 
      }
      else{
        console.log("updatedModelInfo ", model_info);
        const data = await updateModel(model_info);
        if(data){
          alert("Model updated successfully");
          window.location.reload();  
        } 
      }
      setButtonLoader(false);
      // Notify the parent component about successful navigation
      navigateTo && navigateTo(true);
    } catch (error) {
      // Handle errors that may occur during the API call
      alert("Error sending data to API create model");
    }
  };
  return (
    // Render the AddDescription component with relevant props
    <AddDescription
      header={header}
      columns={columns}
      handleHeaderChange={handleHeaderChange}
      handleColumnChange={handleColumnChange}
      handleColumnHeaderChange={handleColumnHeaderChange}
      handleSubmit={handleSubmit}
      updateFlag={updateFlag}
      buttonLoader={buttonLoader}
    />
  );
};

export default AddDescriptionContainer;
